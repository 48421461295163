import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import Subtitles from "../../basics/Subtitles";
import Input from "../../basics/Input";
import InputPassword from "../../basics/InputPassword";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { LoginModel } from "../../../models/signin/LoginModel";
import useValidation from "../../../custom-hooks/useValidation";
import { useAxios } from "../../../custom-hooks/useAxios";
import { AuthClient, MessageAlerteClient } from "../../../services/generated/FrontOffice-api";
import { navigate } from "@reach/router"
import { useAppDispatch, useUserContext } from '../../../context/context-helpers';
import { ActionTypeEnum } from '../../../context/ActionType';
import { RouteComponentProps } from "@reach/router";
import { toast } from 'react-toastify';
interface ConnectionProps extends RouteComponentProps {
	setResetPassword?: (value:boolean) => void;
	setShowPass?: (value:boolean) => void;
	showPass?: boolean;
}

const Connection: React.FunctionComponent<ConnectionProps> = ({
	setResetPassword,
	setShowPass,
	showPass
}) => {
	const { t } = useTranslation();
	const { register, getValues, triggerValidation, errors } = useForm<LoginModel>();
	const { getRootValidator } = useValidation();
	const axiosInstance = useAxios();
	const loginValidator = getRootValidator("LoginDto");
	const dispatch = useAppDispatch();
	const user = useUserContext();
    const [message, setMessage] = useState<string>('');
    const messageAlerteClient = new MessageAlerteClient("", axiosInstance);

	useEffect(() => {
		if (user?.userName) {
			// User is already defined, so the login page should not be accessible. Redirecting to Home page
			navigate("/Structures");
		}
	}, [user]);

	useEffect(() => {
		(async() => {
            const result = await messageAlerteClient.getMessageAlerte();
            result && setMessage(result.message);
        })();
	}, []);

	const login = async (event: MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		const result = triggerValidation();

		if (result) {
			const client = new AuthClient('', axiosInstance);

			try {
				const { username, password } = getValues();
				const result = await client.login({ userName: username, password });
	
				if (result) {
					dispatch({
						type: ActionTypeEnum.SET_USER, payload: {
							statutStructure: result.statutStructure,
							email: result.email,
							id: result.id,
							nom: result.nom,
							prenom: result.prenom,
							userName: result.userName
						}
					});
				}
				navigate("/Structures");

			} catch(error) {
				if(error.code === "structureDisabled")
					navigate("/NonAuthoriseRefuse");

				if(error.code === "structurePending")
					navigate("/NonAuthoriseEnAttente");

				if (error.exception?.message)
					toast.error(error.exception.message);
				else if(window.location.pathname !== "/NonAuthoriseRefuse" && window.location.pathname !== "/NonAuthoriseEnAttente"){
					toast.error(t("errors.default"));
				}
			}
		}
	}


	return (
		<>
			{message?.length !== 0 &&
				<div className="messageWarning messageWarning__connection"><i className="fas fa-exclamation-triangle" />{message}</div>
			}
			<Subtitles
				title={t('portal.connection.title')}
			/>

			<form method="" action="">
				<Input
					name="username"
					reference={register(loginValidator && loginValidator["UserName"])}
					label={t('portal.connection.login')}
					placeHolder={t('portal.connection.login-placeholder')}
					type="text"
					errors={errors}
				/>

				<InputPassword
					name="password"
					reference={register}
					label={t('portal.connection.password')}
					placeHolder={t('portal.connection.password-placeholder')}
					setShowPass={setShowPass}
					showPass={showPass}
					errors={errors}
				/>

				<div className="modal__CTA">
					<button
						type="button"
						className="modal__CTA--button"
						onClick={() => {
							setResetPassword(true);
							setShowPass(false);
						}}
					>
						{t('portal.connection.password-forgotten')}
					</button>

					<FormButton
						type="submit"
						value={t('portal.connection.validate-button')}
						onClick={login}
					/>
				</div>
			</form>
		</>
	)
}

export default Connection;