import React from "react";
import { useTranslation } from 'react-i18next';
import { DonneesBancairesDto } from '../../../services/generated/FrontOffice-api';

interface DonneesBancaireReadonlyProps {
    donneesBancaires: DonneesBancairesDto;
}

const DonneesBancaireReadonly: React.FC<DonneesBancaireReadonlyProps> = ({donneesBancaires}) => {
    const { t } = useTranslation();

    return (<div className="donneesBancairesReadOnly">
		<div className="readOnly__item">
			<div className="input">
				<label>{t('signup.step-three.label')}</label>
				<input value={donneesBancaires.iban} disabled />
			</div>
		</div>
		<div className="readOnly__item">
			<div className="input">
				<label>{t('signup.step-three.bic-label')}</label>
				<input value={donneesBancaires.bic} disabled />
			</div>
			<div className="input">
				<label>{t("signup.step-three.banque-label")}</label>
				<input value={donneesBancaires.banque} disabled />
			</div>
		</div>
		<div className="readOnly__item">
			<div className="input">
				<label>{t('signup.step-three.adresse-label')}</label>
				<input value={donneesBancaires.adresse?.line1} disabled />
			</div>
			<div className="input">
				<label>{t('signup.step-five.complementAdresse')}</label>
				<input value={donneesBancaires.adresse?.line2} disabled />
			</div>
		</div>
		<div className="readOnly__item">
			<div className="input">
				<label>{t('signup.step-three.ville-label')}</label>
				<input value={donneesBancaires.adresse?.ville} disabled />
			</div>
			<div className="input">
				<label>{t('signup.step-three.codepostal-label')}</label>
				<input value={donneesBancaires.adresse?.codePostal} disabled />
			</div>
		</div>
		<div className="readOnly__item">
			<div className="input">
				<label>{t('signup.step-three.pays-label')}</label>
				{/* <input value={donneesBancaires.adresse.pays} disabled /> TODO: Brancher le pays quand il sera remonté ! */}
				<input value={"-"} disabled />
			</div>
			<div className="input">
				<label>{t('signup.step-three.ribPJ')}</label>
				{donneesBancaires.rib && <div className="itemSingle"><a href={donneesBancaires.rib.uri} target="_blank">{donneesBancaires.rib.fileName}</a></div>}
			</div>
		</div>
	</div>)
};

export default DonneesBancaireReadonly;
