import React, { FunctionComponent, KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { navigate, RouteComponentProps } from '@reach/router';
import { AdresseDto, FormeJuridiqueDto, StructureClient, StructureDetailsDto } from '../../services/generated/FrontOffice-api';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useForm } from "react-hook-form";
import useValidation from "../../custom-hooks/useValidation";
import Input from 'adel-shared/dist/components/basics/Input';
import Modal from 'react-modal';
import { modalSmallerCustomStyles } from "../../constants/config.constant";
import { AppStateContext } from '../../context/AppContext';
import { ActionTypeEnum } from "../../context/ActionType";
import InputValidationMonCompteStructure from "./InputValidationMonCompteStructure";
import { toast } from "react-toastify";
import { useAxios } from "../../custom-hooks/useAxios";
import { formatPhone } from 'adel-shared/dist/utils/functions';

interface MonCompteStructureProps extends RouteComponentProps {
	ref: any;
	structureDetails: StructureDetailsDto
	setStructureDetails: (value:StructureDetailsDto) => void;

}

const MonCompteStructure: FunctionComponent<MonCompteStructureProps> = ({
	structureDetails, setStructureDetails
}) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const structureClient = new StructureClient('', axiosInstance);
	const [, dispatch] = useContext(AppStateContext);
	const { register, getValues, setValue, triggerValidation, errors, watch } = useForm();
	const watchFields = watch();
	const { getRootValidator } = useValidation();
	const updateStructureJuridiqueValidator = getRootValidator("UpdateStructureJuridiqueDto");
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [editInput, setEditInput] = useState<boolean>(false);
	const [dataUpdated, setDataUpdated] = useState<boolean>(false);
	const [oldSiretValue, setOldSiretValue] = useState<string>('');
	const [siretValidated, setSiretValidated] = useState<boolean>(false);
	const [isSiretValidated, setIsSiretValidated] = useState<boolean>(true);
	const adresse = useRef<AdresseDto>({});
	const formeJuridique = useRef<FormeJuridiqueDto>({});
	const siren = structureDetails.siret?.substring(0, 9);
	const updatesRequiringDocuments = watchFields.nom !== structureDetails.representantLegal?.nom || 
									watchFields.prenom !== structureDetails.representantLegal?.prenom ||
									watchFields.siret !== structureDetails.siret;	

	useEffect(() => {
		Modal.setAppElement('body');
	}, []);
	
    const CheckSirenValidation = async() => {
		structureClient.getStructureInfoFromSiret(structureDetails.siret, structureDetails.id && parseInt(structureDetails.id)).catch(()=> {
			setIsSiretValidated(false)
		})
	}

	useEffect(() => {
		if (structureDetails?.siret) {
			CheckSirenValidation()
		}
	}, [structureDetails]);

	useEffect(() => {
		const {
			siret,
			raisonSociale,
			codeAPE,
			formeJuridique: {
				code: codeFormeJuridique
			} = {},
			representantLegal: {
				nom,
				prenom,
				email,
				telephoneFixe,
				telephonePortable
			} = {},
			adresse: {
				line1: adresseLine1,
				line2: adresseLine2
			} = {}
		} = structureDetails;

		setValue([
			{ nom },
			{ prenom },
			{ email },
			{ siret },
			{ codeAPE },
			{ raisonSociale },
			{ adresse: adresseLine1 },
			{ formeJuridique: codeFormeJuridique },
			{ telephoneFixe },
			{ telephonePortable }
		]);
		adresse.current = structureDetails.adresse;
		formeJuridique.current = structureDetails.formeJuridique;
		setOldSiretValue(siret);

		if(adresseLine2) {
			setValue([
				{ line2: adresseLine2 }
			]);
		}
	}, [structureDetails, editInput]);

	const handleSubmit = async() => {
		const result = await triggerValidation();

		if(!result) return;

        if (!getValues().telephoneFixe && !getValues().telephonePortable) {
			toast.error(t('errors.telephone'));
			return;
		}

		if(updatesRequiringDocuments) {
			setStructureDetails({...structureDetails, representantLegal: {...structureDetails.representantLegal, nom: watchFields.nom, prenom: watchFields.prenom}, siret: watchFields.siret});
			setIsUpdateModalOpen(true);
		} else {
			try {
				const {
					siret,
					raisonSociale,
					codeAPE,
					nom,
					prenom,
					email,
					telephoneFixe,
					telephonePortable
				} = getValues();

				await structureClient.updateStructureJuridique({
					siret,
					raisonSociale,
					codeAPE,
					adresse: adresse.current,
					formeJuridique: formeJuridique.current,
					nomResponsable: nom,
					prenomResponsable: prenom,
					emailResponsable: email,
					telephoneFixe,
					telephonePortable
				});
				const structureDetailsDto = await structureClient.getStructureDetails();
		     	setStructureDetails(structureDetailsDto);
				toast.success(t('myAccount.structureUpdateSuccess'));
			} catch(error) {
				toast.error(t('myAccount.structureUpdateError'));
				
				if (error.exception?.message)
					toast.error(error.exception.message);
			}
		}
	};

	const handleUpdateModalClose = () => {
		setIsUpdateModalOpen(false)
	};

	const handleUpdateModalValidate = () => {
		const { siret, raisonSociale, formeJuridique, nom, prenom, email } = getValues();
		dispatch({
			type: ActionTypeEnum.UPDATE_STRUCTURE,
			payload: {
				...structureDetails,
				siret,
				raisonSociale,
				adresse: adresse.current,
				formeJuridique: formeJuridique.current,
				representantLegal: {
					...structureDetails.representantLegal,
					nom,
					prenom,
					email
				}
			}
		});
		navigate('/Compte/PiecesJointes/edit');
	};

	const handleEditClick = () => {
		setEditInput(true);
	};

	const handleCancelEdit = () => {
		setEditInput(false);
	}

	// Invalidate Siret to be able to query again the api if the SIRET is modified
	const invalidateSiret = async () => {
		setSiretValidated(false);
	};

	const handleSiretInput = (e: KeyboardEvent<HTMLInputElement>) => {
		let result;

		if(e.currentTarget.value.indexOf(siren) === 0 || !isSiretValidated) {
			result = e.currentTarget.value;
		} else {
			if(e.currentTarget.value) {
				result = oldSiretValue;
			} else {
				result = siren;
			}
		}

		if (typeof result === 'string') {
			setValue('siret', result);
		}
		
		setOldSiretValue(e.currentTarget.value);
	};

	const validateSiret = async () => {
		if (!triggerValidation("siret")) {
			return false;
		}

		const { siret } = getValues();
		setIsLoading(true);

		try {
			const result = await structureClient.getStructureInfoFromSiret(siret, structureDetails.id && parseInt(structureDetails.id));

			if (result.isRegisteredWithAdel) {
				toast.error(t('errors.siretAlreadyRegistered'));
				setIsLoading(false);
				return false;
			}

			setSiretValidated(true);

			setValue('raisonSociale', result.raisonSociale);
			if(result.adresse) {
				setValue('adresse', result.adresse.line1);
				setValue('line2', result.adresse.line2);
				adresse.current = result.adresse;
			}
			if (result.formeJuridique) {
				setValue('formeJuridique', result.formeJuridique.code);
				formeJuridique.current = result.formeJuridique;
			}
			setValue('codeAPE', result.codeAPE);

			setIsLoading(false);		

			// Vérifier si des données ont été modifiées. Si c'est le cas afficher le message d'alerte
			if (structureDetails.codeAPE !== result.codeAPE ||
				structureDetails.raisonSociale !== result.raisonSociale ||
				structureDetails.formeJuridique.code !== result.formeJuridique.code ||
				structureDetails.adresse.line1 !== result.adresse.line1 ||
				structureDetails.adresse.line2 !== result.adresse.line2) {
				setDataUpdated(true);
			}

			return true;
		} catch (error) {
			dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: error as string } });
			setSiretValidated(false);
			setIsLoading(false);
			return false;
		}
	}

	return (
		<>
			<div className="monCompte__header">
				{dataUpdated ? <div className="messageWarning"><i className="fas fa-exclamation-triangle" />{t('signup.step-one.alert-validation-siret')}</div> : ""}	
			</div>
			<div className="monCompte__header">
				
				<h3>{t("myAccount.structure")}</h3>	
				{!editInput && (
					<i
						className="far fa-edit"
						role="button"
						title="Modifier"
						onClick={handleEditClick}
					></i>
				)}				
			</div>			
			{structureDetails.siret && (
				<>
					{editInput ? (
						<>						
							<div>
								<InputValidationMonCompteStructure
									name="siret"
									reference={register(updateStructureJuridiqueValidator?.["Siret"])}
									label={t('signup.step-one.label')}
									placeHolder={t('signup.step-one.placeholder')}
									maxLength={14}
									buttonLabel={t('signup.step-one.validation-siret-button')}
									onChange={invalidateSiret}
									isLoading={isLoading}
									onInput={handleSiretInput}
									onValidate={validateSiret}
									validated={siretValidated}
									errors={errors}
								/>
							</div>
							<div>
								<div className="input">
									<Input
										name="raisonSociale"
										reference={register(updateStructureJuridiqueValidator?.["RaisonSociale"])}
										label={t("signup.step-one.raison-sociale.label")}
										type="text"
										errors={errors}
									/>
								</div>
							</div>
							<div className="readOnly">
								<div className="readOnly__item">
									<div className="input">
										<Input
											name="adresse"
											reference={register(updateStructureJuridiqueValidator?.["Adresse"])}
											label={t("signup.step-one.address.label")}
											type="text"
											readOnly
										/>
									</div>
									<div className="input">
										<Input
											name="line2"
											reference={register(updateStructureJuridiqueValidator?.["Line2"])}
											label={t("signup.step-five.complementAdresse")}
											type="text"
											readOnly
										/>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className="readOnly">
							<div className="readOnly__item">
								<div className="input">
									<label>{t('signup.step-one.label')}</label>
									<input value={structureDetails.siret} disabled />
								</div>
								<div className="input">
									<label>{t("signup.step-one.raison-sociale.label")}</label>
									<input value={structureDetails.raisonSociale} disabled />
								</div>
							</div>
							<div className="readOnly__item">
								<div className="input">
									<label>{t("signup.step-one.address.label")}</label>
									<input value={structureDetails.adresse.line1} disabled />
								</div>
								<div className="input">
									<label>{t("signup.step-five.complementAdresse")}</label>
									<input value={structureDetails.adresse.line2} disabled />
								</div>
							</div>
						</div>
					)}
					<div className="readOnly">
						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.siren")}</label>
								<input value={siren} disabled />
							</div>

							{editInput ? (
								<div className="input">
									<Input
										name="formeJuridique"
										reference={register(updateStructureJuridiqueValidator?.["FormeJuridique"])}
										label={t("myAccount.form.formeJuridique")}
										type="text"
										readOnly
									/>
								</div>
							) : (
									<div className="input">
										<label>{t("myAccount.form.formeJuridique")}</label>
										<input value={structureDetails.formeJuridique.code} disabled />
								</div>
							)}													
						</div>
						{editInput ? (
							<div className="input">
								<Input
									name="codeAPE"
									reference={register(updateStructureJuridiqueValidator?.["codeAPE"])}
									label={t("myAccount.form.codeAPE")}
									type="text"
									readOnly
								/>
							</div>
						) : (
							<div className="readOnly__item">
								<div className="input">
									<label>{t("myAccount.form.codeAPE")}</label>
									<input value={structureDetails.codeAPE} disabled />
								</div>
								<div className="input"></div>
							</div>
						)}
					</div>

					{editInput ? (
						<>
							<div>
								<div className="monCompte__formRow">
									<Input
										name="telephoneFixe"
										reference={register(updateStructureJuridiqueValidator?.["TelephoneFixe"])}
										label={t("myAccount.form.telephoneFixe")}
										type="text"
										errors={errors}
									/>
									<Input
										name="telephonePortable"
										reference={register(updateStructureJuridiqueValidator?.["TelephonePortable"])}
										label={t("myAccount.form.telephonePortable")}
										type="text"
										errors={errors}
									/>
								</div>
								<div className="monCompte__formRow">
									<Input
										name="nom"
										reference={register(updateStructureJuridiqueValidator?.["NomResponsable"])}
										label={t("myAccount.form.nompresident")}
										type="text"
										errors={errors}
									/>

									<Input
										name="prenom"
										reference={register(updateStructureJuridiqueValidator?.["PrenomResponsable"])}
										label={t("myAccount.form.prenomPresident")}
										type="text"
										errors={errors}
									/>
								</div>

								<div className="monCompte__formRow">
									<Input
										name="email"
										reference={register(updateStructureJuridiqueValidator?.["EmailResponsable"])}
										label={t("myAccount.form.emailPresident")}
										type="text"
										errors={errors}
									/>
								</div>
							</div>
							<div className="monCompte__editFooter">
								<FormButton
									type="button"
									value={t('common.cancel')}
									onClick={handleCancelEdit}
								/>
								<FormButton
									type="submit"
									value={t('common.validate')}
									onClick={handleSubmit}
								/>
							</div>
						</>
					) : (
						<div className="readOnly">
							<div className="readOnly__item">
								<div className="input">
									<label>{t("myAccount.form.telephoneFixe")}</label>
									<input value={structureDetails.representantLegal.telephoneFixe ? formatPhone(structureDetails.representantLegal.telephoneFixe) : '-'} disabled />
								</div>
								<div className="input">
									<label>{t("myAccount.form.telephonePortable")}</label>
									<input value={structureDetails.representantLegal.telephonePortable ? formatPhone(structureDetails.representantLegal.telephonePortable) : '-'} disabled />
								</div>
							</div>
							<div className="readOnly__item">
								<div className="input">
									<label>{t("myAccount.form.nompresident")}</label>
									<input value={structureDetails.representantLegal.nom} disabled />
								</div>
								<div className="input">
									<label>{t("myAccount.form.prenomPresident")}</label>
									<input value={structureDetails.representantLegal.prenom} disabled />
								</div>
							</div>
							<div className="readOnly__item">
								<div className="input">
									<label>{t("myAccount.form.emailPresident")}</label>
									<input className="email" value={structureDetails.representantLegal.email} disabled />
								</div>
							</div>
						</div>
					)}
				</>
			)}

			<Modal
				isOpen={isUpdateModalOpen}
				style={modalSmallerCustomStyles}
			>
				<div className="modal__content">
					<h4>{t('myAccount.structureUpdateModal.title')}</h4>
					<p>{t('myAccount.structureUpdateModal.text')}</p>
					<p>{t('myAccount.structureUpdateModal.text2')}</p>
				</div>
				<div className="modal__footer">
					<FormButton
						type="button"
						value="Plus tard"
						onClick={handleUpdateModalClose}
					/>
					<FormButton
						type="submit"
						value="Ajouter maintenant"
						onClick={handleUpdateModalValidate}
					/>
				</div>
			</Modal>
		</>
	)
};

export default MonCompteStructure;
