import React, { ReactNode, Children, useEffect, useRef } from 'react';

/**
 * InfiniteScroll props interface.
 * 
 * @interface
 * @property {Function}  callback   Function to call when component limit is reach.
 * @property {ReactNode} children   Children to render inside.
 * @property {string[]}  classNames An attached class list.
 */
interface IInfiniteScrollProps {
	callback: () => any;
	children: ReactNode;
	classNames: string[];
}

/**
 * InfiniteScroll => The Infinite scroll component.
 * 
 * @returns {JSX.Element}
 */
const InfiniteScroll = ({
	callback,
	children,
	classNames,
}: IInfiniteScrollProps): JSX.Element => {

	const scrollRef = useRef<HTMLDivElement>(null);
	const classList = classNames && classNames.length > 0 ? classNames.join(" ") : '';

	useEffect(() => {
		handleScroll();
	}, []);

	const handleScroll = () => {
		if (scrollRef.current.scrollHeight - scrollRef.current.scrollTop ===
			scrollRef.current.clientHeight) {
			callback();
		}
	}

	return (
		<div ref={scrollRef} className={`infinite-scroll ${classList}`} 
			// onScroll={handleScroll} TODO: A corriger car fonctionne très mal
		>
			{children}
		</div>
	);
}

export default InfiniteScroll;