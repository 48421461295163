import React, { useState, useEffect } from "react";
import { Link, navigate } from '@reach/router';

import Lang from "../../basics/Lang";
import SearchBar from "./SearchBar";
import ColoredLogo from "../../../components/logo/ColoredLogo";
import MenuBurger from "../../basics/MenuBurger";
import { useAppDispatch, useUserContext } from '../../../context/context-helpers';
import { AuthClient, FOCurrentUserDto, NotificationClient, StatutStructure } from '../../../services/generated/FrontOffice-api';
import { useAxios } from '../../../custom-hooks/useAxios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ActionTypeEnum } from '../../../context/ActionType';
import clsx from "clsx";

interface StructuresHeaderProps {
	isStructurePendingOrDisabled?: boolean;
}

const StructuresHeader: React.FC<StructuresHeaderProps> = ({
	isStructurePendingOrDisabled
}) => {
	const [menuCheckbox, setMenuCheckbox] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<boolean>(false);
	const [user, setUser] = useState<FOCurrentUserDto>({});
	const context = useUserContext();
	const axiosInstance = useAxios();
	const notificationClient = new NotificationClient("", axiosInstance);
	const authClient = new AuthClient("", axiosInstance);
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	useEffect(() => {
		if (context) {
			setUser(context);
		}
	}, [context]);

	const getNotifMessages = async () => {
		try {
			const messagesNotifications = await notificationClient.getNotifications();
			setNotifications(messagesNotifications?.aMessageNonLu);
		} catch (error) {
		}
	}

	useEffect(() => {
		getNotifMessages();
	}, [])

	const logout = async () => {
		if(isStructurePendingOrDisabled) {
			navigate("/");
		} else {
			try {
				await authClient.logout();
				navigate("/");
				dispatch({ type: ActionTypeEnum.LOGOUT_USER });
				localStorage.clear();
				toast.success(t("navigation.logoutSuccess"));
			} catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}

	return (
		<header>
			<div className="structuresHeader__leftPart">
				{isStructurePendingOrDisabled ? (
					<ColoredLogo />
				) : (
					<Link to="/Structures">
						<ColoredLogo />
					</Link>
				)}
			</div>

			{
				<div className={clsx("structuresHeader__rightPart", {
					"structuresHeader__rightPart--isOpen": menuCheckbox
				})}>
					{!isStructurePendingOrDisabled && (
						<>
							<i className="fas fa-search"></i>

							{window.location.pathname !== "/Structures" &&
								<span className="structuresHeader__empty-space"></span>
							}

							<MenuBurger
								menuCheckbox={menuCheckbox}
								setMenuCheckbox={setMenuCheckbox}
								logout={logout}
								notifications={notifications}
							/>

							{window.location.pathname === "/Structures" && user.statutStructure !== StatutStructure.Partial
								? <SearchBar />
								: <div className="searchBar"></div>
							}

							<div
								className={clsx("structures__mailbox", {
									"structures__mailbox--hasNotif": notifications
								})}
							>
								<Link to="/Structures/Messagerie">{t("navigation.mailbox")}</Link>
							</div>

							<div className="structuresAccount">
								<Link to="/Compte/Structure">
									<p>{t("navigation.myAccount")}</p>
								</Link>
							</div>
						</>
					)}

					<div className="structures__lang">
						<Lang />
					</div>

					<div className="structuresAccount" onClick={logout} >
						<p>{t("navigation.logout")}</p>
					</div>
				</div>
			}
		</header>
	)
};
export default StructuresHeader;