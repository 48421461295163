import { Dictionary } from 'adel-shared/dist/models';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useMemo, useState, useContext } from "react";
import Loader from "react-loader-spinner";
import { RouteComponentProps } from "@reach/router";
import NoFolder from "../../../assets/img/undraw-not-found.png";
import Filters from "../../../components/basics/Filters";
import { useAxios } from '../../../custom-hooks/useAxios';
import { DossierClient } from "../../../services/generated/FrontOffice-api";
import InfiniteScroll from '../../basics/InfiniteScroll';
import StructuresFolderCard from './StructuresFolderCard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../../../context/AppContext';
import { ActionTypeEnum } from '../../../context/ActionType';
import { CategorieDossier } from 'adel-shared/dist/enums/CategorieDossier';

interface StructuresContentProps extends RouteComponentProps {
	categorieTypeImgDic: Dictionary<any>;
}

const StructuresContent: FunctionComponent<StructuresContentProps> = ({categorieTypeImgDic}) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const dossierClient = new DossierClient("", axiosInstance);
	const [{dossiers = {}, noDossiersMessage}, dispatch] = useContext(AppStateContext);
	const [filters, setFilters] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchDossiers = useMemo(() => _.debounce((filters: string, sort: string, page: number, pageSize: number, disabledPagination: boolean) => {
		dossierClient.getDossiers(filters, sort, page, pageSize, disabledPagination).then(result => {
			dispatch({
				type: ActionTypeEnum.SET_DOSSIERS_LIST_SUCCESS,
				payload: { dossiers: result }
			});
			setIsLoading(false)
		});
	}), [filters]);

	useEffect(() => {
		(async () => {
			try {
            	await debounceFetchDossiers(filters, "", 1, 10, true);
			} catch(error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
        })()
	}, [filters]);

	return <>
		<div className="folders__filters">
			<Filters filters={filters} setFilters={setFilters} />
		</div>

		<InfiniteScroll classNames={["folders__grid--layout"]} callback={() => {} /**nextFolderCard() TODO: A corriger*/} >
			{isLoading ?
				<div className="folders__loader">
					<Loader
						type="TailSpin"
						width={50}
						height={50}
						color="#d93943"
					/>
				</div>
				:
				<div className="folders__grid">
					{dossiers && dossiers.items?.length === 0 ?
						<div className="noFolder">
							<div className="noFolder__item">
								<img src={NoFolder} />
								<p>{noDossiersMessage}</p>
							</div>
						</div>
						:
						dossiers && dossiers.items?.map(dossier => (
							<React.Fragment key={dossier.id}>
								<StructuresFolderCard
									dossier={dossier}
									messages={0} //TODO: câbler plus tard avec les messages
									categorieTypeImg={categorieTypeImgDic[dossier.categorieCode]}
									alert={false} // TODO: câbler ça
									actions="" // TODO: câbler ça
									debounceFetchDossiers={debounceFetchDossiers}
								/>
							</React.Fragment>
						))
					}
				</div>
			}
		</InfiniteScroll>
	</>
};
export default StructuresContent;