import React from 'react';
import moment from 'moment';
const Messages = ({ createdAt, nom, prenom, fromMe, contenu, piecesJointes }) => {
    return (React.createElement("div", { className: 'message' },
        React.createElement("div", { className: fromMe ? 'messageTopInfos messageTopInfosRight' : 'messageTopInfos messageTopInfosLeft' },
            React.createElement("div", { className: 'messageTopInfosAuthor' }, nom + ' ' + prenom + ' - '),
            React.createElement("div", { className: 'messageTopInfosTime' }, moment(createdAt).format('H:mm'))),
        React.createElement("div", { className: fromMe ? 'messageContent messageContentRight' : 'messageContent messageContentLeft' },
            React.createElement("div", { className: 'messageContentText' },
                React.createElement("p", { dangerouslySetInnerHTML: { __html: contenu } }),
                piecesJointes && piecesJointes.length > 0 &&
                    React.createElement("ul", { className: 'message__files' }, piecesJointes.map((pj, index) => {
                        return React.createElement("li", { key: index },
                            React.createElement("a", { target: "_blank", href: pj.uri },
                                React.createElement("i", { className: "far fa-file" }),
                                " ",
                                pj.fileName));
                    }))))));
};
export default Messages;
