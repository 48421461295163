// Note: Step values are used in the Inscription routes
enum SignupStep {
	One = "Step1",
	Two = "Step2",
	// Three = "Step3",
	Four = "Step3",
	Five = "Step4",
	Six = "Step5",
	Seven = "Step6",
	Height = "Step7",
	CreatePassword = "/validation-mail",
	ResetPassword = "/password-reset"
};

export default SignupStep;