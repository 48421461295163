import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface EscaleCheckboxProps {
	name: string;
	reference?: any;
	onChange: (value:React.ChangeEvent<HTMLInputElement>) => void;
	value?:string;
}

const EscaleCheckbox: React.SFC<EscaleCheckboxProps> = ({
	name,
	reference,
	onChange,
	value
}) => {
	const { t } = useTranslation();

	return (
		<div className="input destinationCheckbox">
			<div className="InputRadioYesNo">
				<div className="inputRadioButton">
					<input type="radio"
						name={name}
						id={name + "-yes"}
						value={"true"}
						ref={reference}
						onChange={e => onChange(e)}
						checked={value === "true"}
					/>
					<label htmlFor={name + "-yes"} className="inputRadioButton__label">
						{value === "true" && value
							? <i className="fa fa-check-circle"></i>
							: <i className="far fa-circle"></i>
						} {t("common.yes")}
					</label>
				</div>

				<div className="inputRadioButton">
					<input type="radio"
						name={name}
						id={name + "-no"}
						value={"false"}
						ref={reference}
						checked={value === "false"}
						onChange={e => onChange(e)}
					/>
					<label htmlFor={name + "-no"} className="inputRadioButton__label">
						{value === "false" && value
							? <i className="fa fa-check-circle"></i>
							: <i className="far fa-circle"></i>
						} {t("common.no")}
					</label>
				</div>
			</div>
		</div>
	);
}

export default EscaleCheckbox;