export interface FileRef {
    id: string;
	url?: string;
	uri?:string;
    fileName: string;
}

export const isFileRef = (answer : any) => {
    if (!answer || answer instanceof File) {
        return false;
    } else if (typeof answer === "object" && answer.id && (answer.url || answer.uri) && answer.fileName) {
        return true;
    }
    return false;
}
