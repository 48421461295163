import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toaster = (): JSX.Element => {
	return (
		<ToastContainer
			position="bottom-right"
			autoClose={5000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			draggable
			pauseOnHover
			className="customToastify"
		/>
	);
}

export default Toaster;