import React, { useEffect, useState, forwardRef, useImperativeHandle, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { useForm, Controller } from 'react-hook-form';
import { debounce } from 'lodash';
import { useAxios } from '../../../../custom-hooks/useAxios';
import { toast } from "react-toastify";
import { SalarieFormMode } from '../SalarieArtistes';
import InputCalendarManual from 'adel-shared/dist/components/basics/InputCalendarManual';
import InputCalendarLabelled from 'adel-shared/dist/components/basics/InputCalendarLabelled';
import InputFileSingle, { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import { Equipements, TypesAdresses, SalarieDto, GeoAdresseDto, AdresseClient } from '../../../../services/generated/FrontOffice-api';
import { AdelDossierClient, CreateSalarieDto } from '../../../../clients/AdelDossierClient';
import { Dictionary } from '../../../../models';
import moment from "moment";
import { normalizeDate } from "adel-shared/dist/utils/functions";

export interface EditSalarieRef {
	validateAndSend: () => Promise<boolean>;
}

interface SalarieProps {
	ref: any;
	setIsFormValid: (value: boolean) => void;
	closeView: () => void;
	currentFormMode: SalarieFormMode;
	dossierId: string;
	salarieSelected: SalarieDto;
	salarieValidator: Dictionary<any>;
	adresseValidator: Dictionary<any>;
}

const Salarie: React.FunctionComponent<SalarieProps> = forwardRef(({
	setIsFormValid,
	closeView,
	currentFormMode,
	dossierId,
	salarieSelected,
	salarieValidator = {},
	adresseValidator= {}
}, ref) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const {
		register,
		getValues,
		setValue,
		triggerValidation,
		control,
		watch,
		errors
	} = useForm<any>({});
	const adelDossierClient = new AdelDossierClient("", axiosInstance);
	const nextDay = new Date(new Date().setDate(new Date().getDate() + 1));

	/** Datas à envoyer qui ne sont pas dans le getValues() */
	const [adresses, setAdresses] = useState<TypesAdresses[]>([]);
	const [equipements, setEquipements] = useState<Equipements[]>([]);
    const [dateNaissance, setDateNaissance] = useState<Date>(null);
	const [dateEmbauche, setDateEmbauche] = useState<Date>(nextDay);
	const {
		ville,
		codePostal,
		villeAutre,
		codePostalAutre
	} = watch([
		'ville',
		'codePostal',
		'villeAutre',
		'codePostalAutre'
	]);



	/** GeoAdresse */
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);
	const searchAdresse = (input: string, isAutreAdresse:boolean) => {
		if (!!input) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);

				let searchString = `${ville} ${input}`;
				let codePostalString = codePostal;

				if(isAutreAdresse) {
					searchString = `${villeAutre} ${input}`;
					codePostalString = codePostalAutre;
				}

				adresseClient.searchAdresse(searchString, codePostalString).then(results => {
					setGeoAdresse(results);
				});
				onAdresseChange(input, isAutreAdresse);
			}
			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}
	const onAdresseChange = (label: string, isAutreAdresse:boolean) => {
		const selectedAdresse = geoAdresse.find(adresse => adresse.label === label);

		if (selectedAdresse) {
			if(isAutreAdresse) {
				setValue([
					{ line1Autre: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
					{ codePostalAutre: selectedAdresse.codePostal },
					{ villeAutre: selectedAdresse.ville }
				]);
			} else {
				setValue([
					{ line1: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
					{ codePostal: selectedAdresse.codePostal },
					{ ville: selectedAdresse.ville }
				]);
			}
		}
	}

	/** Dates */
	const handleDateNaissanceChange = (date: Date) => {
		setValue('dateNaissance', date);
		setDateNaissance(date);
	}
	const handleDateEmbaucheChange = (date: Date) => {
		setValue('dateEmbauche', date);
		setDateEmbauche(date);
	}



	/*** Equipements */
	const equipementOptions: AdelOption<Equipements>[] = useMemo(() => {
		let allOptions: AdelOption<Equipements>[] = [];

		for (let statut in Equipements) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: Equipements[statut as keyof typeof Equipements],
						label: t(`common.equipements.${Equipements[statut as keyof typeof Equipements]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const setEquipementsValue = (value:any) => { // value: AdelOption<Equipements>[]
		const result = (value as any[])?.map(o => o.value) ?? [];
		setEquipements(result);
	}
	const getOptionForValue = (value: any): any => {
        return equipementOptions.find(o => o.value == value);
    }



	/** Adresse lieu travail */
	const adresseOptions: AdelOption<TypesAdresses>[] = useMemo(() => {
		let allOptions: AdelOption<TypesAdresses>[] = [];
		for (let statut in TypesAdresses) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: TypesAdresses[statut as keyof typeof TypesAdresses],
						label: t(`common.typesAdresses.${TypesAdresses[statut as keyof typeof TypesAdresses]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const setAdressesValue = (value:any) => {
		const result = (value as any[])?.map(o => o.value) ?? [];
		setAdresses(result);
	}
	const getOptionAdresseForValue = (value: any): any => {
        return adresseOptions.find(o => o.value == value);
    }

	const isFilePDF = (value: DocumentWithFile) => {
		return value.file.type === 'application/pdf' || t('validation-messages.invalid-file-format', {formats: 'PDF'});
	};

	/** Validation et envoi */
	useEffect(() => {
		if (salarieSelected) {
			let equipementSplitted = salarieSelected.equipements.split(",") as Equipements[];
			let array:Equipements[] = [];
			equipementSplitted.map((e: Equipements) => {
				array.push(e)
			})
			setEquipements(array);

			let adressesSplitted = salarieSelected.typesAdresses.split(", ") as TypesAdresses[];
			let arrayAdresses:TypesAdresses[] = [];
			adressesSplitted.map((e: TypesAdresses) => {
				arrayAdresses.push(e)
			})
			setAdresses(arrayAdresses);

			setValue([
				{ nom: salarieSelected?.nom },
				{ prenom: salarieSelected?.prenom },
				{ salaireBrut: salarieSelected?.salaireBrut },
				{ conventionCollective: salarieSelected?.conventionCollective },
				{ email: salarieSelected.email },
				{ equipements: equipementSplitted.map(getOptionForValue) },
				{ line1: salarieSelected.adresse?.line1 },
				{ line2: salarieSelected.adresse?.line2 },
				{ codePostal: salarieSelected.adresse?.codePostal },
				{ ville: salarieSelected.adresse?.ville },
				{ adresseLieuTravail: adressesSplitted.map(getOptionAdresseForValue) }
			]);

			setDateNaissance(salarieSelected?.dateNaissance && new Date(salarieSelected?.dateNaissance));
			setDateEmbauche(salarieSelected?.dateEmbauchePrevue && new Date(salarieSelected?.dateEmbauchePrevue));

			if(salarieSelected.cv) {
				setValue('cv', {
					id: salarieSelected?.cv.id,
					fileName: salarieSelected?.cv.fileName
				});
			}

			if(salarieSelected.diplome) {
				setValue('diplome', {
					id: salarieSelected?.diplome.id,
					fileName: salarieSelected?.diplome.fileName
				});
			}
		}
	}, [salarieSelected]);

	useEffect(() => {
        if(salarieSelected && equipements.find(e => e === Equipements.Autre)) {
			setValue([
				{ autresEquipements: salarieSelected?.autresEquipements }
			]);
		}
		if(salarieSelected && adresses.find(e => e === TypesAdresses.Autre)) {
			setValue([
				{ line1Autre: salarieSelected.autreAdresse?.line1 },
				{ line2Autre: salarieSelected.autreAdresse?.line2 },
				{ codePostalAutre: salarieSelected.autreAdresse?.codePostal },
				{ villeAutre: salarieSelected.autreAdresse?.ville }
			]);
		}
	}, [salarieSelected, equipements, adresses]);



	useEffect(() => {
        setIsFormValid(true);
	}, []);

	useImperativeHandle(ref, () => ({
		async validateAndSend(): Promise<boolean> {
			const result = await triggerValidation();

			if (result) {
				const {
					line1,
					line2,
					codePostal,
					ville,
					cv,
					diplome,
					...formValues
				} = getValues();

				const dataToSend: CreateSalarieDto = {
					...formValues,
					adresse: {
						line1,
						line2,
						codePostal,
						ville
					},
					dateNaissance: normalizeDate(dateNaissance),
					dateEmbauchePrevue: normalizeDate(dateEmbauche),
					salaireBrut: parseFloat(formValues.salaireBrut),
					typesAdresses: adresses.join(','),
					equipements: equipements.join(','),
					cv: {partName: 'cv'},
					diplome: {partName: 'diplome'},
					autresEquipements: formValues.autresEquipements || ""
				}

				if(cv.id)
					dataToSend.cv = {id: cv.id};

				if(diplome.id)
					dataToSend.diplome = {id: diplome.id};

				if(adresses.find(a => a === TypesAdresses.Autre))
					dataToSend.autreAdresse = {
						line1: formValues.line1Autre,
						line2: formValues.line2Autre,
						codePostal: formValues.codePostalAutre,
						ville: formValues.villeAutre
					};

				try {
					switch(currentFormMode) {
						case SalarieFormMode.IS_CREATION:
							await adelDossierClient.createSalarie(dossierId, dataToSend, { 'cv': cv.file, 'diplome': diplome.file });
							break;
						case SalarieFormMode.IS_EDIT:
							let files = {};
							if(!cv.id) files = {...files, 'cv': cv.file};
							if(!diplome.id) files = {...files, 'diplome': diplome.file};
							await adelDossierClient.updateSalarie(salarieSelected.id, dataToSend, files);
							break;
					}
					closeView();
					return true;
				} catch (error) {
					if(error.code === 'dateEmbaucheIsNotValid') {
						const date = moment(error.additionalDetails?.dateLimite).format('DD/MM/YYYY');
						if(error.additionalDetails?.isMin) {
							toast.error(t(`createFolder.salarieArtistes.salarie.errors.dateEmbaucheShouldBeAfter`, { date }));
						} else {
							toast.error(t(`createFolder.salarieArtistes.salarie.errors.dateEmbaucheShouldBeBefore`, { date }));
						}
					} else {
						toast.error(t('errors.default'));
					}
				}
			}
			return false;
		}
	}));

	return (<>
		<div className="creationDossier__header--alt">
			<div className="navigationFil" onClick={closeView}>
				<span className="navigationFil__item">
					<i className="fas fa-chevron-left"></i>
					{t("createFolder.salarieArtistes.salarie.back")}
				</span>
			</div>
			<h3 className="title--dark">{t("createFolder.salarieArtistes.salarie.title")}</h3>
		</div>
		<div className="creationDossier__row">
			<Input name="nom"
				label={`${t('createFolder.salarieArtistes.salarie.form.nom')}`}
				type="text"
				reference={register(salarieValidator["Nom"])}
				errors={errors}
			/>
			<Input name="prenom"
				label={`${t('createFolder.salarieArtistes.salarie.form.prenom')}`}
				type="text"
				reference={register(salarieValidator["Prenom"])}
				errors={errors}
			/>
		</div>
		<div className="creationDossier__row">
			<Input name="email"
				label={`${t('createFolder.salarieArtistes.salarie.form.email')}`}
				type="text"
				reference={register(salarieValidator["Email"])}
				errors={errors}
			/>
			<div></div>
		</div>

		<div className="creationDossier__row">
			<Input name="ville"
				label={`${t('createFolder.salarieArtistes.salarie.form.ville')}`}
				type="text"
				reference={register(adresseValidator["Ville"])}
				errors={errors}
			/>
			<Input name="codePostal"
				label={`${t('createFolder.salarieArtistes.salarie.form.codePostal')}`}
				type="tel"
				reference={register(adresseValidator["CodePostal"])}
				errors={errors}
			/>
		</div>
		<div className="creationDossier__row">
			<Input name="line1"
				label={`${t('createFolder.salarieArtistes.salarie.form.adresse')}`}
				type="text"
				reference={register(adresseValidator["Line1"])}
				errors={errors}
				dataList={geoAdresse.map(adresse => adresse.label)}
				onChange={debounce((value) => searchAdresse(value, false), 1000)}
			/>
			<Input name="line2"
				label={`${t('createFolder.salarieArtistes.salarie.form.adresse2')}`}
				type="text"
				reference={register(adresseValidator["Line2"])}
				errors={errors}
			/>
		</div>
		
		<div className="creationDossier__row">
			<Controller control={control}
				name="adresseLieuTravail"
				as={({ onChange, value, name }) => (
					<InputSelect<TypesAdresses>
						name={name}
						isMulti
						label={t('createFolder.salarieArtistes.salarie.form.adresseLieuTravail')}
						classname="inputSelect inputSelect--multi"
						options={adresseOptions}
						errors={errors}
						onChange={(selectedAdresse) => {
							onChange(selectedAdresse);
							setAdressesValue(selectedAdresse);
						}}
						value={value}
						placeholder={t("common.select")}
					/>
				)}
				rules={salarieValidator?.["AutreAdresse"]}
			/>
			<div></div>
		</div>
		{adresses.find(a => a === TypesAdresses.Autre) &&
			<>
				<div className="creationDossier__row">
					<Input
						name="villeAutre"
						label={`${t('createFolder.salarieArtistes.salarie.form.ville')}`}
						type="text"
						reference={register(adresseValidator["Ville"])}
						errors={errors}
					/>
					<Input
						name="codePostalAutre"
						label={`${t('createFolder.salarieArtistes.salarie.form.codePostal')}`}
						type="number"
						reference={register(adresseValidator["CodePostal"])}
						errors={errors}
					/>
				</div>
				<div className="creationDossier__row">
					<Input
						name="line1Autre"
						label={`${t('createFolder.salarieArtistes.salarie.form.adresse')}`}
						type="text"
						reference={register(adresseValidator["Line1"])}
						errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={debounce((value) => searchAdresse(value, true), 1000)}
					/>
					<Input
						name="line2Autre"
						label={`${t('createFolder.salarieArtistes.salarie.form.adresse2')}`}
						type="text"
						reference={register(adresseValidator["Line2"])}
						errors={errors}
					/>
				</div>
			</>
		}

		<div className="creationDossier__row">
			<Controller control={control}
				name="dateNaissance"
				as={({ name }) => (
					<InputCalendarManual
						name={name}
						label={t('createFolder.salarieArtistes.salarie.form.dateNaissance')}
						onDateSelected={handleDateNaissanceChange}
						maxDate={new Date()}
						defaultDate={dateNaissance}
						errors={errors}
					/>
				)}
				rules={salarieValidator?.["DateNaissance"]}
			/>
			

			<InputCalendarLabelled
				label={t('createFolder.salarieArtistes.salarie.form.dateEmbauche')}
				onDateSelected={handleDateEmbaucheChange}
				defaultDate={dateEmbauche}
				minDate={nextDay}
			/>
		</div>

		<div className="creationDossier__row row--flexStart">
			<Input name="salaireBrut"
				label={`${t('createFolder.salarieArtistes.salarie.form.salaireBrut')}`}
				type="number"
				reference={register(salarieValidator["SalaireBrut"])}
				errors={errors}
				placeHolder={"0.00€"}
			/>
			<Controller
				control={control}
				name="cv"
				as={({ onChange, name, value }) => (
					<InputFileSingle
						label={t('createFolder.salarieArtistes.salarie.form.cv')}
						labelButton={t('common.download-file-single')}
						isRequired
						onChange={onChange}
						currentValue={value}
						name={name}
						errorMessage={errors}
						extensions={["pdf"]}
					/>
				)}
				rules={{
					...salarieValidator["CV"],
					validate: isFilePDF
				}}
			/>
		</div>
		<div className="creationDossier__row">
			<Controller
				control={control}
				name="diplome"
				as={({ onChange, name, value }) => (
					<InputFileSingle
						label={t('createFolder.salarieArtistes.salarie.form.diplome')}
						labelButton={t('common.download-file-single')}
						isRequired
						onChange={onChange}
						currentValue={value}
						name={name}
						errorMessage={errors}
						extensions={["pdf"]}
					/>
				)}
				rules={{
					...salarieValidator["Diplome"],
					validate: isFilePDF
				}}
			/>
			<div></div>
		</div>

		<div className="creationDossier__row row--flexEnd">
			<Controller control={control}
				name="equipements"
				as={({ onChange, value }) => (
						<InputSelect<Equipements>
						label={t('createFolder.salarieArtistes.salarie.form.equipements')}
						name="equipements"
						classname="inputSelect inputSelect--multi"
						options={equipementOptions}
						onChange={(value) => {
							onChange(value);
							setEquipementsValue(value);
						}}
						value={value}
						isMulti
						errors={errors}
						placeholder={t("common.select")}
					/>
				)}
				rules={salarieValidator["Equipements"]}
			/>
			{equipements.find(e => e === Equipements.Autre)
				? <Input name="autresEquipements"
					label={`${t('createFolder.salarieArtistes.salarie.form.autresEquipements')}`}
					type="text"
					reference={register(salarieValidator["AutresEquipements"])}
					errors={errors}
				/>
				: <div></div>
			}
		</div>
		<div className="creationDossier__row">
			<Input name="conventionCollective"
				label={`${t('createFolder.salarieArtistes.salarie.form.conventionCollective')}`}
				type="text"
				reference={register(salarieValidator["ConventionCollective"])}
				errors={errors}
			/>
		</div>
	</>)
});

export default Salarie;
