import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { ActionTypeEnum } from "../../../context/ActionType";
import { AppStateContext } from '../../../context/AppContext';
import { useAppDispatch } from "../../../context/context-helpers";
import { useAxios } from "../../../custom-hooks/useAxios";
import { DossierClient, DossierDto, StatutDossier } from "../../../services/generated/FrontOffice-api";
import ModalValidateCancel from "../../basics/ModalValidateCancel";
import StructuresFolderCardHeaderActions from "./StructuresFolderCardHeaderActions";
import { CategorieDossier } from 'adel-shared/dist/enums/CategorieDossier';
import useBooleanState from "adel-shared/dist/custom-hooks/useBooleanState";

interface StructuresFolderCardProps {
	dossier: DossierDto;
	messages: number;
	alert: boolean;
	actions: string;
	categorieTypeImg: string;
	debounceFetchDossiers?:(filters: string, sort: string, page: number, pageSize: number, disabledPagination: boolean) => void;
}

const StructuresFolderCard: React.FunctionComponent<StructuresFolderCardProps> = ({
	dossier: {
		id: dossierId,
		nom,
		categorieId,
		categorieCode,
		categorieNom,
		numero,
		canVersement,
		convention,
		courrierAgreement,
		statut,
		lettreRefus,
		blocageAvis
	},
	messages,
	alert,
	actions,
	categorieTypeImg,
	debounceFetchDossiers
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const axiosInstance = useAxios();
	const [isDeleteModalOpened, showDeleteModal, closeDeleteModal] = useBooleanState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [context] = useContext(AppStateContext);
	const client = new DossierClient("", axiosInstance);

	const handleView = () => {
		navigate(`/Dossiers/Details/${dossierId}`);
	};

	const onEdit = () => {
		dispatch({
			type: ActionTypeEnum.EDIT_DOSSIER,
			payload: {
				dossierId,
				categorieId,
				numero
			}
		});
		navigate(`/Dossiers/Creation`);
	}

	const onAbandon = () => {
		dispatch({ type: ActionTypeEnum.DELETE_DOSSIER_REQUEST });
		(async function () {
			setIsDeleteLoading(true);
			try {
				await client.deleteDossier(dossierId);
				closeDeleteModal();
				setIsDeleteLoading(false);
				dispatch({
					type: ActionTypeEnum.DELETE_DOSSIER_SUCCESS,
					payload: { dossierId }
				});
				await debounceFetchDossiers("", "", 1, 10, true);
			} catch (error) {
				setIsDeleteLoading(false);

				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		})()
	}

	const goToVersement = useCallback(() => {
		if (canVersement) {
			dispatch({
				type: ActionTypeEnum.EDIT_DOSSIER,
				payload: {
					dossierId,
					categorieId,
					categorieCode: categorieCode as CategorieDossier,
					nom,
					numero
				}
			});
			navigate(`/Versements/${dossierId}`);
		}
	}, [dispatch, navigate]);

	const handleDuplicate = async() => {
		try {
			const { id, categorieId, nom, numero } = await client.duplicationDossier(dossierId);
			dispatch({
				type: ActionTypeEnum.EDIT_DOSSIER,
				payload: {
					dossierId: id,
					categorieId,
					nom,
					numero
				}
			})
			navigate('/Dossiers/Creation');
		} catch(error) {
			if(error.code === 'categorieDossierQuotaExceeded') {
				toast.error(t('createFolder.type.quotaAtteintError'));
			} else {
				toast.error(t('errors.default'));
			}

			if (error.exception?.message)
				toast.error(error.exception.message);
		}
	};

	return (
		<>
			<div className="folderCard">
				<div className="folderCard__header">
					<div className={`structures__foldercardStatus structures__foldercardStatus--${statut}`}>
						{t(`statutDossier.${statut}`)}
					</div>
					{statut === StatutDossier.Brouillon ? (
						<StructuresFolderCardHeaderActions
							messages={messages}
							onView={handleView}
							onDuplicate={handleDuplicate}
							onEdit={onEdit}
							onAbandon={showDeleteModal}
						/>
					) : (
						<StructuresFolderCardHeaderActions
							messages={messages}
							onView={handleView}
							onDuplicate={handleDuplicate}
						/>
					)}
				</div>

				<div className="folderInfos">
					<p>N° {numero}</p>
					<h3>{nom}</h3>
					<div>
						<img src={categorieTypeImg} alt="" />
						<p>{categorieNom}</p>
					</div>
				</div>

				<div className={`folderCard__footer ${!canVersement ? "folderCard__footer--disabled" : ""}`}>
					<div className={alert ? 'alert' : ''}>
						{alert && <i className="fas fa-exclamation-triangle"></i>}
						<div>{actions}</div>
					</div>
					<div className="folderCard__footerRightPart">
						<span className="separator"></span>
						{convention && blocageAvis === false && (
							<>
								<span className="folderCard__footerButton folderCard__footerButton--convention">
									<a href={convention} target="_blank" rel="">
										<i className="fas fa-file"></i>
									</a>
								</span>
								<div className="folderCard__footerTooltip folderCard__footerTooltip--convention">
									{t("versements.convention")}
								</div>
							</>
						)}
						{courrierAgreement && blocageAvis === false && (
							<>
								<span className="folderCard__footerButton folderCard__footerButton--ca">
									<a href={courrierAgreement} target="_blank" rel="">
										<i className="fas fa-envelope"></i>
									</a>
								</span>
								<div className="folderCard__footerTooltip folderCard__footerTooltip--ca">
									{t("versements.courrierAgrement")}
								</div>
							</>
						)}
						{lettreRefus && blocageAvis === false && (
							<>
								<span className="folderCard__footerButton folderCard__footerButton--ca">
									<a href={lettreRefus} target="_blank" rel="">
										<i className="far fa-envelope"></i>
									</a>
								</span>
								<div className="folderCard__footerTooltip folderCard__footerTooltip--ca">
									{t("versements.courrierAgrement-refuse")}
								</div>
							</>
						)}
						{(statut === StatutDossier.Accepte || statut === StatutDossier.Solde || statut === StatutDossier.Reaffecte) && blocageAvis === false
						&& <>
								<span className="folderCard__footerButton folderCard__footerButton--versement" onClick={goToVersement}>
									<i className="far fa-euro-sign"></i>
								</span>
								<div className="folderCard__footerTooltip folderCard__footerTooltip--versement">
									{t("versements.noAccess")}
								</div>
							</>
						}
					</div>
				</div>
			</div>
			<ModalValidateCancel
				isOpen={isDeleteModalOpened}
				onCancel={closeDeleteModal}
				onValidate={onAbandon}
				validateButtonLabel={t('common.delete')}
				isLoading={isDeleteLoading}
			>
				<div className="modal__item">
					<p>{t('structure.dossiers.delete')}</p>
				</div>
			</ModalValidateCancel>
		</>
	)
};

export default StructuresFolderCard;