import React, { useState } from "react";
import InputRadioButton from './InputRadioButton';
import { useTranslation } from "react-i18next";
function InputRadioYesNo({ name, label, radioLabels, onChange, value, errorMessage }) {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(value);
    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
            setChecked(value);
        }
    };
    return (React.createElement("div", { className: "input" },
        React.createElement("label", null, label),
        React.createElement("div", { className: "InputRadioYesNo" },
            React.createElement(InputRadioButton, { name: name, label: (radioLabels === null || radioLabels === void 0 ? void 0 : radioLabels[0]) || t("common.yes"), id: name + "-yes", onChange: (e) => e.target.checked && handleChange(true), checked: value === undefined ? false : checked }),
            React.createElement(InputRadioButton, { name: name, label: (radioLabels === null || radioLabels === void 0 ? void 0 : radioLabels[1]) || t("common.no"), id: name + "-no", onChange: (e) => e.target.checked && handleChange(false), checked: value === undefined ? false : !checked })),
        errorMessage &&
            React.createElement("p", { className: "input__errorMessage" }, errorMessage)));
}
export default InputRadioYesNo;
