import React, { useState, useEffect, useMemo } from "react"
import StructuresFolderAddButton from "../../components/structures/dossiers/StructuresFolderAddButton"
import Chip from "../../components/basics/Chip"
import Tooltip from "../basics/Tooltip"
import { AdelOption } from "./InputSelect"
import { FilterType } from "../../enums/DossierFilterTypes"
import ChipAdelOption from "../../models/ChipAdelOption"
import { useTranslation } from 'react-i18next'
import { typeFilterKey, statutFilterKey, dateFilterKey } from "../../constants/filters.constants"
import { StructureClient, StatutDossier } from "../../services/generated/FrontOffice-api"
import { useAxios } from '../../custom-hooks/useAxios';

interface FiltersProps {
	filters: any,
	setFilters: any
}

const Filters: React.SFC<FiltersProps> = ({
	filters,
	setFilters
}) => {

	const { t } = useTranslation();
	const [chips, setChips] = useState<ChipAdelOption[]>([]);
	/** The x position of the tooltip */
	const [tooltipPosX, setTooltipPosX] = useState<number>(0);
	/** The y position of the tooltip */
	const [tooltipPosY, setTooltipPosY] = useState<number>(0);
	/** The visibility of the tooltip */
	const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

	/** Tableau de dates */
	const [dates, setDates] = useState<Date[]>([new Date, new Date]);
	/** Plage de dates */
	const [dateRange, setDateRange] = useState<boolean>(false);


	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);

	const updateTooltipPosition = (element: any) => {
		const posX = element.getBoundingClientRect().left + (element.offsetWidth / 2);
		const posY = element.getBoundingClientRect().top + element.offsetHeight + 30;

		setTooltipPosX(posX);
		setTooltipPosY(posY);
	}

	const statutOptions: AdelOption<string>[] = useMemo(() => {
		return Object.values(StatutDossier).filter(value => { return value !== "none" }).map(value => {
			return { label: t(`statutDossier.${value}`), value: value }
		});
	}, []);
	const [typeOptions, setTypeOptions] = useState<AdelOption<string>[]>([]);

	useEffect(() => {
		structureClient.getStructureCategoriesDossier().then((result) => {
			let newData: any = [...result]
			newData.forEach(function (element: any) {
				element.value = element.id;
				element.label = element.nom;
			})
			newData.unshift(
				{
					value: "all",
					label: t('filterAll')
				})
			setTypeOptions(newData)
		})
	}, [])

	const addChip = (filter: AdelOption<string>, filterType: FilterType) => {
		var chipToAdd: ChipAdelOption = { ...filter, type: filterType };
		const updateChips = [...chips]
		let index = -1;
		let i = 0;
		updateChips.forEach((element) => {
			if (element.type === FilterType.Date && filterType === FilterType.Date) {
				index = i;
			}
			if (element.type === FilterType.Statut && filterType === FilterType.Statut) {
				index = i;
			}
			if (element.type === FilterType.Categorie && filterType === FilterType.Categorie) {
				index = i;
			}
			i++;
		})
		if (index !== -1) {
			updateChips.splice(index, 1);
		}
		if (updateChips.filter(chip => chip.value == chipToAdd.value).length == 0) {
			setChips([...updateChips, chipToAdd])
		}
	}

	const removeChip = (option: ChipAdelOption) => {
		const newChips = chips.filter(chip => chip.value !== option.value);
		setChips(newChips);
	};

	const onTooltipVisibleChange = (visible: any) => {
		setTooltipVisible(visible);
	}

	useEffect(() => {

		let statutFilter = "";
		let typeFilter = "";
		let datesFilter = "";

		chips.forEach((item) => {
			if (item.type === 'Statut') {
				statutFilter = `${statutFilterKey}${item.value},`;
			}
			if (item.type === 'Catégorie') {
				typeFilter = `${typeFilterKey}${item.value},`;
			}
			if (item.type === 'Date') {
				datesFilter = `${dateFilterKey}${item.value},`;
			}
		})

		const updateFilters: string = `${statutFilter}${typeFilter}${datesFilter}`;
		setFilters(updateFilters)
	}, [chips])

	return (
		<>
			<div className={'filters'}>
				<div className={'filters__title'}>
					<p>{t('filter')}</p>
				</div>

				<div className={'filters__chips'}>
					{chips.map((chipOption, index) => (
						<Chip
							key={index}
							option={chipOption}
							onRemoveChip={removeChip}
							dateRange={dateRange}
							dates={dates}
						/>
					))}

					<div className={'filters__addButton'} onClick={(e: any) => {
						setTooltipVisible(!tooltipVisible);
						updateTooltipPosition(e.target);
					}}>
						<StructuresFolderAddButton colorInverted={true} />
					</div>
				</div>

				<div className={'filters__tooltip'}>
					{tooltipVisible &&
						<Tooltip
							onSelectFilter={addChip}
							onTooltipVisibleChange={onTooltipVisibleChange}
							visible={tooltipVisible}
							statutOptions={statutOptions}
							categoriesOptions={typeOptions}
							dates={dates}
							setDateRange={setDateRange}
						/>
					}
				</div>
			</div>
		</>
	)
}

export default Filters;