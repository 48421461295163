import React from "react"
import { Link } from "@reach/router";
import SignupStep from "../../context/SignupSteps";

interface SignupProps {
    title: string,
    linkLabel: string,
}

const Signup: React.SFC<SignupProps> = ({ title, linkLabel }) => {
    return (
        <section className="signup__CTA">
            <span>{title}</span>
            <Link to={`/Inscription/${SignupStep.One}`}>
                <h4>{linkLabel}</h4>
            </Link>
        </section>
    )
}

export default Signup;