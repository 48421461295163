import React, { FunctionComponent, useState } from "react";
import QuestionInputProps from "./QuestionInputProps";
import {useTranslation} from "react-i18next";
import {FileRef, isFileRef} from "../../../../models/FileRef";

type FileAnswer = File | FileRef;

interface FileQuestionInputProps extends QuestionInputProps{
    name?: string;
    setIsValideDocuments: (value :any) => void;
}

const FileQuestionInput: FunctionComponent<FileQuestionInputProps> = (props) => {
    

    const {t} = useTranslation();
    const [errorValidation, setErrorValidation] = useState('')
    
    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {        
        
        const file = e.target.files.length !== 0
            ? e.target.files[0]
            : null;

        if (file?.name.length > 100) {
            props.setIsValideDocuments(false)
            setErrorValidation('Le nom du fichier ne doit pas excéder 100 caractères.')
        }else {
            setErrorValidation('')
            props.setIsValideDocuments(true)
        }
        if (props.onAnswerChange) {
            props.onAnswerChange(props.question?.id, file);
        }
    }

    const downloadFile = () => {
        if (props.currentValue && !(props.currentValue instanceof File) && props.currentValue?.url) {
            window.open(props.currentValue.url);
        }
    }

    let extensions = props.question?.fileExtensions
        ? props.question.fileExtensions.map(e => e.startsWith('.') ? e : '.' + e).join(',')
        : null;

    if (props.currentValue instanceof File) {        
        
		return <div className="questionField">
			{props.title &&
				<div className="questionField__title"><span dangerouslySetInnerHTML={{__html: props.title}} /> 
                   {props.question.isRequired && "*"}         
                   {props.question?.model && 
                   <button onClick={()=>window.open(props.question?.model)} className="templates__button" style={{fontSize : 14, marginLeft: 5}}>Récupérer le modèle à remplir</button>}                
                </div>
			}
            <div className="inputFile__item">
                <div className="inputFile__infos">
                    <div className="inputFile__infos--file">
						<span className="questionField__fileName">{props.currentValue?.name}</span>
						<span onClick={() => {
								if (props.onAnswerChange)
									props.onAnswerChange(props.question?.id, undefined);
							}}>
							<i className="far fa-trash-alt"></i>
						</span>
                    </div>
                </div>
			</div>
			{(errorValidation || (props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '')) &&
				<p className="input__errorMessage">{errorValidation || props.errorMessage.message}</p>
			}
        </div>;
    } else if (isFileRef(props.currentValue)) {
		return <div className="questionField">
		{props.title &&
			<div className="questionField__title"><span dangerouslySetInnerHTML={{__html: props.title}} />
            {props.question.isRequired && "*"}
            {props.question?.model && 
                   <button onClick={()=>window.open(props.question?.model)} className="templates__button" style={{fontSize : 14, marginLeft: 5}}>Récupérer le modèle à remplir</button>}                
            </div>
		}
            <div className="inputFile__item">
                <div className="inputFile__infos">
                    <div className="inputFile__infos--file">
                        <span className={props.currentValue.url ? "inputFile__infos--fileName" : ""} onClick={() => downloadFile()}>{props.currentValue?.fileName}</span>
						<span onClick={() => {
								if (props.onAnswerChange)
									props.onAnswerChange(props.question?.id, undefined);
							}}>
							<i className="far fa-trash-alt"></i>
						</span>
					</div>
                </div>
			</div>
			{props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '' &&
				<p className="input__errorMessage">{props.errorMessage.message}</p>
			}
        </div>;
    } else {
        return <div className="questionField">
		{props.title &&
			<div className="questionField__title"><span dangerouslySetInnerHTML={{__html: props.title}} />
            {props.question.isRequired && "*"}                
            {props.question?.model && 
                   <button onClick={()=> window.open(props.question?.model)} className="templates__button" style={{fontSize : 14, marginLeft: 5}}>Récupérer le modèle à remplir</button>}                
            </div>
		}
            <div className="inputFile__buttons">
                <label htmlFor={props.question ? props.question.id : props.name}>{props.question?.model ? "Déposer le fichier complété" : t("common.download-file")}</label>
                <input type="file"
                       name={props.question ? props.question.id : props.name}
                       id={props.question ? props.question.id : props.id }
                       accept={extensions}
                       required={props.question?.isRequired}
                       onChange={onFileChange}/>
			</div>
			{props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '' &&
				<p className="input__errorMessage">{props.errorMessage.message}</p>
			}
        </div>
    }
}

export default FileQuestionInput;