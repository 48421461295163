import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LieuValidationError } from '../../../../services/generated/FrontOffice-api';
import ModalErrorInformation from '../../../basics/ModalErrorInformation';

interface ModalValidationLieuxDatesProps {
	isOpen: boolean;
	onCancel: () => void;
	isLoading?: boolean;
	errorsValidation: LieuValidationError[];
}

const ModalValidationLieuxDates: React.FunctionComponent<ModalValidationLieuxDatesProps> = ({
	isOpen,
	onCancel,
	isLoading,
	errorsValidation
}) => {
	const { t } = useTranslation();

	return (
		<ModalErrorInformation
			isOpen={isOpen}
			onCancel={onCancel}
			isLoading={isLoading}
			title={t('common.validation-error.title')}
			cancelButtonLabel={t('common.return')}
		>
			<div className="modal__item">
					{errorsValidation?.map((e, i) => (
						<ul className="modal__itemErrors" key={i}>
							{e.min && <li>{t('createFolder.lieuxDates.errors.minWithCount', {count: e.min})}</li>}
							{e.max && <li>{t('createFolder.lieuxDates.errors.maxWithCount', {count: e.max})}</li>}

							{!e.types && e.maxDuration && <li>{t('createFolder.lieuxDates.errors.maxDurationSolo', {number: e.maxDuration})}</li>}
							{e.types && e.maxDuration && <li>{t('createFolder.lieuxDates.errors.maxDuration', {number: e.maxDuration})}</li>}

							{e.minPrestation && e.withDifferentPlace && <li>{ t('createFolder.lieuxDates.errors.withDifferentPlace', {number: e.minPrestation})}</li>}
							{e.minPrestation && !e.withDifferentPlace && <li>{t('createFolder.lieuxDates.errors.minPrestation', {number: e.minPrestation})}</li>}
                            
							{e.maxMonthDuration && <li>{t('createFolder.lieuxDates.errors.maxMonthDuration', {number: e.maxMonthDuration})}</li>}
                            
							{!e.types && e.minDate && <li>{t('createFolder.lieuxDates.errors.minDateSolo', {date: moment(e.minDate).format('DD/MM/YYYY')})}</li>}
							{e.types && e.minDate && <li>{t('createFolder.lieuxDates.errors.minDate', {date: moment(e.minDate).format('DD/MM/YYYY')})}</li>}

							{!e.types && e.maxDate && <li>{t('createFolder.lieuxDates.errors.maxDateSolo', {date: moment(e.maxDate).format('DD/MM/YYYY')})}</li>}
							{e.types && e.maxDate && <li>{t('createFolder.lieuxDates.errors.maxDate', {date: moment(e.maxDate).format('DD/MM/YYYY')})}</li>}

							{e.allRepresentationsMustBeDuringEvenement && <li>{t('createFolder.lieuxDates.errors.allRepresentationsMustBeDuringEvenement')}</li>}
							{!e.withDifferentPlace && e.types?.map((type, i) => (
								<li key={i}>
									{t('createFolder.lieuxDates.errors.types')}
									{t(`createFolder.lieuxDates.${type}`)}{(e.types.length > 1 && i < (e.types.length - 1)) && ', '}
								</li>
							))}
						</ul>
					))}
			</div>
		</ModalErrorInformation>
	);
}

export default ModalValidationLieuxDates;