import { Context, DispatchAction } from "./context";
import {
	ActionTypeEnum,
	CanCreateStructure,
	ErrorOccurredAction,
	SetSignupHighestStepAction,
	SetSignupStepFiveAction,
	SetSignupStepFourAction,
	SetSignupStepOneAction,
	SetSignupStepSevenAction,
	SetSignupStepSixAction,
	SetSignupStepSixActionDocumentDate,
	SetSignupStepSixActionDocumentFile,
	SetSignupStepSixActionDocumentExtraInfo,
	SetSignupStepThreeAction,
	SetSignupStepTwoAction,
	SetUserAction,
	UpdateStructureAction,
	UpdateStructureInfosAction,
	CreateDossierSuccessOrUpdateAction,
	EditDossierAction,
	DeleteDossierSuccessAction,
	CreateVersementSuccessOrUpdateAction,
	EditVersementAction,
	UpdateStructureAddressAction,
	SetPagesVitrineAction,
	SetSignupStepSixActionDocumentType,
	SetDossiersListAction,
	SearchDossiersAction
} from './ActionType';
import { defaultContextValue } from "./InitializeContextFromStorage";

/**
 * The app context reducer function.
 *
 * @param   {Context.IContext} state The previous state value.
 * @param   {DispatchAction}   dispatchedAction The action.
 * @returns {Context.IContext} The updated state.
 */
const appReducer = (state: Context.IContext, dispatchedAction: DispatchAction): Context.IContext => {
	switch (dispatchedAction.type) {
		case ActionTypeEnum.UPDATE_STRUCTURE:
			return { ...state, structure: (dispatchedAction as UpdateStructureAction).payload };
		case ActionTypeEnum.UPDATE_STRUCTURE_INFOS:
			return {
				...state,
				structure: {
					...state.structure,
					informations: (dispatchedAction as UpdateStructureInfosAction).payload.informations
				}
			};
		case ActionTypeEnum.UPDATE_STRUCTURE_ADDRESS:
			const payloadAddress = (dispatchedAction as UpdateStructureAddressAction).payload;
			return { ...state, structure: {
				...state.structure,
				adresseCorrespondance: !payloadAddress.parDefaut ? payloadAddress.autre : null,
				codeLangue: payloadAddress.codeLangue
			}};
		case ActionTypeEnum.SET_USER:
			return { ...state, user: (dispatchedAction as SetUserAction).payload }
		case ActionTypeEnum.UNSET_USER:
			return { ...state, user: {} }
		case ActionTypeEnum.LOGOUT_USER:
			return defaultContextValue;
		case ActionTypeEnum.SET_SIGNUP_STEP_ONE:
			return { ...state, signupStepOne: (dispatchedAction as SetSignupStepOneAction).payload }
		case ActionTypeEnum.SET_SIGNUP_STEP_TWO:
			return { ...state, signupStepTwo: (dispatchedAction as SetSignupStepTwoAction).payload }
		case ActionTypeEnum.SET_SIGNUP_STEP_THREE:
			return { ...state, signupStepThree: (dispatchedAction as SetSignupStepThreeAction).payload }
		case ActionTypeEnum.SET_SIGNUP_STEP_FOUR:
			return { ...state, signupStepFour: (dispatchedAction as SetSignupStepFourAction).payload }
		case ActionTypeEnum.SET_SIGNUP_STEP_FIVE:
			return { ...state, signupStepFive: (dispatchedAction as SetSignupStepFiveAction).payload }
		case ActionTypeEnum.SET_SIGNUP_STEP_SIX:
			return { ...state, signupStepSix: (dispatchedAction as SetSignupStepSixAction).payload }
		case ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_DATE: {
			const { typeDocument, extraInfoCode, dateValue } = (dispatchedAction as SetSignupStepSixActionDocumentDate).payload;
			const files = [...state.signupStepSix.files];
			const documentIndex = files.findIndex(x => x.code === typeDocument.code);

			if(documentIndex < 0) {
				files.push({
					code: typeDocument.code,
					typeId: typeDocument.id,
					extraInfos: {
						[extraInfoCode]: dateValue
					}
				});
			} else {
				if(!files[documentIndex].extraInfos) {
					files[documentIndex].extraInfos = {};
				}

				files[documentIndex].extraInfos[extraInfoCode] = dateValue;
			}
			
			return {
				...state,
				signupStepSix: {files}
			};
		}
		case ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_FILE: {
			const { typeDocument, fileValue } = (dispatchedAction as SetSignupStepSixActionDocumentFile).payload;
			const files = [...state.signupStepSix.files];
			const documentIndex = files.findIndex(x => x.code === typeDocument.code);

			if(documentIndex < 0) {
				files.push({
					code: typeDocument.code,
					typeId: typeDocument.id,
					file: fileValue
				});
			} else {
				files[documentIndex].file = fileValue;
			}

			return {
				...state,
				signupStepSix: {files}
			};
		}
		
		case ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_TYPE: {
			const { typeDocument, value } = (dispatchedAction as SetSignupStepSixActionDocumentType).payload;
			const files = [...state.signupStepSix.files];
			const documentIndex = files.findIndex(x => x.code === typeDocument.code);

			if(documentIndex < 0) {
				files.push({
					code: typeDocument.code,
					typeId: typeDocument.id,
					typeDeDocumentSelected: value
				});
			} else {
				files[documentIndex].typeDeDocumentSelected = value;
			}

			return {
				...state,
				signupStepSix: {files}
			};
		}
		case ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_EXTRA_INFO: {
			const { typeDocument, extraInfoCode, value } = (dispatchedAction as SetSignupStepSixActionDocumentExtraInfo).payload;
			const files = [...state.signupStepSix.files];
			const documentIndex = files.findIndex(x => x.code === typeDocument.code);

			if(documentIndex < 0) {
				files.push({
					code: typeDocument.code,
					typeId: typeDocument.id,
					extraInfos: {
						[extraInfoCode]: value
					}
				});
			} else {
				if(!files[documentIndex].extraInfos) {
					files[documentIndex].extraInfos = {};
				}

				files[documentIndex].extraInfos[extraInfoCode] = value;
			}
			
			return {
				...state,
				signupStepSix: {files}
			};
		}
		case ActionTypeEnum.SET_SIGNUP_STEP_SEVEN:
			return { ...state, signupStepSeven: (dispatchedAction as SetSignupStepSevenAction).payload }
		case ActionTypeEnum.SET_SIGNUP_HIGHEST_STEP:
			return { ...state, signupHighestStep: (dispatchedAction as SetSignupHighestStepAction).payload }
		case ActionTypeEnum.ERROR_OCCURRED:
			return { ...state, error: (dispatchedAction as ErrorOccurredAction).payload };
		case ActionTypeEnum.CAN_CREATE_STRUCTURE:
			return { ...state, canCreateStructure: (dispatchedAction as CanCreateStructure).payload };
		case ActionTypeEnum.REINIT_TOASTER:
			return { ...state, error: { type: "" } };
		case ActionTypeEnum.START_NEW_DOSSIER_CREATION: 
			return { ...state, currentEditingDossier: {id: "", categorieId: "", categorieCode: "", nom: "", numero: ""} }
		case ActionTypeEnum.CREATE_DOSSIER_REQUEST: return { ...state }
		case ActionTypeEnum.CREATE_DOSSIER_SUCCESS:
		case ActionTypeEnum.UPDATE_DOSSIER_STATE:
			let payload = (dispatchedAction as CreateDossierSuccessOrUpdateAction).payload;
			let newDossierStates = { ...state.dossierStates };
			newDossierStates[payload.dossierId] = payload.state;
			return { ...state, dossierStates: newDossierStates, 
							currentEditingDossier: {id: payload.dossierId, categorieId: payload.categorieId, categorieCode: payload.categorieCode } }
		case ActionTypeEnum.EDIT_DOSSIER: 
			return {
				...state,
				creationDossierResponsable: {},
				creationDossierAnswers: {},
				currentEditingDossier: {
					id: (dispatchedAction as EditDossierAction).payload.dossierId,
					categorieId: (dispatchedAction as EditDossierAction).payload.categorieId,
					categorieCode: (dispatchedAction as EditDossierAction).payload.categorieCode,
					nom: (dispatchedAction as EditDossierAction).payload.nom,
					numero: (dispatchedAction as EditDossierAction).payload.numero,
				}
			}
		case ActionTypeEnum.DELETE_DOSSIER_REQUEST: return { ...state, dossierToDelete: { ...state.dossierToDelete, isLoading: true } }
		case ActionTypeEnum.DELETE_DOSSIER_SUCCESS:
			let dossierIdToDelete = (dispatchedAction as DeleteDossierSuccessAction).payload.dossierId;
			return {
				...state,
				dossierToDelete: { dossierId: "", isLoading: false },
				dossiersNeedRefresh: true,
				// VFA: Needed to remove an entry from a Dictionary in TypeScript ?
				dossierStates: Object.fromEntries(Object.entries(state.dossierStates).filter((entry) => { if (entry[0] !== dossierIdToDelete) return true; }))
			};
		case ActionTypeEnum.DOSSIER_REFRESHED: return { ...state, dossiersNeedRefresh: false };

		case ActionTypeEnum.SET_DOSSIERS_LIST_SUCCESS: return {
			...state,
			dossiers: (dispatchedAction as SetDossiersListAction).payload.dossiers,
			noDossiersMessage: 'Aucun dossier pour le moment.'
		};

		case ActionTypeEnum.SEARCH_DOSSIERS_SUCCESS: return {
			...state,
			dossiers: (dispatchedAction as SearchDossiersAction).payload.dossiers,
			noDossiersMessage: 'Aucun résultat ne correspond à votre recherche.'
		};

		/** VERSEMENTS */
		case ActionTypeEnum.START_NEW_VERSEMENT_CREATION: return {
			...state,
			currentEditingVersement: {
				id: "",
				type: null,
				dossierId: "",
				originalDossierId: ""
			}
		}
		case ActionTypeEnum.CREATE_VERSEMENT_REQUEST: return { ...state }
		case ActionTypeEnum.CREATE_VERSEMENT_SUCCESS:
		case ActionTypeEnum.UPDATE_VERSEMENT_STATE:
			let payloadVersement = (dispatchedAction as CreateVersementSuccessOrUpdateAction).payload;
			let newVersementStates = { ...state.versementStates };
			newVersementStates[payloadVersement.versementId] = payloadVersement.state;
			return {
				...state,
				versementStates: newVersementStates,
				currentEditingVersement: {
					id: payloadVersement.versementId,
					type: payloadVersement.versementType,
					dossierId: payloadVersement.dossierId,
					originalDossierId: payloadVersement.originalDossierId,
				}
			}
		case ActionTypeEnum.EDIT_VERSEMENT: return {
			...state,
			currentEditingVersement: {
				id: (dispatchedAction as EditVersementAction).payload.versementId,
				type: (dispatchedAction as EditVersementAction).payload.versementType,
				dossierId: (dispatchedAction as EditVersementAction).payload.dossierId,
				originalDossierId: (dispatchedAction as EditVersementAction).payload.originalDossierId
			}
		}

		/** PAGES VITRINE */
		case ActionTypeEnum.GET_PAGES_VITRINE_SUCCESS: 
		return {
			...state,
			pagesVitrine: (dispatchedAction as SetPagesVitrineAction).payload.pagesVitrine
		}

		default:
			throw new Error();
	}
}

export default appReducer;