import { Link, navigate, RouteComponentProps, Router } from '@reach/router';
import React, { useState, useRef, useEffect, useContext, FunctionComponent } from "react";
import Structures from '../../../containers/structures/Structure';
import { AuthenticatedRoute } from "../../../containers/AuthenticatedRoute";
import { AppStateContext } from '../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../../custom-hooks/useAxios';
import { DemandeVersementPieceJointeViewModelDto, DossierClient, EtapeDemandeVersement, TypeVersement, VersementClient } from '../../../services/generated/FrontOffice-api';
import Subtitles from '../../basics/Subtitles';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import ValidationPart1 from '../../dossiers/creationDossier/ValidationPart1';
import clsx from 'clsx';
import { useVersementSteps } from '../../../custom-hooks/useSteps';
import ValidationPart2 from '../../dossiers/creationDossier/ValidationPart2';
import { ActionTypeEnum } from '../../../context/ActionType';
import Loader from 'react-loader-spinner';
import InformationsDossier from './etapes/InformationsDossier';
import ContratsCachets from './etapes/ContratsCachets';
import Enseignants from './etapes/Enseignants';
import Trajets from './etapes/Trajets';
import PiecesJointes from './etapes/PiecesJointes';
import Rib from './etapes/Rib';
import Recapitulatif from './etapes/Recapitulatif';
import { CategorieDossier } from '../../../enums/Dossiers';
import { toast } from 'react-toastify';

export interface StepVersementRef {
	validateForm: () => Promise<boolean>;
}

interface CreationVersementProps extends RouteComponentProps {
}

const CreationVersement: FunctionComponent<CreationVersementProps> = () => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const [{
		currentEditingVersement,
		currentEditingDossier,
		versementStates
	}, dispatch] = useContext(AppStateContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [versementId] = useState<string>(currentEditingVersement.id);
	const [dossierId] = useState<string>(currentEditingVersement.dossierId);
	const [originalDossierId] = useState<string>(currentEditingVersement.originalDossierId);
	const versementType = useRef<TypeVersement>(currentEditingVersement.type);
	const [isFormValid, setIsFormValid] = useState<boolean>(false); // Pour les boutons Valider
	const [isEditMode, setEditMode] = useState<boolean>(false);
	const categorieDossierId = useRef<string>(currentEditingDossier.categorieId);
	const codeCategorie = useRef<CategorieDossier>(currentEditingDossier.categorieCode);
	const versementClient = new VersementClient("", axiosInstance);
	const dossierClient = new DossierClient("", axiosInstance);

	/** Validations, refs & Steps */
	const { steps, getNextStep, mergeSteps, isHigherOrCurrentStep } = useVersementSteps();
	const [canGoNext, setCanGoNext] = useState<boolean>(false);
	const [currentStep, setCurrentStep] = useState<EtapeDemandeVersement>(EtapeDemandeVersement.ValidationStructure);
	const [isRedirectedFromValidationStep, setIsRedirectedFromValidationStep] = useState<boolean>(false);
	const [stepsInitialized, setStepsInitialized] = useState<boolean>(false);
	const [piecesJointes, setPiecesJointes] = useState<DemandeVersementPieceJointeViewModelDto>();
	const dynamicStepsLoaded =  steps.length > 3;


	const handleMenuItemClick = (step: EtapeDemandeVersement) => {
		return isHigherOrCurrentStep(step, currentStep) && navigateToStep(step);
	};

	// Initialize currentStep from context
	useEffect(() => {
		if (!stepsInitialized && dynamicStepsLoaded) {
			if (versementId) {
				let firstStepAfterVersementCreation = getNextStep(EtapeDemandeVersement.InformationsProjet);
				setCurrentStep(versementStates[versementId] ? versementStates[versementId]?.currentStep : firstStepAfterVersementCreation);
			}
			setStepsInitialized(true);
		}
	}, [dynamicStepsLoaded, stepsInitialized]);

	useEffect(() => {
		if (categorieDossierId && codeCategorie && versementId) {
			(async() => {
				try {
					let steps = await versementClient.getEtapesDemandeVersement(categorieDossierId.current);
					const pj = await versementClient.getPiecesJointesOnDemandeVersement(versementId);

					if (codeCategorie.current === CategorieDossier.InteretGeneral) {
						// Récupérer la liste d'artistes originelle
						const artistes = await dossierClient.getArtistes(originalDossierId, "", "", 1, 1, true);
						// Cacher si IG sans artiste dans le dossier d'origine			
						if(artistes.items.length === 0)
							steps = steps.filter(e => e !== EtapeDemandeVersement.ValidationArtiste);
					}

					if(pj.typeDeDocuments.length === 0)
						steps = steps.filter(e => e !== EtapeDemandeVersement.PieceJointe);
					
					mergeSteps(steps);
					setPiecesJointes(pj);

				} catch (error) {
					if (error.exception?.message)
						toast.error(error.exception.message);
					else
						toast.error(t('common.api-error'));
					console.error(error)
				}
			})();
		}
	}, [categorieDossierId, versementId, codeCategorie]);

	/** View mode */

	/** Menu */
	const renderMenu = () => {
		return <ul className="creationDossier__menu">
			{dynamicStepsLoaded && <>
				<li
				className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.ValidationStructure, currentStep) })}
				onClick={() => handleMenuItemClick(EtapeDemandeVersement.ValidationStructure)}
			>
				<span className="creationDossier-menu__check">
					<i className="fas fa-check"></i>
				</span>{t("creationVersement.menu.informations")}
			</li>
			<li
				className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.ValidationDocumentStructure, currentStep) })}
				onClick={() => handleMenuItemClick(EtapeDemandeVersement.ValidationDocumentStructure)}
			>
				<span className="creationDossier-menu__check">
					<i className="fas fa-check"></i>
				</span>{t("creationVersement.menu.documents")}
			</li>
			<li
				className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.InformationsProjet, currentStep) })}
				onClick={() => handleMenuItemClick(EtapeDemandeVersement.InformationsProjet)}
			>
				<span className="creationDossier-menu__check">
					<i className="fas fa-check"></i>
				</span>{t("creationVersement.menu.infosDossier")}
			</li>
			</>}

			{dynamicStepsLoaded && steps?.includes(EtapeDemandeVersement.ValidationArtiste) && (
				<li
					className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.ValidationArtiste, currentStep) })}
					onClick={() => handleMenuItemClick(EtapeDemandeVersement.ValidationArtiste)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("creationVersement.menu.contratsCachets")}
				</li>
			)}

			{dynamicStepsLoaded && steps?.includes(EtapeDemandeVersement.ValidationEnseignant) && (
				<li
					className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.ValidationEnseignant, currentStep) })}
					onClick={() => handleMenuItemClick(EtapeDemandeVersement.ValidationEnseignant)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("creationVersement.menu.enseignants")}
				</li>
			)}

			{dynamicStepsLoaded && steps?.includes(EtapeDemandeVersement.ValidationDeplacement) && (
				<li
					className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.ValidationDeplacement, currentStep) })}
					onClick={() => handleMenuItemClick(EtapeDemandeVersement.ValidationDeplacement)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("creationVersement.menu.deplacements")}
				</li>
			)}

			{dynamicStepsLoaded && steps?.includes(EtapeDemandeVersement.PieceJointe) && (
				<li
					className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.PieceJointe, currentStep) })}
					onClick={() => handleMenuItemClick(EtapeDemandeVersement.PieceJointe)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("creationVersement.menu.piecesJointes")}
				</li>
			)}

			{dynamicStepsLoaded && steps?.includes(EtapeDemandeVersement.ConfirmationRIB) && (
				<li
					className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.ConfirmationRIB, currentStep) })}
					onClick={() => handleMenuItemClick(EtapeDemandeVersement.ConfirmationRIB)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("creationVersement.menu.rib")}
				</li>
			)}

			{dynamicStepsLoaded && steps?.includes(EtapeDemandeVersement.Recapitulatif) && (
				<li
					className={clsx({"active": isHigherOrCurrentStep(EtapeDemandeVersement.Recapitulatif, currentStep) })}
					onClick={() => handleMenuItemClick(EtapeDemandeVersement.Recapitulatif)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("creationVersement.menu.recap")}
				</li>
			)}
		</ul>
	}


	const refs: { [key: string]: React.MutableRefObject<StepVersementRef>; } = {
		[EtapeDemandeVersement.ValidationStructure]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.ValidationDocumentStructure]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.InformationsProjet]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.ValidationArtiste]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.ValidationEnseignant]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.ValidationDeplacement]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.PieceJointe]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.ConfirmationRIB]: useRef<StepVersementRef>(null),
		[EtapeDemandeVersement.Recapitulatif]: useRef<StepVersementRef>(null),
	};

	useEffect(() => {
		if(stepsInitialized) {
			dispatch({ type: ActionTypeEnum.UPDATE_VERSEMENT_STATE, payload: {
				versementId,
				versementType: versementType.current,
				dossierId,
				originalDossierId,
				state: { currentStep: currentStep }
			}});
			navigate(`/Versements/Creation/${currentStep}`);
		}
	}, [currentStep, stepsInitialized]);

	const nextStep = async () => {
		if (refs[currentStep] && refs[currentStep].current) {
			
			const isValid = await refs[currentStep].current.validateForm() == false ? false : true;
			const nextStep = getNextStep(currentStep);
			// Si le currentStep est valide OU si le currentStep est valide et n'a pas de changement au niveau de ses documents 
			if (isValid && !(currentStep == EtapeDemandeVersement.Recapitulatif)) {
				setCurrentStep(nextStep);
			}
			if(!isValid){
				if (!(currentStep == EtapeDemandeVersement.InformationsProjet && codeCategorie?.current == "PROMO")){
					toast.error(t("common.validation-error.message"));
				}
			}
		} else {
			console.error("nextStep was called but references were not initialized. Please contact your Dev Team.");
		}
	}
	const navigateToStep = async (targetStep: EtapeDemandeVersement): Promise<void> => {
		setIsRedirectedFromValidationStep(false); // reinit value
		// If trying to access to current step : do nothing
		if (targetStep === currentStep) return;

		// If trying to access to already filled step, allow navigation
		if (isHigherOrCurrentStep(targetStep, currentStep)) {
			setIsRedirectedFromValidationStep(true);
			setCurrentStep(targetStep);
			return;
		}
		await nextStep();
	}

	/** View mode */
	const goBackToViewMode = async () => {
		if (refs[currentStep]?.current) {
			let isValid = await refs[currentStep].current.validateForm();
			if (isValid) setEditMode(false);
		}
	}



	// Validate button label
	const labelValidateButton = () => {
		switch(currentStep) {
			case EtapeDemandeVersement.Recapitulatif:
				return t('createFolder.buttons.submit');
			default:
				return t('createFolder.buttons.next');
		}
	}

	return (
		<AuthenticatedRoute>
			<Structures>
				<div className="creationDossier">
					<span className="navigationFil">
						<Link to={`/Versements/${originalDossierId}`}>
							<span className="navigationFil__item">
								{t("navigation.versements")}
							</span>
						</Link>
						<span className="navigationFil__separator">|</span>
						<span className="navigationFil__item">{currentEditingDossier.nom} - {currentEditingDossier.numero}</span>
						<span className="navigationFil__separator">|</span>
						<span className="navigationFil__item"> {t("navigation.demandeVersement")} </span>
					</span>

					<Subtitles title={t("creationVersement.title")} />

					<div className="creationDossier__wrapper">
						{renderMenu()}

						<div className="creationDossier__content">
							<Router>
								{steps?.includes(EtapeDemandeVersement.ValidationStructure) && (
									<ValidationPart1
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.ValidationStructure}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.ValidationDocumentStructure) && (
									<ValidationPart2
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.ValidationDocumentStructure}
										setCanGoNext={setCanGoNext}
										validateOnLoad={isRedirectedFromValidationStep}
										setIsLoading={setIsLoading}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.InformationsProjet) && (
									<InformationsDossier
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.InformationsProjet}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										isLoading={isLoading}
										versementId={versementId}
										dossierId={dossierId}
										setCurrentStep={setCurrentStep}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.ValidationArtiste) && (
									<ContratsCachets
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.ValidationArtiste}
										setIsFormValid={setIsFormValid}
										setEditMode={setEditMode}
										setCanGoNext={setCanGoNext}
										canGoNext={canGoNext}
										setIsLoading={setIsLoading}
										isLoading={isLoading}
										codeCategorie={codeCategorie.current}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.ValidationEnseignant) && (
									<Enseignants
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.ValidationEnseignant}
										dossierId={dossierId}
										setIsFormValid={setIsFormValid}
										setEditMode={setEditMode}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.ValidationDeplacement) && (
									<Trajets
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.ValidationDeplacement}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										setEditMode={setEditMode}
										setIsFormValid={setIsFormValid}
										isLoading={isLoading}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.PieceJointe) && (
									<PiecesJointes
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.PieceJointe}
										piecesJointes={piecesJointes}
										setIsFormValid={setIsFormValid}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										versementId={versementId}
										setPiecesJointes={setPiecesJointes}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.ConfirmationRIB) && (
									<Rib
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.ConfirmationRIB}
										dossierId={dossierId}
										setIsFormValid={setIsFormValid}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
									/>
								)}
								{steps?.includes(EtapeDemandeVersement.Recapitulatif) && (
									<Recapitulatif
										ref={refs[currentStep]}
										path={EtapeDemandeVersement.Recapitulatif}
										setIsLoading={setIsLoading}
										versementType={versementType}
									/>
								)}
							</Router>

							<div className="creationDossier__footer">
								<span>
									{t('createFolder.form.mandatory-fields')}
								</span>
								{!isEditMode && (!isLoading
									? <FormButton
										type="button"
										value={labelValidateButton()}
										onClick={nextStep}
										disabled={!canGoNext}
									/>
									: <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>)
								}
								{isEditMode && (!isLoading
									? <FormButton
										type="button"
										value={t('createFolder.buttons.validate')}
										onClick={goBackToViewMode}
										disabled={!isFormValid}
									/>
									: <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>)
								}
							</div>
						</div>
					</div>
				</div>
			</Structures>
		</AuthenticatedRoute>
	);
};

export default CreationVersement;