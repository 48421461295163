import React, { FunctionComponent, useState } from "react";
import { useTranslation } from 'react-i18next';

import WhiteLogo from "../logo/WhiteLogo";
import { navigate, RouteComponentProps, Router, useLocation, useParams } from "@reach/router";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { UserClient } from '../../services/generated/FrontOffice-api';
import { useAxios } from '../../custom-hooks/useAxios';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

interface ValidationMailProps extends RouteComponentProps {
}

const ValidationMail:FunctionComponent<ValidationMailProps> = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const axiosInstance = useAxios();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const userClient = new UserClient("", axiosInstance);

	const params = (new URL(location.href)).searchParams;

	const submit = async() => {
		try {
			setIsLoading(true);
			await userClient.changeUserEmail({newEmail: params.get('newEmail'), token: params.get('token') });
			setIsLoading(false);

			if (params.get('redirectUrl'))
				navigate(params.get('redirectUrl'));
			else
				navigate('/Compte/Correspondant');
			
			toast.success(t("validation-mail.success"));

		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("validation-mail.error"));
		}
	}

	return (
		<div id="structure">
			<div className="modal">
				<div className="validationMail">
					<div className="validationMail__content">
						<p>{t("validation-mail.content")}</p>
						{isLoading
							? <Loader
								type="TailSpin"
								width={50}
								height={50}
								color="#d93943"
							></Loader>
							: <FormButton
								type="submit"
								value={t('common.validate')}
								onClick={submit}
							/>
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ValidationMail;