import React, { useImperativeHandle, forwardRef, useState, useEffect } from "react";

import Subtitles from "../basics/Subtitles";
import CheckBoxRound from "../basics/CheckboxRound";
import Input from "../basics/Input";
import StepCounter from "../../components/signup/signup-items/StepCounter";

import { useForm } from "react-hook-form";
import { IStepFiveModel } from "../../models/signup";
import { ActionTypeEnum } from "../../context/ActionType";
import useValidation from '../../custom-hooks/useValidation';
import { useAppDispatch, useStepFiveContext, useStepOneContext } from "../../context/context-helpers";
import { RouteComponentProps } from "@reach/router";
import { useAxios } from "../../custom-hooks/useAxios";
import { AdresseClient, GeoAdresseDto } from "../../services/generated/FrontOffice-api";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { inputAutocompleteDisabled } from '../../constants/config.constant';
import InputSelect from 'adel-shared/dist/components/basics/InputSelect';
import { toast } from 'react-toastify';


interface StepFiveProps extends RouteComponentProps {
	ref: any;
	value?: IStepFiveModel;
	setCanGoNext: (value: boolean) => void;
}

const StepFive: React.FunctionComponent<StepFiveProps> = forwardRef(({
	setCanGoNext
}, ref) => {
	const [defaultCheckbox, setDefaultCheckbox] = useState(true);
	const [otherCheckbox, setOtherCheckbox] = useState(false);
	const [defaultAdresse,] = useState({
		adresse: {
			line1: useStepOneContext().adresse,
			codePostal: useStepOneContext().codePostal,
			ville: useStepOneContext().ville,
		},
	});
	const [langue, setLangue] = useState<string>(useStepFiveContext().codeLangue);
	const {
		register,
		getValues,
		setValue,
		triggerValidation,
		watch,
		errors
	} = useForm<IStepFiveModel>({ defaultValues: useStepFiveContext() });
	const { getRootValidator } = useValidation();
	const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");
	const axiosInstance = useAxios();
	const dispatch = useAppDispatch();
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);
	const context = useStepFiveContext();
	const { t } = useTranslation();
	const {ville, codePostal} = watch(['ville', 'codePostal']);

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			const result = await triggerValidation();
			if (result) {
				dispatch({ type: ActionTypeEnum.SET_SIGNUP_STEP_FIVE, payload: {
					rue: getValues().rue,
					complementAdresse: getValues().complementAdresse,
					ville: getValues().ville,
					hasAdresseParDefaut: getValues().hasAdresseParDefaut,
					codePostal:  getValues().codePostal,
					codeLangue: langue
				}});
			}
			return result;
		}
	}));

	useEffect(() => {
		setCanGoNext(true);
	}, [setCanGoNext]);

	const handleCheckbox = () => {
		setDefaultCheckbox(!defaultCheckbox);
		setOtherCheckbox(!otherCheckbox)
	}

	const searchAdresse = (input: string) => {
		if (!!input) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);

				adresseClient.searchAdresse(`${ville} ${input}`, codePostal).then(results => {
					setGeoAdresse(results);

				});
				onAdresseChange(input);
			}

			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}

	const onAdresseChange = (label: string) => {
		const selectedAdresse = geoAdresse.find(adresse => adresse.label === label);
		if (selectedAdresse) {
			setValue([
				{ rue: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
				{ codePostal: selectedAdresse.codePostal },
				{ ville: selectedAdresse.ville }
			]);
		}
	}

	useEffect(() => {
		setDefaultCheckbox(context.hasAdresseParDefaut);
		setOtherCheckbox(!context.hasAdresseParDefaut);
	}, [context])

	return (
		<>

			<StepCounter
				counter={5}
			/>

			<Subtitles
				title={t('signup.step-five.title')}
			/>

			<div className="contactSelect">
				<InputSelect<string>
					name="langue"
					label={t("myAccount.form.langue")}
					classname="inputSelect"
					options={[
						{ value: "fr", label: t(`common.fr`)},
						{ value: "en", label: t("common.en")}
					]}
					errors={errors}
					onChange={setLangue}
					value={langue}
					placeholder={t("common.select")}
				/>
			</div>

			<div className="defaultContact">
				<CheckBoxRound
					name="hasAdresseParDefaut"
					reference={register}
					id="stepFive__checkBox"
					key="3"
					label={t('signup.step-five.default-adress')}
					sublabel=""
					checked={defaultCheckbox}
					onChange={handleCheckbox}
				/>

				<div className="defaultCheckbox">
					<span>{defaultAdresse.adresse.line1}, {defaultAdresse.adresse.codePostal} {defaultAdresse.adresse.ville}</span>
				</div>
			</div>

			<CheckBoxRound
				name="hasAutreAdresse"
				reference={register}
				id="stepFive__checkBox--label"
				key="4"
				label={t('signup.step-five.other-adress')}
				sublabel=""
				checked={otherCheckbox}
				onChange={handleCheckbox}
			/>

			{otherCheckbox &&
				<div className="contact">
					<section className="contact__item">
						<Input
							name="ville"
							reference={register(adresseValidator?.["Ville"])}
							label={t('signup.step-five.town')}
							type="text"
							placeHolder={t('signup.step-five.town')}
							maxLength={60}
							errors={errors}
						/>

						<Input
							name="codePostal"
							reference={register(adresseValidator?.["CodePostal"])}
							label={t('signup.step-five.zip-code')}
							type="tel"
							placeHolder={t('signup.step-five.zip-code')}
							maxLength={5}
							errors={errors}
						/>
					</section>

					<Input
						autoComplete={inputAutocompleteDisabled}
						name="rue"
						reference={register(adresseValidator?.["Line1"])}
						label={t('signup.step-five.adress')}
						type="text"
						placeHolder={t('signup.step-five.adress')}
						maxLength={120}
						errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={_.debounce(searchAdresse, 1000)}
					/>

					<Input
						name="complementAdresse"
						reference={register(adresseValidator?.["Line2"])}
						label={t('signup.step-five.complementAdresse')}
						type="text"
						placeHolder={t('signup.step-five.complementAdresse')}
						maxLength={200}
						errors={errors}
					/>
				</div>
			}
		</>
	)
})

export default StepFive;