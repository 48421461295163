import React from 'react';
import InputReadOnly from '../InputReadOnly';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const Salaries = ({ salarie }) => {
    var _a, _b, _c, _d;
    const { t, i18n } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nom", content: `${salarie.nom || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Pr\u00E9nom", content: `${salarie.prenom || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Email", content: `${salarie.email || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Adresse", content: `${((_a = salarie.adresse) === null || _a === void 0 ? void 0 : _a.line1) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Adresse compl\u00E9mentaire", content: `${((_b = salarie.adresse) === null || _b === void 0 ? void 0 : _b.line2) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Ville", content: `${((_c = salarie.adresse) === null || _c === void 0 ? void 0 : _c.ville) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Code postal", content: `${((_d = salarie.adresse) === null || _d === void 0 ? void 0 : _d.codePostal) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Type d'adresse", content: t(`typesAdresses.${salarie.typesAdresses}`) }))),
            React.createElement("div", null)),
        salarie.autreAdresse && React.createElement("div", { className: "detailsTab__midsection" },
            React.createElement("h4", null, "Autre adresse"),
            React.createElement("div", { className: "detailsTab__row" },
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement(InputReadOnly, { label: "Adresse", content: `${salarie.autreAdresse.line1 || '-'}` }))),
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement(InputReadOnly, { label: "Adresse compl\u00E9mentaire", content: `${salarie.autreAdresse.line2 || '-'}` }))),
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement(InputReadOnly, { label: "Ville", content: `${salarie.autreAdresse.ville || '-'}` })))),
            React.createElement("div", { className: "detailsTab__row" },
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement(InputReadOnly, { label: "Code postal", content: `${salarie.autreAdresse.codePostal || '-'}` }))))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Date de naissance", content: `${moment(salarie.dateNaissance).format('DD/MM/YYYY') || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Date d'embauche pr\u00E9vue", content: `${moment(salarie.dateEmbauchePrevue).format('DD/MM/YYYY') || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Salaire brut", content: `${salarie.salaireBrut || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Equipements", content: `${salarie.equipements.split(',').map(e => { return e; }).filter(e => e !== "none" && e !== "autre").join(", ")
                            || '-'}` }))),
            salarie.autresEquipements &&
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement(InputReadOnly, { label: "Autre \u00E9quipement", content: `${salarie.autresEquipements || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Convention collective", content: `${salarie.conventionCollective || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            salarie.cv &&
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement("div", { className: "inputReadOnly" },
                            React.createElement("label", null, "CV"),
                            React.createElement("p", null,
                                React.createElement("a", { href: salarie.cv.uri, target: "_blank", rel: "noreferrer" }, salarie.cv.fileName))))),
            salarie.diplome &&
                React.createElement("div", { className: "detailsTab__column" },
                    React.createElement("div", { className: "detailsTab__content" },
                        React.createElement("div", { className: "inputReadOnly" },
                            React.createElement("label", null, "Dipl\u00F4me"),
                            React.createElement("p", null,
                                React.createElement("a", { href: salarie.diplome.uri, target: "_blank", rel: "noreferrer" }, salarie.diplome.fileName))))))));
};
export default Salaries;
