import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { useAxios } from '../../../../custom-hooks/useAxios';
import { Dictionary } from '../../../../models';
import { CreateOrUpdateEtapeDto, CreateOrUpdateTrajetDto, DossierClient, EtapeDto, PaysClient, PaysDto, TrajetDto, TypeTransport } from '../../../../services/generated/FrontOffice-api';
import { TrajetsFormMode } from '../Trajets';
import RenderTrajetBlock from './RenderTrajetBlock';

export interface EditTrajetRef {
	validateAndSend: () => Promise<boolean>;
}

export enum CategorieLieu {
	Depart = "depart",
	Arrivee = "arrivee",
	Etapes = "etapes",
	Escale = "escale",
	Escales = "escales"
}

interface EtapesFromForm {
	pays: PaysDto | AdelOption<PaysDto>;
	transport: string;
	escale: {
		aller: {
			boolean: string;
			pays: PaysDto | AdelOption<PaysDto>;
		},
		retour: {
			boolean: string;
			pays: PaysDto | AdelOption<PaysDto>;
		}
	}
}

interface EditTrajetProps {
	ref: any;
	setIsFormValid: (value: boolean) => void;
	closeView: () => void;
	currentFormMode: TrajetsFormMode;
	dossierId: string;
	trajetSelected: TrajetDto;
	trajetValidator: Dictionary<any>;
	etapeValidator: Dictionary<any>;
	escaleValidator: Dictionary<any>;
}

const EditTrajet: React.FC<EditTrajetProps> = forwardRef(({
	setIsFormValid,
	closeView,
	currentFormMode,
	dossierId,
	trajetSelected,
	trajetValidator,
	etapeValidator,
	escaleValidator
}, ref) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const { register, getValues, setValue, triggerValidation, errors, control, watch } = useForm<any>({ defaultValues: {
		artistes: []	
	}});

	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
	const paysClient = new PaysClient("", axiosInstance);

	const [isSelectWithoutCar, setIsSelectWithoutCar] = useState<boolean>(false);
	const [artisteOptions, setArtisteOptions] = useState<AdelOption<string>[]>([]);

	const [etapes, setEtapes] = useState<EtapeDto[]>([]);
	const [depart, setDepart] = useState<EtapeDto>();
	const [arrivee, setArrivee] = useState<EtapeDto>();

	const watchArtistes = watch("artistes");

	useEffect(() => {
		setIsFormValid(true);
		(async () => {
			await getArtistsList();
			await fetchPays();
			await fetchPaysPresta();
		})();
	}, []);


	/** Render escale */
	const addEtape = () => {
		let temporaryId = 'TEMPORARY_' + Math.random().toString(36).substr(2, 9);
		let array = [...etapes];
		array.push({ id: temporaryId });
		setEtapes(array);
	}

	const removeEtape = (id:string) => {
		let array = [...etapes];
		for (let i in array) {
			if (id === array[i].id)
				array.splice(array.indexOf(array[i]), 1);
		}
		setEtapes(array);
	}



	/** Pays */
	const [pays, setPays] = useState<PaysDto[]>([]);
	const fetchPays = async () => {
		let result = await paysClient.getPays();
		setPays(result);
	}

	const [paysPrestations, setPaysPrestations] = useState<PaysDto[]>([]);
	const fetchPaysPresta = async () => {
		let result = await dossierClient.getDossierPays(dossierId);
		setPaysPrestations(result);
	}



	/** Artistes */
	const getArtistsList = async () => {
		try {
			let result = await dossierClient.getArtistes(dossierId, "", "", 1, 1000, true);
			if (result && result.items) {
				setArtisteOptions(result.items.map(item => {
					return {
						label: item.fullName,
						value: item.id
					}
				}));
			}
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
	}


	/** Edition */
	useEffect(() => {
		if (currentFormMode === TrajetsFormMode.IS_EDIT && artisteOptions) {
			if (trajetSelected) {
				setValue([
					{ artistes: trajetSelected.artistes.map(e => { return {value: e.id, label: e.fullName}}) }
				]);

				let etapes = trajetSelected.etapes.sort((a,b) => a.order - b.order); // pour être sûr d'avoir les étapes dans l'ordre selon order
				const timer = setTimeout( () => {
					updateFields(etapes);
				}, 0);
				return () => clearTimeout(timer);
			}
		}
	}, [trajetSelected, currentFormMode, artisteOptions]);

	const updateFields = (etapes: TrajetDto[]) => {
		let start:EtapeDto = etapes[0];
		let finish:EtapeDto =  etapes[etapes.length - 1];
		let steps:EtapeDto[] =  etapes.length > 2 ?  etapes.slice(1,  etapes.length-1) : [];

		/** depart */
		setDepart(start);
		setValue("depart.pays", {label: start.pays.nom, value: start.pays});
		setValue("depart.transport", start.typeTransport);

		/** arrivee */
		setArrivee(finish);
		setValue("arrivee.pays", {label: finish.pays.nom, value:finish.pays});
		setValue("arrivee.transport", finish.typeTransport);

		/** etapes */
		setEtapes(steps);
		if (start.typeTransport === TypeTransport.Voiture) {
			setIsSelectWithoutCar(true);
		} else {
			setIsSelectWithoutCar(false);
		}
		steps.forEach((e,i) => {
			e.pays && setValue(`etapes[${i}].pays`, {label: e.pays.nom, value: e.pays});
			setValue(`etapes[${i}].transport`, e.typeTransport);
			e.escaleAllerPays && setValue(`etapes[${i}].escale.aller.pays`, {label: e.escaleAllerPays.nom, value: e.escaleAllerPays});
			e.escaleRetourPays && setValue(`etapes[${i}].escale.retour.pays`, {label: e.escaleRetourPays.nom, value: e.escaleRetourPays});

			if(e.escaleAllerPays)
				setValue(`etapes[${i}].escale.aller.boolean`, "true");
			else
				setValue(`etapes[${i}].escale.aller.boolean`, "false");

			if(e.escaleRetourPays)
				setValue(`etapes[${i}].escale.retour.boolean`, "true");
			else
				setValue(`etapes[${i}].escale.retour.boolean`, "false");

		})
	}



	/** Validation et envoi */
	const [hasNoDestination, setHasNoDestination] = useState<boolean>(false);
	const [hasTooManyArtists, setHasTooManyArtists] = useState<boolean>(false);
	const checkValidationDestination = () => {
		let etapesFromForm = getValues({nest:true}).etapes;
		let isFormValid:boolean = true;
	
		if (etapes.length === 0) {
			setHasNoDestination(true);
			isFormValid = false;
		} else {
			setHasNoDestination(false);
		}

		if (watchArtistes.length > 12) {
			setHasTooManyArtists(true);
			isFormValid = false;
		} else {
			setHasTooManyArtists(false);
		}

		if (watchArtistes.length === 0) 
			isFormValid = false;

		const isValid = etapesFromForm != null && etapesFromForm.some((etape:EtapesFromForm) =>
			(etape.escale.aller.boolean !== undefined &&
			etape.escale.retour.boolean !== undefined) ||
			((etape.escale.aller.boolean === "true" && etape.escale.aller.pays !== undefined) ||
			(etape.escale.retour.boolean === "true" && etape.escale.retour.pays !== undefined))
		);

		return (isValid && isFormValid);
	}

	useImperativeHandle(ref, () => ({
		async validateAndSend(): Promise<boolean> {
			let result = await triggerValidation();
			let resultValidateDestination = checkValidationDestination();

			if (resultValidateDestination === false)
				return false;

			if (result) {
				let dataToSend: CreateOrUpdateTrajetDto = {};
				let etapesToSend:CreateOrUpdateEtapeDto[] = [];
				dataToSend.artisteIds = getValues().artistes.map((e:AdelOption<string>) => e.value);

				if (currentFormMode === TrajetsFormMode.IS_CREATION) {
					let depart:CreateOrUpdateEtapeDto = createDepart();
					let arrivee:CreateOrUpdateEtapeDto = createArrivee();
					let etapes:CreateOrUpdateEtapeDto[] = createEtapes();
					dataToSend.etapes = trajetToSend(etapesToSend, depart, arrivee, etapes);
				}
				else {
					let start:CreateOrUpdateEtapeDto = createDepart(depart);
					let finish:CreateOrUpdateEtapeDto = createArrivee(arrivee);
					let steps:CreateOrUpdateEtapeDto[] = createEtapes(etapes);
					dataToSend.etapes = trajetToSend(etapesToSend, start, finish, steps);
				}

				try {
					if (currentFormMode === TrajetsFormMode.IS_CREATION) {
						await dossierClient.createTrajet(dossierId, dataToSend);
					} else if (currentFormMode === TrajetsFormMode.IS_EDIT) {
						await dossierClient.updateTrajet(trajetSelected.id, dataToSend);
					}
					closeView();
					return true;
				} catch (error) {
					if (error.exception?.message)
						toast.error(error.exception.message);
					else
						toast.error(t("errors.default"));
					return false;
				}
			}
			return false;
		}
	}));

	const trajetToSend = (etapesToSend:CreateOrUpdateEtapeDto[],
		depart:CreateOrUpdateEtapeDto,
		arrivee:CreateOrUpdateEtapeDto,
		etapes:CreateOrUpdateEtapeDto[]) => {

		etapesToSend.push(depart); // depart = order 1

		let index = 1;
		const stepArray = etapes.map((e:CreateOrUpdateEtapeDto) => {
			index++;
			return {...e, order: index};
		});
		etapesToSend = etapesToSend.concat(stepArray);

		arrivee = {...arrivee, order: index+1 }
		etapesToSend.push(arrivee);

		return etapesToSend;
	}

	const createDepart = (step?:EtapeDto) => {
		let depart:CreateOrUpdateEtapeDto = {
			paysId: getValues("depart.pays").id ? getValues("depart.pays").id : getValues("depart.pays").value.id,
			typeTransport: getValues("depart.transport"),
			order: 1
		};
		if (step)
			depart = {...depart, id: step.id }

		return depart;
	}

	const createArrivee = (step?:EtapeDto) => {
		let arrivee:CreateOrUpdateEtapeDto = {
			paysId: getValues("arrivee.pays").id ? getValues("arrivee.pays").id : getValues("arrivee.pays").value.id,
			typeTransport: getValues("arrivee.transport")
		};
		if (step)
			arrivee = {...arrivee, id: step.id }

		return arrivee;
	}

	const createEtapes = (steps?:EtapeDto[]) => {
		let etapesFromForm = getValues({nest:true}).etapes;
		return etapesFromForm.map((etape:EtapesFromForm, i:number) => {
			if(steps && steps.length > 0 && steps[i] && !steps[i].id.includes('TEMPORARY_')) {
				return {
					escaleRetourPaysId: (etape.escale.retour.pays as PaysDto)?.id
										|| (etape.escale.retour.pays as AdelOption<PaysDto>)?.value.id
										|| null,
					escaleAllerPaysId: (etape.escale.aller.pays as PaysDto)?.id
										|| (etape.escale.aller.pays as AdelOption<PaysDto>)?.value.id
										|| null,
					typeTransport: etape.transport,
					paysId: (etape.pays as PaysDto).id || (etape.pays as AdelOption<PaysDto>).value.id,
					id: steps[i].id
				}
			} else {
				return {
					escaleRetourPaysId: (etape.escale.retour.pays as PaysDto)?.id
										|| (etape.escale.retour.pays as AdelOption<PaysDto>)?.value.id
										|| null,
					escaleAllerPaysId: (etape.escale.aller.pays as PaysDto)?.id
										|| (etape.escale.aller.pays as AdelOption<PaysDto>)?.value.id
										|| null,
					typeTransport: etape.transport,
					paysId: (etape.pays as PaysDto).id || (etape.pays as AdelOption<PaysDto>).value.id,
				}
			}
		});
	}


	return (<div className="creationDossier__trajet">
		<div className="creationDossier__header--alt">
			<div className="navigationFil" onClick={() => closeView()}>
				<span className="navigationFil__item">
					<i className="fas fa-chevron-left"></i>
					{t("createFolder.trajets.back")}
				</span>
			</div>
			<h3 className="title--dark">{t("createFolder.trajets.title")}</h3>
		</div>
		<section>
			<h4 className="title--red">{t("createFolder.trajets.listColumns.depart")}</h4>
			<RenderTrajetBlock
				name={CategorieLieu.Depart}
				isDepart={true}
				watch={watch}
				isDestination={false}
				control={control}
				paysList={pays}
				paysFull={pays}
				errors={errors}
				etapeValidator={etapeValidator}
				isSelectWithoutCar={isSelectWithoutCar}
				setIsSelectWithoutCar={setIsSelectWithoutCar}
			/>
		</section>
		<section>
			<h4 className="title--red">{t("createFolder.trajets.listColumns.etape")}</h4>
			{hasNoDestination &&
				<div className="errorMessage">{t("createFolder.trajets.no-destination")}</div>
			}
			{etapes.length > 0 && etapes.map((e:EtapeDto, i:number) => {
				return <div className="creationDossier__trajetEtape" key={e.id}>
					<h4>
						{t("createFolder.trajets.form.etape")} {i+1}
						<span className="creationDossier__addElementButton" onClick={() => { removeEtape(e.id) }}>
							<i className="far fa-minus-circle" />
						</span>
					</h4>
					<RenderTrajetBlock
						name={`${CategorieLieu.Etapes}[${i}]`}
						isDepart={false}
						isDestination={true}
						watch={watch}
						control={control}
						errors={errors}
						isSelectWithoutCar={isSelectWithoutCar}
						setIsSelectWithoutCar={setIsSelectWithoutCar}
						paysPrestations={paysPrestations}
						paysFull={pays}
						etapeValidator={etapeValidator}
					/>
				</div>
			})}
			<div className="folders__addButton" onClick={() => { addEtape() }}>
				<i className="fas fa-plus-circle" /><span>{t("createFolder.trajets.addStep")}</span>
			</div>
		</section>
		<section>
			<h4 className="title--red">{t("createFolder.trajets.listColumns.arrivee")}</h4>
			<RenderTrajetBlock
				name={CategorieLieu.Arrivee}
				isDepart={false}
				isDestination={false}
				watch={watch}
				control={control}
				setValue={setValue}
				errors={errors}
				paysList={pays}
				paysFull={pays}
				etapeValidator={etapeValidator}
				isSelectWithoutCar={isSelectWithoutCar}
				setIsSelectWithoutCar={setIsSelectWithoutCar}
			/>
		</section>
		<section>
			<h4 className="title--red">{t("createFolder.trajets.listColumns.artistes")}</h4>
			{hasTooManyArtists &&
				<div className="errorMessage">{t("createFolder.trajets.form.too-many-artists")}</div>
			}
			{!watchArtistes &&
				<div className="errorMessage">{t("createFolder.trajets.form.no-empty-artist")}</div>
			}
			<div className="creationDossier__row">
				<Controller control={control}
					name="artistes"
					as={({ onChange, value, name }) => (
						<InputSelect<any> // string[]
							name={name}
							label={t('createFolder.trajets.form.artistes')}
							classname="inputSelect inputSelect--fullWidth inputSelect--multi"
							options={artisteOptions}
							errors={errors}
							onChange={(value) => onChange(value) }
							selectAll={`${t('createFolder.trajets.form.all-artistes')}`}
							value={value}
							isMulti
							placeholder={t("common.select")}
						/>
					)}
					rules={trajetValidator && trajetValidator["ArtisteIds"]}
				/>
			</div>
		</section>
	</div>)
});

export default EditTrajet;
