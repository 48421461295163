import InputReadOnly from "adel-shared/dist/components/InputReadOnly";
import React, {
    ForwardRefExoticComponent,
    PropsWithoutRef,
    RefAttributes,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
    RefObject,
} from "react";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { RouteComponentProps, navigate } from "@reach/router";
import { AppStateContext } from '../../../../context/AppContext';
import { useAxios } from "../../../../custom-hooks/useAxios";
import { RecapitulatifVersementDto, TypeVersement, VersementClient } from "../../../../services/generated/FrontOffice-api";
import { StepVersementRef } from '../CreationVersement';

interface RecapitulatifProps extends RouteComponentProps {
    setIsLoading: (value: boolean) => void;
    versementType: any
}

const Recapitulatif: ForwardRefExoticComponent<PropsWithoutRef<RecapitulatifProps> & RefAttributes<StepVersementRef>> = forwardRef(({
    setIsLoading,
    versementType
}, ref) => {
    const { t } = useTranslation();
	const axiosInstance = useAxios();
    const versementClient = new VersementClient('', axiosInstance);
    const [recap, setRecap] = useState<RecapitulatifVersementDto>({});
	const [context] = useContext(AppStateContext);
     
    useEffect(() => {
        (async() => {
            const result = await versementClient.getRecapitulatifDemandeVersement(context.currentEditingVersement.id);
            setRecap(result);
        })();
    }, []);
    
    useImperativeHandle(ref, () => ({
        async validateForm() {
            setIsLoading(true);
            try {                
                await versementClient.soumettreVersement(context.currentEditingVersement.id);
				setIsLoading(false);
                toast.success(t('creationVersement.recapitulatif.success'));
                navigate(`/Versements/${context.currentEditingVersement.originalDossierId}`);
            } catch(error) {
				setIsLoading(false);
                toast.error(t('creationVersement.recapitulatif.error'));
				if (error.exception?.message)
					toast.error(error.exception.message);
            }
            return true;
        }
    }));

    return (
        <div className="creationDossier__validation">
            <div className="creationDossier__header">
                <h3 className="title--dark">{t('creationVersement.recapitulatif.title')}</h3>
            </div>
            <div className="creationDossier__content">
                <div className="creationDossier__row">
                    <div className="input">
                        <InputReadOnly
                            label={t('creationVersement.recapitulatif.baseCalculPrevisionnelle')}
                            content={recap.totalMontantPrevisionnel && `${recap.totalMontantPrevisionnel} €`}
                        />
                    </div>
                    <div className="input">
                        <InputReadOnly
                            label={t('creationVersement.recapitulatif.baseCalculRealisee')}
                            content={recap.totalMontantRealise && `${recap.totalMontantRealise} €`}
                        />
                    </div>
                </div>
                <div className="creationDossier__row">
                    <div className="input">
                        <InputReadOnly
                            label={t('creationVersement.recapitulatif.pourcentageRealise')}
                            content={recap.pourcentageRealise && `${(recap.pourcentageRealise * 100).toFixed(2)} %`}
                        />
                    </div>
                    <div className="input">
                        <InputReadOnly
                            label={t('creationVersement.recapitulatif.aideAccordee')}
                            content={recap.montantAccorde && `${recap.montantAccorde} €`}
                        />
                    </div>
                </div>
                <div className="creationDossier__row">
                    <div className="input">
                        <InputReadOnly
                            label={t('creationVersement.recapitulatif.typeDossier')}
                            content={recap.categorie}
                        />
                    </div>
                </div>
                <div className="creationDossier__row">
                    <div className="input">
                        <InputReadOnly
                            label={t('creationVersement.recapitulatif.montantDejaVerse')}
                            content={`${recap.montantDejaVerse} €`}
                        />
                    </div>
                    {versementType.current == TypeVersement.Solde && <div className="input">
                        <InputReadOnly
                            label={t('creationVersement.recapitulatif.AideEstime')}
                            content={`${recap.aideEstimee} €`}
                        />
                    </div>}
                </div>
            </div>
        </div>
    );
});

export default Recapitulatif;