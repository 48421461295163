import React from "react";
import SignupStep from "../../../context/SignupSteps";
import { useTranslation } from "react-i18next";
import { useSignupSteps } from "../../../custom-hooks/useSteps";

interface SignupListProps {
	currentStep: SignupStep;
	highestStep: SignupStep;
	navigateToStep: (step: SignupStep) => void;
}

const SignupList: React.FC<SignupListProps> = ({
	navigateToStep,
	highestStep
}) => {

	const { isHigherStep } = useSignupSteps();

	const { t } = useTranslation();

	return (
		<div id="signupList">
			<nav className="signupList__navigation">
				<ul>
					<li>
						<button className="signupList__button">
							<span className={`${!isHigherStep(highestStep, SignupStep.One) ? "signupList__navIcon--active" : "signupList__navIcon"}`}><span>1</span></span>
							<p
								className={`${!isHigherStep(highestStep, SignupStep.One) ? "signupList__nav--active" : "signupList__nav"}`}
								onClick={() => {
									navigateToStep(SignupStep.One);
								}}
							>
								{t('signup.list.infos-juridiques')}
							</p>
						</button>
					</li>

					<li>
						<button className="signupList__button">
							<span className={`${!isHigherStep(highestStep, SignupStep.Two) ? "signupList__navIcon--active" : "signupList__navIcon"}`}><span>2</span></span>
							<p
								className={`${!isHigherStep(highestStep, SignupStep.Two) ? "signupList__nav--active" : "signupList__nav"}`}
								onClick={() => {
									navigateToStep(SignupStep.Two);
								}}
							>
								{t('signup.list.comments')}
							</p>
						</button>
					</li>

					{/* <li>
						<button className="signupList__button">
							<span className={`${!isHigherStep(highestStep, SignupStep.Three) ? "signupList__navIcon--active" : "signupList__navIcon"}`}><span>3</span></span>
							<p
								className={`${!isHigherStep(highestStep, SignupStep.Three) ? "signupList__nav--active" : "signupList__nav"}`}
								onClick={() => {
									navigateToStep(SignupStep.Three);
								}}
							>
								{t('signup.list.infos-bancaires')}
							</p>
						</button>
					</li> */}

					<li>
						<button className="signupList__button">
							<span className={`${!isHigherStep(highestStep, SignupStep.Four) ? "signupList__navIcon--active" : "signupList__navIcon"}`}><span>3</span></span>
							<p
								className={`${!isHigherStep(highestStep, SignupStep.Four) ? "signupList__nav--active" : "signupList__nav"}`}
								onClick={() => {
									navigateToStep(SignupStep.Four);
								}}
							>
								{t('signup.list.correspondant')}
							</p>
						</button>
					</li>

					<li>
						<button className="signupList__button">
							<span className={`${!isHigherStep(highestStep, SignupStep.Five) ? "signupList__navIcon--active" : "signupList__navIcon"}`}><span>4</span></span>
							<p
								className={`${!isHigherStep(highestStep, SignupStep.Five) ? "signupList__nav--active" : "signupList__nav"}`}
								onClick={() => {
									navigateToStep(SignupStep.Five);
								}}
							>
								{t('signup.list.adresse')}
							</p>
						</button>
					</li>

					<li>
						<button className="signupList__button">
							<span className={`${!isHigherStep(highestStep, SignupStep.Six) ? "signupList__navIcon--active" : "signupList__navIcon"}`}><span>5</span></span>
							<p
								className={`${!isHigherStep(highestStep, SignupStep.Six) ? "signupList__nav--active" : "signupList__nav"}`}
								onClick={() => {
									navigateToStep(SignupStep.Six);
								}}
							>
								{t('signup.list.fichiers')}
							</p>
						</button>
					</li>

					<li>
						<div className="signupList__button">
							<span className={`${!isHigherStep(highestStep, SignupStep.Seven) ? "signupList__navIcon--active" : "signupList__navIcon"}`}><span>6</span></span>
							<p
								className={`${!isHigherStep(highestStep, SignupStep.Seven) ? "signupList__nav--active" : "signupList__nav"}`}
							>
								{t('signup.list.email')}
							</p>
						</div>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default SignupList;