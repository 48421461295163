import React, { useCallback } from "react"
import ChipAdelOption from "../../models/ChipAdelOption";
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

interface ChipProps {
	option: ChipAdelOption;
	onRemoveChip: Function;
	dates?: any;
	dateRange: boolean;
}

const Chip: React.FunctionComponent<ChipProps> = ({
	option,
	onRemoveChip,
	dates,
	dateRange
}: ChipProps): JSX.Element => {


	// Utilisation de moment-range avec TS: https://github.com/rotaready/moment-range
	const moment = extendMoment(Moment);
	const start = moment(dates[0], 'YYYY-MM-DD');
	const end = moment(dates[1], 'YYYY-MM-DD');
	const range = moment.range(start, end);

	const handleRemoveChip = useCallback(() => {
		onRemoveChip(option);
	}, [option, onRemoveChip]);

	return (
		<>
			<div className={'chip'}>
				<p className={'chip__label'}>{option.type + ':'}</p>

				{dateRange ?
					<p className={'chip__value'}>
						{range}
					</p>
					:
					<p className={'chip__value'}>{option.label}</p>
				}

				<i className="fa fa-times chip__icon" aria-hidden="true" onClick={handleRemoveChip}></i>
			</div>
		</>
	)
}

export default Chip;