import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useAxios } from '../../../custom-hooks/useAxios';
import { DossierClient, QuestionBlockDto, AnswerType, QuestionDto, DonneesBancairesDto } from '../../../services/generated/FrontOffice-api';
import { AdelDossierClient, CreateOrUpdateDonneesBancairesDto, SubmitDescriptionProjetAnswersDto } from '../../../clients/AdelDossierClient';
import { useForm } from 'react-hook-form';
import { Dictionary } from '../../../models';
import useValidation from '../../../custom-hooks/useValidation';
import DonneesBancaires from './DonneesBancaires';
import RenderBlockContent, { getQuestions, getAnswer } from './questionsBlock/RenderBlockContent';
import { isAnswerValid, ValidationResult } from '../../../helpers/questionHelpers';
import { isFileRef } from '../../../models/FileRef';
import { toast } from 'react-toastify';
import { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import DonneesBancaireReadonly from '../../monCompte/bancaire/DonneesBancaireReadonly';

interface DescriptionProps extends RouteComponentProps {
	ref: any;
	dossierId: string;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
}

const Description: React.FunctionComponent<DescriptionProps> = forwardRef(({
	dossierId,
	setCanGoNext,
	setIsLoading,
}, ref) => {
    const { i18n, t } = useTranslation();
	const axiosInstance = useAxios();
	const dossierClient = new DossierClient("", axiosInstance);
	const adelDossierClient = new AdelDossierClient("", axiosInstance);
	const form = useForm<any>({}); 
	const {
		getValues,
		setValue,
		triggerValidation,
	} = form;

	const [blocks, setBlocks] = useState<QuestionBlockDto[]>([]);
	const [answers, setAnswers] = useState<Dictionary<any>>({});
	const [messageValidation, setMessageValidation] = useState<Dictionary<ValidationResult>>({});
	const [isValideDocuments, setIsValideDocuments] = useState(true);

	const { getRootValidator } = useValidation();
	const donneesBancairesValidator = getRootValidator("CreateOrUpdateDonneesBancairesDto");
	const [ribPJ, setRibPJ] = useState<DocumentWithFile>();
	const [isIbanValid, setIsIbanValid] = useState<boolean>(false);

	const [commandesInvalid, setcommandesInvalid] = useState<boolean>(false);
	const [defaultDonneesBancaires, setDefaultDonneesBancaires] = useState<DonneesBancairesDto>({});

	useEffect(() => {
		if (!dossierId) return;
		(async function () { await fetchViewModel()})();
        setCanGoNext(false);
	}, [dossierId]);
	
	useEffect(() => {
        setCanGoNext(isIbanValid);
    }, [isIbanValid]);

	const fetchViewModel = async () => {
		const viewModel = await dossierClient.getDescriptionProjetViewModel(dossierId);

		setDefaultDonneesBancaires(viewModel.donneesBancaires);
		//@ts-ignore
		setAnswers(viewModel.answers);
		setBlocks(viewModel.questionsBlocks);

		if (viewModel.donneesBancaires && Object.keys(viewModel.donneesBancaires).length > 0) {
			setIsIbanValid(true);
			setValue([
				{ "iban": viewModel.donneesBancaires.iban },
				{ "bic": viewModel.donneesBancaires.bic },
				{ "banque": viewModel.donneesBancaires.banque },
				{ "line1": viewModel.donneesBancaires.adresse?.line1 },
				{ "line2": viewModel.donneesBancaires.adresse?.line2 },
				{ "codePostal": viewModel.donneesBancaires.adresse?.codePostal },
				{ "ville": viewModel.donneesBancaires.adresse?.ville }
			]);

			if(viewModel.donneesBancaires.rib) {
				setRibPJ({
					id:  viewModel.donneesBancaires.rib.id,
					fileName:  viewModel.donneesBancaires.rib.fileName || '',
					url:  viewModel.donneesBancaires.rib.uri
				});
			}
		}
	}
	/** SUMBIT */
	const submitData = async (answers: {id: string, type: AnswerType, value: any}[], donneesBancaires: CreateOrUpdateDonneesBancairesDto) => {
        let data: SubmitDescriptionProjetAnswersDto = {
            answers: {}
        };
		let files: Dictionary<File> = {};

        for (let answer of answers) {
            if (answer.type === AnswerType.File) {
                if (answer.value instanceof File) {
                    // Uploading a new file
                    data.answers[answer.id] = {
                        partName: answer.id,
                        fileName: answer.value.name,
						contentType: answer.value.type
                    };
                    files[answer.id] = answer.value;
                } else if (isFileRef(answer.value)) {
                    // Specifying an existing file
                    data.answers[answer.id] = { id: answer.value.id };
                }
			} else if (answer.type === AnswerType.Array) {
				if (Array.isArray(answer.value)) {

					let newArray:Object[] = [];

					for (const [idx, a] of answer.value.entries()) {
						let item:any = {};

						for (const [key, value] of Object.entries(a)) {
							if (value instanceof File) {
								const id = key + '-' + idx;
								item[key] = {
									partName: id,
									fileName: value.name,
									contentType: value.type
								}
								files[id] = value;
							} else if (isFileRef(value)) {
								item[key] = { id: answer.value[idx][key].id };
							} else {
								item[key] = value;
							}
						}
						newArray.push(item);
					}
					data.answers[answer.id] = newArray;
				}
			}
			else {
                data.answers[answer.id] = answer.value;
            }
		}

		if(defaultDonneesBancaires.canEditRib)
			await adelDossierClient.createOrUpdateDonneesBancaires(donneesBancaires, ribPJ?.file && {"rib-PJ": ribPJ.file});
		await adelDossierClient.submitDescriptionProjetAnswers(dossierId, data, files);
    }



	/** VALIDATION */
	const validateAnswers = () => {
		setMessageValidation({});
		const errors: Dictionary<ValidationResult> = {};

		const validation = (item: QuestionDto):ValidationResult | Dictionary<ValidationResult> => {
			if (isAnswerValid(item, getAnswer(item, answers), t).isValid === false) {

				/** Answers Array */
				const items: Dictionary<Dictionary<ValidationResult>> = {};

				if (Array.isArray(answers[item.id]) && answers[item.id].length > 0) {
					answers[item.id].forEach((q:any, itemIndex:number) => {
						// Initialiser items à => { nom: null, prenom: null, cv: null } pour chaque groupe d'inputs dynamiques créés
						if (!items[itemIndex])
							items[itemIndex] = item.arrayItemsQuestions.reduce((acc, v) => {acc[v.id] = null; return acc;}, {} as Dictionary<any>);
							
						// Ensuite assigner les valeurs de answers des inputs à items
						Object.assign(items[itemIndex], answers[item.id][itemIndex]);
						
						// Pour chaque input du groupe, on va tester la validation et lui assigner son ValidationResult associé
						for (const [key] of Object.entries(items[itemIndex])) {
							const itemToCheck = item.arrayItemsQuestions.find(aa => aa.id === key);
							const answerToCheck = answers[item.id][itemIndex];

							const validationResult = isAnswerValid(itemToCheck, getAnswer(itemToCheck, answerToCheck), t);

							items[itemIndex][key] = {
								isValid: validationResult.isValid, 
								message: validationResult.message
							}
						}
					})
				}

				/** Answers simples */
				if (!errors[item.id]) {
					errors[item.id] = {} as ValidationResult
				}
				errors[item.id] = {
					isValid: false, 
					message: isAnswerValid(item, getAnswer(item, answers), t).message,
					items
				}
			}
			return errors;
		}

        blocks.forEach(block => {
			getQuestions(block, answers).forEach(q => {
				validation(q);
			})
		});
		setMessageValidation(errors);

		blocks.forEach(block => {
            if (!getQuestions(block, answers).every(q => isAnswerValid(q, getAnswer(q, answers), t).isValid )) {
                return false;
            }
		});

		if(Object.keys(errors).length > 0) {
			return false;
		} else return true;
	};

	const getParsedAnswers = () => {
		const answersParsed = blocks
		.flatMap(block => getQuestions(block, answers))
		.map(question => ({id: question.id, type: question.answerType, value: getAnswer(question, answers)}));
		return answersParsed;
	}

    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
			const donnesBancairesResult = await triggerValidation();
			const answersResult = validateAnswers();
			const {iban, bic, banque, line1, line2, codePostal, ville} = getValues();
            if(!isValideDocuments){
				toast.error(t('validation-messages.invalid-form'))
				return false
			}
			const donneesBancairesValues: CreateOrUpdateDonneesBancairesDto = {
				iban,
				bic,
				banque,
				adresse: {
					line1,
					line2,
					codePostal,
					ville
				},
				rib: {
					id: ribPJ?.file ? undefined : (ribPJ?.id || undefined),
					partName: ribPJ?.file ? "rib-PJ" : undefined
				}
			}

			if (
				getParsedAnswers().find(e => e.id === "commande-musicale")?.value === true && 
				!getParsedAnswers().find(e => e.id === "compositeurs")?.value?.length
			) {
				setcommandesInvalid(true);
				return false;
			} else {
				setcommandesInvalid(false);
			}
			
			if (donnesBancairesResult && answersResult) {
				setIsLoading(true);
				try {
					await submitData(getParsedAnswers(), donneesBancairesValues);
					setIsLoading(false);
					return true;
				} catch(error) {
					setIsLoading(false);
					if(error.additionalDetails?.errors) {
						Object.keys(error.additionalDetails?.errors).forEach(item =>
							toast.error(error.additionalDetails?.errors[item][i18n.language])
						);
					}
					if (error.exception?.message)
						toast.error(error.exception.message);
					return false;
				}
			} else {
                toast.error(t("validation-messages.invalid-form"));
			}
            return false;
        }
	}));
	
	return (
		<div className="creationDossier__description">
			<div className="creationDossier__header">
				<h3 className="title--dark">
					{t("createFolder.menu.descriptionProjet")}
				</h3>
			</div>
			<section>
				<h4 className="title--red">{t("createFolder.description.title-contenu")}</h4>
				{blocks.filter(block => block.id !== "donnees-bancaires").map((block, i) =>{ 
					if(block.id == 'pieces-jointes-obligatoires'){
						block.questions = {...block}.questions.filter((q: any) => q.isShown ? q.isShown : true)
					}					
					return(
					<div className="creationDossier__item creationDossier__descriptionItem" key={i}>
						<RenderBlockContent 
							block={block}
							answers={answers}
							setAnswers={setAnswers}
							messageValidation={messageValidation}
							setIsValideDocuments={setIsValideDocuments}
						/>
					</div>
				)})}
				{commandesInvalid &&
					<div className="errorMessage errorMessage--outBlock">
						{t("validation-messages.not-empty-compositeurs")}
					</div>
				}
			</section>
			<section>
				<h4 className="title--red">{t("createFolder.description.title-bancaire")}</h4>
				{defaultDonneesBancaires.canEditRib
					? <DonneesBancaires 
						form={form}
						donneesBancairesValidator={donneesBancairesValidator}
						isIbanValid={isIbanValid}
						setIsIbanValid={setIsIbanValid}
						ribPJ={ribPJ}
						setRibPJ={setRibPJ}
					/>
					: <DonneesBancaireReadonly donneesBancaires={defaultDonneesBancaires} /> 
				}
			</section>
		</div>
	);
});

export default Description;
