import React, { useState, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import ResetChangePassword from "../structures/portail/mdp/ResetChangePassword";
// import { useTranslation } from "react-i18next";

enum StepResetPasswordQueryParams {
	Token = "token",
	UserName = "userName",
}

const StepResetPassword = (props: RouteComponentProps) => {
	const [token, setToken] = useState<string>(null);
	const [username, setUsername] = useState<string>(null);
	const [, setResetPassword] = useState<boolean>(false);
	const [validPass, setValidPass] = useState<boolean>(false);
	const [showPass, setShowPass] = useState<boolean>(false);

	// const { t } = useTranslation();

	useEffect(() => {
		var searchParams = new URLSearchParams(props.location.search);

		// Retrieve token + userName from url
		let token = searchParams.get(StepResetPasswordQueryParams.Token);
		let userName = searchParams.get(StepResetPasswordQueryParams.UserName);

		setToken(token);
		setUsername(userName);
		// TODO : Afficher le composant de cr�ation de mot de passer avec le userName en props + une callback sur le submit
		// TODO : Appeler l'API pour la cr�ation de mot de passe https://adel-frontoffice-dev.azurewebsites.net/swagger/index.html#operations-User-post_api_v1_User_password_reset_change
		// TODO : Afficher un toaster de succ�s (ou d'erreur), puis rediriger l'utilisateur vers la page de login au bout de 5 secondes ? (� voir avec H�l�ne)
	}, [props.location.search]);

	const afterSubmit = () => {
		navigate("/");
	}

	return (
		<>
			<ResetChangePassword
				validPass={validPass}
				setValidPass={setValidPass}
				setResetPassword={setResetPassword}
				setShowPass={setShowPass}
				showPass={showPass}
				token={token}
				username={username}
				afterSubmit={afterSubmit}
			/>
		</>
	)
}

export default StepResetPassword;