import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import Subtitles from "../basics/Subtitles";
import CheckBoxRound from "../basics/CheckboxRound";
import Input from "../basics/Input";
import StepCounter from "../../components/signup/signup-items/StepCounter";
import InputSelect, { AdelOption } from "adel-shared/dist/components/basics/InputSelect";

import { IStepFourModel } from "../../models/signup";
import { Controller, useForm } from "react-hook-form";
import { ActionTypeEnum } from "../../context/ActionType";
import useValidation from '../../custom-hooks/useValidation';
import { useAppDispatch, useStepFourContext, useStepOneContext } from "../../context/context-helpers";
import { RouteComponentProps } from "@reach/router";
import { Civilite, TypePoste } from '../../services/generated/FrontOffice-api';
import { useTranslation } from "react-i18next";
import { formatPhone } from 'adel-shared/dist/utils/functions';

interface StepFourProps extends RouteComponentProps {
	value?: IStepFourModel;
	ref: any;
	setInput?: any;
	checkInput?: any;
	setCheckInput?: any;
	setStepFour?: any;
	setStepFive?: any;
	setCanGoNext: (value: boolean) => void;
}

const StepFour: React.FunctionComponent<StepFourProps> = forwardRef((props, ref) => {
	const [defaultCheckbox, setDefaultCheckbox] = useState(true);
	const [otherCheckbox, setOtherCheckbox] = useState(false);
	const defaultContact = useStepOneContext();
	const [civilite, setCivilite] = useState<Civilite>(Civilite.Monsieur);
	const context = useStepFourContext();
	const { register, getValues, triggerValidation, control, errors } = useForm<IStepFourModel>({ defaultValues: context })
	const { getRootValidator } = useValidation();
	const contactValidator = getRootValidator("CreateContactValidator");
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const myProp = props;

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			const result = await triggerValidation();
			if (result) {
				dispatch({
					type: ActionTypeEnum.SET_SIGNUP_STEP_FOUR, payload:
						getValues().hasCorrespondantParDefaut
							? {
								hasCorrespondantParDefaut: getValues().hasCorrespondantParDefaut,
								email: null,
								nom: null,
								poste: null,
								prenom: null,
								telephoneFixe: null,
								telephonePortable: null,
								civilite: Civilite.Monsieur,
							}
							: {
								hasCorrespondantParDefaut: getValues().hasCorrespondantParDefaut,
								email: getValues().email,
								nom: getValues().nom,
								poste: getValues().poste,
								prenom: getValues().prenom,
								telephoneFixe: getValues().telephoneFixe,
								telephonePortable: getValues().telephonePortable,
								civilite: civilite,
							}
				});
			}
			return result;
		}
	}));

	const handleCheckbox = () => {
		setDefaultCheckbox(!defaultCheckbox);
		setOtherCheckbox(!otherCheckbox)
	}

	useEffect(() => {
		setCivilite(context.civilite);
		setDefaultCheckbox(context.hasCorrespondantParDefaut);
		setOtherCheckbox(!context.hasCorrespondantParDefaut);
		myProp.setCanGoNext(true);
	}, [myProp, context])

	const options = () => {
		let allOptions: AdelOption<Civilite>[] = [];

		for (let civilite in Civilite) {
			if(civilite === "None") continue;
			allOptions.push(
				{
					value: Civilite[civilite as keyof typeof Civilite],
					label: t(`common.civilite.${Civilite[civilite as keyof typeof Civilite]}`)
				}
			)
		}
		return allOptions;
	};

	const posteOptions = ()  => {
		let allOptions: AdelOption<TypePoste>[] = [];
		for (let poste in TypePoste) {
			if(poste === "None") continue;
			if(poste === "President") continue;
			if(poste === "Gerant") continue;
			allOptions.push(
				{
					value: TypePoste[poste as keyof typeof TypePoste],
					label: t(`common.typePoste.${TypePoste[poste as keyof typeof TypePoste]}`)
				}
			)
		}
		return allOptions;
	}

	return (
		<>

			<StepCounter
				counter={4}
			/>

			<Subtitles
				title={t('signup.step-four.title')}
			/>

			<div className="defaultContact">
				<CheckBoxRound
					name="hasCorrespondantParDefaut"
					reference={register}
					id="stepFour__checkBox"
					key="1"
					label={t('signup.step-four.default-correspondant')}
					sublabel=""
					checked={defaultCheckbox}
					onChange={handleCheckbox}
				/>

				<div className="defaultCheckbox">
					<span>{t('signup.step-four.president')} {defaultContact.presidentPrenom} </span>
					<span>{defaultContact.presidentNom} - </span>
					{defaultContact.telephoneFixe && (
						<span>{formatPhone(defaultContact.telephoneFixe)} - </span>
					)}
					{defaultContact.telephonePortable && (
						<span>{formatPhone(defaultContact.telephonePortable)} - </span>
					)}
					<span>{defaultContact.email}</span>
				</div>
			</div>

			<CheckBoxRound
				name="hasAutreCorrespondant"
				reference={register}
				id="stepFour__checkBox--label"
				key="2"
				label={t('signup.step-four.other-correspondant')}
				sublabel=""
				checked={otherCheckbox}
				onChange={handleCheckbox}
			/>

			{otherCheckbox &&
				<div className="contact">
					<section className="contact__item contact__civility">
						<Controller control={control}
							name="discipline"
							rules={contactValidator && contactValidator["Poste"]}
							as={({ onChange, onBlur, value, name }) => (
								<InputSelect<TypePoste>
									name={name}
									label={t('signup.step-four.poste')}
									classname="inputSelectFO"
									options={posteOptions()}
									errors={errors}
									onChange={onChange}
									value={value}
									placeholder={t("common.select")}
								/>
							)}
						/>

						<InputSelect<Civilite>
							name="civilite"
							label={t('signup.step-four.civilite')}
							classname="inputSelectFO"
							options={options()}
							errors={errors}
							onChange={setCivilite}
							value={civilite}
							placeholder={t("common.select")}
						/>
					</section>

					<section className="contact__item contact__name">
						<Input
							name="nom"
							reference={register(contactValidator?.["Nom"])}
							label={t('signup.step-four.lastname')}
							type="text"
							placeHolder="Nom de famille"
							maxLength={60}
							errors={errors}
						/>

						<Input
							name="prenom"
							reference={register(contactValidator?.["Prenom"])}
							label={t('signup.step-four.firstname')}
							type="text"
							placeHolder="Prénom"
							maxLength={60}
							errors={errors}
						/>
					</section>

					<section className="contact__item contact__phone">
						<Input
							name="telephoneFixe"
							reference={register(contactValidator?.["TelephoneFixe"])}
							label={t('signup.step-four.fix-phone')}
							type="text"
							placeHolder=""
							maxLength={15}
							errors={errors}
						/>

						<Input
							name="telephonePortable"
							reference={register(contactValidator?.["TelephonePortable"])}
							label={t('signup.step-four.phone2')}
							type="text"
							placeHolder=""
							maxLength={15}
							errors={errors}
						/>
					</section>

					<section className="contact__email">
						<Input
							name="email"
							reference={register(contactValidator?.["Email"])}
							label={t('signup.step-four.email')}
							type="text"
							placeHolder=""
							maxLength={120}
							errors={errors}
						/>
					</section>

				</div>
			}
		</>
	)
})

export default StepFour;