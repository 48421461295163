import React, { useState, useEffect } from 'react';
import RenderBlockContent from './infosProjet/RenderBlockContent';
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
const DescriptionProjet = ({ details, lang }) => {
    const { i18n } = useTranslation();
    const [blocks, setBlocks] = useState([]);
    const [answers, setAnswers] = useState({});
    useEffect(() => {
        if (details) {
            setBlocks(details.questionsBlocks);
            setAnswers(details.answers);
        }
    }, [details]);
    const renderBlockContent = (block) => {
        return (React.createElement(RenderBlockContent, { block: block, answers: answers, key: block.id, lang: lang }));
    };
    const renderBlock = (block, i) => {
        var _a;
        return (React.createElement("div", { className: clsx(block.title && 'detailsTab__content'), id: "block-" + block.id, key: i },
            block.title && (React.createElement("h4", { className: "title--red" }, (_a = block.title) === null || _a === void 0 ? void 0 : _a[i18n.language])),
            React.createElement("section", null, renderBlockContent(block))));
    };
    return (React.createElement(React.Fragment, null, blocks.filter(b => b.id !== "donnees-bancaires" && b.id !== "pieces-jointes-obligatoires").map(renderBlock)));
};
export default DescriptionProjet;
