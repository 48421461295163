import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from 'react-loader-spinner';
import { useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { RouteComponentProps, navigate, useParams, Link, useLocation } from "@reach/router";
import { AuthenticatedRoute } from "../../containers/AuthenticatedRoute";
import Structures from '../../containers/structures/Structure';
import { ActionTypeEnum } from '../../context/ActionType';
import { AppStateContext } from '../../context/AppContext';
import { useAxios } from '../../custom-hooks/useAxios';
import { CurrentDossierInfoDto, DossierClient, EtapeDemandeVersement, ModeVersement, StatutDossier, StatutVersement, TypeVersement, VersementClient } from '../../services/generated/FrontOffice-api';
import ModalValidateCancel from "../basics/ModalValidateCancel";
import Subtitles from '../basics/Subtitles';

interface VersementsListeProps extends RouteComponentProps {
}

interface ButtonVersement {
	type: TypeVersement;
	mode: ModeVersement;
}

const VersementsListe: React.FunctionComponent<VersementsListeProps> = () => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const location = useParams();
	const originalId = location.dossierId;
	const versementClient = new VersementClient("", axiosInstance);
	const [context, dispatch] = useContext(AppStateContext);
	const dossierClient = new DossierClient("", axiosInstance);
	
	const [sort, setSort] = useState<string>("");
	const [data, setData] = useState<any[]>([]);
	const [isVersementLoading, setIsVersementLoading] = useState<boolean>(false);

	const [buttonList, setButtonList] = useState<ButtonVersement[]>([]);

	const [isModalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const versementDeleteId = useRef<string>(null);

	const [numeroDossier, setNumeroDossier] = useState<string>();
	const [numeroDossierModal, setNumeroDossierModal] = useState<boolean>(false);
	const [infoDossier, setInfoDossier] = useState<CurrentDossierInfoDto>();

	const getInfosDossier = async () => {
		try {
			const result = await dossierClient.getCurrentDossierInfo(originalId);
			setInfoDossier(result);
		} catch(error) {
			console.error(error)
		}
	}

	useEffect(() => {
		(async function () {
			await getVersements(sort);
		})();
	}, [sort]);

	useEffect(() => {
		(async function () {
			await getInfosDossier();
		})();
	}, [originalId]);

	const getVersements = async (sort: string) => {
		try {
			const result = await versementClient.getVersements(originalId, "", sort, 1, 10, true);
			const data = result.versements?.items.map(v =>
			({
				Statut: (
					<span className={`table__statutItem table__statut--${v.statutVersement}`}>
						{t(`versements.statut.${v.dateDerniereSoumission && v.statutVersement == StatutVersement.Soumis ? 'modifie' : v.statutVersement}`)}
					</span>
				),
				Type: (
					<span data-type={v.typeVersement}>
						{t(`versements.type.${v.typeVersement}`)}
					</span>
				),
				DateDeSoumission: moment(v.dateSoumission).format('DD/MM/YYYY'),
				Id: v.id,
				StatutOrigin: v.statutVersement,
				DossierId: v.dossierId,
				OriginalDossierId: originalId
			}));
			setData(data);

			if (result.numeroDossierToDo) {
				setNumeroDossierModal(true);
				setNumeroDossier(result.numeroDossierToDo);
			}

			const arrayTypes = result.typesVersements;
			const arrayModes = result.modesVersements;

			let array: ButtonVersement[] = [];
			arrayTypes?.forEach(t => {
				arrayModes?.forEach(m => {
					(t !== "none" && m !== "none") && array.push({
						type: t as TypeVersement,
						mode: m as ModeVersement
					})
				})
			});
			setButtonList(array);
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			throw error;
		}
	}

	const onCloseNumeroModal = () => {
		setNumeroDossierModal(false);
		setNumeroDossier(undefined);
	}


	const goToStatut = useCallback((id: string, dossierId: string, originalDossierId: string, type: TypeVersement) => {
		const current = context?.versementStates[id]?.currentStep ? context.versementStates[id].currentStep : EtapeDemandeVersement.InformationsProjet;
		dispatch({
			type: ActionTypeEnum.UPDATE_VERSEMENT_STATE, payload: {
				versementId: id,
				versementType: type,
				dossierId,
				originalDossierId,
				state: { currentStep: current }
			}
		});
		navigate(`/Versements/Statut/${id}`);
	}, [dispatch, navigate]);

	const goToEditVersement = (id: string, dossierId: string, originalDossierId: string, type: TypeVersement) => {
		const current = context?.versementStates[id]?.currentStep ? context.versementStates[id].currentStep : EtapeDemandeVersement.InformationsProjet;
		dispatch({
			type: ActionTypeEnum.UPDATE_VERSEMENT_STATE, payload: {
				versementId: id,
				versementType: type,
				dossierId,
				originalDossierId,
				state: { currentStep: current }
			}
		});
		navigate(`/Versements/Creation/${current}`);
	}


	const goToCreateVersement = useCallback(async (type: TypeVersement, mode: ModeVersement) => {
		if (mode && type) {
			setIsVersementLoading(true);
			try {
				const body = {
					type: type,
					mode: mode
				}
				let newVersement = await versementClient.createVersement(originalId, body);

				if (mode !== ModeVersement.Automatique) {
					navigate(`/Versements/Creation/${EtapeDemandeVersement.ValidationStructure}`);
					dispatch({
						type: ActionTypeEnum.UPDATE_VERSEMENT_STATE, payload: {
							versementId: newVersement.id,
							versementType: type,
							dossierId: newVersement.dossierId,
							originalDossierId: originalId,
							state: { currentStep: EtapeDemandeVersement.ValidationStructure }
						}
					});
				} else {
					toast.success(t("versements.createSuccess"));
				}

				setIsVersementLoading(false);

			} catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
				setIsVersementLoading(false);
				throw error;
			}
		}

	}, [dispatch, navigate]);

	const handleDeleteValidate = useCallback(async () => {
		setIsDeleteLoading(true);

		try {
			await versementClient.deleteVersement(versementDeleteId.current);
			setData(data.filter(x => x.Id !== versementDeleteId.current));
			toast.success(t('versements.deleteSuccess'));
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}

		setIsDeleteLoading(false);
		setModalDeleteOpen(false);
	}, [
		data,
		setData,
		setIsDeleteLoading,
		setModalDeleteOpen,
		versementDeleteId
	]);

	const handleDeleteClick = useCallback((id: string) => {
		versementDeleteId.current = id;
		setModalDeleteOpen(true);
	}, [setModalDeleteOpen, versementDeleteId]);

	const handleCancelDelete = useCallback(() => {
		setModalDeleteOpen(false);
	}, [setModalDeleteOpen]);

	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{ Header: t('versements.table.date'), accessor: "DateDeSoumission", sortType: "basic" },
					{ Header: t('versements.table.type'), accessor: "Type", disableSortBy: true },
					{ Header: t('versements.table.statut'), accessor: "Statut", disableSortBy: true },
					{
						Header: "", accessor: "Actions", disableSortBy: true,
						Cell: (value: any) => (
							<span className="versements__tableButtons">
								{(value.cell.row.values.StatutOrigin === StatutVersement.Brouillon || value.cell.row.values.StatutOrigin === StatutVersement.Modification) &&
									<div className="versements__tableIcon" onClick={() => goToEditVersement(value.cell.row.values.Id, value.cell.row.values.DossierId, value.cell.row.values.OriginalDossierId, value.cell.row.values.Type.props['data-type'])}>
										<i className="far fa-edit"></i>
									</div>
								}
								{value.cell.row.values.StatutOrigin !== StatutVersement.Brouillon &&
									<div className="versements__tableIcon">
										<i className="far fa-eye" onClick={() => goToStatut(value.cell.row.values.Id, value.cell.row.values.DossierId, value.cell.row.values.OriginalDossierId, value.cell.row.values.Type.props['data-type'])}></i>
									</div>
								}
								{value.cell.row.values.StatutOrigin === StatutVersement.Brouillon &&
									<div className="versements__tableIcon" onClick={() => handleDeleteClick(value.cell.row.values.Id)}>
										<i className="far fa-trash-alt"></i>
									</div>
								}
							</span>
						)
					},
					{ Header: "Id", accessor: "Id" },
					{ Header: "DossierId", accessor: "DossierId" },
					{ Header: "OriginalDossierId", accessor: "OriginalDossierId" },
					{ Header: "StatutOrigin", accessor: "StatutOrigin" }
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ["Id", "DossierId", "OriginalDossierId", "StatutOrigin"]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);

    const canCreateVersementSolde = () => {
		let canCreateSolde = data.some(item => item.Type.props['data-type'] == TypeVersement.Acompte && (item.Statut.props['children'].toLowerCase() != StatutVersement.Brouillon));	
		return canCreateSolde
	}
	return (
		<AuthenticatedRoute>
			<Structures>
				<>
					<div className="folders versements versementsListe">
						<span className="navigationFil">
							<Link to="/Structures">
								<span className="navigationFil__item">
									{t("navigation.folder")}
								</span>
							</Link>

							<span className="navigationFil__separator">|</span>

							<span className="navigationFil__item">
								{t('versements.title')} - {context.currentEditingDossier.nom}
							</span>
						</span>
						{<div className="folders__title">
							<Subtitles
								title={`${t('versements.title')} - ${context.currentEditingDossier.nom}`}
							/>
							{isVersementLoading
								? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
								: (infoDossier?.dossierStatut.toLowerCase() != StatutDossier.Reaffecte) && <div>
									{buttonList.map((item, i) => 										
										(
											(item.type == TypeVersement.Solde && canCreateVersementSolde() || item.type != TypeVersement.Solde) &&
											<div key={i} className="folders__addButton" onClick={() => goToCreateVersement(item.type, item.mode)}>
											<i className="far fa-plus"></i>
											<span>{t('versements.create')}{t(`versements.createType.${item.type}`)}</span>
										</div>
									))}
								</div>
							}
						</div>}
						<div className="versementsListe__table">
							<Table
								data={data}
								getTableProps={getTableProps}
								getTableBodyProps={getTableBodyProps}
								headerGroups={headerGroups}
								rows={rows}
								prepareRow={prepareRow}
								styles={styleTable.page}
							/>
						</div>
					</div>
					<ModalValidateCancel
						isOpen={isModalDeleteOpen}
						onCancel={handleCancelDelete}
						onValidate={handleDeleteValidate}
						isLoading={isDeleteLoading}
						validateButtonLabel={t('common.validate')}
					>
						<div className="modal__item">
							<p>{t('versements.delete')}</p>
						</div>
					</ModalValidateCancel>

					<ModalValidateCancel
						isOpen={numeroDossierModal}
						onCancel={onCloseNumeroModal}
						onValidate={onCloseNumeroModal}
						hasValidateButton={false}
					>
						<div className="modal__item">
							<p>{t('versements.numeroDossier', { numero: numeroDossier })}</p>
						</div>
					</ModalValidateCancel>
				</>
			</Structures>
		</AuthenticatedRoute>
	);
}

export default VersementsListe;
