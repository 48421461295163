import Pagination from 'adel-shared/dist/components/basics/Pagination';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import _ from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { RouteComponentProps, navigate } from "@reach/router";
import { ActionTypeEnum } from '../../../context/ActionType';
import { useAppStateContext } from "../../../context/context-helpers";
import { useAxios } from '../../../custom-hooks/useAxios';
import { Dictionary } from '../../../models';
import { DossierClient, DossierDtoSortedFilteredPage, StatutDossier } from "../../../services/generated/FrontOffice-api";
import Filters from "../../basics/Filters";
import ModalValidateCancel from '../../basics/ModalValidateCancel';
import StructuresFolderCardHeaderActions from './StructuresFolderCardHeaderActions';
import { CategorieDossier } from 'adel-shared/dist/enums/CategorieDossier';
import { paginationNumbers } from '../../../constants/config.constant';

interface StructuresContentProps extends RouteComponentProps {
	categorieTypeImgDic: Dictionary<any>;
}

const StructuresContent: FunctionComponent<StructuresContentProps> = ({categorieTypeImgDic}) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const dossierClient = new DossierClient("", axiosInstance);
	const [{dossiers = {}}, dispatch] = useAppStateContext();
	const [data, setData] = useState<Array<any>>([]);

	/** Delete */
	const [isModalOpen, opeModal, closeModal] = useBooleanState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [dossierIdToDelete, setDossierIdToDelete] = useState<string>();


	/** Filtres */
	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(paginationNumbers.p1);

	/** Pagination */
	const [hasNext, setHasNext] = useState<boolean>(false);
	const [hasPrevious, setHasPrevious] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState<boolean>(false);
	const [isLast, setIsLast] = useState<boolean>(true);
	const [totalPageCount, setTotalPageCount] = useState<number>();
	const [totalItemCount, setTotalItemCount] = useState<number>();

	//loader dossier
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchDossiers = useMemo(() => _.debounce((filters: string, sort: string, page: number, pageSize: number, disabledPagination: boolean) => {
		dossierClient.getDossiers(filters, sort, page, pageSize, disabledPagination).then((result: DossierDtoSortedFilteredPage) => {
			dispatch({
				type: ActionTypeEnum.SET_DOSSIERS_LIST_SUCCESS,
				payload: { dossiers: result }
			});
			setTotalPageCount(result?.totalPageCount);
			setTotalItemCount(result?.totalItemCount);
			setHasNext(result?.hasNext);
			setHasPrevious(result?.hasPrevious);
			setIsFirst(result?.isFirst);
			setIsLast(result?.isLast);
			setIsLoading(false);
		});
	}), []);

	useEffect(() => {
		(async () => {
            await debounceFetchDossiers(filters, sort, page, pageSize, false);
        })()
	}, [sort, page, pageSize ]);

	useEffect(() => {
		(async () => {
			setPage(1);
			await debounceFetchDossiers(filters, sort, 1, pageSize, false);
        })()
	}, [filters]);

	useEffect(() => {
		if (dossiers) {
			const data = dossiers.items?.map(dossier => {
				return {
					type: (
						<div className='folders__tableStatut'>
							<img src={categorieTypeImgDic[dossier.categorieCode]} alt="" /> <span>{dossier.categorieNom}</span>
						</div>
					),
					alerte: "", // TODO: câbler ça
					numero: dossier.numero,
					nom: dossier.nom,
					id: dossier.id,
					statut: dossier.statut,
					canVersement: dossier.canVersement,
					categorieId: dossier.categorieId,
					categorieCode: dossier.categorieCode,
					convention: dossier.convention,
					courrierAgrement: dossier.courrierAgreement,
					lettreRefus: dossier.lettreRefus,
					blocageAvis: dossier.blocageAvis
				}
			});
			setData(data);
		}
	}, [dossiers]);

	/** Congifure Table */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: t("common.numero"),
						accessor: "numero",
						sortType: "basic"
					},
					{
						Header: t("common.nomDossier"),
						accessor: "nom",
						sortType: "basic"
					},
					{
						Header: "Type",
						accessor: "type",
						disableSortBy: true
					},
					{
						Header: t("common.statut.label"),
						accessor: "statut",
						disableSortBy: true,
						Cell: (value: any) => (
							<div className={`structures__foldercardStatus structures__foldercardStatus--${value.value}`}>
								{t(`statutDossier.${value.value}`)}
							</div>
						)
					},
					{
						accessor: "options",
						disableSortBy: true,
						Cell: (value: any) => (
							<div className="folders__tableOptions">
								{value.cell.row.values.convention &&
									value.cell.row.values.blocageAvis === false &&
									<span className="folderCard__ButtonTable">
										<span className="folderCard__footerButton folderCard__footerButton--convention">
											<a href={value.cell.row.values.convention} target="_blank" rel="">
												<i className="fas fa-file" ></i>
											</a>
										</span>
										<div className="folderCard__footerTooltip folderCard__footerTooltip--convention">
											{t("versements.convention")}
										</div>
									</span>
								}
								{value.cell.row.values.courrierAgreement &&
									value.cell.row.values.blocageAvis === false &&
									<span className="folderCard__ButtonTable">
										<span className="folderCard__footerButton folderCard__footerButton--ca">
											<a href={value.cell.row.values.courrierAgreement} target="_blank" rel="">
												<i className="fas fa-envelope"></i>
											</a>
										</span>
										<div className="folderCard__footerTooltip folderCard__footerTooltip--ca">
											{t("versements.courrierAgrement")}
										</div>
									</span>
								}
								{value.cell.row.values.lettreRefus &&
									value.cell.row.values.blocageAvis === false &&
									(<span className="folderCard__ButtonTable">
										<span className="folderCard__footerButton folderCard__footerButton--ca">
											<a href={value.cell.row.values.lettreRefus} target="_blank" rel="">
												<i className="far fa-envelope"></i>
											</a>
										</span>
										<div className="folderCard__footerTooltip folderCard__footerTooltip--ca">
											{t("versements.courrierAgrement-refuse")}
										</div>
									</span>
								)}
								{value.cell.row.values.canVersement &&
									(value.cell.row.values.statut === StatutDossier.Accepte || value.cell.row.values.statut === StatutDossier.Solde) &&
										value.cell.row.values.blocageAvis === false
									&& <span className="folderCard__footerButton" onClick={() =>
										goToVersement(
											value.cell.row.values.id,
											value.cell.row.values.categorieId,
											value.cell.row.values.categorieCode,
											value.cell.row.values.nom,
											value.cell.row.values.numero
										)
									}>
										<i className="far fa-usd-circle"></i>
									</span>
								}
								<StructuresFolderCardHeaderActions
									messages={0}
									onView={() => handleView(value.cell.row.values.id)}
									onDuplicate={() => handleDuplicate(value.cell.row.values.id)}
									onEdit={value.cell.row.values.statut === StatutDossier.Brouillon ? () => onEdit(value.cell.row.values.id, value.cell.row.values.categorieId, value.cell.row.values.numero) : undefined}
									onAbandon={value.cell.row.values.statut === StatutDossier.Brouillon ? () => { openDeleteModal(value.cell.row.values.id) } : undefined}
								/>
							</div>
						)
					},
					{ accessor: "id" },
					{ accessor: "canVersement" },
					{ accessor: "convention" },
					{ accessor: "courrierAgrement" },
					{ accessor: "lettreRefus" },
					{ accessor: "categorieId" },
					{ accessor: "categorieCode" },
					{ accessor: "blocageAvis" }
				]
			}
		];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: [
					"id",
					"canVersement",
					"categorieId",
					"categorieCode",
					"convention",
					"courrierAgrement",
					"lettreRefus",
					"blocageAvis"
				]
			},
			manualSortBy: true
		} as any,
		useSortBy
	);

	const sortBy = (state as any).sortBy;

	const onChangeSort = (value: any) => {
		if (value && value.length > 0) {
			if (value[0].desc) {
				setSort(`-${value[0].id}`);
			} else {
				setSort(value[0].id);
			}
		} else {
			setSort("");
		}
	}

	useEffect(() => {
		onChangeSort(sortBy);
	}, [onChangeSort, sortBy]);



	/** Actions */
	const handleView = (id:string) => {
		navigate(`/Dossiers/Details/${id}`);
	};

	const onEdit = (dossierId:string, categorieId:string, numero:string) => {
		dispatch({
			type: ActionTypeEnum.EDIT_DOSSIER,
			payload: {
				dossierId,
				categorieId,
				numero
			}
		});
		navigate(`/Dossiers/Creation`);
	}

	const onAbandon = () => {
		dispatch({ type: ActionTypeEnum.DELETE_DOSSIER_REQUEST });
		(async function () {
			setIsDeleteLoading(true);
			try {
				await dossierClient.deleteDossier(dossierIdToDelete);
				closeModal();
				setIsDeleteLoading(false);
				dispatch({
					type: ActionTypeEnum.DELETE_DOSSIER_SUCCESS,
					payload: { dossierId: dossierIdToDelete }
				});
				setDossierIdToDelete(undefined);
				await debounceFetchDossiers(filters, sort, page, pageSize, false);
			} catch (error) {
				setIsDeleteLoading(false);

				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		})()
	}

	const closeDeleteModal = () => {
		closeModal();
		setDossierIdToDelete(undefined);
	}

	const openDeleteModal = (id:string) => {
		opeModal();
		setDossierIdToDelete(id);
	}

	const goToVersement = useCallback((dossierId:string, categorieId:string, categorieCode:CategorieDossier, nom:string, numero:string) => {
		dispatch({
			type: ActionTypeEnum.EDIT_DOSSIER,
			payload: {
				dossierId,
				categorieId,
				categorieCode,
				nom,
				numero
			}
		});
		navigate(`/Versements/${dossierId}`);
	}, [dispatch, navigate]);

	const handleDuplicate = async(dossierId:string) => {
		try {
			const { id, categorieId, nom, numero } = await dossierClient.duplicationDossier(dossierId);
			dispatch({
				type: ActionTypeEnum.EDIT_DOSSIER,
				payload: {
					dossierId: id,
					categorieId,
					nom,
					numero
				}
			})
			navigate('/Dossiers/Creation');
		} catch(error) {
			if(error.code === 'categorieDossierQuotaExceeded') {
				toast.error(t('createFolder.type.quotaAtteintError'));
			} else {
				toast.error(t('errors.default'));
			}

			if (error.exception?.message)
				toast.error(error.exception.message);
		}
	};

	return <>
		<div className="folders__filters">
			<Filters filters={filters} setFilters={setFilters} />
		</div>
		<div className="folders__table">
			<Table
				data={data}
				getTableProps={getTableProps}
				getTableBodyProps={getTableBodyProps}
				headerGroups={headerGroups}
				rows={rows}
				prepareRow={prepareRow}
				styles={styleTable.page}
			/>
			<Pagination
				hasNext={hasNext}
				hasPrevious={hasPrevious}
				isFirst={isFirst}
				isLast={isLast}
				totalPageCount={totalPageCount}
				totalItemCount={totalItemCount}
				page={setPage}
				pageSize={setPageSize}
				initPageSize={pageSize}
				initPage={page}
				pageNumbers={paginationNumbers}
			/>
			<ModalValidateCancel
				isOpen={isModalOpen}
				onCancel={closeDeleteModal}
				onValidate={onAbandon}
				validateButtonLabel={t('common.delete')}
				isLoading={isDeleteLoading}
			>
				<div className="modal__item">
					<p>{t('structure.dossiers.delete')}</p>
				</div>
			</ModalValidateCancel>
		</div>
	</>
};
export default StructuresContent;