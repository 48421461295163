import React, { useState, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import CreatePassword from "../structures/portail/mdp/CreatePassword";

enum StepCreatePasswordQueryParams {
	Token = "token",
	UserName = "userName",
}

const StepCreatePassword = (props: RouteComponentProps) => {
	const [token, setToken] = useState<string>(null);
	const [username, setUsername] = useState<string>(null);
	const [, setResetPassword] = useState<boolean>(false);
	const [validPass, setValidPass] = useState<boolean>(false);
	const [showPass, setShowPass] = useState<boolean>(false);

	useEffect(() => {
		var searchParams = new URLSearchParams(props.location.search);

		// Retrieve token + userName from url
		let token = searchParams.get(StepCreatePasswordQueryParams.Token);
		let userName = searchParams.get(StepCreatePasswordQueryParams.UserName);

		setToken(token);
		setUsername(userName);
	}, [props.location.search]);

	const afterSubmit = () => {
		navigate("/");
	}

	return (
		<>
			<CreatePassword
				validPass={validPass}
				setValidPass={setValidPass}
				setResetPassword={setResetPassword}
				setShowPass={setShowPass}
				showPass={showPass}
				token={token}
				username={username}
				afterSubmit={afterSubmit}
			/>
		</>
	)
}

export default StepCreatePassword;