import React from "react";

interface WelcomeProps {
    title: string,
}

const Subtitles: React.FunctionComponent<WelcomeProps> =  ({title}) => {
    return (
        <h2 className="modal__title">{title}</h2>
    )
}

export default Subtitles;