import React, { useState, useRef, useEffect, useContext, useMemo } from "react";

import { AuthenticatedRoute } from '../../containers/AuthenticatedRoute';
import Structures from '../../containers/structures/Structure';
import { RouteComponentProps, navigate, Router } from '@reach/router';

import { useTranslation } from 'react-i18next';
import Subtitles from '../basics/Subtitles';
import FormButton from 'adel-shared/dist/components/basics/FormButton';

import ValidationPart1 from './creationDossier/ValidationPart1';
import ValidationPart2 from './creationDossier/ValidationPart2';
import Criteres from './creationDossier/Criteres';
import TypeDossier from './creationDossier/TypeDossier';
import ChiffresCles from './creationDossier/ChiffresCles';
import LieuxDates from './creationDossier/LieuxDates';
import Enseignants from './creationDossier/Enseignants';
import Description from './creationDossier/Description';
import Validation from './creationDossier/Validation';
import { useDossierSteps } from '../../custom-hooks/useSteps';
import { CreationDossierSteps, EtapeCreationPlus } from '../../context/CreationDossierSteps';

import { AppStateContext } from '../../context/AppContext';
import {
	StructureClient,
	DossierClient,
	EtapeCreationDossier,
	CreateLieuDto,
	LieuxDto,
	CurrentDossierInfoDto,
	CategorieDossierDto
} from '../../services/generated/FrontOffice-api';
import { useAxios } from '../../custom-hooks/useAxios';
import { UpdateLieuToSend } from '../../models/IDossier';

import FEST from "../../assets/img/categorieDossier/FEST.png";
import BO from "../../assets/img/categorieDossier/BO.png";
import DRAMA from "../../assets/img/categorieDossier/DRAMA.png";
import ECOLE from "../../assets/img/categorieDossier/ECOLE.png";
import FILM from "../../assets/img/categorieDossier/FILM.png";
import INT from "../../assets/img/categorieDossier/INT.png";
import MUSICAL from "../../assets/img/categorieDossier/MUSICAL.png";
import PROMO from "../../assets/img/categorieDossier/PROMO.png";
import FORTI from "../../assets/img/categorieDossier/FORTI.png";
import INTGEN from "../../assets/img/categorieDossier/INTGEN.png";
import SOIRART from "../../assets/img/categorieDossier/SOIRART.png";
import DIFFLIEU from "../../assets/img/categorieDossier/DIFFLIEU.png";
import AIDERES from "../../assets/img/categorieDossier/AIDERES.png";
import DIFFAVIG from "../../assets/img/categorieDossier/DIFFAVIG.png";
import ASA from "../../assets/img/categorieDossier/ASA.png";
import AUTRE from "../../assets/img/categorieDossier/AUTRE.png";
import { Dictionary } from 'adel-shared/dist/models';
import { ActionTypeEnum } from '../../context/ActionType';
import { Link } from '@reach/router';
import { toast } from "react-toastify";
import ContratsCachets from "./creationDossier/ContratsCachets";
import InfosProjet from "./creationDossier/InfosProjet";
import SalarieArtistes from "./creationDossier/SalarieArtistes";
import Trajets from "./creationDossier/Trajets";
import Budget from "./creationDossier/Budget";
import Loader from 'react-loader-spinner';
import clsx from 'clsx';
import { CategorieDossier } from '../../enums/Dossiers';
import MessageValidation from "./MessageValidation";

export interface StepRef {
	validateForm: () => Promise<boolean>;
}

interface CreationDossierProps extends RouteComponentProps {
}

const CreationDossier: React.FunctionComponent<CreationDossierProps> = () => {
	const { t } = useTranslation();
	const [context, dispatch] = useContext(AppStateContext);
	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);
	const dossierClient = new DossierClient("", axiosInstance);

	// States des infos à envoyer/récupérer à l'API
	const [newPrestation, setNewPrestation] = useState<CreateLieuDto>({});
	const [modifyPrestation, setModifyPrestation] = useState<UpdateLieuToSend>({ lieu: {}, id: '' });


	// Navigation step
	const { steps, getNextStep, mergeSteps, isHigherOrCurrentStep, getStepIndex } = useDossierSteps();
	const [canGoNext, setCanGoNext] = useState<boolean>(false); // Pour les boutons Suivant
	const [isFormValid, setIsFormValid] = useState<boolean>(false); // Pour les boutons Valider


	//
	const [dossierId, setDossierId] = useState<string>(context.currentEditingDossier.id);
	const [categorieDossierId, setCategorieDossierId] = useState<string>(context.currentEditingDossier.categorieId);
	const [currentStep, setCurrentStep] = useState<CreationDossierSteps>(EtapeCreationDossier.ValidationStructure); // Initialized from context in the below useEffect
	const [stepsInitialized, setStepsInitialized] = useState<boolean>(false);
	const [isRedirectedFromValidationStep, setIsRedirectedFromValidationStep] = useState<boolean>(false);
	const [categorieDossier, setCategorieDossier] = useState<CategorieDossierDto>({});

	const dynamicStepsLoaded = useMemo(() => { return steps.length > 4; }, [steps]);

	// Checkbox
	const [criteriaRead, setCriteriaRead] = useState<boolean>(false);

	// Validation & refs
	const refs: { [key: string]: React.MutableRefObject<StepRef>; } = {
		[EtapeCreationDossier.ValidationStructure]: useRef<StepRef>(null),
		[EtapeCreationPlus.ValidationStructure2]: useRef<StepRef>(null),
		[EtapeCreationDossier.Type]: useRef<StepRef>(null),
		[EtapeCreationDossier.Criteres]: useRef<StepRef>(null),
		[EtapeCreationDossier.InformationsProjet]: useRef<StepRef>(null),
		[EtapeCreationDossier.LieuxDates]: useRef<StepRef>(null),
		[EtapeCreationDossier.ChiffresCles]: useRef<StepRef>(null),
		[EtapeCreationDossier.SalarieArtistes]: useRef<StepRef>(null),
		[EtapeCreationDossier.ContratsCachets]: useRef<StepRef>(null),
		[EtapeCreationDossier.Enseignants]: useRef<StepRef>(null),
		[EtapeCreationDossier.Budget]: useRef<StepRef>(null),
		[EtapeCreationDossier.DescriptionProjet]: useRef<StepRef>(null),
		[EtapeCreationDossier.Validation]: useRef<StepRef>(null),
		[EtapeCreationDossier.Trajets]: useRef<StepRef>(null)
	};

	// Images des icônes de catégories
	const categorieTypeImgDic: Dictionary<any> = {
		FEST,
		BO,
		DRAMA,
		ECOLE,
		FILM,
		INT,
		MUSICAL,
		PROMO,
		FORTI,
		INTGEN,
		SOIRART,
		ASA,
		DIFFLIEU,
		AIDERES,
		DIFFAVIG,
		AUTRE
	}


	/** LieuxDates Post & view */
	const [isEditMode, setEditMode] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [lieuxList, setLieuxList] = useState<LieuxDto[]>([]);

	const goBackToViewMode = async () => {
		if (refs[currentStep] && refs[currentStep].current) {
			let isValid = await refs[currentStep].current.validateForm();
			if (isValid) setEditMode(false);
		}
	}

	/** Criterias & Create Dossier */
	const createDossier = async () => {
		try {
			dispatch({ type: ActionTypeEnum.CREATE_DOSSIER_REQUEST });
			var dossier = await dossierClient.createDossier({ categorieId: categorieDossierId });
			let firstStepAfterDossierCreation = getNextStep(EtapeCreationDossier.Criteres);
			dispatch({
				type: ActionTypeEnum.CREATE_DOSSIER_SUCCESS, payload: {
					dossierId: dossier.id,
					categorieId: dossier.categorieId,
					state: {
						currentStep: firstStepAfterDossierCreation
					}
				}
			})
			setDossierId(dossier.id);
			setCategorieDossierId(dossier.categorieId);
		} catch (error) {
			if (error.exception?.message) {
				toast.error(error.exception.message);
			}
		}
	}

	useEffect(() => {
		if (dossierId === null || dossierId === undefined || dossierId === "") {
			if (criteriaRead) (async function () { await createDossier() })();
		}
	}, [criteriaRead]); // VFA: Pas convaincu de l'approche pour gérer le state des critères de dossier.

	// Initialize currentStep from context
	useEffect(() => {
		if (!stepsInitialized && dynamicStepsLoaded) {
			if (dossierId) {
				let firstStepAfterDossierCreation = getNextStep(EtapeCreationDossier.Criteres);
				setCurrentStep(context.dossierStates[dossierId] ? context.dossierStates[dossierId]?.currentStep : firstStepAfterDossierCreation);
				getInfosDossier();
			}
			setStepsInitialized(true);
		}
	}, [dynamicStepsLoaded, stepsInitialized]);

	useEffect(() => {
		if (categorieDossierId) {
			(async function () {
				const types = await structureClient.getStructureCategoriesDossier(); // Pour récupérer de nom de la catégorie où on est
				const steps = await dossierClient.getEtapesCreationDossier(categorieDossierId);
				mergeSteps(steps);
				const categorie = types.find(c => c.id === categorieDossierId);
				if (categorie) {
					setCategorieDossier(categorie);
				}
			})();
		}
	}, [categorieDossierId])

	useEffect(() => {
		if (!dossierId || dossierId && stepsInitialized) {
			console.debug(`useEffect navigate to ${currentStep}`);
			navigate(`/Dossiers/Creation/${currentStep}`);
			dossierId && dispatch({ type: ActionTypeEnum.UPDATE_DOSSIER_STATE, payload: { dossierId: dossierId, categorieId: categorieDossierId, state: { currentStep: currentStep } } });
		}
	}, [currentStep, stepsInitialized]);



	/** Info dossier */
	const [infoDossier, setInfoDossier] = useState<CurrentDossierInfoDto>();
	const getInfosDossier = async () => {
		try {
			const result = await dossierClient.getCurrentDossierInfo(dossierId);
			setInfoDossier(result);
		} catch(error) {
			console.error(error)
		}
	}

	const nextStep = async () => {
		if (refs[currentStep] && refs[currentStep].current) {
			try {
				let isValid = await refs[currentStep].current.validateForm();		
				
				let nextStep = getNextStep(currentStep);

				/** Si le currentStep est valide OU si le currentStep est valide et n'a pas de changement au niveau de ses documents */
				if (isValid) {
					setCurrentStep(nextStep);
				}
			} catch(error) {
				if (error.exception?.message) {
					toast.error(error.exception.message);
				}
			}
		} else {
			toast.error("nextStep was called but references were not initialized. Please contact your Dev Team.");
		}
	}

	const navigateToStep = async (targetStep: CreationDossierSteps): Promise<void> => {
		setIsRedirectedFromValidationStep(false); // reinit value
		// If trying to access to current step : do nothing
		if (targetStep === currentStep) return;

		// If trying to access to already filled step, allow navigation
		if (isHigherOrCurrentStep(targetStep, currentStep)) {
			setIsRedirectedFromValidationStep(true);
			setCurrentStep(targetStep);
			return;
		}

		await nextStep();
	}

	// Validate button label
	const labelValidateButton = () => {
		switch(currentStep) {
			case EtapeCreationDossier.ValidationStructure:
				return t('createFolder.buttons.validate1');
			case EtapeCreationPlus.ValidationStructure2:
				return t('createFolder.buttons.validate2');
			case EtapeCreationDossier.Validation:
				return t('createFolder.buttons.submit');
			default:
				return t('createFolder.buttons.next');
		}
	}

	const isIGDerivates = () => {
       return categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantLabelisationReseauSPEDIDAM1 
	          || categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantLabelisationReseauSPEDIDAM2
	          || categorieDossier.code === CategorieDossier.CreationAideSalons
	          || categorieDossier.code === CategorieDossier.CreationSoutienFestival
	          || categorieDossier.code === CategorieDossier.EducationArtistiqueCulturelleAideActionsEducation
			   || categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantRemisePrixArtistique
			   || categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantRemiseEmissionTeleviseeMusicale
	}

	const renderMenu = () => {
		// Show static menu (3 static steps) when current step index < 4 || when additional dynamic steps are loaded
		if (getStepIndex(currentStep) >= 0 && getStepIndex(currentStep) < 4 || dynamicStepsLoaded)
			return <ul className="creationDossier__menu">
				<li className={clsx({"active": (isHigherOrCurrentStep(EtapeCreationDossier.ValidationStructure, currentStep) || isHigherOrCurrentStep(EtapeCreationPlus.ValidationStructure2, currentStep)) })}
					onClick={() => isHigherOrCurrentStep(EtapeCreationDossier.ValidationStructure, currentStep) && navigateToStep(EtapeCreationDossier.ValidationStructure)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("createFolder.menu.validationStructure")}
				</li>
				<li className={clsx({"active": isHigherOrCurrentStep(EtapeCreationDossier.Type, currentStep) })}
					onClick={() => isHigherOrCurrentStep(EtapeCreationDossier.Type, currentStep) && navigateToStep(EtapeCreationDossier.Type)}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("createFolder.menu.type")}
				</li>
				<li className={clsx({"active": isHigherOrCurrentStep(EtapeCreationDossier.Criteres, currentStep) })}
					onClick={() => {
						isHigherOrCurrentStep(EtapeCreationDossier.Criteres, currentStep) && navigateToStep(EtapeCreationDossier.Criteres)
					}}
				>
					<span className="creationDossier-menu__check">
						<i className="fas fa-check"></i>
					</span>{t("createFolder.menu.criteres")}
				</li>
				{// On commence à partir de l'index 4 car les quatres premiers correspondent aux steps fixes du dessus
					dynamicStepsLoaded && steps && steps.slice(4).map((step, i) => (
						<li key={i} className={clsx({"active": isHigherOrCurrentStep(step, currentStep) })}
							onClick={() => isHigherOrCurrentStep(step, currentStep) && navigateToStep(step)}
						>
							<span className="creationDossier-menu__check"><i className="fas fa-check"></i></span>{
								categorieDossier.code === CategorieDossier.Fortissimo && step === EtapeCreationDossier.LieuxDates
								? t("createFolder.menu.lieuDate")
								: t(`createFolder.menu.${step}`, {text: isIGDerivates() ? " (le cas échéant)" : ""})
							}
						</li>
					))
				}
			</ul>
	}

	return (
		<AuthenticatedRoute>
			<Structures>
				<div className="creationDossier">
					<span className="navigationFil">
						<Link to="/Structures">
							<span className="navigationFil__item">
								{t("navigation.folder")}
							</span>
						</Link>

						<span className="navigationFil__separator">|</span>

						<span className="navigationFil__item">
							{t("navigation.createFolder")}
						</span>
					</span>
					<Subtitles title={t("createFolder.title")} />

					{infoDossier &&
						<div className="creationDossier__infoDossier">
							{infoDossier.structureNom} - {infoDossier.commissionNom} - {t("createFolder.form.categorie")} {infoDossier.categorieNom} 
						</div>
					}
					<div className="creationDossier__wrapper">
						{renderMenu()}


						<div className="creationDossier__content">
								<MessageValidation 
								dossierId={dossierId}
								/>
							<Router>
								<ValidationPart1
									ref={refs[currentStep]}
									path={EtapeCreationDossier.ValidationStructure}
									setCanGoNext={setCanGoNext}
									setIsLoading={setIsLoading}
								/>
								<ValidationPart2
									ref={refs[currentStep]}
									path={EtapeCreationPlus.ValidationStructure2}
									setCanGoNext={setCanGoNext}
									validateOnLoad={isRedirectedFromValidationStep}
									setIsLoading={setIsLoading}
								/>
								<TypeDossier
									ref={refs[currentStep]}
									path={EtapeCreationDossier.Type}
									categorieDossierId={categorieDossierId}
									setCategorieDossierId={setCategorieDossierId}
									categorieTypeImgDic={categorieTypeImgDic}
									setCanGoNext={setCanGoNext}
									dossierId={dossierId}
									setIsLoading={setIsLoading}
									showQuotaAtteintError={isRedirectedFromValidationStep}
								/>
								<Criteres
									ref={refs[currentStep]}
									path={EtapeCreationDossier.Criteres}
									categorieDossierId={categorieDossierId}
									canGoNext={canGoNext}
									setCanGoNext={setCanGoNext}
									setCriteriaRead={setCriteriaRead}
									dossierId={dossierId}
									setIsLoading={setIsLoading}
								/>
								{steps?.filter(item => item === EtapeCreationDossier.InformationsProjet).length !== 0 &&
									<InfosProjet ref={refs[currentStep]}
										path={EtapeCreationDossier.InformationsProjet}
										dossierId={dossierId}
										categorieDossierId={categorieDossierId}
										setCanGoNext={setCanGoNext}
										nomCategorie={categorieDossier.nom}
										setIsLoading={setIsLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.LieuxDates).length !== 0 &&
									<LieuxDates
										ref={refs[currentStep]}
										path={EtapeCreationDossier.LieuxDates}
										dossierId={dossierId}
										lieuxList={lieuxList}
										categorieDossierCode={categorieDossier.code}
										lieuxDatesEditMode={isEditMode}
										setLieuxDatesEditMode={setEditMode}
										setIsFormValid={setIsFormValid}
										setNewPrestation={setNewPrestation}
										setModifyPrestation={setModifyPrestation}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										isLoading={isLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.ChiffresCles).length !== 0 &&
									<ChiffresCles
										ref={refs[currentStep]}
										path={EtapeCreationDossier.ChiffresCles}
										dossierId={dossierId}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.SalarieArtistes).length !== 0 &&
									<SalarieArtistes
										ref={refs[currentStep]}
										path={EtapeCreationDossier.SalarieArtistes}
										dossierId={dossierId}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										setEditMode={setEditMode}
										setIsFormValid={setIsFormValid}
										isLoading={isLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.ContratsCachets).length !== 0 &&
									<ContratsCachets
										ref={refs[currentStep]}
										path={EtapeCreationDossier.ContratsCachets}
										dossierId={dossierId}
										categorieDossierId={categorieDossierId}
										setIsFormValid={setIsFormValid}
										setEditMode={setEditMode}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										codeCategorie={categorieDossier.code as CategorieDossier}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.Trajets).length !== 0 &&
									<Trajets
										ref={refs[currentStep]}
										path={EtapeCreationDossier.Trajets}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										setEditMode={setEditMode}
										setIsFormValid={setIsFormValid}
										isLoading={isLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.Enseignants).length !== 0 &&
									<Enseignants
										ref={refs[currentStep]}
										path={EtapeCreationDossier.Enseignants}
										dossierId={dossierId}
										setIsFormValid={setIsFormValid}
										setEditMode={setEditMode}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.Budget).length !== 0 &&
									<Budget
										ref={refs[currentStep]}
										path={EtapeCreationDossier.Budget}
										dossierId={dossierId}										
										categorieDossier={categorieDossier}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.DescriptionProjet).length !== 0 &&
									<Description
										ref={refs[currentStep]}
										path={EtapeCreationDossier.DescriptionProjet}
										dossierId={dossierId}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
									/>
								}
								{steps?.filter(item => item === EtapeCreationDossier.Validation).length !== 0 &&
									<Validation
										ref={refs[currentStep]}
										path={EtapeCreationDossier.Validation}
										dossierId={dossierId}
										setCanGoNext={setCanGoNext}
										setIsLoading={setIsLoading}
										navigateToStep={navigateToStep}
									/>
								}
							</Router>

							<div className="creationDossier__footer">
								<span>
									{t('createFolder.form.mandatory-fields')}
								</span>
								{currentStep === EtapeCreationDossier.Validation && (
									<FormButton
										className="button__submit--draft"
										type="button"
										value="Sauvegarder au brouillon"
										onClick={() => navigate('/Structures')}
									/>
								)}
								{!isEditMode && (!isLoading
									? <FormButton
										type="button"
										value={labelValidateButton()}
										onClick={nextStep}
										disabled={!canGoNext}
									/>
									: <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>)
								}
								{isEditMode && (!isLoading
									? <FormButton
										type="button"
										value={t('createFolder.buttons.validate')}
										onClick={goBackToViewMode}
										disabled={!isFormValid}
									/>
									: <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>)
								}
							</div>
						</div>
					</div>
				</div>
			</Structures>
		</AuthenticatedRoute>
	);
};

export default CreationDossier;