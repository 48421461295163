import {FunctionComponent, useContext, useEffect} from 'react';
import { PageVitrineClient } from '../services/generated/FrontOffice-api';
import { useAxios } from '../custom-hooks/useAxios';
import { ActionTypeEnum } from '../context/ActionType';
import { AppStateContext } from "../context/AppContext";
import { useUserContext } from '../context/context-helpers';

const AppInitialize: FunctionComponent = () => {
	const axiosInstance = useAxios();
	const userContext = useUserContext();
	const pageVitrineClient = new PageVitrineClient("", axiosInstance);
	const [,dispatch] = useContext(AppStateContext);

	useEffect(() => {
		(async() => {
			await pageVitrineClient.getPagesVitrine().then(result => {
				dispatch({
					type: ActionTypeEnum.GET_PAGES_VITRINE_SUCCESS,
					payload: {
						pagesVitrine: result
					}
				});
			})
		})();
	}, [userContext]);

	return null;
}

export default AppInitialize;
