import React, { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import CorrespondantEdit from './CorrespondantEdit';
import { AppStateContext } from '../../../context/AppContext';

interface MonCompteCorrespondantProps extends RouteComponentProps {
}

const MonCompteCorrespondant: React.FunctionComponent<MonCompteCorrespondantProps> = () => {
	const { t } = useTranslation();
	const [context, dispatch] = useContext(AppStateContext);
	const [editInput, setEditInput] = useState<boolean>(false);

	const structureDetails = context.structure;
	const codePostal = structureDetails.adresseCorrespondance?.codePostal || structureDetails.adresse?.codePostal;
	const ville = structureDetails.adresseCorrespondance?.ville || structureDetails.adresse?.ville;

	const getAdresse = () => {
		let key: 'adresseCorrespondance' | 'adresse';
		if(structureDetails.adresseCorrespondance) {
			key = 'adresseCorrespondance';
		} else {
			key = 'adresse';
		}
		return `${structureDetails[key].line1} ${structureDetails[key].line2 ? ' - ' + structureDetails[key].line2 : ''}`;
	};

	return (
		<>
			<div className="monCompte__header">
				<h3>{t("myAccount.adresse")}</h3>
				{!editInput && (
					<i
						className="far fa-edit"
						title="Modifier"
						role="button"
						onClick={() => setEditInput(true)}
					></i>
				)}
			</div>
			{editInput ? (
				<CorrespondantEdit
					structureDetails={structureDetails}
					setEditInput={setEditInput}
				/>
			) : (
				<div className="defaultContact">
					<div className="readOnly">
						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.langue")}</label>
								<input type="text" value={`${t(`common.${structureDetails.codeLangue}`)}`} disabled />
							</div>
						</div>
						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.adresse")}</label>
								<input type="text" value={getAdresse()} disabled />
							</div>
							<div className="input">
								<label>{t("myAccount.form.codePostal")}</label>
								<input type="text" value={codePostal} disabled />
							</div>
						</div>
						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.ville")}</label>
								<input type="text" value={ville} disabled />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
};

export default MonCompteCorrespondant;
