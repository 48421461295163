import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import BancaireEdit from './BancaireEdit';
import { StructureDetailsDto } from '../../../services/generated/FrontOffice-api';
import DonneesBancaireReadonly from './DonneesBancaireReadonly';

interface MonCompteBancaireProps extends RouteComponentProps {
    ref: any;
    defaultValue?: StructureDetailsDto;
	setStructureDetails: (value:StructureDetailsDto) => void;
}

const MonCompteBancaire: React.FC<MonCompteBancaireProps> = ({defaultValue, setStructureDetails}) => {
    const { t } = useTranslation();
	const [editInput, setEditInput] = useState<boolean>(false);
    const [isIbanValid, setIsIbanValid] = useState<boolean>(false);

	useEffect(() => {
		if(defaultValue)
			setIsIbanValid(true);
	}, [defaultValue]);

	const openEdit = () => {
		if(defaultValue)
			setIsIbanValid(true);
		setEditInput(true);
	}

    return (
        <div>
            <div className="monCompte__header">
                <h3>{t("myAccount.infoBancaire")}</h3>
				{!editInput && defaultValue.donneesBancaires?.canEditRib && (
					<i className="far fa-edit" onClick={openEdit}></i>
				)}
			</div>

			{defaultValue.donneesBancaires && <>
				{editInput && defaultValue.donneesBancaires.canEditRib
					? <BancaireEdit setEditInput={setEditInput} defaultValue={defaultValue} setDefaultValue={setStructureDetails} setIsIbanValid={setIsIbanValid} isIbanValid={isIbanValid} />
					: <DonneesBancaireReadonly donneesBancaires={defaultValue.donneesBancaires} /> 
				}
			</>}
        </div>
    )
};

export default MonCompteBancaire;
