import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { ErrorMessage } from "react-hook-form";
export function arrayToOptions(items, nameEval, t) {
    return items.map(item => {
        return {
            label: eval(nameEval),
            value: item
        };
    });
}
function InputSelect({ label, hasHtmlInLabel = false, name, classname, options, onChange, position, value, errors, customMatchValue, onTryToSelectADisabledValue, isSearchable = false, isMulti = false, isClearable = false, errorMessageCusto, readonly, selectAll = false, placeholder, isDisabled = false, styles = {}, isNomcourt = false }) {
    const [selectedValue, setSelectedValue] = useState(value);
    const [selectOptions, setSelectOptions] = useState(options);
    const allOption = {
        value: 'all',
        label: typeof selectAll === 'string' ? selectAll : 'Toutes les options'
    };
    const handleChange = (value, actionMeta) => {
        if (isMulti) {
            if (value && value.some(x => x.value === 'all')) {
                setSelectedValue(options);
                onChange(options);
                setSelectOptions(selectOptions.slice(1));
            }
            else {
                onChange(value);
                setSelectedValue(value);
            }
        }
        else {
            let adelOption = getAdelOptionFromReactSelectValue(value);
            setSelectOptions(options);
            if (adelOption && !adelOption.disabled) {
                setSelectedValue(value);
                if (onChange && (adelOption === null || adelOption === void 0 ? void 0 : adelOption.value)) {
                    onChange(adelOption.value);
                }
            }
            else {
                console.debug("Trying to select a disabled value");
                onTryToSelectADisabledValue && onTryToSelectADisabledValue(adelOption.value);
            }
        }
    };
    useEffect(() => {
        setSelectedValue(value);
    }, [value]);
    useEffect(() => {
        if (selectOptions && value && !isMulti) {
            let selectedOption = selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions.filter(option => {
                if (!customMatchValue) { // Aucune comparaison custom de dÃƒÂ©finie = type simple (string, number, bool, etc.))
                    return option.value === value;
                }
                else { // Comparaison de type complexe (objets, etc.)
                    return customMatchValue(option.value, value);
                }
            });
            if (selectedOption.length > 0) {
                setSelectedValue(selectedOption[0]);
            }
            else {
                //TODO: Replace this ?
                // console.debug("value could not be found", value);
            }
        }
    }, [value, selectOptions, customMatchValue]); // Executed only once for default value
    useEffect(() => {
        if (isMulti && selectAll && (selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.length) < (options.length - 1) && !selectOptions.some(x => x.value === 'all')) {
            setSelectOptions([
                allOption,
                ...selectOptions
            ]);
        }
    }, [selectedValue]);
    const getAdelOptionFromReactSelectValue = (selectValue) => {
        if (selectValue) {
            if (isMulti) {
                return selectOptions.find(option => option.value == selectValue.value);
            }
            else {
                return options.find(option => option.value == selectValue.value);
            }
        }
        else {
            return null;
        }
    };
    const [colorInput, setColorInput] = useState('');
    const [focusInput, setFocusInput] = useState('');
    const isLocalhost = Boolean(window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
    useEffect(() => {
        const url = window.location.href;
        if (isLocalhost) {
            setColorInput("#5E6A6D");
            setFocusInput("#EDEDED");
        }
        else {
            const link = url.substring(0, url.indexOf('.net'));
            if (link.indexOf('dev') != -1) {
                setColorInput("#5E6A6D");
                setFocusInput("#EDEDED");
            }
            else if (link.indexOf('int') != -1) {
                setColorInput("#760E0E");
                setFocusInput("#F1F1F1");
            }
            else if (link.indexOf('prod') != -1) {
                setColorInput("#5B788C");
                setFocusInput("#EDEDED");
            }
        }
    }, []);
    const customStyles = {
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? colorInput : state.isFocused ? focusInput : "white",
            color: state.isSelected ? "white" : "#333"
        })
    };
    const { Option, SingleValue } = components;
    const customOption = (props) => (React.createElement(Option, Object.assign({}, props),
        React.createElement("div", { className: `inputSelect__option ${props.data.hasClass} ${props.data.disabled && "inputSelect__option--disabled"}` },
            React.createElement("div", { className: "inputSelect-option__item", title: props.data.labelLong },
                props.data.icon &&
                    React.createElement("i", { className: `${props.data.icon} ${selectedValue.value === props.data.value ? "item--active" : ""}` }),
                props.data.img &&
                    React.createElement("img", { className: "inputSelect__img", src: props.data.img, alt: "" }),
                React.createElement("span", null, props.data.label)),
            React.createElement("div", null, props.data.button))));
    const singleValue = (props) => (React.createElement(SingleValue, Object.assign({}, props),
        React.createElement("div", { className: props.data.img ? "inputSelect__singleValue-wrapper" : "" },
            props.data.img &&
                React.createElement("img", { className: "inputSelect__img", src: props.data.img, alt: "" }),
            props.data.label)));
    return (React.createElement("div", { className: "input" },
        label &&
            hasHtmlInLabel
            ? React.createElement("label", { className: "input__label" },
                React.createElement("span", { dangerouslySetInnerHTML: { __html: label } }))
            : React.createElement("label", { className: "input__label" }, label),
        React.createElement(Select, { className: `${classname} ${readonly && 'inputSelect--readonly'} ${position ? `inputSelect--${position}` : ''}`, options: isMulti ? selectOptions : options, value: selectedValue, onChange: handleChange, isSearchable: isSearchable, isMulti: isMulti, isClearable: isClearable, styles: { ...customStyles, ...styles }, components: { Option: customOption, SingleValue: singleValue }, menuIsOpen: readonly ? false : undefined, placeholder: placeholder || "", isDisabled: isDisabled }),
        errors &&
            React.createElement(ErrorMessage, { errors: errors, name: name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message)),
        errorMessageCusto && errorMessageCusto !== '' &&
            React.createElement("p", { className: `input__errorMessage input__errorMessage--selectBox` }, errorMessageCusto)));
}
export default InputSelect;
