import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { normalizeDate, sortArrayAsc } from 'adel-shared/dist/utils/functions';
import { debounce } from 'lodash';
import Calendar from "react-calendar";
import { Controller, useForm } from 'react-hook-form';
import Moment from 'react-moment';
import { toast } from "react-toastify";
import { RouteComponentProps } from '@reach/router';
import { idFrance, inputAutocompleteDisabled, nomFrance } from '../../../../constants/config.constant';
import { useAxios } from '../../../../custom-hooks/useAxios';
import {CategorieDossier, DossierView} from '../../../../enums/Dossiers';
import { UpdateLieuToSend } from '../../../../models/IDossier';
import { AdresseClient, CreateLieuDto, GeoAdresseDto, LieuDateDto, LieuDto, PaysDto, PrestationsViewModelDto, TypePrestation } from '../../../../services/generated/FrontOffice-api';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import useBooleanState from "adel-shared/dist/custom-hooks/useBooleanState";

export interface EditLieuxDatesRef {
	validateAndSend: () => Promise<boolean>;
}

interface LieuxDatesEditionProps extends RouteComponentProps {
	ref: any;
	lieuxDatesEditMode: boolean;
	franceByDefault: boolean;
	hasDisableAdresse: boolean;
	setCurrentView: (value: DossierView) => void;

	setIsFormValid: (value: boolean) => void;
	addNewPrestation: (value: CreateLieuDto) => void;
	setModifyPrestation: (value: UpdateLieuToSend) => void;

	existingLieu: LieuDto;
	setExistingLieu: (value: LieuDto) => void;

	existingDates: LieuDateDto[];
	setExistingDates: (value: LieuDateDto[]) => void;

	typeSelected: TypePrestation;
	setTypeSelected: (value: TypePrestation) => void;

	isEditMode: boolean;
	setIsEditMode: (value: boolean) => void;

	categorieDossierCode: string;

	paysOptions: AdelOption<PaysDto>[];
	prestationValidator: any;

	prestationsViewModel: PrestationsViewModelDto;
}

const LieuxDatesEdition: React.FunctionComponent<LieuxDatesEditionProps> = forwardRef(({
	franceByDefault,
	hasDisableAdresse,
	setCurrentView,
	setIsFormValid,
	addNewPrestation,
	setModifyPrestation,
	existingLieu,
	setExistingLieu,
	existingDates,
	setExistingDates,
	typeSelected,
	setTypeSelected,
	categorieDossierCode,
	isEditMode,
	setIsEditMode,
	paysOptions,
	prestationValidator = {},
	prestationsViewModel: {
		typesPrestation,
		dateRange,
		dateMinimum
	}
}, ref) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();

	const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);
	const [pays, setPays] = useState<string>(existingLieu?.pays?.id || idFrance);
	const [defaultActiveStartDate, setDefaultActiveStartDate] = useState<Date | undefined>(dateMinimum && new Date(dateMinimum));

	//Validation
	const {
		register,
		getValues,
		setValue,
		triggerValidation,
		errors,
		control,
		watch
	} = useForm<any>({
		defaultValues: {
			lieu: null,
			jauge: null
		}
	});

	const codepostal = watch('departement');
	const ville = watch('ville');

	useImperativeHandle(ref, () => ({
		async validateAndSend(): Promise<boolean> {
			const result = await triggerValidation();

			if (result) {
				if (!isEditMode) {
					addNewPrestation({
						departement: getValues().departement,
						ville: getValues().ville,
						paysId: getValues().pays,
						adresse: getValues().adresse,
						adresseLabel: adresseLabel && !hasDisableAdresse ? adresseLabel : null,
						adresseId: typeSelected !== TypePrestation.Repetition ? (!adresseId && hasDisableAdresse ? null : adresseId) : null,
						nomLieu: getValues().lieu,
						dates: dates.map(d => normalizeDate(d)),
						typePrestation: typeSelected,
						jauge: getValues().jauge,
						description: getValues().description
					});
					setDates([]);
				} else {
					setModifyPrestation({
						lieu: {
							departement: getValues().departement,
							ville: getValues().ville,
							paysId: getValues().pays,
							adresse: getValues().adresse,
							adresseLabel: adresseLabel && !hasDisableAdresse ? adresseLabel : null,
							adresseId: typeSelected !== TypePrestation.Repetition ? (!adresseId && hasDisableAdresse ? null : adresseId) : null,
							nomLieu: getValues().lieu,
							dates: existingDates.map(d => ({...d, date: normalizeDate(d.date) }) ),
							typePrestation: typeSelected,
							jauge: getValues().jauge,
							description: getValues().description
						},
						id: existingLieu.id
					});
					setIsEditMode(false);
					setExistingDates([]);
					setExistingLieu({});
				}

				setTypeSelected(undefined);
				setAdresseId("");
				setAdresseLabel("");
				setCurrentView(DossierView.List);
				return true;

			} else { console.debug("form error detected"); return false; }
		}
	}));



	/** Button Back Edit mode */
	const goBack = () => {
		setCurrentView(DossierView.List);
		setTypeSelected(undefined);
		setDates([]);
		setDate(null);
		setIsEditMode(false);
		setExistingDates([]);
		setExistingLieu({});

		setAdresseId("");
		setAdresseLabel("");
	}



	/** Dates */
	const [date, setDate] = useState<Date>(null);
	const [isCalendarOpen, showCalendar, closeCalendar] = useBooleanState(false);
	const calendarRef = useRef();
    useOnClickOutside(calendarRef, closeCalendar);

	const deleteDate = (date: Date) => {
		setDates(dates.filter(d => d !== date));
	}
	const deleteExistingDate = (date: LieuDateDto) => {
		setExistingDates(existingDates.filter(d => d.date !== date.date));
	}



	/** Tableau de dates */
	const [dates, setDates] = useState<Date[]>([]);
	const handleDateChange = (date: Date) => {
		date.setUTCMinutes(-date.getTimezoneOffset());
		if (!isEditMode) {
			let array = [...dates];
			let arrayTimeStamp = array.map(x => x.setHours(0,0,0,0));

			if (arrayTimeStamp.indexOf(date.setHours(0,0,0,0)) === -1) {
				array.push(date);
			}
			setDates(array);
		} else {
			let array = [...existingDates];
			let arrayTimeStamp = array.map(x => x.date.setHours(0,0,0,0));

			if (arrayTimeStamp.indexOf(date.setHours(0,0,0,0)) === -1) {
				array.push({
					id: "",
					date: date
				});
			}
			setExistingDates(array);
		}
	}



	/** Select options */
	const options = () => {
		const allOptions = typesPrestation?.map(type => ({
			value: type,
			label: t(`createFolder.lieuxDates.${type}`)
		}));

		if (allOptions?.length === 1) {
			setTypeSelected(allOptions[0].value);
			setIsSelectDisabled(true);
		}
		return allOptions;
	};

	useEffect(() => {
		if ((typeSelected && (categorieDossierCode == CategorieDossier.AidePromotionImage || dates.length !== 0))
			|| (typeSelected && (categorieDossierCode == CategorieDossier.AidePromotionImage || existingDates.length !== 0))
		)
			setIsFormValid(true)
		else
			setIsFormValid(false)
	}, [typeSelected, dates, existingDates]);

	const setValues = async (lieu: LieuDto) => {
		setValue([
			{ "departement": lieu.departement },
			{ "ville": lieu.ville },
			{ "pays": lieu.pays.id },
			{ "adresse": lieu.adresse },
			{ "jauge": lieu.jauge },
			{ "description": lieu.description },
			{ "lieu": lieu.nomLieu }
		]);

		setPays(lieu.pays.id);
		setAdresseId(lieu.adresseId);
		setAdresseLabel(lieu.adresseLabel);
	}
	useEffect(() => {
		if (existingLieu) {
			if (Object.keys(existingLieu).length === 0) {
				setValue([
					{ "pays": paysOptions && paysOptions.find(e => e.label === nomFrance)?.value }
				])
			} else {
				setValues(existingLieu);
			}
		}
	}, [existingLieu]);

	/** Adresse Geolocalisation */
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);
	const [adresseId, setAdresseId] = useState<string>("");
	const [adresseLabel, setAdresseLabel] = useState<string>("");

	const searchAdresse = (input: string) => {
		if (!!input && pays === idFrance) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);
				adresseClient.searchAdresse(`${ville} ${input}`, codepostal).then(results => {
					setGeoAdresse(results);
				});
				onAdresseChange(input);
			}
			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		} else {
			setGeoAdresse([]);
		};
	}

	const onPaysChange = (pays: PaysDto) => {
		if(paysOptions.find(x => x.value === pays).label !== nomFrance) {
			setAdresseId("");
			setAdresseLabel("");
		}
	};

	const onVilleChange = (value: string) => {
		const selectedAdresse = geoAdresse[0];
		if(value !== selectedAdresse?.ville) {
			setAdresseId("");
			setAdresseLabel("");
		}
	};

	const onCodePostalChange = (value: string) => {
		const selectedAdresse = geoAdresse[0];
		if(value !== selectedAdresse?.codePostal) {
			setAdresseId("");
			setAdresseLabel("");
		}
	};

	const onAdresseChange = (label: string) => {
		const geoAdresseFiltered = geoAdresse.filter(adresse => adresse.label === label);

		if (geoAdresseFiltered.length > 0) {
			const selectedAdresse = geoAdresseFiltered[0];
			setValue([
				{ "adresse": `${selectedAdresse.numero} ${selectedAdresse.rue}` },
				{ "departement": selectedAdresse.codePostal },
				{ "ville": selectedAdresse.ville }
			]);
			setAdresseId(selectedAdresse.id);
			setAdresseLabel(selectedAdresse.label);
		} else {
			setAdresseId('');
			setAdresseLabel('');
		}
	}

	return (<>
		<div className="creationDossier__header--alt">
			<div className="navigationFil" onClick={goBack}>
				<span className="navigationFil__item">
					<i className="fas fa-chevron-left"></i>
					{t("createFolder.lieuxDates.back")}
				</span>
			</div>
			<h3 className="title--dark">{t("createFolder.lieuxDates.title")}</h3>
		</div>
		<div className="creationDossier__row creationDossier__lieuxDates">
			{isSelectDisabled
				? <InputReadOnly
					label={t("createFolder.form.categorie")}
					content={typeSelected ? t(`createFolder.lieuxDates.${typeSelected}`) : '-'}
				/>
				: <InputSelect<any>
					name="typePrestation"
					label={`${t('createFolder.form.categorie')}*`}
					classname="inputSelect"
					options={options()}
					errors={errors}
					onChange={(selected) => {
						setTypeSelected(selected);
					}}
					value={typeSelected}
					placeholder={t("common.select")}
				/>
			}
			<Controller control={control}
				name="pays"
				as={({ onChange, value, name }) => (
					<InputSelect<PaysDto>
						name={name}
						label={`${t('createFolder.form.pays')}*`}
						classname="inputSelect"
						options={paysOptions}
						errors={errors}
						onChange={(p) => {
							onChange(p);
							onPaysChange(p);
							setPays(p as string);
						}}
						value={value}
						isSearchable={true}
						placeholder={t("common.select")}
						isDisabled={franceByDefault}
					/>
				)}
				rules={prestationValidator && prestationValidator["Pays"]}
			/>
		</div>
		{typeSelected !== TypePrestation.None && (
			<div className="creationDossier__item--singleRows-noPadding">
				<div className="creationDossier__row">
					<Input
						name="lieu"
						label={`${t('createFolder.form.lieu')}*`}
						type="text"
						maxLength={100}
						reference={register(prestationValidator && prestationValidator["NomLieu"])}
						errors={errors}
					/>
				</div>
			</div>
		)}
		<div className="creationDossier__row">
			<Input
				name="ville"
				autoComplete={inputAutocompleteDisabled}
				label={`${t('createFolder.form.ville')}*`}
				type="text"
				reference={register(prestationValidator && prestationValidator["Ville"])}
				onChange={onVilleChange}
				errors={errors}
			/>
			{pays === idFrance && //TODO: pas top mais bien obligé pour détecter la France
				<Input
					name="departement"
					label={t('createFolder.form.departement')}
					type="text"
					maxLength={100}
					reference={register(prestationValidator && prestationValidator["Departement"])}
					onChange={onCodePostalChange}
					errors={errors}
				/>
			}
		</div>
		{typeSelected !== TypePrestation.Repetition && (
			<div className="creationDossier__item--singleRows-noPadding">
				<div className="creationDossier__row">
					<Input
						name="adresse"
						autoComplete={inputAutocompleteDisabled}
						label={`${t('createFolder.form.adresse')}${!hasDisableAdresse ? '*' : ''}`}
						type="text"
						reference={!hasDisableAdresse ? register(prestationValidator && prestationValidator["Adresse"]) : register}
						errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={debounce((value) => searchAdresse(value), 1000)}
					/>
				</div>
			</div>
		)}
		{typeSelected === TypePrestation.Concert &&
			<div className="creationDossier__item--singleRows-noPadding">
				<div className="creationDossier__row">
					<Input
						name="jauge"
						label={`${t('createFolder.form.jauge')}*`}
						type="number"
						min={0}
						reference={register(prestationValidator && prestationValidator["Jauge"])}
						errors={errors}
					/>
				</div>
			</div>
		}
		<div className="creationDossier__item--singleRows-noPadding">
				<div className="creationDossier__row">
					<Input
						name="description"
						label={`${t('createFolder.form.Description')}`}
						type="text"
						reference={!hasDisableAdresse ? register(prestationValidator && prestationValidator["Description"]) : register}
						errors={errors}
					/>
				</div>
			</div>
		<div className="creationDossier__row">
			<div className="input">
				<label>{t('createFolder.form.dates')}</label>
				<div className="inputFile__calendar" onClick={showCalendar} ref={calendarRef}>
					<span>{date && <Moment format="DD/MM/YYYY">{date}</Moment>}</span> <i className="far fa-calendar-alt"></i>
					{isCalendarOpen && // && props.typeSelected !== TypePrestation.Diffusion ou Concert ?
						<Calendar
							className="react-calendar--left-top"
							onChange={(date: Date) => {
								setDate(date);
								handleDateChange(date);
								closeCalendar();
								setDefaultActiveStartDate(undefined);
							}}							
							minDate={new Date(dateMinimum)}
							defaultActiveStartDate={defaultActiveStartDate}
							value={date}
							locale="fr-FR"
						/>
					}
					{isCalendarOpen && dateRange && // && props.typeSelected !== TypePrestation.Diffusion ou Concert ?
						<Calendar
							className="react-calendar--left-top"
							onChange={(dateRange: Array<Date>) => {
								var dateArray = new Array<Date>();
								var startDate = dateRange[0];
								var endDate = dateRange[1];
								var date = startDate;
								for(let i = 0; date < endDate; i++)
								{
									dateArray.push(new Date(date));
									date.setDate(date.getDate() + 1);
									date.setUTCMinutes(-date.getTimezoneOffset());
									date.setHours(0,0,0,0)
								}
								setDates(dateArray);
								closeCalendar();
								setDefaultActiveStartDate(undefined);
							}}
							minDate={new Date(dateMinimum)}
							defaultActiveStartDate={defaultActiveStartDate}
							value={dates.length > 2 ? [dates[0], dates[dates.length - 1]] : null}
							locale="fr-FR"
							selectRange
							returnValue="range"
						/>
					}
				</div>
			</div>
		</div>
		<div className="listItems">
			<span>{t("createFolder.lieuxDates.date-added")} </span>
			{dates
				?.sort(sortArrayAsc)
				.map((date, i) => (
					<span key={i}>
						<Moment format="DD/MM/YYYY">{date}</Moment>
						<i className="far fa-trash-alt" onClick={() => deleteDate(date)}></i>
						{dates.length === 1 || dates.indexOf(date) === dates.length - 1 ? '' : ', '}
					</span>
				))
			}
			{existingDates
				?.sort(sortArrayAsc)
				.map((date, i) => (
					<span key={i}>
						<Moment format="DD/MM/YYYY">{date.date}</Moment>
						<i className="far fa-trash-alt" onClick={() => deleteExistingDate(date)}></i>
						{existingDates.length === 1 || existingDates.indexOf(date) === existingDates.length - 1 ? '' : ', '}
					</span>
				))
			}
		</div>
	</>)
});

export default LieuxDatesEdition;
