import React, { useState, useEffect } from "react";
import { Link } from '@reach/router';

import Lang from "../basics/Lang";
import ColoredLogo from "../../components/logo/ColoredLogo";
import { useUserContext } from '../../context/context-helpers';
import { FOCurrentUserDto, NotificationClient } from '../../services/generated/FrontOffice-api';
import { useAxios } from '../../custom-hooks/useAxios';
import { useTranslation } from 'react-i18next';

interface PublicHeaderProps {
}

const PublicHeader: React.FunctionComponent<PublicHeaderProps> = props => {
	const [user, setUser] = useState<FOCurrentUserDto>({});
	const context = useUserContext();

	useEffect(() => {
		if (context) {
			setUser(context);
		}
	}, [context]);


	return (
		<header>
			<div className="structuresHeader__leftPart">
				{user.id ?
					<Link to="/Structures"><ColoredLogo /></Link>
					:
					<Link to="/"><ColoredLogo /></Link>
				}
			</div>

			<div className="structuresHeader__rightPart messaging">

				{window.location.pathname !== "/Structures" &&
					<span className="structuresHeader__empty-space"></span>
				}

				<div className="structures__lang">
					<Lang />
				</div>
			</div>
		</header>
	)
};
export default PublicHeader;