import React, { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { AdresseClient, GeoAdresseDto, StructureClient, StructureDetailsDto } from "../../../services/generated/FrontOffice-api";
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect from 'adel-shared/dist/components/basics/InputSelect';
import useValidation from '../../../custom-hooks/useValidation';
import { useForm, Controller } from 'react-hook-form';
import { useAxios } from '../../../custom-hooks/useAxios';
import { toast } from 'react-toastify';
import { inputAutocompleteDisabled } from '../../../constants/config.constant';
import _ from 'lodash';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputRadioButton from "adel-shared/dist/components/basics/InputRadioButton";
import { AppStateContext } from '../../../context/AppContext';
import { ActionTypeEnum } from '../../../context/ActionType';

interface CorrespondantEditProps extends RouteComponentProps {
	structureDetails: StructureDetailsDto;
	setEditInput: (value: boolean) => void;
}

const CorrespondantEdit: React.FunctionComponent<CorrespondantEditProps> = ({
	structureDetails,
	setEditInput
}) => {
	const { t } = useTranslation();
	const {
		watch,
		control,
		register,
		getValues,
		setValue,
		triggerValidation,
		errors
	} = useForm({
		defaultValues: {
			codeLangue: structureDetails.codeLangue,
			parDefaut: structureDetails.adresseCorrespondance ? false : true,
			rue: structureDetails.adresseCorrespondance?.line1,
			complementAdresse: structureDetails.adresseCorrespondance?.line2,
			codePostal: structureDetails.adresseCorrespondance?.codePostal,
			ville: structureDetails.adresseCorrespondance?.ville
		}
	});
	const [,dispatch] = useContext(AppStateContext);
	const { getRootValidator } = useValidation();
	const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");
	const axiosInstance = useAxios();
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);
	const {parDefaut, ville, codePostal} = watch(['parDefaut', 'ville', 'codePostal']);

	const searchAdresse = (input: string) => {
		if (!!input) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);
				adresseClient.searchAdresse(`${ville} ${input}`, codePostal).then(results => {
					setGeoAdresse(results);
				});
				onAdresseChange(input);
			}
			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}

	const onAdresseChange = (label: string) => {
		if (!label) {
			setValue([
				{ rue: null },
				{ codePostal: null },
				{ ville: null }
			]);
			return;
		}

		const geoAdresseFiltered = geoAdresse.filter(adresse => adresse.label === label);

		if (geoAdresseFiltered.length > 0) {
			const selectedAdresse = geoAdresseFiltered[0];
			setValue([
				{ rue: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
				{ codePostal: selectedAdresse.codePostal },
				{ ville: selectedAdresse.ville }
			]);
		}
	}

	const handleSubmit = async() => {
		const result = await triggerValidation();

		if(!result) return;

		const client = new StructureClient();

		const {codeLangue, parDefaut, rue, complementAdresse, codePostal, ville} = getValues();
		const body = {
			codeLangue,
			parDefaut,
			autre: {
				line1: rue,
				line2: complementAdresse,
				codePostal,
				ville
			}
		};

		try {
			await client.updateStructureAdresse(body);
			dispatch({
				type: ActionTypeEnum.UPDATE_STRUCTURE_ADDRESS,
				payload: body
			});
			toast.success(t('myAccount.addressSuccess'));
		} catch(error) {
			toast.error(t('myAccount.structureUpdateError'));
			
			if (error.exception?.message)
				toast.error(error.exception.message);
		}
	};

	return (
		<div className="monCompte__edit">
			<Controller
				control={control}
				name="codeLangue"
				as={({onChange, name, value}) => (
					<InputSelect<string>
						name={name}
						label={t("myAccount.form.langue")}
						classname="inputSelect"
						options={[
							{ value: "fr", label: t("common.fr")},
							{ value: "en", label: t("common.en")}
						]}
						errors={errors}
						onChange={onChange}
						value={value}
						placeholder={t("common.select")}
					/>
				)}
			/>

			<div className="monCompte__editCheckbox">
				<Controller
					control={control}
					name="parDefaut"
					as={({ onChange, name, checked }) => (
						<>
							<InputRadioButton
								name={name}
								label="Adresse par défaut"
								id="test"
								checked={checked}
								value="parDefaut"
								onChange={onChange}
							/>
							<div className="defaultCheckbox">
								<span>{structureDetails.adresse.line1}, {structureDetails.adresse.codePostal} {structureDetails.adresse.ville}</span>
							</div>

							<InputRadioButton
								name={name}
								label="Autre adresse"
								id="test2"
								checked={!checked}
								value="autre"
								onChange={(e) => {
									e.target.checked = !e.target.checked;
									onChange(e);
								}}
							/>
						</>
					)}
				/>
			</div>
		
			{!parDefaut && (
				<div className="contact">
					<div className="monCompte__formRow">
						<Input
							name="ville"
							reference={register(adresseValidator?.["Ville"])}
							label={t("myAccount.form.ville")}
							type="text"
							maxLength={60}
							errors={errors}
						/>
						<Input
							name="codePostal"
							reference={register(adresseValidator?.["CodePostal"])}
							label={t("myAccount.form.codePostal")}
							type="tel"
							maxLength={5}
							errors={errors}
						/>
					</div>

					<Input
						autoComplete={inputAutocompleteDisabled}
						name="rue"
						reference={register(adresseValidator?.["Line1"])}
						label={t("myAccount.form.adresse")}
						type="text"
						maxLength={120}
						errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={_.debounce((value) => searchAdresse(value), 1000)}
					/>

					<Input
						name="complementAdresse"
						reference={register(adresseValidator?.["Line2"])}
						label={t("myAccount.form.complementAdresse")}
						type="text"
						maxLength={200}
						errors={errors}
					/>
				</div>
			)}
		<div className="monCompte__editFooter">
			<FormButton
				type="submit"
				value={t('common.cancel')}
				onClick={() => setEditInput(false)}
			/>
			<FormButton
				type="button"
				value={t('common.validate')}
				onClick={handleSubmit}
			/>
		</div>
	</div>);
};

export default CorrespondantEdit;
