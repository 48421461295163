import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import PropertyValidatorInfo from '../models/validation/PropertyValidatorInfo';
import { Dictionary } from '../models/Dictionary';

export default class AdelValidationClient {
	private instance: AxiosInstance;
	private baseUrl: string;
	protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

	constructor(baseUrl?: string, instance?: AxiosInstance) {
		this.instance = instance ? instance : axios.create();
		this.baseUrl = baseUrl ? baseUrl : "";
	}

    /**
     * @return Success
     */
	validation(): Promise<Dictionary<Dictionary<PropertyValidatorInfo[]>>> {
		let url_ = this.baseUrl + "/api/v1/Validation";
		url_ = url_.replace(/[?&]$/, "");

		let options_ = {
			method: "GET",
			url: url_,
			headers: {
				"Accept": "text/plain"
			}
		} as AxiosRequestConfig;

		return this.instance.request(options_)
			.then((_response: AxiosResponse) => {
				if (_response.status === 200) {
					return _response.data;
				} else {
					// TODO : Error handling
				}
			})
			.catch((err) => {
				// TODO : Error handling
			});
	}
}
