import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CreateStructureDto, StructureDto, ApiException, DocumentUploadInfoDto } from "../services/generated/FrontOffice-api";
import { IFile } from '../models/IFile';
import { maxTotalFilesSize } from '../constants/config.constant';
import i18n from 'react-i18next';
import i18next from 'i18next';

export class AdelStructureClient {
	private instance: AxiosInstance;
	private baseUrl: string;
	protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

	constructor(baseUrl?: string, instance?: AxiosInstance) {
		this.instance = instance ? instance : axios.create();
		this.baseUrl = baseUrl ? baseUrl : "";
	}

	/**
	 * @param body (optional)
	 * @return Success
	 */
	structure(body: CreateStructureDto | undefined, files: IFile[]): Promise<StructureDto> {
		let url_ = this.baseUrl + "/api/v1/Structure";
		url_ = url_.replace(/[?&]$/, "");

		const content_ = JSON.stringify(body);

		let formData = new FormData();
		formData.set("json", content_);

		files && files.length > 0 && files.forEach(file => {
			if (file && file.file) {
				formData.append(file.code, file.file);
			}
		})

		let options_ = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		} as AxiosRequestConfig;

		return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
			return this.processStructure(_response);
		});
	}

	protected processStructure(response: AxiosResponse): Promise<StructureDto> {
		const status = response.status;
		let _headers: any = {};
		if (response.headers && typeof response.headers === "object") {
			for (let k in response.headers) {
				if (response.headers.hasOwnProperty(k)) {
					_headers[k] = response.headers[k];
				}
			}
		}
		if (status === 201) {
			const _responseText = response.data;
			let result201: any = null;
			let resultData201 = _responseText;
			result201 = resultData201;
			return result201;
		} else if (status !== 200 && status !== 204) {
			const _responseText = response.data;
			return throwException("An unexpected server error occurred.", status, _responseText, _headers);
		}
		return Promise.resolve<StructureDto>(null as any);
	}

	/**
	 * @param body (optional)
	 * @return Success
	 */
	updateStructureDocuments(body: { documents: DocumentUploadInfoDto[] } | undefined, files: IFile[]): Promise<void> {
		// TODO : Enhance client to upload documents using multi-part

		console.debug("updateStructureDocuments-documents", body.documents);
		console.debug("updateStructureDocuments-files", files);

		let url_ = this.baseUrl + "/api/v1/Structure/structureDocuments";
		url_ = url_.replace(/[?&]$/, "");

		const content_ = JSON.stringify(body);

		let formData = new FormData();
		formData.set("json", content_);

		files && files.length > 0 && files.map(file => {
			if (file.file.size >= 100000000) {
				throwException(i18next.t('errors.largeFile', {maxTotalFilesSize}), 413, "", null);
			}
			return formData.append(file.code, file.file);
		})

		let options_ = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		} as AxiosRequestConfig;

		return this.instance.put(url_, formData, options_).then((_response: AxiosResponse) => {
			return this.processUpdateStructureDocuments(_response);
		});
	}

	protected processUpdateStructureDocuments(response: AxiosResponse): Promise<void> {
		const status = response.status;
		let _headers: any = {};
		if (response.headers && typeof response.headers === "object") {
			for (let k in response.headers) {
				if (response.headers.hasOwnProperty(k)) {
					_headers[k] = response.headers[k];
				}
			}
		}
		if (status === 204) {
			const _responseText = response.data;
			return Promise.resolve<void>(<any>null);
		} else if (status !== 200 && status !== 204) {
			const _responseText = response.data;
			return throwException("An unexpected server error occurred.", status, _responseText, _headers);
		}
		return Promise.resolve<void>(<any>null);
	}
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
	if (result !== null && result !== undefined)
		throw result;
	else
		throw new ApiException(message, status, response, headers, null);
}