import React, { useState } from "react";
import Footer from "./Footer";
import { Link } from '@reach/router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FOCurrentUserDto } from '../../services/generated/FrontOffice-api';

interface MenuBurgerProps {
    menuCheckbox: boolean;
    setMenuCheckbox?: (value:boolean) => void;
	logout?: () => void;
	notifications?: boolean;
}

const MenuBurger: React.FC<MenuBurgerProps> = ({
    menuCheckbox,
    setMenuCheckbox,
	logout,
	notifications
}) => {
	const { t } = useTranslation();

    const handleCheckbox = () => {
        setMenuCheckbox(!menuCheckbox);
    }

    return (
        <div className="menuBurger" >
            <div className={clsx({
				"menuBurger__nav": true,
				"menuBurger__nav--open": menuCheckbox
			})}>
                <input
                    type="checkbox"
                    onClick={handleCheckbox}
                />
                <span className="top"></span>
                <span className="middle"></span>
                <span className="bottom"></span>
            </div>
			{menuCheckbox &&
				<div className="mobile__menu">
					<div className={clsx("mobile__menuItem structures__mailbox", { "structures__mailbox--hasNotif": notifications })}>
						<Link to="/Structures/Messagerie">{t("navigation.mailbox")}</Link>
					</div>
					<div className="mobile__menuItem structuresAccount">
						<Link to="/Compte/Structure">
							<p>{t("navigation.myAccount")}</p>
						</Link>
					</div>
					<div className="mobile__menuItem structuresAccount" onClick={logout} >
						<p>{t("navigation.logout")}</p>
					</div>
					<Footer />
				</div>
			}
        </div>
    )
}

export default MenuBurger;