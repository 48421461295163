import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputFileSingle, { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import _ from 'lodash';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { RouteComponentProps } from '@reach/router';
import { AdelDossierClient } from '../../../clients/AdelDossierClient';
import { AppStateContext } from '../../../context/AppContext';
import { useAxios } from '../../../custom-hooks/useAxios';
import useValidation from '../../../custom-hooks/useValidation';
import { DocumentUploadInfoDto, DossierClient, TrajetDto, TrajetViewModelDto } from '../../../services/generated/FrontOffice-api';
import EditTrajet, { EditTrajetRef } from './trajets/EditTrajet';
import ModaldeleteTrajet from './trajets/ModalDeleteTrajet';

enum View {
	List = "List",
	Edit = "Edit"
}

export enum TrajetsFormMode {
	IS_CREATION = 1,
	IS_EDIT = 3
}

interface TrajetsTable {
	id: string;
	depart: string;
	etape: string;
	arrivee: string;
	artistes: string;
}

interface TrajetsProps extends RouteComponentProps {
	ref: any,
	setCanGoNext: (value: boolean) => void;
	isLoading?: boolean;
	setIsLoading: (value: boolean) => void;
	setEditMode: (value: boolean) => void;
	setIsFormValid: (value: boolean) => void;
}

const Trajets: React.FunctionComponent<TrajetsProps> = forwardRef((props, ref) => {
	const [{currentEditingDossier: dossier}] = useContext(AppStateContext);
	const { t } = useTranslation(); const axiosInstance = useAxios();
	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
	const adelDossierClient = new AdelDossierClient("", axiosInstance);

	const trajetsRef: React.MutableRefObject<EditTrajetRef> = useRef<EditTrajetRef>(null);
	const [currentView, setCurrentView] = useState<View>(View.List);
	const [currentFormMode, setCurrentFormMode] = useState<TrajetsFormMode>(TrajetsFormMode.IS_CREATION);

	const [trajets, setTrajets] = useState<TrajetDto[]>([]);
	const [trajetsTable, setTrajetsTable] = useState<TrajetsTable[]>([]);
	const [trajetSelected, setTrajetSelected] = useState<TrajetDto>();
	const [trajetDeleteId, setTrajetDeleteId] = useState<string>();
	const [document, setDocument] = useState<DocumentWithFile>();

	const [showDeleteTrajet, setShowDeleteTrajet] = useState<boolean>(false);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

	const { getRootValidator } = useValidation();
	const trajetValidator = getRootValidator("CreateOrUpdateTrajetDto");
	const etapeValidator = getRootValidator("CreateOrUpdateEtapeDto");
	const escaleValidator = getRootValidator("CreateOrUpdateEscaleDto");

	const { register, errors, triggerValidation } = useForm<any>({});


	/** Salarié */
	useEffect(() => {
		if (!dossier.id) return;
		props.setCanGoNext(true);
	}, [dossier.id]);

	/** Trajets */
	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(1000);
	const [disabledPagination, setDisabledPagination] = useState<boolean>(false);

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchTrajets = useMemo(() => _.debounce((
		dossierId: string,
		filters: string,
		sort: string,
		page: number,
		pageSize: number,
		disabledPagination: boolean
	) => {
		setIsTableLoading(true);
		dossierClient.getTrajets(
			dossierId,
			filters,
			sort,
			page,
			pageSize,
			disabledPagination,
			undefined
		).then((result: TrajetViewModelDto) => {
			setTable(result.trajets.items);
			if(result.document) {
				setDocument({fileName: result.document.fileName, id: result.document.id});
			}
			setIsTableLoading(false);
		});
	}, 1000), []);

	useEffect(() => {
		debounceFetchTrajets(
			dossier.id,
			filters,
			sort,
			page,
			pageSize,
			disabledPagination
		);
	}, [filters, sort, page, pageSize, disabledPagination]);

	const setTable = (trajets: TrajetDto[]) => {
		setTrajets(trajets);
		let result: TrajetsTable[] = [];

		for (let trajet of trajets) {
			let arrivee = trajet.etapes[trajet.etapes?.length - 1] && trajet.etapes[trajet.etapes?.length - 1].pays.nom;
			let step = trajet.etapes?.length > 2 ? trajet.etapes?.slice(1, trajet.etapes.length-1) : [];
			let destinations = step.map(e => e.pays.nom).join(", ");

			let item:TrajetsTable = {
				id: trajet.id,
				depart: trajet.etapes[0]?.pays.nom as string,
				etape: destinations,
				arrivee: arrivee as string,
				artistes: trajet.artistes.map(e => e.fullName).join(", ")
			}
			result.push(item);
		}
		setTrajetsTable(result);
	}



	/** Pour le bouton Valider de CreationDossier */
	useEffect(() => {
		switch (currentView) {
			case View.List:
				props.setEditMode(false);
				debounceFetchTrajets(
					dossier.id,
					filters,
					sort,
					page,
					pageSize,
					disabledPagination
				);
				break;
			case View.Edit:
				props.setEditMode(true);
				break;
		}
	}, [currentView]);



	/** Trajets actions */
	const editTrajet = async (id:string) => {
		props.setIsLoading(true);
		try {
			const result = await dossierClient.getTrajets(
				dossier.id,
				filters,
				sort,
				page,
				pageSize,
				disabledPagination,
				undefined
			);
			setTrajetSelected(result.trajets.items.find((e:TrajetDto) => e.id === id));
			setCurrentFormMode(TrajetsFormMode.IS_EDIT);
			setCurrentView(View.Edit);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
		}
		props.setIsLoading(false);
	}

	const openDeleteTrajet = (id:string) => {
		setTrajetDeleteId(id);
		setShowDeleteTrajet(true);
	}

	const deleteTrajet = async () => {
		setShowDeleteTrajet(false);
		props.setIsLoading(true);
		try {
			await dossierClient.deleteTrajet(trajetDeleteId);
			await debounceFetchTrajets(
				dossier.id,
				filters,
				sort,
				page,
				pageSize,
				disabledPagination
			);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
		setTrajetDeleteId(undefined);
		props.setIsLoading(false);
	}


	/** Table setup */
	const columns = React.useMemo(() => { // VFA : Je n'arrive pas à trouver le type exact de cet objet... Je laisse en any T_T
	return [{
		Header: "Table",
		columns: [
			{ Header: "Id", accessor: "id", disableSortBy: true },
			{ Header: t('createFolder.trajets.listColumns.depart'), accessor: "depart", disableSortBy: true },
			{ Header: t('createFolder.trajets.listColumns.etape'), accessor: "etape", disableSortBy: true },
			{ Header: t('createFolder.trajets.listColumns.arrivee'), accessor: "arrivee", disableSortBy: true },
			{ Header: t('createFolder.trajets.listColumns.artistes'), accessor: "artistes", disableSortBy: true },
			{
				Header: "", accessor: "Actions", disableSortBy: true,
				Cell: (value: any) => (
					<span className="creationDossier__table-buttons">
						<div className="icon-button" onClick={() => { editTrajet(value.cell.row.values.id) }}><i className="far fa-edit"></i></div>
						<div className="icon-button" onClick={() => { openDeleteTrajet(value.cell.row.values.id) }}><i className="far fa-trash-alt"></i></div>
					</span>
				)}
			]
		}];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable<TrajetsTable>({
		columns: columns,
		data: trajetsTable,
		initialState: { hiddenColumns: ["id"] },
	}, useSortBy);



	/** View List mode */
	const listView = () => {
		return (
			<div className="creationDossier__trajet">
				<div className="creationDossier__header">
					<h3 className="title--dark">{t("createFolder.menu.trajets")}</h3>
				</div>
				<section>
					<div className="creationDossier__singleRightButton">
						<FormButton
							type="button"
							value={t("createFolder.trajets.add")}
							onClick={() => {
								setCurrentView(View.Edit);
								setCurrentFormMode(TrajetsFormMode.IS_CREATION);
							}}
						/>
					</div>
					<div className="creationDossier__item">
					{
						isTableLoading
						? <div className="creationDossier__trajetLoader">
							<Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
						</div>
						: <Table
							data={trajets}
							getTableProps={getTableProps}
							getTableBodyProps={getTableBodyProps}
							headerGroups={headerGroups}
							rows={rows}
							prepareRow={prepareRow}
							styles={styleTable.page}
						/>
					}
					</div>
				</section>
					<div className="creationDossier__header">
						<div className="creationDossier__row">
							<InputFileSingle
								label={t('createFolder.trajets.document')}
								labelButton={t('common.download-file-single')}
								isRequired={true}
								onChange={(e:DocumentWithFile) => {setDocument(e)}}
								currentValue={document}
								name="document"
								reference={register({ required: `${t("validation-messages.required-document")}` })}
								errorMessage={errors}
							/>
						</div>
					</div>
				<section>
				</section>
				<ModaldeleteTrajet
					isOpen={showDeleteTrajet}
					onCancel={() => setShowDeleteTrajet(false)}
					onValidate={() => deleteTrajet()}
					isLoading={props.isLoading}
				/>
			</div>
		)
	}

	/** Views */
	const renderSwitchView = () => {
		switch (currentView) {
			case View.List:
				return listView();
			case View.Edit:
				return <EditTrajet
					ref={trajetsRef}
					setIsFormValid={props.setIsFormValid}
					closeView={() => {
						setCurrentView(View.List);
						setCurrentFormMode(TrajetsFormMode.IS_CREATION)
					}}
					dossierId={dossier.id}
					currentFormMode={currentFormMode}
					trajetSelected={trajetSelected}
					trajetValidator={trajetValidator}
					etapeValidator={etapeValidator}
					escaleValidator={escaleValidator}
				/>
		}
	}

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			props.setIsLoading(true);

			try {
				switch (currentView) {
					case View.List:
						const result = await triggerValidation();

						if(result) {
							try {
								let documentToSend:DocumentUploadInfoDto = {partName: "document"};
								if (document.id) {
									documentToSend = {...documentToSend, id: document.id}
								}
								const validationResult = await adelDossierClient.validateTrajets(
									dossier.id,
									{document: documentToSend},
									{document: !document.id && document.file}
								);
								if (validationResult.dossierWithoutTrajet) {
									toast.error(t('createFolder.trajets.validation.dossierWithoutTrajet'));
									props.setIsLoading(false);
									return false;
								} else if (validationResult.noTrajetFromAimableToAimable) {
									toast.error(t('createFolder.trajets.validation.noTrajetFromAimableToAimable'));
									props.setIsLoading(false);
									return false;
								} else if (validationResult.trajetWithoutArtiste) {
									toast.error(t('createFolder.trajets.validation.trajetWithoutArtiste'));
									props.setIsLoading(false);
									return false;
								} else {
									props.setIsLoading(false);
									return true;
								}
							} catch(error) {
								if (error.exception?.message)
									toast.error(error.exception.message);
								props.setIsLoading(false);
								return false;
							}
						}
						props.setIsLoading(false);
						return false;
					case View.Edit:
						if (trajetsRef && trajetsRef.current) {
							let result = await trajetsRef.current.validateAndSend();
							props.setIsLoading(false);
							return result;
						} else {
							props.setIsLoading(false);
							toast.error("trajetsRef undefined. Please contact your Dev Team.");
						}
				}
				throw "currentView unknown. Please contact your Dev Team.";
			} catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				props.setIsLoading(false);
				return false;
			}

		}
	}));

	return (<>{renderSwitchView()}</>);
});

export default Trajets;