import React from "react"
import { ErrorMessage } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InputPasswordProps {
	name: string;
	reference: any;
	label: string,
	placeHolder: string;
	value?: any;
	onChange?: (value: string) => void;
	errors?: any;
	setShowPass?: any;
	showPass?: boolean;
}

const InputPassword: React.SFC<InputPasswordProps> = ({
	label,
	name,
	placeHolder,
	reference,
	value,
	errors,
	onChange,
	showPass,
	setShowPass,
}) => {

	const showPassword = () => {
		let text = "text";
		let password = "password";

		if (showPass) {
			return text;
		} else {
			return password;
		}
	}

	return (
		<div className="input">
			<label>{label}</label>

			<div className="input__item">
				<input
					name={name}
					ref={reference}
					type={showPassword()}
					placeholder={placeHolder}
					value={value}
					onChange={
						e => {
							if (onChange) onChange(e.target.value)
						}
					}
				/>

				<div className="input__item--icon-button"
					onClick={() => {
						setShowPass(!showPass);
					}}>
					{showPass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
				</div>
			</div>

			{errors &&
				<ErrorMessage errors={errors} name={name}>
					{({ message }) => <p className="input__errorMessage">{message}</p>}
				</ErrorMessage>
			}
		</div>
	)
}

export default InputPassword;