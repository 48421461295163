import React, { useState, forwardRef, useImperativeHandle, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAxios } from "../../custom-hooks/useAxios";
import { useTranslation } from "react-i18next";
import { StructureClient, FormeJuridiqueDto, GeoAdresseDto, AdresseClient, Civilite, TypePoste } from "../../services/generated/FrontOffice-api";
import { useAppDispatch, useStepOneContext } from "../../context/context-helpers";
import { IStepOneModel } from "../../models/signup";
import { ActionTypeEnum } from "../../context/ActionType";
import { RouteComponentProps } from "@reach/router";

import Subtitles from "../basics/Subtitles";
import InputValidation from "../basics/InputValidation";
import useValidation from "../../custom-hooks/useValidation";
import Input from "../basics/Input";
import StepCounter from "./signup-items/StepCounter";
import InputSelect, { AdelOption } from "adel-shared/dist/components/basics/InputSelect";
import _ from 'lodash';
import { toast } from "react-toastify";

interface StepOneProps extends RouteComponentProps {
	ref: any;
	setCanGoNext: (value: boolean) => void;
}

const StepOne: React.FC<StepOneProps> = forwardRef(({
	setCanGoNext
}, ref) => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		triggerValidation,
		control,
		watch,
		errors
	} = useForm<IStepOneModel>({ defaultValues: useStepOneContext() });

	const [siretValidated, setSiretValidated] = useState<boolean>(useStepOneContext()?.isValidated);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formeJuridique, setFormeJuridique] = useState<FormeJuridiqueDto>(useStepOneContext()?.formeJuridique);
	const [formeJuridiques, setFormesJuridiques] = useState<Array<FormeJuridiqueDto>>([]);
	const [formeJuridiqueReadOnly, setFormeJuridiqueReadOnly] = useState<boolean>(useStepOneContext()?.formeJuridiqueReadOnly);
	const [raisonSocialeReadonly, setRaisonSocialeReadonly] = useState<boolean>(false);
	const [civilite, setCivilite] = useState<Civilite>(Civilite.Monsieur);

	const { getRootValidator } = useValidation();
	const createStructureValidator = getRootValidator("CreateStructureDto");
	const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");
	const contactValidator = getRootValidator("CreateContactValidator");

	const context = useStepOneContext();

	const onSubmit = (data: any) => { };

	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);

	const {ville, codePostal} = watch(['ville', 'codePostal']);

	const options = () => {
		let allOptions: AdelOption<Civilite>[] = [];

		for (let civilite in Civilite) {
			if(civilite === "None") continue;
			allOptions.push(
				{
					value: Civilite[civilite as keyof typeof Civilite],
					label: t(`common.civilite.${Civilite[civilite as keyof typeof Civilite]}`)
				}
			)
		}
		return allOptions;
	};


	const optionsPoste = useCallback(() => {
		let allOptions: AdelOption<TypePoste>[] = [];

		const gerant:AdelOption<TypePoste> = {
			value: TypePoste.Gerant,
			label: t("signup.step-one.poste.gerant")
		}

		const president:AdelOption<TypePoste> = {
			value: TypePoste.President,
			label: t("signup.step-one.poste.president")
		}

		const directeur:AdelOption<TypePoste> = {
			value: TypePoste.Directeur,
			label: t("signup.step-one.poste.directeur")
		}

		if (formeJuridique?.code === "SARL") {
			allOptions = [gerant]
		} else if (formeJuridique?.code === "ASSO") {
			allOptions = [president]
		} else {
			allOptions = [directeur, gerant, president]
		}

		return allOptions;

	}, [formeJuridique]);


	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			let result = await triggerValidation();
			result = result && siretValidated;

			if (!getValues().telephoneFixe && !getValues().telephonePortable) {
			    toast.error(t('errors.telephone'));
				return;
			}	
			if (result) {
				dispatch({
					type: ActionTypeEnum.SET_SIGNUP_STEP_ONE, payload: {
						siret: getValues().siret,
						raisonSociale: getValues().raisonSociale,
						adresse: getValues().adresse,
						line2: getValues().line2,
						codePostal: getValues().codePostal,
						ville: getValues().ville,
						formeJuridique,
						presidentPrenom: getValues().presidentPrenom,
						presidentNom: getValues().presidentNom,
						presidentPoste: getValues().presidentPoste,
						telephoneFixe: getValues().telephoneFixe,
						telephonePortable: getValues().telephonePortable,
						codeAPE: getValues().codeAPE,
						nomenclatureAPE: getValues().nomenclatureAPE,
						email: getValues().email,
						isValidated: true,
						civilite,
						formeJuridiqueReadOnly
					}
				});
			}
			return result;
		}
	}));

	const validateSiret = async (): Promise<boolean> => {
		if (!triggerValidation("siret")) {
			return false;
		}

		let { siret } = getValues();
		setIsLoading(true);

		try {
			const structureClient = new StructureClient('', axiosInstance);
			let result = await structureClient.getStructureInfoFromSiret(siret, undefined);

			if (result.isRegisteredWithAdel) {
				toast.error(t('errors.siretAlreadyRegistered'));
				setIsLoading(false);
				return false;
			}

			setSiretValidated(true);

			setValue([
				{ adresse: result.adresse?.line1 },
				{ line2: result.adresse?.line2 },
				{ codePostal: result.adresse?.codePostal },
				{ ville: result.adresse?.ville },
				{ email: "" },
				{
					formeJuridique: result.formeJuridique ? {
						id: result.formeJuridique.id,
						code: result.formeJuridique.code,
						nom: result.formeJuridique.nom
					} : {}
				},
				{ presidentNom: result.nomPresident },
				{ raisonSociale: result.raisonSociale },
				{ siret: result.siret },
				{ telephoneFixe: result.telephoneFixe },
				{ telephonePortable: result.telephonePortable },
				{ codeAPE: result.codeAPE },
				{ nomenclatureAPE: result.nomenclatureAPE }
			]);

			if (!result.formeJuridique || result.formeJuridique?.code === "") {
				setFormeJuridiqueReadOnly(false);
			} else {
				setFormeJuridiqueReadOnly(true);
			}

			setFormeJuridique({
				id: result.formeJuridique?.id ? result.formeJuridique.id : "",
				code: result.formeJuridique?.code ? result.formeJuridique.code : "",
				nom: result.formeJuridique?.nom ? result.formeJuridique.nom : ""
			});

			setRaisonSocialeReadonly(result.raisonSociale ? true : false);
			setIsLoading(false);
			return true;
		}

		catch (error) {
			dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: error } });
			setSiretValidated(false);
			setIsLoading(false);
			
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			return false;
		}
	}

	useEffect(() => {
		const structureClient = new StructureClient('', axiosInstance);
		structureClient.getFormesJuridiques().then((result) => {
			setFormesJuridiques(result);
		})
			.catch((error) => {
				dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: error } });
			});
	}, [axiosInstance, dispatch]); // Call only once to retrieve forme juridique list

	useEffect(() => {
		setCanGoNext(siretValidated);
	}, [siretValidated, setCanGoNext]);

	useEffect(() => {
		setCivilite(context.civilite);
	}, [context])

	// Invalidate Siret to be able to query again the api if the SIRET is modified
	const invalidateSiret = async (): Promise<void> => {
		setSiretValidated(false);
	}

	const getOptionsFromFormeJuridique = (optionArray: Array<FormeJuridiqueDto>): AdelOption<FormeJuridiqueDto>[] => {
		let selectResult: AdelOption<FormeJuridiqueDto>[] = [];
		optionArray.map(item => {
			try {
				let option = getOptionFromFormeJuridique(item);
				return selectResult.push(option);
			} catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		})
		return selectResult;
	}

	const getOptionFromFormeJuridique = (item: FormeJuridiqueDto): AdelOption<FormeJuridiqueDto> => {
		if (!item.code) {
			return getOptionsFromFormeJuridique(formeJuridiques)[0];
			// throw `No code found for item`
		}
		return {
			label: item.code,
			value: item
		}
	}

	const searchAdresse = (input: string) => {
		setValue([{ adresse: input }]);

		if (!!input) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);

				adresseClient.searchAdresse(`${ville} ${input}`, codePostal).then(results => {
					setGeoAdresse(results);
				});
				onAdresseChange(input);
			}

			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}

	const onAdresseChange = (label: string) => {
		if (!label) {
			setValue([
				{ adresse: null },
				{ codePostal: null },
				{ ville: null }
			]);
			return;
		}

		const geoAdresseFiltered = geoAdresse.filter(adresse => adresse.label === label);

		if (geoAdresseFiltered.length > 0) {
			const selectedAdresse = geoAdresseFiltered[0];
			setValue([
				{ adresse: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
				{ codePostal: selectedAdresse.codePostal },
				{ ville: selectedAdresse.ville },
			]);
		}
	}

	return (
		<>

			<StepCounter
				counter={1}
			/>

			<Subtitles
				title={t('signup.step-one.title')}
			/>

			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<InputValidation
					name="siret"
					reference={register(createStructureValidator?.["Siret"])}
					label={t('signup.step-one.label')}
					placeHolder={t('signup.step-one.placeholder')}
					maxLength={14}
					buttonLabel={t('signup.step-one.validation-button')}
					onChange={invalidateSiret}
					isLoading={isLoading}
					onValidate={validateSiret}
					onPaste={e => {
						let clipboardContent = e.clipboardData.getData("Text");
						setValue("siret", clipboardContent.replace(/\D/g, ''));
						e.preventDefault();
						return false;
					}}
					validated={siretValidated}
					errors={errors}
				/>

				{siretValidated && <div className="infosJuridiques">
					<div className="infosJuridiques__item">
						<Input
							name="raisonSociale"
							reference={register(createStructureValidator?.["RaisonSociale"])}
							label={t("signup.step-one.raison-sociale.label")}
							type="text"
							placeHolder={t("signup.step-one.raison-sociale.placeholder")}
							errors={errors}
							disabled={raisonSocialeReadonly}
						/>
					</div>
					
					<div className="infosJuridiques__item">
						<Input
							name="ville"
							reference={register(adresseValidator?.["Ville"])}
							label={t("signup.step-one.ville.label")}
							type="text"
							placeHolder={t("signup.step-one.ville.placeholder")}
							maxLength={60}
							errors={errors}
						/>
						<Input
							name="codePostal"
							reference={register(adresseValidator?.["CodePostal"])}
							label={t("signup.step-one.code-postal.label")}
							type="tel"
							placeHolder={t("signup.step-one.code-postal.placeholder")}
							maxLength={5}
							errors={errors}
						/>
					</div>

					<div className="infosJuridiques__item">
						<Input
							name="adresse"
							reference={register(adresseValidator?.["Line1"])}
							label={t("signup.step-one.address.label")}
							type="text"
							placeHolder={t("signup.step-one.address.placeholder")}
							errors={errors}
							dataList={geoAdresse.map(adresse => adresse.label)}
							onChange={_.debounce((value) => searchAdresse(value), 1000)}
						/>
						<Input
							name="line2"
							reference={register(adresseValidator?.["Line2"])}
							label={t("signup.step-five.complementAdresse")}
							type="text"
							placeHolder={t("signup.step-one.address.placeholder")}
							errors={errors}
						/>
					</div>

					<div className="infosJuridiques__item">
						<Input
							name="codeAPE"
							reference={register()}
							label={t("signup.step-one.codeAPE.label")}
							type="text"
							placeHolder={""}
							errors={errors}
							disabled={true}
						/>

						<Input
							name="nomenclatureAPE"
							reference={register()}
							label={t("signup.step-one.nomenclatureAPE.label")}
							type="text"
							placeHolder={""}
							errors={errors}
							disabled={true}
						/>
					</div>

					<div className="infosJuridiques__item">
						<Controller control={control}
							name="formeJuridique"
							as={({ name }) => (
								<InputSelect<FormeJuridiqueDto>
									name={name}
									label={t('signup.step-one.forme-juridique.label')}
									classname="inputSelectFO"
									options={getOptionsFromFormeJuridique(formeJuridiques)}
									onChange={setFormeJuridique}
									value={formeJuridique}
									errors={errors}
									customMatchValue={(source, target) => source.id === target.id}
									readonly={formeJuridiqueReadOnly}
									placeholder={t("common.select")}
								/>
							)}
							rules={createStructureValidator?.["FormeJuridiqueId"]}
						/>
					</div>

					<div className="infosJuridiques__item">
						<Input
							name="presidentPrenom"
							reference={register(contactValidator?.["Prenom"])}
							label={t("signup.step-one.president-prenom.label")}
							type="text"
							placeHolder={t("signup.step-one.president-prenom.placeholder")}
							maxLength={60}
							errors={errors}
						/>

						<Input
							name="presidentNom"
							reference={register(contactValidator?.["Nom"])}
							label={t("signup.step-one.president-nom.label")}
							type="text"
							placeHolder={t("signup.step-one.president-nom.placeholder")}
							maxLength={60}
							errors={errors}
						/>
					</div>

					<div className="infosJuridiques__item">
						<InputSelect<Civilite>
							name="civilite"
							label={t('signup.step-four.civilite')}
							classname="inputSelectFO"
							options={options()}
							errors={errors}
							onChange={setCivilite}
							value={civilite}
							placeholder={t("common.select")}
						/>
						
						<Controller control={control}
							name="presidentPoste"
							as={({ onChange, value, name }) => (
								<InputSelect<TypePoste>
									name={name}
									label={t('signup.step-four.poste')}
									classname="inputSelectFO"
									options={optionsPoste()}
									errors={errors}
									onChange={onChange}
									value={value}
									placeholder={t("common.select")}
								/>
							)}
							rules={contactValidator?.["Poste"]}
						/>
					</div>

					<div className="infosJuridiques__item">
						<Input
							name="telephoneFixe"
							reference={register(contactValidator?.["TelephoneFixe"])}
							label={t("signup.step-one.telephoneFixe.label")}
							type="tel"
							placeHolder={t("signup.step-one.telephoneFixe.placeholder")}
							maxLength={15}
							errors={errors}
						/>

						<Input
							name="telephonePortable"
							reference={register(contactValidator?.["TelephonePortable"])}
							label={t("signup.step-one.telephonePortable.label")}
							type="tel"
							placeHolder={t("signup.step-one.telephonePortable.placeholder")}
							maxLength={15}
							errors={errors}
						/>
					</div>

					<div className="infosJuridiques__item">
						<Input
							name="email"
							reference={register(contactValidator?.["Email"])}
							label={t("signup.step-one.email.label")}
							type="text"
							placeHolder={t("signup.step-one.email.placeholder")}
							maxLength={120}
							errors={errors}
						/>
						<div></div>
					</div>
				</div>}
			</form>
		</>
	)
})

export default StepOne;