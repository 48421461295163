import React, { FunctionComponent } from "react";
import { Civilite } from "../../services/generated/FrontOffice-api";
import InputSelect from "adel-shared/dist/components/basics/InputSelect";
import { useTranslation } from "react-i18next";
import { Controller, FormContextValues } from "react-hook-form";
import Input from "../basics/Input";
import { CreateOrUpdateResponsableDossierDto } from "../../clients/AdelDossierClient";
import useValidation from "../../custom-hooks/useValidation";

interface InfosResponsableDossierProps {
	form: FormContextValues<CreateOrUpdateResponsableDossierDto>
}

const InfosResponsableDossier: FunctionComponent<InfosResponsableDossierProps> = ({
	form: {
		control,
		register,
		errors
	}
}) => {
    const { t } = useTranslation();
	const { getRootValidator } = useValidation();
    const validator = getRootValidator("CreateOrUpdateResponsableDossierDto");

    const civiliteOptions = () => {
		const allOptions = Object.values(Civilite).filter(x => x !== Civilite.None).map(x => ({
			value: x,
			label: t(`common.civilite.${x}`)
		}));
        return allOptions;
    };

    return (
        <div className="creationDossier__item">
			<div className="creationDossier__row">
				<Controller
					control={control}
					name="civilite"
					as={({ onChange, name, value }) => (
						<InputSelect<Civilite>
							name={name}
							label={t('createFolder.responsableDossier.civilite')}
							classname="inputSelectFO"
							options={civiliteOptions()}
							errors={errors}
							onChange={onChange}
							value={value}
							placeholder={t("common.select")}
						/>
					)}
					rules={validator?.["Civilite"]}
				/>

				<Input
					name="nom"
					reference={register(validator?.["Nom"])}
					label={t('createFolder.responsableDossier.lastname')}
					type="text"
					placeHolder="Nom de famille"
					maxLength={60}
					errors={errors}
				/>
			</div>
			<div className="creationDossier__row">
				<Input
					name="prenom"
					reference={register(validator?.["Prenom"])}
					label={t('createFolder.responsableDossier.firstname')}
					type="text"
					placeHolder="Prénom"
					maxLength={60}
					errors={errors}
				/>

				<Input
					name="email"
					reference={register(validator?.["Email"])}
					label={t('createFolder.responsableDossier.email')}
					type="text"
					placeHolder=""
					maxLength={120}
					errors={errors}
				/>
			</div>
			<div className="creationDossier__row">
				<Input
					name="telephoneFixe"
					reference={register(validator?.["TelephoneFixe"])}
					label={t('createFolder.responsableDossier.fix-phone')}
					type="text"
					placeHolder=""
					maxLength={15}
					errors={errors}
				/>

				<Input
					name="telephonePortable"
					reference={register(validator?.["TelephonePortable"])}
					label={t('createFolder.responsableDossier.phone')}
					type="text"
					placeHolder=""
					maxLength={15}
					errors={errors}
				/>
			</div>
        </div>
	);
}

export default InfosResponsableDossier;