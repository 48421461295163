import React, { useEffect } from 'react';
import ModalErrorInformation from '../../../basics/ModalErrorInformation';
import {EnseignantValidationErrorDto, LieuValidationError} from '../../../../services/generated/FrontOffice-api';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface ModalValidationEnseignantsProps {
	isOpen: boolean;
	onCancel: () => void;
	errorsValidation: EnseignantValidationErrorDto[];
}

const ModalValidationEnseignants: React.FunctionComponent<ModalValidationEnseignantsProps> = ({
	isOpen,
	onCancel,
	errorsValidation
}) => {
	const { t } = useTranslation();

	return (<ModalErrorInformation
		isOpen={isOpen}
		onCancel={onCancel}
		title={t('common.validation-error.title')}
		cancelButtonLabel={t('common.return')}
	>
		<div className="modal__item">
			{t('createFolder.enseignants.errors.main')} {errorsValidation?.map((e: EnseignantValidationErrorDto, i:number) => t('createFolder.enseignants.errors.contrat', {name: e.contrat, number: e.numberEnseignantRequired})
				).join(`, `)
			}
		</div>
	</ModalErrorInformation>);
}

export default ModalValidationEnseignants;