import React, { useEffect, useState } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { DisciplineDto, EnseignantDto } from '../../../../../services/generated/FrontOffice-api';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import ModalDeleteEnseignant from './ModalDeleteEnseignant';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import TotalAmountsTable from 'adel-shared/dist/components/basics/TotalAmountsTable';

interface EnseignantListProps {
	enseignants: EnseignantDto[]
	onAddEnseignant: () => void;
	duplicateEnseignant: (id: string) => void;
	editEnseignant: (id: string) => void;
	deleteEnseignant: (id: string) => Promise<void>;
}

const EnseignantList: React.FunctionComponent<EnseignantListProps> = ({
	enseignants,
	onAddEnseignant,
	duplicateEnseignant,
	editEnseignant,
	deleteEnseignant
}) => {
	const { t, i18n } = useTranslation();
	const [modalDeleteEnseignantIsOpen, setModalDeleteEnseignantIsOpen] = useState<boolean>(false)
	const [selectedEnseignantId, setSelectedEnseignantId] = useState<string>();
	const [data, setData] = useState<any[]>(enseignants);
	const [totalAmounts, setTotalAmounts] = useState<Record<string, number>>({});

	const columns = React.useMemo(() => {
		return [{
			Header: "Table",
			columns: [
				{ Header: "Id", accessor: "id", disableSortBy: true },
				{ Header: t('creationVersement.enseignants.listColumns.nom'), accessor: "nom", disableSortBy: true },
				{ Header: t('creationVersement.enseignants.listColumns.prenom'), accessor: "prenom", disableSortBy: true },
				{ Header: t('creationVersement.enseignants.listColumns.discipline'), accessor: "disciplines", disableSortBy: true,
					Cell: (value: any) => (
						<span>
							{value.cell.value.map((element:DisciplineDto) => 
								{ return element.text[i18n.language]  }).join(", ")
							}
						</span>
					)
				},
				{ Header: t('creationVersement.enseignants.listColumns.typeContrat'), accessor: "typeContrat", disableSortBy: true },				
				{ Header: t('creationVersement.enseignants.listColumns.salaires'), accessor: "remunerationBruteAnnuelle", disableSortBy: true},
				{ Header: t('creationVersement.enseignants.listColumns.charges'), accessor: "montantChargesPatronales", disableSortBy: true},
				{
					Header: "", accessor: "Actions", disableSortBy: true,
					Cell: (value: any) => (
						<span className="creationDossier__table-buttons">
							<div className="icon-button" onClick={() => duplicateEnseignant(value.cell.row.values.id)}><i className="far fa-copy"></i></div>
							<div className="icon-button" onClick={() => editEnseignant(value.cell.row.values.id)}><i className="far fa-edit"></i></div>
							<div className="icon-button" onClick={() => openModalDeleteEnseignant(value.cell.row.values.id)}><i className="far fa-trash-alt"></i></div>
						</span>
					)
				}
			]
		}];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<EnseignantDto>({
		columns,
		data,
		initialState: { hiddenColumns: ["id"] },
	}, useSortBy);

	const openModalDeleteEnseignant = (id: string) => {
		setSelectedEnseignantId(id)
		setModalDeleteEnseignantIsOpen(true)
	}

	const confirmDeleteEnseignant = async () => {
		await deleteEnseignant(selectedEnseignantId);
		setModalDeleteEnseignantIsOpen(false);
	}

	useEffect(() => {
		setData(enseignants.map(x => ({
			...x,
			remunerationBruteAnnuelle: `${formatNumber(x.remunerationBruteAnnuelle)} €`,
			montantChargesPatronales: `${formatNumber(x.montantChargesPatronales)} €`,
		})));

		setTotalAmounts({
			remunerationBruteAnnuelle: enseignants.reduce((acc, value) => acc + value.remunerationBruteAnnuelle, 0),
			montantChargesPatronales: enseignants.reduce((acc, value) => acc + value.montantChargesPatronales, 0)
		});
	}, [enseignants]);

	return <>
		<div className="creationDossier__header">
			<h3 className="title--dark">{t("createFolder.enseignants.title")}</h3>
		</div>
		<div className="creationDossier__singleRightButton">
			<FormButton
				type="button"
				value={t("createFolder.enseignants.add")}
				onClick={onAddEnseignant}
			/>
		</div>
		<Table
			data={enseignants}
			getTableProps={getTableProps}
			getTableBodyProps={getTableBodyProps}
			headerGroups={headerGroups}
			rows={rows}
			prepareRow={prepareRow}
			styles={styleTable.onglet}
		/>
		<TotalAmountsTable
			data={[
				{
					label: t("creationVersement.contratsCachets.totalRemunerations"),
					value: `${formatNumber(totalAmounts.remunerationBruteAnnuelle)} €`
				},
				{
					label: t("creationVersement.contratsCachets.totalChargesPatronales"),
					value: `${formatNumber(totalAmounts.montantChargesPatronales)} €`
				}
			]}
		/>
		<ModalDeleteEnseignant
			isOpen={modalDeleteEnseignantIsOpen}
			onCancel={() => setModalDeleteEnseignantIsOpen(false)}
			onValidate={confirmDeleteEnseignant}
		/>
	</>;
}

export default EnseignantList;