import React from "react";
function InputRadioButton({ name, label, id, onChange, checked, value, isDisabled = false }) {
    return (React.createElement("div", { className: "inputRadioButton" },
        React.createElement("input", { type: "radio", id: id, name: name, checked: checked, onChange: onChange, disabled: isDisabled, value: value }),
        React.createElement("label", { htmlFor: id, className: "inputRadioButton__label" },
            !checked
                ? React.createElement("i", { className: "far fa-circle" })
                : React.createElement("i", { className: "fa fa-check-circle" }),
            " ",
            label)));
}
export default InputRadioButton;
