import React, {useState, useEffect} from 'react'
import Loader from 'react-loader-spinner'
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../custom-hooks/useAxios';
import { DossierClient, CommissionDto } from '../../services/generated/FrontOffice-api';
import { RouteComponentProps } from '@reach/router';

interface ValidationProps extends RouteComponentProps {
    dossierId: string;
}


function MessageValidation(props: ValidationProps) {
    const { t, i18n } = useTranslation();
    const axiosInstance = useAxios();
    const dossierClient = new DossierClient("", axiosInstance);
    const [commission, setCommission] = useState<CommissionDto>(undefined);
    const fullDateOptions:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
		(async () => {
			const result = await dossierClient.getDossierCommission(props.dossierId);
            setCommission(result);
        })();
    }, [props.dossierId])


    return (<div className="creationDossier__validation">      
        {commission ? <section>
            <div className="rules__container">
                <i className="fas fa-exclamation-triangle"></i>
                <ul>
                    <li>{t('createFolder.finalValidation.dateCommission', { dateDebut: new Date(commission.dateDebut).toLocaleDateString(i18n.language, fullDateOptions), dateFin: new Date(commission.dateFin).toLocaleDateString(i18n.language, fullDateOptions)})}</li>
                    <li>{t('createFolder.finalValidation.dateLimite', { dateLimite: new Date(commission.dateFinSoumission).toLocaleDateString(i18n.language, fullDateOptions)})}</li>
                    <li>{t('createFolder.finalValidation.warningDeletion')}</li>
                </ul>
            </div>
			</section> 
			: props.dossierId && 
                <Loader
				type="TailSpin"
				width={35}
				height={35}
				color="#d93943"
			></Loader>
		}
    </div>);
};
export default MessageValidation
