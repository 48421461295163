import React, { useState, useEffect } from "react";

import { RouteComponentProps, useParams } from '@reach/router';
import Subtitles from "../basics/Subtitles";
import PublicHeader from "../PagesVitrines/PublicHeader";
import Footer from "../basics/Footer";
import { useTranslation } from "react-i18next";
import { PageVitrineDto } from "adel-shared/dist/models/generated/BackOffice-api";
import { useContext } from "react";
import { AppStateContext } from "../../context/AppContext";

interface PagesVitrinesProps extends RouteComponentProps {

}

const PagesVitrines: React.FunctionComponent<PagesVitrinesProps> = () => {
	const [pageVitrine, setPageVitrine] = useState<PageVitrineDto>({});
	const { i18n } = useTranslation();
	const { page } = useParams();
	const [{pagesVitrine}] = useContext(AppStateContext);

	useEffect(() => {
		setPageVitrine(pagesVitrine.find(x => x.urlSlug[i18n.language] === page))
	}, [page]);

	return (
		<div id="structure">
			<div className="modal">
				<PublicHeader />

				<div className="folders">
					<div className="folders__title">
						<Subtitles
							title={pageVitrine.nom?.[i18n.language]}
						/>
					</div>

					<div dangerouslySetInnerHTML={{ __html: pageVitrine.contenu?.[i18n.language] }}></div>
				</div>

				<div className="structure__footer">
					<Footer />
				</div>
			</div>
		</div>
	);
};

export default PagesVitrines;