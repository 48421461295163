import React, { useCallback, ReactElement } from "react";
import Loader from 'react-loader-spinner';
import FormButton from 'adel-shared/dist/components/basics/FormButton';

interface ModalProps {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	title: string;
	validateLabel: string;
	cancelLabel: string;
    onValidateChange: () => void;
	onCancel?: () => void;
	children: ReactElement<any>;
	img?: string;
	isLoading?:boolean;
}

const Modal: React.FunctionComponent<ModalProps> = ({
	isOpen,
	setIsOpen,
	title,
	validateLabel,
	cancelLabel,
	onValidateChange,
	onCancel,
	children,
	img = "",
	isLoading = false
}) => { 
	
	return (<>
		{isOpen &&
			<div className="modalComponent">
				<div className={`modalComponent__content ${img !== "" && "modalComponent__content--sidePicture"}`}>
					{img !== "" &&
						<div className="modalComponent__picture">
							<img src={img} />
						</div>
					}
					<div>
						<div className="modalComponent__header">
							<h3>{title}</h3>
						</div>
						<div className="modalComponent__body">
							{ React.cloneElement(children) }
						</div>
						<div className="modalComponent__footer">
							<FormButton
								type="button"
								value={cancelLabel}
								onClick={() => {
									onCancel && onCancel();
									setIsOpen(false);
								}}
							/>
							{isLoading
								? <Loader
									type="TailSpin"
									width={50}
									height={50}
									color="#d93943"
								></Loader>
								: <FormButton
									type="submit"
									value={validateLabel}
									onClick={() => onValidateChange()}
								/>
							}
						</div>
					</div>
				</div>
			</div>
		}</>
	)
}
    
export default Modal;