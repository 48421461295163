import React, { useImperativeHandle, useEffect, useState, forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useRef, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../../custom-hooks/useAxios';
import { DossierClient, ArtisteLightDto, ArtisteLightDtoSortedFilteredPage, ArtisteClient, ArtisteDto, MasseSalarialeDto, ArtistesValidationErrorDto } from '../../../services/generated/FrontOffice-api';
import ArtisteList from './artistes/ArtisteList';
import EditArtiste, { EditArtisteRef } from './artistes/EditArtiste';
import _ from 'lodash';
import ModalValidationArtiste from './artistes/ModalValidationArtiste';
import useIsMounted from '../../../custom-hooks/useIsMounted';
import { CategorieDossier } from '../../../enums/Dossiers';

enum View {
	List = "List",
	Edit = "Edit"
}

export enum formMode {
	IS_CREATION = 1,
	IS_DUPLICATE,
	IS_EDIT
}

interface ContratsCachetsProps extends RouteComponentProps {
	dossierId: string;
	categorieDossierId: string;
	setEditMode: (value: boolean) => void;
	setIsFormValid: (value: boolean) => void;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
	codeCategorie: CategorieDossier;
}

const ContratsCachets: ForwardRefExoticComponent<PropsWithoutRef<ContratsCachetsProps> & RefAttributes<any>> = forwardRef(({
	dossierId,
	categorieDossierId,
	setEditMode,
	setIsFormValid,
	setIsLoading,
	codeCategorie,
	setCanGoNext
}, ref) => {
	const { t, i18n } = useTranslation();

	const isMounted = useIsMounted();
	const axiosInstance = useAxios();
	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
	const artisteClient = useMemo(() => { return new ArtisteClient("", axiosInstance) }, [axiosInstance]);


	const [currentView, setCurrentView] = useState<View>(View.List);
	const [artistes, setArtistes] = useState<ArtisteLightDto[]>([]);

	const [artisteSelected, setArtisteSelected] = useState<ArtisteDto>()

	const [currentFormMode, setCurrentFormMode] = useState<formMode>(formMode.IS_CREATION)

	const [stats, setStats] = useState<MasseSalarialeDto>({})


	const [errorsValidation, setErrorsValidation] = useState<ArtistesValidationErrorDto[]>([]);
	const [modalValidationArtisteIsOpen, setModalValidationArtisteIsOpen] = useState<boolean>(false);

	/** Filtres */
	const [sort, setSort] = useState<string>("Nom");
	const [filters, setFilters] = useState<string>("");
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(1000);
	const [disabledPagination, setDisabledPagination] = useState<boolean>(true);


	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchArtists = useMemo(() => _.debounce((dossierId: string, filters: string, sort: string, page: number, pageSize: number, disabledPagination: boolean) => {
		dossierClient.getArtistes(dossierId, filters, sort, page, pageSize, disabledPagination).then((result: ArtisteLightDtoSortedFilteredPage) => {
			setArtistes(result.items);
			getAllStats()
		});
	}, 1000), []);

	useEffect(() => {
		//TODO: ajouter une regle du style check si on a des artists avant de pouvoir go next
		setCanGoNext(true)
	}, [])

	useEffect(() => {
		debounceFetchArtists(dossierId, filters, sort, page, pageSize, disabledPagination)
	}, [filters, sort, page, pageSize, disabledPagination]);

	useEffect(() => {
		switch (currentView) {
			case View.List:
				setEditMode(false);
				setArtisteSelected(undefined);
				debounceFetchArtists(dossierId, filters, sort, page, pageSize, disabledPagination)
				break;
			case View.Edit:
				setEditMode(true);
				break;
		}
	}, [currentView]);

	useEffect(() => {
		if (errorsValidation && errorsValidation.length > 0) {
			setModalValidationArtisteIsOpen(true)
		}
	}, [errorsValidation])

	const addArtisteRef: React.MutableRefObject<EditArtisteRef> = useRef<EditArtisteRef>(null);

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			setIsLoading(true);

			switch (currentView) {
				case View.List:
					let result = await dossierClient.validateArtistes(dossierId);
					setErrorsValidation(result?.errors)
					getAllStats();
					setIsLoading(false);
					if (result?.errors && result.errors.length > 0) {
						return false;
					} else {
						return true;
					}
				case View.Edit:
					if (addArtisteRef && addArtisteRef.current) {
						let result = await addArtisteRef.current.validateAndSend();
						setIsLoading(false);
						return result;
					} else {
						setIsLoading(false);
						console.error("addArtisteRef undefined. Please contact your Dev Team.");
					}
			}
			throw "currentView unknown. Please contact your Dev Team.";
		}
	}));


	const duplicateArtiste = (data: any) => {
		setIsLoading(true);
		artisteClient.getArtiste(data).then((result) => {
			setArtisteSelected(result);
			setCurrentFormMode(formMode.IS_DUPLICATE);
			setCurrentView(View.Edit);
			setIsLoading(false);
		})
	}

	const editArtiste = (data: any) => {
		setIsLoading(true);
		artisteClient.getArtiste(data).then((result) => {
			setArtisteSelected(result);
			setCurrentFormMode(formMode.IS_EDIT);
			setCurrentView(View.Edit);
			setIsLoading(false);
		})
	}

	const deleteArtiste = () => {
		setIsLoading(true);
		debounceFetchArtists(dossierId, filters, sort, page, pageSize, disabledPagination)
		setIsLoading(false);
	}

	const getAllStats = () => {
		dossierClient.getMasseSalariale(dossierId).then((result) => {
			if(isMounted()) {
				setStats(result);
			}
		})
	}

	const renderSwitchView = () => {
		switch (currentView) {
			case View.List:
				return <ArtisteList
					artistes={artistes}
					onAddArtist={() => {
						setCurrentView(View.Edit);
						setCurrentFormMode(formMode.IS_CREATION);
					}}
					duplicateArtiste={duplicateArtiste}
					editArtiste={editArtiste}
					informDeleteArtiste={deleteArtiste}
					dossierId={dossierId}
					categorieDossierId={categorieDossierId}
					stats={stats}


				/>
			case View.Edit:
				return <EditArtiste
					ref={addArtisteRef}
					dossierId={dossierId}
					setIsFormValid={setIsFormValid}
					closeView={() => {
						setCurrentView(View.List);
						setCurrentFormMode(formMode.IS_CREATION)
					}}
					artisteSelected={artisteSelected}
					currentFormMode={currentFormMode}
					codeCategorie={codeCategorie}
				/>
		}
	}

	return (
		<>
			{renderSwitchView()}
			{ currentView === View.List && errorsValidation.length > 0 &&
				<ModalValidationArtiste
					isOpen={modalValidationArtisteIsOpen}
					onCancel={() => setModalValidationArtisteIsOpen(false)}
					errorsValidation={errorsValidation}
				/>
			}
		</>);
});

export default ContratsCachets;