import React, { useState, useEffect, useRef, FunctionComponent } from "react"
import { TypeDeDocumentDto, DocumentDto } from "../../../services/generated/FrontOffice-api";
import InputDocument, { InputDocumentRef } from './InputDocumentV2';
import InputSelect from "adel-shared/dist/components/basics/InputSelect";
import { useTranslation } from "react-i18next";
import { Dictionary } from "adel-shared/dist/models";
import { Controller, FormContextValues } from 'react-hook-form';

interface InputSelectDocumentProps {
	// New way of handling documents
	typeDeDocument: TypeDeDocumentDto;
	document: DocumentDto;
	onDocumentChange?: (document: DocumentDto, file?: File) => void;
	form: FormContextValues;
	onReset: (fieldName: string) => void;
	// Legacy way of handling documents
	defaultSelection?: TypeDeDocumentDto;
	defaultDates?: Dictionary<Date>;
	defaultFiles?: Dictionary<File>;
	defaultValues?: Dictionary<Dictionary<string>>;
	onTypeDocumentChange: (typeDocument: TypeDeDocumentDto, selectedDocument: TypeDeDocumentDto) => void;
	onDateChange: (typeDocument: TypeDeDocumentDto, extraInfoCode: string, date: Date) => void;
	onFileChange: (typeDocument: TypeDeDocumentDto, file: File) => void;
	onExtraInfoChange: (typeDocument: TypeDeDocumentDto, extraInfoCode: string, value: string) => void;
	selection: TypeDeDocumentDto;
	setSelection: (value:TypeDeDocumentDto) => void;
}

const InputSelectDocument: FunctionComponent<InputSelectDocumentProps> = ({
	document,
	onDocumentChange,
	typeDeDocument,
	defaultSelection,
	defaultDates,
	defaultFiles,
	defaultValues,
	onTypeDocumentChange,
	onDateChange,
	onFileChange,
	onExtraInfoChange,
	form,
	onReset,
	selection,
	setSelection
}) => {
	const { t, i18n } = useTranslation();
	const { control, errors } = form;

	const [currentDocument, setCurrentDocument] = useState<DocumentDto>(document);

	const inputDocumentRef = useRef<InputDocumentRef>();

	// Legacy way for defaultSelection
	useEffect(() => {
		if (defaultSelection && !selection) {
			setSelection(defaultSelection);
			form.setValue(typeDeDocument.code, defaultSelection);
		}
	}, [defaultSelection])

	// New way for defaultSelection
	useEffect(() => {
		setCurrentDocument(document);
		if (document && document.type && !selection) {
			setSelection(document.type);
		}
	}, [document])

	/** Options of the select box for Liste ou attestation GUSO */
	const convertTypeDeDocumentDtoToAdelOption = (optionArray: TypeDeDocumentDto[]) => {
		return optionArray.map(item => ({
			value: item,
			label: item.nom[i18n.language]
		}));
	};

	const handleTypeDocumentChange = (typeDoc: TypeDeDocumentDto) => {

		if(selection && selection.code !== typeDoc.code) {
			inputDocumentRef.current.reset();
		}

		setSelection(typeDoc);
		form.setValue(typeDeDocument.code, typeDoc);


		onTypeDocumentChange(typeDeDocument, typeDoc);

		// New way of handling choix selection

		if (!currentDocument) {
			setCurrentDocument({ type: typeDeDocument });
		}

		setCurrentDocument({
			...currentDocument,
			type: typeDeDocument
		});

		onDocumentChange && onDocumentChange(currentDocument);
	}

	return (
		<div className="piecesObligatoires__item">
			<Controller
				control={control}
				name={typeDeDocument.code}
				as={({ name, value }) => (
					<InputSelect<TypeDeDocumentDto>
						name={name}
						label={typeDeDocument.nom[i18n.language]}
						classname="inputSelectFO inputSelectFO--fullWidth"
						options={convertTypeDeDocumentDtoToAdelOption(typeDeDocument.choix)}
						value={value}
						onChange={handleTypeDocumentChange}
						errors={errors}
						customMatchValue={(source, target) => source.id === target.id}
						placeholder={t("common.select")}
					/>
				)}
				rules={{
					required: {
						value: true,
						message: t('validation-messages.required')
					}
				}}
			/>

			{selection && (
				<InputDocument
					className="inputFile__item--modifier"
					typeDeDocument={selection}
					selection={selection}
					document={currentDocument}
					onDocumentChange={onDocumentChange}
					defaultDate={defaultDates?.[typeDeDocument.code]}
					defaultFile={defaultFiles?.[typeDeDocument.code]}
					defaultValues={defaultValues?.[typeDeDocument.code]}
					onDateChange={(extraInfoCode, date) => { onDateChange(selection, extraInfoCode, date) }}
					onFileChange={(documentCode, file) => onFileChange(selection, file)}
					onValueChange={(extraInfoCode, value) => onExtraInfoChange(selection, extraInfoCode, value)}
					onReset={() => onReset(selection.code)}
					form={form}
					ref={inputDocumentRef}
				/>
			)}
		</div>
	)
}

export default InputSelectDocument;