import React, {useRef} from "react";
import QuestionInputProps from "./QuestionInputProps";
import Moment from "react-moment";
import Calendar from "react-calendar";
import useOnClickOutside from "use-onclickoutside";
import {useTranslation} from "react-i18next";
import getAsDate from "../../../../helpers/getAsDate";
import useBooleanState from "adel-shared/dist/custom-hooks/useBooleanState";

const PeriodQuestionInput : React.FC<QuestionInputProps> = ({
	question,
	currentValue,
	title,
	onAnswerChange,
	errorMessage
}) => {
    const { t } = useTranslation();

    const minDate = getAsDate(question?.answerMinValue);
    const maxDate = getAsDate(question?.answerMaxValue);

    const [isCalendarOpen, showCalendar, closeCalendar] = useBooleanState(false);
    const ref = useRef();
    useOnClickOutside(ref, closeCalendar);

    const renderCurrentValue = () => {
        if (currentValue) {
            const {start: startDate, end: endDate} = currentValue;
            return <>
                <Moment format="DD/MM/YYYY">{startDate}</Moment> - <Moment format="DD/MM/YYYY">{endDate}</Moment>
            </>
        }
        else {
            return <span>{t("common.pick-period-dates")}</span>;
        }
    }

	const getActiveStartDate = () => {
		if(currentValue) {
			return currentValue.start;
		}
		if(minDate) {
			return minDate;
		}
		return new Date();
	};

	return <div className="questionField">
		<div className="questionField__title">{title}</div>
		<div className="inputFile__calendar dateInputGroup" onClick={showCalendar} ref={ref}>
			<div className="dateInputGroup__item">
				{renderCurrentValue()}
				&nbsp;
				<i className="far fa-calendar-alt"></i>
			</div>

			{isCalendarOpen && (
				<Calendar
					onChange={(range: Date[]) => {
						const [start, end] = range;
						onAnswerChange(question?.id, {start, end});
						closeCalendar();
					}}
					selectRange
					minDate={minDate}
					defaultActiveStartDate={getActiveStartDate()}
					maxDate={maxDate}
					value={[currentValue?.start, currentValue?.end]}
					label={title}
				/>
			)}

			{errorMessage?.message &&
				<p className="input__errorMessage">{errorMessage.message}</p>
			}
		</div>
	</div>
}

export default PeriodQuestionInput;