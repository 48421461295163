import { useEffect, useState } from 'react';
import { ValidationOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	bicRgx,
	checkRegex,
	emailValidate,
	greaterThan,
	greaterThanOrEqual,
	ibanValidate,
	lessThan,
	lessThanOrEqual,
	phoneValidate,
	requiredUniqueCharValidate,
	siretValidate
} from './../utils/validatorFunctions';
import AdelValidationClient from '../clients/AdelValidationClient';
import { Dictionary } from '../models';
import PropertyValidatorInfo from '../models/validation/PropertyValidatorInfo';
import { ValidationPropertyType } from '../validators/ValidationPropertyType';
import { useAxios } from './useAxios';

function useValidation() {
	const [validationData, setValidationData] = useState(null);
	const client = new AdelValidationClient("", useAxios());
	const { t } = useTranslation();

	useEffect(() => {
		client.validation().then(r => {
			setValidationData(r);
		});
	}, []);

	const getRootValidator = (selector: string): Dictionary<ValidationOptions> | null => {
		const result: Dictionary<ValidationOptions> = {};
		const selectedData = validationData?.[selector];
		if (selectedData) {
			const data = selectedData as Dictionary<PropertyValidatorInfo[]>;
			Object.keys(data).forEach(key => {
				result[key] = transformValidations(data[key])
			});
			return result;
		}

		return null;
	}

	const transformValidations = (validatorInfos: PropertyValidatorInfo[]): ValidationOptions => {
		var validationOptions: ValidationOptions = {};
		validatorInfos.map((info: PropertyValidatorInfo) => {
			switch (info.name) {
				case ValidationPropertyType.NotNull:
					validationOptions.required = { value: true, message: t('validation-messages.required') };
					break;
				case ValidationPropertyType.NotEmpty:
					validationOptions.required = { value: true, message: t('validation-messages.required') };
					if (info.DefaultValueForType === 0) {
						validationOptions.validate = {
							notZero: (value) => parseFloat(value) !== 0 || t('validation-messages.not-empty-number') as string
						};
					} else  {
						validationOptions.minLength = { value: 1, message: t('validation-messages.not-empty') };
					}
					break;
				case ValidationPropertyType.UniqueCharCountProperty:
				case ValidationPropertyType.RegularExpression:
					// si info.expression === 'PP-\\d{6,8}' c'est un identifiant
					if (info.Expression === 'PP-\\d{6,8}') {
						validationOptions.validate = {
							haveGoodFormat: (value) => checkRegex(value, /PP-\d{6,8}/, t('validation-messages.good-format'))
						}
					}
					else if (info.Expression === '^[0-9]*$') {
						validationOptions.validate = {
							haveGoodFormat: (value) => checkRegex(value, /^[0-9]*$/, t('validation-messages.good-format'))
						}
					}
					else if (info.Expression === '^([^0-9]*)$') {
						validationOptions.validate = {
							haveGoodFormat: (value) => checkRegex(value, /^([^0-9]*)$/, t('validation-messages.no-number'))
						}
					}
					else if (info.Expression === "^[\\p{L}\\p{M} '-]*$") {
						validationOptions.validate = {
							haveGoodFormat: (value) => checkRegex(value, /^[\p{L}\p{M} '-]*$/iu, t('validation-messages.good-format'))
						}
					}
					else if (info.Expression?.startsWith('^((([a-zA-Z]|')) {
						validationOptions.pattern = { value: new RegExp(info.Expression), message: t('validation-messages.invalid-email') };
					}
					// sinon c'est un mdp
					else if (info.Expression || info.RequiredUniqueChars) {
						validationOptions.validate = {
							isUpper: (value) => checkRegex(value, /[A-Z]/, t('validation-messages.required-unique-upperCase-char')),
							isLower: (value) => checkRegex(value, /[a-z]/, t('validation-messages.required-unique-lowerCase-char')),
							isDigit: (value) => checkRegex(value, /[0-9]/, t('validation-messages.required-unique-digital-char')),
							isSpecial: (value) => checkRegex(value, /[^a-zA-Z0-9]/, t('validation-messages.invalid-regexp')),
							isUnique: (value) => requiredUniqueCharValidate(value, info.RequiredUniqueChars, t('validation-messages.required-unique-char', { number: info.RequiredUniqueChars }))
						}
					}
					break;
				case ValidationPropertyType.ExactLength:
					validationOptions.minLength = info.Min && { value: info.Min, message: t('validation-messages.exact-length', { length: info.Min }) }
					validationOptions.maxLength = info.Max && { value: info.Max, message: t('validation-messages.exact-length', { length: info.Max }) }
					break;
				case ValidationPropertyType.Email:
					if (info.Expression) {
						validationOptions.pattern = { value: new RegExp(info.Expression), message: t('validation-messages.invalid-email') };
					}
					break;
				case ValidationPropertyType.MaximumLength:
					validationOptions.maxLength = info.Max && { value: info.Max, message: t('validation-messages.max-length', { length: info.Max }) }
					break;
				case ValidationPropertyType.MinimumLength:
					validationOptions.minLength = info.Min && { value: info.Min, message: t('validation-messages.min-length', { length: info.Min }) }
					break;
				case ValidationPropertyType.AspNetCoreCompatibleEmail:
					validationOptions.validate = { value: value => emailValidate(value, t('validation-messages.invalid-email')) };
					break;
				case ValidationPropertyType.Siret:
					validationOptions.validate = { value: value => siretValidate(value, t('validation-messages.invalid-siret')) };
					break;
				case ValidationPropertyType.Phone:
					  //MBo 03/02/2023   tickets:7237 et 7167
					//validationOptions.validate == { value: value => phoneValidate(value, info.AllowedTypes, t('validation-messages.invalid-not-phone'), t('validation-messages.invalid-phone')) };
					validationOptions.pattern = {
						value: new RegExp("^[+]?(0033|33|0)(1|2|3|4|5|6|7|8|9)\\d{8}$"), message: t('validation-messages.invalid-not-phone')
					};
					break;
				case ValidationPropertyType.Iban:
					validationOptions.validate = { value: value => ibanValidate(value, t('validation-messages.invalid-iban-country'), t('validation-messages.invalid-iban')) };
					break;
				case ValidationPropertyType.Bic:
					validationOptions.pattern = { value: new RegExp(bicRgx), message: t('validation-messages.invalid-bic') };
					break;
				case ValidationPropertyType.GreaterThan:
					validationOptions.validate = {
						GreaterThan: (value) => greaterThan(value, info.ValueToCompare, t('validation-messages.greater-than', { ComparaisonValue: info.ValueToCompare })),
					}
					break;
				case ValidationPropertyType.GreaterThanOrEqual:
					validationOptions.required = { value: true, message: t('validation-messages.required') };
					validationOptions.validate = {
						GreaterThanOrEqual: (value) => greaterThanOrEqual(value, info.ValueToCompare, t('validation-messages.greater-than-or-equal', { ComparaisonValue: info.ValueToCompare })),
					}
					break;
				case ValidationPropertyType.LessThan:
					validationOptions.validate = {
						LessThan: (value) => lessThan(value, info.ValueToCompare, t('validation-messages.less-than', { ComparaisonValue: info.ValueToCompare })),
					}
					break;
				case ValidationPropertyType.LessThanOrEqual:
					validationOptions.validate = {
						LessThanOrEqual: (value) => lessThanOrEqual(value, info.ValueToCompare, t('validation-messages.less-than-or-equal', { ComparaisonValue: info.ValueToCompare })),
					}
					break;
				default:
					break;
			}
			return validationOptions;
		});
		return validationOptions;
	}

	return { getRootValidator };
}

export default useValidation;