import React, { useState, useEffect } from 'react';
import RenderBlockContent from './infosProjet/RenderBlockContent';
import { useTranslation } from 'react-i18next';
import InputReadOnly from '../InputReadOnly';
import clsx from 'clsx';
const InfosProjet = ({ details, lang, showResponsableDossier = true, showBlockTitle = true, className }) => {
    const { t, i18n } = useTranslation();
    const [blocks, setBlocks] = useState([]);
    const [answers, setAnswers] = useState({});
    useEffect(() => {
        if (details) {
            setBlocks(details.questionBlocks);
            setAnswers(details.answers);
        }
    }, [details]);
    return (React.createElement("div", { className: clsx('dossier-infos-complementaires', className) }, blocks.map(block => {
        var _a, _b, _c, _d, _e, _f, _g;
        return (React.createElement(React.Fragment, { key: block.id }, (block.id === "infos-responsable-dossier") ? (React.createElement(React.Fragment, null, showResponsableDossier && (React.createElement("div", { className: "detailsTab__content", id: "block-" + block.id },
            React.createElement("h4", { className: "title--red" }, t('createFolder.responsableDossier.title')),
            React.createElement("section", { className: "creationDossier__item" },
                React.createElement("div", { className: "detailsTab__row" },
                    React.createElement("div", { className: "detailsTab__column" },
                        React.createElement("div", { className: "detailsTab__content" },
                            React.createElement(InputReadOnly, { label: t('createFolder.responsableDossier.civilite'), content: ((_a = details.responsableDossier) === null || _a === void 0 ? void 0 : _a.civilite) || '-' }))),
                    React.createElement("div", { className: "detailsTab__column" },
                        React.createElement("div", { className: "detailsTab__content" },
                            React.createElement(InputReadOnly, { label: t('createFolder.responsableDossier.lastname'), content: ((_b = details.responsableDossier) === null || _b === void 0 ? void 0 : _b.nom) || '-' }))),
                    React.createElement("div", { className: "detailsTab__column" },
                        React.createElement("div", { className: "detailsTab__content" },
                            React.createElement(InputReadOnly, { label: t('createFolder.responsableDossier.firstname'), content: ((_c = details.responsableDossier) === null || _c === void 0 ? void 0 : _c.prenom) || '-' })))),
                React.createElement("div", { className: "detailsTab__row" },
                    React.createElement("div", { className: "detailsTab__column" },
                        React.createElement("div", { className: "detailsTab__content" },
                            React.createElement(InputReadOnly, { label: t('createFolder.responsableDossier.email'), content: ((_d = details.responsableDossier) === null || _d === void 0 ? void 0 : _d.email) || '-' }))),
                    React.createElement("div", { className: "detailsTab__column" },
                        React.createElement("div", { className: "detailsTab__content" },
                            React.createElement(InputReadOnly, { label: t('createFolder.responsableDossier.fix-phone'), content: ((_e = details.responsableDossier) === null || _e === void 0 ? void 0 : _e.telephoneFixe) || '-' }))),
                    React.createElement("div", { className: "detailsTab__column" },
                        React.createElement("div", { className: "detailsTab__content" },
                            React.createElement(InputReadOnly, { label: t('createFolder.responsableDossier.phone'), content: ((_f = details.responsableDossier) === null || _f === void 0 ? void 0 : _f.telephonePortable) || '-' }))))))))) : (React.createElement("div", { className: "detailsTab__content", id: "block-" + block.id },
            showBlockTitle && (React.createElement("h4", { className: "title--red" }, (_g = block.title) === null || _g === void 0 ? void 0 : _g[i18n.language])),
            React.createElement("section", { className: "detailsInfosProjet" },
                React.createElement(RenderBlockContent, { block: block, answers: answers, key: block.id, lang: lang }))))));
    })));
};
export default InfosProjet;
