import React from "react"
import { ErrorMessage } from "react-hook-form";

interface TextareaProps {
    name: string;
    reference?: any;
    label?: string;
    value?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
	errors?: any;
	maxLength?: number;
}

const Textarea: React.FunctionComponent<TextareaProps> = ({
    label,
	name,
	value,
    placeholder,
    reference,
	errors,
	maxLength,
    onChange
}) => {
    return (
        <div className="textarea__item">
            <label>{label}</label>

            <textarea
                name={name}
                ref={reference}
				placeholder={placeholder}
				value={value}
				maxLength={maxLength}
                onChange={
                    e => {
                        (onChange) && onChange(e.target.value)
                    }
                }>
            </textarea>

            {errors &&
                <ErrorMessage errors={errors} name={name}>
                    {({ messages }) => {
                        return (
                            Object.keys(errors).map((key) => (
                                errors[key].message && 
                                <p className="errorMessage" key={errors[key].type}>{errors[key].message}</p>
                            ))
                        );
                    }}
                </ErrorMessage>
            }
        </div>
    )
}

export default Textarea;