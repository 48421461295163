import React, { useState, useEffect, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef, useMemo, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from "@reach/router";
import { StepVersementRef } from '../CreationVersement';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useAxios } from '../../../../custom-hooks/useAxios';
import { useForm } from 'react-hook-form';
import { DonneesBancairesDto, StructureClient, VersementClient } from '../../../../services/generated/FrontOffice-api';
import DonneesBancaires from '../../../dossiers/creationDossier/DonneesBancaires';
import useValidation from '../../../../custom-hooks/useValidation';
import { AdelDossierClient, CreateOrUpdateDonneesBancairesDto } from '../../../../clients/AdelDossierClient';
import { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';
import DonneesBancaireReadonly from '../../../monCompte/bancaire/DonneesBancaireReadonly';

interface RibProps extends RouteComponentProps {
    dossierId: string;
    setCanGoNext: (value: boolean) => void;
    setIsFormValid: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
}

const Rib: ForwardRefExoticComponent<PropsWithoutRef<RibProps> & RefAttributes<StepVersementRef>> = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const axiosInstance = useAxios();
    const versementClient = useMemo(() => { return new VersementClient("", axiosInstance) }, [axiosInstance]);
	const adelDossierClient = new AdelDossierClient("", axiosInstance);
	const form = useForm<any>({});
	const { getValues, setValue, triggerValidation } = form;
	const [ribPJ, setRibPJ] = useState<DocumentWithFile>();

	const { getRootValidator } = useValidation();
	const donneesBancairesValidator = getRootValidator("CreateOrUpdateDonneesBancairesDto");
	const [defaultDonneesBancaires, setDefaultDonneesBancaires] = useState<DonneesBancairesDto>({});
	const [isIbanValid, setIsIbanValid] = useState<boolean>(false);

	
	useEffect(() => {
        props.setCanGoNext(isIbanValid);
    }, [isIbanValid]);

    useEffect(() => {		
		(async function () {
			try {
				let result = await versementClient.getInfoBancaireOnDemandeVersement(props.dossierId);

				if(result?.iban) {
					setIsIbanValid(true);
				}
				setDefaultDonneesBancaires(result);
				setValue([
					{ "iban": result?.iban },
					{ "bic": result?.bic },
					{ "banque": result?.banque },
					{ "line1": result?.adresse?.line1 },
					{ "line2": result?.adresse?.line2 },
					{ "codePostal": result?.adresse?.codePostal },
					{ "ville": result?.adresse?.ville }
				]);

				if(result?.rib) {
					setRibPJ({
						id: result.rib.id,
						fileName: result.rib.fileName || '',
						url: result.rib.uri
					});
				}

				props.setIsLoading(false);
			} catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t('common.api-error'));
				console.error(error);
			}
		})();
    }, []);


	/*** POST */

    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
			let isValid = await triggerValidation();

			if(!defaultDonneesBancaires.canEditRib)
				return true;

			if (isValid) {
				props.setIsLoading(true);
				const donneesBancairesValues: CreateOrUpdateDonneesBancairesDto = {
					iban: getValues().iban,
					bic: getValues().bic,
					banque: getValues().banque,
					adresse: {
						line1: getValues().line1,
						line2: getValues().line2,
						codePostal: getValues().codePostal,
						ville: getValues().ville
					},
					rib: {
						id: ribPJ?.file ? undefined : (ribPJ?.id || undefined),
						partName: ribPJ?.file ? "rib-PJ" : undefined
					}
				}
				if (donneesBancairesValues) {
					try {
						await adelDossierClient.createOrUpdateDonneesBancaires(donneesBancairesValues, ribPJ?.file && {"rib-PJ": ribPJ.file});
						props.setIsLoading(false);
					} catch (err) {
						if (err.exception?.message)
							toast.error(err.exception.message);
						else
							toast.error(t("errors.default"));
						isValid = false;
						props.setIsLoading(false);
					}
				}
			}
			return isValid;
        }
    }));



    return (<div className="versementsRib">
		<div className="creationDossier__header">
			<h3 className="title--dark">{t("creationVersement.menu.rib")}</h3>
		</div>
		<div className="creationDossier__item">
		{defaultDonneesBancaires.canEditRib
			? <DonneesBancaires
				form={form}
				donneesBancairesValidator={donneesBancairesValidator}
				isIbanValid={isIbanValid}
				setIsIbanValid={setIsIbanValid}
				ribPJ={ribPJ}
				setRibPJ={setRibPJ}
			/>
			: <DonneesBancaireReadonly donneesBancaires={defaultDonneesBancaires} />
		}
		</div>
	</div>);
})

export default Rib;
