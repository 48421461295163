export enum ValidationPropertyType {
	NotNull = 'NotNullValidator',
	NotEmpty = 'NotEmptyValidator',
	NotEqual = 'NotEqualValidator',
	Email = 'EmailValidator',
	Number = 'NumberValidator',
	Integer = 'IntegerValidator',
	LessThan = 'LessThanValidator',
	LessThanOrEqual = 'LessThanOrEqualValidator',
	GreaterThan = 'GreaterThanValidator',
	GreaterThanOrEqual = 'GreaterThanOrEqualValidator',
	Length = 'LengthValidator',
	ExactLength = 'ExactLengthValidator',
	MinimumLength = 'MinimumLengthValidator',
	MaximumLength = 'MaximumLengthValidator',
	RegularExpression = 'RegularExpressionValidator',
	UniqueCharCountProperty = 'UniqueCharCountPropertyValidator',

	AtLeastDigitChar = 'AtLeastXDigitValidator',
	AtLeastLowerCaseChar = 'AtLeastXLowerCaseCharValidator',
	AtLeastUpperCaseChar = 'AtLeastXUpperCaseCharValidator',
	AtLEastSpecialChar = 'AtLeastXSpecialCharValidator',
	BeforeNow = 'BeforeNowValidator',
	AfterNow = 'AfterNowValidator',
	OlderThanDays = 'OlderThanXDaysValidator',
	NotOlderThanDays = 'NotOlderThanXDaysValidator',
	YoungerThanDays = 'YoungerThanXDaysValidator',
	Iban = 'IbanPropertyValidator',
	Bic = 'BicPropertyValidator',
	Siret = 'SiretPropertyValidator',
	Phone = 'PhoneNumberPropertyValidator',

	Enum = 'EnumValidator',
	AspNetCoreCompatibleEmail = 'AspNetCoreCompatibleEmailValidator',
	ChildValidatorAdaptor = 'ChildValidatorAdaptor',
	Predicate = 'PredicateValidator',
	AsyncPredicate = 'AsyncPredicateValidator'
}