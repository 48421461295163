import React, { useReducer, ReactNode, useEffect } from 'react';
import { Context, DispatchAction } from './context';
import appReducer from './AppReducer';
import InitializeContextFromStorage from './InitializeContextFromStorage';

const defaultContextValue: Context.IContext = InitializeContextFromStorage();

/** The app context variable. */
const AppStateContext = React.createContext<[Context.IContext, React.Dispatch<DispatchAction>]>([defaultContextValue, () => null]);

/**
 * AppContext props interface.
 * 
 * @interface
 * 
 * @param {React.ReactNode} children The children to render.
 */
interface IAppContextProps {
	children: ReactNode;
}

/**
 * AppContext => Provide the context to the entire app.
 * 
 * @param   {IAppContextProps} props The component props.
 * @returns {JSX.Element}
 */
const AppContext: React.FunctionComponent<IAppContextProps> = ({
	children
}: IAppContextProps): JSX.Element => {
	/** The context value */
	const [context, setContext] = useReducer(appReducer, defaultContextValue);

	useEffect(() => {
		localStorage.setItem("adelcontext", JSON.stringify(context));

	}, [context]);

	return (
		<AppStateContext.Provider value={[context, setContext]}>
			{children}
		</AppStateContext.Provider>
	);
}

export default AppContext;
export { AppStateContext };
