import React from "react";
import InputReadOnly from '../../../InputReadOnly';
const ChoiceListQuestionInput = (props) => {
    const content = () => {
        var _a, _b;
        if (props.isMulti) {
            const values = (_a = props.currentValue) === null || _a === void 0 ? void 0 : _a.map((curr) => {
                var _a;
                const value = (_a = props.question) === null || _a === void 0 ? void 0 : _a.choices.filter(c => !props.choiceFilter || props.choiceFilter(c)).find(c => c.id === curr);
                return (value === null || value === void 0 ? void 0 : value.textWithoutTraduction) ? `${value === null || value === void 0 ? void 0 : value.textWithoutTraduction}` : `${value === null || value === void 0 ? void 0 : value.text[props.lang]}`;
            });
            if (values)
                return `${values.join(", ")}`;
        }
        else {
            const value = (_b = props.question) === null || _b === void 0 ? void 0 : _b.choices.filter(c => !props.choiceFilter || props.choiceFilter(c)).find(c => c.id === props.currentValue);
            return (value === null || value === void 0 ? void 0 : value.textWithoutTraduction) ? `${value === null || value === void 0 ? void 0 : value.textWithoutTraduction}` : `${value === null || value === void 0 ? void 0 : value.text[props.lang]}`;
        }
    };
    return React.createElement(InputReadOnly, { label: props.title, content: content() });
};
export default ChoiceListQuestionInput;
