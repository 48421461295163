import React from 'react';
const InformationsBudget = ({ budget, isDetailDossierTab = false }) => {
    return (React.createElement("div", { className: "detailsTab__content" },
        React.createElement("div", { className: "detailsTab__informations" },
            React.createElement("div", { className: "detailsTab__text" }, (budget === null || budget === void 0 ? void 0 : budget.commentaire) || '-'),
            (!isDetailDossierTab && (budget === null || budget === void 0 ? void 0 : budget.documents) && budget.documents.length > 0) && (React.createElement("div", { className: "detailsTab__attachedDocuments" },
                React.createElement("div", { className: "title" }, "Autres documents compl\u00E9mentaire relatif \u00E0 ce budget"),
                React.createElement("ul", null, budget.documents.map(x => (React.createElement("li", { key: x.id },
                    React.createElement("a", { target: "_blank", href: x.uri },
                        React.createElement("i", { className: "fas fa-file-upload" })),
                    x.fileName)))))))));
};
export default InformationsBudget;
