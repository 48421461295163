import React, { useState, useRef, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import Moment from "react-moment";
import 'react-calendar/dist/Calendar.css';
import useOnClickOutside from 'use-onclickoutside';
import { ErrorMessage } from "react-hook-form";
import clsx from "clsx";
const InputCalendar = ({ className, defaultDate, name, errors, label, onDateSelected, onResetExtraInfos, unlock = false, minDate, maxDate, position = 'left-bottom' }) => {
    const [date, setDate] = useState(defaultDate);
    const [showCalendar, setShowCalendar] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setShowCalendar(false));
    useEffect(() => {
        setDate(defaultDate);
    }, [defaultDate]);
    const handleShowCalendar = useCallback(() => {
        setShowCalendar(true);
    }, [setShowCalendar, showCalendar]);
    const handleReset = useCallback((e) => {
        e.stopPropagation();
        setDate(null);
        onDateSelected(null);
        if (onResetExtraInfos)
            onResetExtraInfos();
    }, [setDate, onDateSelected]);
    return (React.createElement("div", { className: clsx("inputFile__calendar", className), onClick: handleShowCalendar, ref: ref },
        date ? (React.createElement(React.Fragment, null,
            React.createElement(Moment, { format: "DD/MM/YYYY" }, date),
            React.createElement("button", { className: "reset", type: "button", onClick: handleReset },
                React.createElement("i", { className: "fas fa-times" })))) : (React.createElement("span", null, label)),
        React.createElement("i", { className: "far fa-calendar-alt" }),
        showCalendar && (React.createElement(Calendar, { className: clsx(`react-calendar--${position}`), onChange: (date) => {
                setDate(date);
                onDateSelected(date);
                setShowCalendar(false);
            }, minDate: minDate, maxDate: unlock ? maxDate : maxDate || new Date(), value: date, locale: "fr-FR" })),
        errors &&
            React.createElement(ErrorMessage, { errors: errors, name: name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message))));
};
export default InputCalendar;
