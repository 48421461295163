import React, { useState, useEffect, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef, useMemo, useContext, useImperativeHandle } from 'react';
import { AppStateContext } from '../../../../context/AppContext';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from "@reach/router";
import { StepVersementRef } from '../CreationVersement';
import _ from 'lodash';
import { DocumentDto, DocumentUploadInfoDto, TypeDeDocumentDto, VersementClient } from '../../../../services/generated/FrontOffice-api';
import { toast } from 'react-toastify';
import { useAxios } from '../../../../custom-hooks/useAxios';
import { AdelVersementClient } from '../../../../clients/AdelVersementClient';
import InputDocuments from '../../../basics/InputDocuments/InputDocuments';
import { useForm } from 'react-hook-form';
import { IFile } from '../../../../models/IFile';
import { Dictionary } from "adel-shared/dist/models";
import { DemandeVersementPieceJointeViewModelDto } from 'adel-shared/dist/models/generated/FrontOffice-api';

interface PiecesJointesProps extends RouteComponentProps {
    setCanGoNext: (value: boolean) => void;
    setIsFormValid: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
	piecesJointes: DemandeVersementPieceJointeViewModelDto;
	versementId: string;
	setPiecesJointes: (value:DemandeVersementPieceJointeViewModelDto) => void;
}

const PiecesJointes: ForwardRefExoticComponent<PropsWithoutRef<PiecesJointesProps> & RefAttributes<StepVersementRef>> = forwardRef((props, ref) => {
	const [{ currentEditingVersement: versement }] = useContext(AppStateContext);
    const { t } = useTranslation();

    const axiosInstance = useAxios();
    const adelVersementClient = useMemo(() => { return new AdelVersementClient("", axiosInstance) }, [axiosInstance]);
	const versementClient = new VersementClient("", axiosInstance);
	
	const [typeDeDocuments, setTypeDeDocuments] = useState<TypeDeDocumentDto[]>([]);		// Required documents (and not only existing) to show the user if new documents are required
	const [documents, setDocuments] = useState<DocumentDto[]>([]);

	const [dirtyFields, setDirtyFields] = useState<string[]>([]);							// Used to keep track of modified documents (which will need to be updated)
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const form = useForm();
	const { getValues, setValue, triggerValidation } = form;


    useEffect(() => {		
		if(props.piecesJointes) {
			const types = props.piecesJointes.typeDeDocuments;

			types.map(d => {
				d.code = d.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

				if(d.extraInfos && d.extraInfos.length > 0) {
					d.extraInfos.map(e => {
						e.code = e.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
					})
				}

				if(d.choix && d.choix.length > 0) {
					d.choix.map(f => {
						f.code = f.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

						if (f.extraInfos && f.extraInfos.length > 0) {
							f.extraInfos.map(g => g.code = g.code.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
						}
					})
				}
			});
			setTypeDeDocuments(types);
			setDocuments(props.piecesJointes.documents);
			
			props.setCanGoNext(true);
			props.setIsLoading(false);
		};
    }, [props.piecesJointes]);



	/*** POST */

    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
			let isValid = await triggerValidation();

			if (isValid) {
				if (isDirty) {
					// Need to Update documents so format documents and files;
					let modifiedDocuments: DocumentUploadInfoDto[] = [];
					let files: IFile[] = [];
					convertDocumentsAndFilesToDtosByRef(modifiedDocuments, files);
					props.setIsLoading(true);
					try {
						await adelVersementClient.createOrUpdatePieceJointes(versement.id, modifiedDocuments.filter(d => d), files);
						const pj = await versementClient.getPiecesJointesOnDemandeVersement(props.versementId);
						props.setPiecesJointes(pj);
						props.setIsLoading(false);
					} catch (err) {
						if (err.exception?.message)
							toast.error(err.exception.message);
						else if (err.message){
							toast.error(t(err.message));
						}
						else
							toast.error(t("errors.default"));
						isValid = false;
						props.setIsLoading(false);
					}
				}
			}
			return isValid;
        }
    }));

	const convertDocumentsAndFilesToDtosByRef = (modifiedDocuments: DocumentUploadInfoDto[], files: IFile[]): void => {

		let formFiles: Dictionary<FileList> = getValues();

		typeDeDocuments.forEach(typeDeDocument => {
			// TODO : Skip non dirty existing documents => strip them of everything except id and typeId
			let correspondingDocument: DocumentDto;

			if (typeDeDocument.hasChoix) {
				correspondingDocument = documents.find(document => typeDeDocument.choix.some(choix => document.type.id === choix.id));
			} else {
				correspondingDocument = documents.find(document => document.type.id === typeDeDocument.id);
			}

			const typeIdDoc = correspondingDocument 
								? correspondingDocument.type.id 
								: typeDeDocument.hasChoix 
									? typeDeDocument.choix.find(choix => !choix.hasAttachment).id
									: undefined;
			// s'il n'a pas de document correspondant, lui donner l'id du type de document qui n'a pas de corresponding document
			let modifiedDocument: DocumentUploadInfoDto;

			if(typeIdDoc) {
				modifiedDocument = { typeId: typeIdDoc };
			}

			if (!isFieldDirty(typeDeDocument.code) && correspondingDocument?.id) {
				if (correspondingDocument?.extraInfo && correspondingDocument?.extraInfo.length > 0) {
					modifiedDocument.extraInfos = {};
	
					correspondingDocument.extraInfo.forEach(extraInfo => {
						modifiedDocument.extraInfos[extraInfo.code] = extraInfo.value;
					});
				}
				modifiedDocuments.push({ id: correspondingDocument.id, typeId: correspondingDocument.type.id, extraInfos: modifiedDocument.extraInfos });
				return;
			} else {
				// Do nothing: id should not be specified for new or modified documents.
			}

			// If there's a corresponding file,
			let correspondingFile = formFiles[typeDeDocument.code];

			if (typeDeDocument.hasChoix) {
				typeDeDocument.choix.forEach(choix => {
					if (formFiles.hasOwnProperty(choix.code)) {
						correspondingFile = formFiles[choix.code];
					}
				});
			}

			if (correspondingFile && correspondingDocument) {
				let modifiedFile: IFile = {
					code: correspondingDocument.type.code,
					file: correspondingFile[0]
				} as IFile;

				modifiedDocument.partName = correspondingDocument.type.code;

				files.push(modifiedFile);
			}

			if (correspondingDocument?.extraInfo && correspondingDocument?.extraInfo.length > 0) {
				modifiedDocument.extraInfos = {};

				correspondingDocument.extraInfo.forEach(extraInfo => {
					modifiedDocument.extraInfos[extraInfo.code] = extraInfo.value;
				});
			}
			modifiedDocuments.push(modifiedDocument);
		});
	}

	const isFieldDirty = (fieldName: string): boolean => {
		if (dirtyFields.findIndex(dirtyField => dirtyField === fieldName) > -1) return true;
		return false;
	}



	/****/



	const handleDocumentChange = (modifiedDocument: DocumentDto, file?: File) => {
		let updatedDocuments = upsertDocument(documents, modifiedDocument, file);
		setDocuments(updatedDocuments);
		setIsDirty(true);
	}

	const upsertDocument = (documents: DocumentDto[], modifiedDocument: DocumentDto, file?: File): DocumentDto[] => {
		let existingDocument = documents.find(document => document.type.id == modifiedDocument.type.id);
		if (!existingDocument) {
			// INSERT
			return [...documents, modifiedDocument];
		} else {
			// UPDATE by merging properties using modification by reference ><
			if (modifiedDocument.fileName) existingDocument.fileName = modifiedDocument.fileName;
			if (modifiedDocument.extraInfo) existingDocument.extraInfo = modifiedDocument.extraInfo;
			return documents;
		}
	}

	const onReset = (fieldName: string,) => {
		setValue(fieldName, '');
		setDocuments(documents.filter(d => d.type.code != fieldName));
		if (dirtyFields.findIndex(field => field === fieldName) <= -1) {
			setDirtyFields([...dirtyFields, fieldName]);
		}
		setIsDirty(true);
	}



    return (
		<div className="versementsPiecesJointes">
			<div className="creationDossier__header">
				<h3 className="title--dark">
					{t("creationVersement.menu.piecesJointes")}
				</h3>
			</div>
			<div className="creationDossier__item">
				<InputDocuments
					typeDeDocuments={typeDeDocuments}
					documents={documents}
					onDocumentChange={handleDocumentChange}
					onReset={onReset}
					form={form}
					isVersement={true}
				/>
			</div>
		</div>
	);
})

export default PiecesJointes;
