import React from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Subtitles from "../../../basics/Subtitles";
import Input from "../../../basics/Input";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import PasswordValidation from "./PasswordValidation";
import { AuthClient } from "../../../../services/generated/FrontOffice-api";
import useValidation from "../../../../custom-hooks/useValidation";
import { IResetPasswordModel } from "../../../../models";
import { useAxios } from "../../../../custom-hooks/useAxios";
import { RouteComponentProps } from "@reach/router";
import { toast } from 'react-toastify';

interface ResetPasswordProps extends RouteComponentProps {
	setResetPassword?: any;
	validPass?: boolean;
	setValidPass?: any;
	setShowPass?: any;
	showPass?: boolean;
}

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({
	setResetPassword,
	validPass,
	setValidPass
}) => {
	const { t } = useTranslation();
	const { register, getValues, triggerValidation, errors } = useForm<IResetPasswordModel>();
	const { getRootValidator } = useValidation();
	const userNameValidator = getRootValidator("PasswordResetRequestDto");
	const axiosInstance = useAxios();

	const sendResetMail = async () => {
		const result = await triggerValidation("username");

		if (result) {
			const client = new AuthClient('', axiosInstance);
			const username = getValues().username;

			const resetPasswordDto = { "userName": username };
			try {
				await client.resetPassword(resetPasswordDto)
				setValidPass(false);
			} catch(error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}
	return (
		<>
			{validPass ?
				<>
					<Subtitles
						title={t('password.reset.subtitle')}
					/>

					<div className="password">
						<form>
							<span>{t('password.reset.details')}</span>

							<Input
								name="username"
								reference={register(userNameValidator?.["UserName"])}
								label={t('portal.connection.login')}
								placeHolder={t('portal.connection.login-placeholder')}
								type="text"
								errors={errors}
							/>

							<div className="modal__CTA">
								<button
									className="modal__CTA--button"
									onClick={() => {
										setResetPassword(false);
									}}
								>
									{t('portal.connection.back')}
								</button>

								<FormButton
									type="button"
									value={t('portal.connection.send-button')}
									onClick={sendResetMail}
								/>
							</div>

						</form>
					</div>
				</>
				:
				<PasswordValidation
					setResetPassword={setResetPassword}
					setValidPass={setValidPass}
					title={t('password.reset.validation.subtitle')}
					paragraph={t('password.reset.validation.text')}
				/>
			}
		</>
	)
}

export default ResetPassword;