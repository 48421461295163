import React, { useState, useContext, useRef, useEffect, FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Lang from "../components/basics/Lang";
import WhiteLogo from "../components/logo/WhiteLogo";
import Subtitles from "../components/basics/Subtitles";
import SignupList from '../components/signup/signup-items/SignupList';
import Login from "../components/basics/Login";
import Footer from "../components/basics/Footer";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import SignupStep from '../context/SignupSteps';

import StepOne from "../components/signup/StepOne";
import StepTwo from "../components/signup/StepTwo";
import StepThree from "../components/signup/StepThree";
import StepFour from "../components/signup/StepFour";
import StepFive from "../components/signup/StepFive";
import StepSix from "../components/signup/StepSix";
import StepSeven from "../components/signup/StepSeven";

import { AppStateContext } from "../context/AppContext";
import { ActionTypeEnum } from "../context/ActionType";
import { useSignupSteps } from "../custom-hooks/useSteps";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, Router, navigate } from "@reach/router";

import { AdelStructureClient } from '../clients/AdelStructureClient'
import { DocumentUploadInfoDto, TypePoste } from '../services/generated/FrontOffice-api';

import { useAxios } from '../custom-hooks/useAxios';
import StepHeight from "../components/signup/StepHeight";
import { convertIFilesToDocumentUploadInfoDto } from '../utils/functions';

const Signup: FunctionComponent<RouteComponentProps> = () => {
	const [context, dispatch] = useContext(AppStateContext);
	const { getNextStep, getPreviousStep, isHigherStep } = useSignupSteps();
	const [canGoNext, setCanGoNext] = useState<boolean>(false);
	const { t } = useTranslation();
	const axiosInstance = useAxios();

	const [currentStep, setCurrentStep] = useState(SignupStep.One);
	const [menuCheckbox, setMenuCheckbox] = useState(true);
	const refs: { [key: string]: React.MutableRefObject<any>; } = {
		[SignupStep.One]: useRef<any>(null),
		[SignupStep.Two]: useRef<any>(null),
		// [SignupStep.Three]: useRef<any>(null),
		[SignupStep.Four]: useRef<any>(null),
		[SignupStep.Five]: useRef<any>(null),
		[SignupStep.Six]: useRef<any>(null),
		[SignupStep.Seven]: useRef<any>(null)
	};

	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		navigate(`/Inscription/${currentStep}`);
		if (currentStep > context.signupHighestStep) { //TODO: utiliser isHigherStep
			dispatch({ type: ActionTypeEnum.SET_SIGNUP_HIGHEST_STEP, payload: currentStep });
		}
	}, [currentStep, context, dispatch]);

	const nextStep = async () => {
		const isValid = await refs[currentStep].current.validateForm();
		const nextStep = getNextStep(currentStep);
		if (isValid) {
			setCurrentStep(nextStep);
		}
	}

	const previousStep = () => {
		const previousStep = getPreviousStep(currentStep);
		setCurrentStep(previousStep);
	}

	const validateFinalStep = async () => {
		const isValid = await refs[currentStep].current.validateForm();
		if (isValid) {
			dispatch({ type: ActionTypeEnum.CAN_CREATE_STRUCTURE, payload: true });
		}
	}

	const createStructure = async () => {
		const nextStep = getNextStep(currentStep);
		const structureClient = new AdelStructureClient("", axiosInstance);
		let documents: DocumentUploadInfoDto[] = [];
		let canClear: boolean;

		if (context.signupStepSix.files) {
			documents = convertIFilesToDocumentUploadInfoDto(context.signupStepSix.files);
		}

		try {
			setIsLoading(true);
			const structure = await structureClient.structure({
				siret: context.signupStepOne.siret,
				raisonSociale: context.signupStepOne.raisonSociale,
				codeAPE: context.signupStepOne.codeAPE,
				nomenclatureAPE: context.signupStepOne.nomenclatureAPE,
				codeLangue: context.signupStepFive.codeLangue,
				adresse: {
					line1: context.signupStepOne.adresse,
					line2: context.signupStepOne.line2,
					codePostal: context.signupStepOne.codePostal,
					ville: context.signupStepOne.ville
				},
				adresseCorrespondance: context.signupStepFive.hasAdresseParDefaut
					? null
					: {
						line1: context.signupStepFive.rue,
						line2: context.signupStepFive.complementAdresse,
						codePostal: context.signupStepFive.codePostal,
						ville: context.signupStepFive.ville
					},
				formeJuridiqueId: context.signupStepOne.formeJuridique.id,
				correspondant: context.signupStepFour.hasCorrespondantParDefaut
					? null
					: {
						poste: context.signupStepFour.poste as TypePoste,
						civilite: context.signupStepFour.civilite,
						nom: context.signupStepFour.nom,
						prenom: context.signupStepFour.prenom,
						email: context.signupStepFour.email,
						telephoneFixe: context.signupStepFour.telephoneFixe,
						telephonePortable: context.signupStepFour.telephonePortable,
					},
				president: {
					prenom: context.signupStepOne.presidentPrenom,
					nom: context.signupStepOne.presidentNom,
					email: context.signupStepOne.email,
					telephoneFixe: context.signupStepOne.telephoneFixe,
					telephonePortable: context.signupStepOne.telephonePortable,
					poste: context.signupStepOne.presidentPoste,
					civilite: context.signupStepOne.civilite
				},
				informations: context.signupStepTwo.informations,
				documents
			}, context.signupStepSix.files);

			setCurrentStep(nextStep);
			canClear = true;
			dispatch({ type: ActionTypeEnum.SET_SIGNUP_STEP_SEVEN, payload: { 
				...context.signupStepSeven, 
				structureId: structure.id, 
				email: context.signupStepOne.email } 
			});
		}

		catch (error) {
			dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: error as string } });
			canClear = false;
		}

		finally {
			dispatch({ type: ActionTypeEnum.CAN_CREATE_STRUCTURE, payload: false });
			setIsLoading(false);

			if (canClear) {
				dispatch({ type: ActionTypeEnum.LOGOUT_USER });
				localStorage.clear();
			}
		}
	}

	useEffect(() => {
		if (context.canCreateStructure) {
			createStructure();
		}
	}, [context.canCreateStructure])

	const navigateToStep = async (targetStep: SignupStep): Promise<void> => {
		// If trying to access to current step : do nothing
		if (targetStep === currentStep) return;

		// If trying to access to already filled step, allow navigation
		if (!isHigherStep(context.signupHighestStep, targetStep) && currentStep < SignupStep.Seven) {
			setCurrentStep(targetStep);
			return;
		}

		// Get next step (next step after highest step)
		let nextStep = getNextStep(context.signupHighestStep);

		// If user is trying to access next step after highest step
		if (targetStep === nextStep) {
			// Make sure highest step is valid
			let isValid = await refs[context.signupHighestStep].current?.validateForm();
			if (isValid) {
				setCurrentStep(targetStep);
			}
			return;
		}

		// Steps higher than current highest step should not be clickable (SignupList)
		// So this error should never appear
		console.debug("Attempting to jump to a step higher than highest step + 2");
	}

	return (
		<div id="signup" className="login">
			<div className="modal">
				<section className="modal__item modal__leftPart">

					<WhiteLogo />

					<Subtitles
						title={t('signup.title')}
					/>

					<SignupList
						navigateToStep={navigateToStep}
						currentStep={currentStep}
						highestStep={context.signupHighestStep}
					/>
					<div className="modal__footer">
						<Login />
						<Footer />
					</div>
				</section>

				<section className="modal__item modal__rightPart">

					<Lang />

					{currentStep !== SignupStep.One &&
						<button
							className="previousStep"
							onClick={() => {
								previousStep();
							}}
						>
							<FontAwesomeIcon icon="chevron-left" />
						</button>
					}

					<Router>
						<StepOne
							ref={refs[currentStep]}
							setCanGoNext={setCanGoNext}
							path={SignupStep.One}
						/>

						<StepTwo
							ref={refs[currentStep]}
							setCanGoNext={setCanGoNext}
							path={SignupStep.Two}
						/>

						{/* <StepThree
							ref={refs[currentStep]}
							setCanGoNext={setCanGoNext}
							path={SignupStep.Three}
						/> */}

						<StepFour ref={refs[currentStep]}
							path={SignupStep.Four}
							setCanGoNext={setCanGoNext}
						/>

						<StepFive ref={refs[currentStep]}
							path={SignupStep.Five}
							setCanGoNext={setCanGoNext}
						/>

						<StepSix
							ref={refs[currentStep]}
							path={SignupStep.Six}
							setCanGoNext={setCanGoNext}
						/>

						<StepSeven
							ref={refs[currentStep]}
							path={SignupStep.Seven}
						/>

						<StepHeight
							path={SignupStep.Height}
						/>
					</Router>

					{
						currentStep !== SignupStep.Six && currentStep !== SignupStep.Seven &&
						<div className="signupfooter__CTA">
							<span>{t('signup.mandatory-fields')}</span>

							<FormButton
								type="button"
								value={t('signup.next-button')}
								onClick={nextStep}
								disabled={!canGoNext}
							/>
						</div>
					}

					{
						currentStep === SignupStep.Six &&
						<div className="signupfooter__CTA">
							<span>{t('signup.mandatory-fields')}</span>
							{
								isLoading ?
									<Loader
										type="TailSpin"
										width={35}
										height={35}
										color="#d93943"
									></Loader>
									:
									<FormButton
										type="button"
										value={t('signup.validate-button')}
										onClick={validateFinalStep}
										disabled={!canGoNext}
									/>
							}
						</div>
					}
				</section>
			</div>
		</div>
	)
}

export default Signup;