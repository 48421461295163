import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FormContextValues, ValidationOptions} from "react-hook-form";
import Input from 'adel-shared/dist/components/basics/Input';
import InputValidation from "../../../components/basics/InputValidation";
import {Dictionary} from "../../../models";
import { BanqueClient, AdresseClient, GeoAdresseDto } from "../../../services/generated/FrontOffice-api";
import { useAxios } from '../../../custom-hooks/useAxios';
import useValidation from "../../../custom-hooks/useValidation";
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import InputFileSingle, { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';

interface DonneesBancairesProps {
	form: FormContextValues;
	donneesBancairesValidator: Dictionary<ValidationOptions>;
	isIbanValid: boolean;
	setIsIbanValid: (value: boolean) => void;
	ribPJ: DocumentWithFile;
	setRibPJ: (value:DocumentWithFile) => void;
}

const DonneesBancaires: React.FunctionComponent<DonneesBancairesProps> = ({
	form: {
		triggerValidation,
		register,
		getValues,
		setValue,
		watch,
		errors
	},
	donneesBancairesValidator,
	isIbanValid,
	setIsIbanValid,
	ribPJ,
	setRibPJ

}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);
	const {ville, codePostal} = watch(['ville', 'codePostal']);

	const validateIBan = async () => {
		const client = new BanqueClient('', axiosInstance);
		if (!triggerValidation("iban")) {
			return false;
		}

		let { iban } = getValues();
		iban = iban.replace(/\s/g, "");
		setIsLoading(true);
		try {
			var result = await client.validateIban(iban)

			if (result === false) {
				setIsIbanValid(false);
				setIsLoading(false);
				return false;
			} else {
				setIsIbanValid(true);
				setValue([
					{ iban: iban },
				]);
				setRibPJ(undefined);
				setIsLoading(false);
				return true;
			}
		}
		catch (error) {
			setIsIbanValid(false);
			setIsLoading(false);
			if (error.exception?.message)
				toast.error(error.exception.message);
			return false;
		}
	}


	// Invalidate Iban to be able to check again the api if the Iban is modified
	const { getRootValidator } = useValidation();
	const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");

	const invalidateIban = async (): Promise<void> => {
		setIsIbanValid(false);
	}

	const searchAdresse = (input: string) => {
		if (!!input) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);
				adresseClient.searchAdresse(`${ville} ${input}`, codePostal).then(results => {
					setGeoAdresse(results);

				});
				onAdresseChange(input);
			}
			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}
	const onAdresseChange = (label: string) => {
		const selectedAdresse = geoAdresse.find(adresse => adresse.label === label);

		if (selectedAdresse) {
			setValue([
				{ line1: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
				{ codePostal: selectedAdresse.codePostal },
				{ ville: selectedAdresse.ville }
			]);
		}
	}

	const handleFileChange = (file: DocumentWithFile) => {
		if(file) {
			setRibPJ({fileName: file.fileName, url:file.url, file: file.file});
		} else {
			setRibPJ(undefined);
			setValue([
				{ iban: null},
				{ banque: null },
				{ bic: null },
				{ line1: null },
				{ line2: null },
				{ codePostal: null },
				{ ville: null },
				{ pays: null }
			]);
			setIsIbanValid(false);
		}
	};

	return (<>
		<div className="creationDossier__item creationDossier__validation">
			<InputValidation
				name="iban"
				reference={register(donneesBancairesValidator && donneesBancairesValidator["Iban"])}
				label={t('signup.step-three.label')}
				placeHolder={t('signup.step-three.placeholder')}
				maxLength={34}
				type=""
				buttonLabel={t('signup.step-three.validation-button')}
				onChange={invalidateIban}
				isLoading={isLoading}
				onValidate={validateIBan}
				validated={isIbanValid}
				errors={errors}
			/>
			{isIbanValid && <>
				<div className="creationDossier__row">
					<Input
						name="bic"
						reference={register(donneesBancairesValidator && donneesBancairesValidator["Bic"])}
						label={t('signup.step-three.bic-label')}
						type="text"
						placeHolder={t('signup.step-three.bic-placeholder')}
						maxLength={11}
						errors={errors}
					/>
					<Input
						name="banque"
						reference={register(donneesBancairesValidator && donneesBancairesValidator["Banque"])}
						label={t('signup.step-three.banque-label')}
						type="text"
						placeHolder={t('signup.step-three.banque-placeholder')}
						maxLength={60}
						errors={errors}
					/>
				</div>
				<div className="creationDossier__row">
					<Input
						name="ville"
						reference={register(adresseValidator?.["Ville"])}
						label={t('signup.step-three.ville-label')}
						type="text"
						placeHolder={t('signup.step-three.ville-placeholder')}
						maxLength={60}
						errors={errors}
					/>
					<Input
						name="codePostal"
						reference={register(adresseValidator?.["CodePostal"])}
						label={t('signup.step-three.codepostal-label')}
						type="tel"
						placeHolder={t('signup.step-three.codepostal-placeholder')}
						maxLength={5}
						errors={errors}
					/>
				</div>
				<div className="creationDossier__row">
					<Input
						name="line1"
						reference={register(adresseValidator?.["Line1"])}
						label={t('signup.step-three.adresse-label')}
						type="text"
						placeHolder={t('signup.step-three.adresse-placeholder')}
						maxLength={60}
						errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={debounce((value) => searchAdresse(value), 1000)}
					/>
					<Input
						name="line2"
						reference={register(adresseValidator?.["Line2"])}
						label={t('signup.step-five.complementAdresse')}
						type="text"
						placeHolder={t('signup.step-three.adresse-placeholder')}
						maxLength={60}
						errors={errors}
					/>
				</div>
				<div className="creationDossier__row">
					<Input
						name="pays"
						reference={register()}
						label={t('signup.step-three.pays-label')}
						type="text"
						placeHolder={t('signup.step-three.pays-placeholder')}
						maxLength={30}
						errors={errors}
					/>
					<div className="input">
						<label>{t('signup.step-three.ribPj')}</label>
						<InputFileSingle
							labelButton={t('signup.step-three.ribPJ-label')}
							onChange={(file:any) => handleFileChange(file)}
							isRequired={true}
							currentValue={ribPJ && {
								id: ribPJ.id,
								fileName: ribPJ.fileName || '',
								url: ribPJ.url
							}}
							name="rib"
							errorMessage={errors}
							reference={register({ required: `${t("validation-messages.required-document")}` })}
						/>
					</div>
				</div>
			</>}
		</div>
	</>)
}

export default DonneesBancaires;