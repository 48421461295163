import React, { useMemo, useState, useEffect } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { ArtisteLightDto, ArtisteClient, MasseSalarialeDto } from '../../../../services/generated/FrontOffice-api';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import { useAxios } from '../../../../custom-hooks/useAxios';
import ModalDeleteArtiste from './ModalDeleteArtiste';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import TotalAmountsTable from 'adel-shared/dist/components/basics/TotalAmountsTable';

interface ArtisteListProps {
	artistes: ArtisteLightDto[]
	onAddArtist: () => void;
	duplicateArtiste: (id: string) => void;
	editArtiste: (id: string) => void;
	informDeleteArtiste: () => void;
	dossierId: string;
	categorieDossierId: string;
	stats: MasseSalarialeDto;
}

const ArtisteList: React.FunctionComponent<ArtisteListProps> = ({
	artistes,
	onAddArtist,
	duplicateArtiste,
	editArtiste,
	informDeleteArtiste,
	stats
}) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const artisteClient = useMemo(() => { return new ArtisteClient("", axiosInstance) }, [axiosInstance]);
	const [modalDeletePageVitrineIsOpen, setModalDeletePageVitrineIsOpen] = useState<boolean>(false)
	const [artisteId, setArtisteId] = useState()
	const [totalsData, setTotalsData] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(artistes.map(x => ({
			...x,
			emploi: t(`emplois.${x.emploi}`)
		})));
	}, [artistes]);

	const columns = React.useMemo(() => { // VFA : Je n'arrive pas à trouver le type exact de cet objet... Je laisse en any T_T
		return [{
			Header: "Table",
			columns: [
				{ Header: "Id", accessor: "id", disableSortBy: true },
				{ Header: t('createFolder.contratsCachets.listColumns.nom'), accessor: "nom", disableSortBy: true },
				{ Header: t('createFolder.contratsCachets.listColumns.prenom'), accessor: "prenom", disableSortBy: true },
				{ Header: t('createFolder.contratsCachets.listColumns.emploi'), accessor: "emploi", disableSortBy: true },
				{
					Header: "", accessor: "Actions", disableSortBy: true,
					Cell: (value: any) => (
						<span className="creationDossier__table-buttons">
							<div className="icon-button" onClick={() => { duplicateArtiste(value.cell.row.values.id) }}><i className="far fa-copy"></i></div>
							<div className="icon-button" onClick={() => { editArtiste(value.cell.row.values.id) }}><i className="far fa-edit"></i></div>
							<div className="icon-button" onClick={() => { openDeleteArtiste(value.cell.row.values.id) }}><i className="far fa-trash-alt"></i></div>
						</span>
					)
				}
			]
		}];
	}, []);

	useEffect(() => {
		if(!Object.keys(stats).length) return;

		const updatedData = [];

		Object.keys(stats.montantCachets).map(key => {
			updatedData.push({
				label: t(`createFolder.contratsCachets.stats${key}`),
				value: `${formatNumber(stats.montantCachets[key])} €`
			})
		});

		if(stats.montantSalaires !== 0) {
			updatedData.push({
				label: 'Montant salaires',
				value: `${formatNumber(stats.montantSalaires)} €`
			})
		}

		if(stats.chargesPatronales !== 0) {
			updatedData.push({
				label: 'Charges patronales',
				value: `${formatNumber(stats.chargesPatronales)} €`
			})
		}

		setTotalsData(updatedData);
	}, [stats]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<ArtisteLightDto>({
		columns,
		data,
		initialState: { hiddenColumns: ["id"] },
	}, useSortBy);

	const openDeleteArtiste = (data: any) => {
		setArtisteId(data)
		setModalDeletePageVitrineIsOpen(true)
	}

	const deleteArtiste = () => {
		artisteClient.deleteArtiste(artisteId);
		setModalDeletePageVitrineIsOpen(false);
		informDeleteArtiste();
	}

	return <>
		<div className="creationDossier__header">
			<h3 className="title--dark">{t("createFolder.contratsCachets.title")}</h3>
		</div>
		<div className="creationDossier__singleRightButton">
			<FormButton
				type="button"
				value={t("createFolder.contratsCachets.add")}
				onClick={onAddArtist}
			/>
		</div>
		<Table
			data={artistes}
			getTableProps={getTableProps}
			getTableBodyProps={getTableBodyProps}
			headerGroups={headerGroups}
			rows={rows}
			prepareRow={prepareRow}
			styles={styleTable.onglet}
		/>
		{stats && artistes?.length > 0 && (
			<TotalAmountsTable
				data={totalsData}
			/>
		)}
		<ModalDeleteArtiste
			isOpen={modalDeletePageVitrineIsOpen}
			onCancel={() => setModalDeletePageVitrineIsOpen(false)}
			onValidate={deleteArtiste}
		/>
	</>;
}

export default ArtisteList;