import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { Dictionary } from 'adel-shared/dist/models';
import React, { FunctionComponent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { RouteComponentProps } from '@reach/router';
import { useAxios } from '../../../custom-hooks/useAxios';
import { CategorieDossierDto, StructureClient } from '../../../services/generated/FrontOffice-api';

interface TypeDossierProps extends RouteComponentProps {
	ref: any,
	categorieTypeImgDic: Dictionary<any>,
	setCategorieDossierId: (value: string) => void;
	categorieDossierId: string;
	setCanGoNext: (value: boolean) => void;
	dossierId?: string;
	setIsLoading: (value: boolean) => void;
	showQuotaAtteintError: boolean;
}

const TypeDossier: FunctionComponent<TypeDossierProps> = forwardRef((props, ref) => {
	const { t } = useTranslation();

	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);
	const [types, setTypes] = useState<CategorieDossierDto[]>();

	const [typesOptions, setTypesOptions] = useState<AdelOption<string>[]>();
	const [typeSelected, setTypeSelected] = useState<string>(props.categorieDossierId);

	const isDossierExist = props.dossierId !== null && props.dossierId !== undefined && props.dossierId !== "";


	/** Get Document Infos */
	const getTypes = async () => {
		props.setIsLoading(true);
		try {
			var types = await structureClient.getStructureCategoriesDossier();
			setTypes(types);
			typeOptionsList(types);
			props.setIsLoading(false);
		} catch (error) {
			props.setIsLoading(false);
			console.error("error", error) /** TODO: catch error dispatch */
		}
	}

	useEffect(() => {
		getTypes();
		// if(props.showQuotaAtteintError) {
		// 	toast.error(t('createFolder.type.quotaAtteintError'));
		// }
	}, []);

	useEffect(() => {
		if (typeSelected !== "") {
			// Find matching category
			let selectedType = types?.find(type => type.id === typeSelected);
			if (typeSelected || selectedType && selectedType.canBeCreated)
				props.setCanGoNext(true);
			else
				props.setCanGoNext(false);
		} else {
			props.setCanGoNext(false);
		}
	}, [typeSelected]);


	/** Select */
	const typeOptionsList = (types: CategorieDossierDto[]) => {
		const options: AdelOption<string>[] = types.map(type => ({
			value: type.id ? type.id : '',
			label: type.nom ? type.nom : '',
			hasClass: '',
			img: `${props.categorieTypeImgDic[type.code] || props.categorieTypeImgDic['AUTRE']}`,
			disabled: !type.canBeCreated
		})).sort((a, b) => a.label.localeCompare(b.label));
		setTypesOptions(options);
	};

	const handleInputSelectChange = (selectedOption: string) => {
		setTypeSelected(selectedOption)
	};

	/** Post */
	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			// const result = await triggerValidation();

			if (!typeSelected || typeSelected === "")
				return false
			else {
				props.setCategorieDossierId(typeSelected);
				return true;
			}
		}
	}));

	const { control } = useForm<any>({});

	return (
		<div>
			<h4>{t("createFolder.type.title")}</h4>
			<Controller control={control}
				name="typeSelect"
				as={({ onChange, value, name }) => (
					<InputSelect<string>
						name={name}
						classname="inputSelect inputSelect--light inputSelect--fullWidth"
						options={typesOptions}
						onChange={(p) => {
							onChange(p);
							handleInputSelectChange(p);
						}}
						value={typeSelected}
						onTryToSelectADisabledValue={(value: string) => toast.error(t('createFolder.type.selectDisabledValue'))}
						readonly={isDossierExist}
						placeholder={t("common.select")}
					/>
				)}
			/>
		</div>
	)
});

export default TypeDossier;
