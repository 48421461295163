import React, { useContext } from 'react';
import { Context, DispatchAction } from './context';
import { AppStateContext } from './AppContext';
import { IStepOneModel, IStepTwoModel, IStepThreeModel, IStepFourModel, IStepFiveModel, IStepSixModel, IStepSevenModel } from '../models/signup';
import { FormeJuridiqueDto, FOCurrentUserDto } from '../services/generated/FrontOffice-api';
/**
 * The useAppStateContext hook.
 *
 * @returns {Context.IContext} The current app state context.
 */
const useAppStateContext = (): [Context.IContext, React.Dispatch<DispatchAction>] => {
	const contextValue = useContext(AppStateContext)
	return contextValue;
}

/**
 * The useAppDispatch hook.
 *
 * @returns {React.Dispatch<DispatchAction>} The current dispatch function to update the app state.
 */
const useAppDispatch = (): React.Dispatch<DispatchAction> => {
	const [, dispatch] = useAppStateContext();
	return dispatch;
}

/**
 * The useErrorContext hook.
 *
 * @returns {Context.IAppError} The current app error context.
 */
const useErrorContext = (): Context.IAppError => {
	const [context] = useAppStateContext();
	return context.error;
}

const useUserContext = (): FOCurrentUserDto => {
	const [context] = useAppStateContext();
	return context.user;
}

const useStepOneContext = (): IStepOneModel => {
	const [context] = useAppStateContext();
	return context.signupStepOne;
}

const useStepTwoContext = (): IStepTwoModel => {
	const [context] = useAppStateContext();
	return context.signupStepTwo;
}

const useStepThreeContext = (): IStepThreeModel => {
	const [context] = useAppStateContext();
	return context.signupStepThree;
}

const useStepFourContext = (): IStepFourModel => {
	const [context] = useAppStateContext();
	return context.signupStepFour;
}

const useStepFiveContext = (): IStepFiveModel => {
	const [context] = useAppStateContext();
	return context.signupStepFive;
}

const useStepSixContext = (): IStepSixModel => {
	const [context] = useAppStateContext();
	return context.signupStepSix;
}

const useStepSevenContext = (): IStepSevenModel => {
	const [context] = useAppStateContext();
	return context.signupStepSeven;
}

const useFormeJuridiqueContext = (): FormeJuridiqueDto[] => {
	const [context] = useAppStateContext();
	return context.formesJuridiques;
}

export {
	useAppStateContext,
	useUserContext,
	useErrorContext,
	useAppDispatch,
	useStepOneContext,
	useStepTwoContext,
	useStepThreeContext,
	useStepFourContext,
	useStepFiveContext,
	useStepSixContext,
	useStepSevenContext,
	useFormeJuridiqueContext
};
