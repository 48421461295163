import FormButton from "adel-shared/dist/components/basics/FormButton";
import Input from "adel-shared/dist/components/basics/Input";
import InputCalendar from "adel-shared/dist/components/basics/InputCalendar";
import InputRadioYesNo from "adel-shared/dist/components/basics/InputRadioYesNo";
import InputSelect, {
  AdelOption,
} from "adel-shared/dist/components/basics/InputSelect";
import { normalizeDate } from "adel-shared/dist/utils/functions";
import { parseNumber } from "libphonenumber-js";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { modalLargeCustomStyles } from "../../../../../constants/config.constant";
import { AppStateContext } from "../../../../../context/AppContext";
import { useAxios } from "../../../../../custom-hooks/useAxios";
import useValidation from "../../../../../custom-hooks/useValidation";
import { CategorieDossier } from "../../../../../enums/Dossiers";
import {
  CommissionDto,
  CreateOrUpdateVersementArtisteDto,
  DossierArtistesViewModelDto,
  DossierClient,
  Emploi,
  TypeVersement,
  VersementArtisteDto,
  VersementClient,
} from "../../../../../services/generated/FrontOffice-api";
import { ContratsCachetsAction } from "../ContratsCachets";

interface ModalAddArtistProps {
  isOpen: boolean;
  onClose: () => void;
  isDuplicating?: boolean;
  isLoading?: boolean;
  artisteSelected?: VersementArtisteDto;
  codeCategorie: CategorieDossier;
  commission?: CommissionDto;
  onUpdateContratsCachets: (action: ContratsCachetsAction) => void;
}

const ModalAddArtist: React.FunctionComponent<ModalAddArtistProps> = ({
  isOpen,
  isDuplicating,
  onClose,
  artisteSelected = {},
  codeCategorie,
  commission,
  onUpdateContratsCachets,
}) => {
  const { t, i18n } = useTranslation();
  const {
    getValues,
    setValue,
    control,
    register,
    errors,
    watch,
    triggerValidation,
  } = useForm<CreateOrUpdateVersementArtisteDto>({
    defaultValues: {
      emploi: artisteSelected.emploi || Emploi.Chanteur,
      styleMusicalId: artisteSelected.styleMusical?.id || "",
      instrumentId: artisteSelected.instrument?.id,
      isTitulaire: null,
      datesTravailles: [],
    },
  });

  const { emploi, isTitulaire, datesTravailles } = watch([
    "emploi",
    "isTitulaire",
    "datesTravailles",
  ]);

  const { getRootValidator } = useValidation();
  const artisteValidator =
    getRootValidator("CreateOrUpdateVersementArtisteDto") || {};
  const axiosInstance = useAxios();
  const [{ currentEditingVersement: versement }] = useContext(AppStateContext);

  // TODO: Mettre dans le store
  const dossierClient = useMemo(
    () => new DossierClient("", axiosInstance),
    [axiosInstance]
  );
  const versementClient = useMemo(
    () => new VersementClient("", axiosInstance),
    [axiosInstance]
  );

  const [viewModel, setViewModel] = useState<DossierArtistesViewModelDto>({});

  //infos extraites de viewmodel
  const [instrumentsOptions, setInstrumentsOptions] = useState<
    AdelOption<string>[]
  >([]);
  const [stylesMusicauxOptions, setStylesMusicauxOptions] = useState<
    AdelOption<string>[]
  >([]);
  const [emploisOptions, setEmploisOptions] = useState<AdelOption<Emploi>[]>(
    []
  );

  const [dateSelected, setDateSelected] = useState<Date>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      getArtistesViewModel();
    })();
  }, []);

  const getArtistesViewModel = async () => {
    const result = await dossierClient.getArtistesViewModel(
      versement.dossierId
    );
    setViewModel(result);

    //on récupère la liste d'instruments
    if (result?.instruments) {
      const instruments = result.instruments.map((instrument) => ({
        label:
          (instrument?.intitule && instrument.intitule[i18n.language]) || "",
        value: instrument?.id || "",
      }));
      setInstrumentsOptions(instruments);
    }
    //on récupère la liste des styles musicaux
    if (result?.stylesMusicaux) {
      const stylesMusicaux = result.stylesMusicaux.map((styleMusical) => ({
        label:
          (styleMusical?.intitule && styleMusical.intitule[i18n.language]) ||
          "",
        value: styleMusical?.id || "",
      }));
      setStylesMusicauxOptions(stylesMusicaux);
    }
    //on récupère la liste des emplois
    if (result?.emplois) {
      const emplois = result.emplois.map((emploi) => ({
        label: t("emplois." + emploi),
        value: emploi,
      }));
      setEmploisOptions(emplois);
    }
  };

  useEffect(() => {
    if (artisteSelected.id && viewModel) {
      setValue([
        { nom: artisteSelected.nom },
        { prenom: artisteSelected.prenom },
        { email: artisteSelected.email },
        { lieuHabitation: artisteSelected.lieuHabitation },
        { isTitulaire: artisteSelected.isTitulaire },
        { totalSalairesBruts: artisteSelected.totalSalairesBruts },
        { totalCharges: artisteSelected.totalCharges },
        {
          datesTravailles: artisteSelected.datesTravailles.map(
            (x) => new Date(x)
          ),
        },
      ]);
    }
  }, [artisteSelected, viewModel]);

  const submit = async () => {
    const result = await triggerValidation();

    if (!result) return;

    const data = {
      ...getValues(),

      totalCharges: parseFloat(
        getValues("totalCharges").toString().replace(",", ".")
      ),
      totalSalairesBruts: parseFloat(
        getValues("totalSalairesBruts").toString().replace(",", ".")
      ),
      datesTravailles: datesTravailles.map((x) => normalizeDate(x)),
    };

    setValue(
      "totalCharges",
      parseFloat(getValues("totalCharges").toString().replace(",", "."))
    );
    setValue(
      "totalSalairesBruts",
      parseFloat(getValues("totalSalairesBruts").toString().replace(",", "."))
    );

    setIsLoading(true);

    if (artisteSelected.id && !isDuplicating) {
      try {
        await versementClient.updateVersementArtiste(artisteSelected.id, data);
        onUpdateContratsCachets({
          type: "updateArtisteDetails",
          payload: {
            artiste: {
              id: artisteSelected.id,
              ...getValues(),
            },
          },
        });
      } catch (error) {
        const localizedError = `creationVersement.contratsCachets.errors.${error.code}`;
        if (error?.code && i18n.exists(localizedError)) {
          toast.error(
            t(localizedError, {
              dateMin: moment(
                error.exception?.additionalDetails?.dateMin
              ).format("DD/MM/YYYY"),
            })
          );
        } else {
          toast.error(t("errors.default"));
        }
      }
    } else {
      try {
        const result = await versementClient.createVersementArtiste(
          versement.id,
          data
        );
        onUpdateContratsCachets({
          type: "addArtiste",
          payload: {
            artiste: result,
          },
        });
      } catch (error) {
        const localizedError = `creationVersement.contratsCachets.errors.${error.code}`;
        if (error?.code && i18n.exists(localizedError)) {
          toast.error(
            t(localizedError, {
              dateMin: moment(
                error.exception?.additionalDetails?.dateMin
              ).format("DD/MM/YYYY"),
            })
          );
        } else {
          toast.error(t("errors.default"));
        }
      }
    }

    setIsLoading(false);
    onClose();
  };

  const deleteDate = (itemClicked: Date) => {
    setValue(
      "datesTravailles",
      datesTravailles.filter((x) => x !== itemClicked)
    );
  };

  const handleDateSelected = (value: Date) => {
    setDateSelected(value);

    if (
      value &&
      !datesTravailles.some((x) => x.toDateString() === value.toDateString())
    ) {
      setValue("datesTravailles", [...datesTravailles, value]);
    }
  };

  const getMinDate = () => {
    if (
      commission &&
      codeCategorie !== CategorieDossier.AideSpecifique &&
      codeCategorie !== CategorieDossier.InteretGeneral
    ) {
      return moment(commission.dateFin).add(1, "d").toDate();
    }
    return undefined;
  };

  const getMaxDate = () => {
    if (versement.type !== TypeVersement.Acompte) {
      return new Date();
    }
    return undefined;
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalLargeCustomStyles}
      className="normal__modal versementTab__artistesModal"
    >
      <div className="modal__header">
        <h3>
          {isDuplicating
            ? "Dupliquer un artiste"
            : artisteSelected.id
            ? "Modifier un artiste"
            : "Ajouter un artiste"}
        </h3>
      </div>
      <div className="modal__content creationDossier">
        <div className="creationDossier__block">
          <div className="creationDossier__row">
            <Input
              name="nom"
              label={`${t("createFolder.contratsCachets.listColumns.nom")}`}
              type="text"
              reference={register(artisteValidator["Nom"])}
              errors={errors}
            />
            <Input
              name="prenom"
              label={`${t("createFolder.contratsCachets.listColumns.prenom")}`}
              type="text"
              reference={register(artisteValidator["Prenom"])}
              errors={errors}
            />
          </div>
          <div className="creationDossier__row">
            <Input
              name="email"
              label={`${t("createFolder.contratsCachets.listColumns.mail")}*`}
              type="text"
              reference={register(artisteValidator["Email"])}
              errors={errors}
            />
            <Input
              name="lieuHabitation"
              label={`${t(
                "createFolder.contratsCachets.listColumns.lieuHabitation"
              )}`}
              type="text"
              reference={register(artisteValidator["LieuHabitation"])}
              errors={errors}
            />
          </div>
          <div className="creationDossier__row">
            {emploisOptions?.length > 0 && (
              <Controller
                name="emploi"
                control={control}
                as={({ onChange, name, value }) => (
                  <InputSelect
                    name={name}
                    label={`${t(
                      "createFolder.contratsCachets.listColumns.emploi"
                    )}*`}
                    classname="inputSelect"
                    options={emploisOptions}
                    errors={errors}
                    onChange={onChange}
                    value={value}
                  />
                )}
                rules={artisteValidator["Emploi"]}
              />
            )}
            {stylesMusicauxOptions.length > 0 && (
              <Controller
                name="styleMusicalId"
                control={control}
                as={({ onChange, name, value }) => (
                  <InputSelect
                    name={name}
                    label={`${t(
                      "createFolder.contratsCachets.listColumns.stylemusical"
                    )}*`}
                    classname="inputSelect"
                    options={stylesMusicauxOptions}
                    errors={errors}
                    onChange={onChange}
                    value={value}
                  />
                )}
                rules={artisteValidator["StyleMusicalId"]}
              />
            )}
          </div>
          <div className="creationDossier__row">
            {instrumentsOptions.length > 0 && emploi === Emploi.Musicien && (
              <Controller
                control={control}
                name="instrumentId"
                as={({ onChange, name, value }) => (
                  <InputSelect<string>
                    name={name}
                    label={`${t(
                      "createFolder.contratsCachets.listColumns.instrument"
                    )}`}
                    classname="inputSelect"
                    options={instrumentsOptions}
                    errors={errors}
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={artisteValidator["InstrumentId"]}
              />
            )}
          </div>
          {viewModel?.artisteTitulaireInfoRequired && (
            <div className="creationDossier__row">
              <Controller
                control={control}
                name="isTitulaire"
                as={({ onChange, name, checked }) => (
                  <InputRadioYesNo
                    name={name}
                    label={`${t(
                      "createFolder.contratsCachets.listColumns.artistTitulaire"
                    )}*`}
                    onChange={onChange}
                    value={checked}
                  />
                )}
                rules={artisteValidator["isTitulaire"]}
              />

              <div className="input">
                <label>
                  {t(
                    "createFolder.contratsCachets.listColumns.artistAdditionnel"
                  )}
                </label>
                <div className="inputContent">
                  {isTitulaire === null
                    ? ""
                    : isTitulaire
                    ? t("common.no")
                    : t("common.yes")}
                </div>
              </div>
            </div>
          )}
          <div className="creationDossier__row">
            <Input
              name="totalSalairesBruts"
              label="Total salaires bruts *"
              type="text"
              reference={register(artisteValidator["TotalSalairesBruts"])}
              errors={errors}
            />

            <Input
              name="totalCharges"
              label="Total charges patronales *"
              type="text"
              reference={register(artisteValidator["TotalCharges"])}
              errors={errors}
            />
          </div>
          <div className="creationDossier__row">
            <div className="input">
              <label>
                {t("createFolder.contratsCachets.listColumns.addDate")}
              </label>
              <Controller
                name="datesTravailles"
                control={control}
                as={() => (
                  <InputCalendar
                    minDate={getMinDate()}
                    maxDate={getMaxDate()}
                    onDateSelected={handleDateSelected}
                    position="left-top"
                    unlock
                    defaultDate={dateSelected}
                  />
                )}
              />
            </div>
          </div>
          <div className="listItems">
            <label>{t("createFolder.lieuxDates.date-added") + "*"}</label>
            <div className="listItemsContent">
              {datesTravailles.map((date, index) => (
                <span key={index}>
                  <span>{moment(date).format("DD/MM/YYYY")}</span>
                  <i
                    className="far fa-trash-alt"
                    onClick={() => deleteDate(date)}
                  ></i>
                  {datesTravailles.length === 1 ||
                  datesTravailles.indexOf(date) === datesTravailles.length - 1
                    ? ""
                    : ", "}
                </span>
              ))}
              {datesTravailles.length === 0 && (
                <div className="errorMessage">
                  {t("createFolder.lieuxDates.no-date")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <FormButton type="button" value="Annuler" onClick={onClose} />
        {isLoading ? (
          <Loader
            type="TailSpin"
            width={35}
            height={35}
            color="#d93943"
          ></Loader>
        ) : (
          <FormButton type="submit" value="Valider" onClick={submit} />
        )}
      </div>
    </Modal>
  );
};

export default ModalAddArtist;
