import React, { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';
import {
    parseAnswerValue,
    shouldDisplayChoice,
    shouldDisplayQuestion,
	ValidationResult
} from "../../../../helpers/questionHelpers";
import SimpleQuestionInput from "../questions/SimpleQuestionInput";
import MultilineTextQuestionInput from "../questions/MultilineTextQuestionInput";
import YesNoQuestionInput from "../questions/YesNoQuestionInput";
import ChoiceListQuestionInput from "../questions/ChoiceListQuestionInput";
import DateQuestionInput from "../questions/DateQuestionInput";
import PeriodQuestionInput from "../questions/PeriodQuestionInput";
import BlockingQuestionInput from "../questions/BlockingQuestionInput";
import FileQuestionInput from "../questions/FileQuestionInput";
import ArrayQuestionInput from "../questions/ArrayQuestionInput";

import { Dictionary } from '../../../../models';
import { QuestionBlockDto, QuestionDto, AnswerType } from '../../../../services/generated/FrontOffice-api';
import clsx from "clsx";

interface RenderBlockContentProps {
	block: QuestionBlockDto;
	answers: Dictionary<any>;
	setAnswers: (value : Dictionary<any>) => void;
	setIsValideDocuments?: (value : any) => void;
	messageValidation: Dictionary<ValidationResult>;
}

export const getQuestions = (block: QuestionBlockDto, answers: Dictionary<any>) => {
	if (!block.questions) {
		return [];
	}

	// Determine which questions should be visible based on current answers
	const isVisible: Dictionary<boolean> = {};
	for (const q of block.questions) {
		isVisible[q.id] = shouldDisplayQuestion(q, answers, block.questions);
	}

	// Remove questions that depend on questions that are not visible (repeat until none are found)
	// This could happen if the answer to a previous question changes. For instance:
	// - question B is visible if the answer to question A is "true"
	// - question C is visible if the answer to question B is "true"
	// - user answers "true" to question A, which makes question B visible
	// - user answers "true" to question B, which makes question C visible
	// - user changes their answer to question A to "false"
	// - question B becomes hidden, so question C should become hidden as well,
	//   even though we have a "true" answer to B
	let removedAny;
	do {
		removedAny = false;
		for (const q of block.questions) {
			if (!isVisible[q.id]) {
				continue;
			}
			// If question depends on a question that is not visible
			if (q.conditions && q.conditions.some(c => !isVisible[c.questionId])) {
				// Hide that question
				isVisible[q.id] = false;
				removedAny = true;
			}
		}
	} while (removedAny);

	return block.questions.filter(q => isVisible[q.id]);
}

export const getAnswer = (question: QuestionDto, answers: Dictionary<any>) => {
	if (answers) {
		const value = !answers.hasOwnProperty(question.id) && question.presetAnswer
			? question.presetAnswer.value
			: answers[question.id]
			
		return parseAnswerValue(value, question.answerType);
	}
}

const RenderBlockContent: FunctionComponent<RenderBlockContentProps> = ({ 
	block,
	answers,
	setAnswers,
	messageValidation,
	setIsValideDocuments
}) => {
	const { i18n } = useTranslation();
	
	const onAnswerChange = (questionId: string, answerValue: any) => {
		const newAnswers = {...answers, [questionId]: answerValue};
		if (newAnswers['nature-enregistrement'] && !newAnswers['nature-enregistrement'].includes('bande-originale'))  {
			delete newAnswers['compositeur'];
			delete newAnswers['duree-estimee-bande'];
			delete newAnswers['musiciens-interpretes'];
		}
		setAnswers(newAnswers);
	}
	const renderQuestion = (question: QuestionDto, block: QuestionBlockDto, currentValue: any) => {
		const questionId = block.id + "-" + question.id;
		const validation = messageValidation[question.id];
		return renderQuestionInput(question, currentValue, questionId, validation, onAnswerChange);
	}

	const renderQuestionInput = (question: QuestionDto, currentValue: any, questionId: string, errorMessage: ValidationResult, onAnswerChange:any) => {
		switch (question.answerType) {
            case AnswerType.Text:
				return (
					<SimpleQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue || ""}
						type="text"
						parse={s => s}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
            case AnswerType.MultilineText:
			case AnswerType.Html:
                return (
					<MultilineTextQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId} 
						question={question}
						currentValue={currentValue}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
            case AnswerType.Date:
                return (
					<DateQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
            case AnswerType.IntegerNumber:
                return (
					<SimpleQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue || 0}
                        type="number"
						parse={s => Number.parseInt(s)}
                        attributes={{step: 1, max:question.answerMaxValue, min: question.answerMinValue}}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
            case AnswerType.DecimalNumber:
                return (
					<SimpleQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
                        type="number"
						parse={s => Number.parseFloat(s)}
                        attributes={{step: 0.01, max:question.answerMaxValue, min: question.answerMinValue}}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
            case AnswerType.SingleChoiceList:
            case AnswerType.MultipleChoiceList:
                return (
					<ChoiceListQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
                        choiceFilter={c => shouldDisplayChoice(c, answers)}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
            case AnswerType.YesNo:
            case AnswerType.YesNoDontKnow:
                return (
					<YesNoQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
            case AnswerType.File:
                return (
					<FileQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
						setIsValideDocuments={setIsValideDocuments}
					/>
				);
            case AnswerType.Url:
                return (
					<SimpleQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
                        type="url"
						parse={s => s}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
			case AnswerType.Link:
				return (
					<div className="row">
						<a href={currentValue} target="_blank">Droits voisins / Simulateur de tarif</a>
					</div>
				);
            case AnswerType.Period:
                return (
					<PeriodQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
						onAnswerChange={onAnswerChange}
						errorMessage={errorMessage}
					/>
				);
			case AnswerType.Array:
				return (
					<ArrayQuestionInput
						title={question.text?.[i18n.language]}
						id={questionId}
						question={question}
						currentValue={currentValue}
						renderQuestionInput={renderQuestionInput}
						onAnswerChange={onAnswerChange}
						validationResult={errorMessage}
					/>
				);
			case AnswerType.Blocking:
                return (
					<BlockingQuestionInput
						id={questionId}
						question={question}
					/>
				);
			default:
                console.warn(`Question type "${question.answerType}" not supported`);
				return null;
        }
	}
	
	return (
		<>{
			block.questions && getQuestions(block, answers).map(question => (
				<div className={clsx("creationDossier__row", question.cssClass)} key={question.id}>
					{renderQuestion(question, block, getAnswer(question, answers))}
				</div>
			))
		}</>
	);
};

export default RenderBlockContent;
