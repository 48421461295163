import React, { useState, useEffect, FunctionComponent } from 'react';

interface InputDocumentInfoDeSaisieProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
}

const InputDocumentInfoDeSaisie: FunctionComponent<InputDocumentInfoDeSaisieProps> = ({
    label,
    value,
    onChange
}) => {
    const [internalValue, setInternalValue] = useState<string>(value ?? "");

    useEffect(() => {
        if(value)
            setInternalValue(value)
        else
			setInternalValue('')
    }, [value, setInternalValue])

    return (
        <div className="piecesObligatoires__item">
            <div className="input">
                <label>{label}</label>
                <input
                    value={internalValue}
                    type="text"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInternalValue(event.target.value);
                        onChange(event.target.value);
                    }}
                />
            </div>
        </div>
    )
}

export default InputDocumentInfoDeSaisie;