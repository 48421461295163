import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactTooltip from "react-tooltip";
import AIDERES from "../../../assets/img/categorieDossier/AIDERES.png";
import ASA from "../../../assets/img/categorieDossier/ASA.png";
import BO from "../../../assets/img/categorieDossier/BO.png";
import DIFFAVIG from "../../../assets/img/categorieDossier/DIFFAVIG.png";
import DIFFLIEU from "../../../assets/img/categorieDossier/DIFFLIEU.png";
import DRAMA from "../../../assets/img/categorieDossier/DRAMA.png";
import ECOLE from "../../../assets/img/categorieDossier/ECOLE.png";
import FEST from "../../../assets/img/categorieDossier/FEST.png";
import FILM from "../../../assets/img/categorieDossier/FILM.png";
import FORTI from "../../../assets/img/categorieDossier/FORTI.png";
import INT from "../../../assets/img/categorieDossier/INT.png";
import INTGEN from "../../../assets/img/categorieDossier/INTGEN.png";
import MUSICAL from "../../../assets/img/categorieDossier/MUSICAL.png";
import PROMO from "../../../assets/img/categorieDossier/PROMO.png";
import SOIRART from "../../../assets/img/categorieDossier/SOIRART.png";
import { useAxios } from "../../../custom-hooks/useAxios";
import { Dictionary } from "../../../models";
import { DiscussionDossierDto, DiscussionStructureDto, MessagerieClient, MessagerieDto } from '../../../services/generated/FrontOffice-api';

interface ListCardsProps {
	messageList: MessagerieDto;
	exchange: boolean;
	setExchange: (value: boolean) => void;

	selectedDiscussion?: DiscussionStructureDto | DiscussionDossierDto;
	setSelectedDiscussion?: (value: DiscussionStructureDto | DiscussionDossierDto) => void;
}

const ListCards: React.FunctionComponent<ListCardsProps> = ({
	messageList,
	selectedDiscussion,
	setSelectedDiscussion
}) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const messagerieClient = new MessagerieClient("", axiosInstance);
	const [discussionsLu, setDiscussionsLu] = useState<string[]>([]);

	useEffect(() => {
		setDiscussionsLu(
			[...messageList.discussionsStructure || [], ...messageList.discussionsDossiers || []]
			.filter(d => !d.aMessageNonLu)
			.map(d => d.id)
		);

		if (selectedDiscussion?.id && !discussionsLu.includes(selectedDiscussion.id)) {
			cardIsActive(selectedDiscussion.id);
		}
	}, [
		messageList.discussionsStructure,
		messageList.discussionsDossiers
	]);
    useEffect(()=> {
		if(selectedDiscussion && selectedDiscussion.id && !discussionsLu.includes(selectedDiscussion.id)) {
			messagerieClient.setDiscussionLu(selectedDiscussion.id);
			setDiscussionsLu([...discussionsLu, selectedDiscussion.id]);
		}
	},[selectedDiscussion])
	const cardIsActive = (id: string) => {
		if(id) {
			const discussion = [...messageList.discussionsStructure, ...messageList.discussionsDossiers].find(d => d.id === id);
			setSelectedDiscussion(discussion);
			if(discussion && !discussionsLu.includes(id)) {
				messagerieClient.setDiscussionLu(id);
				setDiscussionsLu([...discussionsLu, id]);
			}
		}
	}

	// Images des icônes de catégories
	const categorieTypeImgDic: Dictionary<any> = {
		FEST,
		BO,
		DRAMA,
		ECOLE,
		FILM,
		INT,
		MUSICAL,
		PROMO,
		FORTI,
		INTGEN,
		SOIRART,
		ASA,
		DIFFLIEU,
		AIDERES,
		DIFFAVIG
	};

	return (
		<>
			{/** Structures */}
			{messageList.discussionsStructure?.map(d => (
				<div
					className={clsx(
						{
							"active": selectedDiscussion?.id === d.id,
							"nonLu": !discussionsLu.includes(d.id)
						},
						"cardList"
					)}
					key={d.id}
				>
					<div className="cardList__item">
						<p className="text__ellipsis">{t('mailbox.type.structure')}</p>
						<h4 className="text__ellipsis">{d.nom}</h4>
						<p className="text__ellipsis">{t(`objetDiscussion.${d.objet}`)} - {t(`statutDiscussion.${d.statut}`)}</p>
					</div>

					<a data-tip data-for={`cardList_${d.id}`} href="#"
						onClick={() => {
							cardIsActive(d.id);
						}}
					></a>

					<ReactTooltip id={`cardList_${d.id}`} place="bottom" effect="solid" type="dark">
						{t(`objetDiscussion.${d.objet}`)} - {d.nom}
					</ReactTooltip>
				</div>
			))}

			{/** Dossiers */}
			{messageList.discussionsDossiers?.map(d => (
				<div
					className={clsx(
						{
							"active": selectedDiscussion?.id === d.id,
							"nonLu": !discussionsLu.includes(d.id)
						},
						"cardList"
					)}
					key={d.id}
				>
					<img src={categorieTypeImgDic[d.categorieCode]} alt="" />

					<div className="cardList__item">
						<p className="text__ellipsis">N° {d.dossierNumero}</p>
						<h4 className="text__ellipsis">{t('mailbox.type.folder')} {d.nom}</h4>
						<p className="text__ellipsis">{t(`objetDiscussion.${d.objet}`)} - {t(`statutDiscussion.${d.statut}`)}</p>
					</div>

					<a data-tip data-for={`cardList_${d.id}`} href="#"
						onClick={() => {
							cardIsActive(d.id);
						}}
					></a>

					<ReactTooltip id={`cardList_${d.id}`} place="bottom" effect="solid" type="dark">
						{t(`objetDiscussion.${d.objet}`)} - {d.nom}
					</ReactTooltip>
				</div>
			))}
		</>
	)
}

export default ListCards;