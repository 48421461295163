import React, { useEffect } from 'react';
import { FOCurrentUserDto, DiscussionMessageDto } from '../../../services/generated/FrontOffice-api';
import moment from 'moment';
import MessageSeparateur from 'adel-shared/dist/components/basics/MessageSeparateur';
import Message from 'adel-shared/dist/components/basics/Message';
import { scrollToBottom } from 'adel-shared/dist/utils/functions';
import 'moment/locale/fr';

interface MessagesListProps {
	user: FOCurrentUserDto;
	messages: DiscussionMessageDto[];
}

const MessagesList: React.FunctionComponent<MessagesListProps> = ({
	user,
	messages
}) => {
	useEffect(() => {
		if (messages) {
			scrollToBottom("messagerie");
		}
	}, [messages])

	return (
		<div className="messagerie" id="messagerie">
			{messages?.map((m, index) => (
				<div key={m.messageId}>
					{((m.message.createdAt && index === 0) || 
					(index !== 0 && moment(m.message.createdAt).format('YYYY-MM-DD') !== moment(messages[index - 1].message.createdAt).format('YYYY-MM-DD')))
						&& <MessageSeparateur date={moment(m.message.createdAt).locale("fr").format("DD MMMM YYYY")} />
					}
					<Message
						createdAt={m.message.createdAt}
						nom={m.message.auteur.nom}
						prenom={m.message.auteur.prenom}
						fromMe={user && user.id && m.message && m.message.auteur && m.message.auteur.id ? user.id === m.message.auteur.id : false}
						contenu={m.message.contenu}
						piecesJointes={m.message && m.message.piecesJointes ? m.message.piecesJointes : []}
					/>
				</div>
			))}
		</div>
	);
}

export default MessagesList;