import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from 'react-i18next';
import { navigate, RouteComponentProps } from "@reach/router";
import { StepRef } from "../CreationDossier";
import { useAxios } from "../../../custom-hooks/useAxios";
import { DescriptionProjetViewModelDto, DossierClient, DossierDetailsDto, DossierInfosProjetViewModelDto, EtapeCreationDossier } from "../../../services/generated/FrontOffice-api";
import { toast } from "react-toastify";
import { CreationDossierSteps, EtapeCreationPlus } from "../../../context/CreationDossierSteps";
import DetailsDossierContent from '../detailsDossier/DetailsDossierContent';

interface ValidationProps extends RouteComponentProps {
    dossierId: string;
    setCanGoNext: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
    navigateToStep: (targetStep: CreationDossierSteps) => Promise<void>
}

const Validation: ForwardRefExoticComponent<PropsWithoutRef<ValidationProps> & RefAttributes<StepRef>> = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const axiosInstance = useAxios();
    const dossierClient = new DossierClient("", axiosInstance);

    const [dossierDetails, setDossierDetails] = useState<DossierDetailsDto>({});
    const [descrProj, setDescrProj] = useState<DescriptionProjetViewModelDto>();
    const [infosProjet, setInfosProjet] = useState<DossierInfosProjetViewModelDto>();

    useEffect(() => {
        window.scrollTo(0, 0);
        props.setCanGoNext(true);
        (async () => {
            const resultDetails = await dossierClient.getDossierDetails(props.dossierId);
            setDossierDetails(resultDetails);
        })();
        (async () => {
            const resultProjet = await dossierClient.getInfosProjetViewModel(props.dossierId);
            setInfosProjet(resultProjet);
        })();
        (async () => {
            const resulDescr = await dossierClient.getDescriptionProjetViewModel(props.dossierId);
            setDescrProj(resulDescr);
        })();
    }, [])

    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
            props.setIsLoading(true);
            try {
                let stepWithValidationErrors: CreationDossierSteps = await dossierClient.soumettreDossier(props.dossierId) as any; // TODO: fixer le type any
                if (stepWithValidationErrors) {
                    toast.error(t("createFolder.error-validation") + t(`createFolder.menu.${stepWithValidationErrors}`));
                    if (stepWithValidationErrors === EtapeCreationDossier.ValidationStructure) stepWithValidationErrors = EtapeCreationPlus.ValidationStructure2; // L'étape 1 de validation n'a aucune règle de validation
                    await props.navigateToStep(stepWithValidationErrors);
                    props.setIsLoading(false);
                    return false;
                }
                props.setIsLoading(false);
                toast.success(t('createFolder.finalValidation.success'));
                navigate('/Structures');
                return false;
            } catch (error) {
                props.setIsLoading(false);
                if(error.code === "dossierIsNotValid")
                    toast.error(t(`createFolder.recapitulatif.${error.code}`));
                else if (error.exception?.message)
                    toast.error(error.exception.message);
                else
                    toast.error(t("errors.default"));
                return false;
            }
        }
    }));

    return (
        <section className="detailsDossier">
            <h4 className="title--dark">{t("createFolder.menu.recap")}</h4>
            <div className="creationDossier__header">
            </div>
            <DetailsDossierContent
                dossierDetails={dossierDetails}
                descrProj={descrProj}
                infosProjet={infosProjet}
            />
        </section>
    );
});

export default Validation;