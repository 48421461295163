import React, { useEffect, useState, useRef } from 'react';
import Select, { ValueType, ActionMeta, OptionTypeBase, components } from "react-select";
import { AdelOption } from './InputSelect';
import { FilterType } from "../../enums/DossierFilterTypes"
import { useTranslation } from 'react-i18next';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

interface ITooltipPosition {
	left: string;
	top: string;
}

/**
 * Tooltip props interface.
 * @interface
 * @property {Function} position Position of the Tooltip.
 */
interface ITooltipProps {
	visible: boolean;
	statutOptions: AdelOption<string>[];
	categoriesOptions: AdelOption<string>[];
	onSelectFilter: Function;
	onTooltipVisibleChange(visible: boolean): void;
	dates?: any;
	setDateRange?: any;
}

/**
 * TimeSlot => The time slot component.
 * @returns {JSX.Element}
 */
const Tooltip: React.FunctionComponent<ITooltipProps> = ({
	visible,
	statutOptions,
	categoriesOptions,
	onSelectFilter,
	onTooltipVisibleChange,
	dates,
	setDateRange

}: ITooltipProps): JSX.Element => {
	const tooltipRef = useRef<HTMLDivElement>();
	const [isVisible, setIsVisible] = useState<boolean>(visible)
	const { t } = useTranslation();

	/** Calendrier visible ou pas */
	const [showCalendar, setShowCalendar] = useState<boolean>(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleClick, false)

		return () => {
			document.removeEventListener('mousedown', handleClick, false)
		}
	}, []);

	useEffect(() => {
		setIsVisible(visible);
	}, [visible])


	const handleClickOutside = () => {
		onTooltipVisibleChange(false);
	}

	const handleClick = (e: any) => {
		if (tooltipRef.current.contains(e.target) || e.target.classList.contains('rct-item_content')) {
			return;
		}

		handleClickOutside();
	}

	return (
		<div className={isVisible ? 'tooltip visible' : 'tooltip'} ref={tooltipRef}>
			<div className={'header'}>
				<div className={'inputSelects'}>
					<Select
						onChange={(value) => onSelectFilter(value, FilterType.Statut)}
						options={statutOptions}
						className={'inputSelectFilter'}
						label='label'
						name='name'
						isSearchable={false}
						placeholder={t('filters.status')}
					/>

					<Select
						onChange={(value) => onSelectFilter(value, FilterType.Categorie)}
						options={categoriesOptions}
						className={'inputSelectFilter'}
						label='label'
						name='name'
						isSearchable={false}
						placeholder={t('filters.folder-category')}
					/>

					<div className="inputFile__calendar" onClick={() => setShowCalendar(true)}>
						<span>Calendrier</span>

						{showCalendar &&
							<Calendar
								selectRange={true}
								onChange={(dates: Date[]) => {
									setShowCalendar(false);
									onSelectFilter({
										label: 'Du ' + moment(dates[0]).format('DD/MM/YYYY') + ' au ' + moment(dates[1]).format('DD/MM/YYYY'),
										value: dates
									}, FilterType.Date);
									// setDateRange(true);
								}}
								value={dates}
								locale="fr-FR"
							/>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Tooltip;