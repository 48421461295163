import React from "react"
import { ErrorMessage } from "react-hook-form";

interface InputProps {
    autoComplete?: string;
    name: string;
    reference: any;
    label: string,
    placeHolder: string;
    maxLength?: number;
    type: string;
    value?: any;
    onChange?: (value: string) => void;
    errors?: any;
	dataList?: any[];
    disabled?: boolean;
    readonly?: boolean;
}

const Input: React.SFC<InputProps> = ({
    autoComplete,
    label,
    name,
    placeHolder,
    maxLength,
    reference,
    type,
    value,
    errors,
    onChange,
	dataList,
    disabled = false,
    readonly
}) => {
    return (
        <div className="input">
            <label>{label}</label>

            <input
                autoComplete={autoComplete}
                name={name}
                ref={reference}
                type={type}
                placeholder={placeHolder}
                maxLength={maxLength}
				value={value}
				disabled={disabled}
                readOnly={readonly}
                onChange={
                    e => {
                        if (onChange) onChange(e.target.value)
                    }
                }
                list={dataList ? name : ''}    
            />
            {dataList && <datalist id={name}> { dataList.map((data, index) => { return <option key={index} value={data}> </option>}) } </datalist>}
            {errors &&
                <ErrorMessage errors={errors} name={name}>
                    {({ message }) => <p className="input__errorMessage">{message}</p>}
                </ErrorMessage>
            }
        </div>
    )
}

export default Input;