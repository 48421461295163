/**
 * FILTERS CONSTANTS - Les constantes à appliquer à l'Api
 */

const nomFilterKey = "Nom";
const numeroFilterKey = "Numero";
const villeFilterKey = "Ville";

const typeFilterKey = "CategorieId==";
const statutFilterKey = "Statut==";
const dateFilterKey = "Date==";

export {
	nomFilterKey,
	numeroFilterKey,
	villeFilterKey,
	typeFilterKey,
	statutFilterKey,
	dateFilterKey
};