import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import { CategorieDossier } from '../../enums/CategorieDossier';
import { TypePrestation } from '../../models/generated/BackOffice-api';
import Table, { styleTable } from "../basics/Table";
import { formaterDate } from '../../utils/functions';
const Programmation = ({ dates, categorieDossier }) => {
    const [data, setData] = useState([]);
    /** React table config */
    const columns = useMemo(() => {
        return [
            {
                Header: "Table",
                columns: [
                    {
                        Header: "Date",
                        accessor: "DateSpectacle",
                        sortType: (rowA, rowB, id, desc) => {
                            let a = moment(rowA.values[id], 'DD/MM/YYYY').valueOf();
                            let b = moment(rowB.values[id], 'DD/MM/YYYY').valueOf();
                            if (a > b) {
                                return 1;
                            }
                            if (a < b) {
                                return -1;
                            }
                            return 0;
                        }
                    },
                    {
                        Header: "Lieu",
                        accessor: "Lieu",
                        sortType: "basic"
                    },
                    {
                        Header: "Pays",
                        accessor: "Pays",
                        sortType: "basic"
                    },
                    {
                        Header: "Département",
                        accessor: "Departement",
                        sortType: "basic"
                    },
                    {
                        Header: "Description",
                        accessor: "Description",
                        sortType: "basic"
                    },
                    {
                        Header: "Ville",
                        accessor: "Ville",
                        sortType: "basic"
                    },
                    {
                        Header: "Artistes",
                        accessor: "Artistes",
                        sortType: "basic"
                    },
                    {
                        Header: "Id",
                        accessor: "Id"
                    }
                ]
            }
        ];
    }, [categorieDossier]);
    const getArtistesListByDate = (date) => {
        var _a;
        return (_a = date.artistes) === null || _a === void 0 ? void 0 : _a.map(x => (React.createElement(Fragment, { key: x.id }, (x === null || x === void 0 ? void 0 : x.artistePrincipal) ? (React.createElement("span", { className: "artiste-principal" }, x === null || x === void 0 ? void 0 : x.fullName)) : x === null || x === void 0 ? void 0 : x.fullName))).reduce((accumulator, currentVal) => {
            if (accumulator.length) {
                accumulator.push(', ');
            }
            return [accumulator, currentVal];
        }, []);
    };
    /** Get the datas for the table */
    useEffect(() => {
        const data = dates === null || dates === void 0 ? void 0 : dates.map(date => ({
            DateSpectacle: formaterDate(date.date),
            Lieu: date.lieu,
            Departement: date.departement ? date.departement : '',
            Description: date.description,
            Ville: date.ville,
            Pays: date.pays.nom,
            Artistes: getArtistesListByDate(date)
        }));
        setData(data);
    }, [dates]);
    const getHiddenColumns = () => {
        const hiddenColumns = ['Id'];
        const typePrestation = dates[0].typePrestation;
        if (typePrestation !== TypePrestation.Representation &&
            typePrestation !== TypePrestation.Repetition &&
            typePrestation !== TypePrestation.Enregistrement &&
            typePrestation !== TypePrestation.Diffusion &&
            typePrestation !== TypePrestation.Concert) {
            hiddenColumns.push('Lieu');
        }
        if (typePrestation === TypePrestation.Diffusion) {
            hiddenColumns.push('Artistes');
        }
        if ((categorieDossier === null || categorieDossier === void 0 ? void 0 : categorieDossier.code) && categorieDossier.code === CategorieDossier.DeplacementInternational) {
            hiddenColumns.push('Departement');
        }
        return hiddenColumns;
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: getHiddenColumns()
        },
        manualSortBy: true
    }, useSortBy);
    return (React.createElement("div", { className: "detailsTab__content" },
        React.createElement(Table, { className: "table--programmation", data: data, getTableProps: getTableProps, getTableBodyProps: getTableBodyProps, headerGroups: headerGroups, rows: rows, prepareRow: prepareRow, styles: styleTable.onglet })));
};
export default Programmation;
