import React, { FunctionComponent, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";
import clsx from "clsx";
import { AppStateContext } from "../../context/AppContext";

interface FooterProps {
}

const Footer: FunctionComponent<FooterProps> = ({
}) => {
	const { i18n } = useTranslation();
	const [{ pagesVitrine }] = useContext(AppStateContext);

	return (
		<footer>
			<nav>
				<ul>
					{pagesVitrine && pagesVitrine.map(item => (
						<li key={item.id}>
							<Link to={`/PagesVitrines/${item.urlSlug[i18n.language]}`} state={{ info: item }} data-text={item.nom[i18n.language]}>
								{item.nom[i18n.language]}
							</Link>
						</li>
					))}
				</ul>
			</nav>
		</footer>
	)
}

export default Footer;
