import React, { useState, useEffect } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";

import Subtitles from "../../basics/Subtitles";
import ListCards from "./ListCards";
import Structures from '../../../containers/structures/Structure';
import { MessagerieClient, MessagerieDto, FOCurrentUserDto, DiscussionStructureDto, DiscussionDossierDto, StatutDiscussion } from '../../../services/generated/FrontOffice-api';
import MessagesList from './MessagesList';

import { useAxios } from '../../../custom-hooks/useAxios';
import { useUserContext } from '../../../context/context-helpers';
import ReactTooltip from "react-tooltip";
import { scrollToBottom } from 'adel-shared/dist/utils/functions';
import { AuthenticatedRoute } from "../../../containers/AuthenticatedRoute";

import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import { toast } from 'react-toastify';
import FEST from "../../../assets/img/categorieDossier/FEST.png";
import BO from "../../../assets/img/categorieDossier/BO.png";
import DRAMA from "../../../assets/img/categorieDossier/DRAMA.png";
import ECOLE from "../../../assets/img/categorieDossier/ECOLE.png";
import FILM from "../../../assets/img/categorieDossier/FILM.png";
import INT from "../../../assets/img/categorieDossier/INT.png";
import MUSICAL from "../../../assets/img/categorieDossier/MUSICAL.png";
import PROMO from "../../../assets/img/categorieDossier/PROMO.png";
import FORTI from "../../../assets/img/categorieDossier/FORTI.png";
import INTGEN from "../../../assets/img/categorieDossier/INTGEN.png";
import SOIRART from "../../../assets/img/categorieDossier/SOIRART.png";
import DIFFLIEU from "../../../assets/img/categorieDossier/DIFFLIEU.png";
import AIDERES from "../../../assets/img/categorieDossier/AIDERES.png";
import DIFFAVIG from "../../../assets/img/categorieDossier/DIFFAVIG.png";
import ASA from "../../../assets/img/categorieDossier/ASA.png";
import { Dictionary } from "../../../models";
import WysiwygEditor from "adel-shared/dist/components/WysiwygEditor";

interface StructuresMailBoxContentProps extends RouteComponentProps {
}

const StructuresMailBoxContent: React.FunctionComponent<StructuresMailBoxContentProps> = () => {
	const { t } = useTranslation();
	const [sendingMessage, setSendingMessage] = useState(false);

	const axiosInstance = useAxios();

	/** Get current user */
	const [user, setUser] = useState<FOCurrentUserDto>({});
	const context = useUserContext();
	useEffect(() => {
		if (context) {
			setUser(context);
		}
	}, [context]);

	// Get the fils and messages
	const [selectedDiscussion, setSelectedDiscussion] = useState<DiscussionStructureDto | DiscussionDossierDto>({});
	const [discussions, setDiscussions] = useState<MessagerieDto>({});
	const isDiscussionDossier = typeof (selectedDiscussion as DiscussionDossierDto)?.dossierId !== 'undefined';
	const isDiscussionStructure = typeof (selectedDiscussion as DiscussionStructureDto)?.structureId !== 'undefined';

	const [editorContent, setEditorContent] = useState<string>("");

	// Show fils and messages or not - mobile behavior
	const [exchange, setExchange] = useState<boolean>(false);

	const messagerieClient = new MessagerieClient("", axiosInstance);

	const getDiscussions = async () => {
		try {
			const messagerie = await messagerieClient.getMessagerie();
			setDiscussions(messagerie);
			setSelectedDiscussion(messagerie.discussionsStructure[0] || messagerie.discussionsDossiers[0]);
		} catch (error) {
			console.log("error", error); /** TODO: catch error dispatch */
		}
	}

	useEffect(() => {
		getDiscussions();
	}, []);

	const sendMessage = async () => {
		setSendingMessage(true);

		try {
			if(!selectedDiscussion.id) {
				return;
			}

			const result = await messagerieClient.createMessageDiscussion(selectedDiscussion.id, { contenu: editorContent });
			const selectedDisc = selectedDiscussion;
			selectedDisc.messages.unshift(result);
			setSelectedDiscussion(selectedDisc);

			setSendingMessage(false);
			setEditorContent("");
			scrollToBottom("messagerie");

		} catch (error) {
			setSendingMessage(false);
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
	}

	// Images des icônes de catégories
	const categorieTypeImgDic: Dictionary<any> = {
		FEST,
		BO,
		DRAMA,
		ECOLE,
		FILM,
		INT,
		MUSICAL,
		PROMO,
		FORTI,
		INTGEN,
		ASA,
		SOIRART,
		DIFFLIEU,
		AIDERES,
		DIFFAVIG
	};

	return (
		<AuthenticatedRoute>
			<Structures>
				<>
					<div className="mailbox">
						<Link to="/Structures">
							<span className="navigationFil">
								<span className="navigationFil__item">
									{t("navigation.folder")}
								</span>

								<span className="navigationFil__separator">|</span>

								<span className="navigationFil__item">
									{t("navigation.mailbox")}
								</span>
							</span>
						</Link>

						<Subtitles title={t("mailbox.title")} />
						<div className="mailbox__item messagerie__display">
							<section className="messageList">
								{/* <div className="mailboxsearchbar">
									<div>
										<i className="fas fa-search"></i>
										<input placeholder={t("common.search")} />
									</div>
								</div> */}
								<ListCards
									messageList={discussions}
									selectedDiscussion={selectedDiscussion}
									setSelectedDiscussion={setSelectedDiscussion}
									exchange={exchange}
									setExchange={setExchange}
								/>
							</section>

							{!exchange ?
								<section className="mailbox__rightPart">
									<div className="messageHeader">
										<div className="messageHeader__itemTitle">
											<div className="messageHeader__left">
												{isDiscussionDossier && (
													<div className="messageHeader__logo">
														<img src={categorieTypeImgDic[(selectedDiscussion as DiscussionDossierDto).categorieCode]} alt="" />
													</div>
												)}
												<div className="messageHeader__objet">
													<InputReadOnly
														label={isDiscussionDossier ? "Dossier :" : "Structure :"}
														content={selectedDiscussion?.nom || "-"}
													/>
													<div className="messageHeader__itemSubTitle">
														<p>{selectedDiscussion?.objet ? t(`objetDiscussion.${selectedDiscussion?.objet}`) : "-"}</p>
													</div>
												</div>
											</div>
											<InputReadOnly
												label={t('mailbox.interlocuteur')}
												content={selectedDiscussion?.interlocuteur || "-"}
											/>
										</div>
									</div>

									{selectedDiscussion && selectedDiscussion.messages?.length > 0 && (
										<MessagesList
											user={user}
											messages={selectedDiscussion?.messages}
										/>
									)}

									{selectedDiscussion && selectedDiscussion.statut !== StatutDiscussion.Termine && (
										<div className="mailbox__editor">
											<WysiwygEditor
												editorValueContent={editorContent}
												setEditorValueContent={setEditorContent}
											/>
											{sendingMessage ? (
												<Loader
													type="TailSpin"
													width={35}
													height={35}
													color="#d93943">
												</Loader>
											) :	(
												<div className="mailbox__editor--CTA">
													<button onClick={sendMessage}>{t('mailbox.send')}</button>
												</div>
											)}
										</div>
									)}
								</section>
								:
								<section className="mailbox__rightPartMobile">
									<>
										<div className="mailboxMessage">
											<div className="mailboxmessagefolderinfos">
												{
													isDiscussionDossier &&
													<img src={categorieTypeImgDic[(selectedDiscussion as DiscussionDossierDto).categorieCode]} alt="" />
												}

												<div className="mailboxfolderinfos__item">
													<p>{isDiscussionStructure ? t('mailbox.type.structure') : `N° ${(selectedDiscussion as DiscussionDossierDto)?.dossierId}`}</p>
													<h3 data-for="mailboxfolderinfos__itemTitle">{selectedDiscussion?.nom || "-"}</h3>
													<p>{selectedDiscussion?.objet ? t(`objetDiscussion.${selectedDiscussion?.objet}`) : "-"}</p>

													<ReactTooltip id="mailboxfolderinfos__itemTitle" place="bottom" effect="solid" type="dark">
														{selectedDiscussion?.nom || "-"}
													</ReactTooltip>
												</div>
											</div>

											<div className="mailboxMessage__item">
												<h6>
													{isDiscussionDossier ?
														t('mailbox.structure-author-subtitle')
														:
														t('mailbox.folder-author-subtitle')
													}
												</h6>

												{/** <p>AUTHOR</p> TODO: replace Mock et voir avec le Back comment c'est remonté */} 
											</div>
										</div>

										{selectedDiscussion && selectedDiscussion.messages?.length > 0 && (
											<MessagesList
												user={user}
												messages={selectedDiscussion.messages}
											/>
										)}
									</>

									<div className="mailbox__editorMobile">
										{sendingMessage ?
											<Loader
												type="TailSpin"
												width={35}
												height={35}
												color="#d93943">
											</Loader>
											:
											<div className="mailbox__editor--CTA">
												<button
													onClick={() => {
														sendMessage();
														setExchange(!exchange);
													}}
												>
													<i className="far fa-paper-plane"></i>
												</button>
											</div>
										}
									</div>
								</section>
							}
						</div>
					</div>
				</>
			</Structures>
		</AuthenticatedRoute>
	)
};

export default StructuresMailBoxContent;