import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputFileSingle from 'adel-shared/dist/components/basics/InputFileSingle';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import TotalAmountsTable from 'adel-shared/dist/components/basics/TotalAmountsTable';
import { escapeName, formatNumber } from 'adel-shared/dist/utils/functions';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';
import { AppStateContext } from '../../../../../context/AppContext';
import { useAxios } from '../../../../../custom-hooks/useAxios';
import { Dictionary } from '../../../../../models';
import {
	ArtisteLightDto,
	ChampDepense,
	DemandeVersementContratCachetDto,
	TypeDepense,
	TypeVersement,
	UpdateDepenseDto,
	VersementClient,
} from '../../../../../services/generated/FrontOffice-api';
import Textarea from '../../../../basics/Textarea';
import ModalDeleteArtiste from '../../../../dossiers/creationDossier/artistes/ModalDeleteArtiste';
import { ContratsCachetsAction, DocumentDepenses } from '../ContratsCachets';
import DepenseItem from './DepenseItem';
import { FormContextValues, useFieldArray } from 'react-hook-form';
import Loader from 'react-loader-spinner';

interface ArtisteListProps {
	contratsCachets: DemandeVersementContratCachetDto;
	onAddArtist: () => void;
	duplicateArtiste: (id: string) => void;
	editArtiste: (id: string) => void;
	informDeleteArtiste: (id: string) => void;
	depensesSpe: UpdateDepenseDto[];
	autreDepenses: UpdateDepenseDto[];
	depensesDocuments: DocumentDepenses[];
	setDepensesSpecifiques: (value: UpdateDepenseDto[]) => void;
	setDepensesAutres: (value: UpdateDepenseDto[] ) => void;
	setDepensesDocuments: (value: DocumentDepenses[]) => void;
	onUpdateDocuments: (artistesDocumentsFiles: Dictionary<File>) => void;
	artistesDocumentsFiles: Dictionary<File>;
	onUpdateContratsCachets: (action: ContratsCachetsAction) => void;
	commentGeneral: string;
	setCommentGeneral: (value:string) => void;
	isCommentMandatory: boolean;
	form: FormContextValues;
	canGoNext: boolean;
	isLoading: boolean;
	validationRequired: boolean;
	handleSubmit: (validationNotRequired?: boolean) => void
	setValidationRequired: (validationNotRequired?: boolean) => void
}

const ArtisteList: React.FunctionComponent<ArtisteListProps> = ({
	contratsCachets: {
		artistes = [],
		totalMontantPrevisionnel,
		totalRemunerationBrut,
		totalChargePatronales,
		categoryDossierId
	},
	onAddArtist,
	duplicateArtiste,
	editArtiste,
	informDeleteArtiste,
	depensesSpe,
	autreDepenses,
	depensesDocuments,
	setDepensesSpecifiques,
	setDepensesAutres,
	setDepensesDocuments,
	onUpdateDocuments,
	artistesDocumentsFiles,
	onUpdateContratsCachets,
	commentGeneral,
	setCommentGeneral,
	isCommentMandatory,
	form,
	canGoNext,
	isLoading,
	validationRequired,
	setValidationRequired,
	handleSubmit
}) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const versementeClient = useMemo(() => { return new VersementClient("", axiosInstance) }, [axiosInstance]);
	const [modalDeletePageVitrineIsOpen, setModalDeletePageVitrineIsOpen] = useState<boolean>(false)
	const [artisteId, setArtisteId] = useState<string>('');
	const [{currentEditingVersement: versement}] = useContext(AppStateContext);
	const [data, setData] = useState<any[]>(artistes);
	const { register, errors,unregister,control,watch } = form;
	const { fields, remove } = useFieldArray({ control, name: 'artistes' });
	
	const handleFileChange = (file: any, id: string, type: 'bulletin' | 'contrat') => {
		const docsToUpdate = {...artistesDocumentsFiles};

		if(file) {
			docsToUpdate[`${escapeName(file.fileName)}-${id}`] = file.file
		} else {
			const artiste = artistes.find(x => x.id === id);
			const partNameDoc = `${escapeName(artiste?.[type].fileName)}-${id}`;
			delete docsToUpdate[partNameDoc];
		}

		onUpdateDocuments(docsToUpdate);

		onUpdateContratsCachets({
			type: 'updateArtisteDocument',
			payload: {
				artisteId: id,
				artisteDocument: {
					type,
					file: file ? {
						id,
						fileName: file.fileName,
						actionType: 'update',
						uri: file.uri,
					}: {
						id,
						actionType: 'delete',
					}
				}
			}
		});
	};

	useEffect(() => {
		if(artistes.length) {
			setData(artistes.map(x => ({
				...x,
				emploi: t(`emplois.${x.emploi}`),
				dates: x.datesTravailles.map(
					y => moment(y).format('DD/MM/YYYY')
				).join(', '),
				totalCharges: `${formatNumber(x.totalCharges)} €`,
				totalSalairesBruts: `${formatNumber(x.totalSalairesBruts)} €`,
			})));
		}
	}, [artistes]);

	const columns = React.useMemo(() => {
		return [{
			Header: "Table",
			columns: [
				{ accessor: "id", disableSortBy: true },
				{ Header: t('creationVersement.contratsCachets.listColumns.nom'), accessor: "nom", disableSortBy: true },
				{ Header: t('creationVersement.contratsCachets.listColumns.prenom'), accessor: "prenom", disableSortBy: true },
				{ Header: t('creationVersement.contratsCachets.listColumns.emploi'), accessor: "emploi", disableSortBy: true },
				{ Header: t('creationVersement.contratsCachets.listColumns.datesTravail'), accessor: "dates", disableSortBy: true},
				{ Header: t('creationVersement.contratsCachets.listColumns.salaires'), accessor: "totalSalairesBruts", disableSortBy: true},
				{ Header: t('creationVersement.contratsCachets.listColumns.charges'), accessor: "totalCharges", disableSortBy: true},
				{ Header: t('creationVersement.contratsCachets.listColumns.contrats'), accessor: "contrat", disableSortBy: true,
					Cell: (value: any) => (
						<InputFileSingle
							labelButton="Télécharger"
							name={value.cell.row.values.id}
							onChange={(file:any) => handleFileChange(file, value.cell.row.values.id, 'contrat')}
							isRequired={true}
							currentValue={value.cell.row.values.contrat && value.cell.row.values.contrat.fileName ? {
								id: value.cell.row.values.contrat.id,
								fileName: value.cell.row.values.contrat.fileName,
								url: value.cell.row.values.contrat.uri
							}: undefined}
							errorMessage={errors}
							{...(validationRequired && { reference: register({ required: `${t("validation-messages.required-document")}`}) })}
						/>
					)
				},
				{ Header: t('creationVersement.contratsCachets.listColumns.bulletins'), accessor: "bulletin", width: 200, disableSortBy: true,
					Cell: (value: any) => 
					 (
						<InputFileSingle
							labelButton="Télécharger"
							name={value.cell.row.values.id}
							onChange={(file:any) => handleFileChange(file, value.cell.row.values.id, 'bulletin')}
							isRequired={true}
							currentValue={value.cell.row.values.bulletin && value.cell.row.values.bulletin.fileName ? {
								id: value.cell.row.values.bulletin.id,
								fileName: value.cell.row.values.bulletin.fileName,
								url: value.cell.row.values.bulletin.uri
							}: undefined}
							errorMessage={errors}
							{...( { reference: register({ required: `${t("validation-messages.required-document")}`}) })}
						/>
					)
				},
				{
					Header: "", accessor: "Actions", disableSortBy: true,
					Cell: (value: any) => (
						<span className="creationDossier__table-buttons">
							<div className="icon-button" onClick={() => { duplicateArtiste(value.cell.row.values.id) }} title={t("common.copy")}>
								<i className="far fa-copy"></i>
							</div>
							<div className="icon-button" onClick={() => { editArtiste(value.cell.row.values.id) }} title={t("common.edit")}>
								<i className="far fa-edit"></i>
							</div>
							<div className="icon-button" onClick={() => { openDeleteArtiste(value.cell.row.values.id) }} title={t("common.delete")}>
								<i className="far fa-trash-alt"></i>
							</div>
						</span>
					)
				}
			]
		}];
	}, [artistesDocumentsFiles, errors, register, validationRequired]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable<ArtisteLightDto>({
		columns: columns,
		data,
		initialState: {
			hiddenColumns: [
				"id",
				...versement.type !== TypeVersement.Acompte ? ['contrat'] : [],
				...versement.type !== TypeVersement.Complet && versement.type !== TypeVersement.Solde ? ['bulletin'] : [],
			]
		},
	}, useSortBy);

	const openDeleteArtiste = (id: string) => {
	
		console.log(data);

		const index = artistes.findIndex(field => field.id === id);
    if (index !== -1) {
      // Unregister the field for both contrat and bulletin
      const fieldNameBulletin = `${id}`;
      
      console.log(`Unregistering ${fieldNameBulletin}`);
      unregister(fieldNameBulletin);

      // Remove the item
    //   remove(index);
    }
		setArtisteId(id)
		setModalDeletePageVitrineIsOpen(true)
	}

	// Watch all field values
	const watchedValues = watch();

	useEffect(() => {
	  console.log('Registered field values:', watchedValues);
	}, [watchedValues]);

	const deleteArtiste = async() => {
		await versementeClient.deleteVersementArtiste(artisteId);
		unregister(`${artisteId}`);
		setModalDeletePageVitrineIsOpen(false);
		informDeleteArtiste(artisteId);		
	}



	/** DEPENSES */

	const addDepense = () => {
		let array = [...autreDepenses];
		array.push({
			type: TypeDepense.Autre,
			champ: ChampDepense.Autre,
			id: _.uniqueId('autreDepenses_')
		});
		setDepensesAutres(array);
	}

	const deleteDepense = (id:string) => {
		let array = [...autreDepenses];
		array.filter(d => d.id !== id)
		setDepensesAutres(array);
	}

	const handChangeMontant = (value:string, id:string, isDepenseAutre:boolean) => {
		if (isDepenseAutre) {
			let array = [...autreDepenses];
			array.map(
				e => {
					if (e.id === id) e.montantRealise = parseFloat(value);
					return e;
			});
			setDepensesAutres(array);
		}
		else {
			let array = [...depensesSpe]
			array.map(
				e => {
					if (e.id === id) e.montantRealise = parseFloat(value);
					return e;
			});
			setDepensesSpecifiques(array);
		}
	}

	const handChangeMontantName = (value:string, id:string) => {
		let array = [...autreDepenses];
		array.map(
			e => {
				if (e.id === id) e.intitule = value;
				return e;
		});
		setDepensesAutres(array);
	}

	const editFile = (value:DocumentDepenses, idDepense:string, isDelete:boolean, isDepenseAutre:boolean) => {
		let arrayDocs = [...depensesDocuments];
		let partName:string;

		if (isDelete) { // Suppression Document
			arrayDocs.filter(e => e.partName !== value.partName);
			setDepensesDocuments(arrayDocs);

			if (isDepenseAutre) {
				let array = [...autreDepenses];
				array.map(
					e => {
						if (e.id === idDepense) e.document = undefined;
						return e;
				});
				setDepensesAutres(array);
			} else {
				let array = [...depensesSpe];
				array.map(
					e => {
						if (e.id === idDepense) e.document = undefined;
						return e;
				});
				setDepensesSpecifiques(array);
			}
		} else { // Ajout document
			if (isDepenseAutre) {
				partName = `autreDepense${idDepense}`;
				let array = [...autreDepenses];
				array.map(
					e => {
						if (e.id === idDepense) e.document = { partName: partName };
						return e;
				});
				setDepensesAutres(array);

				arrayDocs.push({ data: value.data, partName: partName });
				setDepensesDocuments(arrayDocs);

			} else {
				partName = depensesSpe.find(e => e.id === idDepense).champ;
				let array = [...depensesSpe];
				array.map(
					e => {
						if (e.id === idDepense) e.document = { partName: partName };
						return e;
				});
				setDepensesSpecifiques(array);

				arrayDocs.push({ data: value.data, partName: partName });
				setDepensesDocuments(arrayDocs);
			}
		}
	}

	return <div className="versementsArtistes">
		<div className="creationDossier__header">
			<h3 className="title--dark">{t("creationVersement.contratsCachets.title")}</h3>
		</div>
		<section>
			<div className="versementsArtistes__headerButton">
				<h3>{t("creationVersement.contratsCachets.artistes")}</h3>
				<FormButton
					type="button"
					value={t("createFolder.contratsCachets.add")}
					onClick={onAddArtist}
				/>
			</div>
			<div className="versementsArtistes__warning">
				<i className="fas fa-exclamation-triangle"></i>
				<div>{t("creationVersement.contratsCachets.warning")}</div>
			</div>
			<div className="versementsArtistes__table">
				<Table
					data={data}
					getTableProps={getTableProps}
					getTableBodyProps={getTableBodyProps}
					headerGroups={headerGroups}
					rows={rows}
					prepareRow={prepareRow}
					styles={styleTable.onglet}
				/>
			</div>
		</section>

		<section>
			{depensesSpe?.map(d => (
				<div key={d.id}>
					<h3>{t(`champDepense.${d.champ}`)}</h3>
						<DepenseItem
						isDepenseAutre={false}
						depense={d}
						handChangeMontant={handChangeMontant}
						editFile={editFile}
						hideFileInput={categoryDossierId == 9 && (d.champ === ChampDepense.SalaireArtistes || d.champ === ChampDepense.AutresSalairesChargesSocialesIncluses)}
						/>
				</div>
			))}
			{autreDepenses && <div>
				<h3>{t("creationVersement.contratsCachets.depenses")}</h3>
				{autreDepenses.map(d => (
					<div key={d.id}>
						<DepenseItem
							isDepenseAutre
							depense={d}
							deleteDepense={deleteDepense}
							handChangeMontant={handChangeMontant}
							handChangeMontantName={handChangeMontantName}
							editFile={editFile}
							hideFileInput={false}
						/>
					</div>
				))}
				<div className="creationDossier__addElementButton" onClick={addDepense}>
					<i className="fas fa-plus-circle" />
					<span className="creationDossier__addElementButtonLabel">
						{t('creationVersement.contratsCachets.addDepense')}
					</span>
				</div>
			</div>}
			{(totalMontantPrevisionnel || totalRemunerationBrut || totalChargePatronales) &&
				<TotalAmountsTable
					data={[
						{
							label: t("creationVersement.contratsCachets.totalMontantPrev"),
							value: `${formatNumber(totalMontantPrevisionnel)} €`
						},
						{
							label: t("creationVersement.contratsCachets.totalRemunerations"),
							value: `${formatNumber(totalRemunerationBrut)} €`
						},
						{
							label: t("creationVersement.contratsCachets.totalChargesPatronales"),
							value: `${formatNumber(totalChargePatronales)} €`
						}
					]}
				/>
			}
		</section>
		<section>
			<h3>{t("creationVersement.contratsCachets.commentaires")}</h3>
			<Textarea
				name="commentaireGeneral"
				value={commentGeneral}
				onChange={setCommentGeneral}
				maxLength={1500}
			/>
			{isCommentMandatory &&
				<div className="errorMessage">
					{t("creationVersement.contratsCachets.commentaireObligatoire")}
				</div>
			}
		</section>
		<ModalDeleteArtiste
			isOpen={modalDeletePageVitrineIsOpen}
			onCancel={() => setModalDeletePageVitrineIsOpen(false)}
			onValidate={deleteArtiste}
		/>
		{ (!isLoading? 
		<FormButton
			type="button"
			value={'Enregistrer'}
			disabled={!canGoNext}
			onClick={async() => {
				// await setValidationRequired(false)
				handleSubmit(true)
			}}
		/>
		: <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>)
								}
	</div>;
}

export default ArtisteList;