import React, { useState, useEffect, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef, useImperativeHandle, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import useValidation from '../../../../../custom-hooks/useValidation';

import { EnseignantDto, TypeContrat, StatutEnseignant, DisciplineDto, DossierClient, QualificationEnseignant, DocumentUploadInfoDto, DocumentDto } from '../../../../../services/generated/FrontOffice-api';

import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { EnseignantFormMode } from '../Enseignants';
import { toast } from 'react-toastify';
import { useAxios } from '../../../../../custom-hooks/useAxios';
import { Dictionary } from '../../../../../models';
import { AdelVersementClient } from '../../../../../clients/AdelVersementClient';

export interface EditEnseignantRef {
	validateAndSend: () => Promise<boolean>;
}

interface EditEnseignantProps {
	dossierId: string;
	closeView: () => void;
	setIsFormValid: (value: boolean) => void;
	existingEnseignant: EnseignantDto;
	currentFormMode: EnseignantFormMode | undefined;
}

interface CreateOrUpdateEnseignantModel {
	nom: string;
	prenom: string;
	ville: string;
	discipline: string[];
	instrument?: string;
	qualification: QualificationEnseignant;
	typeContrat: TypeContrat;
	statut: StatutEnseignant;
	remunerationBruteAnnuelle: string;
	montantChargesPatronales: string;
	tarifHoraireBrut: string;
	contratEngagement: DocumentUploadInfoDto;
	otherDiscipline?: string;
}

const CONTRAT_ENGAGEMENT_NAME = "contratEngagement";
const REMUNERATION_DOCUMENT_NAME = "justificatifRemuneration";

const EditEnseignant: ForwardRefExoticComponent<PropsWithoutRef<EditEnseignantProps> & RefAttributes<EditEnseignantRef>> = forwardRef(({
	dossierId,
	closeView,
	setIsFormValid,
	existingEnseignant,
	currentFormMode
}, ref) => {
	const { t, i18n } = useTranslation();

	const axiosInstance = useAxios();
	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
    const adelVersementClient = useMemo(() => { return new AdelVersementClient("", axiosInstance) }, [axiosInstance]);

	const [disciplineOptions, setDisciplineOptions] = useState<AdelOption<string>[]>([]);
	const [instrumentOptions, setInstrumentOptions] = useState<AdelOption<string>[]>([]);
	const [showInstrument, setShowInstrument] = useState<boolean>(false);
	const [showOtherDiscipline, setShowOtherDiscipline] = useState<boolean>(false);

	const [fileContrat, setFileContrat] = useState<File>();
	const [fileRemuneration, setFileRemuneration] = useState<File>();
	const [fileContratWasModified, setFileContratWasModified] = useState<boolean>(false);
	const [fileRemunerationWasModified, setFileRemunerationWasModified] = useState<boolean>(false);

	const {
		register,
		getValues,
		setValue,
		control,
		triggerValidation,
		watch,
		errors
	} = useForm<any>({
		defaultValues: {
			instrument: existingEnseignant?.instrument?.id,
			otherDiscipline: existingEnseignant?.otherDiscipline
		}
	});
	const { getRootValidator } = useValidation();
	const enseignantValidator = getRootValidator("CreateEnseignantDto");

	const contratOptions: AdelOption<TypeContrat>[] = useMemo(() => {
		let allOptions: AdelOption<TypeContrat>[] = [];

		for (let contrat in TypeContrat) {
			if (contrat !== "None") {
				allOptions.push(
					{
						value: TypeContrat[contrat as keyof typeof TypeContrat],
						label: t(`common.contrat.${TypeContrat[contrat as keyof typeof TypeContrat]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const statutOptions: AdelOption<StatutEnseignant>[] = useMemo(() => {
		let allOptions: AdelOption<StatutEnseignant>[] = [];

		for (let statut in StatutEnseignant) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: StatutEnseignant[statut as keyof typeof StatutEnseignant],
						label: t(`common.statut.${StatutEnseignant[statut as keyof typeof StatutEnseignant]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const qualificationOptions: AdelOption<QualificationEnseignant>[] = useMemo(() => {

		let allOptions: AdelOption<QualificationEnseignant>[] = [];

		for (let statut in QualificationEnseignant) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: QualificationEnseignant[statut as keyof typeof QualificationEnseignant],
						label: t(`common.qualification.${QualificationEnseignant[statut as keyof typeof QualificationEnseignant]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	

	/** Disciplines */
	const [disciplines, setDisciplines] = useState<string[]>([]);

	const setDisciplinesValue = useCallback((value:any) => { // value: AdelOption<DisciplineDto>[]
		const result = (value as any[])?.map(o => o.value) ?? [];
		setDisciplines(result);
	}, [setDisciplines]);

	const getOptionForDisciplinesValue = useCallback((value: any): any => {
        return disciplineOptions.find(o => o.value == value.id);
	}, [disciplineOptions]);



	const initializeView = async () => {
		let vm = await dossierClient.getEnseignantsViewModel(dossierId);
		setDisciplineOptions(vm.disciplines.map(discipline => (
			{ label: discipline.text[i18n.language], value: discipline.id }
		)));
		setInstrumentOptions(vm.instruments.map(instrument => (
			{ label: instrument.intitule[i18n.language], value: instrument.id }
		)));
	}

	const initFormValues = (existingEnseignant: EnseignantDto, currentFormMode: EnseignantFormMode) => {	
		Object.entries(existingEnseignant).forEach(([key, value]) => {
			if (currentFormMode === EnseignantFormMode.Duplication && key === "id") return;
			if (currentFormMode === EnseignantFormMode.Duplication && key === "nom") return;
			if (currentFormMode === EnseignantFormMode.Duplication && key === "prenom") return;
			if (currentFormMode === EnseignantFormMode.Duplication && key === "contratEngagement") return;
			if (currentFormMode === EnseignantFormMode.Duplication && key === "justificatifRemuneration") return;
			if (key === "contratEngagement") {
				// Set dummy file to make it looks like file is already set
				setFileContrat({ name: existingEnseignant.contratEngagement.fileName } as File);
			}
			if(key === "justificatifRemuneration") {
				setFileRemuneration({ name: existingEnseignant.justificatifRemuneration.fileName } as File);
			}
			setValue(key, value);
			if (key === "disciplines") {
				let array:string[] = [];
				existingEnseignant.disciplines.map((e: DisciplineDto) => {
					array.push(e.id)
				})
				setDisciplines(array);
				setValue(key, existingEnseignant.disciplines.map(getOptionForDisciplinesValue));
			}
		});

	}



	useEffect(() => {
		if (disciplines.find(d => d === "autre")) {
			setShowOtherDiscipline(true);
		} else {
			setShowOtherDiscipline(false);
		}

		if (disciplines.find(d => d === "instrumentale")) {
			setShowInstrument(true);
		} else {
			setShowInstrument(false);
		}
	}, [disciplines, setShowOtherDiscipline, setShowInstrument])



	useEffect(() => {
		if (setIsFormValid && currentFormMode !== undefined && (currentFormMode as EnseignantFormMode === EnseignantFormMode.Creation as EnseignantFormMode) || existingEnseignant) { // Either this is "Creation Mode", either is it "Duplication or Edition" and a "existingEnseignant" is filled
			setIsFormValid(true);
			(async function () {
				await initializeView();
			})()
		}
	}, [setIsFormValid, currentFormMode])

	useEffect(() => {
		if (currentFormMode !== undefined && (currentFormMode as EnseignantFormMode !== EnseignantFormMode.Creation as EnseignantFormMode) && disciplineOptions.length > 0 && instrumentOptions.length > 0 && existingEnseignant) {
			initFormValues(existingEnseignant, currentFormMode);
		}
	}, [existingEnseignant, currentFormMode, disciplineOptions, instrumentOptions])



	useImperativeHandle(ref, () => ({
		async validateAndSend(): Promise<boolean> {
			let isValid = await triggerValidation();

			if (isValid) {
				try {
					const model = getValues();
					let createOrUpdateEnseignantDto = {
						...model,
						disciplines,
						instrumentId: model.instrument,
						remunerationBruteAnnuelle: parseFloat(model.remunerationBruteAnnuelle),
						montantChargesPatronales: parseFloat(model.montantChargesPatronales),
						tarifHoraireBrut: parseFloat(model.tarifHoraireBrut),
						remunerationPremierTrimestre: parseFloat(model.remunerationPremierTrimestre),
					} as EnseignantDto;

					let contratEngagementFile: Dictionary<File> = { [CONTRAT_ENGAGEMENT_NAME]: fileContrat };
					let remunerationFile: Dictionary<File> = { [REMUNERATION_DOCUMENT_NAME]: fileRemuneration };

					if (currentFormMode === EnseignantFormMode.Edition) {



						await adelVersementClient.updateEnseignantOnDemandeVersement(
							existingEnseignant.id,
							createOrUpdateEnseignantDto, 
							{}
						);
					} else {
						// Creation or Duplication
						await adelVersementClient.createEnseignantOnDemandeVersement(
							dossierId,
							createOrUpdateEnseignantDto,
							{}
						);
					}
					closeView();
					return true;
				} catch (error) {
					if (error.exception?.message)
						toast.error(error.exception.message);
					else
					    console.log('yesss here', error)
						toast.error(t('common.api-error'));
					return false;
				}
			} else {
				toast.error(t('common.validation-error.message'));
				return false;
			}
		}
	}));

	const downloadFile = (document: DocumentDto) => {
		if (document?.uri) {
			window.open(document.uri);
		}
	}

	const handleFileContratChange = (value: React.ChangeEvent<HTMLInputElement>, name:string) => {
		if (value.target.files.length !== 0) {
			if (value.target.files[0].name !== "") {
				const selectedFile = value.target.files[0];
				setFileContrat(selectedFile);
				setFileContratWasModified(true);
				setValue(name, { partName: name } as DocumentUploadInfoDto);
			} else {
				resetFileContrat(name);
			}
		}
	};

	const handleFileRemunerationChange = (value: React.ChangeEvent<HTMLInputElement>, name: string) => {
		if (value.target.files.length !== 0) {
			if (value.target.files[0].name !== "") {
				const selectedFile = value.target.files[0];
				setFileRemuneration(selectedFile);
				setFileRemunerationWasModified(true);
				setValue(name, { partName: name } as DocumentUploadInfoDto);
			} else {
				resetFileRemuneration(name);
			}
		}
	};

	const resetFileContrat = (name: string) => {
		setFileContrat(undefined);
		setValue(name, undefined);
	}

	const resetFileRemuneration = (name: string) => {
		setFileRemuneration(undefined);
		setValue(name, undefined);
	}

	return (
		<div className="creationDossier__editEnseignant versementsEnseignantsEdit">
			<div className="creationDossier__header--alt">
				<div className="navigationFil" onClick={closeView}>
					<span className="navigationFil__item">
						<i className="fas fa-chevron-left"></i>
						{t("createFolder.enseignants.back")}
					</span>
				</div>
				<h2>{t("createFolder.enseignants.add")}</h2>
			</div>

			<section className="creationDossier__item">
				<div className="creationDossier__row">
					<Input
						name="nom"
						label={t('createFolder.form.nom')}
						type="text"
						reference={register(enseignantValidator?.["Nom"])}
						errors={errors}
					/>

					<Input
						name="prenom"
						label={t('createFolder.form.prenom')}
						type="text"
						reference={register(enseignantValidator?.["Prenom"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="ville"
						label={t('createFolder.enseignants.listColumns.ville')}
						type="text"
						reference={register(enseignantValidator?.["Ville"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					{enseignantValidator && <Controller control={control}
						name="disciplines"
						rules={enseignantValidator["Discipline"]}
						as={({ onChange, value, name }) => (
							<InputSelect<string>
								name={name}
								label={t('createFolder.enseignants.listColumns.discipline')}
								classname="inputSelect inputSelect--multi"
								options={disciplineOptions}
								errors={errors}
								isMulti
								onChange={(selectedDiscipline) => {
									onChange(selectedDiscipline);
									setDisciplinesValue(selectedDiscipline);
								}}
								value={value}
								placeholder={t("common.select")}
							/>
						)} 
					/>}

					{showInstrument &&
					<Controller control={control}
						name="instrument"
						rules={enseignantValidator?.["InstrumentId"]}
						as={({ onChange, value, name }) => (
							<InputSelect<string>
								name={name}
								label={t('createFolder.enseignants.listColumns.instrument')}
								classname="inputSelect"
								options={instrumentOptions}
								errors={errors}
								onChange={onChange}
								value={value}
								placeholder={t("common.select")}
							/>
						)} />
					}
				</div>

				{showOtherDiscipline &&
					<div className="creationDossier__row">
						<Input
							name="otherDiscipline"
							label={t('createFolder.enseignants.listColumns.discipline-autre')}
							type="text"
							reference={register}
							errors={errors}
						/>
					</div>
				}
				<div className="creationDossier__row">
					{enseignantValidator && <Controller control={control}
						name="qualification"
						rules={enseignantValidator["Qualification"]}
						as={({ onChange, value, name }) => (
							<InputSelect<QualificationEnseignant>
								name={name}
								label={t('createFolder.enseignants.listColumns.qualification')}
								classname="inputSelect"
								options={qualificationOptions}
								errors={errors}
								onChange={onChange}
								value={value}
								placeholder={t("common.select")}
							/>
						)} />}
					{enseignantValidator && <Controller control={control}
						name="typeContrat"
						rules={enseignantValidator["TypeContrat"]}
						as={({ onChange, value, name }) => (
							<InputSelect<TypeContrat>
								name={name}
								label={t('createFolder.enseignants.listColumns.typeContrat')}
								classname="inputSelect"
								options={contratOptions}
								errors={errors}
								onChange={onChange}
								value={value}
								placeholder={t("common.select")}
							/>
						)} />}
				</div>
				<div className="creationDossier__row">
					{enseignantValidator && <Controller control={control}
						name="statut"
						rules={enseignantValidator?.["Statut"]}
						as={({ onChange, value, name }) => (
							<InputSelect<StatutEnseignant>
								name={name}
								label={t('createFolder.enseignants.listColumns.statut')}
								classname="inputSelect"
								options={statutOptions}
								errors={errors}
								onChange={onChange}
								value={value}
								placeholder={t("common.select")}
							/>
						)} />}

					<Input
						name="remunerationBruteAnnuelle"
						label={t('createFolder.enseignants.listColumns.remunerationBruteAnnuelle')}
						type="number"
						reference={register(enseignantValidator?.["RemunerationBruteAnnuelle"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="montantChargesPatronales"
						label={t('createFolder.enseignants.listColumns.montantChargesPatronales')}
						type="number"
						reference={register(enseignantValidator?.["MontantChargesPatronales"])}
						errors={errors}
					/>

					<Input
						name="tarifHoraireBrut"
						label={t('createFolder.enseignants.listColumns.tarifHoraireBrut')}
						type="number"
						reference={register(enseignantValidator?.["TarifHoraireBrut"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="remunerationPremierTrimestre"
						label={t('creationVersement.enseignants.remunerationPremierTrimestre')}
						type="number"
						reference={register(enseignantValidator?.["RemunerationPremierTrimestre"])}
						errors={errors}
					/>					
				</div>				
			</section>
		</div>
	)
})

export default EditEnseignant;