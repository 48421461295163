import React from "react";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Subtitles from "../../basics/Subtitles";
import EndImg from "../../../assets/img/portail/password_validation.png";
import { useTranslation } from 'react-i18next';

interface SignupEndProps {
    value: string;
}

const SignupEnd: React.FC<SignupEndProps> =  props => {
	const { t } = useTranslation();

    return (
        <div className="signupEnd">
            <Subtitles
                title={t("signup.bienvenue")}
            />

            <Subtitles
                title={t("signup.bienvenue2")}
            />
            
            <div className="signupEnd__img">
                <img src={EndImg} alt=""/>
            </div>

            <div className="signupEnd__content">
                <h3>{t("signup.end.p1")}</h3>
                <p>{t("signup.end.p2")}</p>
                <p><b>{t("signup.end.p3")}</b></p>
                <p>{t("signup.end.p4")}</p>
            </div>

            <FormButton
                type="submit"
                value={props.value}
            />
        </div>
    )
};
export default SignupEnd;