import React, { useState, useEffect, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef, useMemo, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from "@reach/router";
import EditEnseignant, { EditEnseignantRef } from "./enseignants/EditEnseignant";
import EnseignantList from "./enseignants/EnseignantList";
import ModalValidationEnseignants from "./enseignants/ModalValidationEnseignants";
import { StepVersementRef } from '../CreationVersement';
import _ from 'lodash';
import { DossierClient, EnseignantDto, EnseignantValidationErrorDto, VersementClient } from '../../../../services/generated/FrontOffice-api';
import { toast } from 'react-toastify';
import { useAxios } from '../../../../custom-hooks/useAxios';

enum View {
    List = "List",
    Edit = "Edit"
}

export enum EnseignantFormMode {
    Creation,
    Duplication,
    Edition
}

interface EnseignantsProps extends RouteComponentProps {
    dossierId: string;
    setCanGoNext: (value: boolean) => void;
    setEditMode: (value: boolean) => void;
    setIsFormValid: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
}

const Enseignants: ForwardRefExoticComponent<PropsWithoutRef<EnseignantsProps> & RefAttributes<StepVersementRef>> = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const axiosInstance = useAxios();
    const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
    const versementClient = useMemo(() => { return new VersementClient("", axiosInstance) }, [axiosInstance]);

    const [currentView, setCurrentView] = useState<View>(View.List);
    const [currentFormMode, setCurrentFormMode] = useState<EnseignantFormMode>(EnseignantFormMode.Creation)

    const [enseignants, setEnseignants] = useState<EnseignantDto[]>([]);
    const [existingEnseignant, setExistingEnseignant] = useState<EnseignantDto>()
    const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(1000);
	
	const [modalValidationIsOpen, setModalValidationIsOpen] = useState<boolean>(false);
	const [errorsValidation, setErrorsValidation] = useState<EnseignantValidationErrorDto[]>([]);

    useEffect(() => {
        props.setCanGoNext(true);
    }, [])


    const tryGetEnseignants = async (): Promise<EnseignantDto[]> => {
        try {
            const result = await dossierClient.getEnseignants(props.dossierId, page, pageSize)
            setEnseignants(result.items);
            return result.items;
        } catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
            	toast.error(t('common.api-error'));
        }
    }

    useEffect(() => {
        switch (currentView) {
            case View.List:
                props.setEditMode(false);
                (async () => {
                    await tryGetEnseignants();
                })()
                break;
            case View.Edit:
                props.setEditMode(true);
                break;
        }
    }, [currentView]);

    const editEnseignantRef: React.MutableRefObject<EditEnseignantRef> = useRef<EditEnseignantRef>(null);

    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
            props.setIsLoading(true);

            switch (currentView) {
                case View.List:					
						props.setIsLoading(false);
						return true;
                case View.Edit:
                    if (editEnseignantRef?.current) {
                        const result = await editEnseignantRef.current.validateAndSend();
                        props.setIsLoading(false);
                        return result;
                    } else {
                        console.error("editEnseignantRef undefined. Please contact your Dev Team.");
                    }
            }
            throw "currentView unknown. Please contact your Dev Team.";
        }
    }));

    const duplicateEnseignant = async (id: string) => {
        let enseignants = await tryGetEnseignants();   // Workaround car le state `enseignants` est vide durant la première duplication...
        if (enseignants.length <= 0) { toast.error(`Trying to duplicate enseigant id [${id}] but enseignants.length <= 0`); return; }
        let enseignantToDuplicate = enseignants.filter(enseignant => { return enseignant.id === id })[0];
        setExistingEnseignant(enseignantToDuplicate);
        setCurrentFormMode(EnseignantFormMode.Duplication);
        setCurrentView(View.Edit);
    }

    const editEnseignant = async (id: string) => {
        let enseignants = await tryGetEnseignants();   // Workaround car le state `enseignants` est vide durant la première duplication...
        if (enseignants.length <= 0) { toast.error(`Trying to edit enseigant id [${id}] but enseignants.length <= 0`); return; }
        let enseignantToEdit = enseignants.filter(enseignant => { return enseignant.id === id })[0];
        setExistingEnseignant(enseignantToEdit);
        setCurrentFormMode(EnseignantFormMode.Edition);
        setCurrentView(View.Edit);
    }

    const deleteEnseignant = async (enseignantId: string) => {
        props.setIsLoading(true);
        try {
            await versementClient.deleteEnseignantOnDemandeVersement(enseignantId);
        } catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t('common.api-error'));
        }
        await tryGetEnseignants();
        props.setIsLoading(false);
    }

    const renderSwitchView = () => {
        switch (currentView) {
            case View.List:
                return <>
					<EnseignantList
						enseignants={enseignants}
						onAddEnseignant={() => {
							setCurrentView(View.Edit);
							setCurrentFormMode(EnseignantFormMode.Creation);
						}}
						duplicateEnseignant={duplicateEnseignant}
						editEnseignant={editEnseignant}
						deleteEnseignant={deleteEnseignant}
					/>
					<ModalValidationEnseignants
						isOpen={modalValidationIsOpen}
						onCancel={() => setModalValidationIsOpen(false)}
						errorsValidation={errorsValidation}
					/>
				</>
            case View.Edit:
                return <EditEnseignant
                    ref={editEnseignantRef}
                    dossierId={props.dossierId}
                    setIsFormValid={props.setIsFormValid}
                    closeView={() => {
                        setCurrentView(View.List);
                        setCurrentFormMode(EnseignantFormMode.Creation)
                    }}
                    existingEnseignant={existingEnseignant}
                    currentFormMode={currentFormMode}
                />
        }
    }

    return (<>{renderSwitchView()}</>);
})

export default Enseignants;
