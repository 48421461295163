import React from "react";
import { useTranslation } from 'react-i18next';

import PassValidation from "../../../../assets/img/portail/password_validation.png";
import Subtitles from "../../../basics/Subtitles";
import FormButton from 'adel-shared/dist/components/basics/FormButton';

interface PasswordValidationProps {
    setResetPassword?: any;
    setValidPass?: any;
    title: string;
    paragraph: string;
    afterSubmit?: () => void;
}

const PasswordValidation: React.SFC<PasswordValidationProps> = ({
    setResetPassword,
    setValidPass,
    title,
    paragraph,
    afterSubmit
}) => {
    const { t } = useTranslation();

    return (
        <div className="passwordValidated">
            <Subtitles
                title={title}
            />

            <img src={PassValidation} alt="" />

            <p>{paragraph}</p>

            <div className="modal__CTA">
                <FormButton
                    type="button"
                    value={t('password.validation.validate-button')}
                    onClick={() => {
                        setResetPassword(false);
                        setValidPass(false);
                        afterSubmit && afterSubmit();
                    }}
                />
            </div>
        </div>
    )
}

export default PasswordValidation;