import React, {useRef, useState} from "react";
import QuestionInputProps from "./QuestionInputProps";
import Moment from "react-moment";
import Calendar from "react-calendar";
import useOnClickOutside from "use-onclickoutside";
import {useTranslation} from "react-i18next";
import getAsDate from "../../../../helpers/getAsDate";

const DateQuestionInput : React.SFC<QuestionInputProps> = (props) => {

    const { t } = useTranslation();

    const minDate = getAsDate(props.question?.answerMinValue);
    const maxDate = getAsDate(props.question?.answerMaxValue);

    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setShowCalendar(false));

	return <div className="questionField">
		<div  className="questionField__title"><span dangerouslySetInnerHTML={{__html: props.title}} /> {props.question?.isRequired && "*"}</div>
		<div className="inputFile__calendar dateInputGroup" onClick={() => setShowCalendar(true)} ref={ref}>
			<div className="dateInputGroup__item">
				{props.currentValue ? <Moment format="DD/MM/YYYY">{props.currentValue}</Moment> : <span>{t("common.pick-date")}</span>}
				&nbsp;
				<i className="far fa-calendar-alt"/>
			</div>

			{showCalendar && <Calendar
				label={props.title}
				onChange={(date: Date) => {
					props.onAnswerChange(props.question?.id, date);
					setShowCalendar(false);
				}}
				minDate={minDate}
				maxDate={maxDate}
				value={props.currentValue} />}
		</div>
		{props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '' &&
			<p className="input__errorMessage">{props.errorMessage.message}</p>
		}
	</div>
}

export default DateQuestionInput;