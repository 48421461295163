import { Dictionary } from 'adel-shared/dist/models';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from "@reach/router";
import NoFolder from "../../../assets/img/undraw-not-found.png";
import AIDERES from "../../../assets/img/categorieDossier/AIDERES.png";
import ASA from "../../../assets/img/categorieDossier/ASA.png";
import BO from "../../../assets/img/categorieDossier/BO.png";
import DIFFAVIG from "../../../assets/img/categorieDossier/DIFFAVIG.png";
import DIFFLIEU from "../../../assets/img/categorieDossier/DIFFLIEU.png";
import DRAMA from "../../../assets/img/categorieDossier/DRAMA.png";
import ECOLE from "../../../assets/img/categorieDossier/ECOLE.png";
import FEST from "../../../assets/img/categorieDossier/FEST.png";
import FILM from "../../../assets/img/categorieDossier/FILM.png";
import FORTI from "../../../assets/img/categorieDossier/FORTI.png";
import INT from "../../../assets/img/categorieDossier/INT.png";
import INTGEN from "../../../assets/img/categorieDossier/INTGEN.png";
import SOIRART from "../../../assets/img/categorieDossier/SOIRART.png";
import MUSICAL from "../../../assets/img/categorieDossier/MUSICAL.png";
import PROMO from "../../../assets/img/categorieDossier/PROMO.png";
import Subtitles from "../../../components/basics/Subtitles";
import { AuthenticatedRoute } from "../../../containers/AuthenticatedRoute";
import Structures from '../../../containers/structures/Structure';
import { ActionTypeEnum } from "../../../context/ActionType";
import { useAppDispatch, useAppStateContext } from "../../../context/context-helpers";
import { useAxios } from '../../../custom-hooks/useAxios';
import { FOCurrentUserDto, MessageAlerteClient, StatutStructure } from "../../../services/generated/FrontOffice-api";
import StructuresFolderAddButton from "./StructuresFolderAddButton";
import DossiersGrid from './DossiersGrid';
import DossiersTable from './DossiersTable';

interface StructuresContentProps extends RouteComponentProps {
}

const StructuresContent: FunctionComponent<StructuresContentProps> = () => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const [context] = useAppStateContext();
	const dispatch = useAppDispatch();
	const [gridView, setGridView] = useState<boolean>(true);
    const [messageAlerte, setMessageAlerte] = useState<string>('');
    const messageAlerteClient = new MessageAlerteClient("", axiosInstance);

	useEffect(() => {
		(async() => {
            const result = await messageAlerteClient.getMessageAlerte();
            result && setMessageAlerte(result.message);
        })();
	}, []);

	/** Images */
	const categorieTypeImgDic: Dictionary<any> = {
		FEST,
		BO,
		DRAMA,
		ECOLE,
		FILM,
		INT,
		MUSICAL,
		PROMO,
		FORTI,
		INTGEN,
		ASA,
		SOIRART,
		DIFFLIEU,
		AIDERES,
		DIFFAVIG
	}

	const changeView = () => {
		setGridView(!gridView)
	}

	const onCreateDossierButtonClicked = () => {
		dispatch({ type: ActionTypeEnum.START_NEW_DOSSIER_CREATION });
	}

	/** User */
	const [user, setUser] = useState<FOCurrentUserDto>({});
	useEffect(() => {
		if (context.user) {
			setUser(context.user);
		}
	}, [context.user]);


	return (
		<AuthenticatedRoute>
			<Structures>
				{user.statutStructure !== StatutStructure.Partial
					? <div className="folders">
						<div className="folders__title">
							<Subtitles
								title={t('navigation.folder')}
							/>
							<div className="folders__title--actions">
								<StructuresFolderAddButton
									title={t('createFolder.title')}
									colorInverted={false}
									pathLink="/Dossiers/Creation"
									onClick={onCreateDossierButtonClicked}
								/>
								<div className="folders__title--icons">
									<i onClick={changeView} className={gridView ? "fas fa-th icon--active" : "fas fa-th icon--inactive"}></i>
									<i onClick={changeView} className={!gridView ? "fas fa-list icon--active" : "fas fa-list icon--inactive"}></i>
								</div>

							</div>
						</div>
						{messageAlerte?.length !== 0 &&
							<div className="messageWarning">
								<i className="fas fa-exclamation-triangle" />
								{messageAlerte}
							</div>
						}
						{gridView
							? <DossiersGrid categorieTypeImgDic={categorieTypeImgDic} />
							: <DossiersTable categorieTypeImgDic={categorieTypeImgDic} />
						}
					</div>
					: <div className="structure__unauthorized">
						<div className="structure__unauthorizedContent">
							<img src={NoFolder} className="unauthorizedImg" />
						</div>
					</div>
				}
			</Structures>
		</AuthenticatedRoute>
	)
};
export default StructuresContent;