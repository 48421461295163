import React, { forwardRef, useImperativeHandle, useEffect, useState, Fragment } from "react";

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Subtitles from "../../components/basics/Subtitles";
import StepCounter from "../../components/signup/signup-items/StepCounter";

import { ActionTypeEnum } from "../../context/ActionType";
import { useForm } from "react-hook-form";
import { StructureClient, TypeDeDocumentDto } from "../../services/generated/FrontOffice-api";
import { useAxios } from "../../custom-hooks/useAxios";
import { useStepOneContext, useStepSixContext, useAppDispatch } from "../../context/context-helpers";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Dictionary } from "adel-shared/dist/models";
import InputDocumentV2 from "../basics/InputDocuments/InputDocumentV2";
import { DocumentDto } from "adel-shared/dist/models/generated/FrontOffice-api";
import InputSelectDocument from "../basics/InputDocuments/InputSelectDocumentV2";
import { isDateOrDateString } from "../../utils/functions";
import { normalizeDate } from "adel-shared/dist/utils/functions";

interface StepSixProps extends RouteComponentProps {
	ref: any;
	setCanGoNext: (value: boolean) => void;
}

const StepSix: React.FunctionComponent<StepSixProps> = forwardRef(({
	setCanGoNext
}, ref) => {
	const dispatch = useAppDispatch();
	const context = useStepSixContext();
	const axiosInstance = useAxios();
	const { t } = useTranslation();
	const form = useForm();
	const { getValues, setValue, triggerValidation } = form;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const {
		codeAPE,
		nomenclatureAPE,
		formeJuridique
	} = useStepOneContext();

	const [typesDocument, setTypesDocument] = useState<Array<TypeDeDocumentDto>>([]);		// Represent structure documents
	const [datesDic, setDatesDic] = useState<Dictionary<Date>>({});					// Represent dates associated with documents (as an "extraInfo")
	const [filesDic, setFilesDic] = useState<Dictionary<File>>({});					// Represent files uploaded for documents
	const [selectDic, setSelectDic] = useState<Dictionary<TypeDeDocumentDto>>({});	// Represent a selected choix for a document
	const [valuesDic, setValuesDic] = useState<Dictionary<Dictionary<string>>>({});	// Represent string values associated with documents (as an "extraInfo")

	/** API Call to retrieve requiredDocumentTypes */
	useEffect(() => {
		const structureClient = new StructureClient("", axiosInstance);
		setCanGoNext(true);
		setIsLoading(true);

		structureClient
			.getRequiredTypesDeDocument(formeJuridique.id, codeAPE, nomenclatureAPE)
			.then(result => {
				result.map(d => {
					d.code = d.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

					if(d.extraInfos && d.extraInfos.length > 0) {
						d.extraInfos.map(e => {
							e.code = e.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
						})
					}

					if(d.choix && d.choix.length > 0) {
						d.choix.map(f => {
							f.code = f.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

							if (f.extraInfos && f.extraInfos.length > 0) {
								f.extraInfos.map(g => g.code = g.code.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
							}
						})
					}
				});

				setIsLoading(false);
				setTypesDocument(result);
			})
			.catch((error) => {
				dispatch({
					type: ActionTypeEnum.ERROR_OCCURRED,
					payload: { type: error },
				});
				setTypesDocument([]);
				setIsLoading(false);
			});
	}, []);

	/** Step initialization from context (select, dates, values)*/
	useEffect(() => {
		const _selectDic: Dictionary<TypeDeDocumentDto> = {...selectDic};
		const _datesDic: Dictionary<Date> = {...datesDic};
		const _valuesDic: Dictionary<Dictionary<string>> = {...valuesDic};
		const _filesDic: Dictionary<File> = {...filesDic};

		context.files.forEach(file => {
			if (file.extraInfos) {
				for (const extraInfoCode in file.extraInfos) {
					if (isDateOrDateString(file.extraInfos[extraInfoCode])) {
						_datesDic[file.code] = new Date(file.extraInfos[extraInfoCode]);
					} else if (typeof file.extraInfos[extraInfoCode] === "string") {
						if (!_valuesDic[file.code]) {
							_valuesDic[file.code] = {};
						}
						_valuesDic[file.code][extraInfoCode] = file.extraInfos[extraInfoCode] as string; // lookup method in case multiple values with different info.code are available for one document
					} else {
						console.debug("Unhandled value found during initialization", file.extraInfos[extraInfoCode]);
					}
				}
			}
			if (file.typeDeDocumentSelected?.nom) {
				_selectDic[file.code] = file.typeDeDocumentSelected;
			}
			if (file.file && file.file.name !== undefined) {
				_filesDic[file.code] = file.file;
			}
		});
		setSelectDic(_selectDic);
		setDatesDic(_datesDic);
		setValuesDic(_valuesDic);
		setFilesDic(_filesDic);
	}, [context.files])

	/** onChange of the InputSelect */
	const handleSelectTypeDocumentChange = (typeDocument: TypeDeDocumentDto, selectedOption: TypeDeDocumentDto) => {
		dispatch({
			type: ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_TYPE,
			payload: {
				typeDocument,
				value: selectedOption
			}
		});
	}

	/** onChange of the InputCalendar Date */
	const handleDateChange = (typeDocument: TypeDeDocumentDto, extraInfoCode: string, value: Date) => {
		dispatch({
			type: ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_DATE,
			payload: {
				typeDocument,
				extraInfoCode,
				dateValue: value && normalizeDate(value)
			}
		});
	};

	const handleFileChange = (typeDocument: TypeDeDocumentDto) => {
		const fileList = getValues();
		dispatch({
			type: ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_FILE,
			payload: {
				typeDocument,
				fileValue: fileList[typeDocument.code][0]
			}
		});
	};

	// Handle document with many values (extraInfo of type === string).
	/** onChange of the string values (numéro de licence, etc.) */
	const handleExtraInfoChange = (typeDocument: TypeDeDocumentDto, extraInfoCode: string, value: string) => {
		dispatch({
			type: ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_EXTRA_INFO,
			payload: {
				typeDocument,
				extraInfoCode,
				value
			}
		});
	}

	/** reset the field */
	const resetFileAttachment = (fieldName: string) => {
		setValue(fieldName, '');
		const files = {...filesDic};
		files[fieldName] = null;
		setFilesDic(files);
	};

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			try {
				const isValid = await triggerValidation();
				return isValid;
			} catch(error) {
				return false;
			}
		}
	}));

	const [selection, setSelection] = useState<TypeDeDocumentDto>();

	return (
		<div className="piecesObligatoires">
			<StepCounter counter={6} />

			<Subtitles title={t('signup.step-six.title.main')} />

			{!isLoading ? (
				<>
					{typesDocument.map(typeDoc => {
						const existingDocument: DocumentDto = null;

						if (typeDoc.choix?.length) {
							return (
								<Fragment key={typeDoc.id}>
									<InputSelectDocument
										document={existingDocument}
										typeDeDocument={typeDoc}
										form={form}
										selection={selection}
										setSelection={setSelection}
										onReset={resetFileAttachment}
										onTypeDocumentChange={handleSelectTypeDocumentChange}
										onDateChange={handleDateChange}
										onExtraInfoChange={handleExtraInfoChange}
										onFileChange={handleFileChange}
										defaultSelection={selectDic[typeDoc.code]}
										defaultDates={datesDic}
										defaultValues={valuesDic}
									/>
								</Fragment>
							)
						}

						return (
							<Fragment key={typeDoc.id}>
								<InputDocumentV2
									selection={selection}
									document={existingDocument}
									typeDeDocument={typeDoc}
									form={form}
									onReset={() => resetFileAttachment}
									onDateChange={(extraInfoCode, date) => handleDateChange(typeDoc, extraInfoCode, date)}
									onFileChange={handleFileChange}
									defaultDate={datesDic[typeDoc.code]}
									defaultFile={filesDic[typeDoc.code]}
									defaultValues={valuesDic[typeDoc.code]}
								/>
							</Fragment>
						)
					})}
				</>
			) : (
				<div className="piecesObligatoires__loader">
					<Loader
						type="TailSpin"
						width={35}
						height={35}
						color="#d93943"
					/>
				</div>
			)}
		</div>
	);
});

export default StepSix;
