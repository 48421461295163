import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'adel-shared/dist/components/basics/Input';
import { FormContextValues, ValidationOptions } from 'react-hook-form';
import { CreateArtisteDto, Emploi, Groupe, GroupeCodeValues, LieuDateDto, RemunerationDto, TypePrestation } from '../../../../services/generated/FrontOffice-api';
import { Dictionary } from 'lodash';
import { CategorieDossier } from '../../../../enums/Dossiers';
import { DatesSelectOption, SalairesCusto } from './EditArtiste';
import { StyleMusical } from '../../../../enums/StyleMusical';


interface MontantBrutInputProps {
	form: FormContextValues<CreateArtisteDto>;
	label: string;
	name: string;
	step: number;
	value: number;
	index: number;
	handleChangeObject: (value: string, index: number, propertyName: string, selectedAll?:boolean) => void;
	groupesEmploi?: any
	estSalaire: boolean;
	remuneration: RemunerationDto[];
	emploi: Emploi;
	styleMusical: string; // styleMusical ID
	typePrestation?: keyof typeof TypePrestation;
	salaire?: SalairesCusto;
	datesSelectOptions?: DatesSelectOption[];
	prestation?: string
}

const MontantBrutInput: React.FunctionComponent<MontantBrutInputProps> = ({
	name,
	step,
	form,
	value,
	label,
	handleChangeObject,
	index,
	estSalaire,
	remuneration,
	typePrestation,
	groupesEmploi,
	emploi,
	styleMusical,
	salaire,
	datesSelectOptions,
	prestation
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		(async () => {
			await form.triggerValidation(name)
		})()
	}, [emploi, styleMusical, salaire, datesSelectOptions, groupesEmploi]);
	const montantMinValidator = (remuneration:RemunerationDto[]):Dictionary<ValidationOptions> => {
		if(remuneration?.length > 0) {
			styleMusical = styleMusical ?? StyleMusical.pasDeMusiqueId;
			var musiciens = groupesEmploi.filter((gr: any) => gr.groupe.code === GroupeCodeValues.Musiciens).map((gr: any) => gr.type.charAt(0).toLowerCase() + gr.type.slice(1));

			return {
				required: t("validation-messages.required"),
				validate: {validate: (value) => {
					const item = remuneration.find(r => {
						const basicValidator = r.montantMinimum > value;
						const emploiValidator = (r.emploi === Groupe.Musiciens && musiciens.includes(emploi)) || (r.emploi === Groupe.Autre && !musiciens.includes(emploi));
						const estSalaireValidator = r.estSalaire === estSalaire;

						const salaireTypePrestaValidator = salaire?.lieuDateByPresta?.find(e => e.typePresta === r.typePrestation)?.dates.length > 0
													? salaire.lieuDateByPresta.find(e => e.typePresta === r.typePrestation).typePresta
													: null;

						const typePrestaValidator = salaire 
							? salaireTypePrestaValidator
							: r.typePrestation === TypePrestation[typePrestation as keyof typeof TypePrestation];

						if(r.styleMusical && !styleMusical)
							return false;

						switch(r.categorieDossier.code) {
							case CategorieDossier.AidePromotionImage:
								if(basicValidator && emploiValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							// Qu'est-ce que Diffusion au festival d’Avignon (Spectacle musical) ?
							case CategorieDossier.DiffusionAvignon: // Diffusion au festival d’Avignon (Autre)
								if(basicValidator && estSalaireValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.BODramatiqueChoregraphique:
								if(basicValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.SpectacleDramatiqueChoregraphiqueCirque:
								if(basicValidator && typePrestaValidator && emploiValidator && estSalaireValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.DeplacementInternational:
								if(basicValidator && emploiValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.Festival:
								if(basicValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.Fortissimo:
								if(basicValidator && emploiValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.DiffusionLieu:
								if(basicValidator && emploiValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.MusiqueFilm:
								if(basicValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.AideResidences:
								if(basicValidator && emploiValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.SoireeArtistes:
								if(basicValidator && emploiValidator && typePrestaValidator)
									return r.montantMinimum
								break;

							case CategorieDossier.SpectacleMusical:
								if(basicValidator && typePrestaValidator)
									return r.montantMinimum
								break;
						}
					});

					if(item) {
						const result:string = t("validation-messages.min-montant", {number:item.montantMinimum});
						return result;
					}					
				}}
			};
		} else {
			return { required: t("validation-messages.required") };
		}
	}


	return <Input name={name}
		label={label}
		placeHolder="0€"
		type="number"
		step={step}
		min={0}
		reference={remuneration && form.register(montantMinValidator(remuneration))}
		errors={form.errors}
		value={value}
		onBlur={async () => {
			await form.triggerValidation(name);
		}}
		onChange={(value: string) => {
			handleChangeObject(value, index, 'montantBrut');
		}}
	/>

}

export default MontantBrutInput;