import React from 'react';
import InputReadOnly from '../InputReadOnly';
import { TypePoste } from '../../models/generated/BackOffice-api';
import { formatNumber, formatPhone } from '../../utils/functions';
import { useTranslation } from 'react-i18next';
const Structure = ({ 
// Ne passer que structure idéalement
details: { structure = {} } }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const { t, i18n } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: 'structure_info' }, "Information Structure"),
                React.createElement("div", { className: 'structure_separator' }),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Raison sociale", content: structure.raisonSociale || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Statut juridique", content: ((_a = structure.formeJuridique) === null || _a === void 0 ? void 0 : _a.nom) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Code Structure", content: structure.codeStructure || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Code APE", content: structure.codeAPE || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nomenclature APE", content: structure.nomenclatureAPE || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Civilit\u00E9 du repr\u00E9sentant l\u00E9gal de la structure", content: ((_b = structure.representantLegal) === null || _b === void 0 ? void 0 : _b.civilite) ? t(`common.civilite.${structure.representantLegal.civilite}`) : '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: 'structure_info' }, "Repr\u00E9sentant l\u00E9gal"),
                React.createElement("div", { className: 'structure_separator' }),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nom", content: ((_c = structure.representantLegal) === null || _c === void 0 ? void 0 : _c.nom) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Pr\u00E9nom", content: ((_d = structure.representantLegal) === null || _d === void 0 ? void 0 : _d.prenom) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Poste", content: ((_e = structure.representantLegal) === null || _e === void 0 ? void 0 : _e.poste) && ((_f = structure.representantLegal) === null || _f === void 0 ? void 0 : _f.poste) !== TypePoste.None ? t(`common.typePoste.${structure.representantLegal.poste}`) : '-' }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: 'structure_info' }, "Coordonn\u00E9es"),
                React.createElement("div", { className: 'structure_separator' }),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Email", content: ((_g = structure.representantLegal) === null || _g === void 0 ? void 0 : _g.email) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "T\u00E9l\u00E9phone fixe", content: ((_h = structure.representantLegal) === null || _h === void 0 ? void 0 : _h.telephoneFixe) ? formatPhone(structure.representantLegal.telephoneFixe) : '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "T\u00E9l\u00E9phone portable", content: ((_j = structure.representantLegal) === null || _j === void 0 ? void 0 : _j.telephonePortable) ? formatPhone(structure.representantLegal.telephonePortable) : '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Adresse", content: ((_k = structure.adresse) === null || _k === void 0 ? void 0 : _k.line1) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Compl\u00E9ment d'adresse", content: ((_l = structure.adresse) === null || _l === void 0 ? void 0 : _l.line2) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Code postal et ville", content: (((_m = structure.adresse) === null || _m === void 0 ? void 0 : _m.codePostal) && ((_o = structure.adresse) === null || _o === void 0 ? void 0 : _o.ville)) ?
                            `${formatNumber(parseInt(structure.adresse.codePostal))} ${structure.adresse.ville}`
                            : '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Adresse de correspondance", content: ((_p = structure.adresseCorrespondance) === null || _p === void 0 ? void 0 : _p.line1) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Compl\u00E9ment d'adresse de correspondance", content: ((_q = structure.adresseCorrespondance) === null || _q === void 0 ? void 0 : _q.line2) || '-' })),
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Code postal et ville de correspondance", content: (((_r = structure.adresseCorrespondance) === null || _r === void 0 ? void 0 : _r.codePostal) && ((_s = structure.adresseCorrespondance) === null || _s === void 0 ? void 0 : _s.ville)) ?
                            `${formatNumber(parseInt(structure.adresseCorrespondance.codePostal))} ${structure.adresseCorrespondance.ville}`
                            : '-' })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__content" },
                React.createElement(InputReadOnly, { label: "Informations", content: structure.informations || '-' })))));
};
export default Structure;
