import axios, { AxiosInstance } from 'axios';
import { ActionType, ActionTypeEnum } from '../context/ActionType';
import { useAppDispatch } from '../context/context-helpers';
import { useTranslation } from 'react-i18next';
import { ProblemDetails, ProblemDetailsCode } from '../services/generated/FrontOffice-api';
import { useEffect } from 'react';

var axiosInstance: AxiosInstance | null = null;

export const useAxios = () => {
	const { t } = useTranslation();
	const dispatch: React.Dispatch<ActionType> = useAppDispatch();
	axiosInstance = axiosInstance ?? axios.create();

	useEffect(() => {
		if (axiosInstance && ((axiosInstance.interceptors.response as any).handlers as any[]).length < 1) {
			axiosInstance.interceptors.response.use(undefined, (error) => {
				const errorResponse: ProblemDetails = error.response.data;

				if (errorResponse.status === 401) {
					dispatch({ type: ActionTypeEnum.UNSET_USER });
					dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.accessDenied') } });
					return Promise.reject(errorResponse);
				}

				const errorResponseType: string = error.response.data.errors && error.response.data.errors.length !== 0 ? Object.keys(error.response.data.errors)[0] : '';
				var additionalDetails = <any>errorResponse.additionalDetails;
				switch (errorResponse.code) {
					case ProblemDetailsCode.InvalidParameters:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.invalidParameters', { name: errorResponseType }) } });
						break;
					case ProblemDetailsCode.PreconditionFailed:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.preconditionFailed') } });
						break;
					case ProblemDetailsCode.AccessDenied:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.accessDenied') } });
						break;
					case ProblemDetailsCode.Forbidden:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.forbidden') } });
						break;
					case ProblemDetailsCode.EntityNotFound:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.entityNotFound') } });
						break;
					case ProblemDetailsCode.EmailingServiceUnavailable:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.emailingServiceUnavailable') } });
						break;
					case ProblemDetailsCode.SiretNotFound:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.siretNotFound') } });
						break;
					case ProblemDetailsCode.MinimumRemuneration:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.minimumRemuneration', { remuneration: additionalDetails.montantMinimum }) + t(`typePrestation.${additionalDetails.typePrestation}`)}})
						break;
					case ProblemDetailsCode.MontantAideMustBeInferior:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.montantAideMustBeInferior', { montantAide: additionalDetails.montant})}})
						break;
					case ProblemDetailsCode.Unknown:
					default:
						dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: t('errors.default') } });
						break;
				}
				return Promise.reject(errorResponse);
			});
		}

	}, [dispatch, t]) // To be called only once

	return axiosInstance;
}
