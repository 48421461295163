import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import WhiteLogo from "../components/logo/WhiteLogo";
import Subtitles from "../components/basics/Subtitles"
import Signup from "../components/basics/Signup";
import Footer from "../components/basics/Footer";
import Lang from "../components/basics/Lang";
import Connection from "../components/structures/portail/Connection";
import ResetPassword from "../components/structures/portail/mdp/ResetPassword";
import { RouteComponentProps, Router } from "@reach/router";
import SignupStep from "../context/SignupSteps";
import StepCreatePassword from "../components/signup/StepCreatePassword";
import StepResetPassword from "../components/signup/StepResetPassword";

const Portal = (props: RouteComponentProps) => {

	const [menuCheckbox, setMenuCheckbox] = useState(true);
	const [resetPassword, setResetPassword] = useState(false);
	const [validPass, setValidPass] = useState(true);
	const [showPass, setShowPass] = useState(false);
	const { t } = useTranslation();
	return (
		<div id="portal" className="login">
			<div className="modal">
				<section className="modal__item modal__leftPart">

					<div className="background__container">
						<div className="background__image"></div>
					</div>

					<div className="modal__content">
						<WhiteLogo />

						<Subtitles
							title={t('portal.subtitle')}
						/>

						<p>{t('portal.description')}</p>

						<div className="modal__footer">
							<Signup
								title={t('portal.signup.title')}
								linkLabel={t('portal.signup.linkLabel')}
							/>

							<Footer />
						</div>
					</div>

				</section>

				<section className="modal__item modal__rightPart">
					<Lang />
					<Router>
						{!resetPassword ? (
							<Connection
								setResetPassword={setResetPassword}
								setShowPass={setShowPass}
								showPass={showPass}
								path="/*"
							/>
						) : (
							<ResetPassword
								validPass={validPass}
								setValidPass={setValidPass}
								setResetPassword={setResetPassword}
								setShowPass={setShowPass}
								showPass={showPass}
								path="/*"
							/>
						)}
						<StepCreatePassword path={SignupStep.CreatePassword} />
						<StepResetPassword path={SignupStep.ResetPassword} />
					</Router>
				</section>
			</div>
		</div>
	);
}

export default Portal;