import React, { useState } from 'react';
const CollapsibleSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };
    return (React.createElement("div", { className: "collapsibleSection" },
        React.createElement("button", { className: "collapsibleSection__button", onClick: toggleCollapse },
            React.createElement("h3", { className: "collapsibleSection__title" },
                React.createElement("span", null, title),
                React.createElement("i", { className: `fas ${isOpen ? "fa-chevron-down" : "fa-chevron-up"}` }))),
        React.createElement("div", { className: `collapsibleSection__content ${isOpen && "collapsibleSection__content--show"}` }, React.cloneElement(children))));
};
export default CollapsibleSection;
