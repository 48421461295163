import React, { useCallback, useContext, ChangeEvent, useEffect } from "react"
import { debounce } from "lodash";
import { DossierClient } from "../../../services/generated/FrontOffice-api";
import { nomFilterKey, numeroFilterKey, villeFilterKey } from "../../../constants/filters.constants";
import { AppStateContext } from "../../../context/AppContext";
import { ActionTypeEnum } from "../../../context/ActionType";

const SearchBar = () => {
	const [,dispatch] = useContext(AppStateContext);

	const dossierClient = new DossierClient();

	const searchDebounced = debounce(value => searchDossier(value), 1000)

	const searchDossier = (input: string) => {
		if (!!input || input === "") {
			try {
				const filters = `(${nomFilterKey}|${numeroFilterKey}|${villeFilterKey})@=${input}`;

				dossierClient.getDossiers(filters, '', 1, 8, false).then(response => dispatch({
					type: ActionTypeEnum.SEARCH_DOSSIERS_SUCCESS,
					payload: {
						dossiers: response
					}
				}));
			}
			catch (error) {
				console.log(error);
			}
		}
	}

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		searchDebounced(e.target.value);
	}, [searchDebounced]);

	return (
		<div className="searchBar">
			<div>
				<i className="fas fa-search"></i>

				<input placeholder="Rechercher un dossier" onChange={handleChange} />
			</div>
		</div>
	)
}

export default SearchBar;