import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, useMemo } from "react";
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { DossierClient, ArtisteLightDto, SalarieDto, ArtisteLightDtoSortedFilteredPage, ArtisteClient, ArtisteDto, ArtistesValidationErrorDto } from '../../../services/generated/FrontOffice-api';
import { useAxios } from '../../../custom-hooks/useAxios';
import Salarie, { EditSalarieRef } from './salarie/Salarie';
import Artistes, { EditArtistesRef } from './salarie/Artistes';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { useTable, useSortBy } from 'react-table';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ModalDeleteArtiste from './artistes/ModalDeleteArtiste';
import ModalValidationArtiste from './artistes/ModalValidationArtiste';
import Loader from 'react-loader-spinner';
import useValidation from '../../../custom-hooks/useValidation';

enum View {
	List = "List",
	Salarie = "Salarie",
	Artiste = "Artiste"
}

export enum SalarieFormMode {
	IS_CREATION = 1,
	IS_DUPLICATE = 2,
	IS_EDIT = 3
}

interface SalarieAristesProps extends RouteComponentProps {
	ref: any,
	setCanGoNext: (value: boolean) => void;
	dossierId?: string;
	isLoading?: boolean;
	setIsLoading: (value: boolean) => void;
	setEditMode: (value: boolean) => void;
	setIsFormValid: (value: boolean) => void;
}

const SalarieAristes: React.FunctionComponent<SalarieAristesProps> = forwardRef((props, ref) => {
	const { t } = useTranslation(); const axiosInstance = useAxios();
	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
	const artisteClient = useMemo(() => { return new ArtisteClient("", axiosInstance) }, [axiosInstance]);

	const salarieRef: React.MutableRefObject<EditSalarieRef> = useRef<EditSalarieRef>(null);
	const artistesRef: React.MutableRefObject<EditArtistesRef> = useRef<EditArtistesRef>(null);

	const [currentView, setCurrentView] = useState<View>(View.List);
	const [currentFormMode, setCurrentFormMode] = useState<SalarieFormMode>(SalarieFormMode.IS_CREATION);
	const [salarie, setSalarie] = useState<SalarieDto>({});
	const [artistes, setArtistes] = useState<ArtisteLightDto[]>([]);
	const [artisteSelected, setArtisteSelected] = useState<ArtisteDto>();
	const [salarieSelected, setSalarieSelected] = useState<SalarieDto>();

	const [showDeleteArtiste, setShowDeleteArtiste] = useState<boolean>(false);
	const [artisteDeleteId, setArtisteDeleteId] = useState<string>();
	const [errorsValidation, setErrorsValidation] = useState<ArtistesValidationErrorDto[]>([]);
	const [openValidation, setOpenValidation] = useState<boolean>(false);

	const [isArtistLoading, setIsArtistLoading] = useState<boolean>(false);

	const { getRootValidator } = useValidation();
	const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");
	const artisteValidator = getRootValidator("CreateArtisteDto");
	const salarieValidator = getRootValidator("CreateSalarieDto");

	/** Salarié */
	useEffect(() => {
		if (!props.dossierId) return;
		props.setCanGoNext(true);
	}, [props.dossierId]);

	const fetchSalarie = async () => {
		const result = await dossierClient.getSalarie(props.dossierId);
		setSalarie(result);
	}

	/** Artistes */
	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(1000);
	const [disabledPagination, setDisabledPagination] = useState<boolean>(false);

	// UseMemo to save the debounce function in memory when component is re-rendered
	const debounceFetchArtists = useMemo(() => _.debounce((dossierId: string, filters: string, sort: string, page: number, pageSize: number, disabledPagination: boolean) => {
		setIsArtistLoading(true);
		dossierClient.getArtistes(dossierId, filters, sort, page, pageSize, disabledPagination).then((result: ArtisteLightDtoSortedFilteredPage) => {
			setArtistes(result.items);
			setIsArtistLoading(false);
		});
	}, 1000), []);

	useEffect(() => {
		debounceFetchArtists(props.dossierId, filters, sort, page, pageSize, disabledPagination);
	}, [filters, sort, page, pageSize, disabledPagination]);



	/** Pour le bouton Valider de CreationDossier */
	useEffect(() => {
		switch (currentView) {
			case View.List:
				props.setEditMode(false);
				debounceFetchArtists(props.dossierId, filters, sort, page, pageSize, disabledPagination);
				(async function () {await fetchSalarie()})();
				break;
			case View.Salarie:
				props.setEditMode(true);
				break;
			case View.Artiste:
				props.setEditMode(true);
				break;
		}
	}, [currentView]);



	/** Actions sur les artistes */
	const duplicateArtiste = async (id:string) => {
		props.setIsLoading(true);
		try {
			let result = await artisteClient.getArtiste(id);
			setArtisteSelected(result);
			setCurrentFormMode(SalarieFormMode.IS_DUPLICATE);
			setCurrentView(View.Artiste);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
		props.setIsLoading(false);
	}

	const editArtiste = async (id:string) => {
		props.setIsLoading(true);
		try {
			let result = await artisteClient.getArtiste(id);
			setArtisteSelected(result);
			setCurrentFormMode(SalarieFormMode.IS_EDIT);
			setCurrentView(View.Artiste);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
		props.setIsLoading(false);
	}

	const openDeleteArtiste = (id:string) => {
		setArtisteDeleteId(id);
		setShowDeleteArtiste(true);
	}

	const deleteArtiste = async () => {
		setShowDeleteArtiste(false);
		props.setIsLoading(true);
		try {
			await artisteClient.deleteArtiste(artisteDeleteId);
			await debounceFetchArtists(props.dossierId, filters, sort, page, pageSize, disabledPagination);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
		setArtisteDeleteId(undefined);
		props.setIsLoading(false);
	}



	/** Salarie actions */
	const editSalarie = (salarie:SalarieDto) => {
		setSalarieSelected(salarie);
		setCurrentFormMode(SalarieFormMode.IS_EDIT);
		setCurrentView(View.Salarie);
	}

	const deleteSalarie = async (id:string) => {
		props.setIsLoading(true);
		try {
			await dossierClient.deleteSalarie(id);
			await fetchSalarie();
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
		props.setIsLoading(false);
	}



	/** Table setup */
	const columns = React.useMemo(() => { // VFA : Je n'arrive pas à trouver le type exact de cet objet... Je laisse en any T_T
	return [{
		Header: "Table",
		columns: [
			{ Header: "Id", accessor: "id", disableSortBy: true },
			{ Header: t('createFolder.contratsCachets.listColumns.nom'), accessor: "nom", disableSortBy: true },
			{ Header: t('createFolder.contratsCachets.listColumns.prenom'), accessor: "prenom", disableSortBy: true },
			{ Header: t('createFolder.contratsCachets.listColumns.emploi'), accessor: "emploi", disableSortBy: true },
			{
				Header: "", accessor: "Actions", disableSortBy: true,
				Cell: (value: any) => (
					<span className="creationDossier__table-buttons">
						<div className="icon-button" onClick={() => { duplicateArtiste(value.cell.row.values.id) }}><i className="far fa-copy"></i></div>
						<div className="icon-button" onClick={() => { editArtiste(value.cell.row.values.id) }}><i className="far fa-edit"></i></div>
						<div className="icon-button" onClick={() => { openDeleteArtiste(value.cell.row.values.id) }}><i className="far fa-trash-alt"></i></div>
					</span>
				)}
			]
		}];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<ArtisteLightDto>({
		columns: columns,
		data: artistes,
		initialState: { hiddenColumns: ["id"] },
	}, useSortBy);
	


	/** View List mode */
	const listView = () => {
		return (
			<div className="creationDossier__salarieAristes">
				<div className="creationDossier__header">
					<h3 className="title--dark">{t("createFolder.menu.salarieArtistes")}</h3>
				</div>
				<section>
					<h4 className="title--red">{t("createFolder.salarieArtistes.salarie.title")}</h4>
					{
						salarie && Object.keys(salarie).length > 0
						? <div className="creationDossier__salarieAristesButtons">
							<span>{salarie.nom} {salarie.prenom}</span>
							<span className="icon-button icon-button--first" onClick={() => { editSalarie(salarie) }}><i className="far fa-edit"></i></span>
							<span className="icon-button" onClick={() => { deleteSalarie(salarie.id) }}><i className="far fa-trash-alt"></i></span>
						</div>
						: <div className="creationDossier__singleRightButton">
							<FormButton
								type="button"
								value={t("createFolder.salarieArtistes.salarie.add")}
								onClick={() => {
									setCurrentView(View.Salarie);
									setCurrentFormMode(SalarieFormMode.IS_CREATION);
								}}
							/>
						</div>
					}
					<div className="creationDossier__item">
					</div>
				</section>
				<section>
					<h4 className="title--red">{t("createFolder.salarieArtistes.artistes.title")}</h4>
					<div className="creationDossier__singleRightButton">
						<FormButton
							type="button"
							value={t("createFolder.salarieArtistes.artistes.add")}
							onClick={() => {
								setCurrentView(View.Artiste);
								setCurrentFormMode(SalarieFormMode.IS_CREATION);
							}}
						/>
					</div>
					<div className="creationDossier__item">
					{
						isArtistLoading
						? <div className="creationDossier__salarieAristesLoader">
							<Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
						</div>
						: <Table
							data={artistes}
							getTableProps={getTableProps}
							getTableBodyProps={getTableBodyProps}
							headerGroups={headerGroups}
							rows={rows}
							prepareRow={prepareRow}
							styles={styleTable.onglet}
						/>
					}
					</div>
				</section>
				<ModalDeleteArtiste
					isOpen={showDeleteArtiste}
					onCancel={() => setShowDeleteArtiste(false)}
					onValidate={() => deleteArtiste()}
					isLoading={props.isLoading}
				/>
				<ModalValidationArtiste
					isOpen={openValidation}
					onCancel={() => setOpenValidation(false)}
					errorsValidation={errorsValidation}
				/>
			</div>
		)
	}

	/** Views */
	const renderSwitchView = () => {
		switch (currentView) {
			case View.List:
				return listView();

			case View.Salarie:
				return <Salarie
					ref={salarieRef}
					setIsFormValid={props.setIsFormValid}
					closeView={() => {
						setCurrentView(View.List);
						setCurrentFormMode(SalarieFormMode.IS_CREATION)
					}}
					currentFormMode={currentFormMode}
					dossierId={props.dossierId}
					salarieSelected={salarieSelected}
					salarieValidator={salarieValidator}
					adresseValidator={adresseValidator}
				/>
			case View.Artiste:
				return <Artistes
					ref={artistesRef}
					setIsFormValid={props.setIsFormValid}
					closeView={() => {
						setCurrentView(View.List);
						setCurrentFormMode(SalarieFormMode.IS_CREATION)
					}}
					dossierId={props.dossierId}
					currentFormMode={currentFormMode}
					artisteSelected={artisteSelected}
					artisteValidator={artisteValidator}
				/>
		}
	}

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			props.setIsLoading(true);

			switch (currentView) {
				case View.List:
					let valid = await dossierClient.validateArtistes(props.dossierId);
					if (valid.errors && valid.errors.length > 0) {
						setErrorsValidation(valid.errors);
						setOpenValidation(true);
						props.setIsLoading(false);
						return false;
					} else {
						props.setIsLoading(false);
						return true;
					}
				case View.Salarie:
					if (salarieRef && salarieRef.current) {
						let result = await salarieRef.current.validateAndSend();
						props.setIsLoading(false);
						return result;
					} else {
						props.setIsLoading(false);
						console.error("salarieRef undefined. Please contact your Dev Team.");
					}
				case View.Artiste:
					if (artistesRef && artistesRef.current) {
						let result = await artistesRef.current.validateAndSend();
						props.setIsLoading(false);
						return result;
					} else {
						props.setIsLoading(false);
						console.error("artistesRef undefined. Please contact your Dev Team.");
					}
			}
			throw "currentView unknown. Please contact your Dev Team.";
		}
	}));

	return renderSwitchView();
});

export default SalarieAristes;