import React, { useState, useEffect, FunctionComponent } from 'react';
import Input from 'adel-shared/dist/components/basics/Input';
import { useTranslation } from 'react-i18next';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import moment from 'moment';
import { CreateArtisteDto, LieuDateDto, TypePrestation } from '../../../../services/generated/FrontOffice-api';
import { DatesSelectOption, LieuDateByPrestation, SalairesCusto } from './EditArtiste';
import { Controller, FormContextValues } from 'react-hook-form';

interface SalairesDatesBlockProps {
	salaires: SalairesCusto[];
	setSalaires: (value: SalairesCusto[]) => void;
	dates: { [key: string]: LieuDateDto[]; };
	datesSelectOptions: DatesSelectOption[];
	setDatesSelectOptions:(value: DatesSelectOption[]) => void;
	salaire: SalairesCusto;
	typePresta: string;
	form: FormContextValues<CreateArtisteDto>;
}

const SalairesDatesBlock: FunctionComponent<SalairesDatesBlockProps> = ({
	salaires,
	setSalaires,
	dates,
	datesSelectOptions,
	setDatesSelectOptions,
	salaire,
	typePresta,
	form
}) => {
	const { t } = useTranslation();

	/** Options pour les types Enregistrement */
	const [enregistrementOptions, setEnregistrementOptions] = useState<AdelOption<LieuDateDto>[]>([]);

	const salairesDates = salaire.lieuDateByPresta?.find(e => e.typePresta === TypePrestation[typePresta as keyof typeof TypePrestation])?.dates;
	
	useEffect(() => {
		if (salaires && salaire && (TypePrestation[typePresta as keyof typeof TypePrestation]) === TypePrestation.Enregistrement)
			setEnregistrementOptions(generationDatesOptions(dates[typePresta], salaire.lieuDateByPresta?.find(e => e.typePresta === TypePrestation.Enregistrement)))
	}, [salaires]);

	const generationDatesOptions = (array: LieuDateDto[], selectedSalaire: LieuDateByPrestation) => {
		if (selectedSalaire) {
			return array?.filter(d => !selectedSalaire.dates.some(s => s.id === d.id)).map(date => ({
				label: moment(date.date).format('DD/MM/YYY'),
				value: date
			}));
		}
		return array?.map(date => ({
			label: moment(date.date).format('DD/MM/YYY'),
			value: date
		}));
	}

	const optionsDate = () => {
		let opts: (AdelOption<LieuDateDto> | AdelOption<string>)[] = [];

		if(TypePrestation[typePresta as keyof typeof TypePrestation] !== TypePrestation.Enregistrement) {
			opts = datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[typePresta as keyof typeof TypePrestation])?.options || [];
		} else {
			opts = enregistrementOptions;
		}

		const isAllDatesOpt = opts.some((x: any) => x.value === 'all');

		if(!isAllDatesOpt && opts.length > 1) {
			opts.unshift({
				label: t('createFolder.contratsCachets.allDates'),
				value: "all"
			});
		}

		return opts;
	};

	const changeDates = (dateFromSelect: LieuDateDto | string, typePresta: string, salaire: SalairesCusto) => {
		const updateSalaires = [...salaires];
		const currentSalaire = updateSalaires.find(d => d.id === salaire.id);
		const currentDates = currentSalaire.lieuDateByPresta.find(l => l.typePresta === TypePrestation[typePresta as keyof typeof TypePrestation]);

		// If select all
		if(typeof dateFromSelect === 'string') {
			const addedDates = dates[typePresta].filter(x => datesSelectOptions.find(y => y.typePresta === typePresta)?.options.some(z => z.value.id === x.id));

			if(currentDates) {
				currentDates.dates = [
					...currentDates.dates,
					...addedDates
				];
			} else {
				currentSalaire.lieuDateByPresta.push({
					typePresta: TypePrestation[typePresta as keyof typeof TypePrestation],
					dates: addedDates
				});
			}

			const updatedOptions = [...datesSelectOptions];
			updatedOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[typePresta as keyof typeof TypePrestation]).options.length = 0;
			setDatesSelectOptions(updatedOptions);

			setSalaires(updateSalaires);
		} else {
			if (currentDates) {
				currentDates.dates.push(dateFromSelect);
			}
			else {
				currentSalaire.lieuDateByPresta.push({
					typePresta: TypePrestation[typePresta as keyof typeof TypePrestation],
					dates: [dateFromSelect] || []
				});
			}
			setSalaires(updateSalaires);
	
			// Update de DatesSelectOptions (quand ce n'est pas un enregistrement)
			if (TypePrestation[typePresta as keyof typeof TypePrestation] !== TypePrestation.Enregistrement) {
				if (datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[typePresta as keyof typeof TypePrestation])) {
					const updatedOptions = [...datesSelectOptions];
					const updatedDates = updatedOptions.find(e => e.typePresta === typePresta)?.options.filter(d => d.value.id !== dateFromSelect.id );
	
					if (updatedDates) {
						updatedOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[typePresta as keyof typeof TypePrestation]).options = updatedDates;
						setDatesSelectOptions(updatedOptions);
					}
				}
			}
		}
	}

	const deleteDate = (itemClicked: LieuDateDto, selectedSalaire:SalairesCusto) => {
		const updateSalaires = [...salaires]
		const salaireToUpdate = updateSalaires.find(s => s.id == selectedSalaire.id).lieuDateByPresta;

		if (salaireToUpdate) {
			updateSalaires.find(s => s.id == selectedSalaire.id).lieuDateByPresta.find(l => l.typePresta === itemClicked.typePrestation).dates = 
				updateSalaires.find(s => s.id == selectedSalaire.id).lieuDateByPresta.find(l => l.typePresta === itemClicked.typePrestation).dates
				.filter(d => d.id !== itemClicked.id)
		}
		setSalaires(updateSalaires);

		if (itemClicked.typePrestation !== TypePrestation.Enregistrement) {
			if(datesSelectOptions.find(elem => TypePrestation[elem.typePresta] === TypePrestation[typePresta as keyof typeof TypePrestation])) {
				const updatedDates = [...datesSelectOptions];
				const datesCurrentPresta = updatedDates.find(elem => TypePrestation[elem.typePresta] === itemClicked.typePrestation).options;
				datesCurrentPresta.push({
					label: moment(itemClicked.date).format('DD/MM/YYYY'),
					value: itemClicked
				});
				datesCurrentPresta.sort((a, b) => {
					if(a.value.date && b.value.date) {
						return a.value.date > b.value.date ? 1 : -1;
					}
					return 0;
				});
				setDatesSelectOptions(updatedDates);
			}
		}
	}


	return (
		<div className="creationDossier__row">
			<div>
				<Controller
					control={form.control}
					name={`datesSalaire${typePresta}${salaire.id}`}
					as={({ name, value, onChange }) => (
						<InputSelect<LieuDateDto | string>
							name={name}
							label={`${t('createFolder.contratsCachets.listColumns.date' + typePresta)}`}
							classname="inputSelect inputSelect--multi"
							options={optionsDate()}
							errors={form.errors}
							onChange={date => {
								onChange(date);
								changeDates(date, typePresta, salaire);
							}}
							placeholder={t("common.select")}
							value={value}
						/>
					)}
				/>
			</div>
			<div className="listItems">
				<label>{t("createFolder.lieuxDates.date-added")}</label> 
				<div className="listItemsContent">
					{salaire.lieuDateByPresta?.filter(e => e.typePresta === TypePrestation[typePresta as keyof typeof TypePrestation])?.map((lieuDate, index: number) => (
						<span key={'dates'+index}>
							{lieuDate.dates.map((date, i) => (
								<span key={date?.id+i}>
									<span>{moment(date?.date).format('DD/MM/YYYY')}</span>
									<i className="far fa-trash-alt" onClick={() => deleteDate(date, salaire)}></i>
									{lieuDate.dates.length === 1 || lieuDate.dates.indexOf(date) === lieuDate.dates.length - 1 ? '' : ', '}
								</span>
							))}
						</span>
					))}
				</div>
			</div>
			<Input name={`nbJoursRepetition${typePresta}${salaire.id}`}
				label={`${t('createFolder.contratsCachets.listColumns.nbJours' + typePresta)}`}
				type="text"
				value={salairesDates?.length || '0'}
				readOnly
			/>
		</div>
	);
}

export default SalairesDatesBlock;