import React, { useState } from "react";
import { ErrorMessage } from "react-hook-form";
const Input = ({ autoComplete, label, name, className, placeHolder, maxLength, minLength, reference, type, value, errors, onChange, onBlur, dataList, disabled = false, readOnly = false, required = false, errorMessageCusto, step, min, max, defaultValue }) => {
    const [dataListIsOpen, setDataListIsOpen] = useState(false);
    const dataListOnclick = (data) => {
        setDataListIsOpen(false);
        return onChange(data);
    };
    const handleMouseWheel = (e) => {
        e.currentTarget.blur();
    };
    return (React.createElement("div", { className: className ? className : "input" },
        label &&
            React.createElement("label", null, label),
        React.createElement("input", { autoComplete: autoComplete, name: name, ref: reference, type: type, placeholder: placeHolder, maxLength: maxLength, minLength: minLength, value: value, disabled: disabled, readOnly: readOnly, required: required, step: step, min: min, max: max, onWheel: handleMouseWheel, onBlur: e => {
                if (onBlur)
                    onBlur(e.target.value, e);
            }, onChange: e => {
                if (onChange)
                    onChange(e.target.value, e);
                if (dataList && e.target.value !== "")
                    setDataListIsOpen(true);
                else
                    setDataListIsOpen(false);
            }, list: dataList ? name : '', defaultValue: defaultValue }),
        (dataList === null || dataList === void 0 ? void 0 : dataList.length) > 0 &&
            React.createElement(React.Fragment, null,
                React.createElement("ul", { id: name, className: `input__dataList ${dataListIsOpen && 'input__dataList--isOpen'}` }, dataList.map((data, index) => (React.createElement("li", { key: index, onClick: () => dataListOnclick(data) }, data)))),
                React.createElement("div", { className: `overlay__dataList ${dataListIsOpen && 'overlay__dataList--isOpen'}`, onClick: () => setDataListIsOpen(false) })),
        errors &&
            React.createElement(ErrorMessage, { errors: errors, name: name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message)),
        errorMessageCusto &&
            React.createElement("p", { className: "input__errorMessage" }, errorMessageCusto)));
};
export default Input;
