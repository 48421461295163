import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AdelDossierClient } from '../../../../clients/AdelDossierClient';
import { useAxios } from '../../../../custom-hooks/useAxios';
import useValidation from '../../../../custom-hooks/useValidation';
import { Dictionary } from '../../../../models';
import { DisciplineDto, DocumentUploadInfoDto, DossierClient, EnseignantDto, QualificationEnseignant, StatutEnseignant, TypeContrat } from '../../../../services/generated/FrontOffice-api';
import { EnseignantFormMode } from '../Enseignants';

export interface EditEnseignantRef {
	validateAndSend: () => Promise<boolean>;
}

interface EditEnseignantProps {
	dossierId: string;
	closeView: () => void;
	setIsFormValid: (value: boolean) => void;
	existingEnseignant: EnseignantDto;
	currentFormMode: EnseignantFormMode | undefined;
}

interface CreateOrUpdateEnseignantModel {
	nom: string;
	prenom: string;
	ville: string;
	discipline: string[];
	instrument?: string;
	qualification: QualificationEnseignant;
	typeContrat: TypeContrat;
	statut: StatutEnseignant;
	remunerationBruteAnnuelle: string;
	montantChargesPatronales: string;
	tarifHoraireBrut: string;
	contratEngagement: DocumentUploadInfoDto;
	otherDiscipline?: string;
}

const CONTRAT_ENGAGEMENT_NAME = "contratEngagement";

const EditEnseignant: ForwardRefExoticComponent<PropsWithoutRef<EditEnseignantProps> & RefAttributes<EditEnseignantRef>> = forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();

	const axiosInstance = useAxios();
	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
	const adelDossierClient = useMemo(() => { return new AdelDossierClient("", axiosInstance) }, [axiosInstance]);

	const [disciplineOptions, setDisciplineOptions] = useState<AdelOption<string>[]>([]);
	const [instrumentOptions, setInstrumentOptions] = useState<AdelOption<string>[]>([]);
	const [showInstrument, setShowInstrument] = useState<boolean>(false);
	const [showOtherDiscipline, setShowOtherDiscipline] = useState<boolean>(false);

	const [file, setFile] = useState<File>();
	const [fileWasModified, setFileWasModified] = useState<boolean>(false);

	const { register, getValues, setValue, control, triggerValidation, errors } = useForm<any>({
		defaultValues: {
			instrument: props.existingEnseignant?.instrument?.id,
			otherDiscipline: props.existingEnseignant?.otherDiscipline
		}
	});
	const { getRootValidator } = useValidation();
	const enseignantValidator = getRootValidator("CreateEnseignantDto");

	const contratOptions: AdelOption<TypeContrat>[] = useMemo(() => {
		let allOptions: AdelOption<TypeContrat>[] = [];

		for (let contrat in TypeContrat) {
			if (contrat !== "None") {
				allOptions.push(
					{
						value: TypeContrat[contrat as keyof typeof TypeContrat],
						label: t(`common.contrat.${TypeContrat[contrat as keyof typeof TypeContrat]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const statutOptions: AdelOption<StatutEnseignant>[] = useMemo(() => {
		let allOptions: AdelOption<StatutEnseignant>[] = [];

		for (let statut in StatutEnseignant) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: StatutEnseignant[statut as keyof typeof StatutEnseignant],
						label: t(`common.statut.${StatutEnseignant[statut as keyof typeof StatutEnseignant]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const qualificationOptions: AdelOption<QualificationEnseignant>[] = useMemo(() => {

		let allOptions: AdelOption<QualificationEnseignant>[] = [];

		for (let statut in QualificationEnseignant) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: QualificationEnseignant[statut as keyof typeof QualificationEnseignant],
						label: t(`common.qualification.${QualificationEnseignant[statut as keyof typeof QualificationEnseignant]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);



	/** Disciplines */
	const [disciplines, setDisciplines] = useState<string[]>([]);

	const setDisciplinesValue = useCallback((value:any) => { // value: AdelOption<DisciplineDto>[]
		const result = (value as any[])?.map(o => o.value) ?? [];
		setDisciplines(result);
	}, [setDisciplines]);

	const getOptionForDisciplinesValue = useCallback((value: any): any => {
        return disciplineOptions.find(o => o.value == value.id);
	}, [disciplineOptions]);



	const initializeView = async (): Promise<void> => {
		let vm = await dossierClient.getEnseignantsViewModel(props.dossierId);
		setDisciplineOptions(vm.disciplines.map(discipline => {
			return { label: discipline.text[i18n.language], value: discipline.id };
		}));
		setInstrumentOptions(vm.instruments.map(instrument => {
			return { label: instrument.intitule[i18n.language], value: instrument.id };
		}));
	}

	const initFormValues = (existingEnseignant: EnseignantDto, currentFormMode: EnseignantFormMode) => {
		Object.entries(existingEnseignant).forEach(([key, value]) => {
			if (currentFormMode === EnseignantFormMode.Duplication && key === "id") return;
			if (currentFormMode === EnseignantFormMode.Duplication && key === "nom") return;
			if (currentFormMode === EnseignantFormMode.Duplication && key === "prenom") return;
			if (currentFormMode === EnseignantFormMode.Duplication && key === "contratEngagement") return;
			if (key === "contratEngagement") {
				// Set dummy file to make it looks like file is already set
				setFile({ name: props.existingEnseignant.contratEngagement.fileName } as File);
			}
			setValue(key, value);
			if (key === "disciplines") {
				let array:string[] = [];
				existingEnseignant.disciplines.map((e: DisciplineDto) => {
					array.push(e.id)
				})
				setDisciplines(array);
				setValue(key, existingEnseignant.disciplines.map(getOptionForDisciplinesValue));
			}
		});

	}



	useEffect(() => {
		if (disciplines.find(d => d === "autre")) {
			setShowOtherDiscipline(true);
		} else {
			setShowOtherDiscipline(false);
		}

		if (disciplines.find(d => d === "instrumentale")) {
			setShowInstrument(true);
		} else {
			setShowInstrument(false);
		}
	}, [disciplines, setShowOtherDiscipline, setShowInstrument])



	useEffect(() => {
		if (props.setIsFormValid && props.currentFormMode !== undefined && (props.currentFormMode as EnseignantFormMode === EnseignantFormMode.Creation as EnseignantFormMode) || props.existingEnseignant) { // Either this is "Creation Mode", either is it "Duplication or Edition" and a "existingEnseignant" is filled
			props.setIsFormValid(true);
			(async function () {
				await initializeView();
			})()
		}
	}, [props.setIsFormValid, props.currentFormMode])

	useEffect(() => {
		if (props.currentFormMode !== undefined && (props.currentFormMode as EnseignantFormMode !== EnseignantFormMode.Creation as EnseignantFormMode) && disciplineOptions.length > 0 && instrumentOptions.length > 0 && props.existingEnseignant) {
			initFormValues(props.existingEnseignant, props.currentFormMode);
		}
	}, [props.existingEnseignant, props.currentFormMode, disciplineOptions, instrumentOptions])



	useImperativeHandle(ref, () => ({
		async validateAndSend(): Promise<boolean> {
			let isValid = await triggerValidation();
			if (isValid) {
				try {
					let model = getValues();
					let createOrUpdateEnseignantDto = {
						nom: model.nom,
						prenom: model.prenom,
						ville: model.ville,
						disciplines: disciplines,
						otherDiscipline: model.otherDiscipline,
						instrumentId: model.instrument && model.instrument,
						qualification: model.qualification,
						typeContrat: model.typeContrat,
						statut: model.statut,
						remunerationBruteAnnuelle: parseFloat(model.remunerationBruteAnnuelle as string),
						montantChargesPatronales: parseFloat(model.montantChargesPatronales as string),
						tarifHoraireBrut: parseFloat(model.tarifHoraireBrut as string),
						contratEngagement: model.contratEngagement
					} as EnseignantDto;
					let contratEngagementFile: Dictionary<File> = { [CONTRAT_ENGAGEMENT_NAME]: file };
					if (props.currentFormMode === EnseignantFormMode.Edition) {
						// Update
						if (!fileWasModified) {
							// Strip everything except id if file was not modified
							createOrUpdateEnseignantDto = { ...createOrUpdateEnseignantDto, contratEngagement: { id: createOrUpdateEnseignantDto.contratEngagement.id } };
							contratEngagementFile = {};
						}
						await adelDossierClient.updateEnseignant(props.dossierId, props.existingEnseignant.id, createOrUpdateEnseignantDto, contratEngagementFile);
					} else {
						// Creation or Duplication
						await adelDossierClient.createEnseignant(props.dossierId, createOrUpdateEnseignantDto, contratEngagementFile);
					}
					props.closeView();
					return true;
				} catch (error) {
					if (error.exception?.message)
						toast.error(error.exception.message);
					else
						toast.error(t("errors.default"));
					return false;
				}
			} else {
				toast.error(t('common.validation-error.message'));
				return false;
			}
		}
	}));

	const downloadFile = () => {
		if (props.existingEnseignant && props.existingEnseignant.contratEngagement && props.existingEnseignant.contratEngagement.uri) {
			window.open(props.existingEnseignant.contratEngagement.uri);
		}
	}

	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
		if (value.target.files.length !== 0) {
			if (value.target.files[0].name !== "") {
				var selectedFile = value.target.files[0];
				setFile(selectedFile);
				setFileWasModified(true);
				setValue(CONTRAT_ENGAGEMENT_NAME, { partName: CONTRAT_ENGAGEMENT_NAME } as DocumentUploadInfoDto);
			} else {
				resetFile();
			}
		}
	};

	const resetFile = () => {
		setFile(undefined);
		setValue(CONTRAT_ENGAGEMENT_NAME, undefined);
	}

	return (
		<div className="creationDossier__editEnseignant">
			<div className="creationDossier__header--alt">
				<div className="navigationFil" onClick={() => props.closeView()}>
					<span className="navigationFil__item">
						<i className="fas fa-chevron-left"></i>
						{t("createFolder.enseignants.back")}
					</span>
				</div>
				<h2>{t("createFolder.enseignants.add")}</h2>
			</div>

			<section className="creationDossier__item">
				<div className="creationDossier__row">
					<Input
						name="nom"
						label={t('createFolder.form.nom')}
						type="text"
						reference={register(enseignantValidator && enseignantValidator["Nom"])}
						errors={errors}
					/>

					<Input
						name="prenom"
						label={t('createFolder.form.prenom')}
						type="text"
						reference={register(enseignantValidator && enseignantValidator["Prenom"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="ville"
						label={t('createFolder.enseignants.listColumns.ville')}
						type="text"
						reference={register(enseignantValidator && enseignantValidator["Ville"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					{enseignantValidator && <Controller control={control}
						name="disciplines"
						rules={enseignantValidator["Discipline"]}
						as={({ onChange, value, name }) => (
							<InputSelect<string>
								name={name}
								label={t('createFolder.enseignants.listColumns.discipline')}
								classname="inputSelect inputSelect--multi"
								options={disciplineOptions}
								errors={errors}
								isMulti={true}
								onChange={(selectedDiscipline) => {
									onChange(selectedDiscipline);
									setDisciplinesValue(selectedDiscipline);
								}}
								value={value}
								placeholder={t("common.select")}
							/>
						)}
					/>}

					{showInstrument &&

						<Controller control={control}
							name="instrument"
							rules={enseignantValidator && enseignantValidator["InstrumentId"]}
							as={({ onChange, value, name }) => (
								<InputSelect<string>
									name={name}
									label={t('createFolder.enseignants.listColumns.instrument')}
									classname="inputSelect"
									options={instrumentOptions}
									errors={errors}
									onChange={(selectedInstrument) => {
										onChange(selectedInstrument);
									}}
									value={value}
									placeholder={t("common.select")}
								/>
							)} />
						}
				</div>

				{showOtherDiscipline &&
					<div className="creationDossier__row">
						<Input
							name="otherDiscipline"
							label={t('createFolder.enseignants.listColumns.discipline-autre')}
							type="text"
							reference={register}
							errors={errors}
						/>
					</div>
				}
				<div className="creationDossier__row">
					{enseignantValidator && <Controller control={control}
						name="qualification"
						rules={enseignantValidator["Qualification"]}
						as={({ onChange, value, name }) => (
							<InputSelect<QualificationEnseignant>
								name={name}
								label={t('createFolder.enseignants.listColumns.qualification')}
								classname="inputSelect"
								options={qualificationOptions}
								errors={errors}
								onChange={(selectedQualification) => {
									onChange(selectedQualification);
								}}
								value={value}
								placeholder={t("common.select")}
							/>
						)} />}
					{enseignantValidator && <Controller control={control}
						name="typeContrat"
						rules={enseignantValidator["TypeContrat"]}
						as={({ onChange, value, name }) => (
							<InputSelect<TypeContrat>
								name={name}
								label={t('createFolder.enseignants.listColumns.typeContrat')}
								classname="inputSelect"
								options={contratOptions}
								errors={errors}
								onChange={(selectedContrat) => {
									onChange(selectedContrat);
								}}
								value={value}
								placeholder={t("common.select")}
							/>
						)} />}
				</div>
				<div className="creationDossier__row">
					{enseignantValidator && <Controller control={control}
						name="statut"
						rules={enseignantValidator && enseignantValidator["Statut"]}
						as={({ onChange, value, name }) => (
							<InputSelect<StatutEnseignant>
								name={name}
								label={t('createFolder.enseignants.listColumns.statut')}
								classname="inputSelect"
								options={statutOptions}
								errors={errors}
								onChange={(selectedStatut) => {
									onChange(selectedStatut);
								}}
								value={value}
								placeholder={t("common.select")}
							/>
						)} />}

					<Input
						name="remunerationBruteAnnuelle"
						label={t('createFolder.enseignants.listColumns.remunerationBruteAnnuelle')}
						type="number"
						reference={register(enseignantValidator && enseignantValidator["RemunerationBruteAnnuelle"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="montantChargesPatronales"
						label={t('createFolder.enseignants.listColumns.montantChargesPatronales')}
						type="number"
						reference={register(enseignantValidator && enseignantValidator["MontantChargesPatronales"])}
						errors={errors}
					/>

					<Input
						name="tarifHoraireBrut"
						label={t('createFolder.enseignants.listColumns.tarifHoraireBrut')}
						type="number"
						reference={register(enseignantValidator && enseignantValidator["TarifHoraireBrut"])}
						errors={errors}
					/>
				</div>

				<Controller control={control}
					name={CONTRAT_ENGAGEMENT_NAME}
					rules={{ required: { value: true, message: t('common.validation-error.fileRequired') } }}
					as={({name}) => (
						<>
							<div className={`inputFile__item${file ? "--hidden" : ""}`}>
								<div className="inputFile__buttons">
									<label htmlFor={CONTRAT_ENGAGEMENT_NAME}>{t("createFolder.enseignants.addFile")}</label>
									<input
										name={name}
										id={CONTRAT_ENGAGEMENT_NAME}
										type="file"
										onChange={handleFileChange}
									/>
								</div>
							</div>
							<div className={`inputFile__item${!file ? "--hidden" : ""}`}>
								<div className="inputFile__infos">
									<div className="inputFile__infos--file">
										<span className={props.existingEnseignant && props.existingEnseignant.contratEngagement && props.existingEnseignant.contratEngagement.uri ? "inputFile__infos--fileName" : ""} onClick={() => downloadFile()}>{file ? file.name : ""}</span>
										<span onClick={() => resetFile()}>
											<i className="far fa-trash-alt"></i>
										</span>
									</div>
								</div>
							</div>
							{errors &&
								<div className="input">
									<ErrorMessage errors={errors} name={CONTRAT_ENGAGEMENT_NAME}>
										{({ message }) => <p className="input__errorMessage">{message}</p>}
									</ErrorMessage>
								</div>
							}</>
					)} />
			</section>
		</div>
	)
})

export default EditEnseignant;