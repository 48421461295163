import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import { modalSmallerCustomStyles } from "../../constants/config.constant";
import FormButton from 'adel-shared/dist/components/basics/FormButton';

interface ModalErrorInformationProps {
	isOpen: boolean;
	onCancel: () => void;
	children: ReactElement<any>;
	title?: string;
	validateButtonLabel?: string;
	cancelButtonLabel?: string;
	isLoading?: boolean;
}

const ModalErrorInformation: React.SFC<ModalErrorInformationProps> = ({
	isOpen,
	onCancel,
	children,
	title,
	cancelButtonLabel,
}) => {
	return (<Modal
		isOpen={isOpen}
		style={modalSmallerCustomStyles}
	>
		<div className="modal__header">
			<h3>{title ?? "Attention"}</h3>
		</div>
		<div className="modal__content">
			{React.cloneElement(children)}
		</div>
		<div className="modal__footer--flexEnd">
			<FormButton
				type="button"
				value={cancelButtonLabel ?? "Annuler"}
				onClick={() => onCancel()}
			/>
		</div>
	</Modal>);
}

export default ModalErrorInformation;