import { IStepOneModel, IStepTwoModel, IStepThreeModel, IStepFourModel, IStepFiveModel, IStepSevenModel, IStepSixModel } from '../models/signup';
import SignupStep from './SignupSteps';
import { Context } from './context';
import { FOCurrentUserDto, EtapeDemandeVersement, StructureDetailsDto, TypeVersement, UpdateStructureAdresseDto, TypeDeDocumentDto, DossierDtoSortedFilteredPage } from '../services/generated/FrontOffice-api';
import DossierState from '../models/dossiers/DossierState';
import { PageVitrineDto } from 'adel-shared/dist/models/generated/BackOffice-api';
import { CategorieDossier } from 'adel-shared/dist/enums/CategorieDossier';

export enum ActionTypeEnum {
	UPDATE_STRUCTURE = "UPDATE_STRUCTURE",
	UPDATE_STRUCTURE_ADDRESS = "UPDATE_STRUCTURE_ADDRESS",
	UPDATE_STRUCTURE_INFOS = "UPDATE_STRUCTURE_INFOS",
	SET_USER = "SET_USER",
	UNSET_USER = "UNSET_USER",
	LOGOUT_USER = "USER_LOGOUT",
	SET_SIGNUP_STEP_ONE = "SET_SIGNUP_STEP_ONE",
	SET_SIGNUP_STEP_TWO = "SET_SIGNUP_STEP_TWO",
	SET_SIGNUP_STEP_THREE = "SET_SIGNUP_STEP_THREE",
	SET_SIGNUP_STEP_FOUR = "SET_SIGNUP_STEP_FOUR",
	SET_SIGNUP_STEP_FIVE = "SET_SIGNUP_STEP_FIVE",
	SET_SIGNUP_STEP_SIX = "SET_SIGNUP_STEP_SIX",
		SET_SIGNUP_STEP_SIX_DOCUMENT_DATE = "SET_SIGNUP_STEP_SIX_DOCUMENT_DATE",
		SET_SIGNUP_STEP_SIX_DOCUMENT_FILE = "SET_SIGNUP_STEP_SIX_DOCUMENT_FILE",
		SET_SIGNUP_STEP_SIX_DOCUMENT_TYPE = "SET_SIGNUP_STEP_SIX_DOCUMENT_TYPE",
		SET_SIGNUP_STEP_SIX_DOCUMENT_EXTRA_INFO = "SET_SIGNUP_STEP_SIX_DOCUMENT_EXTRA_INFO",

	SET_SIGNUP_STEP_SEVEN = "SET_SIGNUP_STEP_SEVEN",
	SET_SIGNUP_HIGHEST_STEP = "SET_SIGNUP_HIGHEST_STEP",

	START_NEW_DOSSIER_CREATION = "START_NEW_DOSSIER_CREATION",
	CREATE_DOSSIER_REQUEST = "CREATE_DOSSIER_REQUEST",
	CREATE_DOSSIER_SUCCESS = "CREATE_DOSSIER_SUCCESS",
	UPDATE_DOSSIER_STATE = "UPDATE_DOSSIER_STATE",

	EDIT_DOSSIER = "EDIT_DOSSIER",
	DELETE_DOSSIER_REQUEST = "DELETE_DOSSIER_REQUEST",
	DELETE_DOSSIER_SUCCESS = "DELETE_DOSSIER_SUCCESS",
	DOSSIER_REFRESHED = "DOSSIER_REFRESHED",
	SET_DOSSIERS_LIST_SUCCESS = "SET_DOSSIERS_LIST_SUCCESS",
	SEARCH_DOSSIERS_SUCCESS = "SEARCH_DOSSIERS_SUCCESS",
	
	START_NEW_VERSEMENT_CREATION = "START_NEW_VERSEMENT_CREATION",
	CREATE_VERSEMENT_REQUEST = "CREATE_VERSEMENT_REQUEST",
	CREATE_VERSEMENT_SUCCESS = "CREATE_VERSEMENT_SUCCESS",
	UPDATE_VERSEMENT_STATE = "UPDATE_VERSEMENT_STATE",
	EDIT_VERSEMENT = "EDIT_VERSEMENT",

	DO_SOMETHING = "DO_SOMETHING",
	ERROR_OCCURRED = "ERROR_OCCURRED",
	REINIT_TOASTER = "REINIT_TOASTER",
	CAN_CREATE_STRUCTURE = "CAN_CREATE_STRUCTURE",

	GET_PAGES_VITRINE_SUCCESS = "GET_PAGES_VITRINE_SUCCESS"
}

export interface LogoutAction {
	type: typeof ActionTypeEnum.LOGOUT_USER;
}

export interface UpdateStructureAction {
	type: typeof ActionTypeEnum.UPDATE_STRUCTURE;
	payload: StructureDetailsDto;
}

export interface UpdateStructureInfosAction {
	type: typeof ActionTypeEnum.UPDATE_STRUCTURE_INFOS;
	payload: StructureDetailsDto;
}

export interface UpdateStructureAddressAction {
	type: typeof ActionTypeEnum.UPDATE_STRUCTURE_ADDRESS;
	payload: UpdateStructureAdresseDto;
}

export interface DoSomethingAction {
	type: typeof ActionTypeEnum.DO_SOMETHING;
	payload: any;
}

export interface SetUserAction {
	type: typeof ActionTypeEnum.SET_USER;
	payload: FOCurrentUserDto;
}

export interface UnSetUserAction {
	type: typeof ActionTypeEnum.UNSET_USER;
}

export interface SetSignupStepOneAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_ONE;
	payload: IStepOneModel;
}

export interface SetSignupStepTwoAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_TWO;
	payload: IStepTwoModel;
}

export interface SetSignupStepThreeAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_THREE;
	payload: IStepThreeModel;
}

export interface SetSignupStepFourAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_FOUR;
	payload: IStepFourModel;
}

export interface SetSignupStepFiveAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_FIVE;
	payload: IStepFiveModel;
}

export interface SetSignupStepSixAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_SIX;
	payload: IStepSixModel;
}

export interface SetSignupStepSixActionDocumentDate {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_DATE;
	payload: {
		typeDocument: TypeDeDocumentDto;
		extraInfoCode: string;
		dateValue: Date;
	};
}

export interface SetSignupStepSixActionDocumentFile {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_FILE;
	payload: {
		typeDocument: TypeDeDocumentDto;
		fileValue: any;
	};
}

export interface SetSignupStepSixActionDocumentType {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_TYPE;
	payload: {
		typeDocument: TypeDeDocumentDto;
		value: TypeDeDocumentDto;
	};
}
export interface SetSignupStepSixActionDocumentExtraInfo {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_SIX_DOCUMENT_EXTRA_INFO;
	payload: {
		typeDocument: TypeDeDocumentDto;
		extraInfoCode: string;
		value: string;
	};
}

export interface SetSignupStepSevenAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_STEP_SEVEN;
	payload: IStepSevenModel;
}

export interface SetSignupHighestStepAction {
	type: typeof ActionTypeEnum.SET_SIGNUP_HIGHEST_STEP;
	payload: SignupStep;
}

export interface ErrorOccurredAction {
	type: typeof ActionTypeEnum.ERROR_OCCURRED;
	payload: Context.IAppError;
}

export interface ReinitToasterAction {
	type: typeof ActionTypeEnum.REINIT_TOASTER;
}

export interface CanCreateStructure {
	type: typeof ActionTypeEnum.CAN_CREATE_STRUCTURE;
	payload: boolean;
}

export interface StartNewDossierCreationAction {
	type: typeof ActionTypeEnum.START_NEW_DOSSIER_CREATION;
}

export interface CreateDossierRequestAction {
	type: typeof ActionTypeEnum.CREATE_DOSSIER_REQUEST;
}

export interface CreateDossierSuccessOrUpdateAction {
	type: typeof ActionTypeEnum.CREATE_DOSSIER_SUCCESS | typeof ActionTypeEnum.UPDATE_DOSSIER_STATE;
	payload: { dossierId: string, categorieId: string, categorieCode?: CategorieDossier, state: DossierState }
}

export interface EditDossierAction {
	type: typeof ActionTypeEnum.EDIT_DOSSIER;
	payload: { dossierId: string, categorieId: string, categorieCode?: CategorieDossier, nom?: string, numero?: string }
}

export interface DeleteDossierRequestAction {
	type: typeof ActionTypeEnum.DELETE_DOSSIER_REQUEST;
}

export interface DeleteDossierSuccessAction {
	type: typeof ActionTypeEnum.DELETE_DOSSIER_SUCCESS;
	payload: { dossierId: string }
}

export interface DossierRefreshedAction {
	type: typeof ActionTypeEnum.DOSSIER_REFRESHED;
}

/** Versements */
export interface StartNewVersementCreationAction {
	type: typeof ActionTypeEnum.START_NEW_VERSEMENT_CREATION;
}
export interface CreateVersementRequestAction {
	type: typeof ActionTypeEnum.CREATE_VERSEMENT_REQUEST;
}
export interface CreateVersementSuccessOrUpdateAction {
	type: typeof ActionTypeEnum.CREATE_VERSEMENT_SUCCESS | typeof ActionTypeEnum.UPDATE_VERSEMENT_STATE;
	payload: {
		versementId: string;
		versementType: TypeVersement;
		dossierId: string;
		originalDossierId: string;
		state: { currentStep : EtapeDemandeVersement}
	}
}
export interface EditVersementAction {
	type: typeof ActionTypeEnum.EDIT_VERSEMENT;
	payload: {
		versementId: string;
		versementType: TypeVersement;
		dossierId: string;
		originalDossierId: string;
		nom?: string;
	}
}

export interface SetPagesVitrineAction {
	type: typeof ActionTypeEnum.GET_PAGES_VITRINE_SUCCESS;
	payload: { pagesVitrine: PageVitrineDto[] }
}

export interface SetDossiersListAction {
	type: typeof ActionTypeEnum.SET_DOSSIERS_LIST_SUCCESS,
	payload: {
		dossiers: DossierDtoSortedFilteredPage;
	}
}

export interface SearchDossiersAction {
	type: typeof ActionTypeEnum.SEARCH_DOSSIERS_SUCCESS,
	payload: {
		dossiers: DossierDtoSortedFilteredPage;
	}
}


export type ActionType = UpdateStructureAction
	| UpdateStructureInfosAction
	| UpdateStructureAddressAction
	| SetUserAction
	| UnSetUserAction
	| LogoutAction
	| SetSignupStepOneAction
	| SetSignupStepTwoAction
	| SetSignupStepThreeAction
	| SetSignupStepFourAction
	| SetSignupStepFiveAction
	| SetSignupStepSixAction
	| SetSignupStepSixActionDocumentDate
	| SetSignupStepSixActionDocumentFile
	| SetSignupStepSixActionDocumentType
	| SetSignupStepSixActionDocumentExtraInfo
	| SetSignupStepSevenAction
	| SetSignupHighestStepAction
	| DoSomethingAction
	| ErrorOccurredAction
	| CanCreateStructure
	| StartNewDossierCreationAction
	| CreateDossierRequestAction
	| CreateDossierSuccessOrUpdateAction
	| EditDossierAction
	| DeleteDossierRequestAction
	| DeleteDossierSuccessAction
	| DossierRefreshedAction
	| SetDossiersListAction
	| SearchDossiersAction
	| ReinitToasterAction
	| StartNewVersementCreationAction
	| CreateVersementRequestAction
	| CreateVersementSuccessOrUpdateAction
	| EditVersementAction
	| SetPagesVitrineAction;
