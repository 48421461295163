import { RouteComponentProps } from '@reach/router';
import Input from 'adel-shared/dist/components/basics/Input';
import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'adel-shared/dist/utils/functions';
import { DepenseDto } from '../../../../../services/generated/FrontOffice-api';
import { DocumentDepenses } from '../ContratsCachets';

interface DepenseItemProps extends RouteComponentProps {
	isDepenseAutre: boolean;
	hideFileInput: boolean;
	depense: DepenseDto;
	deleteDepense?: (value:string) => void;
	handChangeMontant: (value:string, id:string, isDepenseAutre:boolean) => void;
	handChangeMontantName?: (value:string, id:string) => void;
	editFile: (value:DocumentDepenses, idDepense:string, isDelete:boolean, isDepenseAutre:boolean) => void;
}

const DepenseItem: React.FunctionComponent<DepenseItemProps> = ({
	deleteDepense,
	isDepenseAutre,
	hideFileInput,
	depense,
	handChangeMontant,
	handChangeMontantName,
	editFile
}) => {
	const { t } = useTranslation();
	const [file, setFile] = useState<DocumentDepenses>({
		id: depense.document?.id,
		fileName: depense.document?.fileName
	});
	const [isMontantDirty, setMontantDirty] = useState<boolean>(false);

	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>, id:string) => {
		if (value.target.files.length !== 0) {
            if (value.target.files[0].name !== "") {
				setFile({
					data: value.target.files[0],
					fileName: value.target.files[0].name
				});
				editFile({
					data: value.target.files[0],
					fileName: value.target.files[0].name
				}, id, false, isDepenseAutre);
            } else {
				setFile(undefined);
            }
		}
	}

	const handleMontantChange = (value: string, depenseId: string, isDepenseAutre: boolean) => {
		if(!isMontantDirty) {
			setMontantDirty(true);
		}
		handChangeMontant(value, depenseId, isDepenseAutre);
	};

	const deleteFile = (idFile:string) => {
		setFile(undefined);
		editFile({
			id: idFile
		}, depense.id, true, isDepenseAutre);
	}

	const [montantRealise, setMontantRealise] = useState<string>(depense.montantRealise === undefined ? depense.montant.toString() : depense.montantRealise.toString())

	useEffect(() =>{
		handleMontantChange(montantRealise, depense.id, isDepenseAutre)
	}, [montantRealise])

	return(
		<div className="creationDossier__item questionFieldAnswerArrayItem">
			<div className="creationDossier__row versementsArtistes__depenseInput">
				{isDepenseAutre
					? <Input name="nom"
						label={`${t('creationVersement.contratsCachets.nomDepense')}`}
						type="text"
						value={depense.intitule}
						onChange={(value:string) => handChangeMontantName(value, depense.id)}
					/>
					: <InputReadOnly
						label={t("creationVersement.contratsCachets.montantPrev")}
						content={`${formatNumber(depense.montant)} €`}
					/>
				}
				<Input name="montant"
					label={isDepenseAutre ? t('creationVersement.contratsCachets.montantDepense') : t("creationVersement.contratsCachets.montantReal")}
					type="number"
					value={montantRealise}
					onChange={(value: string) => setMontantRealise(value)}
					errorMessageCusto={(isMontantDirty && (depense.montantRealise < 0 || isNaN(depense.montantRealise))) && t("validation-messages.invalid-number")}
				/>
				{ !hideFileInput ?
				<div className="input">
					<label className="label--withPadding">{t('creationVersement.contratsCachets.piecejointe')}</label>
					{!file?.fileName &&
						<div className="inputFile__buttons">
							<label htmlFor="uploadDocument">{t("common.download-file-single")}</label>
							<input
								type="file"
								id="uploadDocument"
								onChange={(value) => handleFileChange(value, depense.id)}
							/>
						</div>
					}
					{file?.fileName &&
						<div className="inputFile__infos--file">
							<span className="inputFile__infos--fileName">
								{file.fileName}
								<i className="far fa-trash-alt" onClick={() => deleteFile(depense.id)}></i>
							</span>
						</div>
					}
				</div>
				:
				<div className="input">
					
				</div>
				}

			</div>
			{deleteDepense &&
				<div className="creationDossier__addElement elementButton--absolute" onClick={() => deleteDepense(depense.id)}>
					<div className="creationDossier__addElementButton"><i className="far fa-minus-circle" /></div>
				</div>
			}
		</div>
	);
}

export default DepenseItem;
