import React, {useMemo, useState, useEffect} from "react";
import QuestionInputProps from "./QuestionInputProps";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {AnswerChoiceDto, AnswerType} from "../../../../services/generated/FrontOffice-api";

import InputSelect from 'adel-shared/dist/components/basics/InputSelect';

interface ChoiceListQuestionInputProps extends QuestionInputProps{
    choiceFilter?: (choice: AnswerChoiceDto) => boolean;
}

const ChoiceListQuestionInput: React.SFC<ChoiceListQuestionInputProps> = (props) => {

    const {t, i18n} = useTranslation();

    const options = useMemo(
        () => props.question.choices
            .filter(c => !props.choiceFilter || props.choiceFilter(c))
            .map(c => ({ value: c?.id, label: c?.textWithoutTraduction || c?.text[i18n.language], choice: c })),
        [props.question]);
    const isMulti = props.question?.answerType === AnswerType.MultipleChoiceList;

    const getOptionForValue = (value: any): any => {
        return options.find(o => o.value == value);
    }

    let selectedValues = isMulti
        ? (props.currentValue ?? []).map(getOptionForValue)
        : props.currentValue;

    const doSetValue = (value: any) => {
        const valueAsId = isMulti
            ? (value as any[])?.map(o => o.value) ?? []
            : value;
        if (props.onAnswerChange) {
            props.onAnswerChange(props.question?.id, valueAsId);
        }
    }

    const onChange = (value: any) => {
        if (isMulti) {
            // If user selects "none" from the list, clear existing values
            const noneOption = (value as any[])?.find(item => item.choice?.isNone);
            if (noneOption) {
                doSetValue([noneOption]);
                return;
            }
        }
        doSetValue(value);
    }

    return <div className="choiceListGroup">
	{isMulti
		? <InputSelect<any>
			label={`${props.title ? props.title : ""}${props.question?.isRequired ? '*' : ''}`}
			hasHtmlInLabel={true}
			name="questionField"
			classname="inputSelect inputSelect--multi"
			options={options}
			onChange={onChange}
			value={selectedValues}
			isMulti={isMulti}
			isClearable={!props.question?.isRequired}
			placeholder={t("common.select")}
			errorMessageCusto={props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '' && props.errorMessage.message}
		/>
		: <InputSelect<any>
			label={`${props.title ? props.title : ""}${props.question?.isRequired ? '*' : ''}`}
			hasHtmlInLabel={true}
			name="questionField"
			classname="inputSelect"
			options={options}
			onChange={onChange}
			value={selectedValues}
			isClearable={!props.question?.isRequired}
			placeholder={t("common.select")}
			errorMessageCusto={props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '' && props.errorMessage.message}
		/>
	}
    </div>;
}

export default ChoiceListQuestionInput;
