/**
 * CONFIG CONSTANTS - Les constantes qui servent à configurer manuellement des composants (notamment ceux qui viennent de librairies)
 */

import { PageNumbers } from 'adel-shared/dist/components/basics/Pagination';

const modalCustomStyles = {
	content: {
		overflow: 'visible',
		minWidth: '500px'
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, .2)',
		zIndex: 10
	}
};
const modalSmallerCustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		minWidth: '500px',
		maxWidth: '500px',
		padding: 0
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, .2)',
		zIndex: 10
	}
};

const modalLargeCustomStyles = {
	...modalCustomStyles,
	content: {
		minWidth: '1000px'
	}
};

const modalLargerCustomStyles = {
	...modalCustomStyles,
	content: {
		minWidth: '1300px'
	}
};

// La syntaxe de désactivation de l'autocomplete a l'air de changer souvent, il vaut mieux la modifier à un seul endroit au cas où
// Valeur arbitraire sur chrome pour l'empêcher d'autocomplete
const inputAutocompleteDisabled = navigator.userAgent.includes('Chrome') ? 'chrome-off' : 'off';

// Taux de chagres patronales
const tauxChargesPatronales = 0.6;

// Nom pays France
const nomFrance = "FRANCE METROPOLITAINE";
const idFrance = "3-7tgrikpkZUc";

const paginationNumbers:PageNumbers = {
	p1: 50,
	p2: 100,
	p3: 200,
	p4: 500,
	p5: 1000
}
const maxTotalFilesSize = '100';
const maxFileSize = '30';
const maxFileSizeMb = 30000000;

export {
	modalCustomStyles,
	modalSmallerCustomStyles,
	modalLargeCustomStyles,
	modalLargerCustomStyles,
	inputAutocompleteDisabled,
	tauxChargesPatronales,
	nomFrance,
	idFrance,
	paginationNumbers,
	maxTotalFilesSize,
	maxFileSize,
	maxFileSizeMb
};
