import React from "react";
import { ErrorMessage } from "react-hook-form";
const InputFileSingle = ({ label, reference, errorMessage, isRequired, onChange, name, currentValue, labelButton, extensions }) => {
    const isFileRef = (answer) => {
        if (!answer) {
            return false;
        }
        else if (typeof answer === "object" && answer.id && (answer.url) && answer.fileName) {
            return true;
        }
        return false;
    };
    const onFileChange = (e) => {
        const file = e.target.files.length !== 0
            ? {
                fileName: e.target.files[0].name,
                file: e.target.files[0]
            }
            : null;
        if (onChange) {
            onChange(file);
        }
    };
    const downloadFile = () => {
        if (currentValue && !(currentValue instanceof File) && currentValue.url) {
            window.open(currentValue.url);
        }
    };
    let hasExtensions = extensions && extensions.length > 0
        ? extensions.map(e => e.startsWith('.') ? e : '.' + e).join(',')
        : null;
    if (currentValue && Object.keys(currentValue).length > 0) {
        return (React.createElement("div", { className: "input inputFileSingle" },
            label && (React.createElement("label", null,
                label,
                isRequired && '*')),
            React.createElement("div", { className: "inputFile__item" },
                React.createElement("div", { className: "inputFile__infos" },
                    React.createElement("div", { className: "inputFile__infos--file" },
                        React.createElement("span", { className: "questionField__fileName" }, currentValue.url
                            ? React.createElement("a", { target: "_blank", href: currentValue.url }, currentValue === null || currentValue === void 0 ? void 0 : currentValue.fileName)
                            : currentValue === null || currentValue === void 0 ? void 0 : currentValue.fileName),
                        React.createElement("span", { onClick: () => {
                                if (onChange)
                                    onChange(undefined);
                            } },
                            React.createElement("i", { className: "far fa-trash-alt" }))))),
            errorMessage &&
                React.createElement(ErrorMessage, { errors: errorMessage, name: name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message))));
    }
    if (currentValue && Object.keys(currentValue).length > 0 && isFileRef(currentValue)) {
        return (React.createElement("div", { className: "input inputFileSingle" },
            label && (React.createElement("label", null,
                label,
                isRequired && '*')),
            React.createElement("div", { className: "inputFile__item" },
                React.createElement("div", { className: "inputFile__infos" },
                    React.createElement("div", { className: "inputFile__infos--file" },
                        React.createElement("span", { className: currentValue.url ? "inputFile__infos--fileName" : "", onClick: downloadFile }, currentValue.url
                            ? React.createElement("a", { target: "_blank", href: currentValue.url }, currentValue === null || currentValue === void 0 ? void 0 : currentValue.fileName)
                            : currentValue === null || currentValue === void 0 ? void 0 : currentValue.fileName),
                        React.createElement("span", { onClick: () => {
                                if (onChange)
                                    onChange(undefined);
                            } },
                            React.createElement("i", { className: "far fa-trash-alt" }))))),
            errorMessage &&
                React.createElement(ErrorMessage, { errors: errorMessage, name: name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message))));
    }
    return (React.createElement("div", { className: "input inputFileSingle" },
        label && (React.createElement("label", null,
            label,
            isRequired && '*')),
        React.createElement("div", { className: "inputFile__buttons" },
            React.createElement("label", { htmlFor: name }, labelButton),
            React.createElement("input", { type: "file", ref: reference, name: name, required: isRequired, onChange: onFileChange, accept: hasExtensions })),
        errorMessage &&
            React.createElement(ErrorMessage, { errors: errorMessage, name: name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message))));
};
export default InputFileSingle;
