import { useState } from "react";
function useBooleanState(defaultValue = false) {
    const [value, setValue] = useState(defaultValue);
    return [
        value,
        () => setValue(true),
        () => setValue(false)
    ];
}
export default useBooleanState;
