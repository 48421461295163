import {AnswerType, ConditionOperator, QuestionBlockDto} from "../../../../services/generated/FrontOffice-api";

export const fakeQuestionsData: QuestionBlockDto[] = [
    {
        id: "b1",
        title: { "fr": "Bloc 1", "en": "Block 1"},
        questions: [
			{
				answerType: AnswerType.Array,
				arrayItemsQuestions: [
					{
						answerMaxLength: 100,
						answerType: AnswerType.Text,
						id: "nom",
						isRequired: true,
						text: {fr: "Nom", en: "Nom"}
					},
					{
						answerMaxLength: 100,
						answerType: AnswerType.Text,
						id: "prenom",
						isRequired: true,
						text: {fr: "Prenom", en: "Prenom"}
					},
					{
						answerType: AnswerType.File,
						id: "cv",
						isRequired: false,
						text: {fr: "CV en pièce jointe", en: "CV en pièce jointe"}
					}
				],
				id: "compositeurs",
				isRequired: false
			},
            {
                id: "q1",
                text: { "fr": "texte", "en": "text" },
                isRequired: true,
                answerType: AnswerType.IntegerNumber,
                answerMinLength: 5,
                answerMaxLength: 20,
                presetAnswer: {
                    value: 8,
                    isModifiable: true
                }
            },
            // {
            //     id: "q2",
            //     text: { "fr": "texte multiligne", "en": "multiline text" },
            //     isRequired: true,
            //     answerType: AnswerType.MultilineText,
            //     answerMinLength: 5,
            //     answerMaxLength: 20,
            //     presetAnswer: {
            //         value: "hello world",
            //         isModifiable: true
            //     }
            // },
        //     {
        //         id: "q3",
        //         text: { "fr": "entier", "en": "integer" },
        //         isRequired: true,
        //         answerType: AnswerType.IntegerNumber,
        //         answerMinValue: 0,
        //         answerMaxValue: 200,
        //         presetAnswer: {
        //             value: 42,
        //             isModifiable: true
        //         }
        //     },
        //     {
        //         id: "q4",
        //         text: { "fr": "décimal", "en": "decimal" },
        //         isRequired: true,
        //         answerType: AnswerType.DecimalNumber,
        //         answerMinValue: 0.0,
        //         answerMaxValue: 200.0,
        //         presetAnswer: {
        //             value: 123.45,
        //             isModifiable: true
        //         }
        //     },
        //     {
        //         id: "q4",
        //         text: { "fr": "date", "en": "date" },
        //         isRequired: true,
        //         answerType: AnswerType.Date,
        //         answerMinValue: new Date(),
        //         answerMaxValue: (() => { const d = new Date(); d.setFullYear(d.getFullYear() + 1); return d; })()
        //     },
            {
                id: "q5",
                text: { "fr": "période", "en": "period" },
                isRequired: true,
                answerType: AnswerType.Period,
                answerMinValue: new Date(),
                answerMaxValue: (() => { const d = new Date(); d.setFullYear(d.getFullYear() + 1); return d; })()
            },
        //     {
        //         id: "q6",
        //         text: { "fr": "oui/non", "en": "yes/no" },
        //         isRequired: true,
        //         answerType: AnswerType.YesNo
        //     },
        //     {
        //         id: "q7",
        //         text: { "fr": "oui/non/ne sait pas", "en": "yes/no/don't know" },
        //         isRequired: true,
        //         answerType: AnswerType.YesNoDontKnow
        //     },
        //     {
        //         id: "q8",
        //         text: { "fr": "html", "en": "html" },
        //         isRequired: true,
        //         answerType: AnswerType.Html,
        //         answerMaxLength: 200,
        //         presetAnswer: {
        //             value: "<p><b>hello</b> <i>world</i></p>",
        //             isModifiable: true
        //         }
        //     },
        //     {
        //         id: "q9",
        //         text: { "fr": "liste de choix", "en": "choice list" },
        //         isRequired: true,
        //         answerType: AnswerType.SingleChoiceList,
        //         choices: [
        //             {
        //                 id: "choix1",
        //                 text: {fr: "blabla", en: "blabla"}
        //             },
        //             {
        //                 id: "choix2",
        //                 text: {fr: "blablabla", en: "blablabla"}
        //             },
        //             {
        //                 id: "none",
        //                 text: {fr: "Aucun", en: "None"},
        //                 isNone: true
        //             },
        //             {
        //                 id: "other",
        //                 text: {fr: "Autre", en: "Other"},
        //                 isOther: true
        //             }
        //         ]
        //     },
        //     {
        //         id: "q9-other",
        //         text: {fr: "Veuillez préciser", en: "Please specify"},
        //         answerType: AnswerType.Text,
        //         answerMaxLength: 50,
        //         conditions: [
        //             {
        //                 questionId: "q9",
        //                 operator: ConditionOperator.Equal,
        //                 operand: "other"
        //             }
        //         ]
        //     },
        //     {
        //         id: "q10",
        //         text: { "fr": "liste de choix multiple", "en": "multiple choice list" },
        //         isRequired: false,
        //         answerType: AnswerType.MultipleChoiceList,
        //         choices: [
        //             {
        //                 id: "choix1",
        //                 text: {fr: "blabla", en: "blabla"}
        //             },
        //             {
        //                 id: "choix2",
        //                 text: {fr: "blablabla", en: "blablabla"}
        //             },
        //             {
        //                 id: "none",
        //                 text: {fr: "Aucun", en: "None"},
        //                 isNone: true
        //             },
        //             {
        //                 id: "other",
        //                 text: {fr: "Autre", en: "Other"},
        //                 isOther: true
        //             }
        //         ]
        //     },
        //     {
        //         id: "q10-other",
        //         text: {fr: "Veuillez préciser", en: "Please specify"},
        //         answerType: AnswerType.Text,
        //         answerMaxLength: 50,
        //         conditions: [
        //             {
        //                 questionId: "q10",
        //                 operator: ConditionOperator.Equal,
        //                 operand: "other"
        //             }
        //         ]
        //     },
        //     {
        //         id: "q99",
        //         text: { "fr": "Oups, vous avez donné les mauvaises réponses", "en": "Oops, you gave the wrong answers" },
        //         answerType: AnswerType.Blocking
        //     }
        ]
    },
    // {
    //     id: "b2",
    //     title: { "fr": "Bloc 2", "en": "Block 2"},
    //     questions: [
    //         {
    //             id: "qb1",
    //             text: { "fr": "A", "en": "A" },
    //             isRequired: true,
    //             answerType: AnswerType.YesNo
    //         },
    //         {
    //             id: "qb2",
    //             text: { "fr": "B", "en": "B" },
    //             isRequired: true,
    //             answerType: AnswerType.YesNo,
    //             conditions: [
    //                 {
    //                     questionId: "qb1",
    //                     operator: ConditionOperator.Equal,
    //                     operand: true
    //                 }
    //             ]
    //         },
    //         {
    //             id: "qb3",
    //             text: { "fr": "C", "en": "C" },
    //             isRequired: true,
    //             answerType: AnswerType.YesNo,
    //             conditions: [
    //                 {
    //                     questionId: "qb2",
    //                     operator: ConditionOperator.Equal,
    //                     operand: true
    //                 }
    //             ]
    //         },
    //         {
    //             id: "qb4",
    //             text: { "fr": "D", "en": "D" },
    //             isRequired: true,
    //             answerType: AnswerType.YesNo,
    //             conditions: [
    //                 {
    //                     questionId: "qb3",
    //                     operator: ConditionOperator.Equal,
    //                     operand: true
    //                 }
    //             ]
    //         },
    //         {
    //             id: "qb5",
    //             text: { "fr": "E", "en": "E" },
    //             isRequired: true,
    //             answerType: AnswerType.YesNo,
    //             conditions: [
    //                 {
    //                     questionId: "qb2",
    //                     operator: ConditionOperator.Equal,
    //                     operand: true
    //                 }
    //             ]
    //         },
    //     ]
    // }
];