import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { modalSmallerCustomStyles } from '../../../../../constants/config.constant';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Loader from 'react-loader-spinner';

interface ModalDeleteTrajetProps {
	isOpen: boolean;
	onCancel: () => void;
	onValidate: () => void;
	isLoading?: boolean;
}

const ModalDeleteTrajet: React.FunctionComponent<ModalDeleteTrajetProps> = ({
	isOpen,
	onCancel,
	onValidate,
	isLoading
}) => {
	const { t } = useTranslation();

    useEffect(() => {
        Modal.setAppElement('body');
	}, []);

	return (
	<Modal
        isOpen={isOpen}
        style={modalSmallerCustomStyles}
    >
        <div className="modal__header">
            <h3>{t('createFolder.trajets.delete.title')}</h3>
        </div>
        <div className="modal__content">
			<div className="modal__item">
				<p>{t('createFolder.trajets.delete.content')}</p>
			</div>
        </div>
        <div className="modal__footer">
            <FormButton
                type="button"
                value={t('common.cancel')}
                onClick={() => onCancel()}
            />
            {isLoading
                ? <Loader type="TailSpin" width={35} height={35} color="#d93943" ></Loader>
                : <FormButton
                    type="submit"
                    value={t('common.delete')}
                    onClick={() => onValidate()}
                />}
        </div>
    </Modal>);
}

export default ModalDeleteTrajet;