import React from "react"
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";

const Login = () => {
    
    const { t } = useTranslation();

    return (
        <>
            <span>{t('signup.login.title')}</span>
            <Link to={"/"}>
                <h4>{t('signup.login.linkLabel')}</h4>
            </Link>
        </>
    )
}

export default Login;