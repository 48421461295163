import { RouteComponentProps } from '@reach/router';
import React from "react";
import { useTranslation } from 'react-i18next';
import Footer from '../../basics/Footer';
import NoFolder from "../../../assets/img/undraw-season-change-f-99-v.png";
import StructuresHeader from '../dossiers/StructuresHeader';

interface StructurePendingProps extends RouteComponentProps {
}

const StructurePending: React.FC<StructurePendingProps> = ({ }) => {
	const { t } = useTranslation();

	return (
		<div id="structure" className="structure__unauthorized">
			<div className="modal">
				<StructuresHeader isStructurePendingOrDisabled />
				<div className="structure__unauthorizedContent">
					<img src={NoFolder} className="unauthorizedImg" />
					<p>{t("unauthorized-page.pending.part1")}</p>
					<p>{t("unauthorized-page.pending.part2")} <b>{t("unauthorized-page.pending.part3")}</b></p>
				</div>
				<div className="structure__footer">
					<Footer />
				</div>
			</div>
		</div>
	);
};

export default StructurePending;