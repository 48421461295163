import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useAxios } from '../../../custom-hooks/useAxios';
import CollapsibleSection from 'adel-shared/dist/components/basics/CollapsibleSection';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { useTable, useSortBy } from 'react-table';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Modal from '../../basics/Modal';
import { DossierClient, TypePrestation, CreateLieuDto, LieuDto, LieuDateDto, LieuxDto, LieuValidationError, PaysClient, PaysDto, StructureClient, PrestationsViewModelDto } from '../../../services/generated/FrontOffice-api';
import { Dictionary } from '../../../models';
import moment from 'moment';
import { UpdateLieuToSend } from '../../../models/IDossier';
import LieuxDatesEdition, { EditLieuxDatesRef } from './lieuxDates/LieuxDatesEdition';
import { toast } from "react-toastify";
import DeleteImg from "../../../assets/img/undraw-throw-away-ldjd.png";
import ModalValidationLieuxDates from './lieuxDates/ModalValidationLieuxDates';
import {CategorieDossier, DossierView} from '../../../enums/Dossiers';
import { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import useValidation from '../../../custom-hooks/useValidation';
import { nomFrance } from '../../../constants/config.constant';

interface LieuxDatesProps extends RouteComponentProps {
	ref: any;
	path: string;
	dossierId: string;
	lieuxList: LieuxDto[];
	lieuxDatesEditMode: boolean;
	categorieDossierCode: string;
	setNewPrestation: any;
	setLieuxDatesEditMode: (value: boolean) => void;
	setIsFormValid: (value: boolean) => void;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
	isLoading: boolean;
	setModifyPrestation: any;
}

const LieuxDates: React.FunctionComponent<LieuxDatesProps> = forwardRef((props, ref) => {
	const { categorieDossierCode } = props
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const dossierClient = new DossierClient("", axiosInstance);
	const paysClient = new PaysClient("", axiosInstance);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [existingLieu, setExistingLieu] = useState<LieuDto>({});
	const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
	const [deleteLieu, setDeleteLieu] = useState<LieuDto>({});

	const [lieuxList, setLieuxList] = useState<LieuxDto[]>([]);
	const [modifyPrestation, setModifyPrestation] = useState<UpdateLieuToSend>({ lieu: {}, id: '' });
	const [franceByDefault, setFranceByDefault] = useState<boolean>(false);

	const [errorsValidation, setErrorsValidation] = useState<LieuValidationError[]>([]);
	const [openValidation, setOpenValidation] = useState<boolean>(false);

	const { getRootValidator } = useValidation();
	const prestationValidator = getRootValidator("CreateLieuDto");


	useEffect(() => {
		if (!props.lieuxDatesEditMode) props.setCanGoNext(true); // Si on n'est pas en mode Edition, on peut passer l'étape
	}, [props.lieuxDatesEditMode]);


	/** React table config */
	const columns = React.useMemo(() => {
		return [
			{
				Header: "Table",
				columns: [
					{
						Header: t("createFolder.form.pays"),
						accessor: "Pays",
						sortType: "basic"
					},
					{
						Header: t("createFolder.form.ville"),
						accessor: "Ville",
						sortType: "basic"
					},
					{
						Header: t("createFolder.form.lieuTitle"),
						accessor: "Lieu",
						sortType: "basic"
					},
					{
						Header: t("createFolder.form.dateSpectacle"),
						accessor: "DateSpectacle",
						sortType: "basic",
						hasEllipsis: true
					},
					{
						Header: t("createFolder.form.description"),
						accessor: "Description",
						sortType: "basic",
						hasEllipsis: true
					},
					{
						Header: "",
						accessor: "Actions",
						disableSortBy: true,
						Cell: (value: any) => (
							<span className="creationDossier__table-buttons">
								<div className="icon-button" onClick={() => editPrestation(value.cell.value)}><i className="far fa-edit"></i></div>
								<div className="icon-button" onClick={() => {setDeleteModalOpen(true); setDeleteLieu(value.cell.value); }}><i className="far fa-trash-alt"></i></div>
							</span>
						)
					},
					{
						Header: "Id",
						accessor: "Id"
					}
				]
			}
		];
	}, []);
	
	const [dataRepresentation, setDataRepresentation] = useState<Array<any>>([]);
	const [dataRepetition, setDataRepetition] = useState<Array<any>>([]);
	const [dataEnregistrement, setDataEnregistrement] = useState<Array<any>>([]);
	const [dataDiffusion, setDataDiffusion] = useState<Array<any>>([]);
	const [dataConcert, setDataConcert] = useState<Array<any>>([]);
	const tableRepresentation = useTable({ columns, data: dataRepresentation || [], initialState: { hiddenColumns: ["Id"] } }, useSortBy);
	const tableRepetition = useTable({ columns, data: dataRepetition || [], initialState: { hiddenColumns: ["Id"] } }, useSortBy);
	const tableEnregistrement = useTable({ columns, data: dataEnregistrement || [], initialState: { hiddenColumns: ["Id"] } }, useSortBy);
	const tableDiffusion = useTable({ columns, data: dataDiffusion || [], initialState: { hiddenColumns: ["Id"] } }, useSortBy);
	const tableConcert = useTable({ columns, data: dataConcert || [], initialState: { hiddenColumns: ["Id"] } }, useSortBy);
	/** end partie à factoriser */

	const tableDic: Dictionary<any> = {
		[TypePrestation.Representation]: { data: dataRepresentation, table: tableRepresentation },
		[TypePrestation.Repetition]: { data: dataRepetition, table: tableRepetition },
		[TypePrestation.Enregistrement]: { data: dataEnregistrement, table: tableEnregistrement },
		[TypePrestation.Diffusion]: { data: dataDiffusion, table: tableDiffusion },
		[TypePrestation.Concert]: { data: dataConcert, table: tableConcert },
	}

	const [prestationsViewModel, setPrestationsViewModel] = useState<PrestationsViewModelDto>({});

	const getTypePrestations = async () => {
		const result = await dossierClient.getPrestationsViewModel(props.dossierId);

		if(result.franceByDefault)
			setFranceByDefault(true);
		setPrestationsViewModel(result);
	}

	const setPrestationsTable = (type: TypePrestation, lieux: LieuDto[]) => {
		switch (type) {
			case TypePrestation.Representation:
				setDataRepresentation(setPrestationResult(lieux));
				break;
			case TypePrestation.Repetition:
				setDataRepetition(setPrestationResult(lieux));
				break;
			case TypePrestation.Enregistrement:
				setDataEnregistrement(setPrestationResult(lieux));
				break;
			case TypePrestation.Diffusion:
				setDataDiffusion(setPrestationResult(lieux));
				break;
			case TypePrestation.Concert:
				setDataConcert(setPrestationResult(lieux));
				break;
			default:
				break;
		}
	}
	
	const setPrestationResult = (lieux: LieuDto[]) => {
		let result: any = [];
		lieux.length > 0 && lieux
		// Tri des dates au sein du tableau de date de l'item
		.map((item:LieuDto) => {
			item.dates = item.dates.map(item => {return {...item, date: new Date(item.date)}}).sort((a:any,b:any) => {
				return (a.date - b.date);
			})
			return item;
		})
		// Tri des dates entre les items de la table
		.sort((a:any, b:any) => {
			return ((a && a.dates[0] && a.dates[0].date) - (b && b.dates[0] && b.dates[0].date));
		})
		.map(item => {
			result.push({
				Pays: item.pays?.nom,
				Ville: item.ville,
				Lieu: item.nomLieu,
				DateSpectacle: item.dates?.map(d => { return moment(d.date).format("DD/MM/YYYY")}).join(', '),
				Description: item.description,
				Actions: item,
				Id: item.id
			})
		})
		return result;
	}



	/** Next step  */
	const lieuxDatesEditRef: React.MutableRefObject<EditLieuxDatesRef> = useRef<EditLieuxDatesRef>(null);
	const [currentView, setCurrentView] = useState<DossierView>(DossierView.List);

	useEffect(() => {
		switch (currentView) {
			case DossierView.List:
				props.setLieuxDatesEditMode(false);
				break;
			case DossierView.Edit:
				props.setLieuxDatesEditMode(true);
				break;
		}
	}, [currentView]);

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			props.setIsLoading(true);

			switch (currentView) {
				case DossierView.List:
					//quand on appuit sur la boutton suivant appel de Artistes/validation. Si error on reste ici
					setErrorsValidation([]);
					try {
						let valid = await dossierClient.validatePrestations(props.dossierId);
						if (valid.errors && valid.errors.length > 0) {
							setErrorsValidation(valid.errors);
							setOpenValidation(true);
							props.setIsLoading(false);
							return false;
						} else {
							props.setIsLoading(false);
							return true;
						}
					} catch (error) {
						if (error.exception?.message)
							toast.error(error.exception.message);
						else
							toast.error(t("errors.default"));
						props.setIsLoading(false);
						return false;
					}
				case DossierView.Edit:
					if (lieuxDatesEditRef && lieuxDatesEditRef.current) {
						let result = await lieuxDatesEditRef.current.validateAndSend();
						props.setIsLoading(false);
						return result;
					} else { 
						toast.error("lieuxDatesEditRef undefined. Please contact your Dev Team.");
						props.setIsLoading(false);
					}
			}
			throw "currentView unknown. Please contact your Dev Team.";
		}
	}));



	const [hasDisableAdresse, setHasDisableAdresse] = useState<boolean>(false);
	useEffect(() => {
		if (props?.categorieDossierCode === CategorieDossier.DeplacementInternational) {
			setHasDisableAdresse(true);
		}
	}, [props.categorieDossierCode]);



	/** Get lieux */
	const getPrestations = useCallback(async () => {
		let result = await dossierClient.getLieux(props.dossierId);
		props.setIsLoading(true);
		try {
			result.length > 0 && setLieuxList(result);
			props.setIsLoading(false);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			props.setIsLoading(false);
		}
	}, [dossierClient, setLieuxList]);
	
	useEffect(() => {
		if (props.dossierId) {
			(async function () {
				await fetchPays();
				await getTypePrestations();
				await getPrestations();
			})();
		}
	}, [props.dossierId]);


	useEffect(() => {
		if(lieuxList.length === 0) {
			setDataRepresentation([]);
			setDataRepetition([]);
			setDataEnregistrement([]);
			setDataDiffusion([]);
			setDataConcert([]);
		} else {
			lieuxList.forEach(item => {
				setPrestationsTable(item.type, item.lieux);
			})
		}
	}, [lieuxList]);


	
	/** Get Pays Options */
	const [paysOptions, setPaysOptions] = useState<AdelOption<PaysDto>[]>([]);
	const fetchPays = async () => {
		let result = await paysClient.getPays();
		let array: AdelOption<any>[] = [];
		result.sort((a, b) => a.nom.localeCompare(b.nom)).forEach(function (element: PaysDto) {
			array.push({
				value: element.id,
				label: element.nom === "FRANCE" ? nomFrance : element.nom
			})
		});
		setPaysOptions(array);
	}



	/** Add */
	const addLieuDates = async (value:CreateLieuDto) => {
		props.setIsLoading(true);
		try {
			const array = lieuxList ?? [];
			const result = await dossierClient.createPrestation(props.dossierId, value);
			if (!array.some(x => x.type === result.typePrestation)) {
				array.push({
					type: result.typePrestation,
					lieux: [result]
				});
			} else {
				for (const idx in array) {
					if (result.typePrestation === array[idx].type)
						array[idx].lieux.push(result);
				}
			}
			setLieuxList([...array]);
			props.setIsLoading(false);
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			props.setIsLoading(false);
		}
	}



	/** Edit */
	const editLieuDates = async () => {
		props.setIsLoading(true);
		try {
			await dossierClient.updatePrestation(modifyPrestation.id, modifyPrestation.lieu);
			setLieuxList([]);
			await getPrestations();
			setModifyPrestation({ lieu: {}, id: '' });
			props.setIsLoading(false);

		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			props.setIsLoading(false);
		}
	}
	useEffect(() => {
		if (modifyPrestation && Object.keys(modifyPrestation.lieu).length !== 0) {
			(async function() {await editLieuDates()})();
		} 
	}, [modifyPrestation]);



	/** Edit mode setter field */
	const [existingDates, setExistingDates] = useState<LieuDateDto[]>([]);
	const [typeSelected, setTypeSelected] = useState<TypePrestation>();

	const editPrestation = (lieu: LieuDto) => {
		setCurrentView(DossierView.Edit);
		setIsEditMode(true);
		setTypeSelected(lieu.typePrestation);
		setExistingDates(lieu.dates);
		setExistingLieu(lieu);
	}



	/** Delete */
	const deletePrestation = async (lieu: LieuDto) => {
		props.setIsLoading(true);
		try {
			await dossierClient.deletePrestation(lieu.id);
			setLieuxList([]);
			setDeleteLieu({});
			setDeleteModalOpen(false);

			let array = lieuxList;
			for (let idx in array) {
				if (lieu.typePrestation === array[idx].type) {
					for (let i in array[idx].lieux) {
						if (lieu.id === array[idx].lieux[i].id)
							array[idx].lieux.splice(array[idx].lieux.indexOf(array[idx].lieux[i]), 1);
					}
				}
			}
			setLieuxList([...array]);
			props.setIsLoading(false);

			toast.success(t('common.delete-success'));
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			props.setIsLoading(false);
		}
	}


	return (<>
		{!props.lieuxDatesEditMode
			/********************************** VIEW MODE **********************************/
			? <div>
				<div className="creationDossier__header">
					<div>
						<h3 className="title--dark">{props?.categorieDossierCode === CategorieDossier.Fortissimo
							? t("createFolder.lieuxDates.titleSingle")
							: t("createFolder.lieuxDates.title")
						}</h3>
						<h4>{t("createFolder.lieuxDates.infos")}</h4>
					</div>
					<FormButton
						type="button"
						value={t("createFolder.lieuxDates.add")}
						onClick={() =>  setCurrentView(DossierView.Edit) /*props.setLieuxDatesEditMode(true)*/}
					/>
				</div>
				{prestationsViewModel.typesPrestation?.map((type, i) => (
					<section key={i} className="creationDossier__item--fullwidth">
						<CollapsibleSection title={t(`createFolder.lieuxDates.${type}`)}>
							<Table
								data={tableDic[type].data}
								getTableProps={tableDic[type].table.getTableProps}
								getTableBodyProps={tableDic[type].table.getTableBodyProps}
								headerGroups={tableDic[type].table.headerGroups}
								rows={tableDic[type].table.rows}
								prepareRow={tableDic[type].table.prepareRow}
								styles={styleTable.page}
							/>
						</CollapsibleSection>
					</section>
				))}
				<Modal
					isOpen={deleteModalOpen}
					setIsOpen={setDeleteModalOpen}
					title={t("createFolder.lieuxDates.delete.title")}
					validateLabel={t("common.delete")}
					cancelLabel={t("common.cancel")}
					onValidateChange={() => deletePrestation(deleteLieu)}
					img={DeleteImg}
					isLoading={props.isLoading}
				>
					<>{t("createFolder.lieuxDates.delete.content")}</>
				</Modal>
				{/********************************** VALIDATION **********************************/
					<ModalValidationLieuxDates
						errorsValidation={errorsValidation}
						isOpen={openValidation}
						onCancel={() => setOpenValidation(false)}
					/>
				}
			</div>

			/********************************** EDIT MODE **********************************/
			: <LieuxDatesEdition
				ref={lieuxDatesEditRef}
				lieuxDatesEditMode={props.lieuxDatesEditMode}
				franceByDefault={franceByDefault}
				hasDisableAdresse={hasDisableAdresse}
				setCurrentView={setCurrentView}
				setIsFormValid={props.setIsFormValid}
				categorieDossierCode={categorieDossierCode}
				addNewPrestation={(value) => {
					(async function() {await addLieuDates(value)})();
				}}
				setModifyPrestation={setModifyPrestation}
				prestationsViewModel={prestationsViewModel}
				existingLieu={existingLieu}
				setExistingLieu={setExistingLieu}
				existingDates={existingDates}
				setExistingDates={setExistingDates}
				typeSelected={typeSelected}
				setTypeSelected={setTypeSelected}
				isEditMode={isEditMode}
				setIsEditMode={setIsEditMode}
				paysOptions={paysOptions}
				prestationValidator={prestationValidator}
			/>
		} </>)
});

export default LieuxDates;
