import React from "react"
import Logo from "../../assets/logo/logo_spedidam_colored.png"

const ColoredLogo = () => {
    return(
        <img
            className="portal__item portal__logo"
            src={Logo}
            alt="Logo Spedidam"
        />
    )
}

export default ColoredLogo;