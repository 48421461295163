// https://github.com/reach/router/issues/185#issuecomment-588230307
import { useUserContext } from '../context/context-helpers';
import { Redirect, navigate } from '@reach/router';
import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const AuthenticatedRoute = ({ children }: React.ComponentProps<any>) => {
  const user = useUserContext();
  const { t } = useTranslation();
  if (!user || !user.id) {
    console.debug("no user or user.id found. Redirecting to login page");
    toast.error(t('errors.accessDenied'));
    navigate("/");
    return <></>;
  }
  return children
}