import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { RouteComponentProps } from '@reach/router';

import { useForm } from "react-hook-form";
import { useStepThreeContext } from "../../../context/context-helpers";
import { IStepThreeModel } from "../../../models/signup";
import useValidation from "../../../custom-hooks/useValidation";

import InputValidation from "../../basics/InputValidation";
import Input from "../../basics/Input";
import FormButton from  "adel-shared/dist/components/basics/FormButton"
import { AdresseClient, BanqueClient, GeoAdresseDto, StructureDetailsDto } from '../../../services/generated/FrontOffice-api';
import { useAxios } from '../../../custom-hooks/useAxios';
import { toast } from 'react-toastify';
import { AdelDossierClient, CreateOrUpdateDonneesBancairesDto } from '../../../clients/AdelDossierClient';
import InputFileSingle, { DocumentWithFile } from 'adel-shared/dist/components/basics/InputFileSingle';

interface BancaireEditProps extends RouteComponentProps {
    setEditInput: (value:boolean) => void;
    setIsIbanValid: (value:boolean) => void;
    isIbanValid: boolean;
    defaultValue?: StructureDetailsDto;
	setDefaultValue: (value:StructureDetailsDto) => void;
}

const BancaireEdit: React.FC<BancaireEditProps> = ({
	setEditInput,
	defaultValue,
	isIbanValid,
	setIsIbanValid,
	setDefaultValue
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDonneesLoading, setIsDonneesLoading] = useState<boolean>(false);
	const { t } = useTranslation();
	const axiosInstance = useAxios();
    const {
		register,
		errors,
		setValue,
		getValues,
		triggerValidation,
		watch
	} = useForm<IStepThreeModel>({ defaultValues: useStepThreeContext() });
	const adelDossierClient = new AdelDossierClient("", axiosInstance);
    const { getRootValidator } = useValidation();
    const adresseValidator = getRootValidator("CreateOrUpdateAdresseDto");
    const donneesBancairesValidator = getRootValidator("CreateOrUpdateDonneesBancairesDto");
	const [ribPJ, setRibPJ] = useState<DocumentWithFile>();
	const {pays, ville, codePostal} = watch(['pays', 'ville', 'codePostal']);

	useEffect(() => {
		if(defaultValue?.donneesBancaires) {
			setValue([
				{ iban: defaultValue.donneesBancaires.iban },
				{ banque: defaultValue.donneesBancaires.banque },
				{ bic: defaultValue.donneesBancaires.bic },
				{ line1: defaultValue.donneesBancaires.adresse?.line1 },
				{ line2: defaultValue.donneesBancaires.adresse?.line2 },
				{ codePostal: defaultValue.donneesBancaires.adresse?.codePostal },
				{ ville: defaultValue.donneesBancaires.adresse?.ville }
			]);

			if(defaultValue?.donneesBancaires?.rib) {
				setRibPJ({
					id: defaultValue.donneesBancaires.rib.id,
					url: defaultValue.donneesBancaires.rib.uri,
					fileName: defaultValue.donneesBancaires.rib.fileName
				});
			}
		}
	}, [defaultValue]);

	const handleFileChange = (file: DocumentWithFile) => {
		if(file) {
			setRibPJ({fileName: file.fileName, url:file.url, file: file.file});
		} else {
			setRibPJ(undefined);
			setValue([
				{ iban: null},
				{ banque: null },
				{ bic: null },
				{ line1: null },
				{ line2: null },
				{ codePostal: null },
				{ ville: null },
				{ pays: null }
			]);
			setIsIbanValid(false);
		}
	};

	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);

	const validateIBan = async () => {
		const client = new BanqueClient('', axiosInstance);
		const iban = getValues().iban.replace(/\s/g, "");
		setIsLoading(true);

		try {
			const result = await client.validateIban(iban)

			if (result === false) {
				setIsIbanValid(false);
				setIsLoading(false);
				toast.error(t("validation-messages.invalid-iban"))
				return false;
			} else {
				setIsIbanValid(true);
				setValue([
					{ iban: iban },
				]);
				setRibPJ(undefined);
				setIsLoading(false);
				return true;
			}
		}
		catch (error) {
			setIsIbanValid(false);
			setIsLoading(false);
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			return false;
		}
	}

	const invalidateIban = async () => {
		setIsIbanValid(false);
	}

	const searchAdresse = (input: string) => {
		if (!!input) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);
				adresseClient.searchAdresse(`${pays} ${ville} ${input}`, codePostal).then(results => {
					setGeoAdresse(results);
				});
				onAdresseChange(input);
			}
			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}
	const onAdresseChange = (label: string) => {
		if (!label) {
			setValue([
				{ line1: null },
				{ codePostal: null },
				{ ville: null }
			]);
			return;
		}

		const geoAdresseFiltered = geoAdresse.filter(adresse => adresse.label === label);

		if (geoAdresseFiltered.length > 0) {
			const selectedAdresse = geoAdresseFiltered[0];
			setValue([
				{ line1: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
				{ codePostal: selectedAdresse.codePostal },
				{ ville: selectedAdresse.ville }
			]);
		}
	}

	const send = async () => {
		const isValid = await triggerValidation();

		if(isValid) {
			const {iban, bic, banque, line1, line2, codePostal, ville } = getValues();
			let donneesBancairesValues: CreateOrUpdateDonneesBancairesDto = {
				iban,
				bic,
				banque,
				adresse: {
					line1,
					line2,
					codePostal,
					ville
				}
			}

			if(ribPJ?.id) {
				donneesBancairesValues = {...donneesBancairesValues, rib: {...donneesBancairesValues.rib, id: ribPJ.id }}
			}

			if(ribPJ?.file) {
				donneesBancairesValues = {...donneesBancairesValues, rib: {...donneesBancairesValues.rib, partName: "rib-PJ" }}
			}

			if (donneesBancairesValues) {
				setIsDonneesLoading(true);
				try {
					await adelDossierClient.createOrUpdateDonneesBancaires(donneesBancairesValues, ribPJ?.file && {"rib-PJ": ribPJ.file});
					setDefaultValue({...defaultValue, donneesBancaires: {...donneesBancairesValues, rib: {fileName:ribPJ.fileName, uri: ribPJ.url, id:ribPJ.id } }});
					setIsDonneesLoading(false);
					setEditInput(false);
					toast.success(t('myAccount.structureUpdateSuccess'));
				} catch (err) {
					setIsDonneesLoading(false);
					toast.error(t('myAccount.structureUpdateError'));
					
					if (err.exception?.message)
						toast.error(err.exception.message);
				}
			}
		}
	}
	
    return (
        <div>
            <InputValidation
                name="iban"
                reference={register(donneesBancairesValidator?.["Iban"])}
                label={t('signup.step-three.label')}
                placeHolder={t('signup.step-three.placeholder')}
                maxLength={34}
                buttonLabel={t('signup.step-three.validation-button')}
                onChange={invalidateIban}
                isLoading={isLoading}
                onValidate={validateIBan}
                validated={isIbanValid}
           		errors={errors}
            />
			{isIbanValid && 
			<div className="infosBancaires">
                <div className="infosBancaires__item">
                    <Input
                        name="bic"
                        reference={register(donneesBancairesValidator?.["Bic"])}
                        label={t('signup.step-three.bic-label')}
                        type="text"
                        placeHolder={t('signup.step-three.bic-placeholder')}
                        maxLength={11}
                        errors={errors}
                    />

                    <Input
                        name="banque"
                        reference={register(donneesBancairesValidator?.["Banque"])}
                        label={t('signup.step-three.banque-label')}
                        type="text"
                        placeHolder={t('signup.step-three.banque-placeholder')}
                        maxLength={60}
                        errors={errors}
                    />
                </div>

				<div className="infosBancaires__item">
					<Input
                        name="pays"
                        reference={register()}
                        label={t('signup.step-three.pays-label')}
                        type="text"
                        placeHolder={t('signup.step-three.pays-placeholder')}
                        maxLength={30}
                        errors={errors}
                    />
					<Input
                        name="ville"
                        reference={register(adresseValidator?.["Ville"])}
                        label={t('signup.step-three.ville-label')}
                        type="text"
                        placeHolder={t('signup.step-three.ville-placeholder')}
                        maxLength={60}
                        errors={errors}
                    />
				</div>

				<div className="infosBancaires__item">
                    <Input
                        name="codePostal"
                        reference={register(adresseValidator?.["CodePostal"])}
                        label={t('signup.step-three.codepostal-label')}
                        type="tel"
                        placeHolder={t('signup.step-three.codepostal-placeholder')}
                        maxLength={5}
                        errors={errors}
                    />
					<Input
                        name="line1"
                        reference={register(adresseValidator?.["Line1"])}
                        label={t('signup.step-three.adresse-label')}
                        type="text"
                        placeHolder={t('signup.step-three.adresse-placeholder')}
                        maxLength={60}
                        errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={_.debounce((value) => searchAdresse(value), 1000)}
                    />
                </div>

                <div className="infosBancaires__item">
					<Input
                        name="line2"
                        reference={register(adresseValidator?.["Line2"])}
                        label={t('signup.step-five.complementAdresse')}
                        type="text"
                        placeHolder={t('signup.step-three.adresse-placeholder')}
                        maxLength={60}
                        errors={errors}
                    />
					<InputFileSingle
						labelButton={t('signup.step-three.ribPJ-label')}
						onChange={(file:any) => handleFileChange(file)}
						isRequired={false}
						currentValue={ribPJ && {
							id: ribPJ.id,
							fileName: ribPJ.fileName || '',
							url: ribPJ.url
						}}
					/>
                </div>
            </div>}

            <div className="monCompte__footer">
				<FormButton
					type="button"
					value={t('common.cancel')}
					onClick={() => setEditInput(false)}
				/>
				<FormButton
					type="submit"
					value={t('common.validate')}
					onClick={send}
					disabled={!isIbanValid || isDonneesLoading}
				/>
			</div>
        </div>
    )
};

export default BancaireEdit;
