import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import TotalAmountsTable from "adel-shared/dist/components/basics/TotalAmountsTable";
import { formatNumber } from 'adel-shared/dist/utils/functions';
import BigNumber from 'bignumber.js';
import { useForm } from 'react-hook-form';
import {useTranslation} from "react-i18next";
import { toast } from 'react-toastify';
import {RouteComponentProps} from "@reach/router";
import { AdelDossierClient } from '../../../clients/AdelDossierClient';
import {useAxios} from "../../../custom-hooks/useAxios";
import { CategorieDossier } from '../../../enums/Dossiers';
import { CreateBudgetDto, UpdateBudgetDto } from '../../../models/IDossier';
import { IFileBudget } from '../../../models/IFile';
import {
	BudgetDto,
	BudgetViewModelDto,
	CategorieDossierDto,
	ChampDepense,
	ChampFinancement,
	DepenseDto,
	DossierClient,
	FinancementDto,
	TypeDepense,
	TypeFinancement,
	UpdateDepenseDto,
	UpdateFinancementDto
} from "../../../services/generated/FrontOffice-api";
import { calculDepensesBudget, calculFinancementsBudget } from '../../../utils/budgetFunctions';
import { convertIFilesBudgetToDocumentUploadInfoDto } from '../../../utils/functions';
import Textarea from '../../basics/Textarea';
import BudgetBlock from './budget/BudgetBlock';

interface BudgetProps extends RouteComponentProps {
    dossierId: string;
	categorieDossier: CategorieDossierDto;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
	ref: any;
}

export interface CommentChamp {
	id: string;
	comment: string;
}

export interface ConfirmationMontantChamp {
	id: string;
	confirmationMontant: boolean;
}

const Budget : React.FC<BudgetProps> = forwardRef(({
	dossierId,
	categorieDossier,
	setCanGoNext,
	setIsLoading
}, ref) => {
    const { t } = useTranslation();
    const axiosInstance = useAxios();
	const dossierClient = new DossierClient("", axiosInstance);
	const adelDossierClient = new AdelDossierClient("", axiosInstance);

	const form = useForm();
	const {getValues, setValue, triggerValidation, watch} = form;

	const watchCommandesMusicales = watch('depenses.budgetRepetitionsEtEnregistrements.commandesMusicales.montant') || 0;

	const baseCalculFields = {
		[`${CategorieDossier.DeplacementInternational}`]: [
			watch('depenses.fraisDeplacement.locationVoiture.montant') || 0
		],
		[`${CategorieDossier.AidePromotionImage}`]: [
			watch("depenses.fraisDeRealisationDuDocumentDePromotion.devisRealisation.montant") || 0,
			watch("depenses.budgetPrevisionnelDepenses.salairesArtisteRepetition.montant") || 0,
			watch("depenses.budgetPrevisionnelDepenses.salairesArtisteEnregistrement.montant") || 0,
			watch("depenses.budgetPrevisionnelDepenses.salairesArtisteRepresentation.montant") || 0,
			watch("depenses.budgetPrevisionnelDepenses.salairesArtisteConcert.montant") || 0,
			watch("depenses.budgetPrevisionnelDepenses.chargesSocialesPatronalesArtistes.montant") || 0,
			watch("depenses.budgetPrevisionnelDepenses.salairesArtisteMensualisation.montant") || 0,
			watch("depenses.budgetPrevisionnelDepenses.chargesPatronales.montant") || 0
		],
		[`${CategorieDossier.Fortissimo}`]: [
			watch('depenses.budgetPrevisionnelDepenses.salaireArtistes.montant') || 0,
			watch("depenses.budgetPrevisionnelDepenses.chargesSocialesPatronalesArtistes.montant") || 0,
			watch('depenses.autresDepenses.autresSalairesChargesSocialesIncluses.montant') || 0,
			watch('depenses.autresDepenses.realisationEtImpressionInvitations.montant') || 0,
			watch('depenses.autresDepenses.envoisEtInsertionsPresse.montant') || 0,
			watch('depenses.autresDepenses.deplacements.montant') || 0,
			watch('depenses.autresDepenses.defraiements.montant') || 0,
			watch('depenses.autresDepenses.fraisOrganisation.montant') || 0,
			watch('depenses.autresDepenses.divers.montant') || 0,
			watch('depenses.autresDepenses.sacem.montant') || 0
		],
		[`${CategorieDossier.BODramatiqueChoregraphique}`]: [
			watch('depenses.budgetRepetitionsEtEnregistrements.musiciens.montant') || 0,
			watch('depenses.budgetRepetitionsEtEnregistrements.chefsOrchestre.montant') || 0,
			watch('depenses.budgetRepetitionsEtEnregistrements.choristes.montant') || 0,
			watch('depenses.budgetRepetitionsEtEnregistrements.chanteurs.montant') || 0,
			watch('depenses.budgetRepetitionsEtEnregistrements.comediens.montant') || 0,
			watch('depenses.budgetRepetitionsEtEnregistrements.chargesPatronales.montant') || 0,
			watchCommandesMusicales > 3000 ? 3000 : watchCommandesMusicales,
			watch('depenses.budgetRepetitionsEtEnregistrements.agessa.montant') || 0
		]
	};

	const [isEditBudgetMode, setIsEditBudgetMode] = useState<boolean>(false);
	const [viewModel, setViewModel] = useState<BudgetViewModelDto>({});
	const [budget, setBudget] = useState<BudgetDto>({});
	const [budgetOldState, setBudgetOldState] = useState<BudgetDto>({}); // Pour savoir quels sont les champs à l'update qui ont été rajouté ou non

	const [commentsChamp, setCommentsChamp] = useState<CommentChamp[]>([]); // Les commentaires par champ
	const [confirmationMontantChamp, setConfirmationMontantChamp] = useState<ConfirmationMontantChamp[]>([]); // Les confirmations montant par champ
	const [defaultConfirmationMontantChamp, setDefaultConfirmationMontantChamp] = useState<ConfirmationMontantChamp[]>([]);

	const [commentGeneral, setCommentGeneral] = useState<string>(""); // Le commentaire général à la fin

	const [totalDepenses, setTotalDepenses] = useState<number>(0);
	const [totalFinancements, setTotalFinancements] = useState<number>(0);
	const [isBudgetValid, setIsBudgetValid] = useState<boolean>(true);

	const [files, setFiles] = useState<IFileBudget[]>([]);


    useEffect(() => {
        if (!dossierId) return;
		(async () => {
			await fetchViewModel();
			await fetchBudget();
		})();
	}, [dossierId]);

	useEffect(() => {
        if (viewModel.hasBalance && resultBalance() !== 0) {
			setCanGoNext(false);
		} else {
			setCanGoNext(true);
		}
	}, [totalFinancements, totalDepenses]);

	const fetchViewModel = async () => {
		const result = await dossierClient.getBudgetViewModel(dossierId);
		setViewModel(result);
	}
	const fetchBudget = async () => {
		const result = await dossierClient.getDossierBudget(dossierId);

		if (result.dossierId) {
			// Le budget récupéré de l'api qui ne sera pas modifié
			setBudgetOldState({...result, depenses: [...result.depenses], financements: [...result.financements], documents: [...result.documents]});
			setIsEditBudgetMode(true);
			setDefaultValues(result);
		} else {
			setBudget({dossierId:null});
			setIsEditBudgetMode(false);
		}
		getBalanceValues();
	}

	const getBalanceValues = () => {
		let values = getValues();
		setTotalDepenses(calculDepensesBudget(values));
		setTotalFinancements(calculFinancementsBudget(values));
	}

	const setDefaultValues = (result: BudgetDto) => {
		setBudget(result);

		/** Budget existant */
		let docs = [...files];
		let comms = [...commentsChamp];
		let conf = [...confirmationMontantChamp];

		// Setter le commentaire général
		setCommentGeneral(result.commentaire);

		// Setter les documents généraux
		if (result.documents) {
			result.documents.forEach((doc, i) => {
				docs.push({
					id: doc.id,
					fileName: doc.fileName,
					partName: 'generalFiles-' + (i+1)
				});
			})
		}

		result.depenses.forEach(item => {
			if (item.champ === ChampDepense.Autre) {
				setValue(`depenses.${item.type}.${item.champ}.montant.${item.id}`, Math.round(item.montant * 100) / 100);
				setValue(`depenses.${item.type}.${item.champ}.intitule.${item.id}`, item.intitule);
				item.commentaire && comms.push({id: `depenses.${item.type}.${item.champ}.montant.${item.id}`, comment: item.commentaire});
				item.confirmationMontant && conf.push({id: `depenses.${item.type}.${item.champ}.montant.${item.id}`, confirmationMontant: item.confirmationMontant});
			} else {
				setValue(`depenses.${item.type}.${item.champ}.montant`, Math.round(item.montant * 100) / 100);
				item.document && docs.push({...item.document, partName:`depenses.${item.type}.${item.champ}.montant` });
				item.commentaire && comms.push({id: `depenses.${item.type}.${item.champ}.montant`, comment: item.commentaire});
				item.confirmationMontant && conf.push({id: `depenses.${item.type}.${item.champ}.montant`, confirmationMontant: item.confirmationMontant});
			}
		});
		result.financements.forEach(item => {
			if (item.champ === ChampFinancement.Autre) {
				setValue(`financements.${item.type}.${item.champ}.montant.${item.id}`, item.montant);
				setValue(`financements.${item.type}.${item.champ}.intitule.${item.id}`, item.intitule);
				item.commentaire && comms.push({id: `financements.${item.type}.${item.champ}.montant.${item.id}`, comment: item.commentaire});
				item.confirmationMontant && conf.push({id: `financements.${item.type}.${item.champ}.montant.${item.id}`, confirmationMontant: item.confirmationMontant});
			} else {
				setValue(`financements.${item.type}.${item.champ}.montant`, item.montant);
				item.document && docs.push({...item.document, partName:`financements.${item.type}.${item.champ}.montant` });
				item.commentaire && comms.push({id: `financements.${item.type}.${item.champ}.montant`, comment: item.commentaire});
				item.confirmationMontant && conf.push({id: `financements.${item.type}.${item.champ}.montant`, confirmationMontant: item.confirmationMontant});
			}
		});

		setFiles(docs);
		setCommentsChamp(comms);
		setDefaultConfirmationMontantChamp(conf);
		setConfirmationMontantChamp(conf);
	}

    const createAllValues = (budget:CreateBudgetDto, values:any, comments:CommentChamp[], confirmations:ConfirmationMontantChamp[], autre: boolean) => {
		if (values) {
			for (const key in values) {
				const dataInput = key.split('.'); // Les infos des champs se trouvent dans le nom de l'input => "category"."type"."champ"."montant"
				// dataInput[0] => depenses ou financements
				// dataInput[1] => type
				// dataInput[2] => champ
				// dataInput[3] => montant ou intitulé
				// dataInput[4] => index (si champ autre)

				const commentsFiltered = comments.filter(c => c.id === key)[0];
				const confirmationFiltered = confirmations.filter(c => c.id === key)[0];
				/** Champs normaux */
				if (dataInput[2] !== ChampDepense.Autre && dataInput[2] !==ChampFinancement.Autre) {
					let dataToSend:any = {
						type: dataInput[1],
						champ: dataInput[2],
						montant: 0,
						intitule: "",
						commentaire: commentsFiltered ? commentsFiltered.comment : "",
						confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant
					}
					dataToSend = {...dataToSend,
						montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber()
					}

					if (files.filter(c => c.partName === key).length > 0) {
						dataToSend = {
							...dataToSend,
							document: convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0]
						}
					}

					if (dataInput[0] === "depenses") {
						budget.depenses.push(dataToSend as DepenseDto);
					} else {
						budget.financements.push(dataToSend as FinancementDto);
					}
				}

				/** Champs de type Autre */
				if (dataInput[2] === (ChampDepense.Autre || ChampFinancement.Autre) && dataInput[3] === "montant" && autre) {
					let dataToSend = {
						type: dataInput[1],
						montant: 0,
						intitule: values[key.replace('.montant.', '.intitule.')],
						commentaire: commentsFiltered ? commentsFiltered.comment : "",
						confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant
					}
					dataToSend = {...dataToSend,
						montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber()
					};

					if (dataInput[0] === "depenses") {
						budget.depenses.push({...dataToSend, champ: ChampDepense.Autre} as DepenseDto);
					} else {
						budget.financements.push({...dataToSend, champ: ChampFinancement.Autre} as FinancementDto);
					}
				}
			}
		}
	}

	/** Envoi de budget */
	const createBudget = async (budgetCreate:CreateBudgetDto, values:any, comments:CommentChamp[], confirmations:ConfirmationMontantChamp[]) => {

		createAllValues(budgetCreate, values, comments, confirmations, true)
		// commentaire general s'il y a
		budgetCreate = {
			...budgetCreate,
			commentaire: commentGeneral
		}
		// documents
		budgetCreate = {
			...budgetCreate,
			documents: convertIFilesBudgetToDocumentUploadInfoDto(files.filter(f => f.partName.indexOf('generalFiles-') !== -1))
		}

		await adelDossierClient.createBudget(dossierId, budgetCreate, files);
	}



	/** Update de budget */
	const updateBudget = async (values:any, comments:CommentChamp[], confirmations:ConfirmationMontantChamp[]) => {
		let budgetUpdate: UpdateBudgetDto = {
			commentaire: commentGeneral,
			documents: convertIFilesBudgetToDocumentUploadInfoDto(files.filter(f => f.partName.indexOf('generalFiles-') !== -1))
		};
		let depenseDatas: UpdateDepenseDto[] = [];
		let financementDatas: UpdateFinancementDto[] = [];
		let budgetCreate:UpdateBudgetDto = {
			depenses: [],
			financements: [],
			commentaire: "",
			documents: []
		}
		createAllValues(budgetCreate, values, comments, confirmations, false)
		budget.depenses.map(depense => {
			if(depense.champ === ChampDepense.Autre){
				budgetCreate.depenses.push(depense)
			}
		})
		budget.financements.map(financement => {
			if(financement.champ === ChampFinancement.Autre){
				budgetCreate.financements.push(financement)
			}
		})
		
		for (const key in values) {
			const dataInput = key.split('.'); // Les infos des champs se trouvent dans le nom de l'input => "category"."type"."champ"."montant"
			// dataInput[0] => depenses ou financements
			// dataInput[1] => type
			// dataInput[2] => champ
			// dataInput[3] => montant ou intitulé
			// dataInput[4] => ID (si champ autre)
            
			const commentsFiltered = comments.filter(c => c.id === key)[0];
			const confirmationFiltered = confirmations.filter(c => c.id === key)[0];
			
			/** Champs normaux */
			if (dataInput[2] !== ChampDepense.Autre && dataInput[2] !==ChampFinancement.Autre) {
				if (dataInput[0] === "depenses") {					
					if (budgetCreate.depenses.length > 0) {
						budgetCreate.depenses
						.filter(depense => dataInput[1] === depense.type && dataInput[2] === depense.champ)
						.forEach(depense => {
							let data:DepenseDto = {
								id: depense.id,
								intitule: depense.intitule,
								commentaire: commentsFiltered ? commentsFiltered.comment : "",
								confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
								type: dataInput[1] as TypeDepense,
								champ: dataInput[2] as ChampDepense,
								montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber()
							}
							if (convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0]) {
								data = {...data, document: convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0] }
							}
							depenseDatas.push(data);
						})
					} else {
						let data:DepenseDto = {
							commentaire: commentsFiltered ? commentsFiltered.comment : "",
							confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
							type: dataInput[1] as TypeDepense,
							champ: dataInput[2] as ChampDepense,
							montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber()
						}
						if (convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0]) {
							data = {...data, document: convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0] }
						}
						depenseDatas.push(data);
					}

				} else {
					if (budgetCreate.financements.length > 0) {
						budgetCreate.financements
						.filter(finance => dataInput[1] === finance.type && dataInput[2] === finance.champ)
						.forEach(financement => {
							let data:FinancementDto = {
								id: financement.id,
								intitule: financement.intitule,
								commentaire: commentsFiltered ? commentsFiltered.comment : "",
								confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
								type: dataInput[1] as TypeFinancement,
								champ: dataInput[2] as ChampFinancement,
								montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber()
							}
							if (convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0]) {
								data = {...data, document: convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0] }
							}
							financementDatas.push(data);
						})
					} else {
						let data:FinancementDto = {
							commentaire: commentsFiltered ? commentsFiltered.comment : "",
							confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
							type: dataInput[1] as TypeFinancement,
							champ: dataInput[2] as ChampFinancement,
							montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber()
						}
						if (convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0]) {
							data = {...data, document: convertIFilesBudgetToDocumentUploadInfoDto(files.filter(c => c.partName === key))[0] }
						}
						financementDatas.push(data);
					}
				}
			}

			/** Champs autre */
			if (dataInput[2] === (ChampDepense.Autre || ChampFinancement.Autre) && dataInput[3] === "montant") {
				if (dataInput[0] === "depenses") {
					budgetCreate.depenses
					.filter(depense => dataInput[4] === depense.id)
					.forEach(depense => {
						if (budgetOldState.depenses.filter(d => d.id === depense.id).length > 0) {
							depenseDatas.push({
								id: depense.id,
								intitule: values[key.replace('.montant.', '.intitule.')],
								commentaire: commentsFiltered ? commentsFiltered.comment : "",
								confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
								type: dataInput[1] as TypeDepense,
								champ: ChampDepense.Autre,
								montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber(),
							})
						} else {
							depenseDatas.push({
								intitule: values[key.replace('.montant.', '.intitule.')],
								commentaire: commentsFiltered ? commentsFiltered.comment : "",
								confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
								type: dataInput[1] as TypeDepense,
								champ: ChampDepense.Autre,
								montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber(),
							})
						}

					})
				} else {
					budgetCreate.financements
					.filter(finance => dataInput[4] === finance.id)
					.forEach(finance => {
						if (budgetOldState.financements.filter(f => f.id === finance.id).length > 0) {
							financementDatas.push({
								id: finance.id,
								intitule: values[key.replace('.montant.', '.intitule.')],
								commentaire: commentsFiltered ? commentsFiltered.comment : "",
								confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
								type: dataInput[1] as TypeFinancement,
								champ: ChampFinancement.Autre,
								montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber(),
							})
						} else {
							financementDatas.push({
								intitule: values[key.replace('.montant.', '.intitule.')],
								commentaire: commentsFiltered ? commentsFiltered.comment : "",
								confirmationMontant: confirmationFiltered && confirmationFiltered.confirmationMontant,
								type: dataInput[1] as TypeFinancement,
								champ: ChampFinancement.Autre,
								montant: !values[key] ? 0 : new BigNumber(values[key]).toNumber(),
							})
						}
					})
				}
			}
		}

		budgetUpdate = {...budgetUpdate,
			depenses: depenseDatas,
			financements: financementDatas
		}
		await adelDossierClient.updateBudget(dossierId, budgetUpdate, files);
	}



	/** VALIDATION */
    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
			const result = await triggerValidation();
			// Pour bloquer la validation si balance
			const isBalanceInvalid = viewModel.hasBalance && (resultBalance() !== 0);

			let values = getValues();
			let comments = [...commentsChamp];
			let confirmations = [...confirmationMontantChamp];

			/** Envoi de datas */
			if (result && !isBalanceInvalid && isBudgetValid) {
				setIsLoading(true);

				try {
					if (budget.dossierId === null && !isEditBudgetMode) {
						/** Création */
						let budgetCreate:CreateBudgetDto = {
							depenses: [],
							financements: [],
							commentaire: "",
							documents: []
						}
						await createBudget(budgetCreate, values, comments, confirmations);
						setIsLoading(false);
					} else {
						/** Edit */
						await updateBudget(values, comments, confirmations);
						setIsLoading(false);
					}
					return true;
				} catch(error) {
					if(error?.exception?.message) {
						toast.error(error.exception.message);
					} 
					else{
						const messagePt1 = t(`validation-messages.${error.code}`, {number: `${error.additionalDetails?.montant}`});
						const messagePt2 = error.additionalDetails?.champ ? t(`champFinancement.${error.additionalDetails?.champ}`) : '';
						toast.error(`${messagePt1} ${messagePt2}`);
					}

					setIsLoading(false);
					return false;
				}
			} else {
				toast.error(t("validation-messages.invalid-form"));
			}
            return false;
        }
	}));



	/** Documents */
	const handleFileChange = (value: React.ChangeEvent<HTMLInputElement>) => {
		if (value && value.target && value.target.files && value.target.files.length !== 0 && value.target.files[0].name !== "") {
			setFiles([...files,
				{
					file: value.target.files[0],
					fileName: value.target.files[0].name,
					partName: 'generalFiles-' + (files.length + 1)
				}]);
		} else {
			console.error("file added error");
		}
	}
	const deleteFile = (file: IFileBudget) => {
		if (files.length > 0) {
			let array = files.filter(f => f.partName !== file.partName);
			setFiles(array);
		}
	}



	/** Balance */
	const resultBalance = () => {
		let financement = new BigNumber(totalFinancements);
		let result = financement.minus(totalDepenses);
		return result.toNumber();
	}

	const getBaseCalculByCategorie = () => {
		if(baseCalculFields[categorieDossier.code]) {
			return baseCalculFields[categorieDossier.code].reduce((acc, curr) => parseFloat(curr) + parseFloat(acc));
		}

		return null;
	};

	const baseCalculCurrentCategorie = getBaseCalculByCategorie();

	return <div className="creationDossier__budget">
		<section>
			<div className="creationDossier__header">
				<h3 className="title--dark">{t("createFolder.budget.titles.budgetDepenses")}</h3>
			</div>
			{viewModel?.depenses?.map((itemViewModel, i) => (
				<div key={i}>
					<BudgetBlock
						isEditBudgetMode={isEditBudgetMode}
						form={form}
						showBaseCalcul={
							(
								categorieDossier.code === CategorieDossier.MusiqueFilm
							) && i === 0
						}
						baseCalcul={baseCalculCurrentCategorie}
						itemViewModel={itemViewModel}
						index={i}
						isDepense
						budget={budget}
						setBudget={setBudget}
						files={files}
						setFiles={setFiles}
						commentsChamp={commentsChamp}
						setCommentsChamp={setCommentsChamp}
						defaultConfirmationMontantChamp={defaultConfirmationMontantChamp}
						confirmationMontantChamp={confirmationMontantChamp}
						setConfirmationMontantChamp={setConfirmationMontantChamp}
						setTotalDepenses={setTotalDepenses}
						setTotalFinancements={setTotalFinancements}
						setIsBudgetValid={setIsBudgetValid}
						categorieDossier={categorieDossier}
						viewModel={viewModel}
					/>

					{(
						(
							itemViewModel.type === TypeDepense.BudgetPrevisionnelDepenses &&
							categorieDossier.code === CategorieDossier.AidePromotionImage
						) ||
						(
							itemViewModel.type === TypeDepense.AutresDepenses &&
							categorieDossier.code === CategorieDossier.Fortissimo
						) ||
						(
							itemViewModel.type === TypeDepense.BudgetRepetitionsEtEnregistrements &&
							categorieDossier.code === CategorieDossier.BODramatiqueChoregraphique
						)
					) && (
						<h4 className="creationDossier__budgetSubtitleTotal">
							{t("createFolder.budget.titles.baseCalculApi")}
							{formatNumber(baseCalculCurrentCategorie)} €
						</h4>
					)}
				</div>
			))}
		</section>
		<section>
			<div className="creationDossier__header">
				<h3 className="title--dark">{t("createFolder.budget.titles.budgetFinancement")}</h3>
			</div>
			{viewModel?.financements?.map((itemViewModel, index) => {
				const i = index + (viewModel?.depenses.length || 0 )
				return <div key={i}>
					<BudgetBlock
						isEditBudgetMode={isEditBudgetMode}
						form={form}
						itemViewModel={itemViewModel}
						index={i}
						baseCalcul={baseCalculCurrentCategorie}
						isDepense={false}
						budget={budget}
						files={files}
						setBudget={setBudget}
						setFiles={setFiles}
						commentsChamp={commentsChamp}
						setCommentsChamp={setCommentsChamp}
						defaultConfirmationMontantChamp={defaultConfirmationMontantChamp}
						confirmationMontantChamp={confirmationMontantChamp}
						setConfirmationMontantChamp={setConfirmationMontantChamp}
						setTotalDepenses={setTotalDepenses}
						setTotalFinancements={setTotalFinancements}
						setIsBudgetValid={setIsBudgetValid}
						categorieDossier={categorieDossier}
						viewModel={viewModel}
					/>
				</div>
			})}
		</section>
		{viewModel.hasBalance &&
			<section>
				<TotalAmountsTable
					data={[
						{
							label: t("createFolder.budget.titles.totalDepenses"),
							value: `${formatNumber(totalDepenses)} €`
						},
						{
							label: t("createFolder.budget.titles.totalFinancement"),
							value: `${formatNumber(totalFinancements)} €`
						},
						{
							label: t("createFolder.budget.titles.balance"),
							value: `${formatNumber(resultBalance())} €`
						}
					]}
				/>
			</section>
		}
		<section>
			<div className="creationDossier__header">
				<h4 className="title--red">{t("createFolder.budget.titles.infosComplementaires")}</h4>
			</div>
			<div className="creationDossier__item">
				<div className="creationDossier__row">
					<div className="input">
						<label>{t("createFolder.budget.titles.commentaireGeneral")}</label>
						<Textarea
							name="commentaireGeneral"
							value={commentGeneral}
							onChange={setCommentGeneral}
							maxLength={1500}
						/>
					</div>
				</div>
				<div className="creationDossier__row">
					<div className="input">
						<label className="label--withPadding">{t("createFolder.budget.titles.autreDocument")}</label>
						{files && files.length < 3 &&
							<div className="inputFile__buttons">
								<label htmlFor="uploadDocument">{t("common.download-file-single")}</label>
								<input
									type="file"
									id="uploadDocument"
									onChange={handleFileChange}
								/>
							</div>
						}
						{files && files.length > 0 && files.filter(f => f.partName.indexOf('generalFiles-') !== -1).length > 0 && (
							<div className="inputFile__infos--file">
								{files.filter(f => f.partName.indexOf('generalFiles-') !== -1).map((file, i) => (
									<span key={i} className="inputFile__infos--fileName">
											{file.fileName}
										<i className="far fa-trash-alt" onClick={() => deleteFile(file)}></i>
										{files.filter(f => f.partName.indexOf('generalFiles-') !== -1).length === 1
											|| files.filter(f => f.partName.indexOf('generalFiles-') !== -1).indexOf(file) === files.filter(f => f.partName.indexOf('generalFiles-') !== -1).length - 1
											? '' : ', '}
									</span>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
		{viewModel.hasBalance && (resultBalance() !== 0) &&
			<section>
				<div className="creationDossier__item">
					<div className="stats__error stats__error--right">{t("validation-messages.invalid-balance")}</div>
				</div>
			</section>
		}
    </div>;
});

export default Budget;