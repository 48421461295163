import React, { useState, useEffect, Fragment, useContext } from "react";

import { AuthenticatedRoute } from '../../containers/AuthenticatedRoute';
import Structures from '../../containers/structures/Structure';
import { RouteComponentProps, Router, Link, useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { StructureClient, StructureDetailsDto } from '../../services/generated/FrontOffice-api';
import { useAxios } from '../../custom-hooks/useAxios';
import { useMediaQuery } from 'react-responsive'

import Subtitles from '../basics/Subtitles';
import MonCompteStructure from './MonCompteStructure';
import MonCompteBancaire from './bancaire/MonCompteBancaire';
import MonCompteInfos from './MonCompteInfos';
import MonCompteAdministratif from './MonCompteAdministratif';
import MonCompteCorrespondant from './correspondant/MonCompteCorrespondant';
import MonCompteFichier from './MonCompteFichier';
import { toast } from "react-toastify";
import clsx from 'clsx';
import { AppStateContext } from '../../context/AppContext';
import { ActionTypeEnum } from '../../context/ActionType';

interface MonCompteProps extends RouteComponentProps {
}

export enum MonCompteRoutes {
	StructureJuridique = "Structure",
	InfoStructure = "InfoStructure",
	CorrespondantAdministratif = "Correspondant",
	Adresse = "Adresse",
	PiecesJointes = "PiecesJointes",
	InfosBanque = "InfosBanque"
}

const MonCompte: React.FunctionComponent<MonCompteProps> = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);
	const [structureDetails, setStructureDetails] = useState<StructureDetailsDto>({});
	const [initialStructureValues, setInitialStructureValues] = useState({});
	const [, dispatch] = useContext(AppStateContext);
  
	useEffect(() => {
		(async () => {
			try {
				const structureDetailsDto = await structureClient.getStructureDetails();
				setStructureDetails(structureDetailsDto);
				setInitialStructureValues({nom: structureDetailsDto.representantLegal.nom, prenom: structureDetailsDto.representantLegal.prenom, siret: structureDetailsDto.siret})
				dispatch({
					type: ActionTypeEnum.UPDATE_STRUCTURE,
					payload: structureDetailsDto
				});
			} catch (error) {
				toast.error("Une erreur est survenue durant la récupération du détail de la structure.");
				
				if (error.exception?.message)
					toast.error(error.exception.message);
				console.error(error);
			}
		})();
	}, []);


	const isMobile = useMediaQuery({
		query: '(max-device-width: 768px)'
	})

	const linkRoute = (route:string, label:string) => {
		return (
			<li>
				<Link to={route} className={clsx({'active': location.pathname === `/Compte/${route}` || location.pathname === `/Compte/${route}/edit`})}></Link>
				<div className="account__menuHeader">
					<div className="account__menuIcon">
						<span></span>
						<span></span>
					</div>
					<p>{label}</p>
				</div>
			</li>
		);
	}

	const pages = [
		{
			list: linkRoute(MonCompteRoutes.StructureJuridique, t("myAccount.structure")),
			panel: <Router>
				<MonCompteStructure
					ref={null}
					path={MonCompteRoutes.StructureJuridique}
					structureDetails={structureDetails}
					setStructureDetails={setStructureDetails}
				/>
			</Router>
		},
		{
			list: linkRoute(MonCompteRoutes.InfoStructure, t("myAccount.infoStructure")),
			panel: <Router>
				<MonCompteInfos
					path={MonCompteRoutes.InfoStructure}
				/>
			</Router>
		},
		{
			list: linkRoute(MonCompteRoutes.CorrespondantAdministratif, t("myAccount.correspondant")),
			panel: <Router>
				<MonCompteAdministratif
					structureDetails={structureDetails}
					setStructureDetails={setStructureDetails}
					path={MonCompteRoutes.CorrespondantAdministratif}
				/>
			</Router>
		},
		{
			list: linkRoute(MonCompteRoutes.Adresse, t("myAccount.adresse")),
			panel: <Router>
				<MonCompteCorrespondant
					path={MonCompteRoutes.Adresse}
				/>
			</Router>
		},
		{
			list: linkRoute(MonCompteRoutes.PiecesJointes, t("myAccount.piecesJointes")),
			panel: <Router>
				<MonCompteFichier
					path={MonCompteRoutes.PiecesJointes}
					structureDetails={structureDetails}
					setStructureDetails={setStructureDetails}
					initialStructureValues={initialStructureValues}
				/>
				<MonCompteFichier
					path={`${MonCompteRoutes.PiecesJointes}/edit`}
					structureDetails={structureDetails}
					setStructureDetails={setStructureDetails}
					initialStructureValues={initialStructureValues}
					edit
				/>
			</Router>
		},
		{
			list: linkRoute(MonCompteRoutes.InfosBanque, t("myAccount.infoBancaire")),
			panel: <Router>
				<MonCompteBancaire
					ref={null}
					path={MonCompteRoutes.InfosBanque}
					defaultValue={structureDetails}
					setStructureDetails={setStructureDetails}
				/>
			</Router>
		}

	]

	return (
		<AuthenticatedRoute>
			<Structures>
				<div className="monCompte">
					<span className="navigationFil">
						<Link to="/Structures">
							<span className="navigationFil__item">
								{t("navigation.folder")}
							</span>
						</Link>

						<span className="navigationFil__separator">|</span>

						<span className="navigationFil__item">
							{t("navigation.myAccount")}
						</span>
					</span>

					<Subtitles title={t("myAccount.title")} />

					{isMobile ? (
						<div className="monCompte__items">
							<div className="monCompte__menu">
								<ul>
									{pages.map((item, idx) => (
										<div className="account__menuItem" key={idx}>
											{item.list}
											{item.panel}
										</div>
									))}
								</ul>
							</div>
						</div>
					) : (
						<div className="monCompte__items">
							<div className="monCompte__menu">
								<ul>
									{pages.map((item, idx) => (
										<div className="account__menuItem" key={idx}>
											{item.list}
										</div>
									))}
								</ul>
							</div>

							<div className="account__form">
								{pages.map((item, i) => (
									<Fragment key={i}>
										{item.panel}
									</Fragment>
								))}
							</div>
						</div>
					)}
				</div>
			</Structures>
		</AuthenticatedRoute>
	);
};

export default MonCompte;