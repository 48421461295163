import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalValidateCancel from '../../../basics/ModalValidateCancel';

interface ModalDeleteEnseignantProps {
	isOpen: boolean;
	onCancel: () => void;
	onValidate: () => void;
	isLoading?: boolean;
}

const ModalDeleteEnseignant: React.SFC<ModalDeleteEnseignantProps> = ({
	isOpen,
	onCancel,
	onValidate,
	isLoading
}) => {

	const { t } = useTranslation();

	return (<ModalValidateCancel
		isOpen={isOpen}
		onCancel={onCancel}
		onValidate={onValidate}
		isLoading={isLoading}
		title={t("createFolder.enseignants.delete")}
	>
		<div className="modal__item">
			<p>{t("createFolder.enseignants.delete-confirm")}</p>
		</div>
	</ModalValidateCancel>);
}

export default ModalDeleteEnseignant;