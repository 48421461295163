import React, { ReactElement } from "react";

import StructuresHeader from "../../components/structures/dossiers/StructuresHeader";
import Footer from "../../components/basics/Footer";

interface StructuresProps {
	children: ReactElement<any>;
}

const Structures: React.FC<StructuresProps> = ({ children }) => {
	return (
		<div id="structure">
			<div className="modal">
                
				<StructuresHeader />

				{ React.cloneElement(children) }
				
				<div className="structure__footer">
					<Footer />
				</div>

			</div>
		</div>
	);
};

export default Structures;