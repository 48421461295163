import { Context } from "./context";
import SignupStep from "./SignupSteps";
import { Civilite, TypePoste } from '../services/generated/FrontOffice-api';

export const defaultContextValue: Context.IContext = {
	user: {},
	structure: {
		siret: ""
	},
	signupStepOne: {
		adresse: "",
		line2: "",
		codePostal: "",
		ville: "",
		email: "",
		civilite: Civilite.Monsieur,
		formeJuridique: {
			id: "",
			code: "",
			nom: ""
		},
		presidentPrenom: "",
		presidentNom: "",
		raisonSociale: "",
		siret: "",
		codeAPE: "",
		nomenclatureAPE: "",
		isValidated: false,
		formeJuridiqueReadOnly: true
	},
	signupStepTwo: {
	},
	signupStepThree: {
		banque: "",
		bic: "",
		codePostal: "",
		line1: "",
		line2: "",
		iban: "",
		pays: "",
		rue: "",
		ville: "",
		isValidated: false
	},
	signupStepFour: {
		hasCorrespondantParDefaut: true,
		civilite: Civilite.Monsieur,
		email: "",
		nom: "",
		poste: TypePoste.Administrateur,
		prenom: ""
	},
	signupStepFive: {
		hasAdresseParDefaut: true,
		codePostal: "",
		rue: "",
		complementAdresse: "",
		ville: "",
		codeLangue: "fr"
	},
	signupStepSix: {
		files: [],
	},
	signupStepSeven: {
		email: null,
		structureId: null,
		newEmail: null
	},
	signupHighestStep: SignupStep.One,
	formesJuridiques: [],
	error: {
		type: ""
	},
	canCreateStructure: false,
	creationDossierAnswers: {},
	creationDossierResponsable: {},
	dossierStates:{},
	currentEditingDossier: { id: "", nom: "", categorieId: "", categorieCode: "", numero: ""},
	dossierToDelete: {
		dossierId: "",
		isLoading: false
	},
	dossiersNeedRefresh: false,
	dossiers: {},
	noDossiersMessage: 'Aucun dossier pour le moment.',
	versementStates: {},
	currentEditingVersement: {
		id: "default",
		type: null,
		dossierId: "",
		originalDossierId: ""
	},
	pagesVitrine: []
};

export default function InitializeContextFromStorageOrDefault(): Context.IContext {
	if (!localStorage) return defaultContextValue;

	let contextJSON = localStorage.getItem('adelcontext');
	if (!contextJSON) return defaultContextValue;

	let context = (JSON.parse(contextJSON) as Context.IContext);
	if (!context) return defaultContextValue;

	return context;
}
