import Input from 'adel-shared/dist/components/basics/Input';
import InputRadioYesNo from 'adel-shared/dist/components/basics/InputRadioYesNo';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { useAxios } from '../../../../custom-hooks/useAxios';
import { Dictionary } from '../../../../models';
import { ArtisteClient, ArtisteDto, CreateArtisteDto, DossierClient, Emploi, InstrumentDto, UpdateArtisteDto } from '../../../../services/generated/FrontOffice-api';
import { SalarieFormMode } from '../SalarieArtistes';

export interface EditArtistesRef {
	validateAndSend: () => Promise<boolean>;
}

interface ArtistesProps {
	ref: any;
	setIsFormValid: (value: boolean) => void;
	closeView: () => void;
	currentFormMode: SalarieFormMode;
	dossierId?: string;
	artisteSelected?: ArtisteDto;
	artisteValidator: Dictionary<any>;
}

const Artistes: React.SFC<ArtistesProps> = forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const axiosInstance = useAxios();
	const dossierClient = useMemo(() => { return new DossierClient("", axiosInstance) }, [axiosInstance]);
	const artisteClient = useMemo(() => { return new ArtisteClient("", axiosInstance) }, [axiosInstance]);

	const { register, getValues, setValue, triggerValidation, errors, control } = useForm<any>({});

	const [showInstrument, setShowInstrument] = useState<boolean>(false);

	const [instrumentsOptions, setInstrumentsOptions] = useState<AdelOption<string>[]>([]);
	const [artistePrincipal, setArtistePrincipal] = useState<boolean>(false);
	const [hasArtistePrincipal, setHasArtistePrincipal] = useState<boolean>();

	const [errorArtistePrincipalTriggered, setErrorArtistePrincipalTriggered] = useState<boolean>(false);

	/** Emplois */
	const emploisOptions: AdelOption<Emploi>[] = useMemo(() => {
		let allOptions: AdelOption<Emploi>[] = [];
		for (let statut in Emploi) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: Emploi[statut as keyof typeof Emploi],
						label: t(`emplois.${Emploi[statut as keyof typeof Emploi]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);



	/** Instruments */
	const getArtistViewModel = async () => {
		try {
			let result = await dossierClient.getArtistesViewModel(props.dossierId);
			if (result && result.instruments) {
				let instruments = result.instruments.map((instrument: InstrumentDto) => {
					return {
						label: instrument.intitule[i18n.language] ?? "",
						value: instrument.id
					}
				});
				setInstrumentsOptions(instruments)
				setHasArtistePrincipal(result.artistePrincipalInfoRequired);
			}
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
	}



	/** Validation et envoi */
	useEffect(() => {
		props.setIsFormValid(true);
		(async () => {
			await getArtistViewModel();
		})();
	}, []);

	useEffect(() => {
		if (props.artisteSelected && instrumentsOptions) {
			switch (props.currentFormMode) {
				case SalarieFormMode.IS_EDIT:
					if(props.artisteSelected.emploi === Emploi.Musicien)
						setShowInstrument(true);
					setValue([
						{ nom: props.artisteSelected.nom },
						{ prenom: props.artisteSelected.prenom },
						{ email: props.artisteSelected.email },
						{ lieuHabitation: props.artisteSelected.lieuHabitation },
						{ emploi: props.artisteSelected.emploi },
						{ instrumentId: props.artisteSelected.instrument?.id },
						{ siteInternet: props.artisteSelected.siteInternet },
						{ formation: props.artisteSelected.formation },
						{ isGenerationSpedidam: props.artisteSelected.isGenerationSpedidam },
						{ isAssociated: props.artisteSelected.isAssociated },
						{ isTitulaire: props.artisteSelected.isTitulaire }
					]);
					setArtistePrincipal(props.artisteSelected.artistePrincipal);
					break;
				case SalarieFormMode.IS_DUPLICATE:
					if(props.artisteSelected.emploi === Emploi.Musicien)
						setShowInstrument(true);
					setValue([
						{ lieuHabitation: props.artisteSelected.lieuHabitation },
						{ emploi: props.artisteSelected.emploi },
						{ instrumentId: props.artisteSelected.instrument.id },
						{ siteInternet: props.artisteSelected.siteInternet },
						{ formation: props.artisteSelected.formation },
						{ isGenerationSpedidam: props.artisteSelected.isGenerationSpedidam },
						{ isAssociated: props.artisteSelected.isAssociated },
						{ isTitulaire: props.artisteSelected.isTitulaire }
					]);
					setArtistePrincipal(props.artisteSelected.artistePrincipal);
					break;
				default:
					break;
			}
		}
	}, [props.artisteSelected, instrumentsOptions]);

	useImperativeHandle(ref, () => ({
		async validateAndSend(): Promise<boolean> {
			const result = await triggerValidation();

			if (artistePrincipal === undefined) {
				setErrorArtistePrincipalTriggered(true);
			} else {
				setErrorArtistePrincipalTriggered(false);
			}

			if (result) {
				let { nom, prenom, email, lieuHabitation, emploi, instrumentId, siteInternet, formation, isGenerationSpedidam, isAssociated, isTitulaire } = getValues();

				let artiste: CreateArtisteDto | UpdateArtisteDto = {
					nom,
					prenom,
					email,
					lieuHabitation,
					emploi,
					siteInternet,
					formation,
					isGenerationSpedidam,
					isAssociated,
					isTitulaire
				}

				if (hasArtistePrincipal) {
					artiste.artistePrincipal = artistePrincipal;
				}

				if (showInstrument) {
					artiste.instrumentId = instrumentId;
				}

				try {
					if (props.currentFormMode === SalarieFormMode.IS_CREATION || props.currentFormMode === SalarieFormMode.IS_DUPLICATE) {
						await dossierClient.createArtiste(props.dossierId, artiste);
					} else if (props.currentFormMode === SalarieFormMode.IS_EDIT) {
						await artisteClient.updateArtiste(props.artisteSelected.id, artiste)
					}
					props.closeView();
					return true;
				} catch (error) {
					if (error.exception?.message)
						toast.error(error.exception.message);
					else 
						toast.error(t("errors.default"));
				}
			}
			return false;
		}
	}));


	return (<>
		<div className="creationDossier__header--alt">
			<div className="navigationFil" onClick={() => props.closeView()}>
				<span className="navigationFil__item">
					<i className="fas fa-chevron-left"></i>
					{t("createFolder.salarieArtistes.artistes.back")}
				</span>
			</div>
			<h3 className="title--dark">{t("createFolder.salarieArtistes.artistes.title")}</h3>
		</div>
		<div className="creationDossier__row">
			<Input name="nom"
				label={`${t('createFolder.contratsCachets.listColumns.nom')}`}
				type="text"
				reference={register(props.artisteValidator && props.artisteValidator["Nom"])}
				errors={errors}
			/>
			<Input name="prenom"
				label={`${t('createFolder.contratsCachets.listColumns.prenom')}`}
				type="text"
				reference={register(props.artisteValidator && props.artisteValidator["Prenom"])}
				errors={errors}
			/>
		</div>
		<div className="creationDossier__row">
			<Input name="email"
				label={`${t('createFolder.contratsCachets.listColumns.mail')}*`}
				type="text"
				reference={register(props.artisteValidator && props.artisteValidator["Email"])}
				errors={errors}
			/>
			<Input name="lieuHabitation"
				label={`${t('createFolder.contratsCachets.listColumns.lieuHabitation')}`}
				type="text"
				reference={register(props.artisteValidator && props.artisteValidator["LieuHabitation"])}
				errors={errors}
			/>
		</div>
		<div className="creationDossier__row">
			<Controller control={control}
				name="emploi"
				as={({ onChange, value, name }) => (
					<InputSelect<Emploi>
						name={name}
						label={t('createFolder.contratsCachets.listColumns.emploi')}
						classname="inputSelect"
						options={emploisOptions}
						errors={errors}
						onChange={(emploi) => {
							onChange(emploi);
							setShowInstrument(emploi === Emploi.Musicien);
						}}
						value={value}
						placeholder={t("common.select")}
					/>
				)}
				rules={props.artisteValidator && props.artisteValidator["Emploi"]}
			/>
			{showInstrument
				? <Controller control={control}
					name="instrumentId"
					as={({ onChange, value, name }) => (
						<InputSelect<string>
							name={name}
							label={`${t('createFolder.contratsCachets.listColumns.instrument')}*`}
							classname="inputSelect"
							options={instrumentsOptions}
							errors={errors}
							onChange={(instrumentId) => {
								onChange(instrumentId);
							}}
							value={value}
							placeholder={t("common.select")}
						/>
					)}
					rules={props.artisteValidator && props.artisteValidator["InstrumentId"]}
				/>
				: <div></div>
			}
		</div>
		<div className="creationDossier__row">
			{
				hasArtistePrincipal &&
				<InputRadioYesNo
					name="artistPrincipal"
					label={t('createFolder.contratsCachets.listColumns.artistPrincipal')}
					onChange={(value: boolean) => setArtistePrincipal(value)}
					value={artistePrincipal}
					errorMessage={errorArtistePrincipalTriggered && t('validation-messages.required')}
					radioLabels={[t("common.yes"), t("common.no")]}
				/>
			}
			<Input name="siteInternet"
				label={`${t('createFolder.contratsCachets.listColumns.siteWeb')}`}
				type="text"
				reference={register(props.artisteValidator && props.artisteValidator["SiteInternet"])}
				errors={errors}
			/>
		</div>
	</>)
});

export default Artistes;
