import React from "react";
import { Link } from "@reach/router";

interface StructuresFolderAddButtonProps {
	title?: string;
	colorInverted: boolean;
	pathLink?: string;
	onClick?: () => void;
}

const StructuresFolderAddButton: React.SFC<StructuresFolderAddButtonProps> = (props) => {
	return (
		<>
			{props.pathLink ?
				<Link to={`${props.pathLink}`} onClick={props.onClick}>
					<div className={props.colorInverted ? "folders__addButtonInverted" : "folders__addButton"}>
						<i className="far fa-plus"></i>
						<span>{props.title}</span>
					</div>
				</Link>
				:
				<div className={props.colorInverted ? "folders__addButtonInverted" : "folders__addButton"}>
					<i className="far fa-plus"></i>
					<span>{props.title}</span>
				</div>
			}
		</>
	)
}

export default StructuresFolderAddButton;