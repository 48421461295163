import Input from 'adel-shared/dist/components/basics/Input';
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { FormContextValues, ValidateResult, ValidationOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFileBudget } from '../../../../models/IFile';
import { DepenseViewModelDto, FinancementViewModelDto } from '../../../../services/generated/FrontOffice-api';
import Modal from '../../../basics/Modal';
import Textarea from '../../../basics/Textarea';
import { CommentChamp, ConfirmationMontantChamp } from '../Budget';

interface BudgetInputProps {
	item: DepenseViewModelDto|FinancementViewModelDto;
	montant: () => void;
	form: FormContextValues;
	id: string;
	name: string;
	label?: string;
	readOnly?: boolean;
	isTypeOther?: boolean;
	index?: number;
	isDepense: boolean;
	onCommentChange?: (id: string, value: any) => void;
	commentsChamp: CommentChamp[];
	files: IFileBudget[];
	setFiles: (value: IFileBudget[]) => void;
	defaultConfirmationMontantChamp: ConfirmationMontantChamp[];
	onConfirmationMontantChange: (id: string, value: boolean) => void;
	onValidation: (value: string|number, name: string, min?: number, max?: number) => string | boolean;
}

const BudgetInput: React.FunctionComponent<BudgetInputProps> = ({
	item,
	montant,
	form: {
		setValue,
		register,
		errors
	},
	id,
	name,
	label,
	readOnly,
	isTypeOther,
	index,
	isDepense,
	onCommentChange,
	commentsChamp,
	files,
	setFiles,
	defaultConfirmationMontantChamp,
	onConfirmationMontantChange,
	onValidation
}) => {
	const { t } = useTranslation();
	const [openComment, setOpenComment] = useState<boolean>(false);
	const [comment, setComment] = useState<string>("");
	const [confirmationMontant, setConfirmationMontant] = useState<boolean>(false);
	const [file, setFile] = useState<IFileBudget>();

	const idKeyOther = id !== "" ? name + ".montant." + id : name + ".montant." + index;
	const idKey = name + ".montant";
	const montantMin =  'montantMin' in item ? item.montantMin : 0;

	useEffect(() => {
		// Remplissage des infos venant de contrat cachet
		if (isDepense && (item as DepenseViewModelDto).montant !== undefined && (item as DepenseViewModelDto).montant >= 0) {
			setValue(name + ".montant", Math.round((item as DepenseViewModelDto).montant * 100) / 100)
		}
		// Remplissage des documents
		if (files && files.length > 0 && files.filter(doc => doc.partName === idKey)[0]) {
			setFile(files.filter(doc => doc.partName === idKey)[0]);
		}

	}, [files, isDepense]);


	useEffect(() => {
		initializeComment();
	}, [openComment]);


	/** Validation par champ */
	const customValidation: ValidationOptions = {
        validate: (data: number): ValidateResult => {
            const error = onValidation(data, name, montantMin, item.montantMax);
            if (error)
                return error;
            else
                return true;
		}
	};

	/** Commentaires */
	const addComment = () => {
		if (comment.length <= 30) {
			if (isTypeOther) {
				onCommentChange(idKeyOther, comment);
			} else {
				onCommentChange(idKey, comment);
			}
			setOpenComment(false);
		}
	}

	const cancelComment = () => {
		const commentChamp = [...commentsChamp];

		if (commentChamp.length > 0) {
			initializeComment();
		} else {
			setComment("");
		}
	}

	const initializeComment = () => {
		const commentChamp = [...commentsChamp];
		let data = "";
		const currentCommKeyOther = commentChamp.filter(x => x.id === idKeyOther)[0];
		const currentCommKey = commentChamp.filter(x => x.id === idKey)[0];

		if (commentChamp.length > 0) {
			if (isTypeOther) {
				if(currentCommKeyOther)
					data = currentCommKeyOther.comment
			} else {
				if(currentCommKey) {
					data = currentCommKey.comment;
				}
			}
			setComment(data);
		}
	}

	useEffect(() => {
		initializeConfirmation(defaultConfirmationMontantChamp);
	}, [defaultConfirmationMontantChamp]);

	const initializeConfirmation = (confChamp: ConfirmationMontantChamp[]) => {
		let bool = false;
		const currentConfKey = confChamp.find(x => x.id === idKey);
		const currentConfKeyOther = confChamp.find(x => x.id === idKeyOther);

		if (confChamp.length > 0) {
			if (isTypeOther) {
				if(currentConfKeyOther)
					bool = currentConfKeyOther.confirmationMontant
			} else {
				if(currentConfKey) {
					bool = currentConfKey.confirmationMontant;
				}
			}
		}
		setConfirmationMontant(bool);
	}

	const handleConfirmationMontant = () => {
		if (isTypeOther) {
			onConfirmationMontantChange(idKeyOther, !confirmationMontant);
		} else {
			onConfirmationMontantChange(idKey, !confirmationMontant);
		}
		setConfirmationMontant(!confirmationMontant);
	}


	/** Fichier */
	const addFile = (value: React.ChangeEvent<HTMLInputElement>) => {
		if (value && value.target && value.target.files && value.target.files.length !== 0 ) {
			setFile({
				file: value.target.files[0],
				fileName: value.target.files[0].name,
				partName: name + ".montant"
			});
			setFiles([...files, {
				file: value.target.files[0],
				fileName: value.target.files[0].name,
				partName: name + ".montant"
			}]);
		} else console.error(`Error adding file to : ${name}`)
	}

	const deleteFile = () => {
		const array = files.filter(f => f.partName !== file.partName)
		setFiles(array);
		setFile(null);
	}

	const commentChampFilteredOther = commentsChamp.filter(c => c.id === idKeyOther);
	const commentChampFiltered = commentsChamp.filter(c => c.id === idKey);

	return (<>
		<div className={`creationDossier__row row--flexEnd ${isTypeOther ? "row--flexDirectionRow" : ""}`}>
			<div className="creationDossier__rowItem creationDossier__rowItem--flex3">
				{!isTypeOther
					? <Input
						name={name + ".montant"}
						label={label}
						placeHolder="0.00 €"
						type="number"
						reference={register(customValidation)}
						readOnly={readOnly}
						errors={errors}
						min={montantMin}
						max={item.montantMax}
						onChange={montant}
					/>
					: <>
						<Input
							name={name + ".intitule." + (id !== "" ? id : index)}
							label={t("createFolder.budget.champCommon.intitule")}
							type="string"
							readOnly={readOnly}
							reference={register(customValidation)}
							errors={errors}
						/>
						<Input
							name={name + ".montant." + (id !== "" ? id : index) }
							label={t("createFolder.budget.champCommon.montant")}
							placeHolder="0.00 €"
							type="number"
							readOnly={readOnly}
							reference={register(customValidation)}
							errors={errors}
							min={montantMin}
							max={item.montantMax}
							onChange={montant}
						/>
					</>
				}
			</div>
			<div className="creationDossier__rowItem creationDossier__rowItem--flex1">
				<div className="folders__addButton" onClick={() => setOpenComment(true)}>
					{comment !== ""
							|| ((isTypeOther && commentChampFilteredOther.length > 0
								&& commentChampFilteredOther[0].comment !== "")
							|| (!isTypeOther && commentChampFiltered.length > 0
								&& commentChampFiltered[0].comment !== "")
						)
						? <><i className="far fa-comment" /><span>{t("createFolder.form.comment")}</span></>
						: <><i className="far fa-plus" /><span>{t("createFolder.form.addComment")}</span></>
					}
				</div>
			</div>
		</div>
		{item.needConfirmation &&
			<div className="creationDossier__checkbox">
				<input
					type="checkbox"
					checked={confirmationMontant}
					onChange={handleConfirmationMontant}
				/>
				<label>{t('createFolder.budget.champCommon.confirmation')}</label>
			</div>
		}
		{item.hasAttachment &&
			<div>
				{!file && (
					<div className="inputFile__buttons">
						<label htmlFor="uploadDocument">
							{t("createFolder.budget.champCommon.telechargement")}
						</label>
						<input
							type="file"
							id="uploadDocument"
							onChange={addFile}
						/>
					</div>
				)}
				{file && (
					<span className="inputFile__infos--fileName">
						{file.fileName}
						<i className="far fa-trash-alt" onClick={deleteFile}></i>
					</span>
				)}
			</div>
		}
		<Modal
			isOpen={openComment}
			setIsOpen={setOpenComment}
			title={t("createFolder.form.addComment")}
			validateLabel={t("common.add")}
			cancelLabel={t("common.cancel")}
			onValidateChange={addComment}
			onCancel={cancelComment}
		>
			<div className="modalComponentBody__content">
				<Textarea
					name="commentaires"
					value={comment}
					onChange={setComment}
				/>
				<div className={clsx("budgetInfo", {"budgetInfo--error": comment.length > 30})}>
					{comment.length}/30
				</div>
				{comment.length > 30 && (
					<span className="errorMessage">
						{t("validation-messages.max-length", { length: 30 })}
					</span>
				)}
			</div>
		</Modal>
	</>
	);
}

export default BudgetInput;