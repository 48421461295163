import React from 'react';
import InputReadOnly from '../InputReadOnly';
import { formatNumber } from '../../utils/functions';
const ChiffresCles = ({ chiffres }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'enseignants en CDI", content: `${formatNumber(chiffres.nombreEnseignantCDI) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'enseignants en CDD", content: `${formatNumber(chiffres.nombreEnseignantCDD) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'enseignants en CDII", content: `${formatNumber(chiffres.nombreEnseignantCDII) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre de musicien intermittent", content: `${formatNumber(chiffres.nombreMusicienIntermittent) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'employ\u00E9 administratif", content: `${formatNumber(chiffres.nombreEmployeAdministratif) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'employ\u00E9 administratif ETP", content: `${formatNumber(chiffres.nombreEmployeAdministratifETP) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre total d'enseignant", content: `${formatNumber(chiffres.nombreTotalEnseignant) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre total d'enseignant ETP", content: `${formatNumber(chiffres.nombreTotalEnseignantETP) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle pro", content: `${formatNumber(chiffres.nombreEtudiantCyclePro) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant AFDAS", content: `${formatNumber(chiffres.nombreEtudiantAFDAS) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant CNFPT", content: `${formatNumber(chiffres.nombreEtudiantCNFPT) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant CIF", content: `${formatNumber(chiffres.nombreEtudiantCIF) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en formation continue", content: `${formatNumber(chiffres.nombreEtudiantFormationContinue) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en formation initiale", content: `${formatNumber(chiffres.nombreEtudiantFormationInitiale) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9l\u00E8ve amateur", content: `${formatNumber(chiffres.nombreEleveAmateur) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle professionnel", content: `${formatNumber(chiffres.nombreEtudiantCycleProfessionnelCurrentYear) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle professionnel - N1", content: `${formatNumber(chiffres.nombreEtudiantCycleProfessionnelN1) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle professionnel - N2", content: `${formatNumber(chiffres.nombreEtudiantCycleProfessionnelN2) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle professionnel - N3", content: `${formatNumber(chiffres.nombreEtudiantCycleProfessionnelN3) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle professionnel - N4", content: `${formatNumber(chiffres.nombreEtudiantCycleProfessionnelN4) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" })),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle loisir", content: `${formatNumber(chiffres.nombreEtudiantCycleLoisirCurrentYear) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle loisir - N1", content: `${formatNumber(chiffres.nombreEtudiantCycleLoisirN1) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle loisir - N2", content: `${formatNumber(chiffres.nombreEtudiantCycleLoisirN2) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle loisir - N3", content: `${formatNumber(chiffres.nombreEtudiantCycleLoisirN3) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Nombre d'\u00E9tudiant en cycle loisir - N4", content: `${formatNumber(chiffres.nombreEtudiantCycleLoisirN4) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" })),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Chiffre d'affaire", content: `${formatNumber(chiffres.chiffreAffaire) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Chiffre d'affaire - N1", content: `${formatNumber(chiffres.chiffreAffaireN1) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Chiffre d'affaire - N2", content: `${formatNumber(chiffres.chiffreAffaireN2) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Chiffre d'affaire - N3", content: `${formatNumber(chiffres.chiffreAffaireN3) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Chiffre d'affaire - N4", content: `${formatNumber(chiffres.chiffreAffaireN4) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" })),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Salaires", content: `${formatNumber(chiffres.salaires) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Salaires - N1", content: `${formatNumber(chiffres.salairesN1) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Salaires - N2", content: `${formatNumber(chiffres.salairesN2) || '-'}` })))),
        React.createElement("div", { className: "detailsTab__row" },
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Salaires - N3", content: `${formatNumber(chiffres.salairesN3) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" },
                React.createElement("div", { className: "detailsTab__content" },
                    React.createElement(InputReadOnly, { label: "Salaires - N4", content: `${formatNumber(chiffres.salairesN4) || '-'}` }))),
            React.createElement("div", { className: "detailsTab__column" }))));
};
export default ChiffresCles;
