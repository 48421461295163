export var CategorieDossier;
(function (CategorieDossier) {
    CategorieDossier["AidePromotionImage"] = "PROMO";
    CategorieDossier["DeplacementInternational"] = "INT";
    CategorieDossier["BODramatiqueChoregraphique"] = "BO";
    CategorieDossier["SpectacleDramatiqueChoregraphiqueCirque"] = "DRAMA";
    CategorieDossier["SpectacleMusical"] = "MUSICAL";
    CategorieDossier["Festival"] = "FEST";
    CategorieDossier["MusiqueFilm"] = "FILM";
    CategorieDossier["Ecole"] = "ECOLE";
    CategorieDossier["Fortissimo"] = "FORTI";
    CategorieDossier["InteretGeneral"] = "INTGEN";
    CategorieDossier["SoireeArtistes"] = "SOIRART";
    CategorieDossier["AideSecretariatArtiste"] = "ASA";
    CategorieDossier["DiffusionLieu"] = "DIFFLIEU";
    CategorieDossier["AideResidences"] = "AIDERES";
    CategorieDossier["DiffusionAvignon"] = "DIFFAVIG";
    CategorieDossier["AideRemunerationArtiste"] = "AIDEREMU";
    CategorieDossier["AideSpecifique"] = "AIDESPE";
    CategorieDossier["DiffusionSpectacleVivantLabelisationReseauSPEDIDAM1"] = "DIFFSPLAB1";
    CategorieDossier["DiffusionSpectacleVivantLabelisationReseauSPEDIDAM2"] = "DIFFSPLAB2";
    CategorieDossier["CreationAideSalons"] = "CREAIDESAL";
    CategorieDossier["CreationSoutienFestival"] = "CREASTFEST";
    CategorieDossier["EducationArtistiqueCulturelleAideActionsEducation"] = "EACAAEAC";
    CategorieDossier["DiffusionSpectacleVivantRemisePrixArtistique"] = "DSVRPADM";
    CategorieDossier["DiffusionSpectacleVivantRemiseEmissionTeleviseeMusicale"] = "TVMUSIC";
})(CategorieDossier || (CategorieDossier = {}));
