import React, { useState } from 'react';
import { Link, RouteComponentProps, useParams } from '@reach/router';
import StructuresHeader from "../../components/structures/dossiers/StructuresHeader";
import Footer from "../../components/basics/Footer";
import { useEffect } from 'react';
import {
    CreateOrUpdateResponsableDossierDto,
    CurrentDossierInfoDto,
    DossierClient,
    DossierDetailsDto,
    StatutDossier,
    StructureClient,
	DossierInfosProjetViewModelDto,
	DescriptionProjetViewModelDto
} from '../../services/generated/FrontOffice-api';
import { useAxios } from '../../custom-hooks/useAxios';
import { useTranslation } from 'react-i18next';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useForm } from 'react-hook-form';
import InfosResponsableDossier from "./InfosResponsableDossier";
import i18next from 'i18next';
import DetailsDossierContent from './detailsDossier/DetailsDossierContent';
import ModalValidateCancel from '../basics/ModalValidateCancel';
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';

interface DetailsDossierProps extends RouteComponentProps {
}

const DetailsDossier: React.FunctionComponent<DetailsDossierProps> = () => {
	const { t } = useTranslation();
    const axiosInstance = useAxios();
    const structureClient = new StructureClient("", axiosInstance);
    const dossierClient = new DossierClient("", axiosInstance);
    const location = useParams();
	const dossierId = location.dossierId;
	const [infoDossier, setInfoDossier] = useState<CurrentDossierInfoDto>({});
    const [isRenoncerLoading, setIsRenoncerLoading] = useState<string>('start');
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
    const responsableForm = useForm<CreateOrUpdateResponsableDossierDto>({});
    const [dossierDetails, setDossierDetails] = useState<DossierDetailsDto>({});
    const [descrProj, setDescrProj] = useState<DescriptionProjetViewModelDto>();
    const [infosProjet, setInfosProjet] = useState<DossierInfosProjetViewModelDto>();
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    useEffect(() => {
        (async() => {
            const resultDetails = await dossierClient.getDossierDetails(dossierId);
            setDossierDetails(resultDetails);

            const responsableDossier = resultDetails.responsable || {};

			const resultInfosDossier = await dossierClient.getCurrentDossierInfo(dossierId);
            setInfoDossier(resultInfosDossier);

			if(!Object.keys(responsableDossier).length) {
                const resultResponsable = await structureClient.getStructureCorrespondantAdministratif();
                responsableForm.setValue(Object.entries(resultResponsable).map(([key, value]) => ({
                    [key]: value
                })));
            } else {
                responsableForm.setValue(Object.entries(responsableDossier).map(([key, value]) => ({
                    [key]: value
                })));
            }

			const resultProjet = await dossierClient.getInfosProjetViewModel(dossierId);
			setInfosProjet(resultProjet);
			const resulDescr = await dossierClient.getDescriptionProjetViewModel(dossierId);
			setDescrProj(resulDescr);
        })();
    }, []);
    const handleSubmit = async() => {
        if ((!responsableForm.getValues().telephoneFixe && !responsableForm.getValues().telephonePortable)) {
            toast.error(t('detailsFolder.structurePresident.president') + ': ' + t('errors.telephone'));
            return;
        }
        const isValid = await responsableForm.triggerValidation();
        if(!isValid) {
			toast.error(t('common.validation-error.message'));
            return;
        }
        setIsSubmitLoading(true);
        try {
            await dossierClient.setResponsableDossier(dossierId, responsableForm.getValues());
            toast.success(t('createFolder.responsableDossier.success'));
        } catch(error) {
            if(error.code && i18next.exists(`createFolder.responsableDossier.errors.${error.code}`)) {
                toast.error(t(`createFolder.responsableDossier.errors.${error.code}`));
            } else if(error.exception?.message) {
                toast.error(error.exception.message);
			} else {
                toast.error(t('createFolder.responsableDossier.errors.default'));
            }
        }
        setIsSubmitLoading(false);
    };

    const handleRenoncerDossierClick = async() => {
        setIsRenoncerLoading('idle');
        closeModal()
        try {
            await dossierClient.renonciationDossier(dossierId);
            toast.success(t('detailsFolder.renonciation.success'));
            setIsRenoncerLoading('close');
        } catch(error) {
            toast.error(t('detailsFolder.renonciation.error'));
            setIsRenoncerLoading('start');
        }
       
    };

    const handlePrintClick = () => {
        window.print();
    };

    return (
        <div id="structure">
            <div className="modal">
				<StructuresHeader />
                <div className="detailsDossier">
                    <span className="navigationFil">
						<Link to="/Structures">
							<span className="navigationFil__item">
								{t("navigation.folder")}
							</span>
						</Link>

						<span className="navigationFil__separator">|</span>

						<span className="navigationFil__item">
                            {t('detailsFolder.title')} {infoDossier.dossierNom}
						</span>
					</span>

                    <h2 className="modal__title">
                        {t('detailsFolder.title')} {infoDossier.dossierNom}
                    </h2>

                    <div className="detailsDossier__infoDossier">
                        {(infoDossier.commissionNom && infoDossier.categorieNom) && (
                            <>
                                {infoDossier.commissionNom} - {t("createFolder.form.categorie")} {infoDossier.categorieNom}
                            </>
                        )}
                    </div>

                    <h3>{t('detailsFolder.structurePresident.title')}</h3>

                    <div className="creationDossier__content">
                        <InfosResponsableDossier form={responsableForm} />
                    </div>

					<DetailsDossierContent dossierDetails={dossierDetails} descrProj={descrProj} infosProjet={infosProjet} />

                    <div className="detailsDossier__footer">
                        {(StatutDossier[infoDossier.dossierStatut as keyof typeof StatutDossier] === StatutDossier.Accepte) && isRenoncerLoading !== 'close' && (
                            <div className="detailsDossier__btn">
                                {isRenoncerLoading === 'idle' ? (
                                    <Loader type="TailSpin" width={35} height={35} color="#d93943"></Loader>
                                ) : (
                                    <FormButton
                                        type="button"
                                        value={t('detailsFolder.renonciation.button')}
                                        onClick={openModal}
                                    />
                                )}
                            </div>
                        )}

                        <div className="detailsDossier__btn">
                            {isSubmitLoading ? (
                                <Loader type="TailSpin" width={35} height={35} color="#d93943"></Loader>
                            ) : (
                                <FormButton
                                    type="button"
                                    value={t('common.validate')}
                                    onClick={handleSubmit}
                                />
                            )}
                        </div>
                    </div>
                </div>
                    <ModalValidateCancel
                    isOpen={isModalOpen}
                    onCancel={closeModal}
                    onValidate={handleRenoncerDossierClick}
                    validateButtonLabel={'Valider'}
                    isLoading={isRenoncerLoading === 'idle'}
                    >
                        <div className="modal__item">
                            <p>{t('structure.dossiers.renonciation')}</p>
                        </div>
                    </ModalValidateCancel>
                <div className="structure__footer">
					<Footer />
				</div>
            </div>
        </div>
    );
};

export default DetailsDossier;