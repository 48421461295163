import React, { FunctionComponent, useState } from "react"
import { TypeDeDocumentDto, DocumentDto } from "../../../services/generated/FrontOffice-api";
import InputDocument from './InputDocument';
import InputSelectDocument from './InputSelectDocument';
import { Dictionary } from "adel-shared/dist/models";
import { FormContextValues } from "react-hook-form";

interface InputDocumentsProps {
	// New way of handling documents
	typeDeDocuments: TypeDeDocumentDto[];
	documents?: DocumentDto[];
	onDocumentChange?: (document: DocumentDto, file?: File) => void;
	form: FormContextValues;
	setIsSelectionEmpty?: (value:boolean) => void;
	onReset: (fieldName: string) => void;
	// Legacy way of handling documents
	defaultSelections?: Dictionary<TypeDeDocumentDto>;
	defaultDates?: Dictionary<Date>;
	defaultFiles?: Dictionary<File>;
	defaultValues?: Dictionary<Dictionary<string>>;
	onSelect?: (document: TypeDeDocumentDto, documentCode: string) => void;
	onDateChange?: (date: Date, documentCode: string) => void;
	onFileChange?: (file: File, documentCode: string) => void;
	onValueChange?: (value: string, documentCode: string, extraInfoCode: string) => void;
	isVersement?: boolean;
}

const InputDocuments: FunctionComponent<InputDocumentsProps> = ({
	documents,
	onDocumentChange,
	typeDeDocuments,
	defaultSelections,
	defaultDates,
	defaultFiles,
	defaultValues,
	onSelect,
	setIsSelectionEmpty,
	onDateChange,
	onFileChange,
	onValueChange,
	form,
	isVersement = false,
	onReset
}) => {
	const [selection, setSelection] = useState<TypeDeDocumentDto>();
	return (
		<>
			{typeDeDocuments?.map(typeDeDocument => {
				// Find corresponding document
				let existingDocument: DocumentDto = null;
				if (documents) {
					if (typeDeDocument.hasChoix) {
						existingDocument = documents.find(document => typeDeDocument.choix.some(choix => document.type.id === choix.id));
					} else {
						existingDocument = documents
						.find(document => document.type?.id === typeDeDocument.id);
					}
				}

				if (typeDeDocument.choix?.length) {
					return (
						<div key={typeDeDocument.id}>
							<InputSelectDocument
								selection={selection}
								setSelection={setSelection}
								setIsSelectionEmpty={setIsSelectionEmpty}
								typeDeDocument={typeDeDocument}
								document={existingDocument}
								onDocumentChange={onDocumentChange}
								form={form}
								onReset={onReset}
								defaultSelection={defaultSelections && defaultSelections[typeDeDocument.code]}
								defaultDates={defaultDates}
								defaultFiles={defaultFiles}
								defaultValues={defaultValues}
								isVersement={isVersement}
								onDateChange={(date, documentCode) => { onDateChange && onDateChange(date, typeDeDocument.code) }}
								onFileChange={(file) => onFileChange && onFileChange(file, typeDeDocument.code)}
								onValueChange={(selectedDocumentCode, extraInfoCode, value) => onValueChange && onValueChange(value, selectedDocumentCode, extraInfoCode)}
								onDocumentSelected={(selectedDocument) => { onSelect && onSelect(selectedDocument, typeDeDocument.code) }}
							/>
						</div>
					);
				}

				return (
					<div key={typeDeDocument.id}>
						<InputDocument
							typeDeDocument={typeDeDocument}
							document={existingDocument}
							onDocumentChange={onDocumentChange}
							defaultDate={defaultDates?.[typeDeDocument.code]}
							defaultFile={defaultFiles?.[typeDeDocument.code]}
							defaultValues={defaultValues?.[typeDeDocument.code]}
							onDateChange={(date) => { onDateChange && onDateChange(date, typeDeDocument.code) }}
							onFileChange={(file) => onFileChange && onFileChange(file, typeDeDocument.code)}
							onValueChange={(extraInfoCode, value) => onValueChange && onValueChange(value, typeDeDocument.code, extraInfoCode)}
							onReset={() => { onReset(typeDeDocument.code) }}
							form={form}
							isVersement={isVersement}
						/>
					</div>
				);
			})}
		</>
	)
}

export default InputDocuments;