import React, { useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { Jodit } from "jodit";
const WysiwygEditor = ({ editorValueContent, setEditorValueContent, isReadonly, maxLength, customButtons = [] }) => {
    const [options, setOptions] = useState({});
    useEffect(() => {
        const config = Jodit.defaultOptions;
        config.language = "fr";
        config.buttons = [
            'bold',
            'strikethrough',
            'underline',
            'italic',
            '|',
            'ul',
            'ol',
            'outdent',
            'indent',
            'undo',
            'redo',
            '|',
            'brush',
            'paragraph',
            '|',
            'table',
            'link',
            'align'
        ];
        config.buttons = config.buttons.concat(customButtons);
        config.readonly = isReadonly !== null && isReadonly !== void 0 ? isReadonly : false;
        config.limitChars = maxLength;
        config.removeButtons = ['source', 'image', 'file'];
        config.processPasteHTML = true;
        config.processPasteFromWord = true;
        config.askBeforePasteFromWord = false;
        config.askBeforePasteHTML = false;
        setOptions(config);
    }, []);
    const onBlur = (newContent) => {
        if (typeof newContent === "string") {
            setEditorValueContent(newContent);
        }
        else {
            console.debug("[onBlur] Jodit return a non text value. Trying to extract value from event", newContent);
            const content = newContent.target.innerHTML;
            console.debug("[onBlur] value extracted", content);
            setEditorValueContent(content);
        }
    };
    return (React.createElement(JoditEditor, { value: editorValueContent, config: options, onBlur: onBlur, onChange: newContent => {
            //11 characters because of the nativ behavior of jodit: it puts <p><br/></p> as the content value when you delete manually the content.
            if (newContent.length <= 11) {
                setEditorValueContent(newContent);
            }
        } }));
};
export default WysiwygEditor;
