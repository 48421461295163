import React, { useCallback } from "react";
import InputSelect from './InputSelect';
const Pagination = ({ hasNext, hasPrevious, isFirst, isLast, totalPageCount, totalItemCount, page, pageSize, initPageSize, initPage, pageNumbers }) => {
    const pagesSelect = pageNumbers ? [pageNumbers.p1, pageNumbers.p2, pageNumbers.p3, pageNumbers.p4, pageNumbers.p5] : [5, 10, 25, 50, 100];
    const options = () => {
        let allOptions = [];
        pagesSelect.map(page => {
            allOptions.push({
                value: page,
                label: `${page}`
            });
        });
        return allOptions;
    };
    const handleSelectChange = useCallback((elem) => {
        pageSize(elem);
    }, [pageSize]);
    return (React.createElement("div", { className: "pagination" },
        React.createElement("div", { className: "pagination__nav" },
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement("button", { className: `pagination__button ${isFirst ? 'pagination__button--disabled' : ''}`, onClick: () => page(1) }, "<<")),
                React.createElement("li", null,
                    React.createElement("button", { className: `pagination__button ${!hasPrevious ? 'pagination__button--disabled' : ''}`, onClick: () => {
                            if (initPage > 1) {
                                page(initPage - 1);
                            }
                        } }, "<")),
                Array.from(Array(totalPageCount), (e, i) => {
                    if ((i + 1) === 1 ||
                        (i + 1) === totalPageCount ||
                        (i + 1) <= 3 ||
                        initPage - 1 === (i + 1) ||
                        initPage + 1 === (i + 1) ||
                        initPage === (i + 1) // ||
                    // (i+1) % 10 === 0
                    ) {
                        return (React.createElement("li", { key: i },
                            React.createElement("button", { className: `pagination__button ${i + 1 == initPage ? 'pagination__button--active' : ''}`, onClick: () => {
                                    page(i + 1);
                                } }, i + 1)));
                    }
                    else if (initPage - 2 === (i + 1) ||
                        // (i+1) % 10 === 9 ||
                        totalPageCount - 1 === (i + 1)) {
                        return (React.createElement("li", { key: i }, "..."));
                    }
                }),
                React.createElement("li", null,
                    React.createElement("button", { className: `pagination__button ${!hasNext ? 'pagination__button--disabled' : ''}`, onClick: () => {
                            if (initPage < totalPageCount) {
                                page(initPage + 1);
                            }
                        } }, ">")),
                React.createElement("li", null,
                    React.createElement("button", { className: `pagination__button ${isLast ? 'pagination__button--disabled' : ''}`, onClick: () => page(totalPageCount) }, ">>")))),
        React.createElement("div", { className: "pagination__results" },
            totalItemCount,
            " r\u00E9sultat",
            totalItemCount == 1 ? '' : 's'),
        React.createElement("div", { className: "pagination__selection" },
            React.createElement("label", null, "Nombre par page :"),
            React.createElement(InputSelect, { name: "pageSelect", label: "", classname: "pagination__inputSelect", position: "top", options: options(), onChange: handleSelectChange, value: initPageSize }))));
};
export default Pagination;
