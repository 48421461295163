import React, { useState, useEffect, useRef, FunctionComponent } from "react"
import { TypeDeDocumentDto, DocumentDto } from "../../../services/generated/FrontOffice-api";
import InputDocument, { InputDocumentRef } from './InputDocument';
import InputSelect from "adel-shared/dist/components/basics/InputSelect";
import { useTranslation } from "react-i18next";
import { Dictionary } from "adel-shared/dist/models";
import { Controller, FormContextValues } from 'react-hook-form';

interface InputSelectDocumentProps {
	// New way of handling documents
	typeDeDocument: TypeDeDocumentDto;
	document: DocumentDto;
	onDocumentChange?: (document: DocumentDto, file?: File) => void;
	setIsSelectionEmpty?: (value: boolean) => void;
	form: FormContextValues;
	onReset: (fieldName: string) => void;
	// Legacy way of handling documents
	defaultSelection?: TypeDeDocumentDto;
	defaultDates?: Dictionary<Date>;
	defaultFiles?: Dictionary<File>;
	defaultValues?: Dictionary<Dictionary<string>>;
	onDocumentSelected?: (selectedDocument: TypeDeDocumentDto) => void;
	onDateChange?: (date: Date, documentCode: string) => void;
	onFileChange?: (file: File) => void;
	onValueChange?: (selectedDocumentCode: string, extraInfoCode: string, value: string) => void;
	isVersement: boolean;
	selection: TypeDeDocumentDto;
	setSelection: (value:TypeDeDocumentDto) => void;
}

const InputSelectDocument: FunctionComponent<InputSelectDocumentProps> = ({
	selection,
	setSelection,
	document,
	setIsSelectionEmpty,
	onDocumentChange,
	typeDeDocument,
	defaultSelection,
	defaultDates,
	defaultFiles,
	defaultValues,
	onDocumentSelected,
	onDateChange,
	onFileChange,
	onValueChange,
	isVersement,
	form,
	onReset }) => {
	const { t, i18n } = useTranslation();
	const { control, errors } = form;

	useEffect(() => {
		if(!setIsSelectionEmpty) return;

		if(setIsSelectionEmpty && !selection)
			setIsSelectionEmpty(true);
	 	else
			setIsSelectionEmpty(false);
	}, [selection]);


	const inputDocumentRef = useRef<InputDocumentRef>();

	// Legacy way for defaultSelection
	useEffect(() => {
		if (defaultSelection && !selection) {
			setSelection(defaultSelection);
		}
	}, [defaultSelection])

	// New way for defaultSelection
	useEffect(() => {
		if (document && document.type && !selection) {
			setSelection(document.type);
		}
	}, [document])

	/** Options of the select box for Liste ou attestation GUSO */
	const convertTypeDeDocumentDtoToAdelOption = (optionArray: TypeDeDocumentDto[]) => {
		return optionArray.map((item) => ({
			value: item,
			label: item.nom[i18n.language],
		}));
	};

	const handleSelection = (selectedDocument: TypeDeDocumentDto) => {

		if(selection && selection.code !== selectedDocument.code) {
			inputDocumentRef.current.reset();
		}

		setSelection(selectedDocument);
		onDocumentSelected(selectedDocument);

		// New way of handling choix selection

		if (!document) document = { type: typeDeDocument };

		document.type = typeDeDocument;

		onDocumentChange && onDocumentChange(document);
	}

	return (<div className="piecesObligatoires__item">
		<Controller control={control}
			name={typeDeDocument.code}
			as={({ name }) => (
				<InputSelect<TypeDeDocumentDto>
					name={name}
					label={typeDeDocument.nom[i18n.language]}
					classname="inputSelectFO inputSelectFO--fullWidth"
					options={convertTypeDeDocumentDtoToAdelOption(typeDeDocument.choix)}
					value={selection}
					onChange={handleSelection}
					errors={errors}
					customMatchValue={(source, target) => source.id === target.id}
					placeholder={t("common.select")}
				/>
			)}
		/>
		{setIsSelectionEmpty && !selection &&
			<p className="input__errorMessage">{t('validation-messages.required')}</p>
		}

		{selection && <InputDocument
			className="inputFile__item--modifier"
			selection={selection}
			typeDeDocument={selection}
			document={document}
			isVersement={isVersement}
			onDocumentChange={onDocumentChange}
			defaultDate={defaultDates && defaultDates[typeDeDocument.code]}
			defaultFile={defaultFiles && defaultFiles[typeDeDocument.code]}
			defaultValues={defaultValues && defaultValues[typeDeDocument.code]}
			onDateChange={(date) => onDateChange(date, typeDeDocument.code)}
			onFileChange={onFileChange}
			onValueChange={(extraInfoCode, value) => onValueChange(selection.code, extraInfoCode, value)}
			onReset={() => onReset(selection.code)}
			form={form}
			ref={inputDocumentRef} />}
	</div>)
}

export default InputSelectDocument;