import InputCalendar from "adel-shared/dist/components/basics/InputCalendar";
import { Dictionary } from "adel-shared/dist/models";
import { normalizeDate } from 'adel-shared/dist/utils/functions';
import clsx from 'clsx';
import moment from "moment";
import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, forwardRef, useEffect, useImperativeHandle, useState, useRef, useCallback } from "react";
import { ErrorMessage, FormContextValues, ValidateResult, ValidationOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import parseToDuration from "../../../helpers/parseToDuration";
import tryParseToDate from '../../../helpers/tryParseToDate';
import { Duration } from "../../../models";
import { DocumentDto, DocumentExtraInfoDto, TypeDeDocumentDto, TypeDeDocumentExtraInfoDto } from '../../../services/generated/FrontOffice-api';
import InputDocumentInfoDeSaisie from './InputDocumentInfoDeSaisie';
import { usePrevious } from 'adel-shared/dist/custom-hooks/usePrevious';
import { maxFileSize, maxFileSizeMb } from "../../../constants/config.constant";

export interface InputDocumentRef {
    reset: () => void;
}

interface InputDocumentProps {
    // New way of handling documents
    typeDeDocument: TypeDeDocumentDto;
    document: DocumentDto;
    onDocumentChange?: (document: DocumentDto, file?: File) => void;
    form: FormContextValues;
    onReset: () => void;
    className?: string;
    // Legacy way of handling documents
    defaultDate?: Date;
    defaultFile?: File;
    defaultValues?: Dictionary<string>; // key : extraInfo.code
    onDateChange?: (date: Date) => void;
    onFileChange?: (file: File) => void;
    onValueChange?: (extraInfoCode: string, value: string) => void;
	isVersement: boolean;
	selection?: TypeDeDocumentDto;
}

const getDocumentMinDate = (expirationPeriod: Duration): Date => {
    const now = new Date(Date.now());
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth();
    const nowDay = now.getDate();
    const expiredYear = nowYear - expirationPeriod.years;
    const expiredMonth = nowMonth - expirationPeriod.months;
    const expiredDay = nowDay - expirationPeriod.days;
    const minDate = new Date(expiredYear, expiredMonth, expiredDay);

    return minDate;
}

const getDocumentMaxDate = (docDate: Date, expirationPeriod: Duration): Date => {
    const docYear = docDate.getFullYear();
    const docMonth = docDate.getMonth();
    const docDay = docDate.getDate();

    const expiredYear = docYear + expirationPeriod.years;
    const expiredMonth = docMonth + expirationPeriod.months;
    const expiredDay = docDay + expirationPeriod.days;
    const maxDate = new Date(expiredYear, expiredMonth, expiredDay);

    return maxDate;
}

const getDocumentMinDateString = (expirationPeriodString: string): Date => {
    const expirationPeriod = parseToDuration(expirationPeriodString);
    return getDocumentMinDate(expirationPeriod);
}

const getDocumentMaxDateString = (docDate: Date, expirationPeriodString: string): Date => {
    const expirationPeriod = parseToDuration(expirationPeriodString);
    return getDocumentMaxDate(docDate, expirationPeriod);
}

const isDocumentExpired = (
    documentDate: Date,
    expirationDate: Date,
    expirationPeriodString: string
) => {
    const now = new Date(Date.now());
    if(expirationDate && moment(expirationDate).isBefore(now, 'day')) {
        return true;
    }
    if(expirationPeriodString && documentDate) {
        const maxDate = getDocumentMaxDateString(documentDate, expirationPeriodString);
        const minDate = getDocumentMinDateString(expirationPeriodString);
        if(now > maxDate) {
            return minDate;
        }
    }
    return false;
}

const InputDocument: ForwardRefExoticComponent<PropsWithoutRef<InputDocumentProps> & RefAttributes<InputDocumentRef>> = forwardRef(({
    document,
    onDocumentChange,
    typeDeDocument,
    className,
    defaultDate,
    defaultFile,
    defaultValues,
    onFileChange,
    onDateChange,
    onValueChange,
    onReset,
	selection,
	isVersement,
    form: {
        register,
        triggerValidation,
        errors
    }
}, ref) => {
    const { t, i18n } = useTranslation();
    const [file, setFile] = useState<File>(defaultFile ? defaultFile : undefined);
    const [date, setDate] = useState<Date>(defaultDate ? defaultDate : undefined);
    const [isUploadDocumentButtonDisabled, setIsUploadDocumentButtonDisabled] = useState<boolean>(true);
    const [values, setValues] = useState<Dictionary<string>>(defaultValues ? defaultValues : {});

    const prevDate = usePrevious<Date>(date);
    const inputFileRef = useRef(null);
    const docExpirationDate = useRef(document?.expirationDate);

    const [documentRequired, setDocumentRequired] = useState<boolean>(false);
    const [dateRequired, setDateRequired] = useState<boolean>(false);
    const [dateLabel, setDateLabel] = useState<{[key: string]: string}>();
    const [valueRequired, setValueRequired] = useState<boolean>(false);
    const [infosSaisies, setInfosSaisies] = useState<TypeDeDocumentExtraInfoDto[]>([]);

    useEffect(() => {
		setFile(undefined);
		setDate(undefined);
		setValues({});
		setIsUploadDocumentButtonDisabled(false);
		if(typeDeDocument) handleChangeRequiredValues();
    }, [selection, typeDeDocument]);

    useEffect(() => {
        docExpirationDate.current = document?.expirationDate;
    }, [document?.expirationDate]);

    useEffect(() => {
		if(typeDeDocument) {
			handleChangeRequiredValues();
		}
    }, [typeDeDocument])

	const handleChangeRequiredValues = () => {
		const findString = typeDeDocument.extraInfos?.filter(info => info.valueType === "string");
		const hasDate = typeDeDocument.extraInfos?.some(info => info.valueType === "date");
		const hasValue = typeDeDocument.extraInfos?.some(info => info.valueType === "string");

		if(isVersement)
			setDocumentRequired(typeDeDocument.isRequiredInVersement && typeDeDocument.hasAttachment);
		else
			setDocumentRequired(typeDeDocument.isRequired && typeDeDocument.hasAttachment);

		if(hasDate) {
			setDateLabel(typeDeDocument.extraInfos.find(info => info.valueType === "date").nom);
			setDateRequired(hasDate);
		}
		else {
			setDateRequired(false);
			setDateLabel(undefined);
		}

		if(hasValue)
			setValueRequired(hasValue);
		else
			setValueRequired(false);

		if(findString)
			setInfosSaisies(findString);
	}

    useEffect(() => {
        if(
            date &&
            prevDate &&
            !moment(date).isSame(prevDate, 'day')
        ) {
            inputFileRef.current.value = '';
			resetExtraInfos();
        }
    }, [date, prevDate]);

    useEffect(() => {
		triggerValidation(typeDeDocument.code);
    }, [date, file, dateRequired, valueRequired, documentRequired, docExpirationDate, typeDeDocument]);

    useEffect(() => {
        if (!document) {
            if(inputFileRef.current) {
                inputFileRef.current.value = '';
            }
            setFile(undefined);
            return;
        }

        if (document.fileName) {
            setFile({ name: document.fileName } as File); // Relatively dangerous workaround :D
        }
        if (document.extraInfo?.some(extraInfo => extraInfo.valueType === "date")) {
            const existingDate = tryParseToDate(document.extraInfo.find(extraInfo => extraInfo.valueType === "date").value);
            setDate(existingDate);
        }
        if (document.extraInfo?.some(extraInfo => extraInfo.valueType === "string")) {
            const defaultValues = {} as Dictionary<string>;
            document.extraInfo.filter(extraInfo => extraInfo.valueType === "string").forEach(extraInfo => {
                defaultValues[extraInfo.code] = extraInfo.value;
            });
            setValues(defaultValues);
        }
	}, [document])

    useEffect(() => {
        evaluateUploadDocumentButton();
    }, [typeDeDocument?.extraInfos, dateRequired, valueRequired, documentRequired, docExpirationDate]);

    useImperativeHandle(ref, () => ({
        reset: () => {
            setFile(undefined);
			setDate(undefined);
            onReset();
        }
    }));

    const customFileValidation = useCallback(():ValidationOptions => {
        return {
			validate: (data: FileList): ValidateResult => {
				const error = validate(
					data[0],
					date,
					file,
					docExpirationDate.current,
					typeDeDocument?.expirationPeriod,
					typeDeDocument?.expirationRelativeTo,
					dateRequired
				);

				if(file?.name.length > 100) {
					return 'Le nom du fichier ne doit pas excéder 100 caractères.';
				}

				if (error)
					return error;
				return true;
			}
		}
    }, [dateRequired, valueRequired, documentRequired, date, file, docExpirationDate, typeDeDocument]);

    const validate = (file: File,
					date: Date,
					fileAdded: File,
					expirationDate: Date,
					expirationPeriod: string,
					expirationRelativeTo: string,
					dateRequired: boolean
	) => {
		if (dateRequired && !date) {
            return t(`signup.step-six.validations.missing-date`) as string;
        } else {
		    const expiredDate = isDocumentExpired(date, expirationDate, expirationPeriod);
            if (file?.size > maxFileSizeMb) {
                return t(`errors.largeInputFile`, { maxFileSize }); 
            }
            if(expiredDate) {
                if(typeof expiredDate === 'object') {
                    return t(`signup.step-six.validations.too-late-date`, {
                        expirationDate: moment(expiredDate).format('DD/MM/YYYY')
                    });
                }
                return t(`signup.step-six.validations.expired-date`);
            }

            if (valueRequired && !valuesAreValid()) { // multiple value validation
                return t(`signup.step-six.validations.missing-value`) as string;
            }

            if (documentRequired && !file && !fileAdded) {
                return t(`signup.step-six.validations.missing-file`) as string;
            }
        }
        return true;
	};

    const valuesAreValid = (): boolean => {
        return infosSaisies.some(x => values[x.code]);
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length !== 0) {
            if (e.target.files[0].name !== "") {
                var selectedFile = e.target.files[0];
                setFile(selectedFile);
                onFileChange && onFileChange(selectedFile);

                // New way to handle documents

                if (!document) document = { type: typeDeDocument };

                // Change filename of document
                document.fileName = selectedFile.name;

                // Pass the file as a second optional argument
                onDocumentChange && onDocumentChange(document, selectedFile);
            } else {
                setFile(undefined);
				setDate(undefined);
            }
		}

		// Pour renvoyer le extrainfo de la date pour ne pas la perdre quand on modifie un document
		date && handleDateChange(date, selectedFile);

		// Pour renvoyer le extrainfo de l'input (ex: licence numero) pour ne pas le perdre quand on modifie un document
		infosSaisies && infosSaisies.length > 0 && infosSaisies.forEach(infoSaisie => {
			values && handleValueChange(values[infoSaisie.code], infoSaisie.code);
		})
    };

    const handleDateChange = (date: Date, file:File) => {
        docExpirationDate.current = null;

        setDate(date);

        if (!document) {
			document = { type: typeDeDocument };
		}

        // Change value of corresponding extraInfo
        if (!document.extraInfo) {
			document.extraInfo = [];
		}

        const existingExtraInfoDate = document.extraInfo.find(extraInfo => extraInfo?.valueType === "date");
		// Find extraInfo definition from TypeDeDocumentDto
		const extraInfoDateFromType = typeDeDocument.extraInfos.find(extraInfo => extraInfo.valueType === "date");

        if (existingExtraInfoDate) {
            const updatedExtraInfo: DocumentExtraInfoDto = {
                ...existingExtraInfoDate,
                value: date && normalizeDate(date)
            };
            document.extraInfo = [updatedExtraInfo, ...document.extraInfo.filter(extraInfo => extraInfo.code !== existingExtraInfoDate.code)];
        } else {
            // Add new extraInfo
            document.extraInfo = [...document.extraInfo, {
                value: date && normalizeDate(date),
                code: extraInfoDateFromType?.code,
                valueType: extraInfoDateFromType?.valueType,
            }];
        }

        onDateChange && onDateChange(date);

    	onDocumentChange && onDocumentChange(document, file);
        evaluateUploadDocumentButton(date);
    }

    const handleValueChange = (value: string, infoSaisieCode: string) => {
        values[infoSaisieCode] = value;
        setValues(values);
        onValueChange && onValueChange(infoSaisieCode, value);

        if (!document) document = { type: typeDeDocument };

        // Change value of corresponding extraInfo
        if (!document.extraInfo) document.extraInfo = [];

        const existingExtraInfoString = document.extraInfo.find(extraInfo => extraInfo.valueType === "string" && extraInfo.code === infoSaisieCode);
		// Find extraInfo definition from TypeDeDocumentDto
		const extraInfoDateFromType = typeDeDocument.extraInfos.find(extraInfo => extraInfo.valueType === "string" && extraInfo.code === infoSaisieCode);


		if (existingExtraInfoString) {
            const updatedExtraInfo: DocumentExtraInfoDto = {
                ...existingExtraInfoString,
                value
            };
            document.extraInfo = [updatedExtraInfo, ...document.extraInfo.filter(extraInfo => extraInfo.code !== existingExtraInfoString.code)];
        } else { // Add new extraInfo
            document.extraInfo = [...document.extraInfo, {
                value,
                code: extraInfoDateFromType.code,
                valueType: extraInfoDateFromType.valueType,
            }];
        }
        onDocumentChange && onDocumentChange(document, file);
        evaluateUploadDocumentButton();
    };

    const evaluateUploadDocumentButton = (modifiedDate?: Date) => {
		const evaluatedDate = modifiedDate ? modifiedDate : date;
        const isDocExpired = isDocumentExpired(evaluatedDate, docExpirationDate.current, typeDeDocument?.expirationPeriod);

        if ((dateRequired && !evaluatedDate) || (valueRequired && !valuesAreValid()) || isDocExpired) {
            setIsUploadDocumentButtonDisabled(true);
        } else {
            setIsUploadDocumentButtonDisabled(false);
        }
    }

    const downloadFile = () => {
        if (document?.uri) {
            window.open(document.uri);
        }
    }

	const resetExtraInfos = () => {
        setFile(undefined);
		setIsUploadDocumentButtonDisabled(false);
		onReset();		
        setValues({});
	}

	const resetFile = () => {
		setFile(undefined);
		setDate(undefined);
		setIsUploadDocumentButtonDisabled(false);
		onReset();
	}

    return (
        <div className={clsx("inputFile", {className: className})}>
            <label>
                {typeDeDocument.nom[i18n.language]}
            </label>

            <div className="inputFile__row">
                {dateRequired &&
                    <InputCalendar
                        defaultDate={date}
                        name={`${typeDeDocument.code}-date`}
                        label={dateLabel[i18n.language]}
                        onDateSelected={(value:any) => handleDateChange(value, file)}
                        onResetExtraInfos={resetExtraInfos}
                    />
                }
                <div>
                    <div className={clsx("inputFile__infos", {"inputFile__infos--hidden":!file })}>
                        <div className="inputFile__infos--file">
                            <span className={document && document.uri ? "inputFile__infos--fileName" : ""} onClick={downloadFile}>
                                {file?.name}
                            </span>
                            <div onClick={resetFile}>
                                <i className="far fa-trash-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div className={clsx("inputFile__infos", {"inputFile__infos--hidden":file })}>
                        {typeDeDocument.hasAttachment ? (
                            <div className="inputFile__buttons" style={{ opacity: isUploadDocumentButtonDisabled ? "50%" : "100%", width: 'auto', padding: 5 }}>
                                <label htmlFor={typeDeDocument.id}>
                                    {`${t('common.download-file')} ( max ${maxFileSize} ${t('common.mb')} )`}
                                </label>
                                <input
                                    name={typeDeDocument.code}
                                    disabled={isUploadDocumentButtonDisabled}
                                    ref={ref => {
                                        inputFileRef.current = ref;
                                        register(ref, customFileValidation());
                                    }}
                                    id={typeDeDocument.id}
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </div>
                        ) : (
                            <input
                                type="hidden"
                                name={typeDeDocument.code}
                                ref={register(customFileValidation())}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="inputFile__item">
                {valueRequired && infosSaisies.map(infoSaisie => <InputDocumentInfoDeSaisie
                    key={infoSaisie.code}
                    label={infoSaisie.nom[i18n.language]}
                    value={values[infoSaisie.code]}
                    onChange={(value: string) => handleValueChange(value, infoSaisie.code)}
                />)}
            </div>

            {errors && <ErrorMessage errors={errors} name={typeDeDocument.code}>
                {({ message }) => <p className="input__errorMessage">{message}</p>}
            </ErrorMessage>}
        </div>
    )
})

export default InputDocument;