import React, { forwardRef, useImperativeHandle, useEffect } from "react";

import Textarea from "../../components/basics/Textarea";
import Subtitles from "../../components/basics/Subtitles";
import StepCounter from "../../components/signup/signup-items/StepCounter";

import { useForm } from "react-hook-form";
import { IStepTwoModel } from "../../models/signup";
import { ActionTypeEnum } from "../../context/ActionType";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useStepTwoContext } from "../../context/context-helpers";
import { RouteComponentProps } from "@reach/router";
import useValidation from "../../custom-hooks/useValidation";

interface StepTwoProps extends RouteComponentProps {
	value?: IStepTwoModel;
	ref: any;
	setCanGoNext: (value: boolean) => void;
}

const StepTwo: React.FunctionComponent<StepTwoProps> = forwardRef((props, ref) => {
	const { register, getValues, triggerValidation, errors } = useForm<IStepTwoModel>({ defaultValues: useStepTwoContext() });

	const { getRootValidator } = useValidation();
	const createStructureDto = getRootValidator("CreateStructureDto");

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const myProp = props;

	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			const result = await triggerValidation();
			if (result) {
				dispatch({ type: ActionTypeEnum.SET_SIGNUP_STEP_TWO, payload: getValues() });
			}
			return result;
		}
	}));

	useEffect(() => {
		myProp.setCanGoNext(true);
	}, [myProp]);

	return (
		<>
			<form className="infosRelatives">

				<StepCounter
					counter={2}
				/>

				<Subtitles
					title={t('signup.step-two.title')}
				/>

				<Textarea
					name="informations"
					reference={register(createStructureDto && createStructureDto["Informations"])}
					placeholder={t('signup.step-two.placeholder')}
					label={t('signup.step-two.label')}
					value={props.value?.informations}
					errors={errors}
				/>
			</form>
		</>
	)
})

export default StepTwo;