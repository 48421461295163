import React, { useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import Subtitles from "../../../basics/Subtitles";
import Input from "../../../basics/Input";
import InputPassword from "../../../basics/InputPassword";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import PasswordValidation from "./PasswordValidation";
import { ICreatePasswordModel } from "../../../../models/ICreatePasswordModel";
import useValidation from "../../../../custom-hooks/useValidation";
import { useAxios } from "../../../../custom-hooks/useAxios";
import { AuthClient } from "../../../../services/generated/FrontOffice-api";
import { toast } from 'react-toastify';

interface CreatePasswordProps {
	validPass?: boolean;
	setValidPass?: any;
	setResetPassword?: any;
	setShowPass?: any;
	showPass?: boolean;
	token: string;
	username: string;
	afterSubmit: () => void;
}

const CreatePassword: React.SFC<CreatePasswordProps> = ({
	validPass,
	setValidPass,
	setResetPassword,
	setShowPass,
	showPass,
	token,
	username,
	afterSubmit
}) => {
	const { t } = useTranslation();
	const { register, getValues, triggerValidation, errors, watch } = useForm<ICreatePasswordModel>({
		defaultValues: {
			username: username
		}
	});
	const { getRootValidator } = useValidation();
	const createPasswordValidator = getRootValidator("ChangePasswordWithResetTokenDto");
	const axiosInstance = useAxios();
	const password = useRef({});
	password.current = watch("newPassword", "");

	const validationNotMatchMessage = t('validation-messages.not-match');

	const onSubmit = async () => {
		let result = await triggerValidation();

		if (result) {
			var client = new AuthClient('', axiosInstance);
			var changePasswordWithResetTokenDto = { "username": username, "token": token, "newPassword": getValues().newPassword }

			try {
				await client.changePasswordWithResetToken(changePasswordWithResetTokenDto);
				setValidPass(true);
			} catch(error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}

	return (
		<>
			{!validPass ?
				<div className="password">
					<Subtitles
						title={t('password.creation.subtitle')}
					/>

					<form>
						<div>
							<label className="password__label">{t('password.resetChange.login')}</label>
							<span className="password__username">{username ? username : ""}</span>
						</div>

						<InputPassword
							name="newPassword"
							key="1"
							reference={register(createPasswordValidator && createPasswordValidator["NewPassword"])}
							label={t('password.creation.password')}
							placeHolder={t('password.creation.password-placeholder')}
							setShowPass={setShowPass}
							showPass={showPass}
							errors={errors}
						/>

						<InputPassword
							name="newPasswordBis"
							key="2"
							reference={register({
								validate: value =>
									value === password.current || validationNotMatchMessage
							})}
							label={t('password.creation.password-auth')}
							placeHolder={t('password.creation.password-auth-placeholder')}
							setShowPass={setShowPass}
							showPass={showPass}
							errors={errors}
						/>

						<div className="password__footer">
							<FormButton
								type="button"
								value={t('portal.connection.validate-button')}
								onClick={() => onSubmit()}
							/>
						</div>
					</form>
				</div>
				:
				<PasswordValidation
					setResetPassword={setResetPassword}
					setValidPass={setValidPass}
					title={t('password.creation.validation.subtitle')}
					paragraph={t('password.creation.validation.text')}
					afterSubmit={afterSubmit}
				/>

			}
		</>
	)
}

export default CreatePassword;