import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { Civilite, StructureClient, StructureDetailsDto, TypePoste, UpdateContactDto } from '../../services/generated/FrontOffice-api';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { useForm, Controller } from "react-hook-form";
import Input from "adel-shared/dist/components/basics/Input";
import { useAxios } from "../../custom-hooks/useAxios";
import { toast } from "react-toastify";
import useValidation from "../../custom-hooks/useValidation";
import InputSelect from "../basics/InputSelect";
import InputRadioButton from "adel-shared/dist/components/basics/InputRadioButton";
import useBooleanState from 'adel-shared/dist/custom-hooks/useBooleanState';
import { modalSmallerCustomStyles } from "../../constants/config.constant";
import Modal from 'react-modal';
import { formatPhone } from 'adel-shared/dist/utils/functions';


interface MonCompteAdministratifProps extends RouteComponentProps {
	structureDetails: StructureDetailsDto
	setStructureDetails: (value:StructureDetailsDto) => void;
}
interface UpdateCorrespondantForm extends UpdateContactDto {
	parDefaut: boolean;
}

const MonCompteAdministratif: FunctionComponent<MonCompteAdministratifProps> = ({
	structureDetails, setStructureDetails
}) => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);
	const { getRootValidator } = useValidation();
	const updateStructureContactValidator = getRootValidator("UpdateContactValidator");
	const { register, getValues, setValue, triggerValidation, control, watch, errors } = useForm<UpdateCorrespondantForm>();
	const parDefaut = watch('parDefaut');
	const [editInput, setEditInput] = useState<boolean>(false);
	const representant = structureDetails.representantLegal;
	const correspondant = structureDetails.correspondant || structureDetails.representantLegal;
	const [isModalOpen, openModal, closeModal] = useBooleanState(false);

	const optionsCivilite = Object.values(Civilite).filter(x => x !== Civilite.None).map(x => ({
		value: x,
		label: t(`common.civilite.${x}`)
	}));

	const optionsPoste = Object.values(TypePoste).filter(x => x !== TypePoste.None).map(x => ({
		value: x,
		label: t(`common.typePoste.${x}`)
	}));

	useEffect(() => {
		if(structureDetails.id && editInput) {
			setValue('parDefaut', structureDetails.correspondant ? false : true);
		}
	}, [
		structureDetails,
		editInput
	]);

	useEffect(() => {
		const correspondant = structureDetails.correspondant;
		if(correspondant && editInput) {
			const poste = correspondant.poste;
			const civilite = correspondant.civilite;
			const nom = correspondant.nom;
			const prenom = correspondant.prenom;
			const telephoneFixe = correspondant.telephoneFixe;
			const telephonePortable = correspondant.telephonePortable;
			const email = correspondant.email;

			setValue([
				{ poste },
				{ civilite },
				{ nom },
				{ prenom },
				{ telephoneFixe },
				{ telephonePortable },
				{ email }
			]);
		}
	}, [
		correspondant,
		editInput,
	]);

	const handleEditClick = () => {
		setEditInput(true);
	};

	const handleCancelClick = () => {
		setEditInput(false);
	};

	const handleSubmit = async() => {
		const isValid = await triggerValidation();

		if(!isValid) return;

		try {
			const { parDefaut, poste, civilite, nom, prenom, telephoneFixe, telephonePortable, email } = getValues();
			await structureClient.updateStructureContact({
				parDefaut,
				autre: {
					poste,
					civilite,
					nom,
					prenom,
					telephoneFixe,
					telephonePortable,
					email
				}
			});

			if (correspondant.email !== email)
				openModal();

			toast.success(t('myAccount.correspondantSuccess'));
			setEditInput(false);
			setStructureDetails({...structureDetails,
				correspondant: {
					poste,
					civilite,
					nom,
					prenom,
					telephoneFixe,
					telephonePortable,
					email
				}
			});

		} catch(error) {
			toast.error(t('myAccount.correspondantError'));
			
			if (error.exception?.message)
				toast.error(error.exception.message);
		}
	};

	return (
		<>
			<div className="monCompte__header">
				<h3>{t("myAccount.correspondant")}</h3>
				{!editInput && (
					<i
						className="far fa-edit"
						role="button"
						title="Modifier"
						onClick={handleEditClick}
					></i>
				)}
			</div>
			{editInput ? (
				<>
					<div className="defaultContact">
						<div>
							<div className="monCompte__editCheckbox">
								<Controller
									control={control}
									name="parDefaut"
									as={({ onChange, name, checked }) => (
										<>
											<InputRadioButton
												name={name}
												label="Correspondant par défaut"
												id="radio-parDefaut"
												checked={checked}
												value="parDefaut"
												onChange={onChange}
											/>
											<div className="defaultCheckbox">
												<span>
													{`
														${t('common.president')} 
														${representant.prenom} 
														${representant.nom} - 
														${representant.telephoneFixe ? `${formatPhone(representant.telephoneFixe)} - ` : ""} 
														${representant.email}
													`}
												</span>
											</div>
											<InputRadioButton
												name={name}
												label="Autre correspondant"
												id="radio-autre"
												checked={!checked}
												value="autre"
												onChange={(e) => {
													e.target.checked = !e.target.checked;
													onChange(e);
												}}
											/>
										</>
									)}
								/>
							</div>

							{!parDefaut && (
								<>
									<div className="monCompte__formRow">
										<Controller
											name="poste"
											control={control}
											as={({ name, value, onChange }) => (
												<InputSelect
													label={`${t("myAccount.form.poste")}*`}
													classname="inputSelect"
													name={name}
													onChange={onChange}
													options={optionsPoste}
													value={value}
												/>
											)}
											rules={updateStructureContactValidator?.["Poste"]}
										/>

										<Controller
											name="civilite"
											control={control}
											as={({ name, value, onChange }) => (
												<InputSelect
													label={`${t("myAccount.form.civilite")}*`}
													classname="inputSelect"
													name={name}
													onChange={onChange}
													options={optionsCivilite}
													value={value}
												/>
											)}
											rules={updateStructureContactValidator?.["Civilite"]}
										/>
									</div>
									<div className="monCompte__formRow">
										<Input
											name="nom"
											reference={register(updateStructureContactValidator?.["Nom"])}
											label={`${t("myAccount.form.nom")}*`}
											type="text"
											errors={errors}
										/>

										<Input
											name="prenom"
											reference={register(updateStructureContactValidator?.["Prenom"])}
											label={`${t("myAccount.form.prenom")}*`}
											type="text"
											errors={errors}
										/>
									</div>
									<div className="monCompte__formRow">
										<Input
											name="telephoneFixe"
											reference={register(updateStructureContactValidator?.["TelephoneFixe"])}
											label={`${t("myAccount.form.telephoneFixe")}*`}
											type="text"
											errors={errors}
										/>

										<Input
											name="telephonePortable"
											reference={register(updateStructureContactValidator?.["TelephonePortable"])}
											label={t("myAccount.form.telephonePortable")}
											type="text"
											errors={errors}
										/>
									</div>
									<div className="monCompte__formRow">
										<Input
											name="email"
											reference={register(updateStructureContactValidator?.["Email"])}
											label={`${t("myAccount.form.email")}*`}
											type="text"
											errors={errors}
										/>
									</div>
								</>
							)}

							<div className="monCompte__editFooter">
								<FormButton
									type="button"
									value={t('common.cancel')}
									onClick={handleCancelClick}
								/>
								<FormButton
									type="submit"
									value={t('common.validate')}
									onClick={handleSubmit}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="defaultContact">
					<div className="readOnly">
						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.poste")}</label>
								<input value={t(`common.typePoste.${correspondant?.poste}`) as string} disabled />
							</div>

							<div className="input">
								<label>{t("myAccount.form.civilite")}</label>
								<input value={t(`common.civilite.${correspondant?.civilite}`) as string} disabled />
							</div>
						</div>

						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.nom")}</label>
								<input value={correspondant?.nom} disabled />
							</div>

							<div className="input">
								<label>{t("myAccount.form.prenom")}</label>
								<input value={correspondant?.prenom} disabled />
							</div>
						</div>

						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.telephoneFixe")}</label>
								<input value={correspondant?.telephoneFixe ? formatPhone(correspondant.telephoneFixe) : '-' } disabled />
							</div>
							<div className="input">
								<label>{t("myAccount.form.telephonePortable")}</label>
								<input value={correspondant?.telephonePortable ? formatPhone(correspondant.telephonePortable) : '-'} disabled />
							</div>
						</div>

						<div className="readOnly__item">
							<div className="input">
								<label>{t("myAccount.form.email")}</label>
								<input className="email" value={correspondant?.email} disabled />
							</div>
						</div>
					</div>
				</div>
			)}
			<Modal isOpen={isModalOpen} style={modalSmallerCustomStyles}>
				<div className="modal__content">
					<h4>{t('myAccount.mailUpdateModal.title')}</h4>
					<p>{t('myAccount.mailUpdateModal.text')}</p>
				</div>
				<div className="modal__footer">
					<FormButton
						type="button"
						value={t('common.close')}
						onClick={() => closeModal()}
					/>
				</div>
			</Modal>
		</>
	)
};

export default MonCompteAdministratif;
