import React from "react";
import clsx from "clsx";
export var styleTable;
(function (styleTable) {
    styleTable[styleTable["onglet"] = 0] = "onglet";
    styleTable[styleTable["page"] = 1] = "page";
})(styleTable || (styleTable = {}));
// Create a default prop getter
const defaultPropGetter = () => ({});
const Table = ({ className, data, getTableProps, getTableBodyProps, getCellProps = defaultPropGetter, getRowProps = defaultPropGetter, headerGroups, rows, prepareRow, styles, footerGroups }) => {
    return (React.createElement(React.Fragment, null, data &&
        React.createElement("table", Object.assign({}, getTableProps(), { className: clsx("table", className) }),
            React.createElement("thead", { className: styles === styleTable.onglet ? "table__header" : "table__header table__header--color" }, headerGroups === null || headerGroups === void 0 ? void 0 : headerGroups.map((headerGroup) => (React.createElement("tr", Object.assign({}, headerGroup.getHeaderGroupProps(), { className: "table__row " }), headerGroup.headers.map((column) => (React.createElement("th", Object.assign({}, column.getHeaderProps(column.getSortByToggleProps()), { className: styles === styleTable.onglet ? "table__label table__label--font" : "table__label", title: null }),
                React.createElement("div", { className: "table__text" },
                    !column.disableSortBy &&
                        React.createElement("i", { className: clsx({
                                'fas fa-minus': !column.isSorted,
                                'fas fa-chevron-down': column.isSorted && column.isSortedDesc,
                                'fas fa-chevron-up': column.isSorted && !column.isSortedDesc
                            }) }),
                    React.createElement("span", null, column.render("Header")))))))))),
            React.createElement("tbody", Object.assign({}, getTableBodyProps(), { className: "table__body" }), rows.map((row, index) => {
                prepareRow(row);
                return (React.createElement("tr", Object.assign({}, row.getRowProps(getRowProps(row)), { className: clsx({
                        // 'table__row': styles === styleTable.onglet,
                        'table__row': index % 2 === 0,
                        'table__row2': index % 2 !== 0,
                        'table__row table__row--opacity': styles !== styleTable.onglet
                    }, getRowProps(row).className) }), row.cells.map((cell) => {
                    return React.createElement("td", Object.assign({}, cell.getCellProps(getCellProps(cell)), { className: clsx(cell.column.className, {
                            'table__statut table__statut--fixWidth': cell.column.Header === 'Statut',
                            'table__text--fixWidthSmall': cell.column.hasEllipsis,
                            'table__text table__text--font': styles === styleTable.onglet,
                            'table__text': styles !== styleTable.onglet
                        }) }), cell.render("Cell"));
                })));
            })),
            footerGroups &&
                React.createElement("tfoot", { className: "table__footer table__footer--color" }, footerGroups.map(group => (React.createElement("tr", Object.assign({}, group.getFooterGroupProps(), { className: "table__row " }), group.headers.map(column => (React.createElement("td", Object.assign({}, column.getFooterProps(), { className: "table__label" }),
                    React.createElement("div", { className: "table__text" }, column.render('Footer'))))))))))));
};
export default Table;
