import QuestionInputProps from "./QuestionInputProps";
import React from "react";
import {useTranslation} from "react-i18next";

const BlockingQuestionInput : React.SFC<QuestionInputProps> = (props) => {
    const { i18n } = useTranslation();

	return <div className="blockingQuestion">
        {props.question.text[i18n.language]}
    </div>;
}

export default BlockingQuestionInput;