import React from "react";
import InputReadOnly from '../../../InputReadOnly';
const SimpleQuestionInput = (props) => {
    return (props.isUrl || props.id === "contenu-projet-lien-extrait-audiovisuel-realisateur" || props.id === "contenu-projet-lien-extrait-audiovisuel-artiste")
        ? React.createElement("div", { className: `inputReadOnly` },
            React.createElement("label", null, `${props.title ? props.title : ""}`),
            React.createElement("p", null,
                React.createElement("a", { href: `${props.currentValue}`, target: "_blank", rel: "noreferrer" }, props.currentValue)))
        : React.createElement(InputReadOnly, { label: props.title, content: `${props.currentValue}` });
};
export default SimpleQuestionInput;
