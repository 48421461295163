import React, {forwardRef, useEffect, useImperativeHandle, useState, FunctionComponent} from "react";
import {RouteComponentProps} from "@reach/router";
import {useTranslation} from "react-i18next";
import {useAxios} from "../../../custom-hooks/useAxios";
import {
    AnswerType,
    DossierClient,
    QuestionBlockDto,
    QuestionDto,
    ResponsableDossierDto,
	StructureClient
} from "../../../services/generated/FrontOffice-api";
import {Dictionary} from "../../../models";
import { isAnswerValid, ValidationResult } from "../../../helpers/questionHelpers";
import {fakeQuestionsData} from "./questions/fakeQuestionsData";
import InfosResponsableDossier from "../InfosResponsableDossier";
import {useForm} from "react-hook-form";
import {isFileRef} from "../../../models/FileRef";
import {AdelDossierClient, SubmitInfosProjetAnswersDto, CreateOrUpdateResponsableDossierDto} from "../../../clients/AdelDossierClient";
import RenderBlockContent, { getQuestions, getAnswer } from './questionsBlock/RenderBlockContent';
import { toast } from 'react-toastify';
import { normalizeDate } from 'adel-shared/dist/utils/functions';
import clsx from "clsx";
import moment from "moment";

interface InfosProjetProps extends RouteComponentProps {
    ref: any;
	dossierId: string;
	nomCategorie: string;
    categorieDossierId: string;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
}

const InfosProjet : FunctionComponent<InfosProjetProps> = forwardRef((props, ref) => {
    const { i18n, t } = useTranslation();

    const axiosInstance = useAxios();
    const dossierClient = new DossierClient("", axiosInstance);
    const structureClient = new StructureClient("", axiosInstance);
    const adelDossierClient = new AdelDossierClient("", axiosInstance);

    const responsableForm = useForm<CreateOrUpdateResponsableDossierDto>({});

    const [fortissimoTitleCorrect, setFortissimoTitleCorrect] = useState<boolean>(false);

    const [blocks, setBlocks] = useState<QuestionBlockDto[]>([]);
	const [answers, setAnswers] = useState<Dictionary<any>>({});
	const [messageValidation, setMessageValidation] = useState<Dictionary<ValidationResult>>({});

    const setResponsable = (responsable: ResponsableDossierDto) => {
        if (responsable) {
			const responsableValues = Object.entries(responsable).map(([key, value]) => ({
				[key]: value
			}));
			responsableForm.setValue(responsableValues);
        }
    }

    useEffect(() => {

        if (!props.dossierId) {
            return;
        }

        const fetchViewModel = async () => {
            const viewModel = await dossierClient.getInfosProjetViewModel(props.dossierId);
            setBlocks(viewModel.questionBlocks);

            let answers = viewModel.answers;
            setAnswers(answers);

			let responsable = viewModel.responsableDossier ? viewModel.responsableDossier : {};

			// Si pas de responsable rempli, on récupère celui de correspondant administratif, sinon celui de viewModel
			if (Object.keys(responsable).length === 0) {
				const resp = await structureClient.getStructureCorrespondantAdministratif();
				setResponsable(resp);
			} else {
				setResponsable(responsable);
			}
        }

        // Use this instead of fetchBlocks for testing
        const fetchFakeBlocks = () => {
            setBlocks(fakeQuestionsData);
        }

        //fetchFakeBlocks();
        fetchViewModel();

        props.setCanGoNext(true);
    }, [props.dossierId]);

    const getParsedAnswers = () => blocks
            .flatMap(block => getQuestions(block, answers))
            .map(question => ({id: question.id, type: question.answerType, value: getAnswer(question, answers)}));


    /** VALIDATION */
	const validateAnswers = () => {
		setMessageValidation({});
		let errors: Dictionary<ValidationResult> = {};

		const validation = (item: QuestionDto):ValidationResult | Dictionary<ValidationResult> => {
			if (isAnswerValid(item, getAnswer(item, answers), t).isValid === false) {

				/** Answers Array */
				let items: Dictionary<Dictionary<ValidationResult>> = {};

				if (Array.isArray(answers[item.id]) && answers[item.id].length > 0) {
					answers[item.id].forEach((q:any, itemIndex:number) => {
						// Initialiser items à => { nom: null, prenom: null, cv: null } pour chaque groupe d'inputs dynamiques créés
						if (!items[itemIndex])
							items[itemIndex] = item.arrayItemsQuestions.reduce((acc, v) => {acc[v.id] = null; return acc;}, {} as Dictionary<any>);
							
						// Ensuite assigner les valeurs de answers des inputs à items
						Object.assign(items[itemIndex], answers[item.id][itemIndex]);
						
						// Pour chaque input du groupe, on va tester la validation et lui assigner son ValidationResult associé
						for (const [key, value] of Object.entries(items[itemIndex])) {
							const itemToCheck = item.arrayItemsQuestions.find(aa => aa.id === key);
							const answerToCheck = answers[item.id][itemIndex];

							let validationResult = isAnswerValid(itemToCheck, getAnswer(itemToCheck, answerToCheck), t);

							items[itemIndex][key] = {
								isValid: validationResult.isValid, 
								message: validationResult.message
							}
						}
					})
				}

				/** Answers simples */
				if (!errors[item.id]) {
					errors[item.id] = {} as ValidationResult
				}
				errors[item.id] = {
					isValid: false, 
					message: isAnswerValid(item, getAnswer(item, answers), t).message,
					items: items
				}
			}
			return errors;
		}

        blocks.forEach(block => {
			getQuestions(block, answers).forEach(q => {
				validation(q);
			})
		});
		setMessageValidation(errors);

		blocks.forEach(block => {
            if (!getQuestions(block, answers).every(q => isAnswerValid(q, getAnswer(q, answers), t).isValid )) {
                return false;
            }
		});

		if(Object.keys(errors).length > 0) {
			return false;
		} else return true;
	};

    const submitInfosProjet = async (answers: {id: string, type: AnswerType, value: any}[], responsable: CreateOrUpdateResponsableDossierDto) => {
        let data: SubmitInfosProjetAnswersDto = {
            answers: {}
        };
        let files: Dictionary<File> = {};

        for (let answer of answers) {
            if (answer.type === AnswerType.File) {
                if (answer.value instanceof File) {
                    // Uploading a new file
                    data.answers[answer.id] = {
                        partName: answer.id,
                        fileName: answer.value.name,
                        contentType: answer.value.type
                    };
                    files[answer.id] = answer.value;
                } else if (isFileRef(answer.value)) {
                    // Specifying an existing file
                    data.answers[answer.id] = { id: answer.value.id };
                }
            } else if (answer.type === AnswerType.Date) {
				data.answers[answer.id] = normalizeDate(answer.value);
			}  else if (answer.type === AnswerType.Period) {
				const start = normalizeDate(answer.value.start);
				const end = answer.value.end;
				data.answers[answer.id] = {start, end};
			} else {
                data.answers[answer.id] = answer.value;
            }
        }
        await dossierClient.setResponsableDossier(props.dossierId, responsable);
		await adelDossierClient.submitInfosProjetAnswers(props.dossierId, data, files);
    }

    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
            const responsableResult = await responsableForm.triggerValidation();
            const answersResult = validateAnswers();

			if (props.nomCategorie === "Fortissimo" && getParsedAnswers().find(e => e.id === "nom-du-projet").value.substring(0, 11) !== "Fortissimo ") {
				setFortissimoTitleCorrect(true)
				return false;
			} else {
				setFortissimoTitleCorrect(false)
			}

            if (responsableResult && answersResult) {
				props.setIsLoading(true);
				try {
					await submitInfosProjet(getParsedAnswers(), responsableForm.getValues());
					props.setIsLoading(false);
					return true;
				} catch(error) {
					props.setIsLoading(false);

					if(error.code === 'periodStartDateShouldBeAfterCommissionEnding') {
						toast.error(t(
							'createFolder.informationsDossier.errors.periodStartDateShouldBeAfterCommissionEnding',
							{date: moment(error.additionalDetails.dateLimite).format('DD/MM/YYYY')}
						));
					}
					else {
						toast.error(t("errors.default"));
					}

					Object.keys(error.additionalDetails?.errors).forEach(item =>
						toast.error(error.additionalDetails?.errors[item][i18n.language])
					);
					return false;
				}
            } else {
                toast.error(t("validation-messages.invalid-form"));
			}
            return false;
        }
    }));

    const renderBlockContent = (block: QuestionBlockDto) => {
        if (block.id === "infos-responsable-dossier") {
            return (
				<InfosResponsableDossier
					form={responsableForm}
				/>
			);
        }

		return (
			<RenderBlockContent
				block={block}
				answers={answers}
				setAnswers={setAnswers}
				messageValidation={messageValidation}
				key={block.id}
			/>
		);
    }

	return (
		<form className="creationDossier__infosProjet">
			<div className="creationDossier__header">
				<h3 className="title--dark">
					{t("createFolder.infoProjet.title", {name: props.nomCategorie})}
				</h3>
			</div>
			{blocks.map(block => (
				<div className="creationDossier__block" id={"block-" + block.id} key={block.id}>
					<h4 className="title--red">
						{block.title?.[i18n.language]}
					</h4>
					{block.id === "infos-responsable-dossier"
						? renderBlockContent(block)
						: (
							<section className={clsx('creationDossier__item', {
								'creationDossier__item--autoFlexBetween': block.id === "votre-dossier",
								'creationDossier__item--singleRows': block.id === "infos-complementaires"
							})}>
								{renderBlockContent(block)}
								{fortissimoTitleCorrect &&
									<div className="errorMessage">
										{t('createFolder.error-title-fortissimo')}
									</div>
								}
							</section>
						)
					}
				</div>
			))}
    	</form>
	);
});

export default InfosProjet;