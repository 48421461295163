import BigNumber from 'bignumber.js';
import { DepenseBlockViewModelDto, FinancementBlockViewModelDto } from '../services/generated/FrontOffice-api';


export const calculBlockBudget = (values:any, isDepense: boolean, itemViewModel:DepenseBlockViewModelDto | FinancementBlockViewModelDto, isIG: boolean, id?: string) => {
	let totalBlock = new BigNumber(0);

	for (const key in values) {
		
		// dataInput[0] => depenses ou financements
		// dataInput[1] => type
		// dataInput[2] => champ
		// dataInput[3] => montant ou intitulé
		const dataInput = key.split('.');
		const value = values[key] === "" ? 0 : values[key];

		if (dataInput[4] && dataInput[4] === id) {
			continue;
		}

		if (isDepense) {
			if (dataInput[0] === 'depenses' && dataInput[1] === itemViewModel.type && dataInput[3] === 'montant' 
			&& ((dataInput[2] != 'masseSalarialeArtistiqueEstimeeContratsVente' && isIG) || !isIG)) {
				
				totalBlock = totalBlock.plus(value)
			}
		} else {
			if (dataInput[0] !== 'depenses' && dataInput[1] === itemViewModel.type && dataInput[3] === 'montant') {
				totalBlock = totalBlock.plus(value)
			}
		}
	}

	return totalBlock.toNumber();
}

export const calculDepensesBudget = (values:any) => {
	let totalDepenses = new BigNumber(0);

	for (const key in values) {
		// dataInput[0] => depenses ou financements
		// dataInput[1] => type
		// dataInput[2] => champ
		// dataInput[3] => montant ou intitulé
		const dataInput = key.split('.');
		const value = values[key] === "" ? 0 :  values[key];
		
		/** Pour le total des dépenses */
		if (dataInput[0] === 'depenses' && dataInput[3] === 'montant') {
			totalDepenses = totalDepenses.plus(value)
		}
	}

	return totalDepenses.toNumber();
}

export const calculFinancementsBudget = (values:any) => {
	let totalFinances = new BigNumber(0);

	for (const key in values) {
		// dataInput[0] => depenses ou financements
		// dataInput[1] => type
		// dataInput[2] => champ
		// dataInput[3] => montant ou intitulé
		const dataInput = key.split('.');
		const value = values[key] === "" ? 0 : values[key];
		
		/** Pour le total des financements */
		if (dataInput[0] !== 'depenses' && dataInput[3] === 'montant') {
			totalFinances = totalFinances.plus(value)
		}
	}

	return totalFinances.toNumber();
}
