import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from "@reach/router";
import { StepRef } from "../CreationDossier";
import { useAxios } from "../../../custom-hooks/useAxios";
import { AidesFonctionnementByYearModel, ChampFinancement, ChiffresClesViewModelDto, CreateChiffresClesDto, DossierClient } from "../../../services/generated/FrontOffice-api";
import { toast } from "react-toastify";
import Input from "adel-shared/dist/components/basics/Input";
import { useForm } from "react-hook-form";
import useValidation from "../../../custom-hooks/useValidation";
import { Dictionary } from "adel-shared/dist/models";

interface ChiffreCleModel {
    chiffreAffaire: string;
    chiffreAffaireN1: string;
    chiffreAffaireN2: string;
    chiffreAffaireN3: string;
    chiffreAffaireN4: string;
    nombreEleveAmateur: string;
    nombreEmployeAdministratif: string;
    nombreEmployeAdministratifETP: string;
    nombreEnseignantCDD: string;
    nombreEnseignantCDI: string;
    nombreEnseignantCDII: string;
    nombreEtudiantAFDAS: string;
    nombreEtudiantCIF: string;
    nombreEtudiantCNFPT: string;
    nombreEtudiantCycleLoisirN1: string;
    nombreEtudiantCycleLoisirN2: string;
    nombreEtudiantCycleLoisirN3: string;
    nombreEtudiantCycleLoisirN4: string;
    nombreEtudiantCyclePro: string;
    nombreEtudiantCycleProfessionnelN1: string;
    nombreEtudiantCycleProfessionnelN2: string;
    nombreEtudiantCycleProfessionnelN3: string;
    nombreEtudiantCycleProfessionnelN4: string;
    nombreEtudiantFormationContinue: string;
    nombreEtudiantFormationInitiale: string;
    nombreMusicienIntermittent: string;
    nombreTotalEnseignant: string;
    nombreTotalEnseignantETP: string;
    salaires: string;
    salairesN1: string;
    salairesN2: string;
    salairesN3: string;
	salairesN4: string;
	drac: string;
	dracN1: string;
	dracN2: string;
	dracN3: string;
	dracN4: string;
	conseilGeneral: string;
	conseilGeneralN1: string;
	conseilGeneralN2: string;
	conseilGeneralN3: string;
	conseilGeneralN4: string;
	conseilRegional: string;
	conseilRegionalN1: string;
	conseilRegionalN2: string;
	conseilRegionalN3: string;
	conseilRegionalN4: string;
	ville: string;
	villeN1: string;
	villeN2: string;
	villeN3: string;
	villeN4: string;
	montantDemandeSPEDIDAM: string;
	montantDemandeSPEDIDAMN1: string;
	montantDemandeSPEDIDAMN2: string;
	montantDemandeSPEDIDAMN3: string;
	montantDemandeSPEDIDAMN4: string;
	fcm: string;
	fcmN1: string;
	fcmN2: string;
	fcmN3: string;
	fcmN4: string;
	sacem: string;
	sacemN1: string;
	sacemN2: string;
	sacemN3: string;
	sacemN4: string;
	adami: string;
	adamiN1: string;
	adamiN2: string;
	adamiN3: string;
	adamiN4: string;
}

interface ChiffreClesProps extends RouteComponentProps {
    dossierId: string;
	setCanGoNext: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
}

const ChiffreCles: ForwardRefExoticComponent<PropsWithoutRef<ChiffreClesProps> & RefAttributes<StepRef>> = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const axiosInstance = useAxios();
    const dossierClient = new DossierClient("", axiosInstance);
    const { register, getValues, setValue, triggerValidation, errors } = useForm<ChiffreCleModel>({ defaultValues: {
		nombreEnseignantCDI: '0',
		nombreEnseignantCDD: '0',
		nombreEnseignantCDII: '0',
		nombreEtudiantCyclePro: '0',
		nombreEtudiantAFDAS: '0',
		nombreEtudiantCNFPT: '0',
		nombreEtudiantCIF: '0',
		nombreEtudiantFormationContinue: '0',
		nombreEtudiantFormationInitiale: '0',
		nombreEleveAmateur: '0'
	}});
    const { getRootValidator } = useValidation();
    const chiffresClesValidator = getRootValidator("CreateChiffresClesDto");

    const [nombreEtudiantCycleProTotal, setNombreEtudiantCycleProTotal] = useState<number>(0);
    const [nombreEleveAmateur, setNombreEleveAmateur] = useState<string>();
    const [dateDebut, setDateDebut] = useState<Date>();
    const [dateFin, setDateFin] = useState<Date>();
    const [initChiffresClesAides, setInitChiffresClesAides] = useState<AidesFonctionnementByYearModel[]>([]);

    const initWithCurrentValues = (chiffresClesViewModel: ChiffresClesViewModelDto, dateDebut:Date, dateFin:Date) => {
        Object.entries(chiffresClesViewModel.chiffresCles).forEach(([key, value]) => {
            setValue(key, value);
			if (key === "nombreEleveAmateur") setNombreEleveAmateur(value);
		});
		
		chiffresClesViewModel.aidesByYear.forEach((item:AidesFonctionnementByYearModel) => {
			switch (item.anneeScolaireDebut) {
				case renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut:
					setValue([
						{ dracN4: `${item.financements.find(e => e.champ === ChampFinancement.Drac)?.montant}`},
						{ conseilGeneralN4: `${item.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.montant}`},
						{ conseilRegionalN4: `${item.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.montant}`},
						{ villeN4: `${item.financements.find(e => e.champ === ChampFinancement.Ville)?.montant}`},
						{ montantDemandeSPEDIDAMN4: `${item.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.montant}`},
						{ fcmN4: `${item.financements.find(e => e.champ === ChampFinancement.Fcm)?.montant}`},
						{ sacemN4: `${item.financements.find(e => e.champ === ChampFinancement.Sacem)?.montant}`},
						{ adamiN4: `${item.financements.find(e => e.champ === ChampFinancement.Adami)?.montant}`}
					]);
					break;
				case renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut:
					setValue([
						{ dracN3: `${item.financements.find(e => e.champ === ChampFinancement.Drac)?.montant}`},
						{ conseilGeneralN3: `${item.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.montant}`},
						{ conseilRegionalN3: `${item.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.montant}`},
						{ villeN3: `${item.financements.find(e => e.champ === ChampFinancement.Ville)?.montant}`},
						{ montantDemandeSPEDIDAMN3: `${item.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.montant}`},
						{ fcmN3: `${item.financements.find(e => e.champ === ChampFinancement.Fcm)?.montant}`},
						{ sacemN3: `${item.financements.find(e => e.champ === ChampFinancement.Sacem)?.montant}`},
						{ adamiN3: `${item.financements.find(e => e.champ === ChampFinancement.Adami)?.montant}`}
					]);
					break;
				case renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut:
					setValue([
						{ dracN2: `${item.financements.find(e => e.champ === ChampFinancement.Drac)?.montant}`},
						{ conseilGeneralN2: `${item.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.montant}`},
						{ conseilRegionalN2: `${item.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.montant}`},
						{ villeN2: `${item.financements.find(e => e.champ === ChampFinancement.Ville)?.montant}`},
						{ montantDemandeSPEDIDAMN2: `${item.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.montant}`},
						{ fcmN2: `${item.financements.find(e => e.champ === ChampFinancement.Fcm)?.montant}`},
						{ sacemN2: `${item.financements.find(e => e.champ === ChampFinancement.Sacem)?.montant}`},
						{ adamiN2: `${item.financements.find(e => e.champ === ChampFinancement.Adami)?.montant}`}
					]);
					break;
				case renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut:
					setValue([
						{ dracN1: `${item.financements.find(e => e.champ === ChampFinancement.Drac)?.montant}`},
						{ conseilGeneralN1: `${item.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.montant}`},
						{ conseilRegionalN1: `${item.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.montant}`},
						{ villeN1: `${item.financements.find(e => e.champ === ChampFinancement.Ville)?.montant}`},
						{ montantDemandeSPEDIDAMN1: `${item.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.montant}`},
						{ fcmN1: `${item.financements.find(e => e.champ === ChampFinancement.Fcm)?.montant}`},
						{ sacemN1: `${item.financements.find(e => e.champ === ChampFinancement.Sacem)?.montant}`},
						{ adamiN1: `${item.financements.find(e => e.champ === ChampFinancement.Adami)?.montant}`}
					]);
					break;
				case renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut:
					setValue([
						{ drac: `${item.financements.find(e => e.champ === ChampFinancement.Drac)?.montant}`},
						{ conseilGeneral: `${item.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.montant}`},
						{ conseilRegional: `${item.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.montant}`},
						{ ville: `${item.financements.find(e => e.champ === ChampFinancement.Ville)?.montant}`},
						{ montantDemandeSPEDIDAM: `${item.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.montant}`},
						{ fcm: `${item.financements.find(e => e.champ === ChampFinancement.Fcm)?.montant}`},
						{ sacem: `${item.financements.find(e => e.champ === ChampFinancement.Sacem)?.montant}`},
						{ adami: `${item.financements.find(e => e.champ === ChampFinancement.Adami)?.montant}`}
					]);
					break;
			}
		})
    }

    const initWithLatestDossierValues = (chiffresClesViewModel: ChiffresClesViewModelDto) => {
        // Note: Arbitrairement c'est l'année de debut qui est utilisée
        // Find all years covered for currentDossier
        let currentDossierCurrentYear = new Date(chiffresClesViewModel.dateDebut).getFullYear();
        let currentDossierYears = [0, 1, 2, 3, 4].map(diff => currentDossierCurrentYear - diff);

        // Find all years covered for latestDossier
        let latestDossierCurrentYear = new Date(chiffresClesViewModel.previousChiffresCles.anneeScolaireDebut).getFullYear();
        let latestDossierYears = [0, 1, 2, 3, 4].map(diff => latestDossierCurrentYear - diff);

        // Find all common years
        let commonYears = currentDossierYears.filter(year => latestDossierYears.includes(year));

        commonYears.forEach(year => {
            // Find currentDossier and latestDossier index
            let currentDossierIndex = currentDossierCurrentYear - year;
            let latestDossierIndex = latestDossierCurrentYear - year;

            // chiffreAffaire
            let currentDossierChiffreAffaireKey = `chiffreAffaireN${currentDossierIndex}`;
            let latestDossierChiffreAffaireValue = (chiffresClesViewModel.previousChiffresCles as Dictionary<string>)[latestDossierIndex === 0 ? `chiffreAffaire` : `chiffreAffaireN${latestDossierIndex}`];
            setValue(currentDossierChiffreAffaireKey, latestDossierChiffreAffaireValue);

            // salaires
            let currentDossierSalairesKey = `salairesN${currentDossierIndex}`;
            let latestDossierSalairesValue = (chiffresClesViewModel.previousChiffresCles as Dictionary<string>)[latestDossierIndex === 0 ? `salaires` : `salairesN${latestDossierIndex}`];
            setValue(currentDossierSalairesKey, latestDossierSalairesValue);

            // nombreEleveAmateur
            let currentDossierNombreEtudiantCycleLoisirKey = `nombreEtudiantCycleLoisirN${currentDossierIndex}`;
            let latestDossierNombreEtudiantCycleLoisirValue = (chiffresClesViewModel.previousChiffresCles as Dictionary<string>)[latestDossierIndex === 0 ? `nombreEleveAmateur` : `nombreEtudiantCycleLoisirN${latestDossierIndex}`];
            setValue(currentDossierNombreEtudiantCycleLoisirKey, latestDossierNombreEtudiantCycleLoisirValue);

            // nombreEtudiantCyclePro
            let currentDossierNombreEtudiantCycleProfessionnelKey = `nombreEtudiantCycleProfessionnelN${currentDossierIndex}`;
            let latestDossierNombreEtudiantCycleProfessionnelValue = (chiffresClesViewModel.previousChiffresCles as Dictionary<string>)[latestDossierIndex === 0 ? `nombreEtudiantCyclePro` : `nombreEtudiantCycleProfessionnelN${latestDossierIndex}`];
            setValue(currentDossierNombreEtudiantCycleProfessionnelKey, latestDossierNombreEtudiantCycleProfessionnelValue);
        });

        // Configure other values
        [
            "nombreEmployeAdministratif",
            "nombreEmployeAdministratifETP",
            "nombreEnseignantCDD",
            "nombreEnseignantCDI",
            "nombreEnseignantCDII",
            "nombreEtudiantAFDAS",
            "nombreEtudiantCIF",
            "nombreEtudiantCNFPT",
            "nombreEtudiantFormationContinue",
            "nombreEtudiantFormationInitiale",
            "nombreMusicienIntermittent",
            "nombreTotalEnseignant",
            "nombreTotalEnseignantETP"
        ].forEach(key => {
            setValue(key, (chiffresClesViewModel.previousChiffresCles as Dictionary<string>)[key]);
        });
    }

    useEffect(() => {
		window.scrollTo(0,0);

        (async function () {
            let chiffresClesViewModel = await dossierClient.getChiffresClesViewModel(props.dossierId);

            setDateDebut(new Date(chiffresClesViewModel.dateDebut));
            setDateFin(new Date(chiffresClesViewModel.dateFin));

            // Initialize chiffresCles avec les valeurs dernier dossier saisi s'il n'existe encore aucune valeurs pour le dossier courant
            if (!!chiffresClesViewModel && !chiffresClesViewModel.chiffresCles && chiffresClesViewModel.previousChiffresCles) {
                initWithLatestDossierValues(chiffresClesViewModel);
            }

            // Initialize chiffresCles avec les valeurs du dossier courant
            if (!!chiffresClesViewModel && chiffresClesViewModel.chiffresCles) {
				initWithCurrentValues(chiffresClesViewModel, new Date(chiffresClesViewModel.dateDebut), new Date(chiffresClesViewModel.dateFin));
				setInitChiffresClesAides(chiffresClesViewModel.aidesByYear);
				const total = chiffresClesViewModel.chiffresCles.nombreEtudiantCyclePro + 
							chiffresClesViewModel.chiffresCles.nombreEtudiantAFDAS +
							chiffresClesViewModel.chiffresCles.nombreEtudiantCNFPT +
							chiffresClesViewModel.chiffresCles.nombreEtudiantCIF +
							chiffresClesViewModel.chiffresCles.nombreEtudiantFormationContinue +
							chiffresClesViewModel.chiffresCles.nombreEtudiantFormationInitiale;
				setNombreEtudiantCycleProTotal(total || 0);
			}

            props.setCanGoNext(true);
        })()
    }, [])

    const projectChiffreCleModelToCreateChiffreCleDto = async (chiffreCleModel: ChiffreCleModel): Promise<CreateChiffresClesDto> => {
        return {
            nombreEnseignantCDI: chiffreCleModel.nombreEnseignantCDI ? parseInt(chiffreCleModel.nombreEnseignantCDI) : 0,
            nombreEnseignantCDD: chiffreCleModel.nombreEnseignantCDD ? parseInt(chiffreCleModel.nombreEnseignantCDD) : 0,
            nombreEnseignantCDII: chiffreCleModel.nombreEnseignantCDII ? parseInt(chiffreCleModel.nombreEnseignantCDII) : 0,
            nombreMusicienIntermittent: chiffreCleModel.nombreMusicienIntermittent ? parseInt(chiffreCleModel.nombreMusicienIntermittent) : 0,
            nombreEmployeAdministratif: chiffreCleModel.nombreEmployeAdministratif ? parseInt(chiffreCleModel.nombreEmployeAdministratif) : 0,
            nombreEmployeAdministratifETP: chiffreCleModel.nombreEmployeAdministratifETP ? parseInt(chiffreCleModel.nombreEmployeAdministratifETP) : 0,
            nombreTotalEnseignant: chiffreCleModel.nombreTotalEnseignant ? parseInt(chiffreCleModel.nombreTotalEnseignant) : 0,
            nombreTotalEnseignantETP: chiffreCleModel.nombreTotalEnseignantETP ? parseInt(chiffreCleModel.nombreTotalEnseignantETP) : 0,
            nombreEtudiantCyclePro: chiffreCleModel.nombreEtudiantCyclePro ? parseInt(chiffreCleModel.nombreEtudiantCyclePro) : 0,
            nombreEtudiantAFDAS: chiffreCleModel.nombreEtudiantAFDAS ? parseInt(chiffreCleModel.nombreEtudiantAFDAS) : 0,
            nombreEtudiantCNFPT: chiffreCleModel.nombreEtudiantCNFPT ? parseInt(chiffreCleModel.nombreEtudiantCNFPT) : 0,
            nombreEtudiantCIF: chiffreCleModel.nombreEtudiantCIF ? parseInt(chiffreCleModel.nombreEtudiantCIF) : 0,
            nombreEtudiantFormationContinue: chiffreCleModel.nombreEtudiantFormationContinue ? parseInt(chiffreCleModel.nombreEtudiantFormationContinue) : 0,
            nombreEtudiantFormationInitiale: chiffreCleModel.nombreEtudiantFormationInitiale ? parseInt(chiffreCleModel.nombreEtudiantFormationInitiale) : 0,
            nombreEleveAmateur: chiffreCleModel.nombreEleveAmateur ? parseInt(chiffreCleModel.nombreEleveAmateur) : 0,
            nombreEtudiantCycleProfessionnelN1: chiffreCleModel.nombreEtudiantCycleProfessionnelN1 ? parseInt(chiffreCleModel.nombreEtudiantCycleProfessionnelN1) : 0,
            nombreEtudiantCycleLoisirN1: chiffreCleModel.nombreEtudiantCycleLoisirN1 ? parseInt(chiffreCleModel.nombreEtudiantCycleLoisirN1) : 0,
            nombreEtudiantCycleProfessionnelN2: chiffreCleModel.nombreEtudiantCycleProfessionnelN2 ? parseInt(chiffreCleModel.nombreEtudiantCycleProfessionnelN2) : 0,
            nombreEtudiantCycleLoisirN2: chiffreCleModel.nombreEtudiantCycleLoisirN2 ? parseInt(chiffreCleModel.nombreEtudiantCycleLoisirN2) : 0,
            nombreEtudiantCycleProfessionnelN3: chiffreCleModel.nombreEtudiantCycleProfessionnelN3 ? parseInt(chiffreCleModel.nombreEtudiantCycleProfessionnelN3) : 0,
            nombreEtudiantCycleLoisirN3: chiffreCleModel.nombreEtudiantCycleLoisirN3 ? parseInt(chiffreCleModel.nombreEtudiantCycleLoisirN3) : 0,
            nombreEtudiantCycleProfessionnelN4: chiffreCleModel.nombreEtudiantCycleProfessionnelN4 ? parseInt(chiffreCleModel.nombreEtudiantCycleProfessionnelN4) : null,
            nombreEtudiantCycleLoisirN4: chiffreCleModel.nombreEtudiantCycleLoisirN4 ? parseInt(chiffreCleModel.nombreEtudiantCycleLoisirN4) : null,
            chiffreAffaire: chiffreCleModel.chiffreAffaire ? parseFloat(chiffreCleModel.chiffreAffaire) : 0,
            salaires: chiffreCleModel.salaires ? parseFloat(chiffreCleModel.salaires) : 0,
            chiffreAffaireN1: chiffreCleModel.chiffreAffaireN1 ? parseFloat(chiffreCleModel.chiffreAffaireN1) : 0,
            salairesN1: chiffreCleModel.salairesN1 ? parseFloat(chiffreCleModel.salairesN1) : 0,
            chiffreAffaireN2: chiffreCleModel.chiffreAffaireN2 ? parseFloat(chiffreCleModel.chiffreAffaireN2) : 0,
            salairesN2: chiffreCleModel.salairesN2 ? parseFloat(chiffreCleModel.salairesN2) : 0,
            chiffreAffaireN3: chiffreCleModel.chiffreAffaireN3 ? parseFloat(chiffreCleModel.chiffreAffaireN3) : 0,
            salairesN3: chiffreCleModel.salairesN3 ? parseFloat(chiffreCleModel.salairesN3) : 0,
            chiffreAffaireN4: chiffreCleModel.chiffreAffaireN4 ? parseFloat(chiffreCleModel.chiffreAffaireN4) : null,
            salairesN4: chiffreCleModel.salairesN4 ? parseFloat(chiffreCleModel.salairesN4) : null,
        };
	}
	
	const aidesFonctionnementByYearToSend = async (chiffreCleModel: ChiffreCleModel): Promise<AidesFonctionnementByYearModel[]> => {
		const aideN4:AidesFonctionnementByYearModel = {
			anneeScolaireDebut: renderAnneesScolairesObject(-4).anneeScolaireDebut,
			anneeScolaireFin: renderAnneesScolairesObject(-4).anneeScolaireFin,
			financements: [
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Drac)?.id || null,
					champ: ChampFinancement.Drac,
					montant: parseFloat(chiffreCleModel.dracN4) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.id || null,
					champ: ChampFinancement.ConseilGeneral, 
					montant: parseFloat(chiffreCleModel.conseilGeneralN4) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.id || null,
					champ: ChampFinancement.ConseilRegional, 
					montant: parseFloat(chiffreCleModel.conseilRegionalN4) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Ville)?.id || null,
					champ: ChampFinancement.Ville, 
					montant: parseFloat(chiffreCleModel.villeN4) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.id || null,
					champ: ChampFinancement.MontantDemandeSPEDIDAM, 
					montant: parseFloat(chiffreCleModel.montantDemandeSPEDIDAMN4) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Fcm)?.id || null,
					champ: ChampFinancement.Fcm, 
					montant: parseFloat(chiffreCleModel.fcmN4) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Sacem)?.id || null,
					champ: ChampFinancement.Sacem, 
					montant: parseFloat(chiffreCleModel.sacemN4) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-4, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Adami)?.id || null,
					champ: ChampFinancement.Adami, 
					montant: parseFloat(chiffreCleModel.adamiN4) || 0
				}
			]
		}

		const aideN3:AidesFonctionnementByYearModel = {
			anneeScolaireDebut: renderAnneesScolairesObject(-3).anneeScolaireDebut,
			anneeScolaireFin: renderAnneesScolairesObject(-3).anneeScolaireFin,
			financements: [
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Drac)?.id || null,
					champ: ChampFinancement.Drac,
					montant: parseFloat(chiffreCleModel.dracN3) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.id || null,
					champ: ChampFinancement.ConseilGeneral, 
					montant: parseFloat(chiffreCleModel.conseilGeneralN3) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.id || null,
					champ: ChampFinancement.ConseilRegional, 
					montant: parseFloat(chiffreCleModel.conseilRegionalN3) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Ville)?.id || null,
					champ: ChampFinancement.Ville, 
					montant: parseFloat(chiffreCleModel.villeN3) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.id || null,
					champ: ChampFinancement.MontantDemandeSPEDIDAM, 
					montant: parseFloat(chiffreCleModel.montantDemandeSPEDIDAMN3) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Fcm)?.id || null,
					champ: ChampFinancement.Fcm, 
					montant: parseFloat(chiffreCleModel.fcmN3) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Sacem)?.id || null,
					champ: ChampFinancement.Sacem, 
					montant: parseFloat(chiffreCleModel.sacemN3) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-3, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Adami)?.id || null,
					champ: ChampFinancement.Adami, 
					montant: parseFloat(chiffreCleModel.adamiN3) || 0
				}
			]
		}

		const aideN2:AidesFonctionnementByYearModel = {
			anneeScolaireDebut: renderAnneesScolairesObject(-2).anneeScolaireDebut,
			anneeScolaireFin: renderAnneesScolairesObject(-2).anneeScolaireFin,
			financements: [
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Drac)?.id || null,
					champ: ChampFinancement.Drac,
					montant: parseFloat(chiffreCleModel.dracN2) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.id || null,
					champ: ChampFinancement.ConseilGeneral, 
					montant: parseFloat(chiffreCleModel.conseilGeneralN2) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.id || null,
					champ: ChampFinancement.ConseilRegional, 
					montant: parseFloat(chiffreCleModel.conseilRegionalN2) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Ville)?.id || null,
					champ: ChampFinancement.Ville, 
					montant: parseFloat(chiffreCleModel.villeN2) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.id || null,
					champ: ChampFinancement.MontantDemandeSPEDIDAM, 
					montant: parseFloat(chiffreCleModel.montantDemandeSPEDIDAMN2) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Fcm)?.id || null,
					champ: ChampFinancement.Fcm, 
					montant: parseFloat(chiffreCleModel.fcmN2) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Sacem)?.id || null,
					champ: ChampFinancement.Sacem, 
					montant: parseFloat(chiffreCleModel.sacemN2) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-2, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Adami)?.id || null,
					champ: ChampFinancement.Adami, 
					montant: parseFloat(chiffreCleModel.adamiN2) || 0
				}
			]
		}

		const aideN1:AidesFonctionnementByYearModel = {
			anneeScolaireDebut: renderAnneesScolairesObject(-1).anneeScolaireDebut,
			anneeScolaireFin: renderAnneesScolairesObject(-1).anneeScolaireFin,
			financements: [
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Drac)?.id || null,
					champ: ChampFinancement.Drac,
					montant: parseFloat(chiffreCleModel.dracN1) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.id || null,
					champ: ChampFinancement.ConseilGeneral, 
					montant: parseFloat(chiffreCleModel.conseilGeneralN1) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.id || null,
					champ: ChampFinancement.ConseilRegional, 
					montant: parseFloat(chiffreCleModel.conseilRegionalN1) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Ville)?.id || null,
					champ: ChampFinancement.Ville, 
					montant: parseFloat(chiffreCleModel.villeN1) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.id || null,
					champ: ChampFinancement.MontantDemandeSPEDIDAM, 
					montant: parseFloat(chiffreCleModel.montantDemandeSPEDIDAMN1) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Fcm)?.id || null,
					champ: ChampFinancement.Fcm, 
					montant: parseFloat(chiffreCleModel.fcmN1) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Sacem)?.id || null,
					champ: ChampFinancement.Sacem, 
					montant: parseFloat(chiffreCleModel.sacemN1) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(-1, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Adami)?.id || null,
					champ: ChampFinancement.Adami, 
					montant: parseFloat(chiffreCleModel.adamiN1) || 0
				}
			]
		}

		const aide:AidesFonctionnementByYearModel = {
			anneeScolaireDebut: renderAnneesScolairesObject().anneeScolaireDebut,
			anneeScolaireFin: renderAnneesScolairesObject().anneeScolaireFin,
			financements: [
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Drac)?.id || null,
					champ: ChampFinancement.Drac,
					montant: parseFloat(chiffreCleModel.drac) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilGeneral)?.id || null,
					champ: ChampFinancement.ConseilGeneral, 
					montant: parseFloat(chiffreCleModel.conseilGeneral) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.ConseilRegional)?.id || null,
					champ: ChampFinancement.ConseilRegional, 
					montant: parseFloat(chiffreCleModel.conseilRegional) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Ville)?.id || null,
					champ: ChampFinancement.Ville, 
					montant: parseFloat(chiffreCleModel.ville) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.MontantDemandeSPEDIDAM)?.id || null,
					champ: ChampFinancement.MontantDemandeSPEDIDAM, 
					montant: parseFloat(chiffreCleModel.montantDemandeSPEDIDAM) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Fcm)?.id || null,
					champ: ChampFinancement.Fcm, 
					montant: parseFloat(chiffreCleModel.fcm) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Sacem)?.id || null,
					champ: ChampFinancement.Sacem, 
					montant: parseFloat(chiffreCleModel.sacem) || 0
				},
				{
					id: initChiffresClesAides.find(e => e.anneeScolaireDebut === renderAnneesScolairesObject(0, dateDebut, dateFin).anneeScolaireDebut)?.financements.find(e => e.champ === ChampFinancement.Adami)?.id || null,
					champ: ChampFinancement.Adami, 
					montant: parseFloat(chiffreCleModel.adami) || 0
				}
			]
		}

        return [aide, aideN1, aideN2, aideN3, aideN4];
	}

    useImperativeHandle(ref, () => ({
        async validateForm(): Promise<boolean> {
			props.setIsLoading(true);
            try {
                let model = getValues();
                let isValid = await triggerValidation();
				let createChiffresClesDto = await projectChiffreCleModelToCreateChiffreCleDto(model);
				let aidesFonctionnementByYearDto = await aidesFonctionnementByYearToSend(model);

                if (isValid) {
					await dossierClient.createChiffresCles(props.dossierId, createChiffresClesDto);
					await dossierClient.createOrUpdateAidesFonctionnement(props.dossierId, {aidesByYear:aidesFonctionnementByYearDto});
					props.setIsLoading(false);
                    return true;
                }
				toast.error(t("validation-messages.invalid-form"));
				props.setIsLoading(false);
				return false;

            } catch (error) {
				if (error.exception?.message) {
					toast.error(error.exception.message);
				}
				props.setIsLoading(false);
                return false;
            }
        }
    }));

    const renderAnneesScolaires = (yearsToSubstract: number = 0): string => {
        if (!dateDebut || !dateFin) return "";
        return `${dateDebut.getFullYear() + yearsToSubstract} - ${dateFin.getFullYear() + yearsToSubstract}`;
	};

	const renderAnneesScolairesObject = (yearsToSubstract: number = 0, debut?:Date, fin?:Date): {
		anneeScolaireDebut?: number,
		anneeScolaireFin?: number
	} => {
		if (debut && fin) {
			return {
				anneeScolaireDebut: debut.getFullYear() + yearsToSubstract,
				anneeScolaireFin: fin.getFullYear() + yearsToSubstract
			}
		} else {
			if (!dateDebut || !dateFin) return {};
			return {
				anneeScolaireDebut: dateDebut.getFullYear() + yearsToSubstract,
				anneeScolaireFin: dateFin.getFullYear() + yearsToSubstract
			}
		}
	};
	
	

	const setNombreTotalEtudiantPro = useCallback(() => {
		const total = parseInt(getValues().nombreEtudiantCyclePro) + 
					parseInt(getValues().nombreEtudiantAFDAS) +
					parseInt(getValues().nombreEtudiantCNFPT) +
					parseInt(getValues().nombreEtudiantCIF) +
					parseInt(getValues().nombreEtudiantFormationContinue) +
					parseInt(getValues().nombreEtudiantFormationInitiale);
		setNombreEtudiantCycleProTotal(total || 0);
	}, [nombreEtudiantCycleProTotal, setNombreEtudiantCycleProTotal]);



    return (
        <div className="creationDossier__chiffreCles">
            <div className="creationDossier__header">
                <h3 className="title--dark">{t("createFolder.menu.chiffresCles")}</h3>
            </div>
            <section className="creationDossier__item">
                <h3>{t('createFolder.enseignants.chiffreCles.title')}</h3>
                <h2>{t('createFolder.enseignants.chiffreCles.nombreProfesseurs')}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreEnseignantCDI"
                        label={t('createFolder.enseignants.chiffreCles.nombreEnseignantCDI')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEnseignantCDI"])}
                        errors={errors}
                    />
                    <Input name="nombreEnseignantCDD"
                        label={t('createFolder.enseignants.chiffreCles.nombreEnseignantCDD')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEnseignantCDD"])}
                        errors={errors}
                    />
                    <Input name="nombreEnseignantCDII"
                        label={t('createFolder.enseignants.chiffreCles.nombreEnseignantCDII')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEnseignantCDII"])}
                        errors={errors}
                    />
                </div>
                <h2>{t('createFolder.enseignants.chiffreCles.musiciens')}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreMusicienIntermittent"
                        label={""}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreMusicienIntermittent"])}
                        errors={errors}
                    />
                </div>
                <h2>{t('createFolder.enseignants.chiffreCles.administratifs')}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreEmployeAdministratif"
                        label={t('createFolder.enseignants.chiffreCles.nombre')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEmployeAdministratif"])}
                        errors={errors}
                    />
                    <Input name="nombreEmployeAdministratifETP"
                        label={t('createFolder.enseignants.chiffreCles.nombreETP')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEmployeAdministratifETP"])}
                        errors={errors}
                    />
                </div>
                <h2>{t('createFolder.enseignants.chiffreCles.totalProfesseurs')}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreTotalEnseignant"
                        label={t('createFolder.enseignants.chiffreCles.nombre')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreTotalEnseignant"])}
                        errors={errors}
                    />
                    <Input name="nombreTotalEnseignantETP"
                        label={t('createFolder.enseignants.chiffreCles.nombreETP')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreTotalEnseignantETP"])}
                        errors={errors}
                    />
                </div>
            </section>

            <section className="creationDossier__item">
                <h3>{t('createFolder.enseignants.chiffreCles.informationsComplementaires')}</h3>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantCyclePro"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCyclePro')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCyclePro"])}
                        errors={errors}
                        onChange={() => setNombreTotalEtudiantPro() }
                    />
                    <Input name="nombreEtudiantAFDAS"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantAFDAS')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantAFDAS"])}
						errors={errors}
						onChange={() => setNombreTotalEtudiantPro() }
                    />
                </div>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantCNFPT"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCNFPT')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCNFPT"])}
						errors={errors}
						onChange={() => setNombreTotalEtudiantPro() }
                    />
                    <Input name="nombreEtudiantCIF"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCIF')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCIF"])}
						errors={errors}
						onChange={() => setNombreTotalEtudiantPro() }
                    />
                </div>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantFormationContinue"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantFormationContinue')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantFormationContinue"])}
						errors={errors}
						onChange={() => setNombreTotalEtudiantPro() }
                    />
                    <Input name="nombreEtudiantFormationInitiale"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantFormationInitiale')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantFormationInitiale"])}
						errors={errors}
						onChange={() => setNombreTotalEtudiantPro() }
                    />
                </div>
                <div className="creationDossier__row">
                    <Input name="nombreEleveAmateur"
                        label={t('createFolder.enseignants.chiffreCles.nombreEleveAmateur')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEleveAmateur"])}
                        errors={errors}
                        onChange={(value: string) => { setNombreEleveAmateur(value); }}
                    />
                </div>
            </section>

            <section className="creationDossier__item">
                <h3>{t('createFolder.enseignants.chiffreCles.evolutionNombreEleves')}</h3>
                <span>{t('createFolder.enseignants.chiffreCles.parAnneesScolairesDepuis')}</span>
                <h2>{renderAnneesScolaires(-4)}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantCycleProfessionnelN4"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleProfessionnel')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleProfessionnelN4"])}
                        errors={errors}
                    />
                    <Input name="nombreEtudiantCycleLoisirN4"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleLoisir')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleLoisirN4"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires(-3)}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantCycleProfessionnelN3"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleProfessionnel')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleProfessionnelN3"])}
                        errors={errors}
                    />
                    <Input name="nombreEtudiantCycleLoisirN3"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleLoisir')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleLoisirN3"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires(-2)}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantCycleProfessionnelN2"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleProfessionnel')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleProfessionnelN2"])}
                        errors={errors}
                    />
                    <Input name="nombreEtudiantCycleLoisirN2"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleLoisir')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleLoisirN2"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires(-1)}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantCycleProfessionnelN1"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleProfessionnel')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleProfessionnelN1"])}
                        errors={errors}
                    />
                    <Input name="nombreEtudiantCycleLoisirN1"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleLoisir')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["NombreEtudiantCycleLoisirN1"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires()}</h2>
                <div className="creationDossier__row">
                    <Input name="nombreEtudiantCycleProfessionnelCurrentYear"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleProfessionnel')}
                        type="number"
                        value={nombreEtudiantCycleProTotal}
						disabled={true}
						reference={register({
							validate: value => nombreEtudiantCycleProTotal > 0 || `${t('createFolder.enseignants.chiffreCles.errors.etudiantPro')}`
						})}
                    />
                    <Input name="nombreEleveAmateur"
                        label={t('createFolder.enseignants.chiffreCles.nombreEtudiantCycleLoisir')}
                        type="number"
                        value={nombreEleveAmateur}
                        disabled={true}
                    />
                </div>
				{(!nombreEtudiantCycleProTotal || nombreEtudiantCycleProTotal <= 0) &&
					<div className="errorMessage">{t('createFolder.enseignants.chiffreCles.errors.etudiantPro')}</div>
				}
            </section>

            <section className="creationDossier__item">
                <h3>{t('createFolder.enseignants.chiffreCles.evolutionChiffreAffaires')}</h3>
                <span>{t('createFolder.enseignants.chiffreCles.parAnneesScolairesDepuis')}</span>
                <h2>{renderAnneesScolaires(-4)}</h2>
                <div className="creationDossier__row">
                    <Input name="chiffreAffaireN4"
                        label={t('createFolder.enseignants.chiffreCles.chiffreAffaire')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["ChiffreAffaireN4"])}
                        errors={errors}
                    />
                    <Input name="salairesN4"
                        label={t('createFolder.enseignants.chiffreCles.salaires')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["SalairesN4"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires(-3)}</h2>
                <div className="creationDossier__row">
                    <Input name="chiffreAffaireN3"
                        label={t('createFolder.enseignants.chiffreCles.chiffreAffaire')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["ChiffreAffaireN3"])}
                        errors={errors}
                    />
                    <Input name="salairesN3"
                        label={t('createFolder.enseignants.chiffreCles.salaires')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["SalairesN3"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires(-2)}</h2>
                <div className="creationDossier__row">
                    <Input name="chiffreAffaireN2"
                        label={t('createFolder.enseignants.chiffreCles.chiffreAffaire')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["ChiffreAffaireN2"])}
                        errors={errors}
                    />
                    <Input name="salairesN2"
                        label={t('createFolder.enseignants.chiffreCles.salaires')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["SalairesN2"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires(-1)}</h2>
                <div className="creationDossier__row">
                    <Input name="chiffreAffaireN1"
                        label={t('createFolder.enseignants.chiffreCles.chiffreAffaire')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["ChiffreAffaireN1"])}
                        errors={errors}
                    />
                    <Input name="salairesN1"
                        label={t('createFolder.enseignants.chiffreCles.salaires')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register(chiffresClesValidator && chiffresClesValidator["SalairesN1"])}
                        errors={errors}
                    />
                </div>
                <h2>{renderAnneesScolaires()}</h2>
                <div className="creationDossier__row">
                    <Input name="chiffreAffaire"
                        label={t('createFolder.enseignants.chiffreCles.chiffreAffaire')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                        errors={errors}
                    />
                    <Input name="salaires"
                        label={t('createFolder.enseignants.chiffreCles.salaires')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                        errors={errors}
                    />
                </div>
            </section>

			<section className="creationDossier__item">
                <h3>{t('createFolder.enseignants.chiffreCles.aidesdefonctionnement')}</h3>
                <span>{t('createFolder.enseignants.chiffreCles.parAnneesScolairesDepuis')}</span>
                <h2>{renderAnneesScolaires(-4)}</h2>
                <div className="creationDossier__row">
                    <Input name="dracN4"
                        label={t('createFolder.enseignants.chiffreCles.drac')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="conseilGeneralN4"
                        label={t('createFolder.enseignants.chiffreCles.conseilGeneral')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="conseilRegionalN4"
                        label={t('createFolder.enseignants.chiffreCles.conseilRegional')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="villeN4"
                        label={t('createFolder.enseignants.chiffreCles.ville')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="montantDemandeSPEDIDAMN4"
                        label={t('createFolder.enseignants.chiffreCles.montantDemandeSPEDIDAM')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="fcmN4"
                        label={t('createFolder.enseignants.chiffreCles.fcm')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="sacemN4"
                        label={t('createFolder.enseignants.chiffreCles.sacem')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="adamiN4"
                        label={t('createFolder.enseignants.chiffreCles.adami')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>

                <h2>{renderAnneesScolaires(-3)}</h2>
                <div className="creationDossier__row">
                    <Input name="dracN3"
                        label={t('createFolder.enseignants.chiffreCles.drac')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="conseilGeneralN3"
                        label={t('createFolder.enseignants.chiffreCles.conseilGeneral')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="conseilRegionalN3"
                        label={t('createFolder.enseignants.chiffreCles.conseilRegional')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="villeN3"
                        label={t('createFolder.enseignants.chiffreCles.ville')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="montantDemandeSPEDIDAMN3"
                        label={t('createFolder.enseignants.chiffreCles.montantDemandeSPEDIDAM')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="fcmN3"
                        label={t('createFolder.enseignants.chiffreCles.fcm')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="sacemN3"
                        label={t('createFolder.enseignants.chiffreCles.sacem')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="adamiN3"
                        label={t('createFolder.enseignants.chiffreCles.adami')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>

                <h2>{renderAnneesScolaires(-2)}</h2>
                <div className="creationDossier__row">
                    <Input name="dracN2"
                        label={t('createFolder.enseignants.chiffreCles.drac')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="conseilGeneralN2"
                        label={t('createFolder.enseignants.chiffreCles.conseilGeneral')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="conseilRegionalN2"
                        label={t('createFolder.enseignants.chiffreCles.conseilRegional')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="villeN2"
                        label={t('createFolder.enseignants.chiffreCles.ville')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="montantDemandeSPEDIDAMN2"
                        label={t('createFolder.enseignants.chiffreCles.montantDemandeSPEDIDAM')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="fcmN2"
                        label={t('createFolder.enseignants.chiffreCles.fcm')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="sacemN2"
                        label={t('createFolder.enseignants.chiffreCles.sacem')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="adamiN2"
                        label={t('createFolder.enseignants.chiffreCles.adami')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>

                <h2>{renderAnneesScolaires(-1)}</h2>
                <div className="creationDossier__row">
                    <Input name="dracN1"
                        label={t('createFolder.enseignants.chiffreCles.drac')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="conseilGeneralN1"
                        label={t('createFolder.enseignants.chiffreCles.conseilGeneral')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="conseilRegionalN1"
                        label={t('createFolder.enseignants.chiffreCles.conseilRegional')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="villeN1"
                        label={t('createFolder.enseignants.chiffreCles.ville')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="montantDemandeSPEDIDAMN1"
                        label={t('createFolder.enseignants.chiffreCles.montantDemandeSPEDIDAM')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="fcmN1"
                        label={t('createFolder.enseignants.chiffreCles.fcm')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="sacemN1"
                        label={t('createFolder.enseignants.chiffreCles.sacem')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="adamiN1"
                        label={t('createFolder.enseignants.chiffreCles.adami')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>

                <h2>{renderAnneesScolaires()}</h2>
				<div className="creationDossier__row">
                    <Input name="drac"
                        label={t('createFolder.enseignants.chiffreCles.drac')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="conseilGeneral"
                        label={t('createFolder.enseignants.chiffreCles.conseilGeneral')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="conseilRegional"
                        label={t('createFolder.enseignants.chiffreCles.conseilRegional')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="ville"
                        label={t('createFolder.enseignants.chiffreCles.ville')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="montantDemandeSPEDIDAM"
                        label={t('createFolder.enseignants.chiffreCles.montantDemandeSPEDIDAM')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="fcm"
                        label={t('createFolder.enseignants.chiffreCles.fcm')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
				<div className="creationDossier__row">
                    <Input name="sacem"
                        label={t('createFolder.enseignants.chiffreCles.sacem')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                    <Input name="adami"
                        label={t('createFolder.enseignants.chiffreCles.adami')}
                        type="number"
                        step={1}
                        min={0}
                        reference={register}
                    />
                </div>
            </section>

        </div>
    );
});

export default ChiffreCles;