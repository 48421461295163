import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useAxios } from '../../../custom-hooks/useAxios';
import { StructureClient, DocumentDto, TypeDeDocumentDto, DocumentUploadInfoDto } from '../../../services/generated/FrontOffice-api';
import { AdelStructureClient } from '../../../clients/AdelStructureClient';
import { useForm } from 'react-hook-form';
import InputDocuments from "../../basics/InputDocuments/InputDocuments";
import { Dictionary } from "adel-shared/dist/models";
import { IFile } from "../../../models/IFile";
import { toast } from 'react-toastify';


interface ValidationPart2Props extends RouteComponentProps {
	ref: any,
	setCanGoNext: (value: boolean) => void;
	validateOnLoad: boolean;
	setIsLoading: (value: boolean) => void;
}

const ValidationPart2: React.FunctionComponent<ValidationPart2Props> = forwardRef(({
	setCanGoNext,
	validateOnLoad,
	setIsLoading
}, ref) => {
	const { t } = useTranslation();
	const form = useForm();
	const { getValues, setValue, triggerValidation } = form;

	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);
	const adelStructureClient = new AdelStructureClient("", axiosInstance);
	const [typeDeDocuments, setTypeDeDocuments] = useState<TypeDeDocumentDto[]>([]);		// Required documents (and not only existing) to show the user if new documents are required
	const [documents, setDocuments] = useState<DocumentDto[]>([]);
	const [dirtyFields, setDirtyFields] = useState<string[]>([]);							// Used to keep track of modified documents (which will need to be updated)
	const [isDirty, setIsDirty] = useState<boolean>(false);

	useEffect(() => {
		/** Get Document Infos */
		(async () => {
			try {
				var types = await structureClient.getStructureTypesDeDocument();

				types.map(d => {
					d.code = d.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

					if(d.extraInfos && d.extraInfos.length > 0) {
						d.extraInfos.map(e => {
							e.code = e.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
						})
					}

					if(d.choix && d.choix.length > 0) {
						d.choix.map(f => {
							f.code = f.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

							if (f.extraInfos && f.extraInfos.length > 0) {
								f.extraInfos.map(g => g.code = g.code.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
							}
						})
					}
				});
				setTypeDeDocuments(types);

				var docs = await structureClient.getDocuments();
				setDocuments(docs);
				setCanGoNext(true);
				if(validateOnLoad) {
					const timer = setTimeout(async () => {
						let result = await triggerValidation();
						console.debug("Triggering Validation onload", result);
					}, 0);
					return () => clearTimeout(timer);
				}
			} catch (error) {
				if (error.exception?.message) {
					toast.error(error.exception.message);
				}
			}
		})();
	}, []);

	/** Post */
	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			try {
				let isValid = undefined;
				if (isValid !== false) {
					if (isDirty) {
						// Need to Update documents so format documents and files;
						const [modifiedDocuments, files] = convertDocumentsAndFilesToDtosByRef();
						setIsLoading(true);
						try {
							await adelStructureClient.updateStructureDocuments({ documents: modifiedDocuments }, files);
							setIsLoading(false);
						} catch (err) {
							toast.error(err);
							isValid = false;
							setIsLoading(false);
						}
					}
				}
				return isValid !== false ? true : isValid;
			} catch(error) {}
		}
	}));

	const convertDocumentsAndFilesToDtosByRef = () => {
		const formFiles: Dictionary<FileList> = getValues();
		const modifiedDocuments: DocumentUploadInfoDto[] = [];
		const files: IFile[] = [];

		typeDeDocuments.forEach(typeDeDocument => {
			let correspondingDocument: DocumentDto;

			if (typeDeDocument.hasChoix) {
				correspondingDocument = documents.find(document => typeDeDocument.choix.some(choix => document.type.id === choix.id));
			} else {
				correspondingDocument = documents.find(document => document.type.id === typeDeDocument.id);
			}

			let typeIdDoc = '';
			if(correspondingDocument) {
				typeIdDoc = correspondingDocument.type.id;
			} else {
				if(typeDeDocument.hasChoix) {
					typeIdDoc = typeDeDocument.choix.find(choix => !choix.hasAttachment).id;
				} else {
					typeIdDoc = '';
				}
			}

			// s'il n'a pas de document correspondant, lui donner l'id du type de document qui n'a pas de corresponding document
			let modifiedDocument: DocumentUploadInfoDto = {
				typeId: typeIdDoc
			};

			if (correspondingDocument?.id) {
				if (correspondingDocument?.extraInfo && correspondingDocument?.extraInfo.length > 0) {
					modifiedDocument.extraInfos = {};

					correspondingDocument.extraInfo.forEach(extraInfo => {
						modifiedDocument.extraInfos[extraInfo.code] = extraInfo.value;
					});
				}
				modifiedDocuments.push({ id: correspondingDocument.id, typeId: correspondingDocument.type.id, extraInfos: modifiedDocument.extraInfos });
				return;
			} else {
				// Do nothing: id should not be specified for new or modified documents.
			}

			// If there's a corresponding file,
			let correspondingFile = formFiles[typeDeDocument.code];

			if (typeDeDocument.hasChoix) {
				typeDeDocument.choix.forEach(choix => {
					if (formFiles.hasOwnProperty(choix.code)) {
						correspondingFile = formFiles[choix.code];

						if(correspondingFile) {
							const modifiedFile: IFile = {
								code: correspondingDocument.type.code,
								file: correspondingFile[0]
							} as IFile;

							modifiedDocument.partName = correspondingDocument.type.code;

							if(!files.some(x => x.code === modifiedFile.code)) {
								files.push(modifiedFile);
							}
						}

						if (correspondingDocument?.extraInfo && correspondingDocument?.extraInfo.length > 0) {
							modifiedDocument.extraInfos = {};

							correspondingDocument.extraInfo.forEach(extraInfo => {
								modifiedDocument.extraInfos[extraInfo.code] = extraInfo.value;
							});
						}

						if(!modifiedDocuments.some(x => x.typeId === modifiedDocument.typeId)) {
							modifiedDocuments.push(modifiedDocument);
						}
					}
				});
			} else {
				if (correspondingFile && correspondingFile[0] && !correspondingDocument.id) {
					const modifiedFile: IFile = {
						code: correspondingDocument.type.code,
						file: correspondingFile[0]
					} as IFile;

					modifiedDocument.partName = correspondingDocument.type.code;

					files.push(modifiedFile);
				}

				if (correspondingDocument?.extraInfo && correspondingDocument?.extraInfo.length > 0) {
					modifiedDocument.extraInfos = {};

					correspondingDocument.extraInfo.forEach(extraInfo => {
						modifiedDocument.extraInfos[extraInfo.code] = extraInfo.value;
					});
				}

				modifiedDocuments.push(modifiedDocument);
			}
		});

		return [
			modifiedDocuments,
			files
		];
	}

	// New simplified way to handle documents
	const handleDocumentChange = (modifiedDocument: DocumentDto, file?: File) => {
		let updatedDocuments = upsertDocument(documents, modifiedDocument, file);

		setDocuments(updatedDocuments);
		setIsDirty(true);
	}

	const upsertDocument = (documents: DocumentDto[], modifiedDocument: DocumentDto, file?: File): DocumentDto[] => {

		let existingDocument = documents.find(document => document.type.id == modifiedDocument.type.id);

		if (!existingDocument) {

			// INSERT

			return [...documents, modifiedDocument];

		} else {

			// UPDATE by merging properties using modification by reference ><

			if (modifiedDocument.fileName) existingDocument.fileName = modifiedDocument.fileName;

			if (modifiedDocument.extraInfo) existingDocument.extraInfo = modifiedDocument.extraInfo;

			return documents;

		}
	}

	const onReset = (fieldName: string,) => {
		setValue(fieldName, '');
		setDocuments(documents.filter(d => d.type.code != fieldName));
		if (dirtyFields.findIndex(field => field === fieldName) <= -1) {
			setDirtyFields([...dirtyFields, fieldName]);
		}
	}

	return (
		<>
			<h3>{t("createFolder.validation.pieces")}</h3>
			<div className="creationDossier__item creationDossier__validation">
				<InputDocuments
					typeDeDocuments={typeDeDocuments}
					documents={documents}
					onDocumentChange={handleDocumentChange}
					form={form}
					onReset={onReset}
				/>
			</div>

		</>
	)
});

export default ValidationPart2;
