import React from "react";
import { getAnswer } from '../RenderBlockContent';
const ArrayQuestionInputItem = (props) => {
    return React.createElement("div", { className: "creationDossier__row" }, props.questions.map((question, i) => {
        var _a;
        const currentValue = getAnswer(question, (_a = props.item) !== null && _a !== void 0 ? _a : {});
        return React.createElement("div", { key: i }, props.renderQuestionInput(question, //question: QuestionDto
        currentValue, question === null || question === void 0 ? void 0 : question.id));
    }));
};
export default ArrayQuestionInputItem;
