import React, { useState, useEffect, useRef } from "react";
import { ErrorMessage } from "react-hook-form";
import DatePicker from 'react-date-picker';
const InputCalendarManual = ({ defaultDate, onDateSelected, label, minDate, maxDate, errors, name }) => {
    const ref = useRef();
    const [date, setDate] = useState(defaultDate);
    useEffect(() => {
        setDate(defaultDate);
    }, [defaultDate]);
    return React.createElement("div", { className: "inputFile__calendarManualWrapper", ref: ref },
        React.createElement("label", null, label),
        React.createElement("div", { className: "inputFile__calendarManual" },
            React.createElement(DatePicker, { onChange: (date) => {
                    onDateSelected(date);
                }, value: date, locale: "fr-FR", minDate: minDate, maxDate: maxDate, clearIcon: null, calendarIcon: null }),
            React.createElement("i", { className: "far fa-calendar-alt" })),
        errors &&
            React.createElement(ErrorMessage, { errors: errors, name: name }, ({ message }) => React.createElement("p", { className: "input__errorMessage" }, message)));
};
export default InputCalendarManual;
