import React, { useState, useEffect, FunctionComponent, ChangeEvent, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import FormButton from "adel-shared/dist/components/basics/FormButton";
import { StructureClient } from "../../services/generated/FrontOffice-api";
import { toast } from "react-toastify";
import useToggleState from "adel-shared/dist/custom-hooks/useToggleState";
import { AppStateContext } from '../../context/AppContext';
import { ActionTypeEnum } from '../../context/ActionType';
import { useAxios } from "../../custom-hooks/useAxios";

interface MonCompteInfosProps extends RouteComponentProps {
}

const MonCompteInfos: FunctionComponent<MonCompteInfosProps> = () => {
	const { t } = useTranslation();
	const [editInput, toggleEditInput] = useToggleState(false);
	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);
	const [{structure}, dispatch] = useContext(AppStateContext);
	const [infos, setInfos] = useState<string>(structure.informations);

	useEffect(() => {
		setInfos(structure.informations);
	}, [structure.informations]);

	const handleInfosChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setInfos(e.target.value);
	};

	const handleCancel = () => {
		toggleEditInput();
		setInfos(structure.informations);
	};

	const handleSubmit = async() => {
		try {
			await structureClient.updateStructureInformations({
				informations: infos
			});
			dispatch({
				type: ActionTypeEnum.UPDATE_STRUCTURE_INFOS,
				payload: {informations: infos}
			});
			toast.success(t('myAccount.structureUpdateSuccess'));
		} catch {
			toast.error(t('myAccount.structureUpdateError'));
		}
	};


	return (
		<>
			<div className="monCompte__header">
				<h3>{t("myAccount.infoStructure")}</h3>
				{!editInput && (
					<i
						className="far fa-edit"
						role="button"
						title="Modifier"
						onClick={toggleEditInput}
					></i>
				)}
			</div>
			<div className="textarea__item">
				<label>{t("myAccount.form.informations")}</label>

				<textarea
					value={infos}
					disabled={!editInput}
					onChange={handleInfosChange}
				>
				</textarea>
			</div>
			{editInput && (
				<div className="monCompte__editFooter">
					<FormButton
						type="button"
						onClick={handleCancel}
						value="Annuler"
					/>
					<FormButton
						type="button"
						onClick={handleSubmit}
						value="Valider"
					/>
				</div>
			)}
		</>
	)
};

export default MonCompteInfos;
