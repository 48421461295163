import React from "react";
import QuestionInputProps from "./QuestionInputProps"

const MultilineTextQuestionInput : React.FunctionComponent<QuestionInputProps> = (props) => {
	return <div className="questionField">
		<div className="questionField__title">{props.title ? props.title : ""}{props.question?.isRequired ? ' *' : ''}</div>

        <textarea
               id={props.id}
               className="textarea"
               minLength={props.question?.answerMinLength}
               maxLength={props.question?.answerMaxLength}
               value={props.currentValue}
               readOnly={!(props.question?.presetAnswer?.isModifiable ?? true)}
               required={props.question?.isRequired}
               onChange={e => {
                   const value = e.target.value;
                    if (props.onAnswerChange) {
                        props.onAnswerChange(props.question?.id, value);
                    }
			   }} />
		{props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '' &&
			<p className="input__errorMessage">{props.errorMessage.message}</p>
		}
    </div>;
}

export default MultilineTextQuestionInput;
