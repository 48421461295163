import React from "react";
import { QuestionDto} from "../../../../services/generated/FrontOffice-api";
import { Dictionary } from '../../../../models';
import { getAnswer } from '../questionsBlock/RenderBlockContent';
import { ValidationResult } from '../../../../helpers/questionHelpers';

interface ArrayQuestionInputItemProps{
	index: number;
	item: Dictionary<any>;
	renderQuestionInput: any;
	questions: QuestionDto[];
	onItemChange: (value: Dictionary<any>) => void;
	validationResults: Dictionary<ValidationResult>;
	delete: (index: number) => void;
}

const ArrayQuestionInputItem: React.FC<ArrayQuestionInputItemProps> = ({
	index,
	item,
	questions,
	renderQuestionInput,
	onItemChange,
	delete: deleteItem,
	validationResults
}) => {
	const onAnswerChange = (id:string, value:any) => {
		const newItem = {...item};
		newItem[id] = value;
		onItemChange(newItem);
	}

	return (
		<div className="creationDossier__row">
			{questions.map((question, i) => {
				const currentValue = getAnswer(question, item ?? {});
				return (
					<div key={i}>
						{renderQuestionInput(
							question, //question: QuestionDto
							currentValue, // currentValue: any
							question.id, //id: string
							validationResults ? validationResults[question.id] : {}, // errorMessage: ValidationResult
							onAnswerChange
						)}
					</div>
				);
			})}
			<div className="creationDossier__addElement elementButton--absolute">
				<div className="creationDossier__addElementButton" onClick={() => deleteItem(index)}>
					<i className="far fa-minus-circle" />
				</div>
			</div>
		</div>
	)
}

export default ArrayQuestionInputItem;