import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {Dictionary} from "../models";
import {ApiException, Civilite, BudgetDto, EnseignantDto, QualificationEnseignant, TypeContrat, StatutEnseignant, DocumentUploadInfoDto, SalarieDto, CreateOrUpdateAdresseDto, Equipements, TypesAdresses, TrajetValidationDto, UpdateDepenseDto } from "../services/generated/FrontOffice-api";
import { IFile, IFileBudget } from '../models/IFile';
import { CreateBudgetDto, UpdateBudgetDto } from '../models/IDossier';

export interface CreateOrUpdateResponsableDossierDto {
    civilite?: Civilite;
    nom?: string | undefined;
    prenom?: string | undefined;
    email?: string | undefined;
    telephoneFixe?: string | undefined;
    telephonePortable?: string | undefined;
}

export interface SubmitInfosProjetAnswersDto {
    answers: Dictionary<any>;
}
export interface SubmitDescriptionProjetAnswersDto {
    answers: Dictionary<any>;
}
export interface CreateOrUpdateEnseignantDto {
    nom: string;
    prenom: string;
    ville: string;
    discipline: string;
    instrumentId?: string;
    qualification: QualificationEnseignant;
    typeContrat: TypeContrat;
    statut: StatutEnseignant;
    remunerationBruteAnnuelle: number;
    montantChargesPatronales: number;
    tarifHoraireBrut: number;
    contratEngagement: DocumentUploadInfoDto;
}
 export interface ValidateTrajetsDto {
    versementId?: string | undefined;
	document: DocumentUploadInfoDto;
 }
export interface CreateSalarieDto {
	nom: string;
    prenom: string;
    email: string;
	adresse: CreateOrUpdateAdresseDto;
	dateNaissance: Date;
	dateEmbauchePrevue: Date;
	salaireBrut: number;
	cv?: DocumentUploadInfoDto;
	diplome?: DocumentUploadInfoDto;
	conventionCollective: string;
	equipements: any; // type flag Equipements
	autresEquipements?: string;
	typesAdresses: any; // type flag TypesAdresses;
	autreAdresse?: CreateOrUpdateAdresseDto;
}

export interface UpdateSalarieDto {
	nom: string;
	prenom: string;
	adresse: CreateOrUpdateAdresseDto;
	dateNaissance: Date;
	dateEmbauchePrevue: Date;
	salaireBrut: number;
	cv?: DocumentUploadInfoDto;
	diplome?: DocumentUploadInfoDto;
	conventionCollective: string;
	equipements: any; // type flag Equipements
	autresEquipements?: string;
	typesAdresses: any; // type flag TypesAdresses;
	autreAdresse?: CreateOrUpdateAdresseDto;
}

export interface CreateOrUpdateDonneesBancairesDto {
    iban?: string | undefined;
    bic?: string | undefined;
    banque?: string | undefined;
    adresse?: CreateOrUpdateAdresseDto;
	rib?: DocumentUploadInfoDto;
}

export class AdelDossierClient {
    private instance: AxiosInstance;
    private baseUrl: string;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
    }

    submitInfosProjetAnswers(dossierId: string, data: SubmitInfosProjetAnswersDto, files: Dictionary<File>) : Promise<void> {
        let formData = new FormData();
        formData.set("json", JSON.stringify(data));
        for (let key in files) {
            if (files.hasOwnProperty(key)) {
                formData.append(key, files[key]);
            }
        }

        let options = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        } as AxiosRequestConfig;

        let url = `${this.baseUrl}/api/v1/Dossier/${dossierId}/etapes/informationsProjet/answers`
        return this.instance.put(url, formData, options)
            .then((response: AxiosResponse) => {
                return this.processSubmitInfosProjetAnswers(response);
            });
    }

    protected processSubmitInfosProjetAnswers(response: AxiosResponse) : Promise<void> {
        if (response.status !== 200 && response.status !== 204) {
            let headers: any = {};
            if (response.headers && typeof response.headers === "object") {
                for (let k in response.headers) {
                    if (response.headers.hasOwnProperty(k)) {
                        headers[k] = response.headers[k];
                    }
                }
            }

            return throwException("An unexpected server error occurred.", response.status, response.data, headers);
        }
        return Promise.resolve();
	}

	  /**
     * @param body (optional)
     * @return Success
     */
    createBudget(dossierId: string | null, body: CreateBudgetDto | undefined, files: IFileBudget[]): Promise<BudgetDto> {
        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/budget";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

		let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        files && files.length > 0 && files.forEach(file => {
			if(file) {
				formData.append(file.partName.replace(/\./g, "-"), file.file);
			}
		});

		let options_ = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		} as AxiosRequestConfig;

		return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
			return this.processCreateBudget(_response);
		});
    }

    protected processCreateBudget(response: AxiosResponse): Promise<BudgetDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 201) {
            const _responseText = response.data;
            let result201: any = null;
            let resultData201  = _responseText;
            result201 = resultData201;
            return result201;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<BudgetDto>(<any>null);
	}


	/**
     * @param body (optional)
     * @return Success
     */
    updateBudget(dossierId: string | null, body: UpdateBudgetDto | undefined, files: IFileBudget[]): Promise<BudgetDto> {
        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/budget";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        files && files.length > 0 && files.forEach(file => {
			if(file && file.file) {
				formData.append(file.partName.replace(/\./g, "-"), file.file);
			}
		});

		let options_ = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		} as AxiosRequestConfig;

        return this.instance.put(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processUpdateBudget(_response);
        });
    }

    protected processUpdateBudget(response: AxiosResponse): Promise<BudgetDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<BudgetDto>(<any>null);
    }


	/**
     * @param body (optional)
     * @return Success
     */
    submitDescriptionProjetAnswers(dossierId: string | null, body: SubmitDescriptionProjetAnswersDto | undefined, files: Dictionary<File>): Promise<void> {
		let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        for (let key in files) {
            if (files.hasOwnProperty(key)) {
                formData.append(key, files[key]);
            }
        }

        let options = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        } as AxiosRequestConfig;

        let url = `${this.baseUrl}/api/v1/Dossier/${dossierId}/etapes/descriptionProjet/answers`
        return this.instance.post(url, formData, options)
            .then((response: AxiosResponse) => {
                return this.processSubmitDescriptionProjetAnswers(response);
            });
	}

    protected processSubmitDescriptionProjetAnswers(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 204) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400  = _responseText;
            result400 = resultData400;
            return throwException("Bad Request", status, _responseText, _headers, result400);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }

    /**
     * @param body (optional)
     * @return Success
     */
    createEnseignant(dossierId: string | null, body: EnseignantDto | undefined, files: Dictionary<File>): Promise<EnseignantDto> {
        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/enseignant";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processCreateEnseignant(_response);
        });
    }

    protected processCreateEnseignant(response: AxiosResponse): Promise<EnseignantDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 201) {
            const _responseText = response.data;
            let result201: any = null;
            let resultData201 = _responseText;
            result201 = resultData201;
            return result201;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<EnseignantDto>(<any>null);
    }

        /**
     * @param body (optional)
     * @return Success
     */
    updateEnseignant(dossierId: string | null, enseignantId: string | null, body: EnseignantDto | undefined, files: Dictionary<File>): Promise<EnseignantDto> {
        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/enseignant/{enseignantId}";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        if (enseignantId === undefined || enseignantId === null)
            throw new Error("The parameter 'enseignantId' must be defined.");
        url_ = url_.replace("{enseignantId}", encodeURIComponent("" + enseignantId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        return this.instance.put(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processUpdateEnseignant(_response);
        });
    }

    protected processUpdateEnseignant(response: AxiosResponse): Promise<EnseignantDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404  = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<EnseignantDto>(<any>null);
    }

	/**
     * @param body (optional)
     * @return Success
     */
    createSalarie(dossierId: string | null, body: CreateSalarieDto | undefined, files: Dictionary<File>): Promise<SalarieDto> {
		let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/salarie";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
		};

        return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processCreateSalarie(_response);
        });
    }

    protected processCreateSalarie(response: AxiosResponse): Promise<SalarieDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<SalarieDto>(<any>null);
	}

	/**
     * @param body (optional)
     * @return Success
     */
    updateSalarie(salarieId: string | null, body: UpdateSalarieDto  | undefined, files: Dictionary<File>): Promise<SalarieDto> {
        let url_ = this.baseUrl + "/api/v1/Dossier/salarie/{salarieId}";
        let formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

        if (salarieId === undefined || salarieId === null)
            throw new Error("The parameter 'salarieId' must be defined.");
        url_ = url_.replace("{salarieId}", encodeURIComponent("" + salarieId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
		};

        return this.instance.put(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processUpdateSalarie(_response);
        });
    }

    protected processUpdateSalarie(response: AxiosResponse): Promise<SalarieDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<SalarieDto>(<any>null);
	}

	/**
     * @param versementId (optional)
     * @param body (optional)
     * @return Success
     */
    validateTrajets(dossierId: string | null, body: ValidateTrajetsDto | undefined, files: Dictionary<File>): Promise<TrajetValidationDto> {
        let url_ = this.baseUrl + "/api/v1/Dossier/{dossierId}/trajets/validation";
        if (dossierId === undefined || dossierId === null)
			throw new Error("The parameter 'dossierId' must be defined.");

        const formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

		url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
		url_ = url_.replace(/[?&]$/, "");


        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
		};

        return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processValidateTrajets(_response);
        });
    }

    protected processValidateTrajets(response: AxiosResponse): Promise<TrajetValidationDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200  = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<TrajetValidationDto>(<any>null);
    }



	/****/


	/**
     * @param depensesSpecifiques (optional)
     * @param depenses (optional)
     * @param documents (optional)
     * @return Success
     */
	 createOrUpdateDepenses(dossierId: string | null,
							depensesSpecifiques: UpdateDepenseDto[] | null | undefined,
							depenses: UpdateDepenseDto[] | null | undefined,
							documents: Dictionary<File> | null | undefined)
		: Promise<any> {
		let url_ = this.baseUrl + "/api/v1/Versement/dossier/{dossierId}/versement/contratscachets/depenses";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

		const formData = new FormData();
        if (depensesSpecifiques !== null && depensesSpecifiques !== undefined)
			formData.set("json", JSON.stringify({depensesSpecifiques: depensesSpecifiques}))
        if (depenses !== null && depenses !== undefined)
			formData.set("json", JSON.stringify({depenses: depenses}))

		appendFileDictionary(formData, documents);

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
		};

        return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processCreateOrUpdateDepenses(_response);
        });
    }

    protected processCreateOrUpdateDepenses(response: AxiosResponse): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 201) {
            const _responseText = response.data;
            let result201: any = null;
            let resultData201  = _responseText;
            result201 = resultData201;
            return result201;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404  = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<any>(<any>null);
    }


	/** Données bancaires */
	createOrUpdateDonneesBancaires(body: CreateOrUpdateDonneesBancairesDto | undefined , documents?: Dictionary<File> | undefined): Promise<void> {
        let url_ = this.baseUrl + "/api/v1/Structure/donneesBancaires";
        url_ = url_.replace(/[?&]$/, "");

        const formData = new FormData();
        formData.set("json", JSON.stringify(body));

		appendFileDictionary(formData, documents);

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
		};

        return this.instance.put(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processCreateOrUpdateDonneesBancaires(_response);
        });
    }

    protected processCreateOrUpdateDonneesBancaires(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }
}

const appendFileDictionary = (formData: FormData, files: Dictionary<File>) => {
    for (let key in files) {
        if (files.hasOwnProperty(key)) {
            formData.append(key, files[key]);
        }
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}
