import React, { useState } from 'react';
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import Table, { styleTable } from 'adel-shared/dist/components/basics/Table';
import { DisciplineDto, EnseignantDto } from '../../../../services/generated/FrontOffice-api';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import ModalDeleteEnseignant from './ModalDeleteEnseignant';

interface EnseignantListProps {
	enseignants: EnseignantDto[]
	onAddEnseignant: () => void;
	duplicateEnseignant: (id: string) => void;
	editEnseignant: (id: string) => void;
	deleteEnseignant: (id: string) => Promise<void>;
}

const EnseignantList: React.FunctionComponent<EnseignantListProps> = (props) => {
	const { t, i18n } = useTranslation();
	const [modalDeleteEnseignantIsOpen, setModalDeleteEnseignantIsOpen] = useState<boolean>(false)
	const [selectedEnseignantId, setSelectedEnseignantId] = useState<string>();

	const columns = React.useMemo(() => { // VFA : Je n'arrive pas à trouver le type exact de cet objet... Je laisse en any T_T
		return [{
			Header: "Table",
			columns: [
				{ Header: "Id", accessor: "id", disableSortBy: true },
				{ Header: t('createFolder.enseignants.listColumns.nom'), accessor: "nom", disableSortBy: true },
				{ Header: t('createFolder.enseignants.listColumns.prenom'), accessor: "prenom", disableSortBy: true },
				{ Header: t('createFolder.enseignants.listColumns.discipline'), accessor: "disciplines", disableSortBy: true,
					Cell: (value: any) => (
						<span>
							{value.cell.value.map((element:DisciplineDto) => 
								{ return element.text[i18n.language]  }).join(", ")
							}
						</span>
					)
				},
				{ Header: t('createFolder.enseignants.listColumns.typeContrat'), accessor: "typeContrat", disableSortBy: true },
				{
					Header: "", accessor: "Actions", disableSortBy: true,
					Cell: (value: any) => (
						<span className="creationDossier__table-buttons">
							<div className="icon-button" onClick={() => { props.duplicateEnseignant(value.cell.row.values.id) }}><i className="far fa-copy"></i></div>
							<div className="icon-button" onClick={() => { props.editEnseignant(value.cell.row.values.id) }}><i className="far fa-edit"></i></div>
							<div className="icon-button" onClick={() => { openModalDeleteEnseignant(value.cell.row.values.id) }}><i className="far fa-trash-alt"></i></div>
						</span>
					)
				}
			]
		}];
	}, []);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable<EnseignantDto>({
		columns: columns,
		data: props.enseignants,
		initialState: { hiddenColumns: ["id"] },
	}, useSortBy);

	const openModalDeleteEnseignant = (id: string) => {
		setSelectedEnseignantId(id)
		setModalDeleteEnseignantIsOpen(true)
	}

	const confirmDeleteEnseignant = async () => {
		await props.deleteEnseignant(selectedEnseignantId);
		setModalDeleteEnseignantIsOpen(false);
	}

	return <>
		<div className="creationDossier__header">
			<h3 className="title--dark">{t("createFolder.enseignants.title")}</h3>
		</div>
		<div className="creationDossier__singleRightButton">
			<FormButton
				type="button"
				value={t("createFolder.enseignants.add")}
				onClick={() => props.onAddEnseignant()}
			/>
		</div>
		<Table
			data={props.enseignants}
			getTableProps={getTableProps}
			getTableBodyProps={getTableBodyProps}
			headerGroups={headerGroups}
			rows={rows}
			prepareRow={prepareRow}
			styles={styleTable.onglet}
		/>
		{
			<ModalDeleteEnseignant
				isOpen={modalDeleteEnseignantIsOpen}
				onCancel={() => setModalDeleteEnseignantIsOpen(false)}
				onValidate={confirmDeleteEnseignant}
			/>
		}
	</>;
}

export default EnseignantList;