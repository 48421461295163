const tryParseToDate = (value: string): Date | null => {
	try {
		let timestamp = Date.parse(value);
		if(!isNaN(timestamp)) {
			return new Date(timestamp);
		}
	} catch (e) {
		// Date could not be parsed but no error is thrown. If you want to parse with Error, do not use `tryParseToDate` but just new Date(value)
	}
	return null;
}

export default tryParseToDate;