import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import i18next from "i18next";
import { maxTotalFilesSize } from "../constants/config.constant";
import { Dictionary } from "../models";
import { IFile } from '../models/IFile';
import {
    UpdateDepenseDto,
    Unit,
    EnseignantDto,
    DocumentUploadInfoDto,
    UpdateVersementArtisteDocumentDto
} from "../services/generated/FrontOffice-api";

export class AdelVersementClient {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    /**
     * @param versementArtistes (optional) 
     * @param documents (optional) 
     * @return Success
     */
    updateArtisteDocument(versementId: string | null, body: UpdateVersementArtisteDocumentDto[], files: Dictionary<File>, cancelToken?: CancelToken | undefined): Promise<Unit> {
        let url_ = this.baseUrl + "/api/v1/Versement/{versementId}/contratscachets/artiste/document";

        if (versementId === undefined || versementId === null)
            throw new Error("The parameter 'versementId' must be defined.");
        url_ = url_.replace("{versementId}", encodeURIComponent("" + versementId));
        url_ = url_.replace(/[?&]$/, "");

        let formData = new FormData();
        formData.set("json", JSON.stringify({ versementArtistes: body }));
        appendFileDictionary(formData, files);

        let options_ = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        } as AxiosRequestConfig;

        return this.instance.put(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processUpdateArtisteDocument(_response);
        });
    }

    protected processUpdateArtisteDocument(response: AxiosResponse): Promise<Unit> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = resultData200;
            return result200;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<Unit>(<any>null);
    }

    /****/


    /**
     * @param depensesSpecifiques (optional) 
     * @param depenses (optional) 
     * @param documents (optional) 
     * @return Success
     */
    createOrUpdateDepenses(dossierId: string | null,
        depensesSpecifiques: UpdateDepenseDto[] | null | undefined,
        autresDepenses: UpdateDepenseDto[] | null | undefined,
        documents: Dictionary<File> | null | undefined)
        : Promise<any> {
        let url_ = this.baseUrl + "/api/v1/Versement/dossier/{dossierId}/versement/contratscachets/depenses";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

        const formData = new FormData();
        if (depensesSpecifiques !== null && depensesSpecifiques !== undefined)
            formData.set("json", JSON.stringify({ depensesSpecifiques: depensesSpecifiques }))
        if (autresDepenses !== null && autresDepenses !== undefined)
            formData.set("json", JSON.stringify({ autresDepenses: autresDepenses }))

        appendFileDictionary(formData, documents);

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processCreateOrUpdateDepenses(_response);
        });
    }

    protected processCreateOrUpdateDepenses(response: AxiosResponse): Promise<any> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 201) {
            const _responseText = response.data;
            let result201: any = null;
            let resultData201 = _responseText;
            result201 = resultData201;
            return result201;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        }
        else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }

        return Promise.resolve<any>(<any>null);
    }

    /**
     * @param documents (optional) 
     * @return Success
     */
    createEnseignantOnDemandeVersement(dossierId: string | null, body: EnseignantDto | undefined, files: Dictionary<File>, cancelToken?: CancelToken | undefined): Promise<EnseignantDto> {
        let url_ = this.baseUrl + "/api/v1/Versement/dossier/{dossierId}/versement/contratscachets/enseignant/create";
        if (dossierId === undefined || dossierId === null)
            throw new Error("The parameter 'dossierId' must be defined.");
        url_ = url_.replace("{dossierId}", encodeURIComponent("" + dossierId));
        url_ = url_.replace(/[?&]$/, "");

        const formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processCreateEnseignantOnDemandeVersement(_response);
        });
    }

    protected processCreateEnseignantOnDemandeVersement(response: AxiosResponse): Promise<EnseignantDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 201) {
            const _responseText = response.data;
            let result201: any = null;
            let resultData201 = _responseText;
            result201 = resultData201;
            return result201;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<EnseignantDto>(<any>null);
    }


    /**
     * @param piecesJointes (optional) 
     * @param documents (optional) 
     * @return Success
     */
    createOrUpdatePieceJointes(versementId: string | null,
        piecesJointes: DocumentUploadInfoDto[] | null | undefined,
        files: IFile[] | null | undefined,
        cancelToken?: CancelToken | undefined)
        : Promise<any> {
        let url_ = this.baseUrl + "/api/v1/Versement/{versementId}/piecesjointes/update";
        if (versementId === undefined || versementId === null)
            throw new Error("The parameter 'versementId' must be defined.");
        url_ = url_.replace("{versementId}", encodeURIComponent("" + versementId));
        url_ = url_.replace(/[?&]$/, "");


        const formData = new FormData();
        if (piecesJointes !== null && piecesJointes !== undefined)
            formData.set("json", JSON.stringify({ piecesJointes }))

        files && files.length > 0 && files.forEach(file => {
            if (file.file.size >= 100000000) {
				throwException(i18next.t('errors.largeFile', {maxTotalFilesSize}), 413, "", null);
            }
            if (file && file.file) {
                formData.append(file.code, file.file);
            }
        })

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        return this.instance.post(url_, formData, options_).then((_response: AxiosResponse) => {
            return this.processCreateOrUpdateDepenses(_response);
        });
    }

    protected processCreateOrUpdatePieceJointes(response: AxiosResponse): Promise<Unit> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 201) {
            const _responseText = response.data;
            let result201: any = null;
            let resultData201 = _responseText;
            result201 = resultData201;
            return result201;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<Unit>(<any>null);
    }

    /**
     *
     * @param documents (optional) 
     * @return Success
     */
    updateEnseignantOnDemandeVersement(enseignantId: string | null, body: EnseignantDto | undefined, files: Dictionary<File>, cancelToken?: CancelToken | undefined): Promise<EnseignantDto> {
        let url_ = this.baseUrl + "/api/v1/Versement/dossier/versement/contratscachets/enseignant/{enseignantId}/update";
        if (enseignantId === undefined || enseignantId === null)
            throw new Error("The parameter 'enseignantId' must be defined.");
        url_ = url_.replace("{enseignantId}", encodeURIComponent("" + enseignantId));
        url_ = url_.replace(/[?&]$/, "");

        const formData = new FormData();
        formData.set("json", JSON.stringify(body));
        appendFileDictionary(formData, files);

        let options_ = <AxiosRequestConfig>{
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        return this.instance.put(url_, formData, options_).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processUpdateEnseignantOnDemandeVersement(_response);
        });
    }

    protected processUpdateEnseignantOnDemandeVersement(response: AxiosResponse): Promise<EnseignantDto> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 201) {
            const _responseText = response.data;
            let result201: any = null;
            let resultData201 = _responseText;
            result201 = resultData201;
            return result201;
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = resultData404;
            return throwException("Not Found", status, _responseText, _headers, result404);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<EnseignantDto>(<any>null);
    }
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

const appendFileDictionary = (formData: FormData, files: Dictionary<File>) => {
    for (let key in files) {
        if (files.hasOwnProperty(key)) {
            formData.append(key, files[key]);
        }
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}