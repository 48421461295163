import { CategorieDossier } from "../enums/CategorieDossier";
import { ChampDepense } from "../models/generated/BackOffice-api";
export const getChampDepenseKey = (champ, categorieCode) => {
    switch (categorieCode) {
        case CategorieDossier.AidePromotionImage:
            switch (champ) {
                case ChampDepense.SalairesArtisteEnregistrement:
                case ChampDepense.SalairesArtisteRepetition:
                    return champ + "Api";
                default:
                    return champ;
            }
        case CategorieDossier.InteretGeneral:
            switch (champ) {
                case ChampDepense.ContratsVentes:
                    return champ + "IG";
                default:
                    return champ;
            }
        default:
            return champ;
    }
};
