import React from "react";
import QuestionInputProps from "./QuestionInputProps";
import {useTranslation} from "react-i18next";
import {AnswerType, QuestionDto} from "../../../../services/generated/FrontOffice-api";

import ArrayQuestionInputItem from "./ArrayQuestionInputItem";
import { Dictionary } from '../../../../models';
import { ValidationResult } from '../../../../helpers/questionHelpers';


interface ArrayQuestionInputProps extends QuestionInputProps{
	renderQuestionInput: any;
	validationResult: ValidationResult;
}

const ArrayQuestionInput: React.FC<ArrayQuestionInputProps> = ({
	title,
	currentValue = [],
	question,
	onAnswerChange,
	validationResult,
	renderQuestionInput,
}) => {
	const { t } = useTranslation();

	const onItemChange = (item: Dictionary<any>, index:number) => {
		const newItems = [...(currentValue ?? [])];

		if (question.answerType === AnswerType.Array) {
			if (Object.keys(item).length === 0) {
				for(const q of question.arrayItemsQuestions) {
					item[q.id] = null
				}
			}
		}

		newItems[index] = item;

		onAnswerChange(question.id, newItems);
	}

	const addRow = () => {
		onItemChange({}, currentValue?.length ?? 0);
	}

	const deleteRow = (index:number) => {
		const newItems = [...(currentValue ?? [])];
		newItems.splice(index, 1);
		onAnswerChange(question.id, newItems);
	}

	const renderAddButton = (q?: QuestionDto) => {
		let buttonLabel = t("common.add");

		if(q?.answerType === 'file') {
			buttonLabel = t('common.addDocument');
			if(typeof question.answerMaxLength !== 'undefined') {
				buttonLabel = t("common.addDocumentWithMax", {count: question.answerMaxLength - currentValue.length});
			}
		}

		return (
			<div className="creationDossier__addElementButton" onClick={addRow}>
				<i className="fas fa-plus-circle" />
				<span className="creationDossier__addElementButtonLabel">
					{buttonLabel}
				</span>
			</div>
		);
	};

	return (
		<div>
			{title && (
				<div className="questionField__title">
					{title} {question.isRequired && "*"}
				</div>
			)}
			{currentValue.map((item: Dictionary<any>, i: number) => (
				<div className="creationDossier__item questionFieldAnswerArrayItem" key={i}>
					<ArrayQuestionInputItem 
						index={i}
						item={item}
						renderQuestionInput={renderQuestionInput}
						questions={question.arrayItemsQuestions}
						validationResults={validationResult?.items[i]}
						onItemChange={(value:Dictionary<any>) => onItemChange(value, i)}
						delete={deleteRow}
					/>
				</div>
			))}
			{question && (question.arrayItemsQuestions.length === 1 
			? question.answerMaxLength
				? (currentValue.length < question.answerMaxLength) && renderAddButton(question.arrayItemsQuestions[0])
				: renderAddButton(question.arrayItemsQuestions[0])
			: currentValue && question.answerMaxLength
				? (currentValue.length < question.answerMaxLength) && renderAddButton()
				: renderAddButton()
			)}
		</div>
	);
}

export default ArrayQuestionInput;