import React from 'react';
import { Router } from "@reach/router";
import './sass/App.scss';
import "./components/fontawesome/Fontawesome";

import Portal from './containers/Portal';
import Signup from './containers/Signup';
import AppContext from './context/AppContext';
import StructuresMailBoxContent from './components/structures/messagerie/MailBox';
import StructuresContent from './components/structures/dossiers/StructuresContent';
import CreationDossier from './components/dossiers/CreationDossier';
import DetailsDossier from './components/dossiers/DetailsDossier';
import MonCompte from './components/monCompte/MonCompte';
import PagesVitrines from './components/PagesVitrines/PagesVitrines';
import VersementsListe from './components/versements/VersementsListe';
import VersementStatut from './components/versements/VersementStatut';
import CreationVersement from './components/versements/creationVersement/CreationVersement';
import StructureDisabled from './components/structures/portail/StructureDisabled';
import StructurePending from './components/structures/portail/StructurePending';
import ValidationMail from './components/validationMail/ValidationMail';
import Toaster from './containers/Toaster';
import AppInitialize from './components/AppInitialize';

const App = () => {
	return (
		<AppContext>
			<div className="App">
				<AppInitialize />
				<Router>
					<Portal path="/*" />
					<Signup path="/Inscription/*" />
					<StructuresMailBoxContent path="/Structures/Messagerie" />
					<StructuresContent path="/Structures" />
					<CreationDossier path="/Dossiers/Creation/*" />
					<MonCompte path="/Compte/*" />
					<PagesVitrines path="/PagesVitrines/:page" />
					<VersementsListe path="/Versements/:dossierId"/>
					<VersementStatut path="/Versements/Statut/:versementId"/>
					<CreationVersement path="/Versements/Creation/*"/>
					<StructureDisabled path="/NonAuthoriseRefuse" />
					<StructurePending path="/NonAuthoriseEnAttente" />
					<DetailsDossier path="/Dossiers/Details/:dossierId" />
					<ValidationMail path="/validation-mail-change" />
				</Router>
			</div>
			<Toaster />
		</AppContext>
	);
}

export default App;
