import React from "react";

import SignupEnd from "./signup-items/SignupEnd";
import { RouteComponentProps } from "@reach/router";
// import CreatePassword from "../structures/portail/mdp/CreatePassword";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";

const StepHeight = (props: RouteComponentProps) => {
	// const [isLoading, setIsLoading] = useState<Boolean>(false);
	// const [token, setToken] = useState<string>(null);
	// const [username, setUsername] = useState<string>(null);

	// const { t } = useTranslation();

	return (
		<>
			<SignupEnd
				value="Retour à l'accueil"
			/>
		</>
	)
}

export default StepHeight;