import React, { useState, useEffect } from 'react';
import Table, { styleTable } from '../basics/Table';
import { useTable, useSortBy } from 'react-table';
import Modal from 'react-modal';
import { formatNumber, sortArrayAsc } from '../../utils/functions';
import { useTranslation } from 'react-i18next';
import InputReadOnly from '../InputReadOnly';
const Enseignants = ({ enseignants }) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedEnseignant, setSelectedEnseignant] = useState();
    const [data, setData] = useState([]);
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);
    useEffect(() => {
        if (enseignants) {
            const array = enseignants.map((e) => {
                return {
                    actions: e,
                    nom: e.nom,
                    prenom: e.prenom,
                    disciplines: e.disciplines,
                    typeContrat: e.typeContrat
                };
            }).sort((a, b) => sortArrayAsc(a.nom.toLowerCase(), b.nom.toLowerCase()));
            setData(array);
        }
    }, [enseignants]);
    const openModal = (value) => {
        setIsOpen(true);
        setSelectedEnseignant(value);
    };
    const closeModal = () => {
        setIsOpen(false);
        setSelectedEnseignant(undefined);
    };
    const columns = React.useMemo(() => {
        return [{
                Header: "Table",
                columns: [
                    { Header: t('enseignants.listColumns.nom'), accessor: "nom", disableSortBy: true },
                    { Header: t('enseignants.listColumns.prenom'), accessor: "prenom", disableSortBy: true },
                    { Header: t('enseignants.listColumns.discipline'), accessor: "disciplines", disableSortBy: true, Cell: (value) => (React.createElement("span", null, value.cell.value.map((element) => { return element.text[i18n.language]; }).join(", ")))
                    },
                    { Header: t('enseignants.listColumns.typeContrat'), accessor: "typeContrat", disableSortBy: true },
                    { accessor: "id", disableSortBy: true },
                    { accessor: "actions", disableSortBy: true, Cell: (value) => (React.createElement("span", null,
                            React.createElement("i", { className: "fas fa-plus-circle", role: "button", onClick: () => openModal(value.value) })))
                    }
                ]
            }];
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
        initialState: { hiddenColumns: ["id"] },
    }, useSortBy);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "detailsTab__content" },
            React.createElement(Table, { data: data, getTableProps: getTableProps, getTableBodyProps: getTableBodyProps, headerGroups: headerGroups, rows: rows, prepareRow: prepareRow, styles: styleTable.onglet }),
            React.createElement(Modal, { isOpen: isOpen, style: {
                    content: {
                        overflow: 'visible',
                        minWidth: '1000px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, .2)',
                        zIndex: 10
                    }
                }, className: "normal__modal artisteModal" },
                React.createElement("div", { className: "modal__header" },
                    React.createElement("h3", null, "D\u00E9tails d'un enseignant")),
                selectedEnseignant &&
                    React.createElement("div", { className: "modal__content" },
                        React.createElement("div", { className: "detailsTab__row" },
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement(InputReadOnly, { label: "Qualification", content: selectedEnseignant.qualification ? t(`qualification.${selectedEnseignant.qualification}`) : '-' }))),
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement(InputReadOnly, { label: "Statut", content: selectedEnseignant.statut ? t(`statutEnseignant.${selectedEnseignant.statut}`) : '-' }))),
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement(InputReadOnly, { label: "Ville", content: selectedEnseignant.ville || '-' })))),
                        React.createElement("div", { className: "detailsTab__row" },
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement(InputReadOnly, { label: "Montant charges patronales", content: selectedEnseignant.montantChargesPatronales ? `${formatNumber(selectedEnseignant.montantChargesPatronales)} €` : '-' }))),
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement(InputReadOnly, { label: "R\u00E9muneration brute annuelle", content: selectedEnseignant.remunerationBruteAnnuelle ? `${formatNumber(selectedEnseignant.remunerationBruteAnnuelle)} €` : '-' }))),
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement(InputReadOnly, { label: "R\u00E9muneration premier trimestre", content: selectedEnseignant.remunerationPremierTrimestre ? `${formatNumber(selectedEnseignant.remunerationPremierTrimestre)} €` : '-' })))),
                        React.createElement("div", { className: "detailsTab__row" },
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement(InputReadOnly, { label: "Tarif horaire brut", content: selectedEnseignant.tarifHoraireBrut ? `${formatNumber(selectedEnseignant.tarifHoraireBrut)} €` : '-' }))),
                            React.createElement("div", { className: "detailsTab__column" },
                                React.createElement("div", { className: "detailsTab__content" },
                                    React.createElement("div", { className: "inputReadOnly" },
                                        React.createElement("label", null, "Contrat d'engagement"),
                                        React.createElement("p", null, selectedEnseignant.contratEngagement
                                            ? React.createElement("a", { className: "documentsIcon", target: "_blank", href: selectedEnseignant.contratEngagement.uri },
                                                React.createElement("i", { className: "fas fa-file-upload" }),
                                                selectedEnseignant.contratEngagement.fileName)
                                            : '-')))),
                            selectedEnseignant.instrument
                                ? React.createElement("div", { className: "detailsTab__column" },
                                    React.createElement("div", { className: "detailsTab__content" },
                                        React.createElement(InputReadOnly, { label: "Instrument", content: selectedEnseignant.instrument.intitule.fr })))
                                : React.createElement("div", { className: "detailsTab__column" }))),
                React.createElement("div", { className: "modal__footer" },
                    React.createElement("button", { type: "button", onClick: closeModal }, "Fermer")))));
};
export default Enseignants;
