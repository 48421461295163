import React, { FunctionComponent } from "react"
import { ErrorMessage } from "react-hook-form";
interface CheckboxRoundProps {
    name: string;
    reference: any;
    id: string;
    label: string;
    sublabel?: string;
    onClick?: () => void;
    onChange?: () => void;
    checked?: boolean;
    errors?: any;
}

const CheckboxRound: FunctionComponent<CheckboxRoundProps> = (props) => {
    return (
        <div className="checkboxRound">
            <div className="checkboxRound__item">

                <input
                    ref={props.reference}
                    name={props.name}
                    type="checkbox"
                    id={props.id}
                    checked={props.checked}
                    onChange={props.onChange}
                />

                {props.errors &&
                    <ErrorMessage errors={props.errors} name={props.name}>
                        {({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                        }
                    </ErrorMessage>
                }

                <label htmlFor={props.id} onClick={props.onClick}></label>

            </div>

            <div className="checkboxRound__label">
                <label>
                    {props.label}
                    {props.sublabel && (
                        <span>{props.sublabel}</span>
                    )}
                </label>
            </div>
        </div>
    )
}

export default CheckboxRound;