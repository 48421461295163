import React, { useEffect, useState } from "react";
import Table, { styleTable } from "../basics/Table";
import { useTable, useSortBy } from 'react-table';
const DeplacementsTable = ({ trajets }) => {
    const [trajetsTable, setTrajetsTable] = useState([]);
    useEffect(() => {
        trajets && setTable(trajets);
    }, [trajets]);
    const columns = React.useMemo(() => {
        return [{
                Header: "Table",
                columns: [
                    { Header: "Id", accessor: "id", disableSortBy: true },
                    { Header: "Lieu de départ", accessor: "depart", disableSortBy: true },
                    { Header: "Lieu de destination", accessor: "etape", disableSortBy: true },
                    { Header: "Lieu de retour", accessor: "arrivee", disableSortBy: true },
                    { Header: "Artistes", accessor: "artistes", disableSortBy: true },
                ]
            }];
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable({
        columns: columns,
        data: trajetsTable,
        initialState: { hiddenColumns: ["id"] },
    }, useSortBy);
    const setTable = (trajets) => {
        var _a, _b, _c, _d, _e, _f, _g;
        let result = [];
        if (trajets) {
            for (let trajet of trajets) {
                if (trajet.etapes) {
                    let arrivee = trajet.etapes[((_a = trajet.etapes) === null || _a === void 0 ? void 0 : _a.length) - 1] && ((_c = trajet.etapes[((_b = trajet.etapes) === null || _b === void 0 ? void 0 : _b.length) - 1].pays) === null || _c === void 0 ? void 0 : _c.nom);
                    let step = (((_d = trajet.etapes) === null || _d === void 0 ? void 0 : _d.length) || 0) > 2 ? (_e = trajet.etapes) === null || _e === void 0 ? void 0 : _e.slice(1, trajet.etapes.length - 1) : [];
                    let destinations = step === null || step === void 0 ? void 0 : step.map(e => { var _a; return (_a = e.pays) === null || _a === void 0 ? void 0 : _a.nom; }).join(", ");
                    let item = {
                        id: trajet.id || '',
                        depart: ((_f = trajet.etapes[0].pays) === null || _f === void 0 ? void 0 : _f.nom) || '',
                        etape: destinations || '',
                        arrivee: arrivee,
                        artistes: ((_g = trajet.artistes) === null || _g === void 0 ? void 0 : _g.map(e => e.fullName).join(", ")) || ''
                    };
                    result.push(item);
                }
            }
            setTrajetsTable(result);
        }
    };
    return (React.createElement(Table, { data: trajetsTable, getTableProps: getTableProps, getTableBodyProps: getTableBodyProps, headerGroups: headerGroups, rows: rows, prepareRow: prepareRow, styles: styleTable.onglet }));
};
export default DeplacementsTable;
