import React from "react";
import { KeyboardEvent } from "react";
import { ErrorMessage } from "react-hook-form";
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface InputValidationMonCompteStructureProps {
    name:string;
    reference: any;
    label: string;
    placeHolder: string;
    maxLength?: number;
    type?: string;
    buttonLabel: string;
    onChange?: (value: string) => void;
    onValidate?: () => void;
    validated: boolean;
    errors?: any;
    isLoading?: boolean;
	onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
	onInput?: (e?: KeyboardEvent<HTMLInputElement>) => void;
	errorMessageCusto?: string;
}

const InputValidationMonCompteStructure: React.FunctionComponent<InputValidationMonCompteStructureProps> = ({
    name,
    buttonLabel,
    label,
    placeHolder,
    maxLength,
    reference,
    type = 'text',
    validated,
    errors,
    onChange,
    onValidate,
    isLoading,
	onPaste,
    onInput,
	errorMessageCusto
}) => {
    return (
        <div className="inputValidation">
            <span>{label}</span>

            <div className="inputValidation__item">
                <input
                    name={name}
                    ref={reference}
                    type={type}
                    placeholder={placeHolder}
                    maxLength={maxLength}
                    onChange={e => {
                        if (onChange) onChange(e.target.value)
                    }}
                    onPaste={onPaste}
                    onInput={onInput}
                />
                {
                isLoading ?
                    <Loader
                        type="TailSpin"
                        width={35}
                        height={35}
                        color="#d93943"
                    ></Loader>
                    : validated ?
                            <button type="button" style={{ background: "green" }} >{buttonLabel}</button>
                        : <button type="button" onClick={onValidate}>{buttonLabel}</button>
                }
            </div>

            {errors &&
                <ErrorMessage errors={errors} name={name}>
                    {({ message }) => <p className="input__errorMessage">{message}</p>}
                </ErrorMessage>
            }
            {errorMessageCusto &&
                <p className="input__errorMessage">{errorMessageCusto}</p>
            }
        </div>
    )
}

export default InputValidationMonCompteStructure;