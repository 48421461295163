import React, { useImperativeHandle, forwardRef, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Input from 'adel-shared/dist/components/basics/Input';
import InputSelect from 'adel-shared/dist/components/basics/InputSelect';
import { RouteComponentProps } from '@reach/router';
import { Controller, useForm } from 'react-hook-form';
import useValidation from '../../../custom-hooks/useValidation';
import { StructureClient, Civilite, TypePoste, AdresseClient, GeoAdresseDto } from '../../../services/generated/FrontOffice-api';
import { useAxios } from '../../../custom-hooks/useAxios';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

interface ValidationPart1Props extends RouteComponentProps {
	ref: any;
	setCanGoNext: (value: boolean) => void;
	setIsLoading: (value: boolean) => void;
}

const ValidationPart1: React.FunctionComponent<ValidationPart1Props> = forwardRef(({
	setIsLoading,
	setCanGoNext
}, ref) => {
	const { t } = useTranslation();

	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);

	const [formCheckbox, setFormCheckbox] = useState<boolean>(false);
	const [geoAdresse, setGeoAdresse] = useState<GeoAdresseDto[]>([]);

	const {
		register,
		getValues,
		setValue,
		triggerValidation,
		control,
		watch,
		errors
	} = useForm({});
	const { getRootValidator } = useValidation();
	const contactValidator = getRootValidator("UpdateContactValidator");
	const addressValidator = getRootValidator("CreateOrUpdateAdresseDto");
	const {
		ville,
		codePostal,
		correspondanceVille,
		correspondanceCodePostal
	} = watch([
		'ville',
		'codePostal',
		'correspondanceVille',
		'correspondanceCodePostal'
	]);

	/** Post */
	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			setIsLoading(true);
			const result = await triggerValidation();
			if ((!getValues().presidentTelephoneFixe && !getValues().presidentTelephonePortable)) {
				toast.error(t('createFolder.validation.president') + ': ' + t('errors.telephone'));
				setIsLoading(false)
				return;
			}
			if ((!getValues().correspondantTelephoneFixe && !getValues().correspondantTelephonePortable)) {
				toast.error(t('createFolder.validation.correspondant') + ': ' + t('errors.telephone'));
				setIsLoading(false)
				return;
			}

			if (result) {
				const {
					presidentPoste,
					presidentNom,
					presidentPrenom,
					presidentTelephoneFixe,
					presidentTelephonePortable,
					presidentEmail,
					presidentCivilite,
					correspondantPoste,
					correspondantNom,
					correspondantPrenom,
					correspondantTelephonePortable,
					correspondantTelephoneFixe,
					correspondantEmail,
					correspondantCivilite,
					line1,
					line2,
					ville,
					codePostal,
					correspondanceLine1,
					correspondanceLine2,
					correspondanceVille,
					correspondanceCodePostal
				} = getValues();
				const body = {
					president: {
						poste: presidentPoste,
						nom: presidentNom,
						prenom: presidentPrenom,
						telephoneFixe: presidentTelephoneFixe,
						telephonePortable: presidentTelephonePortable,
						email: presidentEmail,
						civilite: presidentCivilite
					},
					correspondant: {
						poste: correspondantPoste,
						nom: correspondantNom,
						prenom: correspondantPrenom,
						telephonePortable: correspondantTelephonePortable,
						telephoneFixe: correspondantTelephoneFixe,
						email: correspondantEmail,
						civilite: correspondantCivilite
					},
					adresse: {
						line1,
						line2,
						ville,
						codePostal
					},
					adresseCorrespondance: {
						line1: correspondanceLine1,
						line2: correspondanceLine2,
						ville: correspondanceVille,
						codePostal: correspondanceCodePostal
					}
				};

				try {
					await structureClient.updateStructureValidationContacts(body);
				} catch (error) {
					toast.error(error);
				}
			} else {
                toast.error(t("validation-messages.invalid-form"));
			}
			setIsLoading(false);
			return result;
		}
	}));

	/** Get Contact Infos */
	const getContactInfos = async () => {
		try {
			const { 
				correspondant,
				president,
				adresse,
				adresseCorrespondance
			} = await structureClient.getStructureValidationContacts();
			const correspondantFinal = correspondant || president;
			const adresseCorrespondanceFinale = adresseCorrespondance || adresse;
			setValue([
				{ "presidentPoste": president.poste },
				{ "presidentCivilite": president.civilite },
				{ "presidentNom": president.nom },
				{ "presidentPrenom": president.prenom },
				{ "presidentTelephoneFixe": president.telephoneFixe },
				{ "presidentTelephonePortable": president.telephonePortable },
				{ "presidentEmail": president.email },
				{ "correspondantPoste": correspondantFinal.poste },
				{ "correspondantCivilite": correspondantFinal.civilite },
				{ "correspondantNom": correspondantFinal.nom },
				{ "correspondantPrenom": correspondantFinal.prenom },
				{ "correspondantTelephoneFixe": correspondantFinal.telephoneFixe },
				{ "correspondantTelephonePortable": correspondantFinal.telephonePortable },
				{ "correspondantEmail": correspondantFinal.email },
				{ "line1": adresse.line1 },
				{ "line2": adresse.line2 },
				{ "ville": adresse.ville },
				{ "codePostal": adresse.codePostal },
				{ "correspondanceLine1": adresseCorrespondanceFinale.line1 },
				{ "correspondanceLine2": adresseCorrespondanceFinale.line2 },
				{ "correspondanceVille": adresseCorrespondanceFinale.ville },
				{ "correspondanceCodePostal": adresseCorrespondanceFinale.codePostal }
			]);
		} catch (error) {
			if (error.exception?.message) {
				toast.error(error.exception.message);
			}
		}
	}

	useEffect(() => {
		window.scrollTo(0,0);
		getContactInfos();
	}, []);

	useEffect(() => {
		setCanGoNext(formCheckbox);
	}, [formCheckbox]);

	const optionsCivilite = Object.values(Civilite).filter(x => x !== Civilite.None).map(x => ({
		value: x,
		label: t(`common.civilite.${x}`)
	}));

	const optionsPosteRepLegal = Object.values(TypePoste)
	.filter(x => x !== TypePoste.None 
		&& x !== TypePoste.Administrateur
		&& x !== TypePoste.Tresorier 
		&& x !== TypePoste.SecretaireAdministratif)
	.map(x => ({
			value: x,
			label: t(`common.typePoste.${x}`)
		}));

	const optionsPosteCorrespondant = Object.values(TypePoste)
	.filter(x => x !== TypePoste.None)
	.map(x => ({
			value: x,
			label: t(`common.typePoste.${x}`)
		}));

	const searchAdresse = (input: string, correspondance: boolean = false) => {
		if (!!input) {
			try {
				const adresseClient = new AdresseClient('', axiosInstance);
				let searchString = `${ville} ${input}`;
				let codePostalString = codePostal;

				if(correspondance) {
					searchString = `${correspondanceVille} ${input}`;
					codePostalString = correspondanceCodePostal;
				}

				adresseClient.searchAdresse(searchString, codePostalString).then(results => {
					setGeoAdresse(results);
				});
				onAdresseChange(input, correspondance);
			}
			catch (error) {
				if (error.exception?.message)
					toast.error(error.exception.message);
				else
					toast.error(t("errors.default"));
			}
		}
	}
	const onAdresseChange = (label: string, correspondance: boolean = false) => {
		const selectedAdresse = geoAdresse.find(adresse => adresse.label === label);

		if (selectedAdresse) {
			if(correspondance) {
				setValue([
					{ correspondanceLine1: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
					{ correspondanceCodePostal: selectedAdresse.codePostal },
					{ correspondanceVille: selectedAdresse.ville }
				]);
			} else {
				setValue([
					{ line1: `${selectedAdresse.numero} ${selectedAdresse.rue}` },
					{ codePostal: selectedAdresse.codePostal },
					{ ville: selectedAdresse.ville }
				]);
			}
		}
	}

	return (
		<form>
			<h3>{t("createFolder.validation.president")}</h3>

			<section className="creationDossier__item">
				<div className="creationDossier__row">
					<Controller
						name="presidentPoste"
						control={control}
						as={({name, onChange, value}) => (
							<InputSelect<TypePoste>
								name={name}
								label={t('createFolder.form.poste')}
								classname="inputSelect"
								onChange={onChange}
								value={value}
								options={optionsPosteRepLegal}
								placeholder={t("common.select")}
							/>
						)}
						rules={contactValidator?.["Poste"]}
					/>

					<Controller
						name="presidentCivilite"
						control={control}
						as={({name, onChange, value}) => (
							<InputSelect<Civilite>
								name={name}
								label={t('createFolder.form.civilite')}
								classname="inputSelect"
								options={optionsCivilite}
								errors={errors}
								onChange={onChange}
								value={value}
								placeholder={t("common.select")}
							/>
						)}
						rules={contactValidator?.["Civilite"]}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="presidentNom"
						label={t('createFolder.form.nom')}
						type="text"
						reference={register(contactValidator?.["Nom"])}
						errors={errors}
					/>

					<Input
						name="presidentPrenom"
						label={t('createFolder.form.prenom')}
						type="text"
						reference={register(contactValidator?.["Prenom"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="presidentTelephoneFixe"
						label={t('createFolder.form.telephoneFixe')}
						type="text"
						reference={register(contactValidator?.["TelephoneFixe"])}
						errors={errors}
					/>

					<Input
						name="presidentTelephonePortable"
						label={t('createFolder.form.telephonePortable')}
						type="text"
						reference={register(contactValidator?.["TelephonePortable"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="presidentEmail"
						label={t('createFolder.form.mail')}
						type="text"
						reference={register(contactValidator?.["Email"])}
						errors={errors}
					/>
				</div>
			</section>

			<h3>{t("createFolder.validation.correspondant")}</h3>

			<section className="creationDossier__item">
				<div className="creationDossier__row">
					<Controller
						name="correspondantPoste"
						control={control}
						as={({name, onChange, value}) => (
							<InputSelect<TypePoste>
								name={name}
								label={t('createFolder.form.poste')}
								classname="inputSelect"
								onChange={onChange}
								value={value}
								options={optionsPosteCorrespondant}
								placeholder={t("common.select")}
							/>
						)}
						rules={contactValidator?.["Poste"]}
					/>

					<Controller
						name="correspondantCivilite"
						control={control}
						as={({name, onChange, value}) => (
							<InputSelect<Civilite>
								name={name}
								label={t('createFolder.form.civilite')}
								classname="inputSelect"
								options={optionsCivilite}
								errors={errors}
								onChange={onChange}
								value={value}
								placeholder={t("common.select")}
							/>
						)}
						rules={contactValidator?.["Civilite"]}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="correspondantNom"
						label={t('createFolder.form.nom')}
						type="text"
						reference={register(contactValidator?.["Nom"])}
						errors={errors}
					/>

					<Input
						name="correspondantPrenom"
						label={t('createFolder.form.prenom')}
						type="text"
						reference={register(contactValidator?.["Prenom"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="correspondantTelephoneFixe"
						label={t('createFolder.form.telephoneFixe')}
						type="tel"
						reference={register(contactValidator?.["TelephoneFixe"])}
						errors={errors}
					/>

					<Input
						name="correspondantTelephonePortable"
						label={t('createFolder.form.telephonePortable')}
						type="tel"
						reference={register(contactValidator?.["TelephonePortable"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="correspondantEmail"
						label={t('createFolder.form.mail')}
						type="text"
						reference={register(contactValidator?.["Email"])}
						errors={errors}
					/>
				</div>
			</section>

			<h3>{t("createFolder.validation.adresse")}</h3>
			<section className="creationDossier__item">
				<div className="creationDossier__row">
					<Input
						name="ville"
						label={t('createFolder.validation.form.ville')}
						type="text"
						reference={register(addressValidator?.["Ville"])}
						errors={errors}
					/>
					<Input
						name="codePostal"
						label={t('createFolder.validation.form.codePostal')}
						type="tel"
						reference={register(addressValidator?.["CodePostal"])}
						errors={errors}
					/>
				</div>

				<div className="creationDossier__row">
					<Input
						name="line1"
						label={t('createFolder.validation.form.adresse')}
						type="text"
						reference={register(addressValidator?.["Line1"])}
						errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={debounce((value) => searchAdresse(value), 1000)}
					/>
					<Input
						name="line2"
						label={t('myAccount.form.complementAdresse')}
						type="text"
						reference={register(addressValidator?.["Line2"])}
						errors={errors}
					/>
				</div>
			</section>

			<h3>{t("createFolder.validation.adresseCorrespondance")}</h3>
			<section className="creationDossier__item">
				<div className="creationDossier__row">
					<Input
						name="correspondanceVille"
						label={t('createFolder.validation.form.ville')}
						type="text"
						reference={register(addressValidator?.["Ville"])}
						errors={errors}
					/>
					<Input
						name="correspondanceCodePostal"
						label={t('createFolder.validation.form.codePostal')}
						type="number"
						reference={register(addressValidator?.["CodePostal"])}
						errors={errors}
					/>
				</div>
				<div className="creationDossier__row">
					<Input
						name="correspondanceLine1"
						label={t('createFolder.validation.form.adresse')}
						type="text"
						reference={register(addressValidator?.["Line1"])}
						errors={errors}
						dataList={geoAdresse.map(adresse => adresse.label)}
						onChange={debounce((value) => searchAdresse(value, true), 1000)}
					/>
					<Input
						name="correspondanceLine2"
						label={t('myAccount.form.complementAdresse')}
						type="text"
						reference={register(addressValidator?.["Line2"])}
						errors={errors}
					/>
				</div>
			</section>

			<div className="creationDossier__checkbox">
				<input
					type="checkbox"
					onChange={() => setFormCheckbox(!formCheckbox)}
				/>

				<label>{t('createFolder.form.checkbox')}</label>
			</div>
		</form>

	)
});

export default ValidationPart1;
