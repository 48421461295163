import Input from 'adel-shared/dist/components/basics/Input';
import { uniqueId } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { FormContextValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdelOption } from '../../../../../../../../../Shared/src/adel-shared/dist/components/basics/InputSelect';
import { tauxChargesPatronales } from '../../../../constants/config.constant';
import { CreateArtisteDto, Emploi, LieuDateDto, RemunerationDto, TypePrestation } from '../../../../services/generated/FrontOffice-api';
import { formMode } from '../ContratsCachets';
import { DatesSelectOption, SalairesCusto } from './EditArtiste';
import MontantBrutInput from './MontantBrutInput';
import SalairesDatesBlock from './SalairesDatesBlock';

interface SalairesProps {
	salaires: SalairesCusto[];
	setSalaires: (value: SalairesCusto[]) => void,
	dates: { [key: string]: LieuDateDto[]; };
	currentFormMode: formMode;
	datesSelectOptions:DatesSelectOption[];
	setDatesSelectOptions:(value:DatesSelectOption[]) => void;
	form: FormContextValues<CreateArtisteDto>;
	setInvalidSalaireDates: (value:boolean) => void;
	groupesEmploi?: any
	remuneration: RemunerationDto[];
	typePrestation?: keyof typeof TypePrestation;
	emploi: Emploi;
	styleMusical: string; // styleMusical ID
}

const Salaires: FunctionComponent<SalairesProps> = ({
	salaires,
	setSalaires,
	datesSelectOptions,
	setDatesSelectOptions,
	setInvalidSalaireDates,
	form,
	dates,
	remuneration,
	groupesEmploi,
	emploi,
	styleMusical
}) => {
	const { t } = useTranslation();
	const isSalaireDatesInvalid = salaires.length > 0 && (!salaires.find(s => s.lieuDateByPresta.length > 0) 
	|| !salaires.find(s => s.lieuDateByPresta.find(l => l.dates.length > 0)));

	const handleChangeObject = (value: string, index: number, propertyName: string) => {
		const updateSalaires = [...salaires];
		switch (propertyName) {
			case "montantBrut":
				updateSalaires[index].montantBrut = Math.round(parseFloat(value as string) * 100) / 100;
				break;
			case "tauxChargesPatronales":
				updateSalaires[index].tauxChargesPatronales = parseFloat(value as string);
				break;
			default: break;
		}
		setSalaires(updateSalaires)
	}

	useEffect(() => {
		setInvalidSalaireDates(isSalaireDatesInvalid)
	}, [salaires]);

	const addSalaire = () => {
		const temporaryId = uniqueId('TEMPORARY_');
		const newSalaire: SalairesCusto = {
			id: temporaryId,
			lieuDateByPresta: [],
		};
		setSalaires([...salaires, newSalaire]);
	}

	const deleteSalaire = (itemClicked:SalairesCusto) => {
		const updateSalaires = [...salaires]
		for (let i in updateSalaires) {
			if (itemClicked.id === updateSalaires[i].id)
				updateSalaires.splice(updateSalaires.indexOf(updateSalaires[i]), 1);
		}
		setSalaires(updateSalaires)

		/** Suppression de dates dans les options (non Enregistrements) */
		if(datesSelectOptions.find(elem => itemClicked.lieuDateByPresta.some(l => TypePrestation[elem.typePresta] === l.typePresta))) {
			const update = [...datesSelectOptions];

			let datesToAdd:AdelOption<LieuDateDto>[] = [];
			itemClicked.lieuDateByPresta.filter(e => e.typePresta !== TypePrestation.Enregistrement).forEach(lieuDate =>
				datesToAdd = lieuDate.dates.map(date => {
					return {
						label: moment(date.date).format('DD/MM/YYYY'),
						value: date
					}
				})
			);

			update.find(elem => itemClicked.lieuDateByPresta.some(l => TypePrestation[elem.typePresta] === l.typePresta)).options =  update
				.find(elem => itemClicked.lieuDateByPresta.some(l => TypePrestation[elem.typePresta] === l.typePresta))
				.options.concat(datesToAdd);
			setDatesSelectOptions(update);
		}
	}

	return (<>
		{salaires.map((salaire, i) => (
			<div className="creationDossier__littleBlock" key={i}>
				<h3>
					<button onClick={() => deleteSalaire(salaire)}>
						<i className="far fa-minus-circle" aria-hidden="true"></i>
					</button>
					{t("createFolder.contratsCachets.salaire")}
				</h3>
				<div className="creationDossier__row">
					<MontantBrutInput
						name={`montantBrutSalaire${i}`}
						step={1}
						form={form}
						value={salaires[i].montantBrut}
						label={t('createFolder.form.montantSalaire')}
						handleChangeObject={handleChangeObject}
						index={i}
						estSalaire
						remuneration={remuneration}
						groupesEmploi={groupesEmploi}
						salaire={salaire}
						datesSelectOptions={datesSelectOptions}
						emploi={emploi}
						styleMusical={styleMusical}
						prestation = 'mensualisation'
					/>
					<Input name={`tauxChargesSalaire${i}`}
						label={`${t('createFolder.form.tauxCharges')}*`}
						type="number"
						min={0}
						max={tauxChargesPatronales * 100}
						placeHolder="0%"
						reference={form.register({
							min: {value: 0.000000001, message: t("validation-messages.greater-than", {ComparaisonValue: 0})},
							required: {value: true, message: t("validation-messages.required")},
							max: {value: tauxChargesPatronales * 100, message: t("validation-messages.less-than-or-equal", {ComparaisonValue: tauxChargesPatronales * 100})}
						})}
						errors={form.errors}
						value={salaires[i].tauxChargesPatronales}
						onChange={(value:string) => {
							handleChangeObject(value, i, 'tauxChargesPatronales');
						}}
					/>
				</div>
				{Object.keys(dates).map((typePresta, i) => (
					<div key={'dateBlock'+i}>
						<SalairesDatesBlock
							salaire={salaire}
							typePresta={typePresta}
							datesSelectOptions={datesSelectOptions}
							setDatesSelectOptions={setDatesSelectOptions}
							dates={dates}
							salaires={salaires}
							setSalaires={setSalaires}
							form={form}
						/>
					</div>
				))}
			</div>
		))}
		{isSalaireDatesInvalid &&
			<div className="errorMessage">
				{t("createFolder.contratsCachets.minimumDateSalaire")}
			</div>
		}
		<div className="creationDossier__addElement" onClick={addSalaire}>
			<i className="fas fa-plus-circle"></i>
			{t("createFolder.contratsCachets.addSalaire")}
		</div>
	</>);
}

export default Salaires;