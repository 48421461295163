import { useState, useEffect } from 'react';
import SignupStep from '../context/SignupSteps';
import { EtapeCreationPlus, CreationDossierSteps } from '../context/CreationDossierSteps';
import { EtapeCreationDossier, EtapeDemandeVersement } from '../services/generated/FrontOffice-api';

// Generic useSteps custom hook to manage ordered types
function useSteps<T>(orderedSteps: T[]) {
	const [steps, setSteps] = useState<T[]>(orderedSteps);
	const [initialSteps] = useState<T[]>(orderedSteps);

	const getNextStep = (step: T): T => {
		// Find current and next step Index
		let currentStepIndex = steps.findIndex(element => element === step);
		let nextStepIndex = currentStepIndex + 1;
		// Handle trying to get next step from last step
		if (nextStepIndex > steps.length) throw new Error("Can not got to next step as it is already at the last step");
		// Get next step
		let nextStep = steps[nextStepIndex];
		return nextStep;
	}

	const getPreviousStep = (step: T): T => {
		// Find current and previous step Index
		let currentStepIndex = steps.findIndex(element => element === step);
		let previousStepIndex = currentStepIndex - 1;
		// Handle trying to get previous step from first step
		if (previousStepIndex < 0) throw new Error("Can not got to previous step as it is already at the first step");
		// Get previous step
		let previousStep = steps[previousStepIndex];
		return previousStep;
	}

	const isHigherOrCurrentStep = (sourceStep: T, targetStep: T): boolean => {
		if (sourceStep === targetStep) return true;
		return isHigherStep(sourceStep, targetStep);
	}

	const isHigherStep = (sourceStep: T, targetStep: T): boolean => {
		// Find source and target step Index
		let sourceStepIndex = steps.findIndex(element => element === sourceStep);
		let targetStepIndex = steps.findIndex(element => element === targetStep);
		if (sourceStepIndex === -1 || targetStepIndex === -1) {
			throw `One of the step could not be found. Source: ${sourceStep} [${sourceStepIndex}], Target: ${targetStep} [${targetStepIndex}]`;
		}
		// Return true if target step is higher than source step
		let isHigher = targetStepIndex > sourceStepIndex;
		return isHigher;
	}


	const mergeSteps = (additionalSteps: T[]): void => {
		let newSteps = [...initialSteps];
		additionalSteps.forEach(step => {
			if (steps.indexOf(step) === -1) {
				newSteps.push(step);
			}
		});
		setSteps(newSteps);
	}

	const getStepIndex = (step: T): number => {
		return steps.findIndex(element => element === step);
	}

	useEffect(() => {
		console.debug("steps", steps);
	}, [steps]);

	return { steps, getNextStep, getPreviousStep, isHigherStep, mergeSteps, isHigherOrCurrentStep, getStepIndex };
}

function useSignupSteps() {
	// Initialize Steps and define their order
	return useSteps<SignupStep>([
		SignupStep.One,
		SignupStep.Two,
		SignupStep.Four,
		SignupStep.Five,
		SignupStep.Six,
		SignupStep.Seven,
	]);
}

function useDossierSteps() {
	return useSteps<CreationDossierSteps>([
		EtapeCreationDossier.ValidationStructure,
		EtapeCreationPlus.ValidationStructure2,
		EtapeCreationDossier.Type,
		EtapeCreationDossier.Criteres,
	]);
}

function useVersementSteps() {
	return useSteps<EtapeDemandeVersement>([
		EtapeDemandeVersement.ValidationStructure,
		EtapeDemandeVersement.ValidationDocumentStructure,
		EtapeDemandeVersement.InformationsProjet,
		// EtapeDemandeVersement.ValidationArtiste,
		// EtapeDemandeVersement.PieceJointe,
		// EtapeDemandeVersement.ConfirmationRIB,
		// EtapeDemandeVersement.Recapitulatif,
	]);
}

export { useSignupSteps, useDossierSteps, useVersementSteps }