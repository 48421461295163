import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { CategorieDossier } from "../../enums/CategorieDossier";
import { TypeDepense, TypeFinancement } from '../../models/generated/BackOffice-api';
import { formatNumber } from '../../utils/functions';
import { getChampDepenseKey } from "../../utils/translationHelpers";
import TotalAmountsTable from "../basics/TotalAmountsTable";
const Contenu = ({ budget, categorie }) => {
    const { t } = useTranslation();
    const getBudgetByType = (budgetType) => {
        if (!budget) {
            return [];
        }
        const budgetByType = budget[budgetType];
        return (budgetByType === null || budgetByType === void 0 ? void 0 : budgetByType.reduce((accumulator, currentVal) => {
            const budgetByType = accumulator.filter(x => x.some(y => y.type === currentVal.type));
            if (budgetByType.length) {
                budgetByType[0].push(currentVal);
            }
            else {
                accumulator.push([currentVal]);
            }
            return accumulator;
        }, [])) || [];
    };
    const getTotalByBudgetType = (budgetType) => {
        if (!budget) {
            return 0;
        }
        const budgetByType = budget[budgetType];
        return (budgetByType === null || budgetByType === void 0 ? void 0 : budgetByType.map(x => x.montant).reduce((accumulator = 0, currentVal = 0) => accumulator + currentVal, 0)) || 0;
    };
    const depensesByType = getBudgetByType("depenses");
    const financementsByType = getBudgetByType("financements");
    const totalDepenses = getTotalByBudgetType("depenses");
    const totalFinancements = getTotalByBudgetType("financements");
    return (React.createElement("div", { className: "detailsTab__content" },
        depensesByType.length > 0 && (React.createElement("table", { className: "detailsTab__table detailsTab__tableBudget" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "budgetCol", id: "budgetPrevisionnelDepenses", scope: "col" },
                        React.createElement("h4", null, t(`dossier.depense.title`))),
                    React.createElement("th", { id: "budgetHTDepenses", scope: "col" }, t(`shared.budgetHt`)),
                    React.createElement("th", { id: "commentaireDepenses", scope: "col" }, t(`shared.commentaire`)),
                    React.createElement("th", { id: "documentDepenses", scope: "col" }, t(`shared.document`)))),
            React.createElement("tbody", null, depensesByType.map((x, index) => (React.createElement(React.Fragment, { key: index },
                React.createElement("tr", null,
                    React.createElement("th", { id: x[0].type, scope: "colgroup", colSpan: 3 },
                        index + 1,
                        ". ",
                        t(`dossier.depense.${((categorie === null || categorie === void 0 ? void 0 : categorie.code) === CategorieDossier.AidePromotionImage) && (x[0].type === TypeDepense.AutresDepenses)
                            ? `${x[0].type}Api`
                            : x[0].type}`))),
                x.map((y, i) => (React.createElement("tr", { key: `${y.id}${i}` },
                    React.createElement("td", { className: "budgetCol", headers: `${x[0].type} budgetPrevisionnelDepenses` },
                        index + 1,
                        ".",
                        i + 1,
                        " ",
                        y.champ == 'autre' ? y.intitule : t(`champDepense.${getChampDepenseKey(y.champ, categorie === null || categorie === void 0 ? void 0 : categorie.code)}`)),
                    React.createElement("td", { headers: `${x[0].type} budgetHTDepenses` },
                        y.montant ? formatNumber(y.montant) : '-',
                        " \u20AC"),
                    React.createElement("td", { headers: `${x[0].type} commentaireDepenses` }, y.commentaire),
                    React.createElement("td", { headers: `${x[0].type} documentDepenses` }, y.document &&
                        React.createElement("a", { className: "documentsIcon", target: "_blank", href: y.document.uri },
                            React.createElement("i", { className: "fas fa-file-upload" }),
                            y.document.fileName))))),
                React.createElement("tr", { className: "strong" },
                    React.createElement("td", null, "Sous-total"),
                    React.createElement("td", null, `${formatNumber(x.reduce((acc, currentVal) => acc + currentVal.montant, 0))} €`),
                    React.createElement("td", null)))))))),
        (totalDepenses !== 0) ? (React.createElement(TotalAmountsTable, { data: [
                {
                    label: t(`dossier.depense.titleTotal`),
                    value: `${formatNumber(totalDepenses)} €`,
                }
            ] })) : (React.createElement("div", { className: "detailsTab__informations" },
            React.createElement("div", { className: "detailsTab__text" }, "-"))),
        financementsByType.length > 0 && (React.createElement("table", { className: "detailsTab__table detailsTab__tableBudget" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "budgetCol", id: "budgetPrevisionnelFinancements", scope: "col" },
                        React.createElement("h4", null, t(`dossier.financement.title`))),
                    React.createElement("th", { id: "budgetHTFinancements", scope: "col" }, t(`shared.budgetHt`)),
                    React.createElement("th", { id: "commentaireFinancements", scope: "col" }, t(`shared.commentaire`)),
                    React.createElement("th", { id: "documentFinancements", scope: "col" }, t(`shared.document`)))),
            React.createElement("tbody", null, financementsByType.map((x, i) => {
                const index = i + depensesByType.length;
                return React.createElement(React.Fragment, { key: index },
                    React.createElement("tr", null,
                        React.createElement("th", { id: x[0].type, scope: "colgroup", colSpan: 3 },
                            index + 1,
                            ". ",
                            t(`dossier.financement.${(categorie === null || categorie === void 0 ? void 0 : categorie.code) === CategorieDossier.AidePromotionImage && x[0].type === TypeFinancement.RecettesPropres
                                ? x[0].type + 'Api'
                                : x[0].type}`))),
                    x.map((y, i) => (React.createElement("tr", { key: `${y.id}${i}`, className: clsx({ 'strong': y.champ === 'montantDemandeSPEDIDAM' }) },
                        React.createElement("td", { className: "budgetCol", headers: `${x[0].type} budgetPrevisionnelFinancements` },
                            index + 1,
                            ".",
                            i + 1,
                            " ",
                            t(`champFinancement.${y.champ}`),
                            y.intitule !== "" &&
                                y.champ === "autre" &&
                                `: ${y.intitule}`),
                        React.createElement("td", { headers: `${x[0].type} budgetHTFinancements` },
                            y.montant ? formatNumber(y.montant) : '-',
                            " \u20AC"),
                        React.createElement("td", { headers: `${x[0].type} commentaireFinancements` }, y.commentaire),
                        React.createElement("td", { headers: `${x[0].type} documentFinancements` }, y.document &&
                            React.createElement("a", { className: "documentsIcon", target: "_blank", href: y.document.uri },
                                React.createElement("i", { className: "fas fa-file-upload" }),
                                y.document.fileName))))),
                    React.createElement("tr", { className: "strong" },
                        React.createElement("td", null, "Sous-total"),
                        React.createElement("td", null, `${formatNumber(x.reduce((acc, currentVal) => acc + currentVal.montant, 0))} €`),
                        React.createElement("td", null)));
            })))),
        (totalFinancements !== 0) ? (React.createElement(TotalAmountsTable, { data: [
                {
                    label: t(`dossier.financement.titleTotal`),
                    value: `${formatNumber(totalFinancements)} €`,
                }
            ] })) : (React.createElement("div", { className: "detailsTab__informations" },
            React.createElement("div", { className: "detailsTab__text" }, "-")))));
};
export default Contenu;
