import { calculateMod } from './../utils/functions';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { isValidPhoneNumber } from 'libphonenumber-js';

const euroPrefix = [
	'AT',
	'BE',
	'CY',
	'DE',
	'EE',
	'ES',
	'FI',
	'FR',
	'GR',
	'IE',
	'IT',
	'LT',
	'LU',
	'LV',
	'MT',
	'NL',
	'PT',
	'SI',
	'SK'
];

export const bicRgx = new RegExp('^[a-z0-9]{4}[a-z]{2}[a-z0-9]{2}([a-z0-9]{3})?$', 'i');

export const emailValidate = (value: string, message: string) => {
	const index = value.indexOf('@');
	if (index > 0 && index !== value.length - 1 && index === value.lastIndexOf('@')) {
		return;
	}
	return message;
}

export const siretValidate = (value: string, message: string) => {
	const siret = value.trim();

	if (siret.length === 0 || !siret)
		return;
	if (siret.length !== 14)
		return message;
	if (!siret.split('').every(x => !isNaN(parseInt(x, 10))))
		return message;
}

export const ibanValidate = (iban: string, messageCountry: string, message: string) => {
	const prefix = iban.substring(0, 2);
	if (!euroPrefix.includes(prefix)) {
		return messageCountry;
	}

	let trimIban = Array.from(iban.replace(/[- ]/g, ''));
	const fourcharacters = trimIban.splice(0, 4);
	trimIban.push(...fourcharacters);
	const converted = Array.from(trimIban).map(x => isNaN(parseInt(x, 10)) ? x.charCodeAt(0) - 55 : x).join('');

	if (calculateMod(converted, 97) === 1)
		return;

	return message;
}

export const phoneValidate = (value: string, allowedTypes: string[], messageNotPhone: string, message: string) => {
	if (value === "") {
		return;
	}

	if (isNaN(parseInt(value, 10))) {
		return messageNotPhone;
	}

	const phoneUtil = PhoneNumberUtil.getInstance();
	const number = phoneUtil.parseAndKeepRawInput(value, 'FR');
	const numberType = phoneUtil.getNumberType(number);

	const result = allowedTypes?.map(t => {
		switch(t?.toUpperCase()) {
			
			case "FIXED_LINE":
				if(numberType === PhoneNumberType.FIXED_LINE || numberType === PhoneNumberType.VOIP) {
					return !isValidPhoneNumber(value, 'FR');
				} return true;
			case "MOBILE":
				if(numberType === PhoneNumberType.MOBILE) {
					return !isValidPhoneNumber(value, 'FR');
				} return true;
			default:
				return;
		}
	})

	if (result[0]) {
		return message;
	} else {
		return;
	}
}

export const requiredUniqueCharValidate = (value: string, validator: number, messageError: string) => {
	const array: Array<string> = Array.from(value);
	const distinctChar = [...new Set(array)];
	if (distinctChar.length > validator) {
		return;
	}
	return messageError;
}


export const checkRegex = (value: string, regex: any, messageError: string) => {
	if (regex.test(value)) {
		return
	}
	return messageError
}

export const greaterThan = (valueInput: string, ValueToCompare: any, messageError: string) => {
	if (valueInput > ValueToCompare) {
		return
	}
	return messageError
}

export const greaterThanOrEqual = (valueInput: string, ValueToCompare: any, messageError: string) => {
	if (valueInput >= ValueToCompare) {
		return
	}
	return messageError
}
export const lessThan = (valueInput: string, ValueToCompare: any, messageError: string) => {
	if (valueInput < ValueToCompare) {
		return
	}
	return messageError
}

export const lessThanOrEqual = (valueInput: string, ValueToCompare: any, messageError: string) => {
	if (valueInput <= ValueToCompare) {
		return
	}
	return messageError
}