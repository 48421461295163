import InputReadOnly from 'adel-shared/dist/components/InputReadOnly';
import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps, useParams } from "@reach/router";
import { AuthenticatedRoute } from "../../containers/AuthenticatedRoute";
import Structures from '../../containers/structures/Structure';
import { AppStateContext } from '../../context/AppContext';
import { useAxios } from '../../custom-hooks/useAxios';
import { DemandeVersementDetailDto, StatutVersement, VersementClient } from '../../services/generated/FrontOffice-api';
import Subtitles from '../basics/Subtitles';
import { toast } from 'react-toastify';
import moment from 'moment';
import { formatNumber } from 'adel-shared/dist/utils/functions';

interface VersementStatutProps extends RouteComponentProps {
}

const VersementStatut: React.FunctionComponent<VersementStatutProps> = () => {
	const { t } = useTranslation();
	const axiosInstance = useAxios();
	const {versementId} = useParams();
	const versementClient = new VersementClient("", axiosInstance);
	const [{
		currentEditingDossier,
		currentEditingVersement
	}] = useContext(AppStateContext);

	const [data, setData] = useState<DemandeVersementDetailDto>({});

	useEffect(() => {
		(async function () {
			await getVersementsDetails();
		})();
	}, []);

	const getVersementsDetails = async () => {
		try {
			const result = await versementClient.getDemandeVersementDetails(versementId);
			setData(result);
		} catch(error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
			throw error;
		}
	}

	const dateProjet = useCallback(() => {
		if (data.dateDebutProjet && data.dateFinProjet) {
			return t("versements.details.dateFull", {debut: moment(data.dateDebutProjet).format('DD/MM/YYYY'), fin: moment(data.dateFinProjet).format('DD/MM/YYYY')});
		} else if (data.dateDebutProjet && !data.dateFinProjet) {
			return `${t("versements.details.dateDebutOnly")} ${moment(data.dateDebutProjet).format('DD/MM/YYYY')}`;
		} else {
			return '-';
		}
	}, [data]);

	const domiciliation = useCallback(() => {
		if (data.donneeBancaire && data.donneeBancaire.adresse) {
			return `${data.donneeBancaire.adresse.line1} ${data.donneeBancaire.adresse.line2 ? `/ ${data.donneeBancaire.adresse.line2} `: ''}- ${data.donneeBancaire.adresse.codePostal} ${data.donneeBancaire.adresse.ville}`;
		} else {
			return '-';
		}
	}, [data]);

	return(
		<AuthenticatedRoute>
			<Structures>
				<div className="folders versements versementStatut">
					<span className="navigationFil">
						<Link to="/Structures">
							<span className="navigationFil__item">
								{t("navigation.folder")}
							</span>
						</Link>

						<span className="navigationFil__separator">|</span>

						<Link to={`/Versements/${currentEditingDossier.id}`}>
							{t('versements.title')}
						</Link>

						<span className="navigationFil__separator">|</span>

						<span className="navigationFil__item">
							{`${t('versements.details.title')} - ${currentEditingDossier.nom}`}
						</span>
					</span>
					<div className="folders__title">
						<Subtitles
							title={`${t('versements.details.title')} - ${currentEditingDossier.nom}`}
						/>
					</div>
					<ul className="versementStatut__header">
						<li className={clsx({"active":data.statutVersement === StatutVersement.Brouillon})} >
							<div className="versementStatut__headerItem statut--brouillon">
								<span className="versementStatut__headerCheck"><i className="fas fa-check"></i></span>
								<label>{t(`versements.statut.brouillon`)}</label>
							</div>
						</li>
						<li className={clsx({"active":data.statutVersement === StatutVersement.Soumis})} >
							<div className="versementStatut__headerItem statut--soumis">
								<span className="versementStatut__headerCheck"><i className="fas fa-check"></i></span>
								<label>{t(`versements.statut.soumis`)}</label>
							</div>
						</li>
						<li className={clsx({"active":data.statutVersement === StatutVersement.Reaffectation ? true : false})} >
							<div className="versementStatut__headerItem statut--soumis">
								<span className="versementStatut__headerCheck"><i className="fas fa-check"></i></span>
								<label>{t(`versements.statut.reaffectation`)}</label>
							</div>
						</li>
						<li className={clsx({"active":data.statutVersement === StatutVersement.PriseEnCharge})} >
							<div className="versementStatut__headerItem statut--priseEnCharge">
								<span className="versementStatut__headerCheck"><i className="fas fa-check"></i></span>
								<label>{t(`versements.statut.priseEnCharge`)}</label>
							</div>
						</li>
						<li className={clsx({"active":data.statutVersement === StatutVersement.Valide})} >
							<div className="versementStatut__headerItem statut--valide">
								<span className="versementStatut__headerCheck"><i className="fas fa-check"></i></span>
								<label>{t(`versements.statut.valide`)}</label>
							</div>
						</li>
						<li className={clsx({"active":data.statutVersement === StatutVersement.Verse})} >
							<div className="versementStatut__headerItem statut--verse">
								<span className="versementStatut__headerCheck"><i className="fas fa-check"></i></span>
								<label>{t(`versements.statut.verse`)}</label>
							</div>
						</li>
					</ul>
					<div className="versementStatut__content">
						<div>
							<h3 className="title--red">{t(`common.typesVersements.${currentEditingVersement.type}`)}</h3>
							<div className="versementStatut__item">
								<div className="versementStatut__row">
									<InputReadOnly
										label={t("versements.details.nom")}
										content={ data.nomDossier || '-'}
									/>
									<InputReadOnly
										label={t("versements.details.categorie")}
										content={data.categorieNom || '-'}
									/>
								</div>
								<div className="versementStatut__row">
									<InputReadOnly
										label={t("versements.details.dateProjet")}
										content={dateProjet()}
									/>
									<InputReadOnly
										label={t("versements.details.dateSoumission")}
										content={moment(data.dateSoumission).format('DD/MM/YYYY') || '-'}
									/>
								</div>
								<div className="versementStatut__row">
									<InputReadOnly
										label={t("versements.details.base")}
										content={`${formatNumber(data.baseCalculSpedidam)} €`}
									/>
									<InputReadOnly
										label={t("versements.details.montantDemande")}
										content={`${formatNumber(data.montantDemande)} €`}
									/>
								</div>
								<div className="versementStatut__row">
									<InputReadOnly
										label={t("versements.details.montantAttribue")}
										content={`${formatNumber(data.montantAttribue)} €`}
									/>
									<InputReadOnly
										label={t("versements.details.montantVerse")}
										content={`${formatNumber(data.montantVerse)} €`}
									/>
								</div>
							</div>
						</div>
						<div>
							<h3 className="title--red">{t("versements.details.coordonnees")}</h3>
							<div className="versementStatut__item">
									<InputReadOnly
										label={t("versements.details.iban")}
										content={data.donneeBancaire?.iban || '-'}
									/>
									<InputReadOnly
										label={t("versements.details.domiciliation")}
										content={domiciliation()}
									/>
									<InputReadOnly
										label={t("versements.details.bic")}
										content={data.donneeBancaire?.bic || '-'}
									/>
							</div>
						</div>
					</div>
				</div>
			</Structures>
		</AuthenticatedRoute>
	);
}

export default VersementStatut;
