import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { RouteComponentProps } from '@reach/router';
import { AdelStructureClient } from "../../clients/AdelStructureClient";
import { AppStateContext } from '../../context/AppContext';
import { useAxios } from "../../custom-hooks/useAxios";
import getAsDate from '../../helpers/getAsDate';
import { Dictionary } from "../../models";
import { IFile } from "../../models/IFile";
import { DocumentDto, DocumentExtraInfoDto, DocumentUploadInfoDto, DocumentWithValidationDto, StructureClient, StructureDetailsDto, TypeDeDocumentDto } from "../../services/generated/FrontOffice-api";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import InputDocuments from "../basics/InputDocuments/InputDocuments";
import { ActionTypeEnum } from '../../context/ActionType';
import { formaterDate } from 'adel-shared/dist/utils/functions';

interface MonCompteFichierProps extends RouteComponentProps {
	structureDetails: StructureDetailsDto
	setStructureDetails: (value:StructureDetailsDto) => void;
	edit?: boolean;
	initialStructureValues: any
}

const MonCompteFichier: React.FunctionComponent<MonCompteFichierProps> = ({
	edit,
	setStructureDetails,
	structureDetails,
	initialStructureValues
}) => {
	const { i18n, t } = useTranslation();
	const [editInput, setEditInput] = useState<boolean>(edit);
	const [documents, setDocuments] = useState<DocumentWithValidationDto[]>(structureDetails.documents);
	const [documentList, setDocumentList] = useState<DocumentWithValidationDto[]>([]);
	const form = useForm();
	const { setValue, getValues, triggerValidation } = form;
	const [dirtyFields, setDirtyFields] = useState<string[]>([]);
	const [context, dispatch] = useContext(AppStateContext);
	const axiosInstance = useAxios();
	const structureClient = new StructureClient("", axiosInstance);
	const adelStructureClient = new AdelStructureClient("", axiosInstance);
	const [isDirty, setIsDirty] = useState<boolean>(false);

	useEffect(() => {
		if(structureDetails && structureDetails.documents && structureDetails.requiredDocuments) {
			setDocuments(structureDetails.documents);
			initDocumentList(structureDetails.documents, structureDetails.requiredDocuments);
			initSiretOrResponsable(structureDetails);
		}
	}, [structureDetails,
		context.structure.siret,
		context.structure.representantLegal
	]);
   
	const initDocumentList = (documents: DocumentWithValidationDto[], requiredDoc:TypeDeDocumentDto[]) => {
		const array = requiredDoc.reduce((accumulator: DocumentWithValidationDto[], currentVal) => {
			let doc:DocumentWithValidationDto;			
			if (currentVal.hasChoix)
				{
					doc = {...documents.find(d => currentVal.choix.find(c => c.id === d.type.id))};
				    doc.type = {...currentVal,  extraInfos: doc.type && doc.type.extraInfos || [], selectedNom: doc.type && doc.type.nom }
				}
				
			else
				doc = documents.find(d => d.type.id === currentVal.id);

			if (doc && !accumulator.some(d => d.type.id === doc.type.id || d.type.id === currentVal.id))
				accumulator.push(doc)
			else
				accumulator.push({ type: currentVal })

			return accumulator;
		}, []);
		setDocumentList(array);
	}

	const initSiretOrResponsable = (structure:StructureDetailsDto) => {
		const docsToExclude: string[] = [];
		if (initialStructureValues.siret && context.structure.siret && initialStructureValues.siret !== context.structure.siret) {
			setValue('avisdesituationinseedemoinsde3moisnom', '');
			docsToExclude.push('avisdesituationinseedemoinsde3moisnom');
		}
		if(
			(
				initialStructureValues?.nom &&
				initialStructureValues?.prenom &&
				context.structure.representantLegal.nom &&
				context.structure.representantLegal.prenom
			) &&
			(
				initialStructureValues.nom !== context.structure.representantLegal.nom ||
				initialStructureValues.prenom !== context.structure.representantLegal.prenom
			)
		) {
			setValue('composition-organes-direction', '');
			docsToExclude.push('composition-organes-direction');
		}
		setDocuments(structure.documents?.filter(d => !docsToExclude.includes(d.type.code)));
	}


	const getExtraInfoNameFromCode = (typeDeDocument: TypeDeDocumentDto, extraInfoCode: string) => {
		const extraInfoDefinition = typeDeDocument.extraInfos.find(info => info.code === extraInfoCode);
		if (extraInfoDefinition) {
			return extraInfoDefinition.nom[i18n.language]
		} else {
			console.error(`extraInfoDefinition with code ${extraInfoCode} could not be found. Please contact your Dev Team`);
		}
	};

	const handleEditClick = () => {
		setEditInput(true);
	};
  
	const handleCancelClick = () => {
		const docs = structureDetails.documents;

		const newArray = docs.map(doc => {
			if(doc.extraInfo?.length > 0) {
				const newDoc = doc.extraInfo.map(d => {
					if(d.valueType === "date") {
						return {...d, value: JSON.parse(d.jsonValue)};
					}
					return d;
				})
				return {...doc, extraInfo: newDoc};
			} else {
				return doc;
			}
		});

		setDocuments(newArray);
		setDocumentList(newArray);
		setEditInput(false);
	};

	const isFieldDirty = (fieldName: string): boolean => {
		return dirtyFields.findIndex(dirtyField => dirtyField === fieldName) > -1;
	}

	const convertDocumentsAndFilesToDtosByRef = (documents: DocumentWithValidationDto[]): [DocumentUploadInfoDto[], IFile[]] => {
		const modifiedDocuments: DocumentUploadInfoDto[] = [];
		const files: IFile[] = [];
		const formFiles: Dictionary<FileList> = getValues();
		const typeDeDocuments = documents.map(d => d.type);

		typeDeDocuments.forEach(typeDeDocument => {
			// TODO : Skip non dirty existing documents => strip them of everything except id and typeId

			let correspondingDocument: DocumentWithValidationDto;

			if (typeDeDocument.hasChoix) {
				correspondingDocument = documents.find(document => typeDeDocument.choix.some(choix => document.type.id === choix.id));
			} else {
				correspondingDocument = documents.find(document => document.type.id === typeDeDocument.id);
			}

			const typeIdDoc = correspondingDocument
								? correspondingDocument.type.id
								: typeDeDocument.hasChoix
									? typeDeDocument.choix.find(choix => !choix.hasAttachment).id
									: "";
			// s'il n'a pas de document correspondant, lui donner l'id du type de document qui n'a pas de corresponding document
			let modifiedDocument: DocumentUploadInfoDto = {
				typeId: typeIdDoc
			};

			if (!isFieldDirty(typeDeDocument.code) && correspondingDocument?.id) {
				if (correspondingDocument?.extraInfo && correspondingDocument?.extraInfo.length > 0) {
					modifiedDocument.extraInfos = {};
	
					correspondingDocument.extraInfo.forEach(extraInfo => {
						modifiedDocument.extraInfos[extraInfo.code] = extraInfo.value;
					});
				}
				modifiedDocuments.push({ id: correspondingDocument.id, typeId: correspondingDocument.type.id, extraInfos: modifiedDocument.extraInfos });
				return;
			} else {
				// Do nothing: id should not be specified for new or modified documents.
			}

			// If there's a corresponding file,
			let correspondingFile = formFiles[typeDeDocument.code];

			if (typeDeDocument.hasChoix) {
				typeDeDocument.choix.forEach(choix => {
					if (formFiles.hasOwnProperty(choix.code)) {
						correspondingFile = formFiles[choix.code];
					}
				});
			}

			if (correspondingFile) {
				let modifiedFile: IFile = {
					code: correspondingDocument.type.code,
					file: correspondingFile[0]
				} as IFile;

				modifiedDocument.partName = correspondingDocument.type.code;

				files.push(modifiedFile);
			}

			if (correspondingDocument?.extraInfo && correspondingDocument?.extraInfo.length > 0) {
				modifiedDocument.extraInfos = {};

				correspondingDocument.extraInfo.forEach(extraInfo => {
					modifiedDocument.extraInfos[extraInfo.code] = extraInfo.value;
				});
			}

			modifiedDocuments.push(modifiedDocument);
		});

		return [modifiedDocuments, files];
	}

	const handleSubmit = async() => {
		const isValid = await triggerValidation();

		if(!isValid) return;

		try {
			if(
				initialStructureValues.nom !== context.structure.representantLegal.nom ||
				initialStructureValues.prenom !== context.structure.representantLegal.prenom ||
				initialStructureValues.siret !== context.structure.siret
			) {
				await structureClient.updateStructureJuridique({
					siret: context.structure.siret,
					adresse: context.structure.adresse,
					nomResponsable: context.structure.representantLegal.nom,
					prenomResponsable: context.structure.representantLegal.prenom,
					emailResponsable: context.structure.representantLegal.email
				});
			}

			const [modifiedDocuments, files] = convertDocumentsAndFilesToDtosByRef(documents);
		   
		   (files.length > 0 || getValues('ni-licence-ni-attestation-guso') === "") && await adelStructureClient.updateStructureDocuments({
				documents: modifiedDocuments
			}, files);

			// reinit structureDetails
			const newStructureDetails = {...structureDetails, documents: documents}
			setStructureDetails(newStructureDetails);
			dispatch({
				type: ActionTypeEnum.UPDATE_STRUCTURE,
				payload: newStructureDetails
			});

			// reinit documents states
			initDocumentList(documents, structureDetails.requiredDocuments);
			setEditInput(false);
			const structureDetailsDto = await structureClient.getStructureDetails();
			setStructureDetails(structureDetailsDto);
			toast.success(t('myAccount.structureUpdateSuccess'));
		} catch(error) {
			toast.error(t('myAccount.structureUpdateError'));
			if (error.message){
				toast.error(t(error.message));
			}
			if (error.exception?.message)
				toast.error(error.exception.message);
		}
	};

	const handleDocumentChange = (modifiedDocument: DocumentDto, file?: File) => {
		const updatedDocuments = upsertDocument(documents, modifiedDocument, file);

		setDocuments(updatedDocuments);
		setIsDirty(true);
	};

	const upsertDocument = (documents: DocumentWithValidationDto[], modifiedDocument: DocumentWithValidationDto, file?: File): DocumentWithValidationDto[] => {
		const existingDocument = documents.find(document => document.type.id === modifiedDocument.type.id);

		if (!existingDocument) {
			// INSERT

			return [...documents, modifiedDocument];
		} else {
			// UPDATE by merging properties using modification by reference ><

			if (modifiedDocument.fileName) existingDocument.fileName = modifiedDocument.fileName;

			if (modifiedDocument.extraInfo) existingDocument.extraInfo = modifiedDocument.extraInfo;

			return documents;
		}
	};

	const onReset = (fieldName: string,) => {
		setValue(fieldName, '');
		setDocuments(documents?.filter(d => d.type.code !== fieldName));
		if (dirtyFields.findIndex(field => field === fieldName) <= -1) {
			setDirtyFields([...dirtyFields, fieldName]);
		}
	};

	return (
		<>
			<div className="monCompte__header">
				<h3>{t("myAccount.piecesJointes")}</h3>
				{!editInput && (
					<i
						className="far fa-edit"
						role="button"
						title="Modifier"
						onClick={handleEditClick}
					></i>
				)}
			</div>

			{editInput ? (
				<>
					<div className="creationDossier__item creationDossier__validation">
						<InputDocuments
							typeDeDocuments={documentList?.map(d => d.type)}
							documents={documents}
							onDocumentChange={handleDocumentChange}
							onReset={onReset}
							form={form}
						/>
					</div>
					<div className="piecesJointes">
						<div className="monCompte__editFooter">
							<FormButton
								type="button"
								value={t('common.cancel')}
								onClick={handleCancelClick}
							/>
							<FormButton
								type="submit"
								value={t('common.validate')}
								onClick={handleSubmit}
							/>
						</div>
					</div>
				</>
			) : (
				<div className="piecesJointes">
					{documentList?.map((document, i) => (
						<div key={document.id || i} className="piecesJointes__item">
							<div className="piecesJointes__name">{document.type?.selectedNom?.[i18n.language] || document.type?.nom?.[i18n.language]}*</div>
							<div className="piecesJointes__infos">
								<div className="piecesJointes__fileName">
									{document.uri &&
										<a target="_blank" href={document.uri}>
											<i className="far fa-file"></i>
											<span className="piecesJointes__fileNameText">{document.fileName}</span>
										</a>
									}
									{document.fileName && !document.uri &&
										<>
											<i className="far fa-file disabled"></i>
											<span className="piecesJointes__fileNameText">{document.fileName}</span>
										</>
									}
								</div>
								{document?.extraInfo && document.extraInfo.length > 0 && (
									<div className="extraInfos">
										{document.extraInfo.map((info,i) => (
											<div key={`${info.documentId}-${i}`} className="extraInfos__item">
												{`${getExtraInfoNameFromCode(document.type, info.code)} : `}
												{info.valueType === "date" && <time>{formaterDate(getAsDate(info.value))}</time>}
												{info.valueType === "string" && info.value}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			)}
		</>
	)
};

export default MonCompteFichier;
