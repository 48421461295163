import { formatNumber } from "adel-shared/dist/utils/functions";
import { getChampDepenseKey } from "adel-shared/dist/utils/translationHelpers";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { FormContextValues } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { CategorieDossier } from '../../../../enums/Dossiers';
import { IFileBudget } from '../../../../models/IFile';
import {
	BudgetDto,
	BudgetViewModelDto,
	CategorieDossierDto,
	ChampDepense,
	ChampFinancement,
	DepenseBlockViewModelDto,
	DepenseDto,
	DepenseViewModelDto,
	FinancementBlockViewModelDto,
	FinancementDto,
	FinancementViewModelDto,
	TypeDepense,
	TypeFinancement
} from '../../../../services/generated/FrontOffice-api';
import {
	calculBlockBudget,
	calculDepensesBudget,
	calculFinancementsBudget
} from '../../../../utils/budgetFunctions';
import { CommentChamp, ConfirmationMontantChamp } from '../Budget';
import BudgetInput from './BudgetInput';

interface BudgetBlockProps {
	isEditBudgetMode: boolean;
	form: FormContextValues;
	itemViewModel: DepenseBlockViewModelDto|FinancementBlockViewModelDto;
	index:number;
	isDepense: boolean;
	budget: BudgetDto;
	setBudget: (value:BudgetDto) => void;
	files: IFileBudget[];
	setFiles: (value: IFileBudget[]) => void;
	commentsChamp:CommentChamp[];
	setCommentsChamp: (value:CommentChamp[]) => void;
	confirmationMontantChamp: ConfirmationMontantChamp[];
	defaultConfirmationMontantChamp: ConfirmationMontantChamp[];
	setConfirmationMontantChamp: (value:ConfirmationMontantChamp[]) => void;
	setTotalDepenses: (value:number) => void;
	setTotalFinancements: (value:number) => void;
	setIsBudgetValid: (value:boolean) => void;
	showBaseCalcul?: boolean;
	baseCalcul?: number;
	categorieDossier: CategorieDossierDto;
	viewModel?: BudgetViewModelDto
}

const BudgetBlock: React.FunctionComponent<BudgetBlockProps> = ({
	isEditBudgetMode,
	form,
	itemViewModel,
	index,
	isDepense,
	budget,
	setBudget,
	files,
	setFiles,
	commentsChamp,
	setCommentsChamp,
	confirmationMontantChamp,
	defaultConfirmationMontantChamp,
	setConfirmationMontantChamp,
	setTotalDepenses,
	setTotalFinancements,
	showBaseCalcul,
	baseCalcul,
	setIsBudgetValid,
	categorieDossier,
	viewModel
}) => {
	const { t } = useTranslation();
	const [montantBlock, setMontantBlock] = useState<number>(0);
	const [montantBlockDeplacement, setMontantBlockDeplacement] = useState<number>();

	const montantMax = itemViewModel.montantMax;
	const montantMin = itemViewModel.montantMin;
	const formValues = form.watch();
	const isIGDerivates = () => {
		return categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantLabelisationReseauSPEDIDAM1 
			   || categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantLabelisationReseauSPEDIDAM2
			   || categorieDossier.code === CategorieDossier.CreationAideSalons
			   || categorieDossier.code === CategorieDossier.CreationSoutienFestival
			   || categorieDossier.code === CategorieDossier.EducationArtistiqueCulturelleAideActionsEducation
			   || categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantRemisePrixArtistique
			|| categorieDossier.code === CategorieDossier.DiffusionSpectacleVivantRemiseEmissionTeleviseeMusicale
	 }
	/** Montant du block */
	const montant = (id?: string) => {
		setMontantBlock(calculBlockBudget(formValues, isDepense, itemViewModel, isIGDerivates(),id));
		setMontantBlockDeplacement(calculBlockBudget(formValues, true, {type: TypeDepense.FraisDeplacement}, isIGDerivates()))
		setTotalDepenses(calculDepensesBudget(formValues));
		setTotalFinancements(calculFinancementsBudget(formValues));
	}

	useEffect(() => {
		montant();
	}, [
		formValues,
		isDepense,
		itemViewModel
	]);

	useEffect(() => {
        if ((montantMax && montantBlock > montantMax) || (montantMin && montantBlock < montantMin)) {
			setIsBudgetValid(false);
		} else {
			setIsBudgetValid(true);
		}
	}, [
		montantBlock,
		setIsBudgetValid,
		montantMax,
		montantMin
	]);	
	
	const sumBlock = (block: string) => {
		return Number(Object.entries(formValues).reduce(
			(accumulator, [k,v]) => k.includes(block) && k.includes('montant') ? accumulator +  Number(v) : accumulator + 0,
			0
		  ))
	}
	const calculMaxAide = () => {
		let pourcentageAide = viewModel.pourcentageAide/100
		switch (categorieDossier.code) {
			case CategorieDossier.AidePromotionImage:			
				return (sumBlock(TypeDepense.BudgetPrevisionnelDepenses) + sumBlock(TypeDepense.FraisDeRealisationDuDocumentDePromotion)) * pourcentageAide
			case CategorieDossier.DeplacementInternational:
				return sumBlock(TypeDepense.FraisDeplacement) * pourcentageAide
			case CategorieDossier.BODramatiqueChoregraphique:
				return sumBlock(TypeDepense.BudgetRepetitionsEtEnregistrements) * pourcentageAide	
			case CategorieDossier.SpectacleDramatiqueChoregraphiqueCirque:
			case CategorieDossier.SpectacleMusical:
			case CategorieDossier.Festival:
			    return sumBlock(TypeDepense.BudgetPrevisionnelDepenses) * pourcentageAide
			case CategorieDossier.Ecole:
			    return sumBlock(TypeDepense.FraisDePersonnel) * pourcentageAide
			case CategorieDossier.Fortissimo:
			case CategorieDossier.InteretGeneral:
			case CategorieDossier.DiffusionSpectacleVivantLabelisationReseauSPEDIDAM1:
			case CategorieDossier.DiffusionSpectacleVivantLabelisationReseauSPEDIDAM2:
			case CategorieDossier.CreationAideSalons:
			case CategorieDossier.CreationSoutienFestival:
			case CategorieDossier.EducationArtistiqueCulturelleAideActionsEducation:
			case CategorieDossier.DiffusionSpectacleVivantRemisePrixArtistique:
			case CategorieDossier.DiffusionSpectacleVivantRemiseEmissionTeleviseeMusicale:
			case CategorieDossier.AideResidences:
			    return sumBlock('depenses') * pourcentageAide
			case CategorieDossier.SoireeArtistes:
			    return (sumBlock(TypeDepense.BudgetPrevisionnelDepenses) + sumBlock(TypeDepense.ContratsVenteEtAutreSalairePersonnel)) * pourcentageAide
		}
	}
	const validationInput = (value: string|number, name: string, min?: number, max?: number) => {
		let maxValue = max && max < calculMaxAide() ? max : calculMaxAide().toFixed(2);
		
		if (value as number < min) {
			return t('createFolder.budget.errors.min', {number:min});
		}
   
		if (value as number > max && name.indexOf(ChampFinancement.MontantDemandeSPEDIDAM) == -1) {
			if (name.indexOf(ChampDepense.CommandesMusicales) > -1) {
				return t('createFolder.budget.errors.maxCommandeMusicale', {number:max});
			}
			return t('createFolder.budget.errors.max', {number:max});
		}

		if (name.indexOf(ChampFinancement.MontantDemandeSPEDIDAM) > -1) {			
			
			if(!value) {
				return t('validation-messages.not-empty');
			}
			if(Number(value) as number > Number(maxValue)) {
				return t('createFolder.budget.errors.max', {number:maxValue});
			}
			// Check base de calcul
			const baseCalculCurrentCategorie = CategorieDossier.SpectacleDramatiqueChoregraphiqueCirque  ==  categorieDossier.code ? 
			                 (baseCalcul || (calculDepensesBudget(formValues) + formValues['depenses.budgetPrevisionnelDepenses.salairesArtisteMensualisation.montant']  ) * categorieDossier.pourcentageAide) 
							 : viewModel.montantMaxAide || (baseCalcul || (calculDepensesBudget(formValues)) * categorieDossier.pourcentageAide);
			if (baseCalculCurrentCategorie && (Number(value) > Number(baseCalculCurrentCategorie))) {
				viewModel.montantMaxAide 
				&& toast.error("L'aide maximum demandée à la SPEDIDAM ne peut dépasser 35 % de la masse salariale artistique retenue une fois appliqué	le plafonnement de la prise en compte du montant des cachets comme indiqué dans les critères du dossier")
				return t('createFolder.budget.errors.max', {number: baseCalculCurrentCategorie});				
			}
			
		}
		if (name.indexOf(ChampDepense.DevisRealisation) > -1 && (!value || value <= 0)) {
			return t('validation-messages.greater-than', {ComparaisonValue:0});
		}

		return true;
	};

	/** RENDERS de blocks */
	const renderChamp = (
		budgetItem: DepenseDto|FinancementDto,
		isDepense: boolean,
		item: DepenseViewModelDto|FinancementViewModelDto,
		type: TypeFinancement|TypeDepense,
		label: string,
		isTypeOther: boolean,
		index: number
	) => {
		const fieldName = `${isDepense ? "depenses" : "financements"}.${type}.${item.champ}`;

		return (
			<BudgetInput
				item={item}
				montant={montant}
				form={form}
				id={budgetItem && budgetItem.id && isEditBudgetMode ? budgetItem.id : ""}
				name={fieldName}
				label={label}
				readOnly={!isTypeOther ? !item.isEditable : false}
				isTypeOther={isTypeOther}
				index={index}
				isDepense={isDepense}
				commentsChamp={commentsChamp}
				onCommentChange={onCommentChange}
				defaultConfirmationMontantChamp={defaultConfirmationMontantChamp}
				onConfirmationMontantChange={onConfirmationMontantChange}
				files={files}
				setFiles={setFiles}
				onValidation={validationInput}
			/>
		);
	}
	
	/** AJOUT / SUPPRESSION DE LIGNES AUTRE */
	const addAutre = (budgetType: any, isDepense: boolean) => {
		let array = {...budget};
		let temporaryId: string; // Id qui sert à cibler les nouvelles lignes qu'on ajoute

		while (true) { // Pour être sûr d'avoir que des ids différents, il regénère l'id tant qu'il n'existe pas
			temporaryId = '_' + Math.random().toString(36).substr(2, 9);
			if(isDepense) {
				if(!array.depenses || !array.depenses.some(d => d.id === temporaryId))
					break;
			} else {
				if(!array.financements || !array.financements.some(d => d.id === temporaryId))
					break;
			}
		}

		if (isDepense) {
			if (!array.depenses) {
				array = {
					...array,
					depenses: []
				};
			}
			array.depenses.push({
				id: temporaryId,
				type: budgetType,
				champ: ChampDepense.Autre
			});
		} else {
			if (!array.financements) {
				array = {
					...array,
					financements: []
				};
			}
			array.financements.push({
				id: temporaryId,
				type: budgetType,
				champ: ChampFinancement.Autre
			});
		}
		setBudget(array);
	}
	const deleteAutre = (id:string, isDepense: boolean) => {
		let array = {...budget};

		if (isDepense) {
			budget.depenses = array.depenses.filter((item) => item.id != id);
		} else {
			budget.financements = array.financements.filter((item) => item.id != id);
		}
		montant(id);
		setBudget(budget);
	}

	const onCommentChange = (id: string, comment: any) => {
		let array = [...commentsChamp];
		if (array.length !== 0) {
			for (let idx in array) {
				if (id === array[idx].id) {
					array[idx] = {
						id: id,
						comment: comment
					};
					setCommentsChamp(array);
					return;
				}
			}
		}

		array.push({ id: id, comment: comment})
		setCommentsChamp(array);
	}

	const onConfirmationMontantChange = (id:string, confirmation: boolean) => {
		let array = [...confirmationMontantChamp];
		if (array.length !== 0) {
			for (let idx in array) {
				if (id === array[idx].id) {
					array[idx] = {
						id: id,
						confirmationMontant: confirmation
					};
					setConfirmationMontantChamp(array);
					return;
				}
			}
		}
		array.push({ id: id, confirmationMontant: confirmation})
		setConfirmationMontantChamp(array);
	}


	/** Render de champs Autre dans un block */
	const renderDelete = (id:string, isDepense:boolean) => {
		return(
			<div className="creationDossier__addElement elementButton--absolute">
				<div className="creationDossier__addElementButton" onClick={() => deleteAutre(id, isDepense) }>
					<i className="far fa-minus-circle" />
				</div>
			</div>
		);
	}
	const renderAdd = (type:TypeDepense|TypeFinancement, isDepense:boolean) => {
		return(
			<div className="creationDossier__addElement">
				<div className="creationDossier__addElementButton" onClick={() => addAutre(type, isDepense) }>
					<i className="fas fa-plus-circle" />
				</div>
			</div>
		);
	}

	 
	/** Render d'un champ */
	const renderBlockRow = (
		isDepense:boolean,
		type: TypeDepense|TypeFinancement,
		index:number,
		viewModelDepenseArray?:DepenseViewModelDto[],
		viewModelFinancementArray?: FinancementViewModelDto[]
	) => {
	return(
		<div>
			{isDepense && viewModelDepenseArray
			? viewModelDepenseArray.map((item, i) => (
				<div key={i}>
					{item.champ !== ChampDepense.Autre && 
						renderChamp(
							null,
							isDepense,
							item,
							type,
							`${index+1}.${i+1}. ${t(`champDepense.${getChampDepenseKey(item.champ, categorieDossier.code as CategorieDossier)}`)}`,
							false,
							i
						)}
					</div>
				))
			: viewModelFinancementArray.map((item, i) => (
				<div key={i}>
					{item.champ !== ChampFinancement.Autre &&
						renderChamp(
							null,
							isDepense,
							item,
							type,
							`${index+1}.${i+1}. ${t(`champFinancement.${item.champ}`)}`,
							false,
							i
						)}
				</div>
			))}
			{isDepense
			? budget && budget.depenses && budget.depenses
				.filter(d => (d.type === type) && (d.champ === ChampDepense.Autre))
				.map((item, i) => (
					<div key={item?.id ?? i} className="creationDossier__itemRow">
						{renderChamp(item, isDepense, item, type, "", true, i)}
						{renderDelete(item.id, isDepense)}
					</div>
				))
			: budget && budget.financements && budget.financements
				.filter(d => (d.type === type) && (d.champ === ChampFinancement.Autre))
				.map((item, i) => (
					<div key={item?.id ?? i} className="creationDossier__itemRow">
						{renderChamp(item, isDepense, item, type, "", true, i)}
						{renderDelete(item.id, isDepense)}
					</div>
				))
			}
			{(isDepense ? viewModelDepenseArray : viewModelFinancementArray).some((x:DepenseDto|FinancementDto) => x.champ === (isDepense ? ChampDepense.Autre : ChampFinancement.Autre)) &&
				renderAdd(type, isDepense)
			}
		</div>);
	}


	return (<>
		<h4 className="title--red">
			{index+1}. {t(`createFolder.budget.${isDepense ? "subtitlesDepenses" : "subtitlesFinancement"}.${
				categorieDossier.code === CategorieDossier.AidePromotionImage &&
					(itemViewModel.type === TypeDepense.AutresDepenses || itemViewModel.type === TypeFinancement.RecettesPropres)
				? itemViewModel.type + 'Api'
				: itemViewModel.type
			}`)}
		</h4>
		{(montantMax || montantMin) &&
			<div className="creationDossier__subtitle">
				{montantMax &&
					<div className={clsx(montantBlock > montantMax && "subtitle--red")}>
						{t("createFolder.budget.titles.montantMax")}{montantBlock}/{montantMax}€
					</div>
				}
				{montantMin &&
					<div className={clsx(montantBlock < montantMin && "subtitle--red")}>
						{t("createFolder.budget.titles.montantMin")}{montantMin}€
					</div>
				}
			</div>
		}
		<div className="creationDossier__item">
			{isDepense
				? renderBlockRow(isDepense, itemViewModel.type, index, (itemViewModel as DepenseBlockViewModelDto).depenses)
				: renderBlockRow(isDepense, itemViewModel.type, index, null, (itemViewModel as FinancementBlockViewModelDto).financements)
			}
			<h4 className="creationDossier__budgetSubtitleTotal">
				{t("createFolder.budget.titles.sousTotal", {montant: formatNumber(montantBlock)})}
			</h4>

			{showBaseCalcul && (
				<h4 className="creationDossier__budgetSubtitleTotal">
					{t("createFolder.budget.titles.baseCalcul", {
						montant: formatNumber(montantBlock)
					})}
				</h4>
			)}
		</div>
	</>);
}

export default BudgetBlock;