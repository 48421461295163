import moment from 'moment';
export const reduceToOneDigit = (value) => {
    let sum = null;
    let tmp = null;
    while (value >= 10) {
        sum = 0;
        tmp = value;
        while (tmp !== 0) {
            sum += (tmp % 10);
            tmp = tmp / 10;
        }
        value = sum;
    }
    return value;
};
export function calculateMod(str, mod) {
    let n = str.length;
    if (n <= 10)
        return parseInt(str) % mod;
    else {
        const first = str.substring(0, n - 10);
        const second = str.substring(n - 10);
        return (calculateMod(first, mod) * (Math.pow(10, 10) % mod) + parseInt(second, 10) % mod) % mod;
    }
}
export const scrollToBottom = (id) => {
    var messageBody = document.getElementById(id);
    if (messageBody) {
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
};
export const scrollWindowTop = () => {
    window.scrollTo(0, 0);
};
export const normalizeDate = (date) => {
    if (date)
        return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
};
export const escapeName = (name) => {
    if (name)
        return name === null || name === void 0 ? void 0 : name.replace(/\W/g, '');
};
export const formatNumber = (number = 0, style) => {
    return new Number(Math.round(number * 100) / 100).toLocaleString("fr-FR", { style: style || "decimal" });
};
export const formatPhone = (nb) => {
    var _a, _b;
    if (nb) {
        if (nb.substring(0, 3) === "+33" || nb.substring(0, 1) === "+")
            return nb.substring(0, 4) + " " + ((_a = nb.substring(4).match(/.{1,2}/g)) === null || _a === void 0 ? void 0 : _a.join(" "));
        else
            return (_b = nb.match(/.{1,2}/g)) === null || _b === void 0 ? void 0 : _b.join(" ");
    }
};
export const sortArrayAsc = (a, b) => {
    if (a < b) {
        return -1;
    }
    else if (a == b) {
        return 0;
    }
    else {
        return 1;
    }
};
export const capitalizeFirstLetter = (value) => {
    if (value)
        return value.charAt(0).toUpperCase() + value.slice(1);
};
export const formaterDate = (date) => {
    if (date)
        return moment(date).format('DD/MM/YY');
};
export const formaterDateWithTime = (date) => {
    if (date)
        return moment(date).format('DD/MM/YY HH:mm:ss');
};
