import React, { useState, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { ActionTypeEnum } from "../../context/ActionType";

import { IStepSevenModel } from "../../models/signup";
import { toast } from 'react-toastify';
import Mail from "../../assets/img/portail/mail.png";
import StepCounter from "../../components/signup/signup-items/StepCounter";
import Subtitles from "../../components/basics/Subtitles";
import Input from "../../components/basics/Input";
import FormButton from 'adel-shared/dist/components/basics/FormButton';
import { RouteComponentProps } from "@reach/router";
import { useAppDispatch, useStepSevenContext } from "../../context/context-helpers";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../custom-hooks/useAxios";
import { StructureClient } from "../../services/generated/FrontOffice-api";
import useValidation from "../../custom-hooks/useValidation";

interface StepSevenProps extends RouteComponentProps {
	ref: any;
	value?: IStepSevenModel;
}

const StepSeven: React.FunctionComponent<StepSevenProps> = forwardRef((value, ref) => {
	const dispatch = useAppDispatch();
	const context = useStepSevenContext();
	const { register, getValues, triggerValidation, errors } = useForm<IStepSevenModel>({ defaultValues: context })
	const [isEmailWrong, setIsEmailWrong] = useState(false);
	const [email, setEmail] = useState<string>(context.email);
	const structureId = context.structureId;
	const { t } = useTranslation();
	const { getRootValidator } = useValidation();
	const contactValidator = getRootValidator("CreateContactValidator");
	const axiosInstance = useAxios();

	const validateForm = async (): Promise<boolean> => {
		const result = await triggerValidation();

		return result;
	}

	const sendEmail = async () => {
		var client = new StructureClient('', axiosInstance);

		try {
			let email = null;
			if (isEmailWrong) {
				const validated = await validateForm();
				if (validated) {
					email = getValues().newEmail;
					setEmail(email);
					setIsEmailWrong(false);
				} else {
					return;
				}
			}
			await client.resendValidationEmail(structureId, email ?? undefined);
			toast.success(t('signup.step-seven.email.sent'));
		}
		catch (error) {
			dispatch({ type: ActionTypeEnum.ERROR_OCCURRED, payload: { type: error } });
			
			if (error.exception?.message)
				toast.error(error.exception.message);
			else
				toast.error(t("errors.default"));
		}
	}

	return (
		<section className="validation">

			<StepCounter
				counter={7}
			/>

			<Subtitles
				title={t('signup.step-seven.email.create-password-title')}
			/>

			<img src={Mail} alt="Logo email" />

			{!isEmailWrong ?
				<>
					<p>
						{t('signup.step-seven.email.confirm-message')}
						<b>{t('signup.step-seven.email.confirm-message2')}</b>
						{t('signup.step-seven.email.confirm-message3')}
					</p>

					<span className="confirm-email">{email}</span>

					<p>
						{t('signup.step-seven.email.click')}
						<b>{t('signup.step-seven.email.validate')}</b>
						{t('signup.step-seven.email.finalize')}
					</p>

					<p>
						{t('signup.step-seven.email.not-received')}
						<br />
						<FormButton
							type="button"
							value={t('signup.step-seven.email.resend')}
							onClick={sendEmail}
						/>
					</p>

					<p>
						{t('signup.step-seven.email.wrong')}
						<br />
						<FormButton
							type="button"
							value={t('signup.step-seven.email.click-here')}
							onClick={() => {
								setIsEmailWrong(true)
							}}
						/>
					</p>
				</>
				:
				<>
					<Input
						name="newEmail"
						reference={register(contactValidator && contactValidator["Email"])}
						label=""
						type="text"
						placeHolder="Renseigner votre adresse mail"
						errors={errors}
					/>

					<FormButton
						type="button"
						value={t('signup.step-seven.email.resend-new-address')}
						onClick={sendEmail}
					/>

					<button
						onClick={() => {
							setIsEmailWrong(false);
						}}
					>
						{t('signup.step-seven.cancel')}
					</button>
				</>
			}
		</section>
	)
})

export default StepSeven;