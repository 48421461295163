import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import Moment from "react-moment";
import 'react-calendar/dist/Calendar.css';
import useOnClickOutside from 'use-onclickoutside';
const InputCalendarLabelled = ({ defaultDate, onDateSelected, label, minDate, maxDate }) => {
    const [date, setDate] = useState(defaultDate);
    const [showCalendar, setShowCalendar] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setShowCalendar(false));
    useEffect(() => {
        setDate(defaultDate);
    }, [defaultDate]);
    return React.createElement("div", { className: "input" },
        React.createElement("label", null, label),
        React.createElement("div", { className: "inputFile__calendar", onClick: () => setShowCalendar(true) },
            React.createElement("span", null, date && React.createElement(Moment, { format: "DD/MM/YYYY" }, date)),
            " ",
            React.createElement("i", { className: "far fa-calendar-alt" }),
            showCalendar &&
                React.createElement(Calendar, { onChange: (date) => {
                        setDate(date);
                        onDateSelected(date);
                        setShowCalendar(false);
                    }, minDate: minDate, maxDate: maxDate, value: date, locale: "fr-FR" })));
};
export default InputCalendarLabelled;
