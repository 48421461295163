import React from "react";
import { useTranslation } from 'react-i18next';

interface StepCounterProps {
    counter: number;
}

const StepCounter: React.FC<StepCounterProps> = (props) => {
	const { t } = useTranslation();

    return(
            <div className="stepCounter">
                <span>{t("signup.step")} {props.counter}/7</span>
            </div>
    )
}

export default StepCounter;