import React, {useState} from "react";
import QuestionInputProps from "./QuestionInputProps"
import Input from 'adel-shared/dist/components/basics/Input';

interface SimpleQuestionInputProps extends QuestionInputProps {
    type: string;
    parse: (rawValue: string) => any;
    attributes?: {[key: string]: any}
}

const SimpleQuestionInput : React.SFC<SimpleQuestionInputProps> = (props) => {
	return <>
		<Input
			name={props.id}
			label={`${props.title ? props.title : ""}${props.question?.isRequired ? '*' : ''}`}
			type={props.type}
			minLength={props.question?.answerMinLength}
			maxLength={props.question?.answerMaxLength}
			value={props.currentValue}
			readOnly={!(props.question.presetAnswer?.isModifiable ?? true)}
			required={props.question?.isRequired}
			onChange={(value:string) => {
				if (props.onAnswerChange) {
					props.onAnswerChange(props.question?.id, value);
				}
			}}
			{ ...props.attributes }
			errorMessageCusto={props.errorMessage && props.errorMessage.message && props.errorMessage.message !== '' && props.errorMessage.message}
		/>
    </>;
}

export default SimpleQuestionInput;
