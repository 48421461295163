import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import InputSelect, { AdelOption } from 'adel-shared/dist/components/basics/InputSelect';
import { TypeTransport, PaysDto } from '../../../../../services/generated/FrontOffice-api';
import EscaleCheckbox from './EscaleCheckbox';
import { Controller } from 'react-hook-form';
import { CategorieLieu } from './EditTrajet';
import { Dictionary } from '../../../../../models';

interface RenderTrajetBlockProps {
	name:string;
	isDepart: boolean;
	isDestination: boolean;
	control: any;
	errors?: any;
	watch: any;
	setValue?:any;
	etapeValidator: Dictionary<any>;
	paysList?: PaysDto[];
	paysPrestations?: PaysDto[];
	paysFull: PaysDto[];

	isSelectWithoutCar: boolean;
	setIsSelectWithoutCar: (value:boolean) => void;
}

const RenderTrajetBlock: React.FC<RenderTrajetBlockProps> = ({
	name,
	isDepart,
	isDestination,
	paysList,
	paysPrestations,
	paysFull,
	watch,
	control,
	errors,
	setValue,
	isSelectWithoutCar,
	setIsSelectWithoutCar,
	etapeValidator,
}) => {
	const { t } = useTranslation();
	const [selectedTransport, setSelectedTransport] = useState<TypeTransport>();
	const watchPaysDepart = watch(`depart.pays`);

	const watchAller = watch(`${name}.${CategorieLieu.Escale}.aller.boolean`);
	const watchRetour = watch(`${name}.${CategorieLieu.Escale}.retour.boolean`);

	const watchPaysAller = watch(`${name}.${CategorieLieu.Escale}.aller.pays`);
	const watchPaysRetour = watch(`${name}.${CategorieLieu.Escale}.retour.pays`);

	const [paysOptions, setPaysOptions] = useState<AdelOption<PaysDto>[]>([]);
	const [paysOptionsFull, setPaysOptionsFull] = useState<AdelOption<PaysDto>[]>([]);

	/** Pays Option */
	useEffect(() => {
		let array:AdelOption<PaysDto>[] = [];

		if (paysList) {
			if (isDepart) {
				array = paysList && paysList.sort((a, b) => a.nom.localeCompare(b.nom))
						.filter(e => e.estAimable || e.nom === "FRANCE")
						.map(element => {
					return {
						value: element,
						label: element.nom
					}
				});
			} else {
				array = paysList && paysList.sort((a, b) => a.nom.localeCompare(b.nom))
						.filter(e => e.nom === "FRANCE" || watchPaysDepart?.id === e.id)
						.map(element => {
					return {
						value: element,
						label: element.nom
					}
				});
			}
			setPaysOptions(array);

		} else if (paysPrestations) {
			array = paysPrestations && paysPrestations.sort((a, b) => a.nom.localeCompare(b.nom)).map(function (element: PaysDto) {
				return {
					value: element,
					label: element.nom
				}
			});
			setPaysOptions(array);
		}

	}, [paysList, paysPrestations, watchPaysDepart]);

	useEffect(() => {
		if (paysFull) {
			setPaysOptionsFull(paysFull.sort((a, b) => a.nom.localeCompare(b.nom))
				.map((element: PaysDto) => {
					return {
						value: element,
						label: element.nom
					}
				})
			);
		}
	}, [paysFull]);

	useEffect(() => {
		if(setValue && !isDepart && !isDestination)
			setValue(`${name}.pays`, watchPaysDepart);
	}, [watchPaysDepart]);


	/** Type transports */
	const transportOptions: AdelOption<TypeTransport>[] = useMemo(() => {
		let allOptions: AdelOption<TypeTransport>[] = [];
		for (let statut in TypeTransport) {
			if (statut !== "None") {
				allOptions.push(
					{
						value: TypeTransport[statut as keyof typeof TypeTransport],
						label: t(`common.typeTransport.${TypeTransport[statut as keyof typeof TypeTransport]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const transportNoCarOptions: AdelOption<TypeTransport>[] = useMemo(() => {
		let allOptions: AdelOption<TypeTransport>[] = [];
		for (let statut in TypeTransport) {
			if (statut !== "None" && statut !== "Voiture") {
				allOptions.push(
					{
						value: TypeTransport[statut as keyof typeof TypeTransport],
						label: t(`common.typeTransport.${TypeTransport[statut as keyof typeof TypeTransport]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const transportOnlyCarOptions: AdelOption<TypeTransport>[] = useMemo(() => {
		let allOptions: AdelOption<TypeTransport>[] = [];
		for (let statut in TypeTransport) {
			if (statut === "Voiture") {
				allOptions.push(
					{
						value: TypeTransport[statut as keyof typeof TypeTransport],
						label: t(`common.typeTransport.${TypeTransport[statut as keyof typeof TypeTransport]}`)
					}
				)
			}
		}
		return allOptions;
	}, []);

	const renderSelect = useMemo(() => {
		if(isSelectWithoutCar) {
			return (
				<Controller control={control}
					name={`${name}.transport`}
					as={({ onChange, value, name }) => (
						<InputSelect<TypeTransport>
							name={name}
							label={t('createFolder.trajets.form.transport')}
							classname="inputSelect"
							options={transportOnlyCarOptions}
							errors={errors}
							onChange={(selectedTransport) => {
								onChange(selectedTransport);
								setSelectedTransport(selectedTransport);
							}}
							value={value}
							placeholder={t("common.select")}
						/>
					)}
					rules={etapeValidator && etapeValidator["TypeTransport"]}
				/>
			);
		} else {
			return(
			<Controller control={control}
				name={`${name}.transport`}
				as={({ onChange, value, name }) => (
					<InputSelect<TypeTransport>
						name={name}
						label={t('createFolder.trajets.form.transport')}
						classname="inputSelect"
						options={transportNoCarOptions}
						errors={errors}
						onChange={(selectedTransport) => {
							onChange(selectedTransport);
							setSelectedTransport(selectedTransport);
						}}
						value={value}
						placeholder={t("common.select")}
					/>
				)}
				rules={etapeValidator && etapeValidator["TypeTransport"]}
			/>
		)}
	}, [isSelectWithoutCar, transportOptions, control, name, errors]);


	return (
		<>
			<div className="creationDossier__row">
				<Controller control={control}
					name={`${name}.pays`}
					as={({ onChange, value, name }) => (
						<InputSelect<PaysDto>
							name={name}
							label={t('createFolder.trajets.form.pays')}
							classname="inputSelect"
							options={paysOptions}
							errors={errors}
							onChange={(p) => onChange(p)}
							value={value}
							isSearchable={true}
							placeholder={t("common.select")}
						/>
					)}
					rules={etapeValidator && etapeValidator["PaysId"]}
				/>
				{isDepart 
					? <Controller control={control}
						name={`${name}.transport`}
						as={({ onChange, value, name }) => (
							<InputSelect<TypeTransport>
								name={name}
								label={t('createFolder.trajets.form.transport')}
								classname="inputSelect"
								options={transportOptions}
								placeholder={t("common.select")}
								errors={errors}
								onChange={(selectedTransport) => {
									onChange(selectedTransport);
									if(selectedTransport === TypeTransport.Voiture) {
										setIsSelectWithoutCar(true);
									} else {
										setIsSelectWithoutCar(false);
									}
									setSelectedTransport(selectedTransport);
								}}
								value={value}
								/>
							)}
						rules={etapeValidator && etapeValidator["TypeTransport"]}
					/> 
					: renderSelect
				}
			</div>

			{!isDepart && isDestination &&
				<div className="creationDossier__row rowTrajet">
					<div className="creationDossier__trajetDestination">
						<h4>{t('createFolder.trajets.aller')}</h4>
						<div className="creationDossier__row destinationRow">
							<Controller control={control}
								name={`${name}.${CategorieLieu.Escale}.aller.boolean`}
								as={({ onChange, value, name }) => (
									<EscaleCheckbox name={name} onChange={onChange} value={value} />
								)}
							/>
							<div>
								<Controller control={control}
									name={`${name}.${CategorieLieu.Escale}.aller.pays`}
									as={({ onChange, value, name }) => (
										<InputSelect<PaysDto>
											name={name}
											label={t('createFolder.trajets.form.pays')}
											classname="inputSelect"
											options={paysOptionsFull}
											errors={errors}
											onChange={(p) => onChange(p)}
											value={value}
											isSearchable={true}
											placeholder={t("common.select")}
											isDisabled={watchAller === "false" || watchAller === undefined}
										/>
									)}
								/>
								{(watchAller === "true" && watchPaysAller === undefined) && <p className="errorMessage">{t("validation-messages.required-country")}</p>}
							</div>
						</div>
						{(watchAller !== "true" && watchAller !== "false") && <p className="errorMessage">{t("validation-messages.required-response")}</p>}						
					</div>

					<div className="creationDossier__trajetDestination">
						<h4>{t('createFolder.trajets.retour')}</h4>
						<div className="creationDossier__row destinationRow">
							<Controller control={control}
								name={`${name}.${CategorieLieu.Escale}.retour.boolean`}
								as={({ onChange, value, name }) => (
									<EscaleCheckbox name={name} onChange={onChange} value={value} />
								)}
							/>
							<div>
								<Controller control={control}
									name={`${name}.${CategorieLieu.Escale}.retour.pays`}
									as={({ onChange, value, name }) => (
										<InputSelect<PaysDto>
											name={name}
											label={t('createFolder.trajets.form.pays')}
											classname="inputSelect"
											options={paysOptionsFull}
											errors={errors}
											onChange={(p) => onChange(p)}
											value={value}
											isSearchable={true}
											placeholder={t("common.select")}
											isDisabled={watchRetour === "false" || watchRetour === undefined}
										/>
									)}
								/>
								{(watchRetour === "true" && watchPaysRetour === undefined) && <p className="errorMessage">{t("validation-messages.required-country")}</p>}
							</div>
						</div>
						{(watchRetour !== "true" && watchRetour !== "false") && <p className="errorMessage">{t("validation-messages.required-response")}</p>}
					</div>
				</div>
			}
		</>
	);
}

export default RenderTrajetBlock;