import React from "react";
import { useTranslation } from "react-i18next";
interface StructuresFolderCardHeaderActionsProps {
	messages: number;
	onView: () => void;
	onDuplicate: () => void;
	onEdit?: () => void;
	onAbandon?: () => void;
}
const StructuresFolderCardHeaderActions: React.FunctionComponent<StructuresFolderCardHeaderActionsProps> = ({
	messages,
	onView,
	onDuplicate,
	onEdit,
	onAbandon
}) => {
	const { t } = useTranslation();
	return (
		<div className="folderCard__headerActions">
			{(messages > 0) && (
				<div className="message"><span>{messages + ' message'}</span>
					{(messages > 1) && <span>s</span>}
				</div>
			)}

			<div>
				<i
					className="far fa-eye"
					role="button"
					title={t('folderActions.view')}
					onClick={onView}
				></i>
			</div>
			{onEdit &&
				<div>
					<i
						className="fas fa-edit"
						role="button"
						title={t('folderActions.edit')}
						onClick={onEdit}
					></i>
				</div>
			}
			<div>
				<i
					className="far fa-copy"
					role="button"
					title={t('folderActions.duplicate')}
					onClick={onDuplicate}
				></i>
			</div>
			{onAbandon &&
				<div>
					<i
						className="far fa-trash-alt"
						role="button"
						title={t('folderActions.delete')}
						onClick={onAbandon}
					></i>
				</div>
			}
		</div>
	)
};

export default StructuresFolderCardHeaderActions;