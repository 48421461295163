import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { CurrentCritereDossierDto, DossierClient } from '../../../services/generated/FrontOffice-api';
import { useAxios } from '../../../custom-hooks/useAxios';
import { toast } from 'react-toastify';

interface CriteresProps extends RouteComponentProps {
	ref: any,
	canGoNext: boolean;
	categorieDossierId: string;
	setCanGoNext: (value: boolean) => void;
	setCriteriaRead: (value: boolean) => void;
	dossierId?: string;
	setIsLoading: (value: boolean) => void;
}

const Criteres: React.FC<CriteresProps> = forwardRef(({
	categorieDossierId,
	setCanGoNext,
	setCriteriaRead,
	dossierId,
	setIsLoading
}, ref) => {
	const { t, i18n } = useTranslation();
	const axiosInstance = useAxios();
	const dossierClient = new DossierClient("", axiosInstance);

	const isDossierExist = dossierId !== null && dossierId !== undefined && dossierId !== "";

	// Get critères quand on arrive sur la page
	const [criteresDossier, setCriteresDossier] = useState<CurrentCritereDossierDto>({});
	const getCriteres = async () => {
		setIsLoading(true);

		try {
			const criteres = await dossierClient.getCurrentCritereDossier(categorieDossierId);
			setCriteresDossier(criteres);
			setIsLoading(false);
		} catch (error) {
			if (error.exception?.message)
				toast.error(error.exception.message);
			setIsLoading(false);
		}
	}
	useEffect(() => {
		if (categorieDossierId) {
			getCriteres();
		}
	}, []);


	useEffect(() => {
		let element = document.getElementById('critereScrollElement')
		const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
		const gradient = document.querySelector(".gradient") as HTMLElement;
		if (!hasVerticalScrollbar) {
			gradient.style.display = "none";
		} else {
			gradient.style.display = "block";
			gradient.style.backgroundImage = "linear-gradient(to bottom, transparent, white)";
		}

	}, [criteresDossier])

	// Obligé de passer par ce state intermédiaire pour ne pas créer directement le dossier quand on clique sur la checkbox, mais que en cliquant sur "Suivant"
	const [checkbox, setCheckbox] = useState<boolean>(false);

	const handleScroll = (e: any) => {
		const target = e.target;
		const gradient = document.querySelector(".gradient") as HTMLElement;

		// target.scrollHeight - target.scrollTop === target.clientHeight => bug de 0.8px sur écran 13p
		if (target.scrollHeight - target.scrollTop > target.clientHeight && target.scrollHeight - target.scrollTop < 451 ||
			target.scrollHeight - target.scrollTop === target.clientHeight) {
			gradient.style.display = "none";
		} else {
			gradient.style.display = "block";
			gradient.style.backgroundImage = "linear-gradient(to bottom, transparent, white)";
		}
	}

	useEffect(() => {
		if(isDossierExist) {
			setCanGoNext(true)
		} else {
			setCanGoNext(checkbox)
		}
	}, [checkbox]);

	/** Post */
	useImperativeHandle(ref, () => ({
		async validateForm(): Promise<boolean> {
			if (isDossierExist) {
				return true;
			} else {
				if (checkbox === true) {
					setCriteriaRead(true);
					return true;
				} else {
					return false;
				}
			}
		}
	}));

	return (
		<div className="criteres">
			<h2>{t("createFolder.critere.title")} {criteresDossier.categorie?.nom}</h2>

			<div className="criteres__item" onScroll={handleScroll} id="critereScrollElement">
				<div>
					<h4>{t("createFolder.critere.subtitle")}</h4>

					{criteresDossier.contenu && (
						<div
							className="messageContentText jodit-wysiwyg"
							dangerouslySetInnerHTML={{ __html: criteresDossier.contenu[i18n.language] }}
						></div>
					)}

					<div className="creationDossier__checkbox">
						<input
							type="checkbox"
							onChange={() => setCheckbox(!checkbox)}
							defaultChecked={isDossierExist}
							disabled={isDossierExist}
						/>

						<label>{t('createFolder.critere.checkbox')}</label>
					</div>
				</div>

				<span className="gradient"></span>
			</div>
		</div>
	)
});

export default Criteres;