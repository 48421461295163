import React, { useState, useEffect } from "react";
import Select, { ValueType, ActionMeta, OptionTypeBase } from "react-select";
import { ErrorMessage } from "react-hook-form";

interface InputSelectProps<T> {
	name: string;
	label: string;
	options?: AdelOption<T>[];
	classname: string;
	errors?: any;
	onChange?: (e: T) => void;
	value?: T;
	isSearchable?: boolean;
	customMatchValue?: (source: T, target: T) => boolean; // Used to compare complex values
}

export interface AdelOption<T> {
	label: string;
	value: T
}

function InputSelect<T>({
	label,
	name,
	classname,
	options,
	onChange,
	value,
	errors,
	customMatchValue,
	isSearchable = false
}: InputSelectProps<T>) {
	const [selectedValue, setSelectedValue] = useState<ValueType<OptionTypeBase, boolean>>({});

	const handleChange = (value: ValueType<OptionTypeBase, boolean>, actionMeta: ActionMeta<OptionTypeBase>) => {
		setSelectedValue(value);
		let adelOption = getAdelOptionFromReactSelectValue(value);
		if (onChange && adelOption?.value) {
			onChange(adelOption.value);
		}
	}

	useEffect(() => {
		if (options && value) {
			let selectedOption = options?.filter(option => {
				if (!customMatchValue) { // Aucune comparaison custom de dÃƒÂ©finie = type simple (string, number, bool, etc.))
					return option.value === value;
				} else { // Comparaison de type complexe (objets, etc.)
					return customMatchValue(option.value, value);
				}
			});
			if (selectedOption.length > 0) {
				setSelectedValue(selectedOption[0]);
			} else {
				//TODO: Replace this ?
				// console.debug("value could not be found", value);
			}
		}
	}, [value, options, customMatchValue]) // Executed only once for default value

	const getAdelOptionFromReactSelectValue = (selectValue: ValueType<OptionTypeBase, boolean>): AdelOption<T> | null => {
		if (selectValue) {
			let adelOption = {
				value: (selectValue as AdelOption<T>).value,
				label: (selectValue as AdelOption<T>).label
			}
			return adelOption;
		} else {
			return null;
		}
	}

	const customStyles = {
		option: (base: any, state: any) => ({
			...base,
			backgroundColor: state.isSelected ? '#d93943' : state.isFocused ? '#f4c7ca' : "white",
		}),
	};

	return (
		<div className="input">
			<label>{label}</label>

			<Select
				className={classname}
				options={options}
				value={selectedValue}
				onChange={handleChange}
				isSearchable={isSearchable}
				styles={customStyles}
			/>

			{errors &&
				<ErrorMessage errors={errors} name={name}>
					{({ message }) => <p className="input__errorMessage">{message}</p>}
				</ErrorMessage>
			}
		</div>
	)
}

export default InputSelect;