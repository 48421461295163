import React from "react"
import Logo from "../../assets/logo/logo_spedidam.png"

const WhiteLogo = () => {
    return(
        <img
            className="portal__item portal__logo"
            src={Logo}
            alt="Logo Spedidam"
        />
    )
}

export default WhiteLogo;