import React from "react";
import ArrayQuestionInputItem from "./ArrayQuestionInputItem";
const ArrayQuestionInput = (props) => {
    var _a;
    return React.createElement("div", null,
        props.title &&
            React.createElement("div", { className: "questionField__title" }, props.title), (_a = props.currentValue) === null || _a === void 0 ? void 0 :
        _a.map((item, i) => {
            var _a;
            return React.createElement("div", { key: i },
                React.createElement(ArrayQuestionInputItem, { index: i, item: item, renderQuestionInput: props.renderQuestionInput, questions: (_a = props.question) === null || _a === void 0 ? void 0 : _a.arrayItemsQuestions }));
        }));
};
export default ArrayQuestionInput;
