import { AnswerType, ConditionOperator } from "../models/generated/FrontOffice-api";
import _ from "lodash";
export const shouldDisplayQuestion = (question, answers) => {
    if (!question.conditions) {
        return true;
    }
    return question.conditions.every(c => isConditionSatisfied(c, answers));
};
export const shouldDisplayChoice = (choice, answers) => {
    if (!choice.conditions) {
        return true;
    }
    return choice.conditions.every(c => isConditionSatisfied(c, answers));
};
export const isConditionSatisfied = (condition, answers) => {
    var _a, _b;
    const answer = answers[condition.questionId];
    switch (condition.operator) {
        case ConditionOperator.Empty:
            return answer === null || answer === undefined || answer === [] || answer === "";
        case ConditionOperator.Equal:
            if (Array.isArray(answer)) {
                return answer.some(item => { var _a; return item === ((_a = answers[condition.operandQuestionId]) !== null && _a !== void 0 ? _a : condition.operand); });
            }
            else {
                return answer === ((_a = answers[condition.operandQuestionId]) !== null && _a !== void 0 ? _a : condition.operand);
            }
        case ConditionOperator.AnyOf:
            if (Array.isArray(answer)) {
                const arr = answer;
                const intersect = _.intersection(arr, condition.operands);
                return intersect.length > 0;
            }
            else {
                return condition.operands.includes(answer);
            }
        case ConditionOperator.LesserThan:
            return answer < ((_b = answers[condition.operandQuestionId]) !== null && _b !== void 0 ? _b : condition.operand);
        default:
            throw new Error("Invalid condition operator");
    }
};
const allowUndefined = (question) => {
    return question.answerType === AnswerType.None || !question.isRequired;
};
const isValidURL = (str) => {
    // protocol                                                  // domain                                   // OR ip (v4) address    // port and path           // query string      // fragment locator
    const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
    return pattern.test(str);
};
export const parseAnswerValue = (value, answerType) => {
    switch (answerType) {
        case AnswerType.Date:
            if (typeof value === "string" || typeof value === "number") {
                value = new Date(value);
            }
            break;
        case AnswerType.IntegerNumber:
            if (typeof value === "string") {
                value = Number.parseInt(value);
            }
            break;
        case AnswerType.DecimalNumber:
            if (typeof value === "string") {
                value = Number.parseFloat(value);
            }
            break;
        case AnswerType.YesNo:
            if (typeof value === "string") {
                value = value.toLowerCase() === "true";
            }
            break;
        case AnswerType.YesNoDontKnow:
            if (typeof value === "string") {
                console.log(value.toLowerCase());
                value = value.toLowerCase();
            }
            break;
        case AnswerType.Period:
            if (value && typeof value === "object") {
                value = {
                    start: parseAnswerValue(value.start, AnswerType.Date),
                    end: parseAnswerValue(value.end, AnswerType.Date),
                };
            }
            break;
    }
    return value;
};
//TODO: renommer ça en ValidateAnswer
export const isAnswerValid = (question, answerValue, t) => {
    if (answerValue === undefined) {
        if (allowUndefined(question) === true) {
            return { isValid: true };
        }
        else {
            return {
                isValid: false,
                message: t("validation-messages.not-empty")
            };
        }
    }
    if (question.presetAnswer && !question.presetAnswer.isModifiable) {
        if (answerValue === question.presetAnswer.value === true) {
            return { isValid: true };
        }
        else {
            return {
                isValid: false,
                message: t("validation-messages.invalid-field")
            };
        }
    }
    switch (question.answerType) {
        case AnswerType.None:
            if (!answerValue === true) {
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-field")
                };
            }
        case AnswerType.Text:
        case AnswerType.MultilineText:
        case AnswerType.Url:
        case AnswerType.Html:
            if (typeof answerValue === "string") {
                if (answerValue.length === 0) {
                    if (question.isRequired) {
                        return {
                            isValid: false,
                            message: t("validation-messages.required")
                        };
                    }
                    else {
                        return { isValid: true };
                    }
                }
                if (question.answerMaxLength && answerValue.length > question.answerMaxLength) {
                    return {
                        isValid: false,
                        message: t("validation-messages.max-length", { length: question.answerMaxLength })
                    };
                }
                if (question.answerMinLength && answerValue.length < question.answerMinLength) {
                    return {
                        isValid: false,
                        message: t("validation-messages.min-length", { length: question.answerMinLength })
                    };
                }
                const isMultiLine = question.answerType === AnswerType.MultilineText || question.answerType === AnswerType.Html;
                if (isMultiLine && answerValue === "") {
                    return {
                        isValid: false,
                        message: t("validation-messages.not-empty")
                    };
                }
                if (question.answerType === AnswerType.Url && !isValidURL(answerValue)) {
                    return {
                        isValid: false,
                        message: t("validation-messages.invalid-url")
                    };
                }
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-field")
                };
            }
        case AnswerType.Date:
            if (answerValue instanceof Date) {
                if (question.answerMaxValue instanceof Date && answerValue > question.answerMaxValue) {
                    return {
                        isValid: false,
                        message: t("validation-messages.max-date", { date: question.answerMaxValue })
                    };
                }
                if (question.answerMinValue instanceof Date && answerValue < question.answerMinValue) {
                    return {
                        isValid: false,
                        message: t("validation-messages.min-date", { date: question.answerMinValue })
                    };
                }
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-date")
                };
            }
        case AnswerType.IntegerNumber:
        case AnswerType.DecimalNumber:
            if (typeof answerValue === "number") {
                if (Number.isNaN(answerValue) || !Number.isFinite(answerValue)) {
                    return {
                        isValid: false,
                        message: t("validation-messages.invalid-field")
                    };
                }
                if (typeof question.answerMaxValue === "number" && answerValue >= question.answerMaxValue) {
                    return {
                        isValid: false,
                        message: t("validation-messages.max-number", { number: question.answerMaxValue })
                    };
                }
                if (typeof question.answerMinValue === "number" && answerValue <= question.answerMinValue) {
                    return {
                        isValid: false,
                        message: t("validation-messages.min-number", { number: question.answerMinValue })
                    };
                }
                if (question.answerType !== AnswerType.DecimalNumber && !Number.isInteger(answerValue)) {
                    return {
                        isValid: false,
                        message: t("validation-messages.invalid-number")
                    };
                }
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-field")
                };
            }
        case AnswerType.SingleChoiceList:
            if (question.choices && question.choices.some(c => c.id === answerValue)) {
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.not-empty")
                };
            }
            ;
        case AnswerType.MultipleChoiceList:
            if (Array.isArray(answerValue) && answerValue.length > 0 && answerValue.every(a => question.choices.some(c => c.id === a))) {
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.not-empty")
                };
            }
            ;
        case AnswerType.YesNo:
            if (typeof answerValue === "boolean") {
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-field")
                };
            }
            ;
        case AnswerType.YesNoDontKnow:
            if (answerValue === null || typeof answerValue === "boolean") {
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-field")
                };
            }
            ;
        case AnswerType.Period:
            if (typeof answerValue === "object") {
                let { start, end } = answerValue;
                if (!start || !end) {
                    return {
                        isValid: false,
                        message: t("validation-messages.invalid-field")
                    };
                }
                if (end <= start) {
                    return {
                        isValid: false,
                        message: t("validation-messages.invalid-field")
                    };
                }
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-field")
                };
            }
        case AnswerType.File:
            if (typeof answerValue === "object") {
                const currentFileExtension = (answerValue.name || answerValue.fileName).split('.').pop();
                if (question.fileExtensions && !question.fileExtensions.some(x => x === currentFileExtension)) {
                    return {
                        isValid: false,
                        message: t("validation-messages.invalid-file-format", { formats: question.fileExtensions.join(', ') })
                    };
                }
                return { isValid: true };
            }
            else {
                return {
                    isValid: false,
                    message: t("validation-messages.invalid-field")
                };
            }
        case AnswerType.Array:
            if (Array.isArray(answerValue)) {
                let itemResults = {};
                for (let index = 0; index < answerValue.length; index++) {
                    const item = answerValue[index];
                    let itemResult = {};
                    for (const q of question.arrayItemsQuestions) {
                        let r = isAnswerValid(q, item[q.id], t);
                        if (!r.isValid)
                            itemResult[q.id] = r;
                    }
                    if (Object.keys(itemResult).length !== 0) {
                        itemResults[index.toString()] = itemResult;
                    }
                }
                return {
                    isValid: Object.keys(itemResults).length === 0,
                    message: Object.keys(itemResults).length === 0 ? "" : t("validation-messages.invalid-fields"),
                    items: itemResults
                };
            }
            return { isValid: true };
        case AnswerType.Blocking:
            // There's no valid answer to a blocking question (that's the point)
            return {
                isValid: false,
                message: ''
            };
    }
};
