import '../../i18n';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSortBy, useTable } from 'react-table';
import { Emploi } from '../../models/generated/BackOffice-api';
import { formatNumber, sortArrayAsc } from '../../utils/functions';
import Table, { styleTable } from "../basics/Table";
import TotalAmountsTable from '../basics/TotalAmountsTable';
const Artistes = ({ details, isResponsableAndMembreCommission = false, isBo = false, openModal }) => {
    var _a, _b, _c;
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation();
    /** React table config */
    const columns = useMemo(() => {
        return [
            {
                Header: "Table",
                columns: [
                    { accessor: "id" },
                    { accessor: "isAssociated" },
                    {
                        Header: t("shared.nom"),
                        accessor: "nom",
                        disableSortBy: true,
                        Cell: (value) => (React.createElement("span", { className: "detailsTab__cellTable" },
                            value.cell.row.values.isAssociated && isResponsableAndMembreCommission && (React.createElement("i", { className: "fas fa-circle", style: { color: 'black', fontSize: 6 } })),
                            value.value))
                    },
                    { Header: t("shared.prenom"), accessor: "prenom", disableSortBy: true },
                    { Header: t("shared.emploi"), accessor: "emploi", disableSortBy: true },
                    { Header: t("shared.instrument"), accessor: "instrument", disableSortBy: true },
                    { Header: t("shared.repetition"), accessor: "montantRepetitions", className: 'table__cell--montants', disableSortBy: true, },
                    { Header: t("shared.representation"), accessor: "montantRepresentations", className: 'table__cell--montants', disableSortBy: true, },
                    { Header: t("shared.enregistrement"), accessor: "montantEnregistrement", className: 'table__cell--montants', disableSortBy: true, },
                    { Header: t("shared.concert"), accessor: "montantConcert", className: 'table__cell--montants', disableSortBy: true, },
                    { Header: t("shared.mensualisation"), accessor: "mensualisations", className: 'table__cell--montants', disableSortBy: true, },
                    { Header: t("shared.charges"), accessor: "montantCharge", className: 'table__cell--montants', disableSortBy: true, },
                    {
                        accessor: "actions",
                        disableSortBy: true,
                        Cell: (props) => (React.createElement("span", null,
                            React.createElement("i", { className: "fas fa-plus-circle", role: "button", onClick: () => openModal(props.value) })))
                    }
                ]
            }
        ];
    }, [details]);
    const hiddenColumns = () => {
        const array = ["id", "isAssociated"];
        if (!isBo)
            array.push("actions");
        if (!details.hasConcert)
            array.push("montantConcert");
        if (!details.hasEnregistrement)
            array.push("montantEnregistrement");
        if (!details.hasMensualisation)
            array.push("mensualisations");
        if (!details.hasRepresentation)
            array.push("montantRepresentations");
        if (!details.hasRepetition)
            array.push("montantRepetitions");
        return array;
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: hiddenColumns()
        },
        manualSortBy: true
    }, useSortBy);
    useEffect(() => {
        var _a;
        const data = ((_a = details.artistes) === null || _a === void 0 ? void 0 : _a.map(artiste => {
            var _a, _b;
            return ({
                nom: artiste.nom,
                prenom: artiste.prenom,
                charges: "",
                emploi: artiste.emploi,
                instrument: ((_b = (_a = artiste.instrument) === null || _a === void 0 ? void 0 : _a.intitule) === null || _b === void 0 ? void 0 : _b[i18n.language]) || '-',
                id: artiste.id,
                isAssociated: artiste.isAssociated,
                actions: artiste.id,
                mensualisations: `${formatNumber(artiste.mensualisations)} €`,
                montantConcert: `${formatNumber(artiste.montantConcert)} €`,
                montantRepetitions: `${formatNumber(artiste.montantRepetitions)} €`,
                montantEnregistrement: `${formatNumber(artiste.montantEnregistrement)} €`,
                montantRepresentations: `${formatNumber(artiste.montantRepresentations)} €`,
                montantCharge: `${formatNumber(artiste.montantCharge)} €`
            });
        }).sort((a, b) => sortArrayAsc(a.nom.toLowerCase(), b.nom.toLowerCase()))) || [];
        setData(data);
        setTotalAmountTable(details);
    }, [details]);
    const getArtistesCountByEmploi = (emploi) => {
        var _a;
        return ((_a = details === null || details === void 0 ? void 0 : details.artistes) === null || _a === void 0 ? void 0 : _a.filter(x => x.emploi === emploi).length) || 0;
    };
    const chanteursCount = getArtistesCountByEmploi(Emploi.Chanteur);
    const danseursCount = getArtistesCountByEmploi(Emploi.Danseur);
    const musiciensCount = getArtistesCountByEmploi(Emploi.Musicien);
    const chefsOrchestreCount = getArtistesCountByEmploi(Emploi.ChefOrchestre);
    const choristesCount = getArtistesCountByEmploi(Emploi.Choriste);
    const comediensCount = getArtistesCountByEmploi(Emploi.Comedien);
    const circassiensCount = getArtistesCountByEmploi(Emploi.Circassien);
    const marionettistesCount = getArtistesCountByEmploi(Emploi.Marionnettiste);
    // Total cachets
    const [totalsData, setTotalsData] = useState([]);
    const setTotalAmountTable = (details) => {
        if (!details.artistes.length)
            return;
        const updatedData = [];
        if (details.hasConcert) {
            updatedData.push({
                label: t(`createFolder.contratsCachets.statsConcert`),
                value: `${formatNumber(details.artistes.reduce((total, curr) => total + curr.montantConcert, 0))} €`
            });
        }
        if (details.hasEnregistrement) {
            updatedData.push({
                label: t(`createFolder.contratsCachets.statsEnregistrement`),
                value: `${formatNumber(details.artistes.reduce((total, curr) => total + curr.montantEnregistrement, 0))} €`
            });
        }
        if (details.hasRepresentation) {
            updatedData.push({
                label: t(`createFolder.contratsCachets.statsRepresentation`),
                value: `${formatNumber(details.artistes.reduce((total, curr) => total + curr.montantRepresentations, 0))} €`
            });
        }
        if (details.hasRepetition) {
            updatedData.push({
                label: t(`createFolder.contratsCachets.statsRepetition`),
                value: `${formatNumber(details.artistes.reduce((total, curr) => total + curr.montantRepetitions, 0))} €`
            });
        }
        if (details.hasMensualisation) {
            updatedData.push({
                label: t(`createFolder.contratsCachets.salaires`),
                value: `${formatNumber(details.artistes.reduce((total, curr) => total + curr.mensualisations, 0))} €`
            });
        }
        updatedData.push({
            label: t(`createFolder.contratsCachets.statsChargesPatronales`),
            value: `${formatNumber(details.artistes.reduce((total, curr) => total + curr.montantCharge, 0))} €`
        });
        setTotalsData(updatedData);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "detailsTab__content" },
            React.createElement("div", { className: "detailsTab__iconsList" },
                React.createElement("div", { className: "total" },
                    React.createElement("span", null, ((_a = details === null || details === void 0 ? void 0 : details.artistes) === null || _a === void 0 ? void 0 : _a.length) || 0),
                    React.createElement("div", null, t('dossier.details.artistes', { count: ((_b = details === null || details === void 0 ? void 0 : details.artistes) === null || _b === void 0 ? void 0 : _b.length) || 0 }))),
                ((details === null || details === void 0 ? void 0 : details.artistes) && ((_c = details.artistes) === null || _c === void 0 ? void 0 : _c.length) > 0) && (React.createElement("div", { className: "icons" },
                    musiciensCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-music" }),
                        t('dossier.details.musiciensWithCount', { count: musiciensCount }))),
                    chefsOrchestreCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-ear" }),
                        t('dossier.details.chefsOrchestreWithCount', { count: chefsOrchestreCount }))),
                    choristesCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-users" }),
                        t('dossier.details.choristesWithCount', { count: choristesCount }))),
                    danseursCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-walking" }),
                        t('dossier.details.danseursWithCount', { count: danseursCount }))),
                    chanteursCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-microphone-alt" }),
                        t('dossier.details.chanteursWithCount', { count: chanteursCount }))),
                    comediensCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-user-ninja" }),
                        t('dossier.details.comediensWithCount', { count: comediensCount }))),
                    circassiensCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-drum" }),
                        t('dossier.details.circassiensWithCount', { count: circassiensCount }))),
                    marionettistesCount > 0 && (React.createElement("div", null,
                        React.createElement("i", { className: "fas fa-male" }),
                        t('dossier.details.marionettistesWithCount', { count: marionettistesCount })))))),
            React.createElement("div", { className: "detailsTab__table" },
                React.createElement(Table, { data: data, getTableProps: getTableProps, getTableBodyProps: getTableBodyProps, headerGroups: headerGroups, rows: rows, prepareRow: prepareRow, styles: styleTable.onglet })),
            totalsData.length > 0 && (React.createElement(TotalAmountsTable, { data: totalsData })))));
};
export default Artistes;
