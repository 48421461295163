import moment from 'moment';
import { IFile, IFileBudget } from '../models/IFile';
import { DocumentUploadInfoDto } from '../services/generated/FrontOffice-api';

export const reduceToOneDigit = (value:any) => {
	let sum = null;
	let tmp = null;
	while (value >= 10) {
		sum = 0;
		tmp = value;
		while (tmp !== 0) {
		sum += (tmp % 10);
		tmp = tmp / 10;
		}
		value = sum;
	}
	return value;
}

export function calculateMod(str: string, mod: number) : number {
	let n = str.length;
	if (n <= 10)
	  return parseInt(str) % mod;
	else {
	  const first = str.substring(0, n - 10);
	  const second = str.substring(n - 10);
	  return (calculateMod(first, mod) * (Math.pow(10, 10) % mod) + parseInt(second, 10) % mod) % mod;
	}
}

export const convertIFilesToDocumentUploadInfoDto = (files: IFile[]): DocumentUploadInfoDto[] => {
	return files.map(item => {
		const doc: DocumentUploadInfoDto = {};
		doc.typeId = item.typeId;
		doc.extraInfos = item.extraInfos;
		if (item.file || item && item.typeDeDocumentSelected && item.typeDeDocumentSelected.hasAttachment) {
			doc.partName = item.code.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		}
		return doc;
	});
}

export const convertIFilesBudgetToDocumentUploadInfoDto = (files: IFileBudget[]): DocumentUploadInfoDto[] => {
	return files.map(item => {
		const doc: DocumentUploadInfoDto = {};
		if(item.id) {
			doc.id = item.id;
		}
		doc.partName = item.partName.replace(/\./g, "-");
		return doc;
	});
}

export const isDateOrDateString = (value: any) => {
	if(value instanceof Date) {
		return true;
	}
	const timestamp = Date.parse(value);
	if(!isNaN(timestamp)) {
		return new Date(timestamp).toISOString() === value;
	}
	return false;
};
